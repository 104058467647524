import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { post } from '../../../../api/base'
import { apiUrls, ICONS } from '../../../../constants'
import { logout } from '../../../../redux/slices/authSlice'
import { deleteUserData, selectUserData } from '../../../../redux/slices/userSlice'
import Menu from '../SideNav/components/Menu'
import { toast } from 'react-toastify'
import { Toast } from '../../../../components'
import { store } from '../../../../redux/store'

const Header = ({ title, crumbs }) => {
  const dispatch = useDispatch()
  let crumbsLastIndex = crumbs ? crumbs.length - 1 : 0
  const [visibility, setVisibility] = useState('hidden')
  const [showNav, setshowNav] = useState(false)
  const userData = useSelector(selectUserData)
  const { uuid } = store.getState().auth

  const menus = userData?.menus ? userData?.menus : []

  const handleLogout = () => {
    post(apiUrls.LOGOUT_URL, { device_id: uuid })
      .then(async response => {
        localStorage.clear()
        dispatch(logout())
        dispatch(deleteUserData())
      })
      .catch(error => {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to logout'} />)
      })
  }

  const handleProfileClick = () => {
    if (visibility === 'hidden') {
      setVisibility('visible')
    } else {
      setVisibility('hidden')
    }
  }

  const handleOnBurgerClick = () => {
    setshowNav(prevState => {
      return !prevState
    })
  }

  return (
    <div className="flex flex-col-reverse md:flex-row justify-between my-2 mx-2 sm:my-2 sm:mx-6 gap-2">
      <div className="flex flex-col">
        {/* title and crumbs */}
        <div className="font-poppins text-[20px] font-semibold text-black">{title}</div>
        <div>
          {crumbs ? (
            crumbs.map((crumbItem, index) => {
              if (index === crumbsLastIndex) {
                return (
                  <span
                    key={index}
                    className={`font-poppins text-[12px] ${
                      index === 0 ? 'text-[#C800A5]' : 'text-black'
                    } `}
                  >
                    {crumbItem}
                  </span>
                )
              } else {
                return (
                  <span key={index} className="font-poppins text-[12px] text-[#C800A5]">
                    {crumbItem} /{' '}
                  </span>
                )
              }
            })
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex items-center">
        {/* notificaation icon and profile */}
        <img src={ICONS.icNotification} className="w-8 h-8 mr-4" />
        <div className="flex bg-white rounded-lg p-[6px] relative">
          {/* Profile Icon */}
          <div className="flex items-center justify-between w-[195px]">
            <div className="flex items-center">
              <img
                src={userData?.picture ? userData.picture : ICONS.icAdminActive}
                className="w-6 h-6 ml-1 rounded-full"
              />
              <div className="px-2 font-poppins text-xs">
                {userData?.full_name ? userData?.full_name : 'Profile Name'}
              </div>
            </div>
            <img
              src={ICONS.icChevronDownSecondary}
              className="w-[10px] mr-1 cursor-pointer"
              onClick={handleProfileClick}
            />
          </div>
          <div
            className={`${visibility} bg-black bg-opacity-70 py-3 px-4 rounded-xl absolute right-0 bottom-[-65px]`}
          >
            <button
              className={`bg-white rounded-lg px-[12px] py-[10px] w-[104px] text-left text-[#C800A5] text-xs`}
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="sm:hidden">
        <div
          className={`cursor-pointer bg-[#6546C3] sm:hidden rounded-md p-1 w-fit ${
            showNav ? 'rounded-b-[0px]' : ''
          }`}
        >
          <img src={ICONS.icCollapseExpand} className="w-6 h-6" onClick={handleOnBurgerClick} />
        </div>
        {showNav ? (
          <div className="absolute bg-[#6546C3] rounded-b-md rounded-tr-md p-2 z-10">
            {menus.length > 0
              ? menus.map((menu, index) => <Menu data={menu} key={index} open={true} />)
              : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Header
