import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sites: []
}

export const micrositeSlice = createSlice({
  name: 'microsite',
  initialState,
  reducers: {
    addSite: (state, action) => {
      const clone = [...state.sites]
      const newSite = action.payload
      const existIndex = state.sites.findIndex(s => s.id === newSite.id)
      if (existIndex > -1) {
        clone[existIndex] = newSite
        state.sites = clone
      } else {
        state.sites = [...state.sites, action.payload]
      }
    },
    removeSite: (state, action) => {
      const index = state.sites.findIndex(site => site.route_name === action.payload)
      if (index > -1) {
        state.sites = [...state.sites.slice(0, index), ...state.sites.slice(index + 1)]
      }
    }
  }
})

export const { addSite, removeSite } = micrositeSlice.actions

export const selectSites = state => state.microsite.sites

export default micrositeSlice.reducer
