import { useNavigate } from 'react-router-dom'
import { ICONS } from '../../../../../constants'
import _ from 'lodash'

export default function ClassicView({ topColor, formData }) {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-4">
      {formData?.map((data, index) => {
        return (
          <div className="bg-[#F5F5F5] pt-7 pb-6 pl-5 rounded-xl" key={index}>
            <div className="flex flex-row">
              <div className="dropdown dropdown-bottom h-fit " onClick={e => e.stopPropagation()}>
                <button
                  className="block rounded-full px-2 py-1 mr-4"
                  style={{ background: topColor }}
                  tabIndex="0"
                >
                  <img
                    width={4}
                    height={4}
                    src={ICONS.icMore}
                    className=" cursor-pointer"
                    alt="action-button"
                  />
                </button>

                <ul
                  tabIndex="0"
                  className="dropdown-content menu p-1 shadow bg-base-100 rounded-box w-20"
                >
                  <li onClick={() => navigate(`edit/${data.id}`)}>
                    <div className="flex">
                      <span className="text-sm">Edit</span>
                    </div>
                  </li>
                  <hr />
                  <li onClick={() => navigate(`view/${data.id}`)}>
                    <div className="flex">
                      <span className="text-sm">Detail</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-[14px] font-semibold mb-7">
                {data?.form?.sorting_fields[0]?.value}
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-y-4">
              {data?.form?.sorting_fields?.map(
                (field, index) =>
                  index !== 0 && (
                    <div
                      key={index}
                      className={`w-full flex flex-col justify-center items-center p-4  ${
                        index % 4 !== 0 &&
                        index !== data?.form?.sorting_fields.length - 1 &&
                        'border-r border-[#DEDEDE]'
                      }`}
                    >
                      <div className="text-[12px] font-bold">{field?.field_title}</div>
                      {_.isObject(field?.value) ? (
                        <div className="tooltip" data-tip={field?.value.value}>
                          <div className="text-[12px] line-clamp-2 px-2">{field?.value.value}</div>
                        </div>
                      ) : field?.value?.includes('data:image/png') ? (
                        <img src={field?.value} />
                      ) : _.isArray(field?.value) ? (
                        field?.value.map(val => (
                          <div className="tooltip" data-tip={field?.value}>
                            <div className="text-[12px] line-clamp-2 px-2">{val}</div>
                          </div>
                        ))
                      ) : (
                        <div className="tooltip" data-tip={field?.value}>
                          <div className="text-[12px] line-clamp-2 px-2">{field?.value || '-'}</div>
                        </div>
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
