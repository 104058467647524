import { TextH6, DeletePopup } from '../../../components'
import { ICONS } from '../../../constants'
import { useDispatch } from 'react-redux'
import { setShowPopupDelete, setMessage } from '../../../redux/slices/popupSlice'
import { useState } from 'react'
import FormListModal from './FormListModal'

export default function Info({
  data: { id, name, alias_name, children, parent, parents, is_active, total_form_mapping },
  is_content,
  handleEditClick,
  handleDeleteClick
}) {
  const dispatch = useDispatch()
  const [showFormListModal, setShowFormListModal] = useState(false)
  const onDeleteClick = () => {
    dispatch(setShowPopupDelete(true))
    dispatch(setMessage('Are you sure want to delete this master data?'))
  }

  return (
    <div className="bg-white rounded-2xl p-2 sm:p-6 w-full my-6">
      <div className="flex lg:flex-row flex-col">
        <div className="w-full lg:my-0 my-2">
          <TextH6 text={is_content ? 'Full Name' : 'Master Data'} />
          <div className="text-xs mt-2">{name || '-'}</div>
        </div>
        {is_content ? (
          <>
            <div className="w-full lg:my-0 my-2">
              <TextH6 text="Alias Name" />
              <div className="text-xs mt-2">{alias_name || '-'}</div>
            </div>
            <div className="w-full lg:my-0 my-2">
              <TextH6 text="Form" />
              <div
                className="text-xs text-[#C800A5] underline mt-2 cursor-pointer"
                onClick={() => setShowFormListModal(true)}
              >
                {total_form_mapping > 0 ? total_form_mapping : '*'}
              </div>
            </div>
          </>
        ) : (
          <div className="w-full lg:my-0 my-2 mr-2">
            <TextH6 text="Child" />
            <div className="flex">
              {children && children.length ? (
                children.map(child => (
                  <div
                    key={child.id}
                    className={`mt-[8px] flex bg-[#FBEAF8] justify-center items-center rounded-lg p-2 mr-2 w-fit`}
                  >
                    <span className={`text-[#C800A5] font-semibold text-xs`}>
                      <div>{child.name}</div>
                    </span>
                  </div>
                ))
              ) : (
                <div className="text-xs mt-2">-</div>
              )}
            </div>
          </div>
        )}
        <div className="w-full max-w-[300px] lg:my-0 my-2">
          <TextH6 text="Parent" />
          <div className="flex">
            {parent ? (
              <div
                className={`mt-[8px] flex bg-[#F0EEF6] justify-center items-center rounded-lg p-2 w-fit m-1`}
              >
                <span className={`text-[#8770CF] font-semibold text-xs`}>
                  <div>{parent.name}</div>
                </span>
              </div>
            ) : (
              <>
                {parents && parents.length > 0 ? (
                  parents.map(v => (
                    <div
                      key={v.id}
                      className={`mt-[8px] flex bg-[#F0EEF6] justify-center items-center rounded-lg p-2 w-fit m-1`}
                    >
                      <span className={`text-[#8770CF] font-semibold text-xs`}>
                        <div>{v.name}</div>
                      </span>
                    </div>
                  ))
                ) : (
                  <div className="text-xs mt-2">-</div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="lg:my-0 my-2 w-full max-w-[100px]">
          <TextH6 text="Status" />
          <div
            className={` mt-2 flex justify-center items-center ${
              is_active ? 'bg-green-100' : 'bg-red-100'
            } rounded-lg p-2`}
          >
            <span
              className={`${is_active ? 'text-green-600' : 'text-red-600'} font-semibold text-xs`}
            >
              <div>{is_active ? 'Active' : 'Inactive'}</div>
            </span>
          </div>
        </div>

        <div className="min-w-[80px] max-w-[300px] flex justify-end">
          <img
            src={ICONS.icEdit}
            className={`w-[28px] h-[28px] mt-[16px] ml-2 cursor-pointer ${
              is_content ? 'visible' : 'hidden'
            }`}
            onClick={() => handleEditClick()}
            alt="edit"
          />
          <img
            src={ICONS.icTrash}
            className={`w-[28px] h-[28px] mt-[16px] ml-2 cursor-pointer ${
              is_content ? 'visible' : 'hidden'
            }`}
            onClick={() => onDeleteClick()}
            alt="delete"
          />
        </div>
      </div>
      <FormListModal
        showModal={showFormListModal}
        setShowModal={setShowFormListModal}
        name={name}
        detailId={id}
      />
      <DeletePopup setContinueDelete={() => handleDeleteClick()} buttonNext={'Delete Now'} />
    </div>
  )
}
