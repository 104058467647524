import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { get } from '../../../api/base'
import { SuccessPopup, Toast, Toggle } from '../../../components'
import { apiUrls, ICONS, images } from '../../../constants'

export default function DetailApplication() {
  const navigate = useNavigate()
  const userData = useSelector(state => state.user.userData)
  const { id } = useParams()
  const [detailData, setDetailData] = useState({})

  const hasAuthority = permission => {
    const path = location.pathname
    const pathSplit = path.split('/')
    const isSubMenu = pathSplit.length > 2
    if (isSubMenu) {
      const menus = userData?.menus
      const menu = menus?.find(menu => {
        const menuPath = menu.path
        const menuPathSplit = menuPath.split('/')
        return menuPathSplit[0] === pathSplit[1]
      })
      const subMenus = menu?.subMenus
      const subMenu = subMenus?.find(subMenu => {
        const subMenuPath = subMenu.path
        const subMenuPathSplit = subMenuPath.split('/')
        return subMenuPathSplit[1] === pathSplit[2]
      })
      return subMenu?.permissions.includes(permission)
    }
  }

  function addModule() {
    navigate(`/Admin/ApplicationProfile/DetailApplicationProfile/${id}/AddModule`, {
      state: {
        title: 'Add Module/Facility',
        crumbs: ['Admin', 'Application Profile', 'Add Module/Facility']
      }
    })
  }

  function editModule(id_module) {
    navigate(`/Admin/ApplicationProfile/DetailApplicationProfile/${id}/EditModule/${id_module}`, {
      state: {
        title: 'Edit Module/Facility',
        crumbs: [
          'Admin',
          'Application Profile',
          'Detail Application Profile',
          'Edit Module/Facility'
        ]
      }
    })
  }

  function viewModule(id_module) {
    navigate(`/Admin/ApplicationProfile/DetailApplicationProfile/${id}/DetailModule/${id_module}`, {
      state: {
        title: 'Detail Module/Facility',
        crumbs: [
          'Admin',
          'Application Profile',
          'Detail Application Profile',
          'Detail Module/Facility'
        ]
      }
    })
  }

  useEffect(() => {
    fetchApplication()
  }, [])

  async function fetchApplication() {
    await get(`${apiUrls.APPLICATIONS_URL}/${id}`).then(response => {
      const { status, data } = response
      if (status === 200) {
        setDetailData(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={'Failed to fetch application data'} />
        )
      }
    })
  }

  return (
    <div className="grid lg:grid-cols-4 gap-6 mx-2 md:mx-6 my-2">
      {/* LOGO */}
      <div className="bg-white rounded-2xl p-4">
        <div className="flex items-center justify-center rounded-xl bg-[#F2F5FC] h-full py-8 px-4">
          {detailData.icon ? (
            <img src={detailData.icon} className="max-w-full max-h-full" alt="icon" />
          ) : (
            <img src={ICONS.icApp} className="w-28" alt="placeholder icon" />
          )}
        </div>
      </div>

      {/* INFO */}
      <div className="bg-white rounded-2xl p-4 sm:p-6 lg:col-span-3">
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-8 mb-2 sm:mb-6">
          <div className="flex-1">
            <div className="font-semibold text-[12px] mb-2">Application Name</div>
            <div className="text-[12px] text-[#858585]">{detailData.name}</div>
          </div>

          <div className="flex-1">
            <div className="font-semibold text-[12px] mb-2">Application Link</div>
            <div className="text-[12px] text-[#858585]">{detailData.application_link}</div>
          </div>

          <div className="flex-1">
            <div className="font-semibold text-[12px] mb-2">Application Deep Link</div>
            <div className="text-[12px] text-[#858585]">{detailData.deeplink || '-'}</div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-2 sm:gap-8 mb-2 sm:mb-6">
          <div className="flex-1">
            <div className="font-semibold text-[12px] mb-2">Authentication API Link</div>
            <div className="text-[12px] text-[#858585]">
              {detailData.authentication_api_link || '-'}
            </div>
          </div>

          <div className="flex-1">
            <div className="font-semibold text-[12px] mb-2">Authentication Parameter</div>
            <div className="text-[12px] text-[#858585]">
              {detailData.authentication_parameter || '-'}
            </div>
          </div>

          <div className="flex-1">
            <div className="font-semibold text-[12px] mb-2">Contact Person</div>
            <div className="text-[12px] text-[#858585]">{detailData.contact_person || '-'}</div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-2 sm:gap-16 mb-2 sm:mb-6">
          <div className="basis-2/3">
            <div className="font-semibold text-[12px] mb-2">Description</div>
            <div className="text-[12px] text-[#858585] mb-2">
              {detailData.description ? (
                <span dangerouslySetInnerHTML={{ __html: detailData.description }} />
              ) : (
                <span>-</span>
              )}
            </div>
          </div>

          <div className="basis-1/3 flex gap-4">
            <div>
              <div className="font-semibold text-[12px] mb-2">Is Active</div>
              <Toggle checked={detailData.active} />
            </div>
            <div>
              <div className="font-semibold text-[12px] mb-2">Visibility</div>
              <Toggle checked={detailData.visibility} />
            </div>
          </div>
        </div>
      </div>

      {/* MODULES */}
      {detailData.modules?.length > 0 ? (
        <div className="lg:col-span-4">
          <div className="flex justify-between items-center mb-4">
            <div className="font-semibold text-[16px]">List Module</div>
            <button
              className="flex w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold text-xs rounded-md px-8 py-3"
              onClick={() => addModule()}
            >
              Add Module
            </button>
          </div>

          <div className="flex flex-col gap-4">
            {detailData?.modules.map(module => (
              <div
                key={module.id}
                className="flex flex-col sm:flex-row gap-2 sm:gap-8 bg-white rounded-2xl p-4"
              >
                <div className="flex justify-center items-center mx-2">
                  {module.icon ? (
                    <img src={module.icon} className="w-12" alt="icon" />
                  ) : (
                    <img src={ICONS.icDefaultModule} className="w-12" alt="icon" />
                  )}
                </div>

                <div className="flex-1">
                  <div className="font-semibold text-[12px] mb-2">Module Name</div>
                  <div className="text-[12px]">{module.name}</div>
                </div>

                <div className="flex-1 max-w-[320px]">
                  <div className="font-semibold text-[12px] mb-2">URL</div>
                  <div className="text-[12px] break-words">{module.deeplink}</div>
                </div>

                <div className="flex-1">
                  <div className="font-semibold text-[12px] mb-2">Available Area</div>
                  <div className="text-[12px]">{module.available_area}</div>
                </div>

                <div className="flex flex-1 justify-between">
                  <div>
                    <div className="font-semibold text-[12px] mb-2">Is Active</div>
                    <Toggle checked={module.active} />
                  </div>
                  <div className="flex gap-4 items-center">
                    {hasAuthority('READ') && (
                      <img
                        src={ICONS.icDetail}
                        className="w-[28px] cursor-pointer"
                        onClick={() => viewModule(module.id)}
                        alt="view detail"
                      />
                    )}
                    {hasAuthority('UPDATE') && (
                      <img
                        src={ICONS.icEdit}
                        className="w-[28px] cursor-pointer"
                        onClick={() => editModule(module.id)}
                        alt="edit"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center sm:p-6 lg:col-span-4">
          <img src={images.noSection} className="w-[160px] h-[160px] my-6" alt="No Data" />
          <div className="font-semibold text-[18px] mb-2">Module does not exist yet</div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "add module button to add it now!
          </div>
          <button
            className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold text-xs rounded-md px-8 py-3"
            onClick={() => addModule()}
          >
            Add Module
          </button>
        </div>
      )}

      <div className="flex justify-end gap-4 lg:col-span-4">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
        >
          Back
        </button>
      </div>
      <SuccessPopup />
    </div>
  )
}
