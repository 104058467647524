import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTable, useSortBy } from 'react-table'
import { toast } from 'react-toastify'
import { get, put } from '../../../api/base'
import { FormInput, Toast } from '../../../components'
import { apiUrls, ICONS } from '../../../constants'
import { useMediaQuery } from 'react-responsive'
import CollaboratorModal from './CollaboratorModal'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="flex w-full md:w-fit font-poppins border-[1px] items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}

const FormTable = () => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  const COLUMNS = [
    {
      Header: 'Code Form',
      accessor: 'code_form',
      Cell: ({ row, value }) => {
        return value
      }
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row, value }) => {
        return value
      }
    },
    {
      Header: 'Ref number',
      accessor: 'ref_number',
      Cell: ({ row, value }) => {
        return value
      }
    },
    {
      Header: 'Company',
      accessor: 'company',
      Cell: ({ row, value }) => {
        const companies = value
          .map(company => {
            return company
          })
          .join(', ')
        return companies
      }
    },
    {
      Header: 'Attachment',
      accessor: 'attachment',
      Cell: ({ row, value }) => {
        if (value) {
          return <span className="font-semibold italic text-[12px] text-[#6546C3]">Yes</span>
        } else {
          return <span className="font-semibold italic text-[12px] text-[#C800A5]">No</span>
        }
      }
    },
    {
      Header: 'Last submission',
      accessor: 'last_submission',
      Cell: ({ row, value }) => {
        if (row.original.module_id) return '-'
        return value
      }
    },
    {
      Header: 'Status',
      accessor: 'is_active',
      Cell: ({ row, value, pageNum }) => {
        const formId = row.original.id
        const hasFullAccess = row.original.full_access
        const checked = value
        const handleStatusChange = (event, id) => {
          const newChecked = event.target.checked
          put(`${apiUrls.FORMS_URL}/${id}`, { is_active: newChecked }).then(response => {
            const { status } = response
            if (status === 200) {
              toast.success(
                <Toast
                  message={`Success`}
                  detailedMessage={`You have successfully update form ${
                    row.original.code_form
                  } to ${newChecked ? 'Active' : 'Inactive'}`}
                />
              )
            } else {
              toast.error(
                <Toast
                  message={`Error`}
                  detailedMessage={`Failed to update form ${row.original.code_form} to ${
                    checked ? 'Active' : 'Inactive'
                  }`}
                />
              )
            }
            fetchForms(pageNum)
          })
        }
        const checkedClass =
          "w-11 h-6 bg-[#6546C3] rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all after:translate-x-full after:border-white "
        const unCheckedClass =
          "w-11 h-6 bg-gray-200 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all"
        return (
          <label
            htmlFor={`status-toggle-${formId}`}
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              id={`status-toggle-${formId}`}
              className="sr-only peer"
              checked={checked}
              onChange={event => handleStatusChange(event, row.original.id)}
              disabled={!hasFullAccess}
            />
            <div className={checked ? checkedClass : unCheckedClass}></div>
          </label>
        )
      }
    },
    {
      Header: 'Point',
      accessor: 'point',
      Cell: ({ row, value }) => {
        return value
      }
    },
    {
      Header: 'WF Module',
      accessor: 'module_name',
      Cell: ({ row, value }) => {
        return value || '-'
      }
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleDetailFormClick = () => {
          const DETAIL_FORM_PATH = `/DocumentLibrary/Form/DetailForm/${row.original.id}`
          const DETAIL_FORM_CRUMBS = 'Document Library/Form/Detail Form'
          const crumbs = DETAIL_FORM_CRUMBS.split('/')
          navigate(DETAIL_FORM_PATH, {
            state: { title: 'Detail Form', crumbs: crumbs }
          })
        }
        const handleEditFormClick = () => {
          const DETAIL_FORM_PATH = `/DocumentLibrary/Form/EditForm/${row.original.id}`
          const DETAIL_FORM_CRUMBS = 'Document Library/Form/Edit Form'
          const crumbs = DETAIL_FORM_CRUMBS.split('/')
          navigate(DETAIL_FORM_PATH, {
            state: { title: 'Detail Form', crumbs: crumbs }
          })
        }
        const handleCollaboratorActionClick = () => {
          setFormData(row.original)
          setShowCollaboratorModal(true)
        }
        const handleDuplicateClick = () => {
          const DETAIL_FORM_PATH = `/DocumentLibrary/Form/EditForm/${row.original.id}`
          const DETAIL_FORM_CRUMBS = 'Document Library/Form/Duplicate Form'
          const crumbs = DETAIL_FORM_CRUMBS.split('/')
          navigate(`${DETAIL_FORM_PATH}?duplicate=true`, {
            state: { title: 'Duplicate Form', crumbs: crumbs }
          })
        }
        return (
          <div className="flex flex-wrap gap">
            <img
              src={ICONS.icDuplicate}
              className="w-[28px] cursor-pointer"
              onClick={() => handleDuplicateClick()}
            />
            <img
              src={ICONS.icCollaborator}
              className="w-[28px] cursor-pointer"
              onClick={() => handleCollaboratorActionClick()}
            />
            <img
              src={ICONS.icEdit}
              className="w-[28px] cursor-pointer"
              onClick={() => handleEditFormClick()}
            />
            <img
              src={ICONS.icDetail}
              className="w-[28px] cursor-pointer"
              onClick={() => handleDetailFormClick()}
            />
          </div>
        )
      }
    }
  ]
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const isAddRow = useRef(false)
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false)
  const [formData, setFormData] = useState(null)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    setHiddenColumns,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        hiddenColumns: []
      },
      pageNum
    },
    useSortBy
  )

  useEffect(() => {
    if (isDesktop) {
      setHiddenColumns([])
    } else {
      setHiddenColumns(['code_form', 'ref_number', 'attachment', 'last_submission'])
    }
  }, [isDesktop])

  useEffect(() => {
    if (sortBy.length > 0) {
      setManualSortBy(sortBy[0].id)
      setOrderBy(sortBy[0].desc ? 'desc' : 'asc')
    } else {
      setManualSortBy('')
      setOrderBy('')
    }
  }, [sortBy])

  useEffect(() => {
    fetchForms()
  }, [search, pageNum, limit, manualSortBy, orderBy])

  function fetchForms(currPage) {
    let query = {
      query: {}
    }
    if (search !== '') query.query.search = search

    if (pageNum !== '') query.query.page = currPage ? currPage : pageNum

    if (limit !== '') query.query.limit = limit

    if (manualSortBy !== '') query.query.sort_by = manualSortBy

    if (orderBy !== '') query.query.order_by = orderBy

    get(apiUrls.FORMS_URL, query).then(response => {
      const { status } = response
      if (status === 200) {
        if (pageNum > response.data?.total_page) {
          setPageNum(1)
        }
        setData(response.data.forms)
        setTotalPage(response.data.total_page)
        setTotalData(response.data.total_data)
      } else {
        errorApi(status, response)
      }
    })
  }

  const errorApi = (status, response) => {
    switch (status) {
      case 408:
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
        break
      case 400:
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
        break
      default:
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
        break
    }
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => setPageNum(data)}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function handleAddFormClick() {
    const ADD_FORM_PATH = '/DocumentLibrary/Form/AddForm'
    const ADD_FORM_CRUMBS = 'DocumentLibrary/Form/AddForm'
    const crumbs = ADD_FORM_CRUMBS.split('/')
    navigate(ADD_FORM_PATH, { state: { title: 'Add Form', crumbs: crumbs } })
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 != 0
  }

  const goToNextPage = () => {
    setPageNum(pageNum + 1)
  }
  const goToPreviousPage = () => {
    setPageNum(pageNum - 1)
  }

  return (
    <div className="p-2">
      <div className="flex flex-col md:flex-row gap-2 items-center justify-between font-poppins text-[12px] mb-10">
        <button
          className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-8 py-3"
          onClick={() => handleAddFormClick()}
        >
          Add Form
        </button>
        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
            />
            <select
              value={limit}
              onChange={e => {
                setLimit(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(limit => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex w-full md:w-fit">
          <GlobalFilter filter={search} setFilter={setSearch} />
        </div>
      </div>
      <div className="overflow-x-auto w-full">
        <table {...getTableProps()} className="font-poppins text-[12px] w-full">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex gap-1">
                      {column.render('Header')}
                      <img
                        src={
                          column.canSort
                            ? column.isSorted
                              ? column.isSortedDesc
                                ? ICONS.icSortDown
                                : ICONS.icSortUp
                              : ICONS.icSort
                            : ''
                        }
                        className="w-2"
                      />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-9">
        <div className="font-poppins text-[12px]">
          Showing {pageNum * limit - (limit - 1)} -{' '}
          {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
        </div>
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &gt;
          </button>
        </div>
      </div>
      <CollaboratorModal
        showModal={showCollaboratorModal}
        setShowModal={setShowCollaboratorModal}
        formData={formData}
      />
    </div>
  )
}

export default FormTable
