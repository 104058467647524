import { images } from '../../constants'

const NoDataTable = ({ message, detailedMessage }) => {
  return (
    <div className="font-poppins text-black grid justify-items-center md:justify-items-center">
      <img src={images.noSection} width="160px" height="160px" className="mb-6" />
      <div className="font-semibold text-[14px] text-center mb-2">{message}</div>
      <div className="text-[12px] text-center text-[#AAAAAA]">{detailedMessage}</div>
    </div>
  )
}

export default NoDataTable
