import { useEffect, useMemo, useState } from 'react'

import { DropdownIndicator, DeletePopup, Toast } from '../../../components'
import { ICONS, apiUrls } from '../../../constants'
import AsyncSelect from 'react-select/async'
import { useDispatch } from 'react-redux'
import {
  setShowPopupSuccess,
  setMessage,
  setShowPopupDelete
} from '../../../redux/slices/popupSlice'
import NoDataTable from '../../../components/NoDataTable'
import { useTable } from 'react-table'
import { del, get, post, put } from '../../../api/base'
import { toast } from 'react-toastify'

export default function FormMappingModal({ showModal, setShowModal, masterData, fetchMasterData }) {
  const dispatch = useDispatch()
  const [selectedForm, setSelectedForm] = useState(null)
  const [isEditingMode, setIsEditingMode] = useState(false)
  const [selectedFormMappingId, setSelectedFormMappingId] = useState(null)
  const [optionList, setOptionList] = useState([])
  const [searchOptionQuery, setSearchOptionQuery] = useState('')
  const [selectedOptionList, setSelectedOptionList] = useState([])

  const noOptionsMessage = ({ inputValue }) =>
    inputValue.length >= 0 && inputValue.length < 5
      ? 'Type at least 5 characters'
      : 'No results found'

  const fetchForms = title => {
    return new Promise((resolve, reject) => {
      if (title.length === 0 || title.length >= 5) {
        get(`${apiUrls.MASTER_DATA}/forms`, {
          query: { search: title }
        })
          .then(res => {
            if (res.status === 200) {
              resolve(res.data)
            } else {
              toast.error(
                <Toast message={'Error'} detailedMessage={`${res.data?.error?.message}`} />
              )
              resolve([])
            }
          })
          .catch(error => {
            reject(error)
          })
      } else {
        resolve([])
      }
    })
  }

  useEffect(() => {
    if (showModal && masterData?.data) {
      setOptionList(masterData.data?.map(item => ({ id: item.id, name: item.name })))
    }
  }, [showModal])

  function close() {
    setShowModal(false)
    setSelectedForm(null)
    setSelectedOptionList([])
  }

  function cancel() {
    setSelectedForm(null)
    setSelectedOptionList([])
    setSelectedFormMappingId(null)
    setIsEditingMode(false)
  }

  const handleOnChangeCheckOption = (option, event) => {
    let selectedOptionCopy = [...selectedOptionList]
    if (event.target.checked) {
      selectedOptionCopy.push(option)
    } else {
      const index = selectedOptionCopy.indexOf(option)
      selectedOptionCopy.splice(index, 1)
    }

    setSelectedOptionList(selectedOptionCopy)
  }

  function formMappingValidation() {
    if (!selectedForm || selectedOptionList.length === 0) return false
    return true
  }

  function handleAddFormMapping() {
    const data = {
      form_id: selectedForm?.id,
      option_ids: selectedOptionList
    }
    post(`${apiUrls.MASTER_DATA}/${masterData?.id}/form-mapping`, data).then(response => {
      const { status } = response
      if (status === 201) {
        fetchListFormMapping()
        setSelectedForm(null)
        setSelectedOptionList([])
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully set form mapping'))
        fetchMasterData()
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={`${response.data?.error?.message}`} />
        )
      }
    })
  }

  function handleEditFormMapping() {
    const data = {
      option_ids: selectedOptionList
    }
    put(`${apiUrls.MASTER_DATA}/form-mapping/${selectedFormMappingId}`, data).then(response => {
      const { status } = response
      if (status === 200) {
        fetchListFormMapping()

        setSelectedForm(null)
        setSelectedOptionList([])
        setSelectedFormMappingId(null)
        setIsEditingMode(false)

        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully edit form mapping'))
        fetchMasterData()
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={`${response.data?.error?.message}`} />
        )
      }
    })
  }

  function handleDeleteFormMapping() {
    del(`${apiUrls.MASTER_DATA}/form-mapping/${selectedFormMappingId}`).then(response => {
      const { status } = response
      if (status === 200) {
        fetchListFormMapping()

        setSelectedForm(null)
        setSelectedOptionList([])
        setSelectedFormMappingId(null)
        setIsEditingMode(false)

        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully delete form mapping'))
        fetchMasterData()
      } else {
        toast.error(
          <Toast message={'Error'} detailedMessage={`${response.data?.error?.message}`} />
        )
      }
    })
  }

  //====== Form Mapping Table ======\\

  const COLUMNS = [
    {
      Header: 'Form Name',
      accessor: 'form',
      Cell: ({ value }) => {
        return <div className="max-w-[200px] truncate">{value.title}</div>
      }
    },
    {
      Header: 'Option',
      accessor: 'options',
      Cell: ({ value }) => (
        <div className="flex flex-col gap-1">
          {value.map(v => (
            <div key={v.id}>{v.name}</div>
          ))}
        </div>
      )
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleEditClick = () => {
          setIsEditingMode(true)
          setSelectedFormMappingId(row.original.id)
          setSelectedForm(row.original.form)
          setSelectedOptionList(row.original.options.map(obj => obj.id))
        }
        const handleDeleteClick = () => {
          setSelectedFormMappingId(row.original.id)
          dispatch(setShowPopupDelete(true))
          dispatch(setMessage('Are you sure you want to delete this form mapping setting?'))
        }

        return (
          <div className="flex gap-2">
            <img
              src={ICONS.icEdit}
              className="w-[28px] cursor-pointer"
              onClick={() => handleEditClick()}
              alt="edit"
            />
            <img
              src={ICONS.icTrash}
              className="w-[28px] cursor-pointer"
              onClick={() => handleDeleteClick(row.original.id)}
              alt="delete"
            />
          </div>
        )
      }
    }
  ]

  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const limit = 8
  const [search, setSearch] = useState('')
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)

  useEffect(() => {
    if (showModal) fetchListFormMapping()
  }, [showModal, search, pageNum])

  const fetchListFormMapping = () => {
    get(`${apiUrls.MASTER_DATA}/${masterData?.id}/form-mapping`, {
      query: { search, limit, page: pageNum }
    }).then(response => {
      const { status, data } = response
      if (status === 200) {
        setData(data?.data)
        setTotalData(data?.total_data)
        setTotalPage(data?.total_page)
        if (data?.total_page < pageNum) {
          setPageNum(1)
        }
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={'Failed to fetch form mapping data'} />
        )
      }
    })
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => {
              setPageNum(data)
            }}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    pageNum
  })

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 !== 0
  }

  const goToNextPage = () => {
    setPageNum(prev => {
      return prev + 1
    })
  }
  const goToPreviousPage = () => {
    setPageNum(prev => {
      return prev - 1
    })
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div
          className="modal-box bg-white max-w-full w-fit md:w-[760px] lg:w-[900px] h-fit flex flex-col"
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">
              Form Mapping - {masterData?.name}
            </div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />
          <div className="flex gap-6">
            <div className="flex flex-col justify-between p-4 bg-white rounded-2xl shadow-lg basis-5/12">
              <div className="flex flex-col gap-4">
                <div>
                  <div className="font-semibold text-xs mb-2">Form Name</div>
                  <AsyncSelect
                    components={{ DropdownIndicator }}
                    getOptionLabel={option => option?.title}
                    getOptionValue={option => option?.id}
                    onChange={form => setSelectedForm(form)}
                    value={selectedForm}
                    isSearchable={true}
                    isDisabled={isEditingMode}
                    className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Select form"
                    styles={{
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({})
                    }}
                    loadOptions={fetchForms}
                    noOptionsMessage={noOptionsMessage}
                  />
                </div>
                <div className="flex-row flex-1 rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
                  <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
                    <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
                      Option List
                    </div>
                    {selectedOptionList.length !== 0 ? (
                      <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
                        {selectedOptionList.length} data Selected
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex-row px-4 py-3">
                    <div className={`flex items-center relative mb-4 overflow-hidden`}>
                      <input
                        type="text"
                        placeholder="Search here"
                        className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                        value={searchOptionQuery}
                        onChange={event => {
                          setSearchOptionQuery(event.target.value)
                        }}
                      />
                      <img
                        src={ICONS.icSearch}
                        className="w-[14px] h-[14px] absolute left-[13px]"
                      />
                    </div>
                    <div className="flex-row max-h-[200px] min-h-[200px] overflow-y-auto scrollbar">
                      {optionList
                        .filter(option => {
                          return option?.name
                            .toLowerCase()
                            .match(new RegExp(searchOptionQuery.toLowerCase(), ''))
                        })
                        .map(option => (
                          <div key={option?.id} className="flex items-center justify-between mb-4">
                            <div className="flex flex-1 text-[12px]">{option?.name}</div>
                            <input
                              type="checkbox"
                              className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                              checked={selectedOptionList.includes(option?.id)}
                              onChange={event => handleOnChangeCheckOption(option?.id, event)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {isEditingMode ? (
                <div className="flex justify-end gap-4 mt-8">
                  <button
                    onClick={cancel}
                    className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    className="py-3 px-8 disabled:bg-gray-400 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                    onClick={() => handleEditFormMapping()}
                    disabled={!formMappingValidation()}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div className="flex justify-end mt-8">
                  <button
                    className="py-3 px-8 disabled:bg-gray-400 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                    onClick={() => handleAddFormMapping()}
                    disabled={!formMappingValidation()}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
            <div className="flex-col basis-7/12">
              <div className={`flex items-center relative mb-4 overflow-hidden`}>
                <input
                  type="text"
                  placeholder="Search form name here..."
                  className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                  value={search}
                  onChange={event => {
                    setSearch(event.target.value)
                  }}
                />
                <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" />
              </div>
              <div className="overflow-auto w-full max-h-[420px]">
                <table {...getTableProps()} className="w-full font-poppins text-[12px]">
                  <thead className="">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th key={index}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {!data?.length && (
                <div className="py-12">
                  <NoDataTable
                    message="No Form Mapping yet"
                    detailedMessage="The added data will be displayed here"
                  />
                </div>
              )}
              <hr />
              <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-4">
                {data?.length > 0 ? (
                  <div className="font-poppins text-[12px]">
                    Showing {pageNum * limit - (limit - 1)} -{' '}
                    {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
                  </div>
                ) : (
                  <div className="font-poppins text-[12px]">No data to show</div>
                )}
                <div className="flex font-poppins text-[12px]">
                  <button
                    onClick={() => goToPreviousPage()}
                    disabled={!canPreviousPage()}
                    className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                      !canPreviousPage()
                        ? 'bg-[#F2F5FC]'
                        : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
                    }`}
                  >
                    &lt;
                  </button>
                  <div className="bg-[#F2F5FC] rounded-full flex">
                    {showPaginationNumbers(totalPage)}
                  </div>
                  <button
                    onClick={() => goToNextPage()}
                    disabled={!canNextPage()}
                    className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                      !canNextPage()
                        ? 'bg-[#F2F5FC]'
                        : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
                    }`}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>

          <DeletePopup
            setContinueDelete={() => handleDeleteFormMapping()}
            buttonNext={'Delete Now'}
          />
        </div>
      </div>
    </>
  )
}
