import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'

import { Toast } from '../../../components'
import { toast } from 'react-toastify'
import { get, post, put } from '../../../api/base'
import { apiUrls, ICONS } from '../../../constants'
import { DropdownIndicator } from '../../../components'
import {
  setShowPopupLeavePage,
  setShowPopupSuccess,
  setShowPopupVerifyEmail,
  setMessage
} from '../../../redux/slices/popupSlice'
import LeavePagePopup from '../../../components/Popup/LeavePagePopup'
import SuccessPopup from '../../../components/Popup/SuccessPopup'
import VerifyEmailPopup from '../../../components/Popup/VerifyEmailPopup'
import { useSelector } from 'react-redux'
import { selectSites } from '../../../redux/slices/micrositeSlice'

const EditUserPage = () => {
  const { id, microsite, menu } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [user, setUser] = useState({
    username: '',
    full_name: '',
    groups: [],
    company: '',
    email: '',
    work_location: '',
    landing_page: '',
    timezone: '',
    is_active: false,
    is_external: false,
    is_vip: false,
    microsoft_teams: '',
    whatsapp_number: '',
    password: '',
    tags: [],
    other_company_ids: [],
    contractor_id: null,
    contractor: null,
    subcontractor: { id: null }
  })

  const { register, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      username: '',
      full_name: '',
      groups: [],
      company: '',
      email: '',
      work_location: '',
      landing_page: '',
      timezone: '',
      is_active: false,
      is_external: false,
      is_vip: false,
      microsoft_teams: '',
      whatsapp_number: '',
      password: '',
      tags: [],
      other_company_ids: [],
      contractor_id: null,
      subcon_id: null
    }
  })

  const [previousUserEmail, setPreviousUserEmail] = useState('')
  const [workLocationOptions, setWorkLocationOptions] = useState([])
  const [selectedWorkLocation, setSelectedWorkLocation] = useState(null)

  const [selectedRoleOption, setSelectedRoleOption] = useState(null)
  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null)
  const [selectedOtherCompanyOption, setSelectedOtherCompanyOption] = useState(null)
  const [selectedContractorOption, setSelectedContractorOption] = useState(null)
  const [selectedSubContractorOption, setSelectedSubContractorOption] = useState(null)
  const [selectedTagsOption, setSelectedTagsOption] = useState(null)
  const [isCheckedTag, setIsCheckedTag] = useState(false)

  const [roleOptions, setRoleOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])
  const [otherCompanyOptions, setOtherCompanyOptions] = useState([])
  const [contractorOptions, setContractorOptions] = useState([])
  const [subContractorOptions, setSubContractorOptions] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const [isExternal, setIsExternal] = useState(false)
  const [isVip, setIsVip] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const landingPageOptions = [
    { label: 'Home', value: 'Home' },
    { label: 'My Activity', value: 'My Activity' },
    { label: 'Task', value: 'Task' },
    { label: 'User Log', value: 'User Log' }
  ]
  const [selectedLandingPageOption, setSelectedLandingPageOption] = useState(null)
  const [timeZoneOptions, setTimeZoneOptions] = useState([])
  const [selectedTimeZone, setSelectedTimeZone] = useState(null)
  const [teamsChecked, setTeamsChecked] = useState(false)
  const [waChecked, setWaChecked] = useState(false)
  const location = useLocation()
  const isDuplicate = location?.pathname.includes('duplicate-user')
  const isMicrosite = location.pathname.includes('Site')
  const siteColor =
    useSelector(selectSites).find(s => s.route_name.toLowerCase() === microsite.toLowerCase())
      ?.top_color || (isMicrosite ? '#01a76b' : '')
  useEffect(() => {
    fetchContractors()
    fetchWorkLocations()
    fetchTimeZones()
    fetchRoles()
    fetchCompanies()
    fetchTags()
  }, [])

  const handleOnRoleChange = selectedOptions => {
    if (selectedOptions) {
      const roleIds = selectedOptions.map(selectedOption => selectedOption.value)
      setValue('groups', roleIds)
    } else {
      setValue('groups', [])
    }

    setSelectedRoleOption(selectedOptions)
  }

  const handleOnCompanyChange = selectedOption => {
    setValue('company', selectedOption ? selectedOption.value : '')
    setSelectedCompanyOption(selectedOption)

    //populate new list other company
    if (selectedOption) {
      const newOtherCompanyOption = []
      companyOptions.map(v => {
        if (selectedOption.id !== v.id) {
          newOtherCompanyOption.push(v)
        }
      })
      setOtherCompanyOptions(newOtherCompanyOption)
    } else {
      setOtherCompanyOptions(companyOptions)
    }
    setSelectedOtherCompanyOption([])
    setValue('other_company_ids', [])
  }

  const handleOnOtherCompanyChange = selectedOption => {
    const otherCompanies = []
    if (selectedOption.length) {
      selectedOption.map(v => {
        otherCompanies.push(v.id)
      })
    }

    setValue('other_company_ids', otherCompanies)
    setSelectedOtherCompanyOption(selectedOption)
  }

  const handleOnWorkLocationChange = selectedOption => {
    setSelectedWorkLocation(selectedOption)
    setValue('work_location', selectedOption ? selectedOption.value : null)
  }

  const handleOnLandingPageChange = selectedOption => {
    setSelectedLandingPageOption(selectedOption)
    setValue('landing_page', selectedOption ? selectedOption.value : null)
  }

  const handleOnTimeZoneChange = selectedOption => {
    setSelectedTimeZone(
      selectedOption ? { name: selectedOption.name, offset: selectedOption.timezone } : null
    )
    setValue(
      'timezone',
      selectedOption ? { name: selectedOption.name, offset: selectedOption.timezone } : null
    )
  }

  const handleOnStatusChange = event => {
    setValue('is_active', event.target.checked)
    setIsActive(event.target.checked)
  }

  const handleOnVipChange = event => {
    setValue('is_vip', event.target.checked)
    setIsVip(event.target.checked)
  }

  const handleOnExternalUserChange = event => {
    setValue('is_external', event.target.checked)
    setIsExternal(event.target.checked)

    if (event.target.checked) {
      setValue('is_vip', false)
      setValue('microsoft_teams', '')
      setTeamsChecked(false)
      if (isDuplicate) {
        setIsActive(false)
      }
    }
  }

  const handleOnContractorChange = selectedOption => {
    setValue('contractor_id', selectedOption ? selectedOption.id : null)
    setSelectedContractorOption(selectedOption)
    setValue('subcon_id', null)
    setSelectedSubContractorOption(null)

    const newSubContractorList = []
    if (selectedOption) {
      const childContractor = contractorOptions.find(v => v.id === selectedOption.id).children
      childContractor.map(v => {
        newSubContractorList.push({ ...v, label: v.name, value: v.id })
      })
    }

    setSubContractorOptions(newSubContractorList)
  }

  const handleOnSubContractorChange = selectedOption => {
    setValue('subcon_id', selectedOption ? selectedOption.id : null)
    setSelectedSubContractorOption(selectedOption)
  }

  const onChangeTags = selectedOptions => {
    const tag_names = []
    if (selectedOptions.length) {
      selectedOptions.map(v => {
        tag_names.push(v.value)
      })
    }
    setValue('tags', tag_names)
    setSelectedTagsOption(selectedOptions)
  }

  const handleOnPasswordChange = text => {
    setValue('password', text)
  }

  const handleOnTeamsCheckboxChange = event => {
    setTeamsChecked(event.target.checked)
    if (event.target.checked) {
      if (getValues('email').includes('@banpuindo.co.id')) {
        setValue('microsoft_teams', getValues('email'))
      }
    } else {
      setValue('microsoft_teams', '')
    }
  }

  const handleOnWaCheckboxChange = event => {
    setWaChecked(event.target.checked)
    if (!event.target.checked) {
      setValue('whatsapp_number', '')
    }
  }

  const fetchUser = listContractors => {
    get(`/api/users/manage-user/${id}`).then(response => {
      const { status, data } = response
      if (status === 200) {
        setUser(data)
        setTeamsChecked(data.microsoft_teams && data.microsoft_teams !== '')
        setWaChecked(data.whatsapp_number && data.whatsapp_number !== '')
        setPreviousUserEmail(data.email)

        setValue('full_name', data?.full_name)
        if (!isDuplicate) {
          setValue('username', data?.username)
          setValue('email', data?.email)
        }

        // Populate selected role
        const roles = []
        const roleIds = []
        data.groups.map(role => {
          roles.push({ value: role.id, label: role.name })
          roleIds.push(role.id)
        })
        setSelectedRoleOption(roles)
        setValue('groups', roleIds)

        // Populate selected company
        if (data?.company) {
          setSelectedCompanyOption({ value: data.company, label: data.company })
          setValue('company', data.company)

          if (data.company) {
            const newOtherCompanyOption = []
            companyOptions.map(v => {
              if (data.company !== v.value) {
                newOtherCompanyOption.push(v)
              }
            })
            setOtherCompanyOptions(newOtherCompanyOption)
          } else {
            setOtherCompanyOptions(companyOptions)
          }
        }

        // Populate selected other company
        if (data?.user_company) {
          const otherCompanies = []
          const otherCompanyIds = []
          data.user_company.map(v => {
            if (data.company !== v.company.name) {
              otherCompanies.push({ ...v.company, value: v.company.id, label: v.company.name })
              otherCompanyIds.push(v.company.id)
            }
          })
          setSelectedOtherCompanyOption(otherCompanies)
          setValue('other_company_ids', otherCompanyIds)
        }

        // Populate selected work location
        if (data?.work_location) {
          setSelectedWorkLocation({ label: data.work_location, value: data.work_location })
          setValue('work_location', data.work_location)
        }

        // Populate selected landing page
        if (data?.landing_page) {
          setSelectedLandingPageOption({ label: data.landing_page, value: data.landing_page })
          setValue('landing_page', data.landing_page)
        }

        // Populate selected contractor
        setValue('is_external', data?.is_external)
        setIsExternal(data?.is_external)
        if (data?.contractor) {
          setSelectedContractorOption({
            ...data?.contractor,
            label: data.contractor.name,
            value: data.contractor.id
          })
          setValue('contractor_id', data.contractor.id)
        }

        // Populate selected tag
        if (data?.tags && data?.tags.length) {
          setIsCheckedTag(true)
          const tags = data.tags.map(v => {
            return { value: v, label: v }
          })
          setSelectedTagsOption(tags)
          setValue('tags', data?.tags)
        }

        // Populate timezone value
        if (data?.timezone) {
          setValue('timezone', data?.timezone)
        }

        setValue('is_active', data?.is_active)
        setIsActive(data?.is_active)
        if (isDuplicate && data?.is_external) {
          setIsActive(false)
          setValue('is_active', false)
        }

        setValue('is_vip', data?.is_vip)
        setIsVip(data?.is_vip)

        setValue('microsoft_teams', data?.microsoft_teams)
        setValue('whatsapp_number', data?.whatsapp_number)

        // Populate selected sub contractor
        if (data?.subcontractor) {
          setSelectedSubContractorOption({
            ...data?.subcontractor,
            label: data.subcontractor.name,
            value: data.subcontractor.id
          })
          setValue('subcon_id', data.subcontractor.id)

          let contractors = listContractors.find(v => v.id === data.contractor.id).children

          const subContOptions = []
          contractors.map(v => {
            subContOptions.push({ ...v, label: v.name, value: v.id })
          })
          setSubContractorOptions(subContOptions)
        }
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Error while getting user detail. ${data.error.message}`}
          />
        )
      }
    })
  }

  const fetchContractors = () => {
    const query = {
      query: {
        child_master_data_id: 2
      }
    }
    get(`${apiUrls.MASTER_DATA}/2/details`, query).then(response => {
      const { ok, data } = response
      if (ok) {
        const listContractors = []
        data.map(v => {
          listContractors.push({
            ...v,
            label: v.name,
            value: v.id
          })
        })
        setContractorOptions(listContractors)
        fetchUser(listContractors)
      } else {
        ;<Toast message={`Error`} detailedMessage={'Failed to fetch contractor list.'} />
      }
    })
  }

  const fetchWorkLocations = () => {
    get(`/api/users/organization-master`, {
      query: { organization_column: 'WORK_LOCATION' }
    }).then(response => {
      const { status, data } = response
      if (status === 200) {
        populateWorkLocationDropdown(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch work locations'} />)
      }
    })
  }

  const fetchTimeZones = () => {
    get(apiUrls.TIMEZONE_LIST, { query: '' }).then(response => {
      const { status, data } = response
      if (status === 200) {
        setTimeZoneOptions(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch time zone list'} />)
      }
    })
  }

  const fetchRoles = () => {
    get(`/api/groups`).then(response => {
      const { status, data } = response
      if (status === 200) {
        populateRolesDropdown(data.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch roles'} />)
      }
    })
  }

  const fetchCompanies = () => {
    get(`/api/users/organization-master`, {
      query: { organization_column: 'COMPANY' }
    }).then(response => {
      const { status, data } = response
      if (status === 200) {
        populateCompaniesDropdown(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch companies'} />)
      }
    })
  }

  const fetchTags = () => {
    get(apiUrls.TAGS).then(response => {
      const { ok, data } = response
      if (ok) {
        const listTags = []
        data.map(v => {
          listTags.push({
            ...v,
            label: v.name,
            value: v.name
          })
        })

        setTagOptions(listTags)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch tags'} />)
      }
    })
  }

  const populateWorkLocationDropdown = data => {
    const workLocations = data.map(workLocation => {
      if (workLocation != null) {
        return { value: workLocation.name, label: workLocation.name }
      } else {
        return { value: '', label: '' }
      }
    })
    setWorkLocationOptions(workLocations)
  }

  useEffect(() => {
    if (user?.timezone && timeZoneOptions.length) {
      // Populate selected timezone
      let timezone = timeZoneOptions.find(
        item => item.name === user.timezone?.name && item.timezone === user.timezone?.offset
      )
      if (timezone) {
        setSelectedTimeZone({ name: timezone.name, offset: timezone.timezone })
      }
    }
  }, [timeZoneOptions, user])

  const populateRolesDropdown = data => {
    const roles = data.map(role => {
      return { value: role.id, label: role.name }
    })
    setRoleOptions(roles)
  }

  const populateCompaniesDropdown = data => {
    const companies = data.map(company => {
      return { value: company.name, label: company.name, id: company.id }
    })
    setCompanyOptions(companies)
    setOtherCompanyOptions(companies)
  }

  const handleLeavePage = () => {
    dispatch(setShowPopupLeavePage(true))
  }

  const onSubmit = form => {
    if (userValidation(form)) {
      if (isDuplicate) {
        submitUser(form)
      } else {
        editUser(form)
      }
    }
  }

  const handleOnSuccessSubmitUser = () => {
    dispatch(setShowPopupVerifyEmail(true))
    dispatch(setMessage(getValues('email')))
  }

  const submitUser = form => {
    const payload = { ...form }

    if (!isExternal) {
      payload.contractor_id = null
    } else {
      payload.microsoft_teams = ''
    }

    delete payload.password
    if (!isCheckedTag) payload.tags = []

    post(`/api/users/manage-user`, payload).then(response => {
      const { status, data } = response
      if (status === 200) {
        handleOnSuccessSubmitUser()
      } else if (status === 422) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Error adding user. ${
              data.error?.errors[0][0]?.message || data.error?.errors[0]?.message
            }`}
          />
        )
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={`Error adding user. ${data.error.message}`} />
        )
      }
    })
  }

  const editUser = form => {
    const payload = { ...form }

    if (!isExternal) {
      payload.contractor_id = null
      delete payload.password
    } else {
      payload.microsoft_teams = ''
    }

    if (!isCheckedTag) payload.tags = []

    put(`/api/users/manage-user/${id}`, payload).then(response => {
      const { ok, status, data } = response
      if (ok) {
        handleOnSuccessEditUser()
      } else if (status === 422) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Error editing user. ${
              data.error?.errors[0][0]?.message || data.error?.errors[0]?.message
            }`}
          />
        )
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={`Error editing user. ${data.error.message}`} />
        )
      }
    })
  }

  const handleOnSuccessEditUser = () => {
    if (previousUserEmail === user.email) {
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully edit user'))
    } else {
      dispatch(setShowPopupVerifyEmail(true))
      dispatch(setMessage(user.email))
    }
  }

  const userValidation = form => {
    let isUserValid = true
    // Text Input Validation
    const textInputs = document.getElementsByClassName('input-text')
    Array.from(textInputs).forEach(function (input) {
      if (
        input.value === '' &&
        input.name !== 'microsoft_teams' &&
        input.name !== 'Password' &&
        input.name !== 'whatsapp_number'
      ) {
        isUserValid = false
        toast.error(
          <Toast message={`Error`} detailedMessage={`${input.name} should not be empty`} />
        )
        input.focus()
      }
    })

    if (form.groups.length === 0) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Role should not be empty`} />)
    }

    if (!isExternal && form.company.length === 0) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Company should not be empty`} />)
    }

    if (!form.work_location) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Work Location should not be empty`} />)
    }

    if (!form.landing_page) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Landing Page should not be empty`} />)
    }

    if (isCheckedTag && form.tags.length < 1) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Tags should not be empty`} />)
    }

    return isUserValid
  }

  const handleOnSuccessPopupClose = () => {
    if (isMicrosite) {
      navigate(`/Site/${microsite}/menu/${menu}`, {
        state: { title: 'Manage User', crumbs: ['Manage User'] }
      })
    } else {
      navigate('/Admin/ManageUser', {
        state: { title: 'Manage User', crumbs: ['Admin', 'Manage User'] }
      })
    }
  }

  const handleOnClickResendEmail = () => {
    resendEmail()
  }

  const resendEmail = () => {
    post(`/api/users/${id}/resend-email`).then(response => {
      const { status, data } = response
      if (status === 200) {
        handleOnSuccessResendEmail()
      } else {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Failed to resend link. ${data.error.message}`}
          />
        )
      }
    })
  }

  const handleOnSuccessResendEmail = () => {
    dispatch(setShowPopupVerifyEmail(true))
    dispatch(setMessage(user.email))
  }

  const timer = useRef()
  const [countDown, setCountDown] = useState(0)

  useEffect(() => {
    if (!user?.last_email_sent_at) return () => {}

    timer.current = setInterval(() => {
      const now = new Date()
      const lastSend = new Date(user?.last_email_sent_at)
      const allowResendTime = lastSend.getTime() + 1000 * 60 * 3
      const timeLeft = allowResendTime - now.getTime()
      setCountDown(timeLeft)
    }, 1000)

    return () => {
      clearInterval(timer.current)
    }
  }, [user])

  useEffect(() => {
    if (countDown <= 0) {
      clearInterval(timer.current)
    }
  }, [countDown])

  const formatTimeToDate = time => {
    if (time > 0) {
      let minutes = Math.floor(time / 60000)
      let seconds = Math.floor((time - minutes * 60000) / 1000)
      if (minutes < 10) minutes = '0' + minutes
      if (seconds < 10) seconds = '0' + seconds

      return `${minutes}:${seconds}`
    } else {
      return '00:00'
    }
  }

  return (
    <div className="flex-row p-2 sm:p-6">
      <div className="flex flex-col justify-evenly gap-6">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col py-4 rounded-2xl">
          <div className="flex-1 bg-white p-6 rounded-2xl">
            <div className="flex flex-col md:flex-row justify-between items-center mb-6">
              <div className="font-poppins font-semibold text-[16px] text-[#C800A5]">User Info</div>
              {user?.last_email_sent_at && !isDuplicate ? (
                <div className="flex flex-row items-center gap-4">
                  <div className="flex flex-row gap-2 items-center p-2 rounded-lg bg-[#EBEDF8]">
                    <img src={ICONS.icClock} className="w-6 h-6" alt="" />
                    <div className="text-[#6546C3] text-[12px] font-semibold">
                      {formatTimeToDate(countDown)}
                    </div>
                  </div>
                  <button
                    className={`py-3 px-8 text-white text-[12px] font-semibold rounded-md`}
                    style={{
                      background:
                        countDown > 0
                          ? '#6b7280'
                          : siteColor || 'linear-gradient(to right, #6546C3, #9E7CE3)'
                    }}
                    onClick={handleOnClickResendEmail}
                    disabled={countDown > 0}
                  >
                    Resend Verification Link
                  </button>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Display Name</div>
                <input
                  type="text"
                  name="Display Name"
                  className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Enter display name"
                  defaultValue={getValues('full_name')}
                  {...register('full_name', { required: true })}
                />
              </div>
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Username</div>
                <input
                  type="text"
                  name="Username"
                  className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Enter username"
                  defaultValue={isDuplicate ? '' : getValues('username')}
                  disabled={!isDuplicate}
                  {...register('username', { required: true })}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Role</div>
                <Select
                  components={{ DropdownIndicator }}
                  value={selectedRoleOption}
                  onChange={selectedOptions => handleOnRoleChange(selectedOptions)}
                  options={roleOptions}
                  name="Role"
                  className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select role"
                  isMulti={true}
                  styles={{
                    option: provided => ({
                      ...provided
                    }),
                    control: provided => ({
                      ...provided,
                      minHeight: '40px',
                      borderColor: '#DEDEDE',
                      borderRadius: '6px',
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      paddingTop: 2,
                      paddingBottom: 2,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: provided => ({
                      ...provided,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({}),
                    valueContainer: provided => ({
                      ...provided
                    }),
                    multiValueLabel: provided => ({
                      ...provided,
                      color: '#fff',
                      fontFamily: 'poppins'
                    }),
                    multiValueRemove: provided => ({
                      ...provided,
                      color: '#fff'
                    }),
                    multiValue: provided => ({
                      ...provided,
                      backgroundColor: '#6546C3',
                      borderRadius: 8
                    })
                  }}
                />
              </div>
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Email</div>
                <input
                  type="text"
                  name="Email"
                  className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Ex: abcd@banpuindo.co.id"
                  defaultValue={isDuplicate ? '' : getValues('email')}
                  disabled={!isExternal}
                  {...register('email', { required: true })}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Company</div>
                <Select
                  components={{ DropdownIndicator }}
                  value={selectedCompanyOption}
                  onChange={selectedOptions => handleOnCompanyChange(selectedOptions)}
                  options={companyOptions}
                  name="Company"
                  className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select company"
                  isMulti={false}
                  isClearable={true}
                  styles={{
                    option: provided => ({
                      ...provided
                    }),
                    control: provided => ({
                      ...provided,
                      minHeight: '40px',
                      borderColor: '#DEDEDE',
                      borderRadius: '6px',
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      paddingTop: 2,
                      paddingBottom: 2,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: provided => ({
                      ...provided,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({}),
                    valueContainer: provided => ({
                      ...provided
                    }),
                    multiValueLabel: provided => ({
                      ...provided,
                      color: '#fff',
                      fontFamily: 'poppins'
                    }),
                    multiValueRemove: provided => ({
                      ...provided,
                      color: '#fff'
                    }),
                    multiValue: provided => ({
                      ...provided,
                      backgroundColor: '#6546C3',
                      borderRadius: 8
                    })
                  }}
                />
              </div>
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Other Company</div>
                <Select
                  components={{ DropdownIndicator }}
                  value={selectedOtherCompanyOption}
                  onChange={selectedOptions => handleOnOtherCompanyChange(selectedOptions)}
                  options={otherCompanyOptions}
                  name="Company"
                  className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select other company"
                  isMulti={true}
                  styles={{
                    option: provided => ({
                      ...provided
                    }),
                    control: provided => ({
                      ...provided,
                      minHeight: '40px',
                      borderColor: '#DEDEDE',
                      borderRadius: '6px',
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      paddingTop: 2,
                      paddingBottom: 2,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: provided => ({
                      ...provided,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({}),
                    valueContainer: provided => ({
                      ...provided
                    }),
                    multiValueLabel: provided => ({
                      ...provided,
                      color: '#fff',
                      fontFamily: 'poppins'
                    }),
                    multiValueRemove: provided => ({
                      ...provided,
                      color: '#fff'
                    }),
                    multiValue: provided => ({
                      ...provided,
                      backgroundColor: '#6546C3',
                      borderRadius: 8
                    })
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Work Location</div>
                <Select
                  components={{ DropdownIndicator }}
                  value={selectedWorkLocation}
                  onChange={selectedOption => handleOnWorkLocationChange(selectedOption)}
                  options={workLocationOptions}
                  name="Work Location"
                  className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select work location"
                  isClearable={true}
                  styles={{
                    control: provided => ({
                      ...provided,
                      height: '40px',
                      borderColor: '#DEDEDE',
                      borderRadius: '6px',
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({})
                  }}
                />
              </div>
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Landing Page</div>
                <Select
                  components={{ DropdownIndicator }}
                  value={selectedLandingPageOption}
                  onChange={selectedOption => handleOnLandingPageChange(selectedOption)}
                  options={landingPageOptions}
                  name="Landing Page"
                  className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select landing page"
                  isSearchable={false}
                  isClearable={true}
                  styles={{
                    control: provided => ({
                      ...provided,
                      height: '40px',
                      borderColor: '#DEDEDE',
                      borderRadius: '6px',
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({})
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-2">Time Zone</div>
                <Select
                  components={{ DropdownIndicator }}
                  value={timeZoneOptions?.find(
                    item =>
                      item.name === selectedTimeZone?.name &&
                      item.timezone === selectedTimeZone?.offset
                  )}
                  onChange={selectedOptions => handleOnTimeZoneChange(selectedOptions)}
                  options={timeZoneOptions}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  name="Time Zone"
                  className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select time zone"
                  isClearable={true}
                  styles={{
                    control: provided => ({
                      ...provided,
                      height: '40px',
                      borderColor: '#DEDEDE',
                      borderRadius: '6px',
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({})
                  }}
                />
              </div>
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-4">Is Active</div>
                <label
                  htmlFor={`is-user-active`}
                  className="flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id={`is-user-active`}
                    className="sr-only peer"
                    checked={isActive}
                    disabled={isExternal && isDuplicate}
                    onChange={event => handleOnStatusChange(event)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                </label>
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
              <div className="flex flex-1">
                <div className="flex flex-col w-28">
                  <div className="font-poppins font-semibold text-[12px] mb-4">External User</div>
                  <input
                    type="checkbox"
                    checked={isExternal}
                    disabled={!isDuplicate}
                    className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-[#6546C3]"
                    onChange={event => handleOnExternalUserChange(event)}
                  />
                </div>
                <div className={`${isExternal ? 'visible' : 'invisible'} flex-1 ml-3`}>
                  <div className="font-poppins font-semibold text-[12px] mb-2">Contractor</div>
                  <Select
                    components={{ DropdownIndicator }}
                    value={selectedContractorOption}
                    onChange={selectedOption => handleOnContractorChange(selectedOption)}
                    options={contractorOptions}
                    name="Contractor"
                    className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Select contractor"
                    isClearable={true}
                    isDisabled={!isExternal}
                    styles={{
                      control: provided => ({
                        ...provided,
                        height: '40px',
                        borderColor: '#DEDEDE',
                        borderRadius: '6px',
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({})
                    }}
                  />
                  <div className="font-poppins font-semibold text-[12px] my-2">Sub Contractor</div>
                  <Select
                    components={{ DropdownIndicator }}
                    value={selectedSubContractorOption}
                    onChange={selectedOption => handleOnSubContractorChange(selectedOption)}
                    options={subContractorOptions}
                    name="Contractor"
                    className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Select sub contractor"
                    isClearable={true}
                    isDisabled={
                      !isExternal ||
                      !selectedContractorOption ||
                      !contractorOptions.find(v => v.id === selectedContractorOption.id).children
                        .length
                    }
                    styles={{
                      control: provided => ({
                        ...provided,
                        height: '40px',
                        borderColor: '#DEDEDE',
                        borderRadius: '6px',
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({})
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-4">VIP</div>
                <input
                  type="checkbox"
                  className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-[#6546C3]"
                  disabled={isExternal}
                  checked={isVip}
                  onChange={event => handleOnVipChange(event)}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
              <div className="flex flex-1">
                <div className="flex flex-col w-28">
                  <div className="font-poppins font-semibold text-[12px] mb-4">Tags</div>
                  <input
                    type="checkbox"
                    checked={isCheckedTag}
                    className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-[#6546C3]"
                    onChange={event => setIsCheckedTag(event.target.checked)}
                  />
                </div>
                <div className={`${isCheckedTag ? 'visible' : 'invisible'} grow ml-3`}>
                  <div className="font-poppins font-semibold text-[12px] mb-2">
                    Search or Add New Tag
                  </div>
                  <div className="flex">
                    <CreatableSelect
                      options={tagOptions}
                      isMulti={true}
                      value={selectedTagsOption}
                      onChange={selectedOption => onChangeTags(selectedOption)}
                      className={`grow input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                      placeholder="Type here..."
                      isDisabled={!isCheckedTag}
                      styles={{
                        option: provided => ({
                          ...provided
                        }),
                        control: provided => ({
                          ...provided,
                          minHeight: '40px',
                          borderColor: '#DEDEDE',
                          borderRadius: '6px',
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          paddingTop: 2,
                          paddingBottom: 2,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: provided => ({
                          ...provided,
                          color: '#C800A5',
                          ':hover': { color: '#6546C3' }
                        }),
                        indicatorSeparator: () => ({}),
                        valueContainer: provided => ({
                          ...provided
                        }),
                        multiValueLabel: provided => ({
                          ...provided,
                          color: '#fff',
                          fontFamily: 'poppins'
                        }),
                        multiValueRemove: provided => ({
                          ...provided,
                          color: '#fff'
                        }),
                        multiValue: provided => ({
                          ...provided,
                          backgroundColor: '#6546C3',
                          borderRadius: 8
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1">
                {isExternal && !isDuplicate && (
                  <div className="flex-1 flex-col md:flex-row mb-4">
                    <div className="basis-1/2">
                      <div className="font-poppins font-semibold text-[12px] mb-2">Password</div>
                      <input
                        type="password"
                        name="Password"
                        defaultValue={getValues('password')}
                        onChange={event => handleOnPasswordChange(event.target.value)}
                        autoComplete="new-password"
                        className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex-1 bg-white p-6 rounded-2xl">
            <div className="font-poppins font-semibold text-[16px] text-[#C800A5] mb-6">
              Connected Chat
            </div>
            <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
              <div className="flex-1">
                <div className="flex flex-row gap-2 mb-1">
                  <div className="font-poppins font-semibold text-[12px] flex items-center">
                    Microsoft Teams
                  </div>
                  <label
                    htmlFor={`is-teams-active`}
                    className="flex relative items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      value=""
                      id={`is-teams-active`}
                      className="sr-only peer"
                      disabled={isExternal}
                      checked={teamsChecked}
                      onChange={event => handleOnTeamsCheckboxChange(event)}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                  </label>
                </div>
                <div
                  className={`flex w-full h-[40px] font-poppins border-[1px] items-center p-[12px] rounded-lg ${
                    isExternal || !teamsChecked ? 'bg-[#F7F5F9]' : ''
                  }`}
                >
                  <img src={ICONS.icTeams} alt="Microsoft Teams" className="w-[24px] h-[24px]" />
                  <input
                    type="text"
                    defaultValue={getValues('microsoft_teams')}
                    name="Microsoft Teams"
                    className={`input-text text-[12px] w-full focus:outline-none focus:border-[#6546C3] ml-2 disabled:bg-[#F7F5F9]`}
                    placeholder="Enter microsoft teams link"
                    disabled={isExternal || !teamsChecked}
                    {...register('microsoft_teams')}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div className="flex flex-row gap-2 mb-1">
                  <div className="font-poppins font-semibold text-[12px] flex items-center">
                    WhatsApp Number
                  </div>
                  <label
                    htmlFor={`is-wa-active`}
                    className="flex relative items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      value=""
                      id={`is-wa-active`}
                      className="sr-only peer"
                      checked={waChecked}
                      onChange={event => handleOnWaCheckboxChange(event)}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                  </label>
                </div>
                <div
                  className={`flex w-full h-[40px] font-poppins border-[1px] items-center p-[12px] rounded-lg ${
                    !waChecked ? 'bg-[#F7F5F9]' : ''
                  }`}
                >
                  <img src={ICONS.icWa} alt="WhatsApp" className="w-[24px] h-[24px]" />
                  <input
                    type="text"
                    defaultValue={getValues('whatsapp_number')}
                    name="WhatsApp Number"
                    className={`input-text text-[12px] w-full focus:outline-none focus:border-[#6546C3] ml-2 disabled:bg-[#F7F5F9]`}
                    placeholder="Ex: 081509870987"
                    disabled={!waChecked}
                    {...register('whatsapp_number')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-4 mt-10">
            <button
              type="button"
              onClick={() => handleLeavePage()}
              className="py-3 px-8 border-[1px] text-[12px] font-semibold rounded-md"
              style={{
                borderColor: siteColor || '#6546C3',
                color: siteColor || '#6546C3'
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="py-3 px-8 text-white text-[12px] font-semibold rounded-md"
              style={{
                background: siteColor || 'linear-gradient(to right, #6546C3, #9E7CE3)'
              }}
            >
              Save
            </button>
          </div>
        </form>
      </div>

      <LeavePagePopup
        onLeavePage={() => {
          if (isMicrosite) {
            navigate(`/Site/${microsite}/menu/${menu}`, {
              state: { title: 'Manage User', crumbs: ['Manage User'] }
            })
          } else {
            navigate('/Admin/ManageUser', {
              state: { title: 'Manage User', crumbs: ['Admin', 'Manage User'] }
            })
          }
        }}
      />
      <SuccessPopup onClosePopup={handleOnSuccessPopupClose} />
      <VerifyEmailPopup />
    </div>
  )
}

export default EditUserPage
