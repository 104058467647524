import { components } from 'react-select'
import { ICONS } from '../../constants'

export const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={ICONS.icArrowDown} className="w-3 mr-1" />
      </components.DropdownIndicator>
    )
  )
}
