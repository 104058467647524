import { useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { ICONS, images } from '../../../../../constants'
import Field from '../Field'
import { v4 as uuidv4 } from 'uuid'

const SubSection = ({ index, data, editable, onDelete, handleChangeSection, firstIdx }) => {
  const [title, setTitle] = useState(data.section_title)
  const [multipleInput, setMultipleInput] = useState(data.multiple_input)
  const [fields, setFields] = useState(data.fields)
  const subSectionData = useRef(data)
  const subSectionIdx = useRef(index)

  const handleOnTitleChange = event => {
    subSectionData.current.section_title = event.target.value
    handleChangeSection(
      'sub_sections',
      '',
      firstIdx,
      subSectionIdx.current,
      null,
      subSectionData.current
    )
    setTitle(event.target.value)
  }

  const handleOnMultiInputChange = event => {
    subSectionData.current.multiple_input = event.target.checked
    handleChangeSection(
      'sub_sections',
      '',
      firstIdx,
      subSectionIdx.current,
      null,
      subSectionData.current
    )
    setMultipleInput(event.target.checked)
  }

  const handleOnDragEnd = result => {
    if (!result.destination) return
    const items = Array.from(fields)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    subSectionData.current.fields = items
    handleChangeSection(
      'sub_sections',
      '',
      firstIdx,
      subSectionIdx.current,
      null,
      subSectionData.current
    )
    setFields(items)
  }

  const handleOnDeleteField = index => {
    const newFields = fields.filter((field, i) => i !== index)
    subSectionData.current.fields = newFields
    handleChangeSection(
      'sub_sections',
      '',
      firstIdx,
      subSectionIdx.current,
      null,
      subSectionData.current
    )
    setFields(newFields)
  }

  const handleOnAddField = () => {
    const fieldId = uuidv4()
    const newField = {
      field_id: fieldId,
      field_title: null,
      field_type: null,
      keyboard_type: '',
      format: '',
      additional_option: [],
      value: '',
      options: [],
      is_segregate_by_tags: false,
      tags: []
    }
    const newFields = [...fields, newField]
    subSectionData.current.fields = newFields
    handleChangeSection(
      'sub_sections',
      '',
      firstIdx,
      subSectionIdx.current,
      null,
      subSectionData.current
    )
    setFields(newFields)
    return {
      parentType: 'sub_sections',
      sectionIdx: firstIdx,
      subSectionIdx: subSectionIdx.current,
      fieldIdx: newFields.length - 1,
      newField
    }
  }

  return (
    <div className="flex-1 p-6 bg-white rounded-2xl">
      <div className="flex flex-col flex-1 p-6 bg-[#F2F5FC] rounded-2xl gap-2">
        <div className="font-semibold text-[12px]">Sub Section Title</div>
        <div className="flex items-center justify-between gap-4">
          <input
            type="text"
            name="Sub Section Title"
            className={`input-text flex-1 text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            onChange={handleOnTitleChange}
            value={title}
          />
          <img
            src={ICONS.icTrash}
            className="w-8 h-8 cursor-pointer"
            onClick={editable ? () => onDelete(index) : () => {}}
          />
        </div>
      </div>
      <hr className="mt-4 mb-4" />
      {fields.length === 0 ? (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" />
          <div className="font-semibold text-[18px] mb-2">You’ve not added any field yet</div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "add field” button to add it now!
          </div>
          <button
            onClick={handleOnAddField}
            disabled={!editable}
            className={`${
              !editable ? 'btn-disabled' : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
            } py-3 px-6 text-white text-[12px] font-semibold rounded-md`}
          >
            Add Field
          </button>
        </div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="fields" isDropDisabled={!editable}>
            {provided => (
              <div
                className="flex flex-col gap-4"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {fields.map((field, index) => (
                  <Draggable
                    key={field.field_id}
                    draggableId={field.field_id}
                    index={index}
                    isDragDisabled={!editable}
                  >
                    {provided => (
                      <div
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <Field
                          index={index}
                          data={field}
                          editable={editable}
                          onDelete={handleOnDeleteField}
                          handleChangeSection={handleChangeSection}
                          firstIdx={firstIdx}
                          secondIdx={subSectionIdx.current}
                          parentName={'sub_sections'}
                          handleOnAddField={handleOnAddField}
                          isSubSection={true}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <button
                  onClick={handleOnAddField}
                  disabled={!editable}
                  className={`${
                    !editable ? 'btn-disabled' : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
                  } my-[10px] max-w-[140px] py-3 px-8 text-white text-[12px] font-semibold rounded-md`}
                >
                  Add Field
                </button>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  )
}

export default SubSection
