import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Select, Toast, DropdownIndicator, DeletePopup } from '../../../../../components'
import { ICONS, apiUrls } from '../../../../../constants'

import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import ModalWorkFlow from '../../../../../components/Popup/WorkflowListPopup'
import {
  setShowWorkflowList,
  setShowPopupSuccess,
  setMessage,
  setShowPopupDelete
} from '../../../../../redux/slices/popupSlice'
import SuccessPopup from '../../../../../components/Popup/SuccessPopup'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import PrintApprovalModal from '../PrintApprovalModal'
import AddParamModal from '../AddParamModal'
import ConfirmationPopup from '../../../../../components/Popup/ConfirmationPopup'
import CustomTabletLayout from '../CustomTabletLayout'
import moment from 'moment'
import LinkageModal from '../LinkageModal'
import NoDataTable from '../../../../../components/NoDataTable'
import { useTable } from 'react-table'
import { get } from '../../../../../api/base'

const PERIODS = [
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Monthly', value: 'MONTHLY' }
]

const WEEKLY_DAYS_BEFORE_END = Array.from(Array(7)).map((_, n) => ({
  label: n + 1,
  value: n + 1
}))
const MONTHLY_DAYS_BEFORE_END = Array.from(Array(30)).map((_, n) => ({
  label: n + 1,
  value: n + 1
}))
const LAST_SUBMISSION_DATES = Array.from(Array(25)).map((_, n) => ({ label: n + 1, value: n + 1 }))

export default function AdvancedSettingsModal({
  showModal,
  setShowModal,
  settings,
  otherFormData,
  sections,
  sectionsOriginal,
  tags,
  workflowModules,
  onSubmit
}) {
  const { register, control, getValues, setValue, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      // advance setting data
      is_repetitive_checklist: false,
      period: 'DAILY',
      remind_at_days_before_end_period: { label: 1, value: 1 },
      remind_at_time: '',
      last_submission_date: { label: 1, value: 1 },
      // form data
      isPoint: false,
      point: null,
      running_number: { title: '', format: '' },
      is_segregate_by_tags: false,
      tags: [],
      location: false,
      isWorkflow: false,
      module_id: {},
      assign_to: [],
      title: '',
      print_approval_format: { format: '', params: [], exclude_box_approval: false },
      params_wfe: {
        params: [],
        doa_value: ''
      },
      different_requestor: false,
      image_resolution: null,
      form_size: null,
      form_method: null,
      form_type: null,
      custom_tablet_layout: null,
      linkage: null,
      api: []
    }
  })

  const form = watch()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('form')
  const [disableUser] = useState(false)
  const helpContent = `<div style="font-size:12px;">{yyyy}/{mmm}/{company}/{SEQ-XXX} -> 2023/JUN/TCM/001<br />
  {company}-{yyyy}-{MM}-{SEQ-XXXXX} -> TCM-2023-06-00001<br />
  LGL-{company}-{yyyy}-{MM}-{SEQ-XXXXX} -> LGL-TCM-2023-06-00001<br />
  {yyyy}{MM}{dd}-{SEQ-XXXX} -> 20230626-0001</div><br /><div style="font-weight:600;">Note:</div><div style="font-size:12px;">{Company} will required there is 
  field type of Master Data:Company <br />should exist in particular form creation, and show as Company alias in <br />the generated running number.</div>`
  const [showPrintApprovalModal, setShowPrintApprovalModal] = useState(false)
  const [printApprovalFormat, setPrintApprovalFormat] = useState(form.print_approval_format)
  const [showAddParamModal, setShowAddParamModal] = useState(false)
  const [paramModalTitle, setParamModalTitle] = useState('')
  const [addParamChecked, setAddParamChecked] = useState(false)
  const [fieldsParam, setFieldsParam] = useState([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [paramsWfe, setParamsWfe] = useState(null)
  const [differentRequestor, setDifferentRequestor] = useState(false)
  const imageResolutionOption = [
    { name: 'Good', width: 1920, height: 1200 },
    { name: 'Medium', width: 1200, height: 800 },
    { name: 'Minimum', width: 640, height: 480 }
  ]

  useEffect(() => {
    setValue('print_approval_format', printApprovalFormat)
  }, [printApprovalFormat])

  const getStringHtml = html => {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  useEffect(() => {
    if (form.period === 'DAILY')
      setValue('remind_at_days_before_end_period', { label: 1, value: 1 })
    if (form.period === 'WEEKLY')
      setValue('remind_at_days_before_end_period', { label: 7, value: 7 })
  }, [form.period, setValue])

  useEffect(() => {
    if (showModal) {
      const {
        point,
        running_number,
        print_approval_format,
        params_wfe,
        different_requestor,
        module_id,
        ...rest
      } = otherFormData

      if (params_wfe) {
        setAddParamChecked(true)
      } else {
        setAddParamChecked(false)
      }

      if (settings) {
        const { remind_at_days_before_end_period, last_submission_date, ...restSettings } = settings

        reset({
          ...rest,
          ...restSettings,
          point,
          running_number,
          print_approval_format,
          params_wfe,
          different_requestor,
          module_id,
          isPoint: !!point,
          isWorkflow: Object.keys(module_id).length > 0,
          remind_at_days_before_end_period: {
            label: remind_at_days_before_end_period,
            value: remind_at_days_before_end_period
          },
          last_submission_date: {
            label: last_submission_date,
            value: last_submission_date
          }
        })
      } else {
        reset({
          ...form,
          ...rest,
          point,
          running_number,
          print_approval_format,
          params_wfe,
          different_requestor,
          module_id,
          isPoint: !!point,
          isWorkflow: Object.keys(module_id).length > 0
        })
      }
    }
  }, [reset, settings, otherFormData, showModal])

  const formValidation = () => {
    let isFormValid = true

    const textInputs = document.getElementsByClassName('input-text')
    const regexRunningNumber = /{SEQ-X*}/i
    Array.from(textInputs).forEach(function (input) {
      if (
        input.value &&
        !regexRunningNumber.test(input.value) &&
        input.name === 'Running Number Format'
      ) {
        isFormValid = false
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`${input.name} must include sequence format "SEQ-XXXX"`}
          />
        )
        input.focus()
      }
    })

    // Tags
    if (form.is_segregate_by_tags && !form.tags.length) {
      isFormValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage="At least 1 tag selected when Segregate by Tags is true. Please check form's tags."
        />
      )
    }

    return isFormValid
  }

  function onFormSubmit(data) {
    if (formValidation()) {
      const {
        point,
        running_number,
        is_segregate_by_tags,
        tags,
        location,
        module_id,
        assign_to,
        title,
        period,
        remind_at_days_before_end_period,
        last_submission_date,
        is_repetitive_checklist,
        remind_at_time,
        print_approval_format,
        params_wfe,
        different_requestor,
        image_resolution,
        form_size,
        form_method,
        form_type,
        custom_tablet_layout,
        linkage,
        api
      } = data

      const formData = {
        point,
        running_number,
        is_segregate_by_tags,
        tags,
        location,
        assign_to,
        module_id,
        print_approval_format,
        params_wfe,
        different_requestor,
        image_resolution,
        form_size,
        form_method,
        form_type,
        custom_tablet_layout,
        linkage,
        api,
        ...(title && { title })
      }

      const advancedSettingsData = is_repetitive_checklist
        ? {
            is_repetitive_checklist,
            remind_at_time,
            period,
            remind_at_days_before_end_period: remind_at_days_before_end_period.value,
            ...(period === 'MONTHLY' ? { last_submission_date: last_submission_date.value } : {})
          }
        : null

      if (activeTab !== 'api') {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully update advanced settings'))
        close()
      }
      onSubmit(formData, advancedSettingsData)
    }
  }

  function close() {
    setShowModal(false)
  }

  const handleSetActiveTab = choice => {
    setActiveTab(choice)
  }

  //====== Form Size & Tablet Layout ======\\

  const formSizeOption = [
    { name: 'Responsive', min_width: null, max_width: null },
    { name: 'Web Desktop', min_width: 1024, max_width: null },
    { name: 'Tablet', min_width: 481, max_width: 1024 },
    { name: 'Smart phone', min_width: null, max_width: 480 }
  ]

  const [showTabletLayoutModal, setShowTabletLayoutModal] = useState(false)
  const [customTabletLayout, setCustomTabletLayout] = useState(form.custom_tablet_layout)

  useEffect(() => {
    if (customTabletLayout) setValue('custom_tablet_layout', customTabletLayout)
  }, [customTabletLayout])

  const handleOnTabletLayoutChecked = event => {
    if (event.target.checked) {
      setShowTabletLayoutModal(true)
    } else {
      setValue('custom_tablet_layout', null)
    }
  }

  //====== Form Method & Form Type ======\\
  const [showLinkageModal, setShowLinkageModal] = useState(false)
  const [linkage, setLinkage] = useState(form.linkage)

  const formMethodOption = [
    { label: 'Default', value: 'DEFAULT' },
    { label: 'Wizard', value: 'WIZARD' }
  ]
  const formTypeOption = [
    { label: 'Default', value: 'DEFAULT' },
    { label: 'Linkage', value: 'LINKAGE' },
    { label: 'System', value: 'SYSTEM' }
  ]

  useEffect(() => {
    if (linkage) {
      setValue('form_type', 'LINKAGE')
      setValue('linkage', linkage)
    }
  }, [linkage])

  //====== POINT ======\\

  const handleOnPointChange = event => {
    if (!event.target.checked) {
      setValue('point', null)
    }
    setValue('isPoint', event.target.checked)
  }

  const handleOnValPointBlur = event => {
    const data = event.target.value.replace(/\,/g, '')
    if (data) {
      if (isNaN(parseInt(data)) || parseInt(data) < 0 || parseInt(data) > 10) {
        toast.error(<Toast message={`Error`} detailedMessage={'Field does not match the format'} />)
        event.target.value = ''
        return
      }

      setValue('point', event.target.value)
    }
  }

  //====== WORKFLOW ======\\

  const handleOnWorkflowChange = event => {
    if (event.target.checked) {
      setValue('assign_to', [])
      setValue('isWorkflow', true)
      dispatch(setShowWorkflowList(true))
      return
    }

    setValue('isWorkflow', false)
    setValue('module_id', {})
    setValue('assign_to', [])
    setValue('print_approval_format', null)
    setValue('params_wfe', null)
    setValue('different_requestor', false)
  }

  const onWorkflowList = (isBack, choosenWorkflow, isChanged) => {
    if (Object.entries(choosenWorkflow).length) {
      setValue('title', choosenWorkflow?.name || '')
      setValue('module_id', choosenWorkflow)

      if (!isBack) {
        if (!isChanged) {
          dispatch(setShowPopupSuccess(true))
          dispatch(setMessage('You have successfully add workflow'))
        }

        dispatch(setShowWorkflowList(false))
      }
      return
    }

    setValue('isWorkflow', false)
  }
  const handleEditWorkflow = () => {
    dispatch(setShowWorkflowList(true))
  }

  //====== PARAM ======\\
  useEffect(() => {
    setValue('params_wfe', paramsWfe)
  }, [paramsWfe])

  useEffect(() => {
    setValue('different_requestor', differentRequestor)
  }, [differentRequestor])

  const handleAddParam = (checked, title) => {
    if (checked && sectionsOriginal) {
      const getFieldTitles = data => {
        const fieldTitles = []

        for (const item of data) {
          for (const field of item.fields) {
            fieldTitles.push(field.field_title)
          }

          for (const subSection of item.sub_sections) {
            for (const field of subSection.fields) {
              fieldTitles.push(field.field_title)
            }
          }
        }

        return fieldTitles
      }

      const result = getFieldTitles(sectionsOriginal)
      const uniqueFieldTitle = result.filter((value, index, self) => self.indexOf(value) === index)
      // add submitterWorklocation to fields option
      uniqueFieldTitle.unshift('submitterWorklocation')
      setFieldsParam(uniqueFieldTitle)
      if (result.length) {
        setShowAddParamModal(true)
        setParamModalTitle(title)
      }
    } else {
      setShowConfirmationModal(true)
    }
  }

  const handleDisableParam = () => {
    setValue('params_wfe', null)
    setValue('different_requestor', false)
    setAddParamChecked(false)
    setShowConfirmationModal(false)
  }

  //====== TAGS ======\\
  const unselectedTags = tags.filter(t => !form.tags.includes(t.value))

  function onTagChange(v) {
    setValue('tags', [...form.tags, v.value])
  }

  function onRemoveTag(index) {
    setValue('tags', [...form.tags.slice(0, index), ...form.tags.slice(index + 1)])
  }

  //====== API Tab ======\\
  const [selectedApplication, setSelectedApplication] = useState(null)
  const [selectedAuthType, setSelectedAuthType] = useState(null)
  const [isEditingMode, setIsEditingMode] = useState(false)
  const [selectedApiApp, setSelectedApiApp] = useState(null)
  const [applicationOption, setApplicationOption] = useState([])

  function cancel() {
    setSelectedApplication(null)
    setSelectedAuthType(null)
    setIsEditingMode(false)
  }

  useEffect(() => {
    if (showModal) fetchApplication()
  }, [showModal])

  function fetchApplication() {
    get(`${apiUrls.APPLICATIONS_URL}/api-forms`).then(response => {
      const { status, data } = response
      if (status === 200) {
        const newData = data?.map(app => ({
          application_id: app.id,
          application_name: app.name
        }))
        setApplicationOption(newData || [])
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch applications'} />)
      }
    })
  }

  function appDataValidation() {
    if (!selectedApplication || !selectedAuthType) {
      return false
    }
    return true
  }

  function appExists(app) {
    return form.api?.some(
      obj =>
        obj.application_id === app.application_id &&
        obj.application_name === app.application_name &&
        obj.authentication_type === app.authentication_type
    )
  }

  function handleAddApiApplication() {
    let application = {
      application_id: selectedApplication?.application_id,
      application_name: selectedApplication?.application_name,
      authentication_type: selectedAuthType
    }

    if (appExists(application)) {
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`API application for ${selectedApplication?.application_name} with authentication type ${selectedAuthType} already exists`}
        />
      )
    } else {
      setSelectedApplication(null)
      setSelectedAuthType(null)

      setValue('api', [...form.api, application])

      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully add API Application'))

      handleSubmit(onFormSubmit({ ...form, api: [...form.api, application] }))
    }
  }

  function handleEditApiApplication() {
    let apiAppData = [...form.api]
    let application = {
      application_id: selectedApplication?.application_id,
      application_name: selectedApplication?.application_name,
      authentication_type: selectedAuthType
    }

    const applicationIndex = apiAppData.findIndex(
      item =>
        item.application_id === selectedApiApp.application_id &&
        item.application_name === selectedApiApp.application_name &&
        item.authentication_type === selectedApiApp.authentication_type
    )

    if (applicationIndex !== -1) {
      if (appExists(application)) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`API application for ${selectedApplication?.application_name} with authentication type ${selectedAuthType} already exists`}
          />
        )
      } else {
        apiAppData[applicationIndex] = {
          application_id: selectedApplication?.application_id,
          application_name: selectedApplication?.application_name,
          authentication_type: selectedAuthType
        }
        setValue('api', apiAppData)

        setSelectedApplication(null)
        setSelectedAuthType(null)
        setIsEditingMode(false)
        setSelectedApiApp(null)

        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully edit API Application'))

        handleSubmit(onFormSubmit({ ...form, api: apiAppData }))
      }
    }
  }

  function handleDeleteApiApplication() {
    let apiAppData = [...form.api]

    const applicationIndex = apiAppData.findIndex(
      item =>
        item.application_id === selectedApiApp.application_id &&
        item.application_name === selectedApiApp.application_name &&
        item.authentication_type === selectedApiApp.authentication_type
    )

    if (applicationIndex !== -1) {
      apiAppData.splice(applicationIndex, 1)
      setValue('api', apiAppData)

      setSelectedApiApp(null)

      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully delete API Application'))

      handleSubmit(onFormSubmit({ ...form, api: apiAppData }))
    }
  }

  //====== API Application Table ======\\

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => {
              setPageNum(data)
            }}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  const COLUMNS = [
    {
      Header: 'Application Name',
      accessor: 'application_name'
    },
    {
      Header: 'Authentication Type',
      accessor: 'authentication_type'
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleEditClick = () => {
          setIsEditingMode(true)
          setSelectedApiApp(row.original)
          setSelectedApplication({
            application_id: row.original.application_id,
            application_name: row.original.application_name
          })
          setSelectedAuthType(row.original.authentication_type)
        }
        const handleDeleteClick = () => {
          setSelectedApiApp(row.original)
          dispatch(setShowPopupDelete(true))
          dispatch(setMessage('Are you sure you want to delete this API Application?'))
          return
        }

        return (
          <div className="flex gap-2">
            <img
              src={ICONS.icEdit}
              className="w-[28px] cursor-pointer"
              onClick={() => handleEditClick()}
              alt="edit"
            />
            <img
              src={ICONS.icTrash}
              className="w-[28px] cursor-pointer"
              onClick={() => handleDeleteClick(row.original.id)}
              alt="delete"
            />
          </div>
        )
      }
    }
  ]

  const columns = useMemo(() => COLUMNS, [])
  const [pageNum, setPageNum] = useState(1)
  const limit = 5
  const totalPage = Math.ceil(form.api?.length / limit)
  const totalData = form.api?.length

  const splittedData = []

  for (let i = 0; i < form.api?.length; i += limit) {
    const chunk = form.api?.slice(i, i + limit)
    splittedData.push(chunk)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: splittedData[pageNum - 1] || [],
    pageNum
  })

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 !== 0
  }

  const goToNextPage = () => {
    setPageNum(prev => {
      return prev + 1
    })
  }
  const goToPreviousPage = () => {
    setPageNum(prev => {
      return prev - 1
    })
  }

  const FormTab = () => {
    return (
      <div className="flex flex-col md:flex-row justify-evenly gap-6">
        <div className="flex-1">
          <div className="flex gap-6">
            <div className="flex-1">
              <div className="font-semibold text-xs mb-2">Form Size</div>
              <Select
                components={{ DropdownIndicator }}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.name}
                onChange={selectedOption => setValue('form_size', selectedOption)}
                isSearchable={false}
                options={formSizeOption}
                value={form.form_size}
                name="Form Size"
                className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Select form size"
                styles={{
                  control: provided => ({
                    ...provided,
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
            {(form?.form_size?.name === 'Responsive' || form?.form_size?.name === 'Tablet') && (
              <div className="flex-1">
                <div className="font-poppins font-semibold text-[12px] mb-3">
                  Custom Tablet Layout
                </div>
                <label
                  htmlFor={`custom_tablet_layout`}
                  className="flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value=""
                    id={`custom_tablet_layout`}
                    className="sr-only peer"
                    checked={form.custom_tablet_layout}
                    onChange={e => handleOnTabletLayoutChecked(e)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                </label>
                {form?.custom_tablet_layout && (
                  <div className="my-2 px-[12px] py-[8px] rounded-lg bg-gradient-to-r from-[#9E7CE3] to-[#6546C3] text-white flex justify-between items-center">
                    <div>
                      <p className="text-[12px]">Modified Date:</p>
                      <p className="text-[12px]">
                        {moment(form?.custom_tablet_layout?.modified_date).format('DD-MM-YYYY')}
                      </p>
                    </div>
                    <img
                      src={ICONS.icPen}
                      className="w-[15px] h-[15px] bg-white rounded-full p-[1px] cursor-pointer"
                      onClick={() => setShowTabletLayoutModal(true)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex gap-5">
            <div className="flex-1">
              <div className="font-semibold text-xs mb-2">Method</div>
              <Select
                components={{ DropdownIndicator }}
                onChange={selectedOption => setValue('form_method', selectedOption.value)}
                isSearchable={false}
                options={formMethodOption}
                value={formMethodOption.find(option => option.value === form.form_method) || null}
                name="Form Method"
                className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Select method"
                styles={{
                  control: provided => ({
                    ...provided,
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
            <div className="flex-1">
              <div className="font-semibold text-xs mb-2">Type</div>
              <Select
                components={{ DropdownIndicator }}
                onChange={selectedOption => {
                  if (selectedOption.value === 'LINKAGE') {
                    setShowLinkageModal(true)
                  } else {
                    setValue('form_type', selectedOption.value)
                    if (form?.linkage) {
                      setValue('linkage', null)
                    }
                  }
                }}
                isSearchable={false}
                options={formTypeOption}
                value={formTypeOption.find(option => option.value === form.form_type) || null}
                name="Form Type"
                className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Select type"
                styles={{
                  control: provided => ({
                    ...provided,
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
              {form?.linkage && (
                <div className="my-2 px-[12px] py-[8px] rounded-lg bg-gradient-to-r from-[#9E7CE3] to-[#6546C3] text-white flex justify-between items-center">
                  <p className="text-[12px]">{form?.linkage?.form_title}</p>
                  <img
                    src={ICONS.icPen}
                    className="w-[15px] h-[15px] bg-white rounded-full p-[1px] cursor-pointer"
                    onClick={() => setShowLinkageModal(true)}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="font-semibold text-xs mb-2">Image Resolution</div>
            <Select
              components={{ DropdownIndicator }}
              getOptionLabel={option => option.name + ': ' + option.width + ' x ' + option.height}
              getOptionValue={option => option?.name}
              onChange={selectedOption => setValue('image_resolution', selectedOption)}
              isSearchable={false}
              isClearable={true}
              options={imageResolutionOption}
              value={form.image_resolution}
              name="Image Resolution"
              className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
              placeholder="Select image resolution"
              styles={{
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  color: '#C800A5',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({})
              }}
            />
          </div>
          <div>
            <div className="font-semibold text-xs mb-2">Segregate by Tags?</div>
            <input
              type="checkbox"
              className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
              checked={form.is_segregate_by_tags}
              onChange={({ target }) => setValue('is_segregate_by_tags', target.checked)}
            />
          </div>
          <div className="mt-3">
            <div className="font-semibold text-xs mb-2">Search or Add New Tag</div>
            <Select
              placeholder="Type here"
              options={unselectedTags}
              value={null}
              onChange={onTagChange}
              onCreateOption={value => onTagChange({ value })}
              isCreatable
            />
            <div className="flex flex-wrap text-xs mt-2">
              {form.tags.map((tag, t) => (
                <div
                  className="flex items-center rounded bg-purple-700 text-white my-2 mr-2 p-2"
                  key={t}
                >
                  {tag}
                  <img
                    src={ICONS.icCloseModal}
                    className="w-4 h-4 bg-white rounded-full ml-2 cursor-pointer"
                    alt="remove tag"
                    onClick={() => onRemoveTag(t)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex flex-col mb-3">
            <div className="font-poppins font-semibold text-[12px] mb-2">Running Number</div>
            <div className="flex flex-row items-center">
              <input
                type="text"
                name="Running Number Title"
                className="flex-1 input-text text-[12px] px-[16px] py-[12px] mr-2 h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                placeholder="Enter title"
                onBlur={({ target }) => setValue('running_number.title', target.value)}
                defaultValue={form.running_number?.title}
              />
              <input
                type="text"
                name="Running Number Format"
                className="flex-1 input-text text-[12px] px-[16px] py-[12px] mr-2 h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                placeholder="CCC/YYYY/SEQ-XXX"
                onBlur={({ target }) => setValue('running_number.format', target.value)}
                defaultValue={form.running_number?.format}
              />
              <img
                src={ICONS.icHelp}
                alt="Help"
                className="w-6 h-6 cursor-pointer"
                data-tooltip-id="help-tooltip"
                data-tooltip-html={helpContent}
                data-tooltip-place="top"
              />
              <Tooltip id="help-tooltip" place={'top'} className="z-[9999]" />
            </div>
          </div>
          <div className="flex">
            <div>
              <div className="font-poppins font-semibold text-[12px] mb-2">Point</div>
              <label htmlFor={`point`} className="flex relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  id={`point`}
                  className="sr-only peer"
                  {...register('isPoint')}
                  onChange={event => handleOnPointChange(event)}
                  checked={form.isPoint}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
              </label>
            </div>
            {form.isPoint ? (
              <div>
                <input
                  id={`valPoint`}
                  type="text"
                  name="Point Value"
                  className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] ml-2 mt-[19px]`}
                  placeholder="1-10"
                  {...register('point')}
                  onBlur={event => handleOnValPointBlur(event)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="mt-3">
            <div className="font-semibold text-xs mb-2">GPS Location</div>
            <input
              type="checkbox"
              className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
              checked={form.location}
              {...register('location')}
              onChange={({ target }) => setValue('location', target.checked)}
            />
          </div>
        </div>
      </div>
    )
  }

  const WorkflowAndReminder = () => {
    return (
      <div className="flex flex-col md:flex-row justify-evenly gap-6">
        <div className="flex-1">
          <div className="p-4 rounded-2xl border text-xs">
            <div className="flex items-center">
              <img src={ICONS.icRewind} className="w-8 mr-2" alt="reminder icon" />
              <div>
                <div className="font-semibold text-base">Reminder</div>
                <div className="text-gray-400">Send reminder to assigned user</div>
              </div>
            </div>

            <hr className="my-4" />

            <label className="flex items-center mt-3 select-none">
              <input
                type="checkbox"
                className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                {...register('is_repetitive_checklist')}
              />
              <div className="text-xs ml-2">Repetitive Checklist</div>
            </label>

            {watch('is_repetitive_checklist') && (
              <>
                <div className="flex justify-between mt-4">
                  {PERIODS.map((period, p) => (
                    <label className="flex items-center mt-3 select-none" key={p}>
                      <input
                        type="radio"
                        name="advanced_settings.period"
                        className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                        value={period.value}
                        {...register('period')}
                      />
                      <div className="text-xs ml-2">{period.label}</div>
                    </label>
                  ))}
                </div>

                <div className="p-4 rounded-2xl bg-indigo-50 mt-4">
                  <div className="text-pink-600 font-semibold">Reminder at</div>

                  <div className="mt-4">
                    <div className="font-semibold mb-1">Days Before End Period</div>
                    {form.period === 'DAILY' ? (
                      <div className="flex items-stretch">
                        <input
                          className="px-4 h-9 border w-full rounded-l-lg"
                          value={getValues('remind_at_days_before_end_period').value}
                          disabled
                        />
                        <div className="flex items-center bg-purple-700 text-white rounded-r-lg px-3">
                          Day
                        </div>
                      </div>
                    ) : (
                      <Controller
                        control={control}
                        name="remind_at_days_before_end_period"
                        render={({ field: { value, onChange } }) => (
                          <Select
                            options={
                              form.period === 'MONTHLY'
                                ? MONTHLY_DAYS_BEFORE_END
                                : WEEKLY_DAYS_BEFORE_END
                            }
                            value={value}
                            onChange={onChange}
                            menuPortalTarget={document.body}
                          />
                        )}
                      />
                    )}
                  </div>

                  {form.period === 'MONTHLY' && (
                    <div className="mt-4">
                      <div className="font-semibold mb-1">Last Submission Date</div>
                      <Controller
                        control={control}
                        name="last_submission_date"
                        render={({ field: { value, onChange } }) => (
                          <Select
                            options={LAST_SUBMISSION_DATES}
                            value={value}
                            onChange={onChange}
                            menuPortalTarget={document.body}
                          />
                        )}
                      />
                    </div>
                  )}

                  <div className="mt-4">
                    <div className="font-semibold mb-1">Time</div>
                    <Controller
                      control={control}
                      name="remind_at_time"
                      render={({ field: { value, onChange } }) => (
                        <input
                          type="time"
                          className="px-4 h-9 border w-full rounded-lg"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex-1 p-4 rounded-2xl border">
          <div>
            <div className="font-poppins font-semibold text-[12px] mb-2">Workflow</div>
            <label htmlFor={`workflow`} className="flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                id={`workflow`}
                className="sr-only peer"
                checked={form.isWorkflow}
                {...register('isWorkflow')}
                onChange={event => handleOnWorkflowChange(event)}
                disabled={disableUser}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
            </label>
          </div>
          {!form.module_id?.name ? (
            <div className="flex mt-1">
              <img src={ICONS.icInfo} className="w-[20px] h-[20px] mr-1" />
              <p className="font-poppins text-[12px] flex items-center">
                No workflow selected yet {form.module_id?.name}
              </p>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <hr className="mt-4" />
              <div className="mt-2 px-[12px] py-[8px] rounded-lg bg-gradient-to-r from-[#9E7CE3] to-[#6546C3] text-white flex justify-between">
                <div>
                  <p className="text-[12px]">{form.module_id?.name}</p>
                  <p className="text-[12px]">{form.module_id?.id}</p>
                </div>
                <img
                  src={ICONS.icPen}
                  className="w-[15px] h-[15px] bg-white rounded-full p-[1px] cursor-pointer"
                  onClick={() => handleEditWorkflow()}
                />
              </div>
              <button
                type="button"
                onClick={() => setShowPrintApprovalModal(true)}
                className="py-2 px-8 border-2 border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                {getStringHtml(form.print_approval_format?.format).trim() === '' ||
                form.print_approval_format === null
                  ? 'Set Up Print Approval'
                  : 'Edit Print Approval'}
              </button>
              <div className="font-poppins font-semibold text-[12px]">Add Param</div>
              <label htmlFor={`param`} className="flex relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  id={`param`}
                  className="sr-only peer"
                  checked={addParamChecked}
                  onChange={event => handleAddParam(event.target.checked, 'Add Param')}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
              </label>
              {addParamChecked && (
                <div className="mt-2 px-[12px] py-[8px] rounded-lg bg-gradient-to-r from-[#9E7CE3] to-[#6546C3] text-white flex justify-between">
                  <div className="text-[12px]">You have set this parameter</div>
                  <img
                    src={ICONS.icPen}
                    className="w-[15px] h-[15px] bg-white rounded-full p-[1px] cursor-pointer"
                    onClick={() => handleAddParam(true, 'Edit Param')}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  const ApiTab = () => {
    return (
      <div className="flex flex-col gap-6">
        <div className="bg-[#C800A5] bg-opacity-[0.08] rounded-2xl px-6 py-3 flex gap-2 items-center">
          <img src={ICONS.icInfoSecondary} className="w-5 h-5" />
          <div className="text-[12px] text-[#C800A5] italic font-semibold">
            Required IT supervision to update this section
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-6">
          <div className="flex flex-col justify-between p-4 bg-white rounded-2xl shadow-lg basis-5/12 sm:min-h-[350px]">
            <div className="flex flex-col gap-4">
              <div className="flex-1">
                <div className="font-semibold text-xs mb-2">Application Name</div>
                <Select
                  components={{ DropdownIndicator }}
                  getOptionLabel={option => option?.application_name}
                  getOptionValue={option => option?.application_id}
                  onChange={app => setSelectedApplication(app)}
                  value={selectedApplication}
                  isSearchable={true}
                  options={applicationOption}
                  className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select application"
                  styles={{
                    control: provided => ({
                      ...provided,
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({})
                  }}
                />
              </div>
              <div className="flex-1">
                <div className="font-semibold text-xs mb-3">Authentication Type</div>
                <div className="flex">
                  <label className="flex flex-1 items-center select-none">
                    <input
                      type="radio"
                      name="authenticationType"
                      className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                      checked={selectedAuthType === 'Application'}
                      onChange={() => setSelectedAuthType('Application')}
                    />
                    <div className="text-xs ml-2">Application</div>
                  </label>
                  <label className="flex flex-1 items-center select-none">
                    <input
                      type="radio"
                      name="authenticationType"
                      className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                      checked={selectedAuthType === 'User'}
                      onChange={() => setSelectedAuthType('User')}
                    />
                    <div className="text-xs ml-2">User</div>
                  </label>
                </div>
              </div>
            </div>
            {isEditingMode ? (
              <div className="flex justify-end gap-4 mt-12">
                <button
                  type="button"
                  onClick={cancel}
                  className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="py-3 px-8 disabled:bg-gray-400 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                  onClick={() => handleEditApiApplication()}
                  disabled={!appDataValidation()}
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="flex justify-end mt-12">
                <button
                  type="button"
                  className="py-3 px-8 disabled:bg-gray-400 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                  onClick={() => handleAddApiApplication()}
                  disabled={!appDataValidation()}
                >
                  Add
                </button>
              </div>
            )}
          </div>
          <div className="flex-col basis-7/12">
            <div className="overflow-x-auto w-full">
              <table {...getTableProps()} className="w-full font-poppins text-[12px]">
                <thead className="">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th key={index}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td {...cell.getCellProps()} className="!bg-transparent">
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            {!form.api?.length && (
              <div className="py-10">
                <NoDataTable
                  message="API does not exist yet"
                  detailedMessage="Please fill out the form and click the button to add it now!"
                />
              </div>
            )}
            <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-4">
              {form.api?.length > 0 ? (
                <div className="font-poppins text-[12px]">
                  Showing {pageNum * limit - (limit - 1)} -{' '}
                  {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
                </div>
              ) : (
                <div className="font-poppins text-[12px]">No data to show</div>
              )}
              <div className="flex font-poppins text-[12px]">
                <button
                  type="button"
                  onClick={() => goToPreviousPage()}
                  disabled={!canPreviousPage()}
                  className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                    !canPreviousPage()
                      ? 'bg-[#F2F5FC]'
                      : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
                  }`}
                >
                  &lt;
                </button>
                <div className="bg-[#F2F5FC] rounded-full flex">
                  {showPaginationNumbers(totalPage)}
                </div>
                <button
                  type="button"
                  onClick={() => goToNextPage()}
                  disabled={!canNextPage()}
                  className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                    !canNextPage()
                      ? 'bg-[#F2F5FC]'
                      : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
                  }`}
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="modal-box bg-white max-w-full w-fit md:w-[780px] h-fit"
        >
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Advanced Setting</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />

          <div className="flex gap-4 mb-6">
            <div
              className={`h-max flex flex-row flex-1 bg-${
                activeTab === 'form' ? 'gradient-to-r from-[#9E7CE3] to-[#6546C3]' : '[#F2F5FC]'
              } rounded-md p-[8px] cursor-pointer`}
              onClick={() => handleSetActiveTab('form')}
            >
              <div
                className={`w-[4px] h-[27px] bg-${
                  activeTab === 'form' ? 'white' : '[#6546C3]'
                } rounded-lg mr-[12px]`}
              ></div>
              <p
                className={`text-[12px] font-semibold text-${
                  activeTab === 'form' ? 'white' : '[#6546C3]'
                } self-center`}
              >
                Form
              </p>
            </div>
            <div
              className={`h-max flex flex-row flex-1 bg-${
                activeTab === 'workflow-reminder'
                  ? 'gradient-to-r from-[#9E7CE3] to-[#6546C3]'
                  : '[#F2F5FC]'
              } rounded-md p-[8px] cursor-pointer`}
              onClick={() => handleSetActiveTab('workflow-reminder')}
            >
              <div
                className={`w-[4px] h-[27px] bg-${
                  activeTab === 'workflow-reminder' ? 'white' : '[#6546C3]'
                } rounded-lg mr-[12px]`}
              ></div>
              <p
                className={`text-[12px] font-semibold text-${
                  activeTab === 'workflow-reminder' ? 'white' : '[#6546C3]'
                } self-center`}
              >
                Workflow & Reminder
              </p>
            </div>
            <div
              className={`h-max flex flex-row flex-1 bg-${
                activeTab === 'api' ? 'gradient-to-r from-[#9E7CE3] to-[#6546C3]' : '[#F2F5FC]'
              } rounded-md p-[8px] cursor-pointer`}
              onClick={() => handleSetActiveTab('api')}
            >
              <div
                className={`w-[4px] h-[27px] bg-${
                  activeTab === 'api' ? 'white' : '[#6546C3]'
                } rounded-lg mr-[12px]`}
              ></div>
              <p
                className={`text-[12px] font-semibold text-${
                  activeTab === 'api' ? 'white' : '[#6546C3]'
                } self-center`}
              >
                API
              </p>
            </div>
          </div>
          {activeTab === 'form' ? (
            <FormTab />
          ) : activeTab === 'workflow-reminder' ? (
            <WorkflowAndReminder />
          ) : (
            <ApiTab />
          )}

          {activeTab !== 'api' && (
            <div className="flex justify-end gap-4 mt-10">
              <button
                type="button"
                onClick={close}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Back
              </button>
              <button
                type="submit"
                className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Save
              </button>
            </div>
          )}
        </form>
      </div>
      <ModalWorkFlow
        onWorkflowList={onWorkflowList}
        dataList={workflowModules}
        choosenWorkflow={form.module_id}
      />
      <PrintApprovalModal
        showModal={showPrintApprovalModal}
        setShowModal={setShowPrintApprovalModal}
        setPrintApprovalFormat={setPrintApprovalFormat}
        sections={sections}
        currentPrintApprovalFormat={form.print_approval_format}
      />
      <AddParamModal
        showModal={showAddParamModal}
        setShowModal={setShowAddParamModal}
        fieldsParam={fieldsParam}
        setAddParamChecked={setAddParamChecked}
        currentParams={form.params_wfe}
        setParamsWfe={setParamsWfe}
        currentDifferentRequestor={form.different_requestor}
        setDifferentRequestor={setDifferentRequestor}
        title={paramModalTitle}
      />
      <SuccessPopup />
      <ConfirmationPopup
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        title="Disable Param"
        message="Are you sure want to DISABLE and REMOVE all selected field?"
        onConfirm={handleDisableParam}
      />
      <CustomTabletLayout
        showModal={showTabletLayoutModal}
        setShowModal={setShowTabletLayoutModal}
        setCustomTabletLayout={setCustomTabletLayout}
        sections={sections}
        currentTabletLayout={form.custom_tablet_layout}
      />
      <LinkageModal
        showModal={showLinkageModal}
        setShowModal={setShowLinkageModal}
        currentLinkage={form.linkage}
        setLinkage={setLinkage}
      />
      <DeletePopup
        setContinueDelete={() => handleDeleteApiApplication()}
        buttonNext={'Delete Now'}
      />
    </>
  )
}
