import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { del } from '../../../api/base'
import { SuccessPopup, Toast } from '../../../components'
import { apiUrls, ICONS, images } from '../../../constants'
import { setMessage, setShowPopupSuccess } from '../../../redux/slices/popupSlice'

import ImageDelete from '../../../assets/images/confirmation.png'
import UserGroupModal from './UserGroupModal'
import { useEffect } from 'react'

const ModalConfirmationDelete = ({ isShow, setShowModal, cancelDelete, actionDelete }) => {
  return (
    <div>
      <input
        type="checkbox"
        id="my-modal-5"
        className="modal-toggle"
        checked={isShow}
        onChange={() => setShowModal(!isShow)}
      />
      <div className="modal">
        <div className="modal-box bg-white relative w-full max-h-max max-w-max py-[60px] px-0">
          <div className="flex flex-col items-center min-w-[21.25rem]">
            <img src={ImageDelete} className="w-[120px] h-[120px] mb-9" alt="leave page icon" />
            <span className="font-semibold text-lg mb-1">Delete</span>
          </div>
          <div className="flex justify-center">
            <div className="text-xs text-[#AAAAAA] break-words w-[200px] text-center">
              Are you sure you want to delete this group?
            </div>
          </div>
          <div className="flex justify-center gap-4 mt-9">
            <button
              onClick={() => cancelDelete()}
              className="py-3 px-8 border-[2px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={() => actionDelete()}
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            >
              Delete now
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function UserGroup({ route_name, data = [], onSuccess }) {
  const dispatch = useDispatch()
  const [deletedItemId, setDeletedItemId] = useState(-1)
  const [showUserGroupModal, setShowUserGroupModal] = useState(false)
  const [userGroupSelected, setUserGroupSelected] = useState([])
  const [showModalGroup, setShowModalGroup] = useState(false)

  function addUserGroup() {
    setShowUserGroupModal(true)
    if (data.length) {
      const selectedUserGroup = []
      data.map(v => {
        selectedUserGroup.push(v.name)
      })
      setUserGroupSelected(selectedUserGroup)
    }
  }

  function confirmDelete(item) {
    setDeletedItemId(item.id)
  }

  function handleCancelDelete() {
    setDeletedItemId(null)
    setShowModalGroup(false)
  }

  async function onDeleteGroup() {
    const response = await del(`${apiUrls.MICROSITES_ROUTE}/${route_name}/groups/${deletedItemId}`)
    if (response.ok) {
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully deleted user group assignment'))
      setShowModalGroup(false)
      onSuccess()
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
    }
  }

  useEffect(() => {
    onSuccess()
  }, [deletedItemId])

  return (
    <div className="bg-white rounded-2xl p-2 sm:p-6 lg:my-0 my-1">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-base mr-2">User Group Assignment List</div>
        <img
          src={ICONS.icAddMenuList}
          className="w-7 h-7 cursor-pointer"
          alt="add menu"
          onClick={addUserGroup}
        />
      </div>

      <hr className="my-6" />
      {data.length ? (
        <div className="flex flex-col w-full">
          {data.map((item, index) => (
            <div key={index}>
              <div className={`bg-[rgb(242,245,252)] h-[40px] mx-[2px] rounded-md my-2 flex`}>
                <span
                  className={`text-black text-[14px] py-[10px] pl-[2px] mr-[10px] ml-[10px] grow`}
                >
                  {item.group ? item.group.name : '-'}
                </span>

                <div className="dropdown dropdown-left dropdown-start">
                  <label htmlFor="my-modal-5">
                    <img
                      src={ICONS.icTrash}
                      className="w-[25px] h-[25px] cursor-pointer mx-2 my-2"
                      alt="action-button"
                      onClick={() => confirmDelete(item)}
                    />
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
          <div className="font-semibold text-[18px] mb-2">No User Group assigned yet</div>
          <div className="text-[12px] text-[#AAAAAA] mb-6 text-center">
            Please add a user group first by clicking the + button
          </div>
        </div>
      )}
      <SuccessPopup />
      <UserGroupModal
        showModal={showUserGroupModal}
        setShowModal={() => setShowUserGroupModal(false)}
        userGroupSelected={userGroupSelected}
        on_success={() => onSuccess()}
      />

      {/* modal for delete group */}
      <ModalConfirmationDelete
        isShow={showModalGroup}
        setShowModal={value => setShowModalGroup(value)}
        cancelDelete={() => handleCancelDelete()}
        actionDelete={() => onDeleteGroup()}
      />
    </div>
  )
}
