import React from 'react'
import Field from '../Field'

const SubSection = ({ section }) => {
  const fields = section.fields
  return (
    <div className="flex flex-col bg-[#F2F5FC] rounded-2xl p-6">
      <div className="font-semibold text-[14px] text-[#C800A5]">{section.section_title}</div>
      <hr className="mt-2 mb-6" />
      <div className="flex flex-col gap-4">
        {fields?.map((field, fieldIndex) => (
          <Field key={`${field.field_id}${fieldIndex}`} fieldData={field} />
        ))}
      </div>
    </div>
  )
}

export default SubSection
