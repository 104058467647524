import React, { useState, useMemo, useEffect } from 'react'
import { get, put } from '../../../api/base'
import { apiUrls } from '../../../constants'
import { useTable } from 'react-table'
import Select from 'react-select'
import { Toast } from '../../../components'
import { toast } from 'react-toastify'
import { DropdownIndicator } from '../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  setShowPopupLeavePage,
  setShowPopupSuccess,
  setMessage
} from '../../../redux/slices/popupSlice'
import LeavePagePopup from '../../../components/Popup/LeavePagePopup'
import SuccessPopup from '../../../components/Popup/SuccessPopup'

const EditGroupPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [group, setGroup] = useState({
    name: '',
    active: false,
    assignment: false,
    vip: false,
    local_group: false,
    workLocations: null,
    permissions: []
  })
  const [workLocationOptions, setWorkLocationOptions] = useState([])
  const [selectedWorkLocation, setSelectedWorkLocation] = useState(null)

  const COLUMNS = [
    {
      Header: 'Menu',
      accessor: 'name',
      Cell: ({ value }) => {
        return value
      }
    },
    {
      Header: () => <div style={{ textAlign: 'center' }}>Create</div>,
      accessor: 'create',
      Cell: ({ row, group }) => {
        const currAccess = group.permissions.find(obj => obj.menu_id === row.original.id)
        const checked = currAccess ? currAccess.access.includes('CREATE') : false
        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
              checked={checked}
              onChange={event =>
                handleOnPermissionsChange(row.original.id, event.target.checked, 'CREATE', group)
              }
            />
          </div>
        )
      }
    },
    {
      Header: () => <div style={{ textAlign: 'center' }}>Read</div>,
      accessor: 'read',
      Cell: ({ row, group }) => {
        const currAccess = group.permissions.find(obj => obj.menu_id === row.original.id)
        const checked = currAccess ? currAccess.access.includes('READ') : false
        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
              checked={checked}
              onChange={event =>
                handleOnPermissionsChange(row.original.id, event.target.checked, 'READ', group)
              }
            />
          </div>
        )
      }
    },
    {
      Header: () => <div style={{ textAlign: 'center' }}>Update</div>,
      accessor: 'update',
      Cell: ({ row, group }) => {
        const currAccess = group.permissions.find(obj => obj.menu_id === row.original.id)
        const checked = currAccess ? currAccess.access.includes('UPDATE') : false
        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
              checked={checked}
              onChange={event =>
                handleOnPermissionsChange(row.original.id, event.target.checked, 'UPDATE', group)
              }
            />
          </div>
        )
      }
    },
    {
      Header: () => <div style={{ textAlign: 'center' }}>Delete</div>,
      accessor: 'delete',
      Cell: ({ row, group }) => {
        const currAccess = group.permissions.find(obj => obj.menu_id === row.original.id)
        const checked = currAccess ? currAccess.access.includes('DELETE') : false
        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
              checked={checked}
              onChange={event =>
                handleOnPermissionsChange(row.original.id, event.target.checked, 'DELETE', group)
              }
            />
          </div>
        )
      }
    }
  ]

  const columns = useMemo(() => COLUMNS, [])
  const [menuList, setMenuList] = useState([])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: menuList,
    group
  })

  useEffect(() => {
    fetchWorkLocations()
    fetchGroup()
    fetchMenuList()
  }, [])

  useEffect(() => {
    // Populate work location
    const selectedWorkLocation = {
      label: group?.workLocations?.name ? group.workLocations.name : '',
      value: group?.workLocations?.id ? group.workLocations.id : ''
    }
    setSelectedWorkLocation(selectedWorkLocation)
  }, [group])

  const fetchGroup = () => {
    get(`${apiUrls.GROUPS_URL}/${id}`).then(response => {
      const { status } = response
      if (status === 200) {
        let group = response.data
        group.permissions = []
        group.menus.forEach(function (menu) {
          group.permissions.push({
            menu_id: menu.id,
            access: menu.permissions
          })
        })
        group.workLocations =
          group.workLocations === null ? { id: 'null', name: 'UNSET' } : group.workLocations
        setGroup(group)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch group'} />)
      }
    })
  }

  const handleOnGroupNameChange = event => {
    setGroup({ ...group, name: event.target.value })
  }

  const handleOnStatusChange = event => {
    setGroup({ ...group, active: event.target.checked })
  }

  const handleOnAssignmentChange = event => {
    if (event.target.checked) {
      setGroup({ ...group, assignment: event.target.checked })
    } else {
      setGroup({
        ...group,
        assignment: event.target.checked,
        vip: false,
        local_group: false
      })
    }
  }

  const handleOnVipChange = event => {
    if (event.target.checked) {
      setGroup({ ...group, vip: event.target.checked })
    } else {
      setGroup({ ...group, vip: event.target.checked, local_group: false })
    }
  }

  const handleOnLocalGroupChange = event => {
    setGroup({ ...group, local_group: event.target.checked })
  }

  const handleOnWorkLocationChange = selectedOption => {
    setGroup({
      ...group,
      workLocations: { id: selectedOption.value, name: selectedOption.label }
    })
  }

  const fetchWorkLocations = () => {
    get(apiUrls.WORK_LOCATIONS).then(response => {
      const { status } = response
      if (status === 200) {
        populateWorkLocationDropdown(response.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch work locations'} />)
      }
    })
  }

  const populateWorkLocationDropdown = work_location => {
    let newWorkLocationOptions = [{ value: 'null', label: 'UNSET' }]
    const newWorkLocationOptionsApi = work_location.map(work_location => {
      if (work_location != null) {
        return { value: work_location.id, label: work_location.name }
      } else {
        return { value: null, label: '' }
      }
    })
    newWorkLocationOptions = newWorkLocationOptions.concat(newWorkLocationOptionsApi)
    setWorkLocationOptions(newWorkLocationOptions)
  }

  const fetchMenuList = () => {
    get(`${apiUrls.GROUPS_URL}/menus`).then(response => {
      const { status } = response
      if (status === 200) {
        setMenuList(response.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch menu'} />)
      }
    })
  }

  const handleOnPermissionsChange = (id, checked, menu, group) => {
    // find menu access in permissions
    const menuAccess = group.permissions.find(obj => obj.menu_id === id)
    // get permissions except permission with current menu id
    const otherPermissions = group.permissions?.filter(function (obj) {
      return obj.menu_id !== id
    })
    if (checked) {
      // checkbox checked
      if (menuAccess) {
        // menu already exist in permissions
        menuAccess.access.push(menu)
        otherPermissions.push(menuAccess)
        setGroup({ ...group, permissions: otherPermissions })
      } else {
        // menu not exist in permissions
        otherPermissions.push({
          menu_id: id,
          access: [menu]
        })
        setGroup({ ...group, permissions: otherPermissions })
      }
    } else {
      // uncheck checkbox
      if (menuAccess) {
        const accessIndex = menuAccess.access.indexOf(menu)
        menuAccess.access.splice(accessIndex, 1)
        if (menuAccess.access.length > 0) {
          // menu access in permissions has another value, update menu access value
          otherPermissions.push(menuAccess)
          setGroup({ ...group, permissions: otherPermissions })
        } else {
          // menu access in permission has no value, remove menu in permissions
          setGroup({ ...group, permissions: otherPermissions })
        }
      }
    }
  }

  const handleLeavePage = () => {
    dispatch(setShowPopupLeavePage(true))
  }

  const handleEditGroup = () => {
    if (groupValidation()) {
      group.work_location = group?.workLocations?.id
      delete group.workLocations
      delete group.id
      delete group.editable
      delete group.menus
      const sendForm = {
        ...group,
        work_location: group.work_location === 'null' ? null : group.work_location
      }
      put(`${apiUrls.GROUPS_URL}/${id}`, sendForm).then(response => {
        const { status } = response
        if (status === 200) {
          dispatch(setShowPopupSuccess(true))
          dispatch(setMessage('You have successfully edit group'))
          navigate('/Admin/ManageGroup', {
            state: { title: 'Manage Group', crumbs: ['Admin', 'Manage Group'] }
          })
        } else if (status === 401) {
          toast.error(<Toast message={`Error`} detailedMessage={`Unauthorized user.`} />)
        } else if (status === 500) {
          toast.error(<Toast message={`Server Error`} detailedMessage={`Failed to edit group.`} />)
        } else {
          toast.error(
            <Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />
          )
        }
      })
    }
  }

  const groupValidation = () => {
    let isGroupValid = true

    // Text Input Validation
    const textInputs = document.getElementsByClassName('input-text')
    Array.from(textInputs).forEach(function (input) {
      if (input.value === '') {
        isGroupValid = false
        toast.error(
          <Toast message={`Error`} detailedMessage={`${input.name} should not be empty`} />
        )
        input.focus()
      }
    })

    // Group Name & Local Group Validation
    if (group.name.includes('SITE') && !group.local_group) {
      isGroupValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Local Group Checkbox must be checked because group name contain SITE`}
        />
      )
    }

    if (!group.name.includes('SITE') && group.local_group) {
      isGroupValid = false
      toast.error(
        <Toast message={`Error`} detailedMessage={`Local Group must have SITE on it's name`} />
      )
    }

    return isGroupValid
  }

  return (
    <div className="flex-row p-2 md:p-6">
      <div className="flex flex-col justify-evenly gap-6">
        <div className="flex-1 bg-white p-6 rounded-2xl">
          <div className="font-poppins font-semibold text-[16px] text-[#C800A5] mb-6">
            Group Info
          </div>
          <div className="flex flex-row justify-evenly gap-5 md:gap-[80px]">
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Group Name</div>
              <input
                type="text"
                name="Group Name"
                className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-1`}
                placeholder="Enter location name"
                defaultValue={group?.name}
                onChange={event => handleOnGroupNameChange(event)}
              />
            </div>
            <div className="md:flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Is Active</div>
              <label
                htmlFor={`is-group-active`}
                className="flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  value=""
                  id={`is-group-active`}
                  className="sr-only peer"
                  checked={group?.active}
                  onChange={event => handleOnStatusChange(event)}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
              </label>
            </div>
          </div>
          <hr className="my-6" />
          <div className="flex flex-col lg:flex-row gap-5 lg:gap-[90px]">
            <div className="flex-1 flex flex-row justify-between">
              <div className="flex flex-col items-center">
                <div className="font-poppins font-semibold text-[12px] mb-4">Assignment</div>
                <input
                  type="checkbox"
                  className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-[#6546C3]"
                  checked={group?.assignment}
                  onChange={group?.editable ? event => handleOnAssignmentChange(event) : null}
                  readOnly={!group?.editable}
                />
              </div>
              <div className="flex flex-col items-center">
                <div className="font-poppins font-semibold text-[12px] mb-4">VIP</div>
                <input
                  type="checkbox"
                  className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                  disabled={!group?.assignment}
                  checked={group?.vip}
                  onChange={group?.editable ? event => handleOnVipChange(event) : null}
                  readOnly={!group?.editable}
                />
              </div>
              <div className="flex flex-col items-center">
                <div className="font-poppins font-semibold text-[12px] mb-4">Local Group</div>
                <input
                  type="checkbox"
                  className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                  disabled={!group?.vip}
                  checked={group?.local_group}
                  onChange={group?.editable ? event => handleOnLocalGroupChange(event) : null}
                  readOnly={!group?.editable}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Work Location</div>
              <Select
                components={{ DropdownIndicator }}
                value={selectedWorkLocation}
                onChange={selectedOption => handleOnWorkLocationChange(selectedOption)}
                menuIsOpen={group?.editable ? undefined : false}
                isSearchable={group?.editable}
                options={workLocationOptions}
                name="Work Location"
                className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Select work location"
                styles={{
                  control: provided => ({
                    ...provided,
                    borderColor: '#DEDEDE',
                    borderRadius: '6px',
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 bg-white p-6 rounded-2xl">
          <div className="flex-1">
            <div className="font-poppins font-semibold text-[16px] text-[#C800A5] mb-6">
              Access Permission
            </div>
            <table {...getTableProps()} className="font-poppins text-[12px]">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-10">
          <button
            onClick={() => handleLeavePage()}
            className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={() => handleEditGroup()}
            className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
          >
            Save
          </button>
        </div>
      </div>
      <LeavePagePopup
        onLeavePage={() => {
          navigate('/Admin/ManageGroup', {
            state: { title: 'Manage Group', crumbs: ['Admin', 'Manage Group'] }
          })
        }}
      />
      <SuccessPopup />
    </div>
  )
}

export default EditGroupPage
