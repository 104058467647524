import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ICONS, images } from '../../../../constants'
import { selectUserData } from '../../../../redux/slices/userSlice'
import Menu from './components/Menu'

const SideNav = () => {
  const sortingDefault = ['dashboard', 'create', 'my activity', 'document library', 'admin']
  const sortingMenu = dataMenu => {
    let newMenus = []
    if (dataMenu) {
      sortingDefault.map(el => {
        let found = dataMenu.findIndex(menu => menu.name.toLowerCase() === el)
        if (found !== -1) {
          newMenus.push(dataMenu[found])
        }
      })
    }
    return newMenus
  }

  const [open, setOpen] = useState(true)
  const userData = useSelector(selectUserData)
  let menus = userData?.menus ? sortingMenu(userData?.menus) : []
  useEffect(() => {
    sortingMenu(menus)
  }, [])

  return (
    <div className={`${open ? 'w-[252px] px-4' : 'w-[72px]'} `}>
      <div
        className={`w-full flex items-center h-[60px] ${
          open ? 'justify-between' : 'justify-center'
        } mb-[46px]`}
      >
        {open ? <img src={images.itmLogoWhite} className="h-[60px] cursor-pointer" /> : <></>}
        <img
          src={ICONS.icCollapseExpand}
          className="w-6 h-6 cursor-pointer"
          onClick={() => setOpen(!open)}
        />
      </div>
      <ul className="pt-6">
        {menus.length > 0
          ? menus.map((menu, index) => <Menu data={menu} key={index} open={open} />)
          : null}
      </ul>
    </div>
  )
}

export default SideNav
