import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowWorkflowList } from '../../../redux/slices/popupSlice'
import { apiUrls, ICONS, images } from '../../../constants'
import { toast } from 'react-toastify'
import { Toast } from '../../../components'

const ModalWorkFlow = ({ onWorkflowList, dataList, choosenWorkflow }) => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.popup.showPopupWorkflowList)
  const [value, setValue] = useState(choosenWorkflow?.id)

  useEffect(() => {
    setValue(choosenWorkflow?.id)
  }, [choosenWorkflow])

  const changeList = id => {
    const tempValue = dataList.find(el => el.id === id)
    setValue(tempValue.id)
  }

  function handleBackButton() {
    setValue(choosenWorkflow?.id)
    dispatch(setShowWorkflowList(false))
    if (onWorkflowList) {
      onWorkflowList(true, choosenWorkflow?.id === undefined ? {} : choosenWorkflow, false)
    }
  }

  function handleAddButton() {
    let isChoosen = false
    dataList.map(option => {
      if (option.checked) {
        isChoosen = true
      }
    })

    if (!value) {
      toast.error(
        <Toast message={`Error`} detailedMessage={'Must choose at least one workflow!'} />
      )

      return
    }

    dispatch(setShowWorkflowList(false))
    if (onWorkflowList) {
      const found = dataList.findIndex(el => el.id === value)
      onWorkflowList(false, found !== -1 ? dataList[found] : {})
    }
  }

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal bg-[#232323]/[0.5] overflow-y-auto py-6">
        <div
          className="modal-box bg-white relative w-full h-full lg:w-[410px] h-[452px] p-[24px]"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex justify-between pb-5 border-b-2 border-b-[#DEDEDE]">
            <p className="font-poppins font-semibold text-[16px] text-[#C800A5]">Workflow</p>
            <img
              src={ICONS.icCloseModal}
              className="w-[25px] h-[25px] cursor-pointer"
              onClick={() => handleBackButton()}
            />
          </div>
          <p className="font-poppins font-semibold text-[12px] mt-[24px] mb-[16px]">
            Select Workflow
          </p>
          <div className="h-[225px] overflow-auto">
            {dataList.length ? (
              dataList.map(item => (
                <div
                  key={item.id}
                  className="px-[8px] py-[12px] border-2 border-[#DEDEDE] mb-[12px] rounded-lg flex justify-between"
                >
                  <div>
                    <p className="font-poppins text-[12px]">{item.name}</p>
                    <p className="font-poppins text-[12px] text-[#AAAAAA]">{item.id}</p>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      className="w-5 h-5 border-[#6546C3]"
                      checked={value === item.id}
                      value={item.name}
                      onChange={e => changeList(item.id, e.target.checked)}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p className="font-poppins text-[16px] flex justify-center">No Data</p>
            )}
          </div>
          <div className="flex justify-end gap-4 mt-6">
            <button
              onClick={() => handleBackButton()}
              className="py-3 px-8 border-[2px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Back
            </button>
            <button
              onClick={() => handleAddButton()}
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalWorkFlow
