import { useEffect, useState } from 'react'

import { SuccessPopup, DropdownIndicator } from '../../../../../../components'
import { ICONS } from '../../../../../../constants'
import Select from 'react-select'

export default function ParameterModal({
  showModal,
  setShowModal,
  parameterOptions,
  setSelectedParameter
}) {
  const [selectedTag, setSelectedTag] = useState(null)

  const handleAddParameter = selectedOption => {
    setSelectedTag(selectedOption)
  }

  const handleSaveParameter = () => {
    setSelectedParameter(selectedTag)
    setShowModal(false)
  }

  useEffect(() => {
    if (showModal) setSelectedTag('')
  }, [showModal])

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white w-fit md:w-[500px] min-h-[350px] flex flex-col">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Tag Parameter</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={() => setShowModal(false)}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />
          <div className="flex flex-col justify-between w-full flex-1">
            <Select
              components={{ DropdownIndicator }}
              getOptionLabel={option =>
                option?.additionalOption ? option.additionalOption : option.field_title
              }
              getOptionValue={option =>
                option?.additionalOption
                  ? option.field_id + '-' + option.additionalOption
                  : option.field_id
              }
              onChange={selectedOption => handleAddParameter(selectedOption)}
              isSearchable={true}
              options={parameterOptions}
              value={selectedTag}
              name="Tag"
              className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
              placeholder="Select tag parameter"
              styles={{
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  color: '#C800A5',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({}),
                menu: base => ({
                  ...base
                }),
                menuList: base => ({
                  ...base,
                  maxHeight: '200px'
                })
              }}
            />

            <div className="flex justify-end gap-4 mt-10">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Back
              </button>
              <button
                type="submit"
                onClick={() => handleSaveParameter()}
                className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Save
              </button>
            </div>
          </div>

          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
