import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageContext } from '../../..'

export default function StaticField() {
  const location = useLocation()
  const { content } = location.state
  const navigate = useNavigate()
  const { topColor } = useContext(PageContext)
  return (
    <>
      <div
        className="bg-white rounded-2xl justify-between p-4 mb-[40px]"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <button
        type="button"
        className="py-2 px-8 border-2 font-semibold rounded-md ml-auto flex text-sm"
        style={{
          color: topColor || '#00B1B5',
          borderColor: topColor || '#00B1B5'
        }}
        onClick={() => navigate(-1)}
      >
        Back
      </button>
    </>
  )
}
