import { useContext, useEffect, useRef, useState } from 'react'
import Iframe from 'react-iframe'

import { images } from '../../../../constants'
import { PageContext } from '../..'
import Filter from './Filter'

export default function TypeReport() {
  const { page } = useContext(PageContext)
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const [reportUrl, setReportUrl] = useState('')
  const [filter, setFilter] = useState({
    company_code: null,
    contractor_code: null
  })

  const onChangeFilter = filterParams => {
    const newFilter = {
      company_code: filterParams.company_code ? filterParams.company_code : null,
      contractor_code: filterParams.contractor_code ? filterParams.contractor_code : null
    }

    setFilter(newFilter)
  }

  const createReportEmbedParamsUrl = (url, params) => {
    const query = new URLSearchParams()

    if (params.company_code) query.append('COMPANY_CODE', params.company_code)
    if (params.contractor_code) query.append('CONTRACTOR_CODE', params.contractor_code)

    setReportUrl(`${url}&${query.toString()}`)
  }

  useEffect(() => {
    setFilter({ company_code: null, contractor_code: null })
  }, [page])

  useEffect(() => {
    if (page.reports[0].report_type === 'SSRS' && (filter.company_code || filter.contractor_code)) {
      createReportEmbedParamsUrl(page.reports[0].report_link, filter)
    } else setReportUrl(page.reports[0].report_link)
  }, [filter])

  return (
    <div>
      {page.reports[0].report_type === 'SSRS' ? (
        <Filter reports={page?.reports} handleOnChangeFilter={filter => onChangeFilter(filter)} />
      ) : (
        <></>
      )}
      <div className="rounded-xl py-10 bg-white">
        {page.reports[0].report_type !== 'SSRS' ? (
          <Iframe
            url={reportUrl}
            width="100%"
            height={`${windowSize.current[1]}px`}
            className=""
            display="block"
            position="relative"
          />
        ) : filter.company_code || filter.contractor_code ? (
          <Iframe
            url={reportUrl}
            width="100%"
            height={`${windowSize.current[1]}px`}
            className=""
            display="block"
            position="relative"
          />
        ) : (
          <div className="flex flex-col justify-center items-center p-6">
            <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="" />
            <div className="font-semibold text-[18px] mb-2">Cannot showing report</div>
            <div className="text-[12px] text-[#AAAAAA] mb-6">
              Please choose company or contractor to show the report!
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
