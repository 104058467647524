import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useRef } from 'react'

import { get, put } from '../../../../../api/base'
import { Toast } from '../../../../../components'
import { ICONS, apiUrls, mockData } from '../../../../../constants'
import { selectUserData } from '../../../../../redux/slices/userSlice'
import {
  setMessage,
  setShowComment,
  setShowPopupSuccess
} from '../../../../../redux/slices/popupSlice'
import { post } from '../../../../../api/base'

import Section from './Section'
import { SuccessPopup } from '../../../../../components'
import CommentPopup from '../../../../../components/Popup/CommentPopup'

const EditSubmission = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { ids } = useParams()
  const [submission, setSubmission] = useState(null)
  const [isPic, setIsPic] = useState(false)
  const [action, setAction] = useState(false)
  const user = submission?.user
  const sentAt = submission?.sent_at
  const [sections, setSections] = useState(null)
  const form = submission?.form
  const userData = useSelector(selectUserData)
  const drop = useRef(null)
  const inputRef = useRef(null)
  const [progress, setProgress] = useState(0)
  const [newAttachments, setNewAttachments] = useState([])
  const [isHaveAttachment, setIsHaveAttachment] = useState(false)

  useEffect(() => {
    fetchDetailSubmission()
  }, [])

  useEffect(() => {
    if (form) {
      checkIsPic()
    }
  }, [form])

  useEffect(() => {
    if (action) {
      dispatch(setShowComment(true))
    }
  }, [action])

  const checkIsPic = () => {
    const found = submission?.form?.userPics?.findIndex(
      el => el.name.toLowerCase() === userData.username.toLowerCase()
    )
    if (found !== -1) {
      setIsPic(true)
    }
  }

  const fetchDetailSubmission = () => {
    get(`/api/dynamic-forms/contents/${ids}`).then(response => {
      const { status } = response
      if (status === 200) {
        setSubmission(response.data)
        const newSections = response.data.sections

        newSections.map(section => {
          section.fields.map(field => {
            if (field.field_type.value === 'DROPDOWN_MASTER_DATA') {
              field.master_data.contentId = null
              if (field.value) {
                field.value.label = field.value.name
                field.value.value = field.value.name
              }
            } else {
              field.master_data = {
                contentId: null
              }
            }
          })
          if (section.sub_sections) {
            section.sub_sections.map(sub_section => {
              sub_section.fields.map(field => {
                if (field.field_type.value === 'DROPDOWN_MASTER_DATA') {
                  field.master_data.contentId = null
                  if (field.value) {
                    field.value.label = field.value.name
                    field.value.value = field.value.name
                  }
                } else {
                  field.master_data = {
                    contentId: null
                  }
                }
              })
            })
          }
        })

        setSections(newSections)
        setIsHaveAttachment(response.data.form.attachment)
        if (response.data.attachments && response.data.attachments.length) {
          setNewAttachments(response.data.attachments)
        } else {
          setNewAttachments([])
        }
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
      }
    })
  }

  const titleCase = str => {
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
  }

  const handleButton = action => {
    setAction(action)
  }

  const handleSend = comment => {
    const query = {
      query: { type: action, comment: comment }
    }
    post(`${apiUrls.FORMS_URL}/contents/${ids}/action`, {}, query).then(response => {
      const { status } = response
      if (status === 200) {
        toast.success(
          <Toast
            message={`Success`}
            detailedMessage={`You have successfully ${action.toLowerCase()}`}
          />
        )
        navigate('/MyActivity', {
          state: { title: 'My Activity' }
        })
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`Failed to fetch.`} />)
      }
    })
  }

  const getAttachmentType = fileName => {
    if (fileName) {
      const fileNameSplit = fileName.split('.')
      return fileNameSplit[fileNameSplit.length - 1]
    } else {
      return 'jpg'
    }
  }

  const getAttachmentImage = fileType => {
    switch (fileType) {
      case 'pptx':
        return ICONS.icPpt
      case 'ppt':
        return ICONS.icPpt
      case 'docx':
        return ICONS.icDoc
      case 'doc':
        return ICONS.icDoc
      case 'xls':
        return ICONS.icXls
      case 'xlsx':
        return ICONS.icXls
      default:
        return ICONS.icDoc
    }
  }

  //Handle open file manager to upload file after click button
  const handleClick = () => {
    inputRef.current.click()
  }

  const handleChangeAttachment = event => {
    const fileObj = event.target.files
    if (!fileObj) {
      return
    } else {
      fileListMapper(fileObj)
    }
  }

  const fileListMapper = async fileList => {
    let isValidFile = []
    let validFile = null
    const fileNameSplit = fileList[0].name.split('.')
    // Checking type and size of file on MB
    if (
      mockData.fileTypes.includes(fileNameSplit[fileNameSplit.length - 1]) &&
      fileList[0].size / 1000000 < userData.file_size
    ) {
      isValidFile.push(true)
      validFile = fileList[0]
    } else {
      isValidFile.push(false)
    }

    //return message error if file not valid
    if (isValidFile.includes(false)) {
      validFile = null
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage="file type not supported or file size larger than 5 mb"
        />
      )
    } else {
      //create payload form data
      const formData = new FormData()
      formData.append('files[]', validFile)

      const res = await post(`${apiUrls.FORMS_URL}/attachments`, formData)
      if (res.ok) {
        setNewAttachments(newAttachments.concat(res.data))
        toast.success(<Toast message={`Success`} detailedMessage={`success to upload this file`} />)
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={`failed to upload file ${validFile.name}`} />
        )
      }
    }
  }

  //Handle drag and drop files
  useEffect(() => {
    if (isHaveAttachment) {
      drop.current.addEventListener('dragover', handleDragOver)
      drop.current.addEventListener('drop', handleDrop)
    }
  }, [])

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()

    const { files } = e.dataTransfer

    if (files && files.length) {
      fileListMapper(files)
    }
  }

  const handleSubmit = async () => {
    const currentDate = new Date()
    const payload = {
      sections: sections,
      submitted_time: `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}`,
      attachments: isHaveAttachment ? newAttachments : undefined,
      company_id: submission.company ? submission.company.id : null
    }

    const response = await put(`${apiUrls.FORMS_URL}/contents/${submission.id}`, payload)

    if (response.ok) {
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully update submission form'))
    } else {
      toast.error(<Toast message={`Error`} detailedMessage="Failed to update submission form." />)
    }
  }

  const onChangeCheckBox = (
    event,
    label,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    let newSections = [...sections]
    const values = []
    if (innerSectionIndex || innerSectionIndex === 0) {
      if (additionalIndex || additionalIndex === 0) {
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
          additionalIndex
        ].options.map(v => {
          if (v.label === label) {
            v.value = event.target.checked
          }
          if (v.value) {
            values.push(v.label)
          }
        })
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
          additionalIndex
        ].value = values
      } else {
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].options.map(v => {
          if (v.label === label) {
            v.value = event.target.checked
          }
          if (v.value) {
            values.push(v.label)
          }
        })
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].value = values
      }
    } else {
      if (additionalIndex || additionalIndex === 0) {
        newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].options.map(
          v => {
            if (v.label === label) {
              v.value = event.target.checked
            }
            if (v.value) {
              values.push(v.label)
            }
          }
        )
        newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
          values
      } else {
        newSections[sectionIndex].fields[fieldIndex].options.map(v => {
          if (v.label === label) {
            v.value = event.target.checked
          }
          if (v.value) {
            values.push(v.label)
          }
        })
        newSections[sectionIndex].fields[fieldIndex].value = values
      }
    }

    setSections(newSections)
  }

  const onChangeRadioButton = (
    event,
    label,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    let newSections = [...sections]
    if (innerSectionIndex || innerSectionIndex === 0) {
      if (additionalIndex || additionalIndex === 0) {
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
          additionalIndex
        ].value = label
      } else {
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].value = label
      }
    } else {
      if (additionalIndex || additionalIndex === 0) {
        newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
          label
      } else {
        newSections[sectionIndex].fields[fieldIndex].value = label
      }
    }
    setSections(newSections)
  }

  const onChangeValueField = (event, additionalIndex, fieldIndex, sectionIndex) => {
    let newSections = [...sections]
    if (additionalIndex) {
      switch (newSections[sectionIndex].fields[fieldIndex].field_type.value) {
        case 'TEXT_FIELD': {
          newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
            event.target.value
          break
        }
        case 'TEXT_AREA': {
          newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
            event.target.value
          break
        }
        case 'DROPDOWN': {
          newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
            event.value
          break
        }
        case 'DROPDOWN_MASTER_DATA': {
          newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
            event.value
          break
        }
        case 'CHECKBOX': {
          newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
            event.target.checked
          break
        }
        case 'RADIO_BUTTON': {
          newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
            event.target.checked
          break
        }
        case 'DATE_PICKER': {
          newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
            event.target.value
          break
        }
        case 'SIGNATURE': {
          newSections[sectionIndex].fields[fieldIndex].additional_option[additionalIndex].value =
            event
          break
        }
        default:
          break
      }
    } else {
      switch (newSections[sectionIndex].fields[fieldIndex].field_type.value) {
        case 'TEXT_FIELD': {
          newSections[sectionIndex].fields[fieldIndex].value = event.target.value
          break
        }
        case 'TEXT_AREA': {
          newSections[sectionIndex].fields[fieldIndex].value = event.target.value
          break
        }
        case 'DROPDOWN': {
          newSections[sectionIndex].fields[fieldIndex].value = event.value
          break
        }
        case 'DROPDOWN_MASTER_DATA': {
          newSections[sectionIndex].fields[fieldIndex].value = event
          newSections[sectionIndex].fields.map(v => {
            if (
              newSections[sectionIndex].fields[fieldIndex].field_id === v.master_data.parentFieldId
            ) {
              v.master_data.contentId = event.id
            }
          })
          break
        }
        case 'CHECKBOX': {
          newSections[sectionIndex].fields[fieldIndex].value = event.target.checked
          break
        }
        case 'RADIO_BUTTON': {
          newSections[sectionIndex].fields[fieldIndex].value = event.target.checked
          break
        }
        case 'DATE_PICKER': {
          newSections[sectionIndex].fields[fieldIndex].value = event.target.value
          break
        }
        case 'SIGNATURE': {
          newSections[sectionIndex].fields[fieldIndex].value = event
          break
        }
        default:
          break
      }
    }

    setSections(newSections)
  }

  const onChangeValueInnerSectionField = (
    event,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    let newSections = [...sections]
    if (additionalIndex) {
      switch (
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].field_type.value
      ) {
        case 'TEXT_FIELD': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
            additionalIndex
          ].value = event.target.value
          break
        }
        case 'TEXT_AREA': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
            additionalIndex
          ].value = event.target.value
          break
        }
        case 'DROPDOWN': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
            additionalIndex
          ].value = event.value
          break
        }
        case 'DROPDOWN_MASTER_DATA': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
            additionalIndex
          ].value = event.value
          break
        }
        case 'DATE_PICKER': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
            additionalIndex
          ].value = event.target.value
          break
        }
        case 'SIGNATURE': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
            additionalIndex
          ].value = event
          break
        }
        default:
          break
      }
    } else {
      switch (
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].field_type.value
      ) {
        case 'TEXT_FIELD': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].value =
            event.target.value
          break
        }
        case 'TEXT_AREA': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].value =
            event.target.value
          break
        }
        case 'DROPDOWN': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].value = event.value
          break
        }
        case 'DROPDOWN_MASTER_DATA': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].value = event.value
          break
        }
        case 'DATE_PICKER': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].value =
            event.target.value
          break
        }
        case 'SIGNATURE': {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].value = event
          break
        }
        default:
          break
      }
    }

    setSections(newSections)
  }

  const onAddAttachment = async (
    file,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    let uploadedFile = null
    //create payload form data
    const formData = new FormData()
    formData.append('files[]', file)

    const res = await post(`${apiUrls.FORMS_URL}/attachments`, formData)
    if (res.ok) {
      uploadedFile = res.data[0]
      toast.success(<Toast message={`Success`} detailedMessage={`success to upload this file`} />)
    } else {
      toast.error(
        <Toast message={`Error`} detailedMessage={`failed to upload file ${file.name}`} />
      )
    }

    if (uploadedFile) {
      let newSections = [...sections]
      if (innerSectionIndex || innerSectionIndex === 0) {
        if (additionalIndex || additionalIndex === 0) {
          newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
            additionalIndex
          ].attachment_value.push(uploadedFile)
        } else {
          newSections[sectionIndex].inner_section[innerSectionIndex][
            fieldIndex
          ].attachment_value.push(uploadedFile)
        }
      } else {
        if (additionalIndex || additionalIndex === 0) {
          newSections[sectionIndex].fields[fieldIndex].additional_option[
            additionalIndex
          ].attachment_value.push(uploadedFile)
        } else {
          newSections[sectionIndex].fields[fieldIndex].attachment_value.push(uploadedFile)
        }
      }
      setSections(newSections)
    }
  }

  const onRemoveAttachment = (
    fileIndex,
    additionalIndex,
    fieldIndex,
    innerSectionIndex,
    sectionIndex
  ) => {
    let newSections = [...sections]
    if (innerSectionIndex || innerSectionIndex === 0) {
      if (additionalIndex || additionalIndex === 0) {
        newSections[sectionIndex].inner_section[innerSectionIndex][fieldIndex].additional_option[
          additionalIndex
        ].attachment_value.splice(fileIndex, 1)
      } else {
        newSections[sectionIndex].inner_section[innerSectionIndex][
          fieldIndex
        ].attachment_value.splice(fileIndex, 1)
      }
    } else {
      if (additionalIndex || additionalIndex === 0) {
        newSections[sectionIndex].fields[fieldIndex].additional_option[
          additionalIndex
        ].attachment_value.splice(fileIndex, 1)
      } else {
        newSections[sectionIndex].fields[fieldIndex].attachment_value.splice(fileIndex, 1)
      }
    }

    setSections(newSections)
  }

  const onDeleteAttachment = index => {
    const attachments = [...newAttachments]
    attachments.splice(index, 1)
    setNewAttachments(attachments)
  }

  const onChangeValueSubSectionField = (
    event,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    let newSections = [...sections]
    if (additionalIndex || additionalIndex === 0) {
      switch (newSections[sectionIndex].fields[fieldIndex].field_type.value) {
        case 'TEXT_FIELD': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].value = event.target.value
          break
        }
        case 'TEXT_AREA': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].value = event.target.value
          break
        }
        case 'DROPDOWN': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].value = event.value
          break
        }
        case 'DROPDOWN_MASTER_DATA': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].value = event.value
          break
        }
        case 'CHECKBOX': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].value = event.target.checked
          break
        }
        case 'RADIO_BUTTON': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].value = event.target.checked
          break
        }
        case 'DATE_PICKER': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].value = event.target.value
          break
        }
        case 'SIGNATURE': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].value = event
          break
        }
        default:
          break
      }
    } else {
      switch (
        newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].field_type.value
      ) {
        case 'TEXT_FIELD': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value =
            event.target.value
          break
        }
        case 'TEXT_AREA': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value =
            event.target.value
          break
        }
        case 'DROPDOWN': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value =
            event.value
          break
        }
        case 'DROPDOWN_MASTER_DATA': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value = event
          newSections[sectionIndex].sub_sections[subSectionIndex].fields.map(v => {
            if (
              newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex]
                .field_id === v.master_data.parentFieldId
            )
              v.master_data.contentId = event.id
          })
          break
        }
        case 'CHECKBOX': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value =
            event.target.checked
          break
        }
        case 'RADIO_BUTTON': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value =
            event.target.checked
          break
        }
        case 'DATE_PICKER': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value =
            event.target.value
          break
        }
        case 'SIGNATURE': {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value = event
          break
        }
        default:
          break
      }
    }

    setSections(newSections)
  }

  const onChangeSubSectionCheckBox = (
    event,
    label,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    let newSections = [...sections]
    const values = []
    if (additionalIndex || additionalIndex === 0) {
      newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].additional_option[
        additionalIndex
      ].options.map(v => {
        if (v.label === label) {
          v.value = event.target.checked
        }
        if (v.value) {
          values.push(v.label)
        }
      })
      newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].additional_option[
        additionalIndex
      ].value = values
    } else {
      newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].options.map(v => {
        if (v.label === label) {
          v.value = event.target.checked
        }
        if (v.value) {
          values.push(v.label)
        }
      })
      newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value = values
    }

    setSections(newSections)
  }

  const onChangeSubSectionRadio = (
    event,
    label,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    let newSections = [...sections]
    if (additionalIndex || additionalIndex === 0) {
      newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].additional_option[
        additionalIndex
      ].value = label
    } else {
      newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].value = label
    }
    setSections(newSections)
  }

  const onAddSubSectionAttachment = async (
    file,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    let uploadedFile = null
    //create payload form data
    const formData = new FormData()
    formData.append('files[]', file)

    const res = await post(`${apiUrls.FORMS_URL}/attachments`, formData)
    if (res.ok) {
      uploadedFile = res.data[0]
      toast.success(<Toast message={`Success`} detailedMessage={`success to upload this file`} />)
    } else {
      toast.error(
        <Toast message={`Error`} detailedMessage={`failed to upload file ${file.name}`} />
      )
    }

    if (uploadedFile) {
      let newSections = [...sections]
      if (additionalIndex || additionalIndex === 0) {
        if (
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex]
            .additional_option[additionalIndex].attachment_value &&
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex]
            .additional_option[additionalIndex].attachment_value.length
        ) {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].attachment_value.push(uploadedFile)
        } else {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].additional_option[additionalIndex].attachment_value = [uploadedFile]
        }
      } else {
        if (
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex]
            .attachment_value &&
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex]
            .attachment_value.length
        ) {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].attachment_value.push(uploadedFile)
        } else {
          newSections[sectionIndex].sub_sections[subSectionIndex].fields[
            fieldIndex
          ].attachment_value = [uploadedFile]
        }
      }
      setSections(newSections)
    }
  }

  const onRemoveSubSectionAttachment = (
    fileIndex,
    additionalIndex,
    fieldIndex,
    subSectionIndex,
    sectionIndex
  ) => {
    let newSections = [...sections]
    if (additionalIndex || additionalIndex === 0) {
      newSections[sectionIndex].sub_sections[subSectionIndex].fields[fieldIndex].additional_option[
        additionalIndex
      ].attachment_value.splice(fileIndex, 1)
    } else {
      newSections[sectionIndex].sub_sections[subSectionIndex].fields[
        fieldIndex
      ].attachment_value.splice(fileIndex, 1)
    }

    setSections(newSections)
  }

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex flex-col bg-white rounded-2xl p-6 gap-6">
        <div className="flex">
          <p className="font-semibold text-[16px] text-[#C800A5] self-center mr-3">
            Info Initiated
          </p>
          {form?.status ? (
            <span
              className={`${
                form?.status.toLowerCase() === 'submitted'
                  ? 'class-submitted'
                  : form?.status.toLowerCase().includes('approved')
                  ? 'class-approved'
                  : form?.status.toLowerCase().includes('revise')
                  ? 'class-sendback'
                  : 'class-ongoing'
              } text-[12px]`}
            >
              {titleCase(form?.status)}
            </span>
          ) : (
            ''
          )}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-[12px] text-[#232323]">Initiated</div>
            <div className="flex items-center gap-3">
              <img
                src={user?.picture ? user?.picture : ICONS.icAdminActive}
                className="w-6 h-6 rounded-full"
                alt="userpict"
              />
              <div className="text-[12px] text-[#6546C3]">{user?.full_name}</div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-[12px] text-[#232323]">Sent At</div>
            <div className="text-[12px] text-[#6546C3]">{sentAt}</div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-[12px] text-[#232323]">Company</div>
            <div className="text-[12px] text-[#6546C3]">{user?.company}</div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-[12px] text-[#232323]">ID Request</div>
            <div className="text-[12px] text-[#6546C3]">{form?.module_id || '-'}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-2xl p-6 gap-6">
        <div className="flex font-semibold text-[16px] text-[#C800A5]">Result Response</div>
        <div className="flex flex-col gap-6">
          {sections?.map((section, sectionIndex) => (
            <Section
              key={sectionIndex}
              section={section}
              onChangeValueField={(event, additionalIndex, fieldIndex) =>
                onChangeValueField(event, additionalIndex, fieldIndex, sectionIndex)
              }
              onChangeValueInnerSectionField={(
                event,
                additionalIndex,
                fieldIndex,
                innerSectionIndex
              ) =>
                onChangeValueInnerSectionField(
                  event,
                  additionalIndex,
                  fieldIndex,
                  innerSectionIndex,
                  sectionIndex
                )
              }
              onChangeCheckBox={(event, label, index, fieldIndex, innerSectionIndex) =>
                onChangeCheckBox(event, label, index, fieldIndex, innerSectionIndex, sectionIndex)
              }
              onChangeRadio={(event, label, index, fieldIndex, innerSectionIndex) =>
                onChangeRadioButton(
                  event,
                  label,
                  index,
                  fieldIndex,
                  innerSectionIndex,
                  sectionIndex
                )
              }
              onAddAttachment={(file, additionalIndex, fieldIndex, innerSectionIndex) =>
                onAddAttachment(file, additionalIndex, fieldIndex, innerSectionIndex, sectionIndex)
              }
              removeFile={(fileIndex, additionalIndex, fieldIndex, innerSectionIndex) =>
                onRemoveAttachment(
                  fileIndex,
                  additionalIndex,
                  fieldIndex,
                  innerSectionIndex,
                  sectionIndex
                )
              }
              onChangeValueSubSection={(event, additionalIndex, fieldIndex, subSectionIndex) =>
                onChangeValueSubSectionField(
                  event,
                  additionalIndex,
                  fieldIndex,
                  subSectionIndex,
                  sectionIndex
                )
              }
              onChangeSubCheckBox={(e, label, additionalIndex, fieldIndex, subSectionIndex) =>
                onChangeSubSectionCheckBox(
                  e,
                  label,
                  additionalIndex,
                  fieldIndex,
                  subSectionIndex,
                  sectionIndex
                )
              }
              onChangeSubRadio={(e, label, additionalIndex, fieldIndex, subSectionIndex) =>
                onChangeSubSectionRadio(
                  e,
                  label,
                  additionalIndex,
                  fieldIndex,
                  subSectionIndex,
                  sectionIndex
                )
              }
              onAddSubAttachment={(file, additionalIndex, fieldIndex, subSectionIndex) =>
                onAddSubSectionAttachment(
                  file,
                  additionalIndex,
                  fieldIndex,
                  subSectionIndex,
                  sectionIndex
                )
              }
              removeSubSectionFile={(fileIndex, additionalIndex, fieldIndex, subSectionIndex) =>
                onRemoveSubSectionAttachment(
                  fileIndex,
                  additionalIndex,
                  fieldIndex,
                  subSectionIndex,
                  sectionIndex
                )
              }
            />
          ))}
          {isHaveAttachment ? (
            <div className="flex flex-col bg-[#F2F5FC] rounded-2xl p-6">
              <div className="font-semibold text-[14px] text-[#C800A5]">Attachment</div>
              <hr className="mt-2 mb-6" />
              <div className="flex flex-col gap-2">
                <input
                  className="absolute w-full h-16 invisible cursor-pointer"
                  ref={inputRef}
                  type="file"
                  onChange={handleChangeAttachment}
                />
                <div
                  ref={drop}
                  className="flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px] border-[#6546C3]"
                >
                  <button
                    onClick={handleClick}
                    className="px-4 py-3 text-[12px] rounded-lg bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold"
                  >
                    Select Files...
                  </button>
                  <div className="flex-1 text-center text-[12px] text-[#6546C3]">
                    Drag file here to upload
                  </div>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-2 flex flex-col gap-4 mt-5">
                {newAttachments && newAttachments.length ? (
                  newAttachments.map((attachment, index) => {
                    return (
                      <div key={index}>
                        {mockData.extensionImages.includes(
                          getAttachmentType(attachment.fileName)
                        ) ? (
                          <div className="flex">
                            <div className="indicator">
                              <img
                                src={ICONS.icTrash}
                                className="indicator-item w-7 h-7 cursor-pointer"
                                alt={`delete attachment ${index}`}
                                onClick={() => onDeleteAttachment(index)}
                              />
                              <img
                                src={
                                  attachment.url ? attachment.url : URL.createObjectURL(attachment)
                                }
                                className="w-20 h-20 min-w-[80px] rounded-md"
                                alt={`attachment ${index}`}
                              />
                            </div>

                            <div className="my-6 mx-3">
                              <p className="font-poppins text-xs">{attachment.fileName}</p>
                              <p className="font poppins text-xs text-[#AAAAAA]">
                                {attachment.size / 1000} Kb
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="flex">
                            <div className="indicator bg-white rounded">
                              <img
                                src={ICONS.icTrash}
                                className="indicator-item w-7 h-7 cursor-pointer"
                                alt={`delete attachment ${index}`}
                              />
                              <img
                                src={getAttachmentImage(getAttachmentType(attachment.fileName))}
                                className="w-20 h-20"
                                alt={`attachment ${index}`}
                              />
                            </div>

                            <div className="my-6 mx-3 w-1/2">
                              <p className="font-poppins text-xs text-clip overflow-hidden">
                                {attachment.fileName}
                              </p>
                              <p className="font poppins text-xs text-[#AAAAAA]">
                                {attachment.size / 1000} Kb
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  })
                ) : (
                  <p className="text-[12px] pl-2 text-[##808080] italic align-middle">
                    No Attachment
                  </p>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-10">
        {isPic ? (
          <div className="gap-2 border-r-2 border-r-[#DEDEDE] pr-4">
            <button
              className="py-3 px-8 bg-gradient-to-r from-[#C800A5] to-[#FD79E6] text-white text-[12px] font-semibold rounded-md mr-3"
              onClick={() => handleButton('REVISE')}
            >
              Send Back
            </button>
            <button
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              onClick={() => handleButton('APPROVE')}
            >
              Approve
            </button>
          </div>
        ) : (
          ''
        )}
        <div className="gap-2">
          <button
            className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md mr-2"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            onClick={() => handleSubmit()}
            className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
          >
            Submit
          </button>
        </div>
      </div>
      <CommentPopup
        setContinue={handleSend}
        buttonNext={`Yes, ${action ? titleCase(action) : ''}`}
        action={action}
      />
      <SuccessPopup onClosePopup={() => navigate(-1)} />
    </div>
  )
}

export default EditSubmission
