import { useSelector } from 'react-redux'
import ReactLoading from 'react-loading'

const LoadingPopup = () => {
  const show = useSelector(state => state.popup.showPopupLoading)

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal bg-[#232323]/[0.5] overflow-y-auto py-6">
        <div
          className="modal-box bg-white relative w-fit h-fit flex justify-center items-center"
          onClick={e => e.stopPropagation()}
        >
          <ReactLoading type="spin" color="#6546C3" />
        </div>
      </div>
    </div>
  )
}

export default LoadingPopup
