import { Route, Routes } from 'react-router-dom'
import {
  RequireAuthentication,
  RequireNotAuth,
  RequireAuthorization,
  RequireMicrosite,
  RequireMicrositeAuthentication
} from './components'
import {
  ApplicationProfile,
  Area,
  Dashboard,
  Form,
  Issue,
  LoginPage,
  LogsPage,
  MainPage,
  ManageGroup,
  ManageUser,
  Microsite,
  MyActivity,
  Task,
  TaskProfile,
  VerificationPage,
  CreatePasswordPage,
  UnauthorizePage,
  DetailTaskPage,
  Site
} from './pages'
import AddFormPage from './pages/Form/AddFormPage'
import DetailFormPage from './pages/Form/DetailFormPage'
import DetailSubmission from './pages/Form/DetailFormPage/components/DetailSubmission'
import EditFormPage from './pages/Form/EditFormPage'
import AddGroupPage from './pages/ManageGroup/AddGroupPage'
import EditGroupPage from './pages/ManageGroup/EditGroupPage'
import AddUserPage from './pages/ManageUser/AddUserPage'
import EditUserPage from './pages/ManageUser/EditUserPage'
import AddTaskPage from './pages/Task/AddTaskPage'
import EditTaskPage from './pages/Task/EditTaskPage'
import AddMicrosite from './pages/Microsite/AddMicrosite'
import EditMicrosite from './pages/Microsite/EditMicrosite'
import DetailMicrosite from './pages/Microsite/DetailMicrosite'
import MasterData from './pages/MasterData'
import SiteLogin from './pages/Site/Login'
import SitePage from './pages/Site/Page'
import MasterDataDetail from './pages/MasterData/MasterDataDetail'
import SitePageFormCreate from './pages/Site/Page/TypeForm/Create'
import SitePageFormEdit from './pages/Site/Page/TypeForm/Edit'
import SitePageFormView from './pages/Site/Page/TypeForm/View'
import SitePageFormStaticField from './pages/Site/Page/TypeForm/StaticField'
import EditSubmission from './pages/Form/DetailFormPage/components/EditSubmission'
import AddApplication from './pages/ApplicationProfile/AddApplication'
import EditApplication from './pages/ApplicationProfile/EditApplication'
import DetailApplication from './pages/ApplicationProfile/DetailApplication'
import AddModule from './pages/ApplicationProfile/AddModule'
import EditModule from './pages/ApplicationProfile/EditModule'
import DetailModule from './pages/ApplicationProfile/DetailModule'

function App() {
  return (
    <Routes>
      <Route element={<RequireNotAuth />}>
        <Route path="Login" element={<LoginPage />} />
        <Route path="VerifyChangeEmail/:token" element={<VerificationPage />} />
        <Route path="VerifyEmail/:token" element={<CreatePasswordPage />} />
      </Route>
      <Route element={<RequireAuthentication />}>
        <Route path="/" element={<MainPage />}>
          <Route element={<RequireAuthorization pageName="Dashboard" permission="READ" />}>
            <Route path="Dashboard" element={<Dashboard />} />
          </Route>
          <Route element={<RequireAuthorization pageName="MyActivity" permission="READ" />}>
            <Route path="MyActivity" element={<MyActivity />} />
          </Route>

          <Route element={<RequireAuthorization pageName="Create/Task" permission="READ" />}>
            <Route path="Create/Task/AddTask" element={<AddTaskPage />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Create/Task" permission="CREATE" />}>
            <Route path="Create/Task/AddTask" element={<AddTaskPage />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Create/Task" permission="UPDATE" />}>
            <Route path="Create/Task/AddTask" element={<AddTaskPage />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Create/Task" permission="DELETE" />}>
            <Route path="Create/Task/AddTask" element={<AddTaskPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Form" permission="CREATE" />}
          >
            <Route path="DocumentLibrary/Form/AddForm" element={<AddFormPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Form" permission="READ" />}
          >
            <Route path="DocumentLibrary" element={<Form />} />
            <Route path="DocumentLibrary/Form" element={<Form />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Form" permission="CREATE" />}
          >
            <Route path="DocumentLibrary/Form/AddForm" element={<AddFormPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Form" permission="UPDATE" />}
          >
            <Route path="DocumentLibrary/Form/EditForm/:id" element={<EditFormPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Form" permission="READ" />}
          >
            <Route path="DocumentLibrary/Form/DetailForm/:id" element={<DetailFormPage />} />
            <Route
              path="DocumentLibrary/Form/DetailForm/:id/DetailSubmission/:ids"
              element={<DetailSubmission />}
            />
            <Route
              path="DocumentLibrary/Form/DetailForm/:id/EditSubmission/:ids"
              element={<EditSubmission />}
            />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Task" permission="READ" />}
          >
            <Route path="DocumentLibrary/Task" element={<Task />} />
            <Route path="DocumentLibrary/Task/DetailTask/:id" element={<DetailTaskPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Task" permission="CREATE" />}
          >
            <Route path="DocumentLibrary/Task/AddTask" element={<AddTaskPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Task" permission="UPDATE" />}
          >
            <Route path="DocumentLibrary/Task/EditTask/:id" element={<EditTaskPage />} />
            <Route path="DocumentLibrary/Task/DetailTask/:id" element={<DetailTaskPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Task" permission="CREATE" />}
          >
            <Route path="DocumentLibrary/Task/AddTask" element={<AddTaskPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Task" permission="UPDATE" />}
          >
            <Route path="DocumentLibrary/Task/EditTask/:id" element={<EditTaskPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="DocumentLibrary/Issue" permission="READ" />}
          >
            <Route path="DocumentLibrary/Issue" element={<Issue />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin" permission="READ" />}>
            <Route path="Admin" element={<Area />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/Area" permission="READ" />}>
            <Route path="Admin/Area" element={<Area />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="Admin/ApplicationProfile" permission="READ" />}
          >
            <Route path="Admin/ApplicationProfile" element={<ApplicationProfile />} />
          </Route>
          <Route
            element={
              <RequireAuthorization pageName="Admin/ApplicationProfile" permission="CREATE" />
            }
          >
            <Route
              path="Admin/ApplicationProfile/AddApplicationProfile"
              element={<AddApplication />}
            />
          </Route>
          <Route
            element={
              <RequireAuthorization pageName="Admin/ApplicationProfile" permission="UPDATE" />
            }
          >
            <Route
              path="Admin/ApplicationProfile/EditApplicationProfile/:id"
              element={<EditApplication />}
            />
          </Route>
          <Route
            element={<RequireAuthorization pageName="Admin/ApplicationProfile" permission="READ" />}
          >
            <Route
              path="Admin/ApplicationProfile/DetailApplicationProfile/:id"
              element={<DetailApplication />}
            />
          </Route>
          <Route
            element={
              <RequireAuthorization pageName="Admin/ApplicationProfile" permission="CREATE" />
            }
          >
            <Route
              path="Admin/ApplicationProfile/DetailApplicationProfile/:id/AddModule"
              element={<AddModule />}
            />
          </Route>
          <Route
            element={
              <RequireAuthorization pageName="Admin/ApplicationProfile" permission="UPDATE" />
            }
          >
            <Route
              path="Admin/ApplicationProfile/DetailApplicationProfile/:id/EditModule/:id_module"
              element={<EditModule />}
            />
          </Route>
          <Route
            element={<RequireAuthorization pageName="Admin/ApplicationProfile" permission="READ" />}
          >
            <Route
              path="Admin/ApplicationProfile/DetailApplicationProfile/:id/DetailModule/:id_module"
              element={<DetailModule />}
            />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/TaskProfile" permission="READ" />}>
            <Route path="Admin/TaskProfile" element={<TaskProfile />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/ManageGroup" permission="READ" />}>
            <Route path="Admin/ManageGroup" element={<ManageGroup />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="Admin/ManageGroup" permission="CREATE" />}
          >
            <Route path="Admin/ManageGroup/AddGroup" element={<AddGroupPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="Admin/ManageGroup" permission="UPDATE" />}
          >
            <Route path="Admin/ManageGroup/EditGroup/:id" element={<EditGroupPage />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/ManageGroup" permission="READ" />}>
            <Route path="Admin/ManageUser" element={<ManageUser />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="Admin/ManageGroup" permission="CREATE" />}
          >
            <Route path="Admin/ManageUser/AddUser" element={<AddUserPage />} />
          </Route>
          <Route
            element={<RequireAuthorization pageName="Admin/ManageGroup" permission="UPDATE" />}
          >
            <Route path="Admin/ManageUser/EditUser/:id" element={<EditUserPage />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/Microsite" permission="READ" />}>
            <Route path="Admin/Microsite" element={<Microsite />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/Microsite" permission="CREATE" />}>
            <Route path="Admin/Microsite/AddMicrosite" element={<AddMicrosite />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/Microsite" permission="UPDATE" />}>
            <Route
              path="Admin/Microsite/EditMicrosite/:microsite_route"
              element={<EditMicrosite />}
            />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/Microsite" permission="READ" />}>
            <Route
              path="Admin/Microsite/DetailMicrosite/:microsite_route"
              element={<DetailMicrosite />}
            />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/MasterData" permission="READ" />}>
            <Route path="Admin/MasterData" element={<MasterData />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/MasterData" permission="READ" />}>
            <Route path="Admin/MasterData/Detail/:id" element={<MasterDataDetail />} />
          </Route>
          <Route element={<RequireAuthorization pageName="Admin/Logs" permission="READ" />}>
            <Route path="Admin/Logs" element={<LogsPage />} />
          </Route>
          <Route path="Unauthorize" element={<UnauthorizePage />} />
        </Route>
      </Route>

      <Route path="Site" element={<RequireMicrosite />}>
        <Route path=":microsite/Login" element={<SiteLogin />} />
        <Route element={<RequireMicrositeAuthentication />}>
          <Route path=":microsite" element={<Site />}>
            <Route path="menu/:menu" element={<SitePage />} />
            <Route path="menu/:menu/duplicate-user/:id" element={<EditUserPage />} />
            <Route path="menu/:menu/edit-user/:id" element={<EditUserPage />} />
            <Route path="menu/:menu/add-user" element={<AddUserPage />} />
            <Route path="menu/:menu/create" element={<SitePageFormCreate />} />
            <Route path="menu/:menu/edit/:id" element={<SitePageFormEdit />} />
            <Route path="menu/:menu/view/:id" element={<SitePageFormView />} />
            <Route path="menu/:menu/sub-menu/:subMenu" element={<SitePage />} />
            <Route path="menu/:menu/sub-menu/:subMenu/create" element={<SitePageFormCreate />} />
            <Route path="menu/:menu/sub-menu/:subMenu/edit/:id" element={<SitePageFormEdit />} />
            <Route path="menu/:menu/sub-menu/:subMenu/view/:id" element={<SitePageFormView />} />
            <Route
              path="menu/:menu/sub-menu/:subMenu/create/:staticField"
              element={<SitePageFormStaticField />}
            />
            <Route
              path="menu/:menu/sub-menu/:subMenu/edit/:id/:staticField"
              element={<SitePageFormStaticField />}
            />
            <Route
              path="menu/:menu/sub-menu/:subMenu/view/:id/:staticField"
              element={<SitePageFormStaticField />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default App
