import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: null,
  refreshToken: null,
  micrositeTokens: {},
  uuid: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload
    },
    setMicrositeTokens: (state, action) => {
      state.micrositeTokens = action.payload
    },
    logout: state => {
      state.token = null
      state.refreshToken = null
      state.micrositeTokens = {}
    },
    logoutMicrosite: (state, action) => {
      // const { [action.payload]: remove, ...micrositeTokens } = state.micrositeTokens
      state.micrositeTokens = {}
    },
    saveUuid: (state, action) => {
      state.uuid = action.payload
    }
  }
})

export const { setToken, setRefreshToken, setMicrositeTokens, logout, logoutMicrosite, saveUuid } =
  authSlice.actions

export const selectToken = state => state.auth.token
export const selectRefreshToken = state => state.auth.refreshToken
export const selectMicrositeTokens = state => state.auth.micrositeTokens

export default authSlice.reducer
