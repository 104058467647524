import { useState, useEffect } from 'react'
import { ICONS, images, apiUrls } from '../../constants'
import { isMobile } from 'react-device-detect'
import ReactModal from 'react-modal'
import { get } from '../../api/base'
import { toast } from 'react-toastify'
import { Toast } from '../../components'

const ModalDetectDevice = () => {
  const [showApkModal, setShowApkModal] = useState(isMobile)
  const [showDownloadApkModal, setShowDownloadApkModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [latestVer, setLatestVer] = useState('')

  const handleOpenFromApp = () => {
    window.location.href = 'm2p://www.m2p-cms.banpuindo.co.id/deeplink'
    setShowApkModal(false)
  }
  const handleOnDownloadApkClick = () => {
    setShowApkModal(false)
    setShowDownloadApkModal(true)
  }
  const handleOnCancelDownload = () => {
    setShowApkModal(true)
    setShowDownloadApkModal(false)
  }
  const handleDownloaApk = () => {
    setShowDownloadApkModal(false)
    setShowSuccessModal(true)
    window.open(process.env.REACT_APP_APK_URL, '_blank')
  }

  const fetchLatestAppVersion = () => {
    get(apiUrls.LATEST_VERSION).then(response => {
      const { status } = response
      if (status === 200) {
        setLatestVer(response.data?.latest_version || '')
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={`Failed to fetch latest app version`} />
        )
      }
    })
  }

  useEffect(() => {
    if (showApkModal) {
      fetchLatestAppVersion()
    }
  }, [showApkModal])

  return (
    <div>
      <ReactModal
        isOpen={showApkModal}
        appElement={document.getElementById('root') || undefined}
        className="absolute bottom-0 left-0 right-0 rounded-2xl border-none m-2"
      >
        <div className="flex flex-col font-poppins">
          <img className="" src={images.bgApkPopup} />
          <div className="absolute w-full h-full p-3">
            <div className="flex flex-row items-center">
              <img className="w-12 h-12" src={ICONS.icM2pApps} />
              <div className="flex flex-col px-4">
                <div className="font-semibold text-[16px] text-white">M2P Superapp</div>
                <div className="font-semibold text-[10px] text-white">App Version {latestVer}</div>
                <div className="font-normal text-[10px] text-[#EBEDF8]">
                  Available for mobile android
                </div>
              </div>
            </div>
            <div className="flex flex-row w-[206px] bg-opacity-[0.16] rounded-lg bg-white pl-3 pr-2 py-1 mb-2 mt-2 items-center justify-between">
              <div className="font-normal text-[12px] text-[#EBEDF8]">M2P Application</div>
              <div className="flex flex-row gap-2 items-center">
                <img src={ICONS.icOpen} className="w-6 h-6" onClick={handleOpenFromApp} />
                <div className="w-[1px] h-[16px] bg-[#DEDEDE]"></div>
                <img
                  src={ICONS.icDownload}
                  className="w-6 h-6"
                  onClick={handleOnDownloadApkClick}
                />
              </div>
            </div>
            <div className="flex flex-row w-[206px] bg-opacity-[0.16] rounded-lg bg-white pl-3 pr-2 py-1 items-center justify-between">
              <div className="font-normal text-[12px] text-[#EBEDF8]">Open with browser</div>
              <img
                src={ICONS.icCevRightBg}
                className="w-6 h-6"
                onClick={() => setShowApkModal(false)}
              />
            </div>
          </div>
          <img
            src={ICONS.icCloseRound}
            className="w-6 h-6 absolute right-0 top-[-8px]"
            onClick={() => setShowApkModal(false)}
          />
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showDownloadApkModal}
        appElement={document.getElementById('root') || undefined}
        className="absolute bottom-0 left-0 right-0 rounded-2xl border-none"
      >
        <div className="flex flex-col font-poppins bg-white p-4 pt-6 rounded-t-[32px]">
          <div className="font-semibold text-[18px] text-[#232323] mb-[15px]">Confirmation</div>
          <hr />
          <div className="flex flex-col items-center">
            <img src={ICONS.icAndroidRounded} className="w-[120px] h-[120px] m-6" />
            <div className="font-semibold text-[14px] text-[#232323] mb-2">
              Do you want to install M2P Superapp?
            </div>
            <div className="font-normal text-[12px] text-[#AAAAAA] mb-[46px]">
              Click Ok to continue download android installler.
            </div>
          </div>
          <div className="flex flex-row">
            <button
              className="flex-1 p-3 border-[#6546C3] border-[2px] rounded-md text-[#6546C3] font-semibold text-[12px]"
              onClick={handleOnCancelDownload}
            >
              Cancel
            </button>
            <div className="w-6"></div>
            <button
              className="flex-1 p-3 rounded-md bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold text-[12px]"
              onClick={handleDownloaApk}
            >
              Ok
            </button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showSuccessModal}
        appElement={document.getElementById('root') || undefined}
        className="absolute bottom-0 left-0 right-0 rounded-2xl border-none"
      >
        <div className="flex flex-col font-poppins bg-white p-4 pt-6 rounded-t-[32px]">
          <div className="flex flex-col items-center">
            <img src={ICONS.icSuccess} className="w-[120px] h-[120px] m-[36px]" />
            <div className="font-semibold text-[18px] text-[#232323] mb-2">Successfully</div>
            <div className="font-normal text-[12px] text-[#AAAAAA] mb-[60px]">
              You have successfully download application
            </div>
          </div>
          <button
            className="flex-1 p-3 rounded-md bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold text-[12px]"
            onClick={() => setShowSuccessModal(false)}
          >
            OK
          </button>
        </div>
      </ReactModal>
    </div>
  )
}

export default ModalDetectDevice
