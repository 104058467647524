import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { get } from '../../../../../api/base'
import { DropdownIndicatorSite, Toast } from '../../../../../components'
import { apiUrls } from '../../../../../constants'
import { PageContext } from '../../..'

const Filter = ({ reports, handleOnChangeFilter }) => {
  const { topColor } = useContext(PageContext)
  const [companyList, setCompanyList] = useState([])
  const [contractorList, setContractorList] = useState([])
  const [contractor, setContractor] = useState(null)
  const [company, setCompany] = useState(null)
  const [filter, setFilter] = useState({
    company_code: null,
    contractor_code: null
  })
  const isSegregateByCompanies = reports ? reports[0].attributes.is_segregate_by_companies : false
  const isSegregateByContractors = reports
    ? reports[0].attributes.is_segregate_by_contractors
    : false

  const fetchCompanyList = async () => {
    const { ok, data } = await get(`${apiUrls.USER_PROFILE}/companies`)
    if (ok) {
      const companyList = data?.map(company => {
        return { ...company, label: company.name, value: company.alias_name }
      })
      setCompanyList(companyList)
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch companies'} />)
    }
  }

  const fetchContractorList = async () => {
    const { ok, data } = await get(`${apiUrls.USER_PROFILE}/contractors`)
    if (ok) {
      const contractorList = data?.map(contractor => {
        return {
          ...contractor,
          label: `${contractor.name} (${contractor.alias_name})`,
          value: contractor.alias_name
        }
      })
      setContractorList(contractorList)
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch contractors'} />)
    }
  }

  const handleCompanyChange = selectedOption => {
    const newFilter = { ...filter }
    setCompany(selectedOption)
    newFilter.company_code = selectedOption ? selectedOption.value : null
    setFilter({ ...filter, company_code: selectedOption ? selectedOption.value : null })

    handleOnChangeFilter(newFilter)
  }

  const handleContractorChange = selectedOption => {
    const newFilter = { ...filter }
    setContractor(selectedOption)
    newFilter.contractor_code = selectedOption ? selectedOption.value : null
    setFilter({ ...filter, contractor_code: selectedOption ? selectedOption.value : null })

    handleOnChangeFilter(newFilter)
  }

  const resetFilter = () => {
    setFilter({
      company_code: null,
      contractor_code: null
    })
    setCompany(null)
    setContractor(null)
    handleOnChangeFilter({ company_code: null, contractor_code: null })
  }

  useEffect(() => {
    fetchCompanyList()
    fetchContractorList()
  }, [])

  return (
    <div className="rounded-xl bg-white flex md:flex-row flex-col my-5 p-5 gap-x-3">
      <div className="grow">
        <div className="font-poppins text-sm font-semibold mb-2">Company</div>
        <Select
          components={{ DropdownIndicatorSite }}
          onChange={selectedOption => handleCompanyChange(selectedOption)}
          isClearable={true}
          options={companyList}
          value={company}
          isDisabled={!isSegregateByCompanies}
          name="company"
          className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4 grow`}
          placeholder="Select company"
          styles={{
            control: provided => ({
              ...provided,
              ':focus': { borderColor: '#6546C3' },
              ':active': { borderColor: '#6546C3' },
              ':hover': { borderColor: '#6546C3' },
              paddingLeft: 4,
              boxShadow: 'none'
            }),
            dropdownIndicator: base => ({
              ...base,
              color: '#00B1B5',
              ':hover': { color: '#6546C3' }
            }),
            indicatorSeparator: () => ({})
          }}
        />
      </div>
      <div className="grow">
        <div className="font-poppins text-sm font-semibold mb-2">Contractor</div>
        <Select
          components={{ DropdownIndicatorSite }}
          onChange={selectedOption => handleContractorChange(selectedOption)}
          isClearable={true}
          options={contractorList}
          isDisabled={!isSegregateByContractors}
          name="contractor"
          value={contractor}
          className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4 grow`}
          placeholder="Select contractor"
          styles={{
            control: provided => ({
              ...provided,
              ':focus': { borderColor: '#6546C3' },
              ':active': { borderColor: '#6546C3' },
              ':hover': { borderColor: '#6546C3' },
              paddingLeft: 4,
              boxShadow: 'none'
            }),
            dropdownIndicator: base => ({
              ...base,
              color: '#00B1B5',
              ':hover': { color: '#6546C3' }
            }),
            indicatorSeparator: () => ({})
          }}
        />
      </div>
      <button
        className="md:w-[200px] w-full justify-center text-white text-sm font-medium rounded-md md:mb-4 md:mt-7 md:py-0 py-2"
        style={{
          background: topColor || '#00B1B5'
        }}
        onClick={() => resetFilter()}
      >
        Reset
      </button>
    </div>
  )
}

export default Filter
