import { useController } from 'react-hook-form'

import { Editor } from '../../../../components'

export default function TypeHTML({ control, name, isEditing }) {
  const {
    field: { value, onChange }
  } = useController({ name, control })

  return (
    <div>
      <div className="font-semibold text-xs mb-2">Body</div>
      {isEditing && (
        <div className="rounded-xl border border-gray-200">
          <Editor value={value} onEditorChange={onChange} />
        </div>
      )}
      {!isEditing && <div className="unreset" dangerouslySetInnerHTML={{ __html: value }} />}
    </div>
  )
}
