import ReactLoading from 'react-loading'

const LoadingComponent = () => {
  return (
    <div className="bg-white w-screen h-screen flex flex-col gap-2 items-center justify-center">
      <p className="text-[20px] font-semibold">Authentication in progress</p>
      <ReactLoading type="balls" color="#6546C3" height={60} width={60} />
    </div>
  )
}

export default LoadingComponent
