import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { images } from '../../constants'
import { setShowPopupSuccess, setMessage } from '../../redux/slices/popupSlice'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ReactLoading from 'react-loading'

const VerificationPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useParams()
  const baseURL = process.env.REACT_APP_BASE_URL
  const config = {
    headers: {
      'Cache-Control': 'no-store',
      Authorization: `Bearer ${token}`
    }
  }
  const axiosInstance = axios.create({ config, baseURL, timeout: 10000 })
  const [user, setUser] = useState(null)
  const [isFailed, setIsFailed] = useState(false)

  axiosInstance.interceptors.response.use(
    response => {
      const { data, status } = response
      const ok = status && status >= 200 && status < 300
      return { ok, status, data }
    },
    error => {
      const { response, config, message } = error
      const { data, status } = response
      const ok = status && status >= 200 && status < 300
      return { ok, status, data }
    }
  )

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if (user) {
      handleVerifyEmail()
    }
  }, [user])

  const fetchUser = () => {
    axiosInstance
      .get(`${baseURL}/api/users/me`, config)
      .then(response => {
        const { status, data } = response
        if (status === 200) {
          setUser(data)
        } else {
          setIsFailed(true)
        }
      })
      .catch(e => {
        setIsFailed(true)
      })
  }

  const handleVerifyEmail = () => {
    axiosInstance
      .post(`${baseURL}/api/users/activate`, {}, config)
      .then(response => {
        const { status, data } = response
        if (status === 200) {
          handleOnSuccessVerifyEmail()
        } else {
          setIsFailed(true)
        }
      })
      .catch(e => {
        setIsFailed(true)
      })
  }

  const handleOnSuccessVerifyEmail = () => {
    navigate('/Login')
    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage('You have successfully verify change email'))
  }

  return (
    <div className="w-screen h-screen bg-[#F2F5FC] p-[16px] flex items-center justify-center">
      <div className="flex flex-col bg-white rounded-2xl p-6 justify-center items-center gap-2">
        {isFailed ? (
          <div className="font-poppins text-[20px] font-semibold text-black text-center">
            Failed to verify your email, please retry by refreshing this page.
          </div>
        ) : (
          <>
            <div className="font-poppins text-[20px] font-semibold text-black text-center">
              Verifying Your Email Address
            </div>
            <ReactLoading type="spin" color="#6546C3" height={100} width={100} />
          </>
        )}
      </div>
    </div>
  )
}

export default VerificationPage
