import { useEffect, useState } from 'react'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import { EditorState, AtomicBlockUtils } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { FileUploader } from 'react-drag-drop-files'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { get, put } from '../../../api/base'
import { SuccessPopup, Toast, Toggle } from '../../../components'
import { apiUrls, draftLogic, ICONS } from '../../../constants'
import { setMessage, setShowPopupSuccess } from '../../../redux/slices/popupSlice'
import { setShowPopupLeavePage } from '../../../redux/slices/popupSlice'
import LeavePagePopup from '../../../components/Popup/LeavePagePopup'
import SysAdminUserModal from '../components/SysAdminUserModal'
import VisibilityModal from '../components/VisibilityModal'

export default function EditApplication() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [detailData, setDetailData] = useState({})
  const [showSysAdminUserModal, setShowSysAdminUserModal] = useState(false)
  const [showVisibilityModal, setShowVisibilityModal] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      name: '',
      icon: '',
      authentication_api_link: '',
      contact_person: '',
      application_link: '',
      deeplink: '',
      authentication_parameter: '',
      description: EditorState.createEmpty(),
      active: false,
      sysadmin_user: null,
      visibility: false,
      work_location: []
    }
  })

  const form = watch()

  useEffect(() => {
    fetchApplication()
  }, [])

  async function fetchApplication() {
    await get(`${apiUrls.APPLICATIONS_URL}/${id}`).then(response => {
      const { status, data } = response
      if (status === 200) {
        //populate description
        if (data?.description) {
          const contentState = convertFromHTML(draftLogic.DRAFT_TO_HTML)(data?.description)
          data.description = EditorState.createWithContent(contentState)
        }
        setDetailData(data)
        reset({ ...form, ...data })
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={'Failed to fetch application data'} />
        )
      }
    })
  }

  // Handling image upload in text editor
  const handleImageUpload = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => {
        resolve({ data: { link: e.target.result } })
      }
      reader.onerror = e => {
        reject(e)
      }
      reader.readAsDataURL(file)
    })
  }

  // Handling paste image in text editor
  const handlePastedFiles = files => {
    Array.from(files).forEach(file => {
      if (file.type.includes('image')) {
        const reader = new FileReader()
        reader.onload = () => {
          const imageDataUrl = reader.result

          const contentStateWithEntity = form.description
            .getCurrentContent()
            .createEntity('IMAGE', 'IMMUTABLE', { src: imageDataUrl })
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
          const editorStateWithEntity = EditorState.set(form.description, {
            currentContent: contentStateWithEntity
          })
          const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorStateWithEntity,
            entityKey,
            ' '
          )
          const newState = EditorState.forceSelection(
            newEditorState,
            newEditorState.getCurrentContent().getSelectionAfter()
          )

          setValue('description', newState)
        }
        reader.readAsDataURL(file)
      }
    })
  }

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result
        resolve(base64String)
      }
      reader.onerror = error => {
        reject(error)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleSaveSysAdminUser = data => {
    setValue('sysadmin_user', data)
  }

  const handleSaveWorkLocation = data => {
    setValue('visibility', true)
    setValue('work_location', data)
  }

  async function onSubmit() {
    form.description = convertToHTML(draftLogic.HTML_TO_DRAFT)(form.description.getCurrentContent())
    if (form.icon !== detailData.icon) {
      const file = form.icon
      if (file) {
        const base64String = await convertFileToBase64(file)
        form.icon = base64String
      }
    }
    delete form.id
    delete form.modules

    put(`${apiUrls.APPLICATIONS_URL}/${id}`, form).then(response => {
      const { status, data } = response
      if (status === 200) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully edit application profile'))
        navigate('/Admin/ApplicationProfile', {
          state: { title: 'Application Profile', crumbs: ['Admin', 'Application Profile'] }
        })
      } else if (status === 401) {
        toast.error(<Toast message={`Error`} detailedMessage={`Unauthorized user.`} />)
      } else if (status === 422) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Failed to edit application profile. Invalid Data. ${
              data.error.errors[0].message || data.error.errors[0][0].message
            }`}
          />
        )
      } else if (status === 500) {
        toast.error(
          <Toast message={`Server Error`} detailedMessage={`Failed to edit application profile.`} />
        )
      } else if (status === 408) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Looks like the server is taking too long to respond. Please try again in while if still no update.`}
          />
        )
      }
    })
  }

  useEffect(() => {
    // hide some icon from editor
    document.querySelector('[title="Monospace"]').style.display = 'none'
    document.querySelector('[title="Superscript"]').style.display = 'none'
    document.querySelector('[title="Subscript"]').style.display = 'none'
    document.querySelector('[title="Indent"]').style.display = 'none'
    document.querySelector('[title="Outdent"]').style.display = 'none'
  }, [])

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid lg:grid-cols-3 gap-6 mx-2 md:mx-6 my-2"
      >
        {/* LOGO */}
        <div className="bg-white rounded-2xl p-2 sm:p-6 flex flex-col">
          <div className="flex items-center justify-between mb-6">
            <div className="font-semibold text-[#C800A5]">Icon App</div>
            <div className="text-xs italic text-purple-700">
              <small>will use as app profile</small>
            </div>
          </div>

          <div className="relative flex flex-grow items-center justify-center rounded-xl bg-[#F2F5FC] p-8">
            <Controller
              control={control}
              name="icon"
              render={({ field: { onChange, value } }) => (
                <>
                  <div className="absolute top-2 right-2 w-7 h-7 overflow-hidden">
                    <img src={ICONS.icEdit} className="w-full h-full" alt="edit icon" />
                    <FileUploader
                      handleChange={onChange}
                      classes="opacity-0 !absolute top-0 right-0 !w-full !h-full !min-w-0"
                      types={['jpg', 'png', 'jpeg']}
                    />
                  </div>

                  {value && value !== detailData.icon ? (
                    <img
                      src={URL.createObjectURL(value)}
                      className="max-w-full max-h-full"
                      alt="icon"
                    />
                  ) : value && value === detailData.icon ? (
                    <img src={detailData.icon} className="max-w-full max-h-full" alt="icon" />
                  ) : (
                    <img src={ICONS.icApp} className="w-28" alt="placeholder icon" />
                  )}
                </>
              )}
            />
          </div>
        </div>

        {/* INFO */}
        <div className="bg-white rounded-2xl p-2 sm:p-6 lg:col-span-2">
          <div className="mb-6">
            <div className="font-semibold text-[#C800A5]">Application Profile Info</div>
          </div>

          <div className="flex gap-8">
            <div className="flex-1">
              <div className="font-semibold text-[12px] mb-2">Application Name</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter application name"
                {...register('name', { required: true })}
              />
            </div>

            <div className="flex-1">
              <div className="font-semibold text-[12px] mb-2">Application Link</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter application link"
                {...register('application_link', { required: true })}
              />
            </div>
          </div>

          <div className="flex gap-8">
            <div className="flex-1">
              <div className="font-semibold text-[12px] mb-2">Application Deep Link</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter application deep link"
                {...register('deeplink')}
              />
            </div>

            <div className="flex-1">
              <div className="font-semibold text-[12px] mb-2">Authentication API Link</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter authentication API link"
                {...register('authentication_api_link')}
              />
            </div>
          </div>

          <div className="flex gap-8">
            <div className="flex-1">
              <div className="font-semibold text-[12px] mb-2">Authentication Parameter</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter authentication parameter"
                {...register('authentication_parameter')}
              />
            </div>

            <div className="flex-1">
              <div className="font-semibold text-[12px] mb-2">Contact Person</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter contact person"
                {...register('contact_person')}
              />
            </div>
          </div>

          <div className="flex flex-row gap-8">
            <div>
              <div className="font-semibold text-xs mb-4">Is Active</div>
              <Controller
                control={control}
                name="active"
                render={({ field: { onChange, value } }) => (
                  <Toggle checked={value} onChange={onChange} />
                )}
              />
            </div>
            <div>
              <div className="font-semibold text-xs mb-3.5">Visibility</div>
              <div className="flex flex-row gap-4 items-center">
                <Controller
                  control={control}
                  name="visibility"
                  render={({ field: { value } }) => {
                    const checkedClass =
                      "w-11 h-6 bg-[#6546C3] rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all after:translate-x-full after:border-white "
                    const unCheckedClass =
                      "w-11 h-6 bg-gray-200 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all"
                    return (
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={!!value}
                          onChange={event => {
                            if (event.target.checked) {
                              setShowVisibilityModal(true)
                            } else {
                              setValue('visibility', false)
                              setValue('work_location', [])
                            }
                          }}
                        />
                        <div className={value ? checkedClass : unCheckedClass}></div>
                      </label>
                    )
                  }}
                />
                <div
                  className={`${form.visibility ? 'block' : 'hidden'} w-7 h-7 cursor-pointer`}
                  onClick={() => setShowVisibilityModal(true)}
                >
                  <img src={ICONS.icEdit} className="w-full h-full" alt="edit icon" />
                </div>
              </div>
            </div>
            <div>
              <div className="font-semibold text-xs mb-3.5">SysAdmin User</div>
              <div className="flex flex-row gap-4 items-center">
                <Controller
                  control={control}
                  name="sysadmin_user"
                  render={({ field: { value } }) => {
                    const checkedClass =
                      "w-11 h-6 bg-[#6546C3] rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all after:translate-x-full after:border-white "
                    const unCheckedClass =
                      "w-11 h-6 bg-gray-200 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all"
                    return (
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={!!value}
                          onChange={event => {
                            if (event.target.checked) {
                              setShowSysAdminUserModal(true)
                            } else {
                              setValue('sysadmin_user', null)
                            }
                          }}
                        />
                        <div className={value ? checkedClass : unCheckedClass}></div>
                      </label>
                    )
                  }}
                />
                <div
                  className={`${form?.sysadmin_user ? 'block' : 'hidden'} w-7 h-7 cursor-pointer`}
                  onClick={() => setShowSysAdminUserModal(true)}
                >
                  <img src={ICONS.icEdit} className="w-full h-full" alt="edit icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* DESCRIPTION */}
        <div className="bg-white rounded-2xl p-2 sm:p-6 lg:col-span-3">
          <div className="font-semibold text-[12px] mb-2">Description</div>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <Editor
                toolbar={{
                  options: [
                    'inline',
                    'fontSize',
                    'list',
                    'textAlign',
                    'history',
                    'colorPicker',
                    'image'
                  ],
                  image: {
                    previewImage: true,
                    uploadEnabled: true,
                    uploadCallback: handleImageUpload,
                    alt: { present: false, mandatory: false }
                  }
                }}
                editorState={value}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onChange}
                handlePastedText={() => false}
                handlePastedFiles={handlePastedFiles}
              />
            )}
          />
        </div>

        <div className="flex justify-end gap-4 lg:col-span-3">
          <button
            type="button"
            onClick={() => dispatch(setShowPopupLeavePage(true))}
            className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
          >
            Submit
          </button>
        </div>
      </form>
      <LeavePagePopup
        onLeavePage={() => {
          navigate(-1)
        }}
      />
      <SysAdminUserModal
        showModal={showSysAdminUserModal}
        setShowModal={setShowSysAdminUserModal}
        handleSaveSysAdminUser={handleSaveSysAdminUser}
        currentData={form?.sysadmin_user}
      />
      <VisibilityModal
        showModal={showVisibilityModal}
        setShowModal={setShowVisibilityModal}
        handleSaveWorkLocation={handleSaveWorkLocation}
        currentData={form.work_location}
      />
      <SuccessPopup />
    </>
  )
}
