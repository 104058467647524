import { useEffect, useMemo, useRef, useState } from 'react'
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table'
import { toast } from 'react-toastify'
import { get, post } from '../../../api/base'
import { FormInput, Toast } from '../../../components'
import { apiUrls, ICONS, mockData } from '../../../constants'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="flex w-full md:w-fit font-poppins border-[1px] items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}

const AreaTable = () => {
  const [formAreaName, setFormAreaName] = useState('Haloo')
  const [formIsActive, setFormIsActive] = useState(true)

  const handleAreaNameChange = event => {
    setFormAreaName(event.target.value)
  }

  const COLUMNS = [
    {
      Header: 'Area Name',
      accessor: 'name',
      Cell: ({ row, value }) => {
        if (row.original.status === 'add') {
          return (
            <input
              id="areaName"
              type="text"
              placeholder="Enter area name"
              className="p-3 border-[1px] border-[#AAAAAA] rounded-lg"
            />
          )
        } else {
          return value
        }
      }
    },
    {
      Header: 'is Active',
      accessor: 'active',
      Cell: ({ row, value }) => {
        if (row.original.status === 'add') {
          return (
            <label
              htmlFor={`add-area-toggle`}
              className="flex relative items-center cursor-pointer"
            >
              <input
                type="checkbox"
                value=""
                id={`add-area-toggle`}
                className="sr-only peer"
                defaultChecked
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
            </label>
          )
        }
        return (
          <label
            htmlFor={`default-toggle-size-${row.id}`}
            className="flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value=""
              id={`default-toggle-size-${row.id}`}
              className="sr-only peer"
              defaultChecked={value}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
          </label>
        )
      },
      sortType: 'basic'
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        if (row.original.status === 'add') {
          return (
            <div className="flex gap-2">
              <img
                src={ICONS.icChecklistAction}
                className="w-[28px] cursor-pointer"
                onClick={() => postArea()}
              />
              <img
                src={ICONS.icRemoveForm}
                className="w-[28px] cursor-pointer"
                onClick={() => cancelAddArea()}
              />
            </div>
          )
        }
        return <img src={ICONS.icEdit} className="w-[28px] cursor-pointer" />
      }
    }
  ]
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const isAddRow = useRef(false)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state: { pageIndex, pageSize, globalFilter },
    nextPage,
    previousPage,
    gotoPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    pageCount,
    prepareRow,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  useEffect(() => {
    fetchAreas()
  }, [])

  function fetchAreas() {
    get(apiUrls.AREAS_URL).then(response => {
      const { status, data } = response
      if (status === 200) {
        setData(response.data.areas)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch areas'} />)
      }
      cancelAddArea()
    })
  }

  function postArea() {
    const name = document.getElementById('areaName').value
    const active = document.getElementById('add-area-toggle').checked
    const bodyParams = {
      name: name,
      active: active
    }
    post(apiUrls.AREAS_URL, bodyParams).then(response => {
      const { status } = response
      if (status === 201) {
        toast.success(
          <Toast message={`Success`} detailedMessage={`You have successfully add new area`} />
        )
      } else if (status === 400) {
        toast.error(<Toast message={`Error`} detailedMessage={`Area name already exist`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`Failed to add new area`} />)
      }
      cancelAddArea()
    })
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map(data => {
        if (data < 0)
          return (
            <div key={data} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={data}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageIndex === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => gotoPage(data)}
          >
            {data + 1}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(0)
      if (pageIndex - 2 > 0) paginationNumbers.push(-1)
      if (pageIndex - 1 > 0) paginationNumbers.push(pageIndex - 1)
      if (pageIndex !== 0 && pageIndex !== pageNumbers - 1) paginationNumbers.push(pageIndex)
      if (pageIndex + 1 < pageNumbers - 1) paginationNumbers.push(pageIndex + 1)
      if (pageIndex + 2 < pageNumbers - 1) paginationNumbers.push(-1)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers - 1)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function addArea() {
    if (!isAddRow.current) {
      const area = {
        areaId: pageSize + 1,
        areaName: 'Adding new',
        isActive: false,
        status: 'add'
      }
      setData([area, ...data])
      isAddRow.current = true
    }
  }

  function cancelAddArea() {
    if (isAddRow.current) {
      fetchAreas()
      isAddRow.current = false
    }
  }

  return (
    <>
      <div className="flex flex-col md:flex-row items-center gap-4 justify-between font-poppins text-[12px] mb-10">
        <button
          className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-8 py-3"
          onClick={() => addArea()}
        >
          Add Area
        </button>
        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
            />
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex w-full md:w-fit">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>
      <table {...getTableProps()} className="font-poppins text-[12px]">
        <thead className="">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex gap-1">
                    {column.render('Header')}
                    <img
                      src={
                        column.canSort
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? ICONS.icSortDown
                              : ICONS.icSortUp
                            : ICONS.icSort
                          : ''
                      }
                      className="w-2"
                    />
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(page.length > 0 &&
            page.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })) || (
            <tr>
              <td>No data</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-9">
        <div className="font-poppins text-[12px]">
          Showing {pageSize - (pageSize - 1) + pageIndex * 10} -{' '}
          {pageIndex === pageCount - 1 || pageIndex === 0
            ? data.length
            : pageSize * (pageIndex + 1)}{' '}
          of {data.length} data
        </div>
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(pageCount)}</div>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  )
}

export default AreaTable
