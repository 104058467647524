import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userData: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    deleteUserData: (state, action) => {
      state.userData = null
    }
  }
})

export const { setUserData, deleteUserData } = userSlice.actions

export const selectUserData = state => state.user.userData

export default userSlice.reducer
