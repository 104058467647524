import moment from 'moment'
import { Fragment, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import ReactSignatureCanvas from 'react-signature-canvas'

import { Select as Dropdown } from '../../../../../components'
import { ICONS } from '../../../../../constants'
import StaticField from '../components/Field/StaticField'

export default function Field({ fieldData, name, value }) {
  const fieldType = fieldData.field_type.value
  const fieldTitle = fieldData.field_title
  const fieldOptions = fieldData.options
  const attachment_value = fieldData.attachment_value
    ? fieldData.attachment_value.filter(att => !!att && Object.keys(att).length)
    : []

  const signatureCanvasRef = useRef()

  useEffect(() => {
    if (fieldType === 'SIGNATURE') {
      signatureCanvasRef.current.fromDataURL(value)
    }
  }, [fieldType, value])

  const renderField = (fieldType, fieldTitle, fieldOptions, name, value) => {
    switch (fieldType) {
      case 'TEXT_FIELD':
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <input
              type="text"
              className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
              placeholder={`Enter ${fieldTitle}`}
              value={value || ''}
              disabled
            />
          </div>
        )

      case 'TEXT_AREA':
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <textarea
              className="border rounded-lg p-2 text-xs"
              rows={5}
              value={value || ''}
              disabled
            />
          </div>
        )

      case 'CHECKBOX':
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex flex-col gap-1">
              {fieldOptions.map((option, o) => (
                <label key={o} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={Array.isArray(value) ? value.includes(option.label) : false}
                    name={name}
                    value={option.label}
                    disabled
                  />
                  <div className="text-[12px]">{option.label}</div>
                </label>
              ))}
            </div>
          </div>
        )

      case 'RADIO_BUTTON':
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex flex-col gap-1">
              {fieldOptions.map((option, o) => (
                <label key={o} className="flex items-center gap-2">
                  <input
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={value === option.value}
                    disabled
                  />
                  <div className="text-[12px]">{option.label}</div>
                </label>
              ))}
            </div>
          </div>
        )

      case 'DROPDOWN':
        const dropdownOptions = fieldData.options
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <Select
              isSearchable={false}
              options={dropdownOptions}
              name={fieldTitle}
              className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
              placeholder={`Select ${fieldTitle}`}
              value={value}
              isDisabled
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: 'none',
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  color: '#C800A5',
                  marginRight: '4px',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({})
              }}
            />
          </div>
        )

      case 'DATE_PICKER':
        return (
          <DatePickerField
            label={fieldTitle}
            value={value}
            format={fieldData.format || undefined}
          />
        )

      case 'SIGNATURE':
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="relative border rounded-lg bg-white text-xs">
              <div className="absolute inset-0 z-10" />
              <ReactSignatureCanvas
                ref={signatureCanvasRef}
                canvasProps={{ className: 'w-full h-[240px]' }}
                clearOnResize={false}
              />
            </div>
          </div>
        )

      case 'CHECKBOX_ATTACHMENT':
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex flex-col gap-1">
              {fieldOptions.map((option, index) => (
                <label key={index} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name={`${name}_${index}`}
                    checked={Array.isArray(value) ? value.includes(option.label) : false}
                    value={option.label}
                    disabled
                  />
                  <div className="text-[12px]">{option.label}</div>
                </label>
              ))}
            </div>
            <div className="flex flex-col gap-2">
              <hr />
              {attachment_value.map((attachment, a) => (
                <Fragment key={a}>
                  {a > 0 && <hr className="my-2" />}
                  <div className="flex items-center text-xs py-2">
                    <img src={attachment.thumbnail} className="w-20 h-20 mr-2" alt="file" />
                    <div>{attachment.fileName}</div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        )

      case 'RADIO_BUTTON_ATTACHMENT':
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex flex-col gap-1">
              {fieldOptions.map((option, index) => (
                <label key={index} className="flex items-center gap-2">
                  <input
                    type="radio"
                    name={`${name}_${index}`}
                    value={option.value}
                    checked={value === option.value}
                    disabled
                  />
                  <div className="text-[12px]">{option.label}</div>
                </label>
              ))}
            </div>
            <div className="flex flex-col gap-2">
              <hr />
              {attachment_value.map((attachment, a) => (
                <Fragment key={a}>
                  {a > 0 && <hr className="my-2" />}
                  <div className="flex items-center text-xs py-2">
                    <img src={attachment.thumbnail} className="w-20 h-20 mr-2" alt="file" />
                    <div>{attachment.fileName}</div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        )

      case 'DROPDOWN_MASTER_DATA':
        return (
          <Dropdown
            value={value}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            isDisabled
          />
        )

      case 'STATIC_FIELD':
        return (
          <StaticField
            title={fieldTitle}
            value={fieldData?.description}
            height={fieldData?.height}
            fieldId={fieldData.field_id}
          />
        )

      default:
        return <></>
    }
  }

  return renderField(fieldType, fieldTitle, fieldOptions, name, value)
}

const DatePickerField = ({ label, format = 'dd-MMM-yyyy HH:mm', value = null }) => {
  let valueFormat = format.replace(/d/g, 'D').replace(/y/g, 'Y')
  let adjustedFormat = format.replace(/D/g, 'd').replace(/Y/g, 'y')

  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{label}</div>
      <div className="flex relative">
        <DatePicker
          selected={!value ? null : moment(value, valueFormat).toDate()}
          dateFormat={adjustedFormat}
          className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
          disabled
        />
        <img
          src={ICONS.icCalendar}
          className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
          alt="calendar"
        />
      </div>
    </div>
  )
}
