export default function Toggle({ checked = false, onChange = value => undefined }) {
  const checkedClass =
    "w-11 h-6 bg-[#6546C3] rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all after:translate-x-full after:border-white "
  const unCheckedClass =
    "w-11 h-6 bg-gray-200 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all"

  return (
    <label className="inline-flex relative items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <div className={checked ? checkedClass : unCheckedClass}></div>
    </label>
  )
}
