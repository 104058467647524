import { useEffect, useState } from 'react'
import { convertToHTML } from 'draft-convert'
import { EditorState, AtomicBlockUtils } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { FileUploader } from 'react-drag-drop-files'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { post, get } from '../../../api/base'
import { Toast, Toggle } from '../../../components'
import { apiUrls, draftLogic, ICONS } from '../../../constants'
import { setMessage, setShowPopupSuccess } from '../../../redux/slices/popupSlice'

export default function AddModule() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    defaultValues: {
      name: '',
      icon: '',
      areas: [],
      deeplink: '',
      description: EditorState.createEmpty(),
      active: false
    }
  })

  const form = watch()
  const [listOfArea, setListOfArea] = useState([])
  const [totalAreaChecked, setTotalAreaChecked] = useState(0)
  const [searchAreaQuery, setSearchAreaQuery] = useState('')

  const handleOnChangeCheckArea = (id, event) => {
    var newListOfArea = listOfArea.map(area =>
      area.id === id ? { ...area, checked: event.target.checked } : area
    )
    setListOfArea(newListOfArea)

    var checkedArea = newListOfArea.filter(area => {
      if (area.checked) {
        return area
      }
    })
    var areas = checkedArea.map(area => {
      return area.id
    })
    setValue('areas', areas)
  }

  useEffect(() => {
    let counter = 0
    listOfArea.map(area => {
      if (area.checked) {
        counter++
      }
    })
    setTotalAreaChecked(counter)
  }, [listOfArea])

  useEffect(() => {
    // hide some icon from editor
    document.querySelector('[title="Monospace"]').style.display = 'none'
    document.querySelector('[title="Superscript"]').style.display = 'none'
    document.querySelector('[title="Subscript"]').style.display = 'none'
    document.querySelector('[title="Indent"]').style.display = 'none'
    document.querySelector('[title="Outdent"]').style.display = 'none'

    fetchArea()
  }, [])

  async function fetchArea() {
    await get(`${apiUrls.AREAS_URL}`).then(response => {
      const { status, data } = response
      if (status === 200) {
        setListOfArea(data.areas)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch area list'} />)
      }
    })
  }

  // Handling image upload in text editor
  const handleImageUpload = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => {
        resolve({ data: { link: e.target.result } })
      }
      reader.onerror = e => {
        reject(e)
      }
      reader.readAsDataURL(file)
    })
  }

  // Handling paste image in text editor
  const handlePastedFiles = files => {
    Array.from(files).forEach(file => {
      if (file.type.includes('image')) {
        const reader = new FileReader()
        reader.onload = () => {
          const imageDataUrl = reader.result

          const contentStateWithEntity = form.description
            .getCurrentContent()
            .createEntity('IMAGE', 'IMMUTABLE', { src: imageDataUrl })
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
          const editorStateWithEntity = EditorState.set(form.description, {
            currentContent: contentStateWithEntity
          })
          const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorStateWithEntity,
            entityKey,
            ' '
          )
          const newState = EditorState.forceSelection(
            newEditorState,
            newEditorState.getCurrentContent().getSelectionAfter()
          )

          setValue('description', newState)
        }
        reader.readAsDataURL(file)
      }
    })
  }

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result
        resolve(base64String)
      }
      reader.onerror = error => {
        reject(error)
      }
      reader.readAsDataURL(file)
    })
  }

  async function onSubmit() {
    form.description = convertToHTML(draftLogic.HTML_TO_DRAFT)(form.description.getCurrentContent())
    const file = form.icon
    if (file) {
      const base64String = await convertFileToBase64(file)
      form.icon = base64String
    }

    post(`${apiUrls.APPLICATIONS_URL}/${id}/modules`, form).then(response => {
      const { status, data } = response
      if (status === 201) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully add module/facility'))
        navigate(`/Admin/ApplicationProfile/DetailApplicationProfile/${id}`, {
          state: {
            title: 'Detail Application Profile',
            crumbs: ['Admin', 'Application Profile', 'Detail Application Profile']
          }
        })
      } else if (status === 401) {
        toast.error(<Toast message={`Error`} detailedMessage={`Unauthorized user.`} />)
      } else if (status === 422) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Failed to add module. Invalid Data. ${
              data.error.errors[0].message || data.error.errors[0][0].message
            }`}
          />
        )
      } else if (status === 500) {
        toast.error(<Toast message={`Server Error`} detailedMessage={`Failed to add module.`} />)
      } else if (status === 408) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Looks like the server is taking too long to respond. Please try again in while if still no update.`}
          />
        )
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid lg:grid-cols-3 gap-6 mx-2 md:mx-6 my-2">
      {/* LOGO */}
      <div className="bg-white rounded-2xl p-2 sm:p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="font-semibold text-[#C800A5]">Icon Module</div>
          <div className="text-xs italic text-purple-700">
            <small>will use as module app</small>
          </div>
        </div>

        <div className="relative flex items-center justify-center rounded-xl bg-[#F2F5FC] h-60 p-8">
          <Controller
            control={control}
            name="icon"
            render={({ field: { onChange, value } }) => (
              <>
                <div className="absolute top-2 right-2 w-7 h-7 overflow-hidden">
                  <img src={ICONS.icEdit} className="w-full h-full" alt="edit icon" />
                  <FileUploader
                    handleChange={onChange}
                    classes="opacity-0 !absolute top-0 right-0 !w-full !h-full !min-w-0"
                    types={['jpg', 'png', 'jpeg']}
                  />
                </div>

                {value ? (
                  <img
                    src={URL.createObjectURL(value)}
                    className="max-w-full max-h-full"
                    alt="icon"
                  />
                ) : (
                  <img src={ICONS.icDefaultModule} className="w-28" alt="placeholder icon" />
                )}
              </>
            )}
          />
        </div>
      </div>

      {/* INFO */}
      <div className="bg-white rounded-2xl p-2 sm:p-6 lg:col-span-2">
        <div className="mb-6">
          <div className="font-semibold text-[#C800A5]">Module/Facility Info</div>
        </div>

        <div className="flex flex-col sm:flex-row gap-6">
          <div className="flex flex-col flex-1">
            <div className="flex-1">
              <div className="font-semibold text-[12px] mb-2">Module Name</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter module name"
                {...register('name', { required: true })}
              />
            </div>

            <div className="flex-1">
              <div className="font-semibold text-[12px] mb-2">URL</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter url deeplink"
                {...register('deeplink')}
              />
            </div>

            <div className="flex-1">
              <div className="font-semibold text-xs mb-4">Is Active</div>
              <Controller
                control={control}
                name="active"
                render={({ field: { onChange, value } }) => (
                  <Toggle checked={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 h-60">
            <div className="flex-row flex-1 rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
              <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
                <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
                  Available Area
                </div>
                {totalAreaChecked !== 0 ? (
                  <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
                    {totalAreaChecked} Area Selected
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex-row px-4 py-3">
                <div className={`flex items-center relative mb-4 overflow-hidden`}>
                  <input
                    type="text"
                    placeholder="Search here"
                    className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                    value={searchAreaQuery}
                    onChange={event => {
                      setSearchAreaQuery(event.target.value)
                    }}
                  />
                  <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" />
                </div>
                <div className="flex-row max-h-[100px] min-h-[100px] overflow-y-auto scrollbar">
                  {listOfArea
                    .filter(area => {
                      return area.name
                        .toLowerCase()
                        .match(new RegExp(searchAreaQuery.toLowerCase(), ''))
                    })
                    .map(area => (
                      <div key={area.id} className="flex items-center justify-between mb-4">
                        <div className="flex flex-1 text-[12px]">{area.name}</div>
                        <input
                          type="checkbox"
                          className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                          checked={area.checked}
                          onChange={event => handleOnChangeCheckArea(area.id, event)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DESCRIPTION */}
      <div className="bg-white rounded-2xl p-2 sm:p-6 lg:col-span-3">
        <div className="font-semibold text-[12px] mb-2">Description</div>
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'fontSize',
                  'list',
                  'textAlign',
                  'history',
                  'colorPicker',
                  'image'
                ],
                image: {
                  previewImage: true,
                  uploadEnabled: true,
                  uploadCallback: handleImageUpload,
                  alt: { present: false, mandatory: false }
                }
              }}
              editorState={value}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onChange}
              handlePastedText={() => false}
              handlePastedFiles={handlePastedFiles}
            />
          )}
        />
      </div>

      <div className="flex justify-end gap-4 lg:col-span-3">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
        >
          Submit
        </button>
      </div>
    </form>
  )
}
