import React from 'react'

const UnauthorizePage = () => {
  return (
    <div className="p-6">
      You do not have the authority to see this page. Please log in using an authorized account.
    </div>
  )
}

export default UnauthorizePage
