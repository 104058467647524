import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showPopupLeavePage: false,
  showPopupSuccess: false,
  showPopupDelete: false,
  showPopupWorkflowList: false,
  showPopupLoading: false,
  showPopupComment: false,
  showPopupVerifyEmail: '',
  popupMessage: '',
  handleLeavePage: null
}

export const PopupSlice = createSlice({
  name: 'popup',
  initialState: initialState,
  reducers: {
    setShowPopupLeavePage: (state, action) => {
      state.showPopupLeavePage = action.payload
    },
    setShowPopupSuccess: (state, action) => {
      state.showPopupSuccess = action.payload
    },
    setShowPopupDelete: (state, action) => {
      state.showPopupDelete = action.payload
    },
    setShowPopupVerifyEmail: (state, action) => {
      state.showPopupVerifyEmail = action.payload
    },
    setShowWorkflowList: (state, action) => {
      state.showPopupWorkflowList = action.payload
    },
    setShowLoading: (state, action) => {
      state.showPopupLoading = action.payload
    },
    setShowComment: (state, action) => {
      state.showPopupComment = action.payload
    },
    setMessage: (state, action) => {
      state.popupMessage = action.payload
    },
    setHandleLeavePage: (state, action) => {
      state.handleLeavePage = action.payload
    }
  }
})

export const {
  setShowPopupLeavePage,
  setShowPopupSuccess,
  setShowPopupDelete,
  setShowPopupVerifyEmail,
  setShowWorkflowList,
  setShowLoading,
  setShowComment,
  setMessage,
  setHandleLeavePage
} = PopupSlice.actions

export default PopupSlice.reducer
