const BASE_IMAGE_PATH = '../assets/images'
const ilsLogin = require('../assets/images/ils_login.png')
const itmLogo = require('../assets/images/itm_logo.png')
const itmLogoWhite = require('../assets/images/itm_logo_white.png')
const noSection = require('../assets/images/no_section.png')
const bgApkPopup = require('../assets/images/bg_apk_popup.webp')
const m2pLogo = require('../assets/images/m2p_logo.webp')
const confirmation = require('../assets/images/confirmation.png')
const success = require('../assets/images/success.png')
const verification = require('../assets/images/verification.webp')
const ilsPassword = require('../assets/images/ils_password.webp')
const siteLogin = require('../assets/images/site_login.webp')

export default {
  ilsLogin,
  itmLogo,
  itmLogoWhite,
  noSection,
  bgApkPopup,
  m2pLogo,
  confirmation,
  success,
  verification,
  ilsPassword,
  siteLogin
}
