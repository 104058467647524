import { convertToHTML } from 'draft-convert'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { FileUploader } from 'react-drag-drop-files'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { post } from '../../../api/base'
import { Toast, Toggle } from '../../../components'
import { apiUrls, draftLogic, ICONS } from '../../../constants'
import { setMessage, setShowPopupSuccess } from '../../../redux/slices/popupSlice'
import { Tooltip } from 'react-tooltip'
import { ChromePicker } from 'react-color'
import { useState } from 'react'
import _ from 'lodash'

export default function AddMicrosite() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tooltipContent = `<div style="font-size:12px;">Use this color to enable the color <br />gradient effect</div>`
  const [showTopColorPicker, setShowTopColorPicker] = useState(false)
  const [showBottomColorPicker, setShowBottomColorPicker] = useState(false)

  const decimalToHex = alpha => (alpha === 0 ? '00' : Math.round(255 * alpha).toString(16))

  const handleColorChange = color => {
    let hexCode = color.hex
    if (color.rgb.a < 1) {
      hexCode = `${color.hex}${decimalToHex(color.rgb.a)}`
    }

    return hexCode.toUpperCase()
  }

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: null,
      description: EditorState.createEmpty(),
      file_logo: null,
      is_active: true,
      top_color: '',
      bottom_color: '',
      login_image: null
    }
  })

  async function onSubmit({
    name,
    description,
    file_logo,
    is_active,
    top_color,
    bottom_color,
    login_image
  }) {
    const form = new FormData()
    form.append('name', name)
    form.append(
      'description',
      convertToHTML(draftLogic.HTML_TO_DRAFT)(description.getCurrentContent())
    )
    form.append('file_logo', file_logo)
    form.append('is_active', is_active)
    form.append('top_color', _.isObject(top_color) ? handleColorChange(top_color) : top_color)
    form.append(
      'bottom_color',
      _.isObject(bottom_color) ? handleColorChange(bottom_color) : bottom_color
    )
    form.append('login_image', login_image)

    const { ok } = await post(apiUrls.LIST_MICROSITES, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (ok) {
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully add microsite'))
      navigate('/Admin/Microsite')
    } else {
      toast.error(<Toast message="Error" detailedMessage="Failed to add microsite." />)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid lg:grid-cols-3 gap-8 mx-2 md:mx-6 my-2">
      {/* LOGO */}
      <div className="bg-white rounded-2xl p-2 sm:p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="font-semibold text-[#C800A5]">Logo</div>
          <div className="text-xs italic text-purple-700">
            <small>will use as app</small>
          </div>
        </div>

        <div className="relative flex items-center justify-center rounded-xl bg-gray-100 h-60 p-8">
          <Controller
            control={control}
            name="file_logo"
            render={({ field: { onChange, value } }) => (
              <>
                <div className="absolute top-2 right-2 w-7 h-7 overflow-hidden">
                  <img src={ICONS.icEdit} className="w-full h-full" alt="edit icon" />
                  <FileUploader
                    handleChange={onChange}
                    classes="opacity-0 !absolute top-0 right-0 !w-full !h-full !min-w-0"
                    types={['jpg', 'png', 'jpeg']}
                  />
                </div>

                {value ? (
                  <img
                    src={URL.createObjectURL(value)}
                    className="max-w-full max-h-full"
                    alt="logo"
                  />
                ) : (
                  <img src={ICONS.icDefaultImage} className="w-28" alt="placeholder logo" />
                )}
              </>
            )}
          />
        </div>
      </div>

      {/* INFO */}
      <div className="bg-white rounded-2xl p-2 sm:p-6 lg:col-span-2 lg:row-span-2 flex flex-col">
        <div className="mb-6">
          <div className="font-semibold text-[#C800A5]">Microsite Info</div>
        </div>

        <div className="flex gap-8">
          <div className="flex-1">
            <div className="font-semibold text-[12px] mb-2">Name</div>
            <input
              className={`${
                errors.name ? 'empty-field ' : ''
              }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
              placeholder="Enter name"
              {...register('name', { required: true })}
            />
          </div>

          <div className="flex-1">
            <div className="font-semibold text-xs mb-4">Is Active</div>
            <Controller
              control={control}
              name="is_active"
              render={({ field: { onChange, value } }) => (
                <Toggle checked={value} onChange={onChange} />
              )}
            />
          </div>
        </div>

        <div className="flex gap-8">
          <div className="flex-1">
            <div className="font-semibold text-[12px] mb-2">Top Color</div>
            <Controller
              control={control}
              name="top_color"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <div className="relative">
                  <div className="relative">
                    <input
                      className={`${
                        errors.top_color ? 'empty-field ' : ''
                      }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4 pl-10`}
                      placeholder="Select color"
                      onFocus={() => setShowTopColorPicker(true)}
                      value={_.isObject(value) ? handleColorChange(value) : value ? value : ''}
                      onChange={onChange}
                    />
                    <div
                      className="rounded-lg w-7 h-7 border border-[#AAAAAA] absolute top-1.5 left-1.5 cursor-pointer"
                      style={{
                        background: value
                          ? _.isObject(value)
                            ? handleColorChange(value)
                            : value
                          : 'linear-gradient(135deg, transparent calc(50% - 1px), #C800A5 50%, transparent calc(50% + 1px))'
                      }}
                      onClick={() => setShowTopColorPicker(prevState => !prevState)}
                    />
                  </div>
                  {showTopColorPicker && (
                    <div className="absolute top-12 left-0 z-[9999] rounded-xl p-3 bg-white shadow-[0_5px_15px_0_rgba(0,0,0,0.08)]">
                      <ChromePicker
                        className="!shadow-none"
                        onChange={onChange}
                        color={_.isObject(value) ? value?.rgb : value}
                      />
                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={() => setShowTopColorPicker(false)}
                          className="py-1 px-4 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <div className="flex-1">
            <div className="flex gap-1 items-center mb-2">
              <div className="font-semibold text-xs">Bottom Color</div>
              <img
                src={ICONS.icInfo}
                alt="Help"
                className="w-[18px] h-[18px] cursor-pointer"
                data-tooltip-id="help-tooltip"
                data-tooltip-html={tooltipContent}
                data-tooltip-place="top-start"
              />
            </div>
            <Tooltip id="help-tooltip" place={'top'} className="z-[9999]" />
            <Controller
              control={control}
              name="bottom_color"
              render={({ field: { onChange, value } }) => (
                <div className="relative">
                  <div className="relative">
                    <input
                      className={`input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4 pl-10`}
                      placeholder="Select color"
                      onFocus={() => setShowBottomColorPicker(true)}
                      value={_.isObject(value) ? handleColorChange(value) : value ? value : ''}
                      onChange={onChange}
                    />
                    <div
                      className="rounded-lg w-7 h-7 border border-[#AAAAAA] absolute top-1.5 left-1.5 cursor-pointer"
                      style={{
                        background: value
                          ? _.isObject(value)
                            ? handleColorChange(value)
                            : value
                          : 'linear-gradient(135deg, transparent calc(50% - 1px), #C800A5 50%, transparent calc(50% + 1px))'
                      }}
                      onClick={() => setShowBottomColorPicker(prevState => !prevState)}
                    />
                  </div>
                  {showBottomColorPicker && (
                    <div className="absolute top-12 left-0 z-[9999] rounded-xl p-3 bg-white shadow-[0_5px_15px_0_rgba(0,0,0,0.08)]">
                      <ChromePicker
                        className="!shadow-none"
                        onChange={onChange}
                        color={_.isObject(value) ? value?.rgb : value}
                      />
                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={() => setShowBottomColorPicker(false)}
                          className="py-1 px-4 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        </div>

        <div className="mt-4 flex flex-grow">
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <Editor
                toolbar={{
                  options: ['blockType', 'inline']
                }}
                editorState={value}
                onEditorStateChange={onChange}
                wrapperClassName="rounded-xl border border-gray-200 !w-full"
                toolbarClassName="!border-0 !bg-gray-100 !p-2 !pb-0 !rounded-t-xl"
                editorClassName="px-3 py-2 !text-xs"
              />
            )}
          />
        </div>
      </div>

      {/* LOGIN IMAGE */}
      <div className="bg-white rounded-2xl p-2 sm:p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="font-semibold text-[#C800A5]">Login Image</div>
          <div className="text-xs italic text-purple-700">
            <small>will use as login screen</small>
          </div>
        </div>

        <div className="relative flex items-center justify-center rounded-xl bg-gray-100 h-60 p-8">
          <Controller
            control={control}
            name="login_image"
            render={({ field: { onChange, value } }) => (
              <>
                <div
                  className={`absolute top-2 ${
                    value ? 'right-10' : 'right-2'
                  } w-7 h-7 overflow-hidden`}
                >
                  <img src={ICONS.icEdit} className="w-full h-full" alt="edit icon" />
                  <FileUploader
                    handleChange={onChange}
                    classes="opacity-0 !absolute top-0 right-0 !w-full !h-full !min-w-0"
                    types={['jpg', 'png', 'jpeg']}
                  />
                </div>

                {value ? (
                  <>
                    <div className="absolute top-2 right-2 w-7 h-7 overflow-hidden">
                      <img
                        src={ICONS.icTrash}
                        className="w-full h-full cursor-pointer"
                        alt="delete icon"
                        onClick={() => onChange(null)}
                      />
                    </div>
                    <img
                      src={URL.createObjectURL(value)}
                      className="max-w-full max-h-full"
                      alt="logo"
                    />
                  </>
                ) : (
                  <img src={ICONS.icDefaultImage} className="w-28" alt="placeholder logo" />
                )}
              </>
            )}
          />
        </div>
      </div>

      <div className="flex justify-end gap-4 lg:col-span-3">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
        >
          Submit
        </button>
      </div>
    </form>
  )
}
