import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Select, SuccessPopup, Toast, DropdownIndicator } from '../../../../../components'
import { toast } from 'react-toastify'
import { ICONS, apiUrls } from '../../../../../constants'
import {
  setShowPopupSuccess,
  setMessage,
  setShowLoading
} from '../../../../../redux/slices/popupSlice'
import { FormContext } from '../..'
import { useTable } from 'react-table'
import { get } from '../../../../../api/base'

export default function ApiDetailModal({
  showModal,
  setShowModal,
  onSubmit,
  currentOtherMasterData
}) {
  const dispatch = useDispatch()
  const [application, setApplication] = useState(null)
  const [apiUrl, setApiUrl] = useState('')
  const [apiMethod, setApiMethod] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [groupDataName, setGroupDataName] = useState('')
  const [optionName, setOptionName] = useState('')
  const [optionId, setOptionId] = useState('')
  const { appApiList } = useContext(FormContext)
  const [showData, setShowData] = useState(false)
  const [data, setData] = useState([])

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'value'
    },
    {
      Header: optionName,
      accessor: 'label'
    }
  ]

  const columns = useMemo(() => COLUMNS, [optionName])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })

  useEffect(() => {
    if (showModal) {
      setApplication(currentOtherMasterData?.application || null)
      setApiUrl(currentOtherMasterData?.apiUrl || '')
      setApiMethod(currentOtherMasterData?.apiMethod)
      setKeyword(currentOtherMasterData?.keyword || '')
      setGroupDataName(currentOtherMasterData?.groupDataName || '')
      setOptionName(currentOtherMasterData?.optionName || '')
      setOptionId(currentOtherMasterData?.optionId || '')
    }
  }, [showModal])

  function apiDetailValidation() {
    if (!application) {
      toast.error(<Toast message={`Error`} detailedMessage={'Application Name is required'} />)
      return false
    }
    if (!apiUrl) {
      toast.error(<Toast message={`Error`} detailedMessage={'URL API is required'} />)
      return false
    }
    if (!apiMethod) {
      toast.error(<Toast message={`Error`} detailedMessage={'API method is required'} />)
      return false
    }
    if (!optionName) {
      toast.error(
        <Toast message={`Error`} detailedMessage={'Attribute Name as Option is required'} />
      )
      return false
    }
    if (!optionId) {
      toast.error(<Toast message={`Error`} detailedMessage={'Attribute Name as ID is required'} />)
      return false
    }
    return true
  }

  function onFormSubmit(e) {
    e.preventDefault()
    if (apiDetailValidation()) {
      let data = {
        application,
        apiUrl,
        apiMethod,
        keyword,
        optionName,
        optionId,
        groupDataName
      }

      onSubmit(data)
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully add other master data'))
      close()
    }
  }

  function getData() {
    if (apiDetailValidation()) {
      dispatch(setShowLoading(true))
      let query = {
        query: {
          application_id: application.application_id,
          url_api: apiUrl,
          method: apiMethod,
          keyword: keyword || undefined,
          option_name: optionName,
          option_id: optionId,
          group_data_name: groupDataName || undefined
        }
      }
      get(`${apiUrls.FORMS_URL}/other-master-data`, query).then(response => {
        const { status, data } = response
        if (status === 200) {
          dispatch(setShowLoading(false))
          setShowData(true)
          setData(data)
        } else if (status === 400 || status === 404) {
          toast.error(
            <Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />
          )
          dispatch(setShowLoading(false))
        } else {
          toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
          dispatch(setShowLoading(false))
        }
      })
    }
  }

  function close() {
    setShowModal(false)
    setApplication(null)
    setApiUrl('')
    setApiMethod(null)
    setKeyword('')
    setGroupDataName('')
    setOptionName('')
    setOptionId('')
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal  overflow-y-auto py-10 bg-[#23232350]">
        <div className="modal-box max-h-max m-auto bg-white w-fit md:w-[780px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">API Detail</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="mt-4 mb-6" />

          <form onSubmit={onFormSubmit} className="flex flex-col">
            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-3">Application Name</div>
              <Select
                components={{ DropdownIndicator }}
                getOptionLabel={option => option?.application_name}
                getOptionValue={option => option?.application_id}
                value={application}
                onChange={selectedOption => setApplication(selectedOption)}
                options={appApiList}
                className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Select application name"
                styles={{
                  control: provided => ({
                    ...provided,
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
                noOptionsMessage={() => 'No options, please add API Application first'}
              />
            </div>

            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">
                URL API <span className="italic font-normal">(e.g. /GetRole, /GetItemList)</span>
              </div>
              <input
                type="text"
                name="apiUrl"
                className="text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                placeholder="Enter URL API"
                value={apiUrl}
                onChange={event => setApiUrl(event.target.value)}
              />
              <div className="flex mt-4">
                <label className="flex flex-1 items-center select-none">
                  <input
                    type="radio"
                    name="apiMethod"
                    className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                    checked={apiMethod === 'GET'}
                    onChange={() => setApiMethod('GET')}
                  />
                  <div className="text-xs ml-2">Get</div>
                </label>
                <label className="flex flex-1 items-center select-none">
                  <input
                    type="radio"
                    name="apiMethod"
                    className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                    checked={apiMethod === 'POST'}
                    onChange={() => setApiMethod('POST')}
                  />
                  <div className="text-xs ml-2">Post</div>
                </label>
              </div>
            </div>

            <div className="flex-1 mb-4">
              <div className="font-semibold text-[12px] mb-2">Keyword</div>
              <textarea
                type="text"
                rows={5}
                className="text-[12px] px-[16px] py-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                placeholder="Enter keyword here"
                value={keyword}
                onChange={event => setKeyword(event.target.value)}
              />
            </div>

            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
              <div>
                <div className="font-semibold text-[12px] mb-2">Attribute Name as Option</div>
                <input
                  type="text"
                  className="text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                  placeholder="Enter attribute name for option"
                  value={optionName}
                  onChange={event => setOptionName(event.target.value)}
                />
              </div>
              <div>
                <div className="font-semibold text-[12px] mb-2">Attribute Name as ID</div>
                <input
                  type="text"
                  className="text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                  placeholder="Enter attribute name for ID"
                  value={optionId}
                  onChange={event => setOptionId(event.target.value)}
                />
              </div>
              <div>
                <div className="font-semibold text-[12px] mb-2">Group Data Name</div>
                <input
                  type="text"
                  className="text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                  placeholder="Enter group data name"
                  value={groupDataName}
                  onChange={event => setGroupDataName(event.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-end gap-4 mt-16">
              <button
                type="button"
                onClick={() => {
                  if (!showData) {
                    getData()
                  } else {
                    setShowData(false)
                  }
                }}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                {showData ? 'Hide Data' : 'Show Data'}
              </button>
              <button
                type="submit"
                className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Confirm
              </button>
            </div>

            {showData && (
              <>
                <hr className="my-6 border" />
                <table {...getTableProps()} className="w-full font-poppins text-[12px]">
                  <thead className="">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th key={index}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return (
                              <td {...cell.getCellProps()} className="!bg-transparent">
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </>
            )}
          </form>

          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
