import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filterForm: null,
  filterField: null,
  micrositeName: null
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    saveFilterForm: (state, action) => {
      const { micrositeName, payloadLocal } = action.payload
      if (state.filterForm?.hasOwnProperty(micrositeName)) {
        state.filterForm = {
          ...state.filterForm,
          [micrositeName]: { ...state.filterForm[micrositeName], ...payloadLocal }
        }
      } else {
        state.filterForm = {
          ...state.filterForm,
          [micrositeName]: payloadLocal
        }
      }
    },
    saveFilterField: (state, action) => {
      const { micrositeName, pageId, filterField } = action.payload
      state.filterForm[micrositeName][pageId]['selectedFields'] = filterField
    },
    removeFilterBasedOnMenu: (state, action) => {
      const { micrositeName, pageId } = action.payload
      delete state.filterForm[micrositeName][pageId]
    },
    removeFilter: () => initialState
  }
})

export const { saveFilterField, saveFilterForm, removeFilter, removeFilterBasedOnMenu } =
  formSlice.actions

export default formSlice.reducer
