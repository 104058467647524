import { useEffect, useState } from 'react'

import { SuccessPopup } from '../../../../../components'
import { ICONS, draftLogic } from '../../../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../../../redux/slices/popupSlice'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, Modifier, AtomicBlockUtils } from 'draft-js'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import ParameterModal from './components/ParameterModal'
import { useDispatch } from 'react-redux'
import ReactDOMServer from 'react-dom/server'

export default function PrintApprovalModal({
  showModal,
  setShowModal,
  setPrintApprovalFormat,
  sections,
  currentPrintApprovalFormat
}) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [showParameterModal, setShowParameterModal] = useState(false)
  const [parameterOptions, setParameterOptions] = useState([])
  const [selectedParameter, setSelectedParameter] = useState(null)
  const [count, setCount] = useState(0)
  const [param, setParam] = useState(currentPrintApprovalFormat.params)
  const [excludeBoxApproval, setExcludeBoxApproval] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // hide some icon from editor
    document.querySelector('[title="Monospace"]').style.display = 'none'
    document.querySelector('[title="Superscript"]').style.display = 'none'
    document.querySelector('[title="Subscript"]').style.display = 'none'
    document.querySelector('[title="Indent"]').style.display = 'none'
    document.querySelector('[title="Outdent"]').style.display = 'none'
  }, [])

  useEffect(() => {
    if (currentPrintApprovalFormat) {
      // Populate print approval format to text editor
      let innerPrintApprovalFormat = currentPrintApprovalFormat.format
      if (innerPrintApprovalFormat.startsWith('<div>')) {
        innerPrintApprovalFormat = innerPrintApprovalFormat.slice(5)
      }

      if (innerPrintApprovalFormat.endsWith('</div>')) {
        innerPrintApprovalFormat = innerPrintApprovalFormat.slice(0, -6)
      }

      const contentState = convertFromHTML(draftLogic.DRAFT_TO_HTML)(innerPrintApprovalFormat)
      setEditorState(EditorState.createWithContent(contentState))

      setParam(currentPrintApprovalFormat.params)
      setExcludeBoxApproval(currentPrintApprovalFormat?.exclude_box_approval || false)

      const lastIndex = Math.max(...currentPrintApprovalFormat?.params?.map(obj => obj.index))
      setCount(lastIndex > 0 ? lastIndex : 0)
    }

    if (sections) {
      function getMappedFields(dataArray) {
        const mappedFields = []
        dataArray.forEach(item => {
          mappedFields.push(...getFieldsWithAdditionalOption(item.fields))
          if (item.sub_sections) {
            item.sub_sections.forEach(subSection => {
              mappedFields.push(...getFieldsWithAdditionalOption(subSection.fields))
            })
          }
        })
        return mappedFields
      }

      function getFieldsWithAdditionalOption(fields) {
        const mappedFields = []
        fields.forEach(field => {
          if (field.additional_option && field.additional_option.length > 0) {
            field.additional_option.forEach(option => {
              mappedFields.push({
                field_id: field.field_id,
                field_title: field.field_title,
                additionalOption: option.title
              })
            })
          } else {
            mappedFields.push({
              field_id: field.field_id,
              field_title: field.field_title
            })
          }
        })
        return mappedFields
      }

      const result = getMappedFields(sections)
      setParameterOptions(result)
    }
  }, [showModal])

  useEffect(() => {
    if (selectedParameter) {
      sendTextToEditor(
        `<div><span style="color:rgb(85,57,130)"><strong>{{ ${count + 1}::${
          selectedParameter?.additionalOption
            ? selectedParameter.additionalOption
            : selectedParameter.field_title
        } }}</strong></span>&nbsp;</div>`
      )
      let currentParam = {
        index: count + 1,
        id: selectedParameter.field_id,
        ...(selectedParameter.additionalOption && {
          additionalOption: selectedParameter.additionalOption
        })
      }
      setParam([...param, currentParam])
      setCount(prevCount => prevCount + 1)
    }
  }, [selectedParameter])

  const sendTextToEditor = text => {
    const contentState = convertFromHTML(draftLogic.DRAFT_TO_HTML)(text)

    setEditorState(insertContentState(contentState, editorState))
  }

  const insertContentState = (contentState, editorValue) => {
    const currentContent = editorValue.getCurrentContent()
    const currentSelection = editorValue.getSelection()

    const newContent = Modifier.replaceWithFragment(
      currentContent,
      currentSelection,
      contentState.getBlockMap()
    )

    const newEditorState = EditorState.push(editorValue, newContent, 'insert-fragment')
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
  }

  // Handling image upload in text editor
  const handleImageUpload = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => {
        resolve({ data: { link: e.target.result } })
      }
      reader.onerror = e => {
        reject(e)
      }
      reader.readAsDataURL(file)
    })
  }

  // Handling paste image in text editor
  const handlePastedFiles = files => {
    Array.from(files).forEach(file => {
      if (file.type.includes('image')) {
        const reader = new FileReader()
        reader.onload = () => {
          const imageDataUrl = reader.result

          const contentStateWithEntity = editorState
            .getCurrentContent()
            .createEntity('IMAGE', 'IMMUTABLE', { src: imageDataUrl })
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
          const editorStateWithEntity = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
          })
          const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorStateWithEntity,
            entityKey,
            ' '
          )
          const newState = EditorState.forceSelection(
            newEditorState,
            newEditorState.getCurrentContent().getSelectionAfter()
          )

          setEditorState(newState)
        }
        reader.readAsDataURL(file)
      }
    })
  }

  const handleSaveFormat = () => {
    let data = convertToHTML(draftLogic.HTML_TO_DRAFT)(editorState.getCurrentContent())
    const newStringHTML = ReactDOMServer.renderToStaticMarkup(<HtmlWithNbsp htmlString={data} />)
    setPrintApprovalFormat({
      format: newStringHTML,
      params: param,
      exclude_box_approval: excludeBoxApproval
    })
    setShowModal(false)
    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage('You have successfully set up print approval'))
  }

  function replaceSpacesWithNbsp(text) {
    return text.replace(/  /g, '&nbsp;&nbsp;')
  }

  function HtmlWithNbsp({ htmlString }) {
    return <div dangerouslySetInnerHTML={{ __html: replaceSpacesWithNbsp(htmlString) }} />
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white max-w-full w-fit md:w-[850px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Set Up Print Approval</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={() => setShowModal(false)}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />

          <div className="relative">
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'fontSize',
                  'list',
                  'textAlign',
                  'history',
                  'colorPicker',
                  'image'
                ],
                image: {
                  previewImage: true,
                  uploadEnabled: true,
                  uploadCallback: handleImageUpload,
                  alt: { present: false, mandatory: false }
                }
              }}
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState}
              handlePastedText={() => false}
              handlePastedFiles={handlePastedFiles}
            />
            <button
              type="button"
              onClick={e => {
                e.stopPropagation()
                setShowParameterModal(true)
              }}
              className="absolute z-10 bottom-5 right-5 py-2 px-5 bg-[#6546C3] text-white text-[12px] font-semibold rounded-full"
            >
              + Parameter
            </button>
          </div>

          <div className="flex items-center gap-2 mt-5">
            <input
              type="checkbox"
              className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
              checked={excludeBoxApproval}
              onChange={() => setExcludeBoxApproval(prev => !prev)}
            />

            <div className="font-poppins font-normal text-[12px]">
              Exclude Printing Box Approval
            </div>
          </div>

          <div className="flex justify-end gap-4 mt-10">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Back
            </button>
            <button
              type="submit"
              onClick={() => handleSaveFormat()}
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            >
              Save
            </button>
          </div>
          <ParameterModal
            showModal={showParameterModal}
            setShowModal={setShowParameterModal}
            parameterOptions={parameterOptions}
            setSelectedParameter={setSelectedParameter}
          />
          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
