import MicrositeList from './MicrositeList'

const Microsite = () => {
  return (
    <div className="bg-white rounded-2xl p-2 sm:p-6 mx-2 md:mx-6 my-2">
      <MicrositeList />
    </div>
  )
}

export default Microsite
