import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Toast } from '../../../../../components'
import { toast } from 'react-toastify'
import { ICONS, apiUrls } from '../../../../../constants'
import { setShowLoading } from '../../../../../redux/slices/popupSlice'
import { useTable } from 'react-table'
import { get } from '../../../../../api/base'

export default function ShowDataApiDetailModal({
  showModal,
  setShowModal,
  currentOtherMasterData
}) {
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'value'
    },
    {
      Header: currentOtherMasterData?.optionName || 'Data',
      accessor: 'label'
    }
  ]

  const columns = useMemo(() => COLUMNS, [currentOtherMasterData?.optionName])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  })

  useEffect(() => {
    if (showModal && currentOtherMasterData) getData()
  }, [showModal])

  function getData() {
    dispatch(setShowLoading(true))
    let query = {
      query: {
        application_id: currentOtherMasterData.application.application_id,
        url_api: currentOtherMasterData.apiUrl,
        method: currentOtherMasterData.apiMethod,
        keyword: currentOtherMasterData.keyword || undefined,
        option_name: currentOtherMasterData.optionName,
        option_id: currentOtherMasterData.optionId,
        group_data_name: currentOtherMasterData.groupDataName || undefined
      }
    }
    get(`${apiUrls.FORMS_URL}/other-master-data`, query).then(response => {
      const { status, data } = response
      if (status === 200) {
        dispatch(setShowLoading(false))
        setData(data)
      } else if (status === 400 || status === 404) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
        dispatch(setShowLoading(false))
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
        dispatch(setShowLoading(false))
      }
    })
  }

  function close() {
    setShowModal(false)
    setData([])
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal  overflow-y-auto py-10 bg-[#23232350]">
        <div className="modal-box max-h-max m-auto bg-white w-fit md:w-[780px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Show Data API Detail</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="mt-4 mb-6" />

          <div className="w-full max-h-[300px] overflow-y-auto scrollbar">
            <table {...getTableProps()} className="w-full font-poppins text-[12px]">
              <thead className="">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th key={index}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()} className="!bg-transparent">
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end mt-8">
            <button
              type="button"
              onClick={close}
              className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
