import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Select, Toast, Toggle, SuccessPopup, DropdownIndicator } from '../../../components'
import { post, put, get } from '../../../api/base'
import { ICONS, apiUrls, images } from '../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../redux/slices/popupSlice'

const ChildList = ({ childList, onChangeCheckChild }) => {
  return (
    <div className="flex-row rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
      <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
        <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
          Child
        </div>
      </div>
      {childList.length ? (
        <div className="flex-row px-4 py-3">
          <div className="flex-row h-[200px] overflow-y-auto scrollbar">
            {childList.map(child => (
              <div key={child.id} className="flex items-center justify-between mb-4">
                <div className="font-poppins text-xs">{child.name}</div>
                <input
                  type="checkbox"
                  className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                  checked={child.checked}
                  onChange={event => onChangeCheckChild(child.id, event.target.checked)}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="flex-row px-4 py-3">
            <div className="h-[200px] flex flex-col justify-center items-center p-6">
              <img src={images.noSection} className="w-[100px] h-[100px] mb-6" alt="No Data" />
              <div className="font-semibold text-[18px] mb-2">No child yet</div>
              <div className="text-[12px] text-[#AAAAAA] mb-6">
                Master data has been made viewable and becomes child data.
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default function MasterDataModal({
  showModal,
  setShowModal,
  isEdit,
  setIsEdit,
  form,
  onSuccess
}) {
  const dispatch = useDispatch()
  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      is_active: form ? form.is_active : null,
      name: form ? form.name : null,
      childs: [],
      parent: null
    }
  })
  const [parentList, setParentList] = useState([])
  const [childList, setChildList] = useState([])
  const [tempChildList, setTempChildList] = useState([])
  const [tempParentList, setTempParentList] = useState([])

  const handleCloseModal = () => {
    setChildList([])
    setTempChildList([])
    setIsEdit(false)
    setShowModal(false)
    reset()
  }

  async function onSubmit({ name, is_active, parent, childs }) {
    const payload = {
      name: name,
      is_active: is_active,
      parent_id: parent ? parent.id : null,
      child_ids: childs
    }

    if (isEdit) {
      const { ok, data } = await put(`${apiUrls.MASTER_DATA}/${form.id}`, payload)
      if (ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully update master data'))
        handleCloseModal()
        onSuccess()
        reset()
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      }
    } else {
      const { ok, data } = await post(`${apiUrls.MASTER_DATA}`, payload)
      if (ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully add master data'))
        handleCloseModal()
        onSuccess()
        reset()
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      }
    }
  }

  function onChangeCheckChild(childId, event) {
    const newChild = getValues('childs')
    if (event) {
      newChild.push(childId)
    } else {
      const index = newChild.indexOf(childId)
      newChild.splice(index, 1)
    }

    const newChildList = []
    childList.map(v => {
      if (childId === v.id) {
        newChildList.push({ ...v, checked: event })
      } else {
        newChildList.push({ ...v })
      }
    })
    setChildList(newChildList)
    setValue('childs', newChild)
  }

  function checkChildList(item) {
    const newSelectedChild = getValues('childs')

    if (item) {
      const newChildList = []
      childList.map(v => {
        if (v.id !== item.id) newChildList.push(v)
      })

      if (newSelectedChild.includes(item.id)) {
        const index = newSelectedChild.indexOf(item.id)
        newSelectedChild.splice(index, 1)
        setValue('childs', newSelectedChild)
      }

      setChildList(newChildList)
    } else {
      const newChildList = []
      tempChildList.map(v => {
        if (newSelectedChild.includes(v.id)) {
          newChildList.push({ ...v, checked: true })
        } else newChildList.push({ ...v, checked: false })
      })
      setChildList(newChildList)
    }
  }

  const populateParentList = parentList => {
    parentList.map(v => {
      v.label = v.name
      v.value = v.id
    })
    setParentList(parentList)
  }

  const fetchListParentChild = async () => {
    const query = {
      sort_by: 'name',
      order_by: 'desc'
    }

    const { ok, data } = await get(`${apiUrls.MASTER_DATA}/all`, query)

    if (ok) {
      if (!isEdit) {
        populateParentList(data)
        setChildList(data)
      }
      const tempChildList = data.map(v => {
        return { ...v, label: v.name, value: v.id }
      })
      setTempChildList(data)
      setTempParentList(tempChildList)
    } else {
      toast.error(
        <Toast message={`Error`} detailedMessage={`failed to get list parent and child`} />
      )
    }
  }

  useEffect(() => {
    fetchListParentChild()
    if (isEdit) {
      setValue('name', form.name)
      setValue('is_active', form.is_active)

      //populate child list
      const selectedChild = []
      form.childs.map(v => {
        selectedChild.push(v.id)
      })
      setValue('childs', selectedChild)

      const checkedList = []
      tempChildList.map(v => {
        if (v.id !== form.id) {
          if (selectedChild.includes(v.id)) {
            checkedList.push({ ...v, checked: true })
          } else checkedList.push({ ...v })
        }
      })

      setChildList(checkedList)

      //populate parent list
      const newParentList = []
      tempParentList.map(v => {
        if (v.id !== form.id) {
          newParentList.push(v)
        }
      })
      setParentList(newParentList)
      setValue('parent', form.parent)
    } else {
      setValue('name', null)
      setValue('is_active', false)
      setValue('childs', [])
      setValue('parent', null)
    }
  }, [showModal])

  return (
    <>
      <input
        type="checkbox"
        id="master-data-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white w-fit md:w-[780px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">
              {isEdit ? 'Edit Master Data' : 'Add Master Data'}
            </div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={handleCloseModal}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />

          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col py-4 rounded-2xl">
            <div className="flex justify-end">
              <div className="grow mr-4">
                <div className="font-semibold text-[12px] mb-2">Master Data Name</div>
                <input
                  className={`${
                    errors.name ? 'empty-field ' : ''
                  }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                  placeholder="Enter master data name"
                  {...register('name', { required: true })}
                />
              </div>
              <div>
                <div className="font-semibold text-xs mb-4">Status</div>
                <Controller
                  control={control}
                  name="is_active"
                  render={({ field: { onChange, value } }) => (
                    <Toggle checked={value} onChange={onChange} />
                  )}
                />
              </div>
            </div>
            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">Parent</div>
              <Controller
                control={control}
                name="parent"
                render={({ field: { onChange, value } }) => (
                  <Select
                    components={{ DropdownIndicator }}
                    value={value}
                    onChange={selected => {
                      onChange(selected)
                      checkChildList(selected)
                    }}
                    options={parentList}
                    className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                    placeholder="Select parent"
                    isClearable={true}
                    styles={{
                      option: (provided, state) => ({
                        ...provided
                      }),
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({}),
                      valueContainer: provided => ({
                        ...provided
                      }),
                      multiValueLabel: provided => ({
                        ...provided,
                        color: '#fff',
                        fontFamily: 'poppins'
                      }),
                      multiValueRemove: provided => ({
                        ...provided,
                        color: '#fff'
                      }),
                      multiValue: provided => ({
                        ...provided,
                        backgroundColor: '#6546C3',
                        borderRadius: 8
                      })
                    }}
                  />
                )}
              />
            </div>
            <ChildList
              childList={childList}
              onChangeCheckChild={(childId, event) => onChangeCheckChild(childId, event)}
            />
            <br></br>
            <div className="flex justify-end gap-4 lg:col-span-3">
              <button
                type="button"
                onClick={() => handleCloseModal()}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Back
              </button>
              <button
                type="submit"
                className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Submit
              </button>
            </div>
          </form>
          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
