import { useEffect, useState } from 'react'

import { SuccessPopup, DropdownIndicator } from '../../../../../components'
import { ICONS } from '../../../../../constants'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { setShowPopupSuccess, setMessage } from '../../../../../redux/slices/popupSlice'
import ConfirmationPopup from '../../../../../components/Popup/ConfirmationPopup'

export default function AddParamModal({
  showModal,
  title,
  setShowModal,
  fieldsParam,
  setAddParamChecked,
  currentParams,
  setParamsWfe,
  currentDifferentRequestor,
  setDifferentRequestor
}) {
  const dispatch = useDispatch()
  const [listOfParams, setListOfParams] = useState([])
  const [searchParamQuery, setSearchParamQuery] = useState('')
  const [selectAllParam, setSelectAllParam] = useState(false)
  const [selectedParams, setSelectedParams] = useState([])
  const [selectedDoaValue, setSelectedDoaValue] = useState(null)
  const [optionsDoaValue, setOptionsDoaValue] = useState([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [diffRequestor, setDiffRequestor] = useState(false)

  const handleOnCheckAllParam = event => {
    setSelectAllParam(event.target.checked)
    if (event.target.checked) {
      let paramsCopy = [...listOfParams]
      setSelectedParams(paramsCopy)
    } else {
      setSelectedParams([])
    }
  }

  const handleOnChangeCheckParam = (param, event) => {
    let selectedParamsCopy = [...selectedParams]
    if (event.target.checked) {
      selectedParamsCopy.push(param)
    } else {
      const index = selectedParamsCopy.indexOf(param)
      selectedParamsCopy.splice(index, 1)
    }

    setSelectedParams(selectedParamsCopy)
  }

  useEffect(() => {
    if (selectedParams.length === listOfParams.length && listOfParams.length > 0) {
      setSelectAllParam(true)
    } else {
      setSelectAllParam(false)
    }

    // populate doa value options
    if (selectedParams.length > 0) {
      const options = selectedParams.map(param => {
        return { label: param, value: param }
      })
      setOptionsDoaValue(options)

      if (selectedDoaValue) {
        /* check if selected doa value exist in param options to handle selected doa value 
        still have value even when the selected value removed from selected param */
        const exist = selectedParams.find(
          param => param === selectedDoaValue.label && param === selectedDoaValue.value
        )

        if (!exist) {
          setSelectedDoaValue(null)
        }
      }
    } else {
      setSelectedDoaValue(null)
    }
  }, [selectedParams])

  const handleSaveParam = () => {
    setParamsWfe({
      params: selectedParams,
      doa_value: selectedDoaValue?.value || null
    })
    setDifferentRequestor(diffRequestor)
    setAddParamChecked(true)
    setShowModal(false)

    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage(`You have successfully ${title.toLowerCase()}`))
  }

  const handleResetParam = () => {
    setShowModal(false)
    setAddParamChecked(false)
    setListOfParams(fieldsParam)
    setSelectedParams([])
    setSelectedDoaValue(null)
    setParamsWfe(null)
    setDiffRequestor(false)
  }

  useEffect(() => {
    if (showModal) {
      if (currentParams) {
        if (currentParams?.params.length) {
          setSelectedParams(currentParams.params)
        }
        if (currentParams?.doa_value) {
          setSelectedDoaValue({ label: currentParams.doa_value, value: currentParams.doa_value })
        }
      } else {
        setSelectedParams([])
        setSelectedDoaValue(null)
      }
      setListOfParams(fieldsParam)

      if (currentDifferentRequestor) {
        setDiffRequestor(currentDifferentRequestor)
      }
    }
  }, [showModal])

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white max-w-full w-fit md:w-[780px] h-fit flex flex-col">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">
              {title ? title : 'Add Param'}
            </div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={() => setShowModal(false)}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />
          <div className="flex flex-col md:flex-row justify-between gap-6">
            <div className="flex-row flex-1 rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
              <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
                <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
                  Available Param
                </div>
                <div className="flex flex-row gap-4">
                  {selectedParams.length !== 0 ? (
                    <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
                      {selectedParams.length} Param Selected
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                      checked={selectAllParam}
                      onChange={handleOnCheckAllParam}
                    />
                    <div className="font-poppins font-normal text-[12px] text-white">All Param</div>
                  </div>
                </div>
              </div>
              <div className="flex-row px-4 py-3">
                <div className={`flex items-center relative mb-4 overflow-hidden`}>
                  <input
                    type="text"
                    placeholder="Search here"
                    className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                    value={searchParamQuery}
                    onChange={event => {
                      setSearchParamQuery(event.target.value)
                    }}
                  />
                  <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" />
                </div>
                <div className="flex-row max-h-[200px] min-h-[200px] overflow-y-auto scrollbar">
                  {listOfParams
                    .filter(param => {
                      return param
                        .toLowerCase()
                        .match(new RegExp(searchParamQuery.toLowerCase(), ''))
                    })
                    .map((param, index) => (
                      <div key={index} className="flex items-center justify-between mb-4">
                        <div
                          className={`flex flex-1 text-[12px] ${
                            param === 'submitterWorklocation' ? 'text-gray-400' : ''
                          }`}
                        >
                          {param}
                        </div>
                        <input
                          type="checkbox"
                          className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                          checked={selectedParams.includes(param)}
                          onChange={event => handleOnChangeCheckParam(param, event)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="flex-col flex-1">
              <div>
                <div className="font-semibold text-xs mb-2">DOA Value</div>
                <Select
                  components={{ DropdownIndicator }}
                  onChange={selectedOption => setSelectedDoaValue(selectedOption)}
                  isSearchable={false}
                  options={optionsDoaValue}
                  value={selectedDoaValue}
                  name="Tag"
                  className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-6`}
                  placeholder="Select doa value"
                  styles={{
                    control: provided => ({
                      ...provided,
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({})
                  }}
                />
              </div>
              <div>
                <div className="font-semibold text-xs mb-2">
                  Option requestor different with submitter
                </div>
                <label
                  htmlFor={`requestor_different`}
                  className="flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    id={`requestor_different`}
                    className="sr-only peer"
                    checked={diffRequestor}
                    onChange={event => setDiffRequestor(event.target.checked)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-10">
            <button
              type="button"
              onClick={() => {
                title === 'Add Param' ? handleResetParam() : setShowConfirmationModal(true)
              }}
              className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Reset
            </button>
            <button
              type="submit"
              onClick={() => handleSaveParam()}
              className="py-3 px-8 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md disabled:bg-gray-200"
              disabled={selectedParams.length === 0}
            >
              Save
            </button>
          </div>

          <SuccessPopup />
          <ConfirmationPopup
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
            title="Reset Param"
            message="Are you sure want to RESET and REMOVE all selected field?"
            onConfirm={() => {
              setShowConfirmationModal(false)
              handleResetParam()
            }}
          />
        </div>
      </div>
    </>
  )
}
