import { useCallback, useEffect, useState } from 'react'

import { get } from '../../../../../../api/base'
import { Select } from '../../../../../../components'
import { apiUrls } from '../../../../../../constants'

export default function MasterData({ field, value, onChange, siblings, disabled }) {
  const [masterData, setMasterData] = useState([])
  const parent = field.master_data.parentFieldId
    ? siblings.find(sibling => sibling.field_id === field.master_data.parentFieldId)
    : null

  const fetch = useCallback(async () => {
    const { data } = await get(
      `${apiUrls.MASTER_DATA}/${field.master_data.sourceMasterDataId}/details`,
      {
        query: {
          ...(parent && parent.value ? { parent_id: parent.value.id } : {})
        }
      }
    )

    if (value?.id && !data.find(({ id }) => id === value?.id)) {
      onChange('')
    }

    setMasterData(data)
  }, [field, parent])

  useEffect(() => {
    if (!parent || (parent && parent.value)) {
      fetch()
    } else if (parent && !parent.value) {
      onChange('')
    }
  }, [fetch, parent?.value])

  return (
    <div className="relative border rounded-lg bg-white">
      <Select
        options={masterData}
        value={value}
        onChange={v => onChange(v)}
        placeholder={`Select ${field.field_title}`}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        isDisabled={(parent && !parent.value) || disabled}
      />
    </div>
  )
}
