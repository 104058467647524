import React from 'react'
import { images } from '../../../../../constants'

function RemoveCollabModal({ showModal, setShowModal, onRemoveClick, deleteSoonData }) {
  const collaboratorId = deleteSoonData?.id
  const collaboratorName = deleteSoonData?.full_name
  const handleOnCancelClick = () => {
    setShowModal(false)
  }

  const handleOnRemoveClick = () => {
    setShowModal(false)
    onRemoveClick()
  }
  return (
    <>
      <input
        type="checkbox"
        id="collaborator-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white w-fit h-fit p-6 flex flex-col items-center">
          <img src={images.confirmation} className="w-[120px] h-[120px] mb-9" />
          <div className="font-semibold text-[18px] mb-1">Remove Collaborator</div>
          <div className="text-[12px] text-[#AAAAAA] text-center mb-10">
            Are you sure want to remove{' '}
            <span className="font-semibold text-[#C800A5]">{collaboratorName}</span> as
            collaborator?
          </div>
          <div className="flex flex-row items-center gap-4">
            <button
              className="flex flex-1 p-3 px-8 bg-white text-[#6546C3] text-[12px] font-semibold border-solid border-2 border-[#6546C3] rounded-md"
              onClick={handleOnCancelClick}
            >
              Cancel
            </button>
            <button
              className="flex flex-1 p-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              onClick={handleOnRemoveClick}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default RemoveCollabModal
