import { useEffect, useMemo, useState } from 'react'
import { ICONS, apiUrls } from '../../../../../constants'
import NoDataTable from '../../../../../components/NoDataTable'
import { useTable } from 'react-table'
import { get } from '../../../../../api/base'
import { toast } from 'react-toastify'
import { Toast } from '../../../../../components'

export default function MasterStockModal({ showModal, setShowModal, formTitle, formId }) {
  const COLUMNS = [
    {
      Header: 'Item',
      accessor: 'item'
    },
    {
      Header: 'Jumlah Stock',
      accessor: 'amount'
    },
    {
      Header: 'Stock Unit',
      accessor: 'stock_unit'
    }
  ]
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const limit = 5
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)

  useEffect(() => {
    if (showModal) {
      fetchFormList()
    }
  }, [showModal, pageNum])

  const fetchFormList = () => {
    get(`${apiUrls.FORMS_URL}/master-stocks/${formId}`, {
      query: { limit, page: pageNum }
    }).then(response => {
      const { status, data } = response
      if (status === 200) {
        setData(data?.data || [])
        setTotalData(data?.total_data)
        setTotalPage(data?.total_page)
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={'Failed to fetch form system list'} />
        )
      }
    })
  }

  function close() {
    setShowModal(false)
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => {
              setPageNum(data)
            }}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    pageNum
  })

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 !== 0
  }

  const goToNextPage = () => {
    setPageNum(prev => {
      return prev + 1
    })
  }
  const goToPreviousPage = () => {
    setPageNum(prev => {
      return prev - 1
    })
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div
          className="modal-box bg-white max-w-full w-fit md:w-[620px] h-fit flex flex-col"
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">{formTitle}</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />
          <div className="flex gap-6">
            <div className="flex flex-col w-full">
              <div className="overflow-x-auto w-full">
                <table {...getTableProps()} className="w-full font-poppins text-[12px]">
                  <thead className="">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th key={index}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return (
                              <td {...cell.getCellProps()} className="!bg-transparent">
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {!data?.length && (
                <div className="py-6">
                  <NoDataTable
                    message="No data yet"
                    detailedMessage="Data will be available if the form already has submission"
                  />
                </div>
              )}
              <hr className="mt-4" />
              <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-4">
                {data?.length > 0 ? (
                  <div className="font-poppins text-[12px]">
                    Showing {pageNum * limit - (limit - 1)} -{' '}
                    {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
                  </div>
                ) : (
                  <div className="font-poppins text-[12px]">No data to show</div>
                )}
                <div className="flex font-poppins text-[12px]">
                  <button
                    onClick={() => goToPreviousPage()}
                    disabled={!canPreviousPage()}
                    className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                      !canPreviousPage()
                        ? 'bg-[#F2F5FC]'
                        : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
                    }`}
                  >
                    &lt;
                  </button>
                  <div className="bg-[#F2F5FC] rounded-full flex">
                    {showPaginationNumbers(totalPage)}
                  </div>
                  <button
                    onClick={() => goToNextPage()}
                    disabled={!canNextPage()}
                    className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                      !canNextPage()
                        ? 'bg-[#F2F5FC]'
                        : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
                    }`}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
