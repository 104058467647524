import { sub } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { selectToken } from '../../redux/slices/authSlice'
import { selectUserData } from '../../redux/slices/userSlice'

const RequireAuthorization = ({ pageName, permission }) => {
  const location = useLocation()
  const token = useSelector(selectToken)
  const userData = useSelector(selectUserData)

  const hasAuthority = () => {
    const path = location.pathname
    const pathSplit = path.split('/')
    const isSubMenu = pathSplit.length > 2
    if (isSubMenu) {
      const menus = userData?.menus
      const menu = menus?.find(menu => {
        const menuPath = menu.path
        const menuPathSplit = menuPath.split('/')
        return menuPathSplit[0] === pathSplit[1]
      })
      const subMenus = menu?.subMenus
      const subMenu = subMenus?.find(subMenu => {
        const subMenuPath = subMenu.path
        const subMenuPathSplit = subMenuPath.split('/')
        return subMenuPathSplit[1] === pathSplit[2]
      })
      return subMenu?.permissions.includes(permission)
    } else {
      const menus = userData?.menus
      const menu = menus?.find(menu => menu.path === pageName)
      return menu?.permissions.includes(permission)
    }
  }

  return hasAuthority() ? (
    <Outlet />
  ) : (
    <Navigate to="/Unauthorize" state={{ from: location }} replace />
  )
}

export default RequireAuthorization
