import { useEffect, useState } from 'react'
import { ICONS, apiUrls } from '../../../../../constants'
import Select from 'react-select'

import { components } from 'react-select'
import { get } from '../../../../../api/base'
import { toast } from 'react-toastify'
import { Toast } from '../../../../../components'
import { Controller, useForm } from 'react-hook-form'

export default function AdvanceSearchModal({
  showModal,
  setShowModal,
  advanceSearch,
  setAdvanceSearch,
  selectedFieldOptions,
  setSelectedFieldOption,
  topColor,
  selectedForm
}) {
  const [fieldOptions, setFieldOptions] = useState([])
  const { control, handleSubmit, reset, setValue, register } = useForm({
    defaultValues: {
      selectedFields: null,
      keyword: null
    }
  })

  const close = () => {
    setValue('selectedFields', null)
    setValue('keyword', null)
    reset()
    setShowModal(false)
  }
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img src={ICONS.icArrowDownSite} className="w-3 mr-1" />
        </components.DropdownIndicator>
      )
    )
  }

  const populateField = () => {
    get(`${apiUrls.FORMS_URL}/${selectedForm?.value}/fields`).then(res => {
      if (res.status === 200) {
        let temp = res.data.map(field => ({ label: field.field_title, value: field.field_id }))
        setFieldOptions(temp)
      } else {
        toast.error(<Toast message="Error" detailedMessage={res.data.error.message} />)
      }
    })

    setValue('selectedFields', selectedFieldOptions)
    setValue('keyword', advanceSearch)
  }

  useEffect(() => {
    showModal && populateField()
  }, [showModal])

  function onSubmit({ selectedFields, keyword }) {
    setSelectedFieldOption(selectedFields)
    setAdvanceSearch(keyword)
    setShowModal(false)
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div
          className="modal-box bg-white w-fit md:w-[760px] lg:w-[900px] h-fit flex flex-col"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row justify-between forms-center">
            <div className="font-semibold text-[16px]">Advance Search</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>
          <hr className="my-4" />
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col py-4 rounded-2xl">
            <div>
              <div className="font-poppins font-semibold text-[12px] mb-2">Field</div>
              <Controller
                control={control}
                name="selectedFields"
                render={({ field: { onChange, value } }) => (
                  <Select
                    components={{ DropdownIndicator }}
                    value={value}
                    onChange={selectedOptions => onChange(selectedOptions)}
                    options={fieldOptions}
                    name="Fields"
                    className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                    placeholder="Select fields"
                    isMulti={true}
                    styles={{
                      option: provided => ({
                        ...provided
                      }),
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: topColor },
                        ':active': { borderColor: topColor },
                        ':hover': { borderColor: topColor },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        color: '#C800A5',
                        ':hover': { color: topColor }
                      }),
                      indicatorSeparator: () => ({}),
                      valueContainer: provided => ({
                        ...provided
                      }),
                      multiValueLabel: provided => ({
                        ...provided,
                        color: '#fff',
                        fontFamily: 'poppins'
                      }),
                      multiValueRemove: provided => ({
                        ...provided,
                        color: '#fff'
                      }),
                      multiValue: provided => ({
                        ...provided,
                        backgroundColor: topColor,
                        borderRadius: 8
                      })
                    }}
                  />
                )}
              />
            </div>
            <div className="font-poppins font-semibold text-[12px] mb-2">Keyword</div>
            <div className="flex w-full font-poppins border-[1px] items-center p-2 rounded-lg">
              <input
                className="ml-2 focus:outline-none w-full text-[12px]"
                placeholder="Search here..."
                {...register('keyword')}
              />
            </div>
            <div className="flex justify-end gap-4 lg:col-span-3 mt-20">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="py-3 px-8 text-[12px] font-semibold rounded-md"
                style={{ border: `1px solid ${topColor}`, color: topColor }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="py-3 px-8 text-white text-[12px] font-semibold rounded-md"
                style={{ background: topColor }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
