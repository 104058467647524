import { useLocation, useNavigate } from 'react-router-dom'

import { ICONS } from '../../../constants'

import { MENU_ICONS } from './constants'
import SubMenu from './SubMenu'

const Menu = ({ data, open, textColor }) => {
  const { subMenus } = data
  const location = useLocation()
  const navigate = useNavigate()

  const path = location.pathname
  const crumbs = data.path.split('/')
  const isActive = Object.values(crumbs).includes(path.split('/')[4])

  const classNames = isActive
    ? `cursor-pointer bg-white bg-opacity-[16%] h-10 rounded-lg p-1 flex gap-x-4 items-center ${
        open ? '' : 'justify-center'
      } mb-2`
    : `cursor-pointer hover:bg-white hover:bg-opacity-[16%] h-10 rounded-lg p-1 flex gap-x-4 items-center ${
        open ? '' : 'justify-center'
      } mb-2`

  const icon = (
    <img
      src={MENU_ICONS[data.type][isActive ? 'active' : 'inactive']}
      className="w-8 h-8"
      alt={`${data.name} icon`}
    />
  )

  const handleMenuClick = () => {
    navigate(`menu/${data.path}`)
  }

  if (subMenus.length > 0 && isActive && open) {
    return (
      <>
        <li className={classNames} onClick={handleMenuClick}>
          {icon}
          {open && <span className="text-white font-poppins text-[14px]">{data.name}</span>}
          {subMenus.length > 0 && (
            <img src={ICONS.icChevronDown} className="w-5 h-5" alt="expand" />
          )}
        </li>
        <ul className="bg-white/[16%] rounded-lg py-[10px] mb-2">
          {subMenus.map((subMenu, index) => (
            <SubMenu data={subMenu} key={index} open={open} textColor={textColor} />
          ))}
        </ul>
      </>
    )
  } else if (subMenus.length > 0) {
    return (
      <>
        <li className={classNames} onClick={handleMenuClick}>
          {icon}
          {open && <span className="text-white font-poppins text-[14px]">{data.name}</span>}
          {open && subMenus.length > 0 && (
            <img src={ICONS.icChevronUp} className="w-5 h-5" alt="collapse" />
          )}
        </li>
      </>
    )
  } else {
    return (
      <li className={classNames} onClick={handleMenuClick}>
        {icon}
        {open && <span className="text-white font-poppins text-[14px]">{data.name}</span>}
      </li>
    )
  }
}

export default Menu
