import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { del, put } from '../../../api/base'
import { DeletePopup, SuccessPopup, Toast } from '../../../components'
import { apiUrls, ICONS, images } from '../../../constants'
import {
  setMessage,
  setShowPopupDelete,
  setShowPopupSuccess
} from '../../../redux/slices/popupSlice'

import MenuModal from './MenuModal'
import { useParams } from 'react-router-dom'

export default function MenuList({
  route_name,
  menus = [],
  onChangeMenus,
  selected = {},
  onSelect,
  onSuccess
}) {
  const dispatch = useDispatch()
  const params = useParams()
  const [deleteItem, setDeleteItem] = useState({})
  const [showMenuModal, setShowMenuModal] = useState(false)
  const [isEditMenu, setIsEditMenu] = useState(false)
  const [parentOptions] = useState([])
  const [item, setItem] = useState({})

  function addMenu() {
    setShowMenuModal(true)
    setItem({})
  }

  function editMenu(item) {
    setShowMenuModal(true)
    setIsEditMenu(true)
    setItem(item)
  }

  function confirmDelete(item) {
    setDeleteItem(item)
    dispatch(setShowPopupDelete(true))
    dispatch(setMessage('Are you sure you want to delete this menu?'))
  }

  async function onDelete() {
    const { ok, data } = await del(
      `${apiUrls.MICROSITES_ROUTE}/${route_name}/menus/${deleteItem.id}`
    )
    if (ok) {
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully deleted menu'))
      onSuccess()
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
    }
  }

  function onDragParentEnd(result) {
    if (!result.destination) return

    const clone = Array.from(menus)
    const [removed] = clone.splice(result.source.index, 1)
    clone.splice(result.destination.index, 0, removed)
    const mappedClone = clone.map((menu, order) => ({ ...menu, attributes: { order } }))

    const affectedStartIndex =
      result.destination.index < result.source.index
        ? result.destination.index
        : result.source.index

    onChangeMenus(mappedClone)
    update(mappedClone.slice(affectedStartIndex))
    return clone
  }

  function onDragChildEnd(result, parentIndex) {
    if (!result.destination) return

    const clone = Array.from(menus)
    const childrenClone = Array.from(clone[parentIndex].children)
    const [removed] = childrenClone.splice(result.source.index, 1)
    childrenClone.splice(result.destination.index, 0, removed)
    const mappedClone = childrenClone.map((menu, order) => ({ ...menu, attributes: { order } }))
    clone[parentIndex].children = mappedClone

    const affectedStartIndex =
      result.destination.index < result.source.index
        ? result.destination.index
        : result.source.index

    onChangeMenus(clone)
    update(mappedClone.slice(affectedStartIndex))
    return mappedClone
  }

  async function update(items) {
    try {
      const responses = await Promise.all(
        items.map(menu => {
          const { name, menu_type, attributes } = menu
          return put(`${apiUrls.MICROSITES_ROUTE}/${params.microsite_route}/menus/${menu.id}`, {
            name,
            menu_type,
            attributes
          })
        })
      )

      if (responses.every(response => response.ok)) {
        toast.success(<Toast message="Success" detailedMessage="Menu list updated" />)
      } else {
        toast.error(<Toast message="Error" detailedMessage="Failed updating menu list" />)
      }
    } catch (error) {
    } finally {
      onSuccess()
    }
  }

  return (
    <div className="bg-white rounded-2xl p-2 sm:p-6 lg:my-0 my-1">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-base">Menu List</div>
        <img
          src={ICONS.icAddMenuList}
          className="w-7 h-7 cursor-pointer"
          alt="add menu"
          onClick={addMenu}
        />
      </div>

      <hr className="my-6" />

      {menus.length ? (
        <DragDropContext onDragEnd={onDragParentEnd}>
          <Droppable droppableId="parents">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {menus.map((item, index) => (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {provided => (
                      <div
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <div
                          className={`${
                            selected.route_name === item.route_name
                              ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
                              : 'bg-[#F2F5FC]'
                          } w-full mx-[4px] rounded-md my-2 flex items-center justify-between cursor-pointer p-2 text-sm`}
                          onClick={() => onSelect(item)}
                        >
                          <div
                            className={`${
                              selected.route_name === item.route_name ? 'bg-white' : 'bg-[#6546C3]'
                            } self-stretch w-[5px] rounded-[3px] mr-3`}
                          />

                          <span
                            className={`${
                              selected.route_name === item.route_name
                                ? 'text-white font-semibold'
                                : 'text-black'
                            } pl-[2px] grow`}
                          >
                            {item.name}
                          </span>

                          <div
                            className="dropdown dropdown-right dropdown-end"
                            onClick={e => e.stopPropagation()}
                          >
                            <button className="block" tabIndex="0">
                              <img
                                src={ICONS.icAction}
                                className="w-[25px] h-[25px] cursor-pointer"
                                alt="action-button"
                              />
                            </button>

                            <ul
                              tabIndex="0"
                              className="dropdown-content menu p-1 shadow bg-base-100 rounded-box w-40"
                            >
                              <li onClick={() => editMenu(item)}>
                                <div className="flex">
                                  <img
                                    src={ICONS.icEdit}
                                    className="w-[25px] h-[25px] cursor-pointer"
                                    alt="action-button"
                                  />
                                  <span className="text-sm">Edit</span>
                                </div>
                              </li>

                              <hr />

                              <li onClick={() => confirmDelete(item)}>
                                <div className="flex">
                                  <img
                                    src={ICONS.icTrash}
                                    className="w-[25px] h-[25px] cursor-pointer"
                                    alt="delete menu"
                                  />
                                  <span className="text-sm">Delete</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <DragDropContext onDragEnd={result => onDragChildEnd(result, index)}>
                          <Droppable droppableId="children">
                            {provided => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                {item.children.map((child, index) => (
                                  <Draggable key={index} draggableId={`${index}`} index={index}>
                                    {provided => (
                                      <div
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                      >
                                        <div
                                          key={index}
                                          className={`${
                                            selected.route_name === child.route_name
                                              ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
                                              : 'bg-[#F2F5FC]'
                                          } w-full mx-[4px] rounded-md my-2 flex items-center justify-between cursor-pointer p-2 pl-4 text-sm`}
                                          onClick={() => onSelect(child)}
                                        >
                                          <div
                                            className={`${
                                              selected.route_name === child.route_name
                                                ? 'bg-white'
                                                : 'bg-[#B39AFF]'
                                            } self-stretch w-[5px] rounded-[3px] mr-3`}
                                          />

                                          <span
                                            className={`${
                                              selected.route_name === child.route_name
                                                ? 'text-white font-semibold'
                                                : 'text-black'
                                            } pl-[2px] grow`}
                                          >
                                            {child.name}
                                          </span>

                                          <div
                                            className="dropdown dropdown-right dropdown-end"
                                            onClick={e => e.stopPropagation()}
                                          >
                                            <button className="block" tabIndex="0">
                                              <img
                                                src={ICONS.icAction}
                                                className="w-[25px] h-[25px] cursor-pointer"
                                                alt="action-button"
                                              />
                                            </button>

                                            <ul
                                              tabIndex="0"
                                              className="dropdown-content menu p-1 shadow bg-base-100 rounded-box w-40"
                                            >
                                              <li onClick={() => editMenu(child)}>
                                                <div className="flex">
                                                  <img
                                                    src={ICONS.icEdit}
                                                    className="w-[25px] h-[25px] cursor-pointer"
                                                    alt="action-button"
                                                  />
                                                  <span className="text-sm">Edit</span>
                                                </div>
                                              </li>

                                              <hr />

                                              <li onClick={() => confirmDelete(child)}>
                                                <div className="flex">
                                                  <img
                                                    src={ICONS.icTrash}
                                                    className="w-[25px] h-[25px] cursor-pointer"
                                                    alt="delete menu"
                                                  />
                                                  <span className="text-sm">Delete</span>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}

                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
          <div className="font-semibold text-[18px] mb-2">Menu is not available</div>
          <div className="text-[12px] text-[#AAAAAA] mb-6 text-center">
            Please add a menu first by clicking the + button
          </div>
        </div>
      )}
      <MenuModal
        showModal={showMenuModal}
        setShowModal={() => setShowMenuModal(false)}
        isEdit={isEditMenu}
        setIsEdit={() => setIsEditMenu(false)}
        parent_options={parentOptions}
        formData={item}
        setFormData={value => setItem(value)}
        on_success={() => onSuccess()}
      />
      <DeletePopup setContinueDelete={onDelete} buttonNext={'Delete Now'} />
      <SuccessPopup />
    </div>
  )
}
