import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Select, SuccessPopup } from '../../../../../components'
import { get } from '../../../../../api/base'
import { ICONS, apiUrls } from '../../../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../../../redux/slices/popupSlice'

export default function MasterDataModal({ showModal, setShowModal, item, onSubmit }) {
  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [child, setChild] = useState()
  const parents = item?.parents || []
  const lastParent = parents[parents.length - 1]

  const fetchParent = useCallback(async () => {
    const { data } = await get(`${apiUrls.MASTER_DATA}/${lastParent.id}`)
    setOptions(data.children)
  }, [lastParent])

  useEffect(() => {
    if (showModal) fetchParent()
  }, [showModal, fetchParent])

  function onFormSubmit(e) {
    e.preventDefault()
    if (!child) return

    onSubmit({
      ...item,
      sourceMasterDataId: child.id,
      sourceMasterDataName: child.name
    })
    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage('You have successfully added child'))
    close()
  }

  function close() {
    setShowModal(false)
    setOptions([])
    setChild(null)
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white w-fit md:w-[780px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Add Data Child</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />

          <form onSubmit={onFormSubmit} className="flex flex-col py-4 rounded-2xl">
            <div className="flex-1 mb-6 rounded-2xl border border-gray-200 p-4 text-xs">
              <span className="mr-2">Master Data:</span>
              {parents.map((parent, p) => (
                <Fragment key={p}>
                  {parents.length > 1 && p > 0 && <span className="mx-1 text-pink">/</span>}
                  <span className="bg-indigo-50 text-indigo-500 rounded-lg px-2 py-1 font-semibold">
                    {parent.name}
                  </span>
                </Fragment>
              ))}
            </div>

            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">Child</div>
              <Select
                value={child}
                onChange={v => setChild(v)}
                options={options}
                placeholder="Select child"
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                menuPortalTarget={document.body}
              />
            </div>

            <div className="flex justify-end gap-4 lg:col-span-3">
              <button
                type="button"
                onClick={close}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                disabled={!child}
              >
                Save
              </button>
            </div>
          </form>

          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
