import { useRef, useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { ICONS, images } from '../../../../../constants'
import Field from '../Field'
import { v4 as uuidv4 } from 'uuid'
import SubSection from '../SubSection'
import DeletePopup from '../../../../../components/Popup/DeletePopup'
import { setShowPopupDelete, setMessage } from '../../../../../redux/slices/popupSlice'
import { Toast, DropdownIndicator } from '../../../../../components'
import { toast } from 'react-toastify'
import Select from 'react-select'

const Section = ({
  index,
  sections,
  data,
  enableJump,
  editable,
  onDelete,
  validationBefAddSubSection,
  handleChangeSection,
  setShowJumpSettingsModal,
  setCurrentSectionId
}) => {
  const dispatch = useDispatch()
  const [title, setTitle] = useState(data.section_title)
  const [multipleInput, setMultipleInput] = useState(data.multiple_input)
  const [tabular, setTabular] = useState(data.tabular || false)
  const [checkStock, setCheckStock] = useState(data.check_stock || false)
  const [fields, setFields] = useState(data.fields)
  const [subSections, setSubSection] = useState(data.sub_sections || [])
  const [deleteField, setDeleteField] = useState(false)
  const [deletedId, setDeletedId] = useState(-1)
  const sectionData = useRef(data)
  const sectionId = useRef(index)
  const [jumpToSection, setJumpToSection] = useState(data?.jump_to_section || null)

  // handle delete field after click action delete
  useEffect(() => {
    if (deleteField) {
      setDeleteField(false)
      setDeletedId(-1)
      const newFields = fields.filter((field, i) => i !== deletedId)
      sectionData.current.fields = newFields
      handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
      setFields(newFields)

      sectionData.current.sub_sections = []
      handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
      setSubSection([])
    }
  }, [deleteField])

  useEffect(() => {
    if (jumpToSection) {
      sectionData.current.jump_to_section = jumpToSection
    } else if (sectionData.current.hasOwnProperty('jump_to_section')) {
      delete sectionData.current.jump_to_section
    }
  }, [jumpToSection])

  const handleOnTitleChange = event => {
    sectionData.current.section_title = event.target.value
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setTitle(event.target.value)
  }

  const handleOnMultiInputChange = event => {
    if (!event.target.checked) {
      const foundSectionSeqField = sectionData?.current.fields?.find(obj => {
        return obj.field_type && obj.field_type.value === 'SECTION_SEQ'
      })

      const foundCalculatedSubtotalField = sectionData?.current.fields?.find(obj => {
        return obj.field_type && obj.field_type.value === 'CALCULATED_SUBTOTAL'
      })

      if (foundSectionSeqField) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`You've declared Section_SEQ field! Section that has Section_SEQ field must be multiple inputs!`}
          />
        )
        return
      }

      if (foundCalculatedSubtotalField) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`You've declared Calculated Subtotal field! Section that has Calculated Subtotal field must be multiple inputs!`}
          />
        )
        return
      }
    }

    const foundMasterStockField = sectionData?.current.fields?.find(obj => {
      return (
        obj.field_type &&
        obj.field_type.value === 'MASTER_STOCK' &&
        obj.field_type.label === 'Master Stock'
      )
    })

    if (foundMasterStockField) {
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`You've declared field with Master Stock type! Section that have a Master Stock field cannot have multiple inputs!`}
        />
      )
      return
    }

    if (sectionData?.current.sub_sections?.length) {
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`You've already declare the subsections! Please remove the subsections first!`}
        />
      )
      return
    }

    sectionData.current.multiple_input = event.target.checked
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setMultipleInput(event.target.checked)
  }

  const handleOnTabularChange = event => {
    const foundValidFormField = sectionData?.current.fields?.find(obj => {
      return (
        obj.field_type &&
        (obj.field_type.value === 'M2P_FORM' ||
          obj.field_type.value === 'SECTION_SEQ' ||
          obj.field_type.value === 'CALCULATED_FIELD')
      )
    })

    if (!foundValidFormField) {
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`You haven't declared a field with type M2P Form, Section_SEQ, or Calculated Field. Declare it first if you want to make this section tabular!`}
        />
      )
      return
    }

    sectionData.current.tabular = event.target.checked
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setTabular(event.target.checked)
  }

  const handleOnCheckStockChange = event => {
    const foundMtopFormField = sectionData?.current.fields?.find(obj => {
      return obj.field_type && obj.field_type.value === 'M2P_FORM'
    })

    if (!foundMtopFormField) {
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`You haven't declared a field of type M2P Form. Declare it first if you want to enable check stock!`}
        />
      )
      return
    }

    sectionData.current.check_stock = event.target.checked
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setCheckStock(event.target.checked)
  }

  const handleOnDragEnd = result => {
    if (!result.destination) return
    const items = Array.from(fields)
    const destinationFieldType = items[result.destination.index]?.field_type?.value
    const isDestinationMasterStockField =
      destinationFieldType === 'MASTER_STOCK_AMOUNT' ||
      destinationFieldType === 'STOCK_IN_TRANSACTION' ||
      (destinationFieldType === 'MASTER_STOCK' && result.destination.index > result.source.index) ||
      (destinationFieldType === 'STOCK_UNIT' && result.destination.index < result.source.index)

    if (isDestinationMasterStockField) {
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Cannot place this field between Master Stock, Master Stock Amount, Stock in Transaction, or Stock Unit field!`}
        />
      )
    } else {
      const isMasterStock = items[result.source.index]?.field_type?.value === 'MASTER_STOCK'

      if (isMasterStock) {
        const masterStocks = items.splice(result.source.index, 4)
        items.splice(result.destination.index, 0, ...masterStocks)
      } else {
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
      }

      sectionData.current.fields = items
      handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
      setFields(items)
    }
  }

  const handleOnDeleteField = index => {
    if (
      sectionData?.current?.jump_settings?.length &&
      sectionData?.current?.jump_settings.find(
        item => item.field_id === sectionData?.current.fields[index].field_id
      )
    ) {
      toast.error(
        <Toast
          message={`Failed`}
          detailedMessage={`You've already used this field in the jump settings! Please delete this field in the jump settings first!`}
        />
      )
      return
    }

    if (sectionData?.current.sub_sections?.length && sectionData?.current.fields?.length === 1) {
      setDeletedId(index)
      dispatch(setShowPopupDelete(true))
      dispatch(
        setMessage(
          'Are you sure want to delete this field? Deleting a field will delete all sub-sections that have been created.'
        )
      )
      return
    }

    if (sectionData?.current?.fields[index]?.field_type?.value === 'M2P_FORM' && checkStock) {
      toast.error(
        <Toast
          message={`Failed`}
          detailedMessage={`Section with checked check stock must have a field with type M2P Form! Uncheck first if you want to delete this field!`}
        />
      )
      return
    }

    const validFieldForTabular = sectionData?.current.fields?.filter(obj => {
      return (
        obj.field_type &&
        (obj.field_type.value === 'M2P_FORM' ||
          obj.field_type.value === 'SECTION_SEQ' ||
          obj.field_type.value === 'CALCULATED_FIELD')
      )
    })

    if (
      (sectionData?.current?.fields[index]?.field_type?.value === 'M2P_FORM' ||
        sectionData?.current?.fields[index]?.field_type?.value === 'SECTION_SEQ' ||
        sectionData?.current?.fields[index]?.field_type?.value === 'CALCULATED_FIELD') &&
      tabular &&
      validFieldForTabular.length === 1
    ) {
      toast.error(
        <Toast
          message={`Failed`}
          detailedMessage={`Section with checked tabular must have a field with type M2P Form, Section_SEQ, or Calculated Field! Uncheck first if you want to delete this field!`}
        />
      )
      return
    }

    const calculatedFields = sectionData?.current.fields?.filter(obj => {
      return obj.field_type && obj.field_type.value === 'CALCULATED_FIELD'
    })

    for (const field of calculatedFields) {
      if (
        field?.field_formula?.operand_fields?.find(
          item => item.field_id === sectionData?.current.fields[index].field_id
        )
      ) {
        toast.error(
          <Toast
            message={`Failed`}
            detailedMessage={`You cannot delete this field because it is already used in the formula for field "${field.field_title}"!`}
          />
        )
        return
      }
    }

    const calculatedSubtotalField = sectionData?.current.fields?.find(obj => {
      return obj.field_type && obj.field_type.value === 'CALCULATED_SUBTOTAL'
    })

    if (
      calculatedSubtotalField?.field_subtotal?.field_id ===
      sectionData?.current?.fields[index]?.field_id
    ) {
      toast.error(
        <Toast
          message={`Failed`}
          detailedMessage={`You cannot delete this field because it is already used in field subtotal for field "${calculatedSubtotalField?.field_title}"!`}
        />
      )
      return
    }

    const newFields = fields.filter((field, i) => i !== index)
    sectionData.current.fields = newFields
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setFields(newFields)
  }

  const handleOnDeleteFieldMasterStock = (index, childOnly) => {
    if (
      sectionData?.current?.jump_settings?.length &&
      sectionData?.current?.jump_settings.find(
        item => item.field_id === sectionData?.current.fields[index].field_id
      )
    ) {
      toast.error(
        <Toast
          message={`Failed`}
          detailedMessage={`You've already used this field in the jump settings! Please delete this field in the jump settings first!`}
        />
      )
      return
    }

    if (sectionData?.current.sub_sections?.length && sectionData?.current.fields?.length === 1) {
      setDeletedId(index)
      dispatch(setShowPopupDelete(true))
      dispatch(
        setMessage(
          'Are you sure want to delete this field? Deleting a field will delete all sub-sections that have been created.'
        )
      )
      return
    }
    let masterStockIndexs = childOnly
      ? [index + 1, index + 2, index + 3]
      : [index, index + 1, index + 2, index + 3]

    const newFields = fields.filter((field, i) => !masterStockIndexs.includes(i))
    sectionData.current.fields = newFields
    setFields(newFields)
  }

  const handleOnAddField = () => {
    const fieldId = uuidv4()
    const newField = {
      field_id: fieldId,
      field_title: null,
      field_type: null,
      keyboard_type: '',
      format: '',
      additional_option: [],
      value: '',
      options: [],
      is_segregate_by_tags: false,
      tags: []
    }
    const newFields = [...fields, newField]
    sectionData.current.fields = newFields
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setFields(newFields)
    return {
      parentType: 'sections',
      sectionIdx: sectionId.current,
      subSectionIdx: null,
      fieldIdx: newFields.length - 1,
      newField
    }
  }

  const handleOnAddFieldMasterStock = ({ fieldIndex }) => {
    const fieldsCopy = [...fields]
    const newFields = [
      {
        field_id: uuidv4(),
        field_title: null,
        field_type: {
          label: 'Master Stock Amount',
          value: 'MASTER_STOCK_AMOUNT'
        },
        keyboard_type: { value: 'NUMERIC', label: 'Numeric' },
        format: '',
        additional_option: [],
        value: '',
        options: [],
        is_segregate_by_tags: false,
        tags: []
      },
      {
        field_id: uuidv4(),
        field_title: null,
        field_type: {
          label: 'Stock In Transaction',
          value: 'STOCK_IN_TRANSACTION'
        },
        keyboard_type: { value: 'NUMERIC', label: 'Numeric' },
        format: '',
        additional_option: [],
        value: '',
        options: [],
        is_segregate_by_tags: false,
        tags: []
      },
      {
        field_id: uuidv4(),
        field_title: null,
        field_type: {
          label: 'Stock Unit',
          value: 'STOCK_UNIT'
        },
        keyboard_type: '',
        format: '',
        additional_option: [],
        value: '',
        options: [],
        is_segregate_by_tags: false,
        tags: []
      }
    ]
    fieldsCopy.splice(fieldIndex + 1, 0, ...newFields)
    sectionData.current.fields = fieldsCopy
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setFields(fieldsCopy)
  }

  const handleOnChangeMandatoryMasterStock = (index, checked) => {
    const fieldsCopy = [...fields]
    fieldsCopy[index + 1].mandatory = checked
    fieldsCopy[index + 2].mandatory = checked
    fieldsCopy[index + 3].mandatory = checked
    sectionData.current.fields = fieldsCopy
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setFields(fieldsCopy)
  }

  const handleOnAddSubSection = async () => {
    const isValid = await validationBefAddSubSection(sectionId.current)
    if (!isValid) {
      return
    }

    const subSectionId = uuidv4()
    const newSubSection = {
      section_id: subSectionId,
      section_title: '',
      multiple_input: false,
      fields: []
    }
    const newSubSections = [...subSections, newSubSection]
    sectionData.current.sub_sections = newSubSections
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setSubSection(newSubSections)
  }

  const handleOnDeleteSubSection = index => {
    const newSubSection = subSections.filter((field, i) => i !== index)
    sectionData.current.sub_sections = newSubSection
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setSubSection(newSubSection)
  }

  const handleOnDragEndSubSections = result => {
    if (!result.destination) return
    const items = Array.from(subSections)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    sectionData.current.sub_sections = items
    handleChangeSection('sections', '', sectionId.current, null, null, sectionData.current)
    setSubSection(items)
  }

  const handleAfterFieldTypeChange = () => {
    setFields([...fields])
  }

  const validationBeforeChangeFieldData = id => {
    const calculatedFields = sectionData?.current.fields?.filter(obj => {
      return obj.field_type && obj.field_type.value === 'CALCULATED_FIELD'
    })

    for (const field of calculatedFields) {
      if (field?.field_formula?.operand_fields?.find(item => item.field_id === id)) {
        toast.error(
          <Toast
            message={`Failed`}
            detailedMessage={`You cannot change this field title because it is already used in the formula for field "${field.field_title}"!`}
          />
        )
        return false
      }
    }

    return true
  }

  return (
    <div className="flex-1 p-6 bg-white rounded-2xl">
      <div className="flex flex-col flex-1 p-6 bg-[#F2F5FC] rounded-2xl gap-2">
        <div className="font-semibold text-[12px]">Section Title</div>
        <div className="flex items-center justify-between gap-4">
          <input
            type="text"
            name="Section Title"
            className={`input-text flex-1 text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            onChange={handleOnTitleChange}
            value={title}
          />
          <div className="flex flex-1 justify-start items-center gap-6">
            <div className="flex gap-2">
              <input
                type="checkbox"
                className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                onChange={handleOnMultiInputChange}
                checked={multipleInput}
                disabled={!editable}
              />
              <div className="text-[12px]">Multiple Input</div>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                onChange={handleOnTabularChange}
                checked={tabular}
                disabled={!editable}
              />
              <div className="text-[12px]">Tabular</div>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                onChange={handleOnCheckStockChange}
                checked={checkStock}
                disabled={!editable}
              />
              <div className="text-[12px]">Check Stock</div>
            </div>
          </div>
          <img
            src={ICONS.icTrash}
            className="w-8 h-8 cursor-pointer"
            onClick={editable ? () => onDelete(index) : () => {}}
          />
        </div>
      </div>
      <hr className="mt-4 mb-4" />
      {fields.length === 0 ? (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" />
          <div className="font-semibold text-[18px] mb-2">You’ve not added any field yet</div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "add field” button to add it now!
          </div>
          <button
            onClick={handleOnAddField}
            disabled={!editable}
            className={`${
              !editable ? 'btn-disabled' : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
            } py-3 px-6 text-white text-[12px] font-semibold rounded-md`}
          >
            Add Field
          </button>
        </div>
      ) : (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="fields" isDropDisabled={!editable}>
            {provided => (
              <div
                className="flex flex-col gap-4"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {fields.map((field, index) => (
                  <Draggable
                    key={field.field_id}
                    draggableId={field.field_id}
                    index={index}
                    isDragDisabled={
                      !editable ||
                      field.field_type?.value === 'MASTER_STOCK_AMOUNT' ||
                      field.field_type?.value === 'STOCK_IN_TRANSACTION' ||
                      field.field_type?.value === 'STOCK_UNIT'
                    }
                  >
                    {provided => (
                      <div
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <Field
                          index={index}
                          data={field}
                          editable={editable}
                          multipleInput={multipleInput}
                          tabular={tabular}
                          checkStock={checkStock}
                          onDelete={handleOnDeleteField}
                          onDeleteMasterStock={handleOnDeleteFieldMasterStock}
                          handleChangeSection={handleChangeSection}
                          firstIdx={sectionId.current}
                          secondIdx={null}
                          parentName={'sections'}
                          handleOnAddField={handleOnAddField}
                          handleOnAddFieldMasterStock={handleOnAddFieldMasterStock}
                          handleOnChangeMandatoryMasterStock={handleOnChangeMandatoryMasterStock}
                          handleAfterFieldTypeChange={handleAfterFieldTypeChange}
                          hasSubSections={!!sectionData?.current.sub_sections?.length}
                          hasMtopFormField={
                            !!sectionData?.current.fields?.find(
                              obj => obj.field_type && obj.field_type.value === 'M2P_FORM'
                            )
                          }
                          fieldsForTabular={sectionData?.current.fields?.filter(
                            obj =>
                              obj.field_type &&
                              (obj.field_type.value === 'M2P_FORM' ||
                                obj.field_type.value === 'SECTION_SEQ' ||
                                obj.field_type.value === 'CALCULATED_FIELD')
                          )}
                          fieldsForOperand={sectionData?.current.fields?.filter(
                            obj =>
                              (obj.field_type &&
                                obj.field_type?.value === 'TEXT_FIELD' &&
                                obj.keyboard_type?.value === 'NUMERIC') ||
                              obj.field_type?.value === 'CALCULATED_FIELD'
                          )}
                          validationBeforeChangeFieldData={validationBeforeChangeFieldData}
                          hasCalculatedSubtotalField={
                            !!sectionData?.current.fields?.find(
                              obj =>
                                obj.field_type && obj.field_type.value === 'CALCULATED_SUBTOTAL'
                            )
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <button
                  onClick={handleOnAddField}
                  disabled={!editable}
                  className={`${
                    !editable ? 'btn-disabled' : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
                  } my-[10px] max-w-[140px] py-3 px-8 text-white text-[12px] font-semibold rounded-md`}
                >
                  Add Field
                </button>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <div className="bg-[#F2F5FC] p-[24px] rounded-md mt-2">
        {subSections?.length === 0 ? (
          <button
            onClick={handleOnAddSubSection}
            disabled={!editable}
            className={`${
              !editable ? 'btn-disabled' : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
            } max-w-[140px] py-3 px-4 text-white text-[12px] font-semibold rounded-md`}
          >
            Add Sub Section
          </button>
        ) : (
          <DragDropContext onDragEnd={handleOnDragEndSubSections}>
            <Droppable droppableId="sub_sections" isDropDisabled={!editable}>
              {provided => (
                <div
                  className="flex flex-col gap-6"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {subSections.map((subSection, index) => (
                    <Draggable
                      key={subSection.section_id}
                      draggableId={subSection.section_id}
                      index={index}
                      isDragDisabled={!editable}
                    >
                      {provided => (
                        <div
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <SubSection
                            index={index}
                            data={subSection}
                            editable={editable}
                            onDelete={handleOnDeleteSubSection}
                            handleChangeSection={handleChangeSection}
                            firstIdx={sectionId.current}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <button
                    onClick={handleOnAddSubSection}
                    disabled={!editable}
                    className={`${
                      !editable ? 'btn-disabled' : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
                    } my-[10px] max-w-[140px] py-3 px-4 text-white text-[12px] font-semibold rounded-md`}
                  >
                    Add Sub Section
                  </button>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
      {enableJump && (
        <>
          <hr className="my-6" />
          <div className="text-[12px] font-semibold mb-2">Jump to Section</div>
          <div className="flex">
            <Select
              components={{ DropdownIndicator }}
              getOptionLabel={option => option?.section_title}
              getOptionValue={option => option?.section_id}
              onChange={section =>
                setJumpToSection(
                  section
                    ? {
                        section_id: section.section_id,
                        section_title: section.section_title
                      }
                    : null
                )
              }
              value={jumpToSection}
              isSearchable
              isClearable
              isDisabled={!editable}
              options={sections.filter(section => section.section_id !== data.section_id)}
              className={`flex-1 text-[12px] border rounded-lg max-w-[450px] w-full focus:outline-none focus:border-[#6546C3] mr-6`}
              placeholder="Select section"
              styles={{
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  color: '#C800A5',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({})
              }}
            />
            <button
              className="flex w-fit justify-center items-center h-[40px] px-3 mr-4 rounded bg-[#CDC6E1] bg-opacity-[0.2] text-[#6546C3] text-xs font-semibold"
              onClick={() => {
                setShowJumpSettingsModal(true)
                setCurrentSectionId(data.section_id)
              }}
              disabled={!jumpToSection || !editable}
            >
              <img src={ICONS.icSettings} className="inline w-6 mr-2" alt="settings icon" />
              Jump Settings
            </button>
            {(!sectionData.current?.jump_settings ||
              sectionData.current?.jump_settings?.length < 1) && (
              <div className="flex items-center">
                <img src={ICONS.icInfo} className="w-[20px] h-[20px] mr-1" />
                <p className="font-poppins text-[12px] flex items-center">
                  You haven't set up the jump settings yet.
                </p>
              </div>
            )}
          </div>
        </>
      )}

      <DeletePopup setContinueDelete={setDeleteField} />
    </div>
  )
}

export default Section
