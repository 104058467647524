import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { selectToken } from '../../redux/slices/authSlice'

const RequireNotAuth = () => {
  const location = useLocation()
  const token = useSelector(selectToken)

  return token ? <Navigate to={'/'} state={{ from: location }} /> : <Outlet />
}

export default RequireNotAuth
