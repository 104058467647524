import ReactSelect from 'react-select'
import Creatable from 'react-select/creatable'

import { DropdownIndicator } from '../DropdownIndicator'

const customSelectInputStyle = {
  control: provided => ({
    ...provided,
    borderColor: '#DEDEDE',
    borderRadius: '8px',
    ':focus': { borderColor: '#6546C3' },
    ':active': { borderColor: '#6546C3' },
    ':hover': { borderColor: '#6546C3' },
    paddingLeft: 4,
    boxShadow: 'none'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
}

export default function Select({
  options,
  value,
  onChange,
  isDisabled,
  placeholder,
  isClearable,
  isMulti,
  isCreatable,
  ...rest
}) {
  const Comp = isCreatable ? Creatable : ReactSelect
  return (
    <Comp
      components={{ DropdownIndicator }}
      onChange={onChange}
      options={options}
      value={value}
      isDisabled={isDisabled}
      className="input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
      placeholder={placeholder}
      isOptionDisabled={option => option.disabled}
      styles={customSelectInputStyle}
      isClearable={isClearable}
      isMulti={isMulti}
      {...rest}
    />
  )
}
