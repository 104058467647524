import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { useTable, useSortBy, useResizeColumns, useBlockLayout } from 'react-table'
import { useMediaQuery } from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { del, get } from '../../../../../api/base'
import SuccessPopup from '../../../../../components/Popup/SuccessPopup'
import { DeletePopup, Toast } from '../../../../../components'
import { apiUrls, ICONS, images } from '../../../../../constants'
import { logoutMicrosite, selectMicrositeTokens } from '../../../../../redux/slices/authSlice'
import {
  setMessage,
  setShowPopupDelete,
  setShowPopupSuccess
} from '../../../../../redux/slices/popupSlice'
import { PageContext } from '../../..'
import Filter from './Filter'
import moment from 'moment'
import FilterFormModal from './FilterFormModal'
import AdvanceSearchModal from './AdvanceSearchModal'
import ClassicView from './ClassicView'
import _ from 'lodash'
import FilterFieldModal from './FilterFieldModal'
import { removeFilterBasedOnMenu, saveFilterField } from '../../../../../redux/slices/formSlice'

export default function List() {
  const { microsite } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const micrositeTokens = useSelector(selectMicrositeTokens)
  const { page, topColor, bottomColor } = useContext(PageContext)
  const [deleteItem, setDeleteItem] = useState(null)
  const [isJustOneForm, setIsJustOneForm] = useState(page?.forms?.length === 1)
  const [dynamicHeaders, setDynamicHeaders] = useState([])
  const [fullData, setFullData] = useState(null)
  const data = fullData?.form_contents ? fullData?.form_contents : []

  const defaultHeaders = [
    {
      Header: 'Code Form',
      accessor: 'form.code_form',
      Cell: ({ value }) => value
    },
    {
      Header: 'Form',
      accessor: 'form.title',
      Cell: ({ value }) => value
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <span className="bg-teal-50 text-teal-500 px-2 py-1 text-xs rounded">{value}</span>
      )
    },
    {
      Header: 'Submitted Date',
      accessor: 'submitted_time',
      Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      Header: 'Submitted By',
      accessor: 'user.full_name',
      Cell: ({ value }) => value
    }
  ]
  const resultCondition = isJustOneForm && data?.length ? dynamicHeaders : defaultHeaders

  const COLUMNS = [
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex gap-2">
            {row.original.is_can_delete && (
              <img
                src={ICONS.icTrash}
                className="w-[28px] cursor-pointer"
                onClick={() => confirmDelete(row.original)}
                alt="delete"
              />
            )}
            {row.original.is_can_edit &&
              (topColor ? (
                <div className="relative">
                  <div
                    className="h-[28px] min-w-[28px] rounded-full"
                    style={{
                      background: topColor || '#6546C3',
                      opacity: '0.08'
                    }}
                  ></div>
                  <div
                    className="h-[22px] w-[22px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
                    style={{
                      background: bottomColor
                        ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                        : topColor
                    }}
                  ></div>
                  <img
                    src={ICONS.icEditTransparent}
                    className="w-[14px] h-[14px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                    onClick={() => navigate(`edit/${row.original.id}`)}
                    alt="edit"
                  />
                </div>
              ) : (
                <img
                  src={ICONS.icEditTeal}
                  className="w-[28px] cursor-pointer"
                  onClick={() => navigate(`edit/${row.original.id}`)}
                  alt="edit"
                />
              ))}
            {topColor ? (
              <div className="relative">
                <div
                  className="h-[28px] min-w-[28px] rounded-full"
                  style={{
                    background: topColor || '#6546C3',
                    opacity: '0.08'
                  }}
                ></div>
                <div
                  className="h-[22px] w-[22px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
                  style={{
                    background: bottomColor
                      ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                      : topColor
                  }}
                ></div>
                <img
                  src={ICONS.icDetailTransparent}
                  className="w-[14px] h-[14px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                  onClick={() => navigate(`view/${row.original.id}`)}
                  alt="view detail"
                />
              </div>
            ) : (
              <img
                src={ICONS.icDetailTeal}
                className="w-[28px] cursor-pointer"
                onClick={() => navigate(`view/${row.original.id}`)}
                alt="view detail"
              />
            )}
          </div>
        )
      }
    }
  ].concat(resultCondition)

  const columns = useMemo(() => COLUMNS, [isJustOneForm, dynamicHeaders])
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [activeView, setActiveView] = useState('tableView')
  const [filterLength, setFilterLength] = useState(0)
  const [advanceSearchLength, setAdvanceSearchLength] = useState(0)
  const [showFilterFormModal, setShowFilterFormModal] = useState(false)
  let localFilterForm = useSelector(state => {
    let tempFilterForm = state.form.filterForm
    if (tempFilterForm?.hasOwnProperty(microsite)) {
      if (tempFilterForm[microsite]?.hasOwnProperty(page.id)) {
        let tempValue = tempFilterForm[microsite][page.id]
        return tempValue
      } else {
        return null
      }
    } else {
      return null
    }
  })
  const [advanceSearch, setAdvanceSearch] = useState('')
  const [advanceSearchModal, setAdvanceSearchModal] = useState(false)
  const [advanceFields, setAdvanceFields] = useState([])
  const [logicalFilter, setLogicalFilter] = useState([])
  const totalPage = fullData?.total_page
  const totalData = fullData?.total_data

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    setHiddenColumns,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        hiddenColumns: []
      },
      pageNum
    },
    useBlockLayout,
    useSortBy,
    useResizeColumns
  )

  useEffect(() => {
    if (isDesktop) {
      setHiddenColumns([])
    } else {
      setHiddenColumns(['logo_url', 'description'])
    }
  }, [isDesktop])

  useEffect(() => {
    if (sortBy.length > 0) {
      setManualSortBy(sortBy[0].id)
      setOrderBy(sortBy[0].desc ? 'desc' : 'asc')
    } else {
      setManualSortBy('')
      setOrderBy('')
    }
  }, [sortBy])

  useEffect(() => {
    fetch()
  }, [
    page,
    search,
    limit,
    pageNum,
    orderBy,
    manualSortBy,
    advanceFields,
    advanceSearch,
    localFilterForm?.selectedForms,
    localFilterForm?.endDate,
    localFilterForm?.startDate,
    localFilterForm?.logical
  ])

  useEffect(() => {
    populateAdvanceSearch()
  }, [advanceFields, advanceSearch])

  useEffect(() => {
    if (localFilterForm?.selectedFields && data?.length > 0) {
      updateDynamicHeaders()
    }
  }, [localFilterForm?.selectedFields])

  function populateFilterForm(formData) {
    let length = 0
    if (localFilterForm?.endDate) {
      length = length + 1
    }
    if (localFilterForm?.startDate) {
      length = length + 1
    }
    if (localFilterForm?.selectedForms?.length) {
      length = length + 1
    }
    setIsJustOneForm(localFilterForm?.selectedForms.length === 1 || page?.forms?.length === 1)
    if (formData?.form_contents.length > 0) {
      populateDynamicHeaders(formData?.form_contents)
    }
    setFilterLength(length)
  }

  function populateAdvanceSearch() {
    let length = 0
    if (advanceFields.length) {
      length = length + 1
    }
    if (advanceSearch) {
      length = length + 1
    }
    setAdvanceSearchLength(length)
  }

  function populateDynamicHeaders(form) {
    if (form?.length && isJustOneForm) {
      const tempForm = form.find(
        form =>
          form?.form?.id === localFilterForm?.selectedForms[0]?.value ||
          form?.form?.id === page?.forms[0]?.form?.id
      )
      const sortingFields = tempForm?.form?.sorting_fields?.slice(0, 4)
      if (
        localFilterForm?.selectedForms.length === 1 &&
        _.isEmpty(localFilterForm?.selectedFields)
      ) {
        dispatch(
          saveFilterField({
            micrositeName: microsite,
            pageId: page.id,
            filterField: {
              selectionFields: sortingFields,
              fieldList: tempForm?.form?.sorting_fields
            }
          })
        )
      } else if (!localFilterForm?.selectedForms.length) {
        dispatch(removeFilterBasedOnMenu({ micrositeName: microsite, pageId: page.id }))
      }
      const resultHeaders = !tempForm
        ? []
        : sortingFields?.map((field, index) => {
            return {
              Header: (
                <div className="tooltip tooltip-bottom" data-tip={field?.field_title}>
                  <div className="text-[12px] line-clamp-2 px-2">{field?.field_title}</div>
                </div>
              ),
              accessor: `form.sorting_fields[${index}].value`,
              Cell: ({ value }) =>
                _.isObject(value) ? (
                  <div className={`${value?.length > 30 && 'tooltip'}`} data-tip={value.value}>
                    <div className="text-[12px] line-clamp-2 px-2">{value.value}</div>
                  </div>
                ) : value?.includes('data:image/png') ? (
                  <img src={value} />
                ) : _.isArray(value) ? (
                  value.map(val => (
                    <div className={`${value?.length > 30 && 'tooltip'}`} data-tip={value}>
                      <div className="text-[12px] line-clamp-2 px-2">{val.value}</div>
                    </div>
                  ))
                ) : (
                  <div className={`${value?.length > 30 && 'tooltip'}`} data-tip={value}>
                    <div className="text-[12px] line-clamp-2 px-2">{value}</div>
                  </div>
                )
            }
          })
      setDynamicHeaders(resultHeaders)
    }
  }

  function updateDynamicHeaders() {
    const resultHeaders = localFilterForm?.selectedFields?.selectionFields?.map((field, index) => {
      return {
        Header: (
          <div className="tooltip tooltip-bottom" data-tip={field?.field_title}>
            <div className="text-[12px] line-clamp-2 px-2">{field?.field_title}</div>
          </div>
        ),
        accessor: `form.sorting_fields[${index}].value`,
        Cell: ({ value }) => {
          return _.isObject(value) ? (
            <div className={`${value?.length > 30 && 'tooltip'}`} data-tip={value.value}>
              <div className="text-[12px] line-clamp-2 px-2">{value.value}</div>
            </div>
          ) : value?.includes('data:image/png') ? (
            <img src={value} />
          ) : _.isArray(value) ? (
            value.map(val => (
              <div className={`${value?.length > 30 && 'tooltip'}`} data-tip={value}>
                <div className="text-[12px] line-clamp-2 px-2">{val.value}</div>
              </div>
            ))
          ) : (
            <div className={`${value?.length > 30 && 'tooltip'}`} data-tip={value}>
              <div className="text-[12px] line-clamp-2 px-2">{value}</div>
            </div>
          )
        }
      }
    })
    setDynamicHeaders(resultHeaders)
  }

  function fetch(currPage) {
    let query = {}
    if (search !== '') query.search = search
    if (pageNum !== '') query.page = currPage ? currPage : pageNum
    if (limit !== '') query.limit = limit
    if (manualSortBy !== '') query.sort_by = manualSortBy
    if (orderBy !== '') query.order_by = orderBy
    if (advanceSearch) query.keyword = advanceSearch
    if (advanceFields.length) query.search_fields = advanceFields.map(field => field.value)
    if (localFilterForm?.logical.length) query.filter = populateLogical(localFilterForm?.logical)
    if (localFilterForm?.selectedForms?.length)
      query.form_id = localFilterForm?.selectedForms.map(form => form.value)
    if (localFilterForm?.startDate) query.start_date = moment(localFilterForm?.startDate).format()
    if (localFilterForm?.endDate) query.end_date = moment(localFilterForm?.endDate).format()
    get(`${apiUrls.MICROSITES_ROUTE}/${microsite}/menus/${page.id}/form-contents`, {
      query,
      headers: {
        Authorization: `Bearer ${micrositeTokens[microsite.toLowerCase()]}`
      }
    }).then(response => {
      const { ok, status, data } = response
      if (!ok && status === 401) {
        dispatch(logoutMicrosite())
        return
      }

      if (status === 200) {
        if (pageNum > data?.total_page) {
          setPageNum(1)
        }
        setFullData(data)
        populateFilterForm(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch microsites'} />)
      }
    })
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'text-white' : ''
            }`}
            style={{
              background:
                pageNum === data
                  ? bottomColor
                    ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                    : topColor || 'linear-gradient(to right, #01a76b, #00B1B5)'
                  : ''
            }}
            onClick={() => {
              setPageNum(data)
            }}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 !== 0
  }

  const goToNextPage = () => {
    setPageNum(prev => {
      return prev + 1
    })
  }
  const goToPreviousPage = () => {
    setPageNum(prev => {
      return prev - 1
    })
  }

  function confirmDelete(item) {
    setDeleteItem(item)
    dispatch(setShowPopupDelete(true))
    dispatch(setMessage('Are you sure you want to delete this submission?'))
  }

  async function onDelete() {
    const { ok, data } = await del(`${apiUrls.FORMS_URL}/contents/${deleteItem.id}`)
    if (ok) {
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully deleted submission'))
      fetch()
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
    }
  }

  function isTableView() {
    if (activeView === 'tableView') {
      return true
    } else {
      return false
    }
  }

  function populateLogical(logical) {
    let tempLogical = logical.map(logic => {
      if (
        (logic.field.fieldData?.fieldType.value === 'TEXT_FIELD' &&
          logic.field.fieldData?.keyboardType.value !== 'NUMERIC') ||
        logic.field.fieldData?.fieldType.value !== 'DATE_PICKER'
      ) {
        delete logic.field.rules.condition
      }
      if (_.has(logic.field.rules, 'condition')) {
        return `${logic?.operator}|${logic?.field?.fieldData?.value}|${
          logic?.field?.rules.condition?.value
        }|${logic?.field?.rules?.value?.payload || logic?.field?.rules?.value}`
      } else {
        return `${logic?.operator}|${logic?.field?.fieldData?.value}|${
          logic?.field?.rules?.value?.payload || logic?.field?.rules?.value || 'undefined'
        }`
      }
    })
    return tempLogical
  }

  return (
    <>
      <div className="flex flex-col md:flex-row gap-2 items-center justify-between font-poppins text-[12px] mb-10">
        <NavLink
          to="create"
          className="inline-block px-5 py-3 text-white font-semibold rounded-md text-xs"
          style={{
            backgroundColor: topColor || '#00B1B5'
          }}
        >
          Create Data
        </NavLink>

        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
              alt="page limit icon"
            />
            <select
              value={limit}
              onChange={e => {
                setLimit(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(limit => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex w-full md:w-fit gap-4">
          <div
            className="flex flex-row border rounded-md p-1 gap-1 h-[40px] items-center cursor-pointer"
            onClick={() => setShowFilterFormModal(true)}
          >
            {filterLength !== 0 && (
              <div
                className="p-2 text-[12px] font-bold rounded-md text-white"
                style={{ background: topColor || '#00B1B5' }}
              >
                {filterLength}
              </div>
            )}
            <img src={ICONS.icFilterGray} width={26} height={26} />
          </div>
          <FilterFieldModal
            topColor={topColor || '#00B1B5'}
            fields={localFilterForm?.selectedFields}
            isJustOneForm={isJustOneForm}
            micrositeName={microsite}
            pageId={page.id}
            selectedForms={localFilterForm?.selectedForms || page.forms[0] || []}
          />
          <div className="flex flex-row">
            <div className="relative cursor-pointer" onClick={() => setActiveView('tableView')}>
              <div
                className="flex h-[40px] min-w-[28px] rounded-l-md items-center justify-center p-2"
                style={{
                  border: isTableView() ? '' : `1px solid ${topColor || '#00B1B5'}`,
                  background: isTableView()
                    ? bottomColor
                      ? `linear-gradient(to bottom, ${topColor}, ${bottomColor})`
                      : topColor || '#00B1B5'
                    : 'white'
                }}
              >
                <img
                  className="cursor-pointer self-center"
                  src={isTableView() ? ICONS.icTable : ICONS.icTableGreen}
                  width={24}
                  height={24}
                />
              </div>
            </div>
            <div
              className={`relative ${isJustOneForm ? 'cursor-pointer' : 'opacity-25'}`}
              onClick={isJustOneForm ? () => setActiveView('classicView') : null}
            >
              <div
                className="flex h-[40px] min-w-[28px] rounded-r-md items-center justify-center p-2"
                style={{
                  border: isTableView() ? `1px solid ${topColor || '#00B1B5'}` : '',
                  background: isTableView()
                    ? 'white'
                    : bottomColor
                    ? `linear-gradient(to bottom, ${topColor}, ${bottomColor})`
                    : topColor || '#00B1B5'
                }}
              >
                <img
                  className=" self-center"
                  src={isTableView() ? ICONS.icListGreen : ICONS.icList}
                  width={24}
                  height={24}
                />
              </div>
            </div>
          </div>
          <Filter filter={search} setFilter={setSearch} />
          <div
            onClick={isJustOneForm ? () => setAdvanceSearchModal(true) : null}
            className={`flex flex-row border rounded-md p-1 gap-1 h-[40px] items-center ${
              isJustOneForm ? 'cursor-pointer' : ''
            } `}
          >
            {advanceSearchLength !== 0 && (
              <div
                className="p-2 text-[12px] font-bold rounded-md text-white"
                style={{ background: topColor || '#00B1B5' }}
              >
                {advanceSearchLength}
              </div>
            )}
            <img
              className={`${isJustOneForm ? '' : 'opacity-25'}`}
              src={ICONS.icAdvanceSearch}
              width={26}
              height={26}
            />
          </div>
        </div>
      </div>

      {isTableView() ? (
        <div className="overflow-x-auto w-full">
          <table {...getTableProps()} className="w-full font-poppins text-[12px]">
            <thead className="">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      className="min-w-[150px]"
                      {...(isJustOneForm
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                    >
                      <div className="flex gap-1 justify-center">
                        {column.render('Header')}
                        {!isJustOneForm && column.canSort && (
                          <img
                            src={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? ICONS.icSortDown
                                  : ICONS.icSortUp
                                : ICONS.icSort
                            }
                            className="w-2"
                            alt="sort icon"
                          />
                        )}
                        {column.render('Header') !== 'Action' && (
                          <div {...column.getResizerProps()} className={`resizer w-2`}>
                            <div className="w-[0.5px] bg-gray-400 h-4" />
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td className="min-w-[150px]" {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <ClassicView topColor={topColor || '#00B1B5'} formData={data} />
      )}
      {data.length === 0 && (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
          <div className="font-semibold text-[18px] mb-2">
            {search.length ? 'Not Found' : 'You’ve not submitted any form yet'}
          </div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "create form" button to create now!
          </div>
        </div>
      )}
      <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-9">
        {data.length !== 0 ? (
          <div className="font-poppins text-[12px]">
            Showing {pageNum * limit - (limit - 1)} -{' '}
            {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
          </div>
        ) : (
          <div className="font-poppins text-[12px]">No data to show</div>
        )}
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage() ? '' : 'text-white'
            }`}
            style={{
              background: !canPreviousPage()
                ? '#F2F5FC'
                : bottomColor
                ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                : topColor || 'linear-gradient(to right, #01a76b, #00B1B5)'
            }}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage() ? '' : 'text-white'
            }`}
            style={{
              background: !canNextPage()
                ? '#F2F5FC'
                : bottomColor
                ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                : topColor || 'linear-gradient(to right, #01a76b, #00B1B5)'
            }}
          >
            &gt;
          </button>
        </div>
      </div>
      <FilterFormModal
        showModal={showFilterFormModal}
        setShowModal={setShowFilterFormModal}
        page={page}
        topColor={topColor || '#00B1B5'}
        selectedFormOption={localFilterForm?.selectedForms || page.forms[0]?.form || []}
        startDate={localFilterForm?.startDate || ''}
        endDate={localFilterForm?.endDate || ''}
        logicalFilter={logicalFilter}
        setLogicalFilter={setLogicalFilter}
        populateLogical={populateLogical}
        micrositeName={microsite}
        menuId={page.id}
      />
      <AdvanceSearchModal
        selectedForm={
          localFilterForm?.selectedForms?.length ? localFilterForm?.selectedForms[0] : page.forms[0]
        }
        showModal={advanceSearchModal}
        setShowModal={setAdvanceSearchModal}
        topColor={topColor || '#00B1B5'}
        selectedFieldOptions={advanceFields}
        setSelectedFieldOption={setAdvanceFields}
        advanceSearch={advanceSearch}
        setAdvanceSearch={setAdvanceSearch}
      />
      <SuccessPopup />
      <DeletePopup setContinueDelete={onDelete} buttonNext={'Delete Now'} />
    </>
  )
}
