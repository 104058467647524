import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import authSlice from './slices/authSlice'
import micrositeSlice from './slices/micrositeSlice'
import masterSlice from './slices/masterSlice'
import popupSlice from './slices/popupSlice'
import userSlice from './slices/userSlice'
import formSlice from './slices/formSlice'
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'user', 'form']
}

const rootReducer = combineReducers({
  auth: authSlice,
  master: masterSlice,
  popup: popupSlice,
  user: userSlice,
  microsite: micrositeSlice,
  form: formSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})
