import { useEffect, useRef } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'

export default function Signature({ value, onChange, disabled }) {
  const signatureCanvasRef = useRef()

  useEffect(() => {
    signatureCanvasRef.current.fromDataURL(value)
  }, [value])

  return (
    <div className="relative border rounded-lg bg-white text-xs">
      {disabled && <div className="absolute inset-0 z-10" />}
      <button
        type="button"
        className="absolute top-3 right-3 bg-gray-200 rounded-lg px-2 py-1 text-xs"
        onClick={() => {
          signatureCanvasRef?.current?.clear()
          onChange('')
        }}
      >
        Clear
      </button>
      <ReactSignatureCanvas
        ref={signatureCanvasRef}
        canvasProps={{ className: 'w-full h-[240px]' }}
        clearOnResize={false}
        onEnd={() => {
          onChange(signatureCanvasRef?.current?.toDataURL('image/png'))
        }}
      />
    </div>
  )
}
