import moment from 'moment/moment'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import { useRef } from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { useSortBy, useTable } from 'react-table'
import { get } from '../../../api/base'
import { Toast, DropdownIndicator } from '../../../components'
import { apiUrls, ICONS } from '../../../constants'
import Select, { components, ValueContainerProps } from 'react-select'
import { toast } from 'react-toastify'
const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="flex w-full md:w-fit font-poppins border-[1px] items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}
export const getRandomColor = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const getInitials = name => {
  let initials
  const nameSplit = name.split(' ')
  const nameLength = nameSplit.length
  if (nameLength > 1) {
    initials = nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1)
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1)
  } else return

  return initials.toUpperCase()
}

export const createImageFromInitials = (size, name, color) => {
  if (name == null) return
  name = getInitials(name)

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.width = canvas.height = size

  context.fillStyle = '#ffffff'
  context.fillRect(0, 0, size, size)

  context.fillStyle = `${color}`
  context.fillRect(0, 0, size, size)

  context.fillStyle = '#ffffff'
  context.textBaseline = 'middle'
  context.textAlign = 'center'
  context.font = `${size / 2}px Poppins`
  context.fillText(name, size / 2, size / 2)

  return canvas.toDataURL()
}

const TaskTable = () => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const COLUMNS = [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row, value, isDesktop }) => {
        if (isDesktop) {
          return value
        } else {
          return (
            <button
              className=" md:no-disabled underline md:no-underline text-blue-700 md:text-current md:font-reguler underline-offset-2"
              onClick={() => {}}
            >
              {value}
            </button>
          )
        }
      }
    },
    {
      Header: 'Planned Start',
      accessor: 'planned_start',
      Cell: ({ row, value }) => {
        return moment(value).format('DD-MMM-YYYY HH:mm')
      }
    },
    {
      Header: 'Due Date',
      accessor: 'due_date',
      Cell: ({ row, value }) => {
        return moment(value).format('DD-MMM-YYYY HH:mm')
      }
    },
    {
      Header: 'Assign To',
      accessor: 'users',
      Cell: ({ row, value }) => {
        let imgSrc = ''
        let rawAssignto = row.original.groups.concat(value)
        return (
          <div className=" flex flex-row gap-1">
            {rawAssignto.map((user, index) => {
              if (index <= 1) {
                return (
                  <img
                    key={index}
                    className="w-[28px] rounded-full cursor-pointer"
                    src={
                      imgSrc.length <= 0
                        ? createImageFromInitials(500, user.full_name || user.name, '#C800A5')
                        : imgSrc
                    }
                  />
                )
              } else {
                rawAssignto.splice(0, 2)
                let usersLeft = rawAssignto.map((raw, index) => {
                  return raw.full_name || raw.name
                })
                return (
                  <div key={index} className="tooltip" data-tip={usersLeft}>
                    <img
                      className="w-[28px] rounded-full cursor-pointer"
                      src={
                        imgSrc.length <= 0
                          ? createImageFromInitials(500, `+ ${usersLeft.length}`, '#C800A5')
                          : imgSrc
                      }
                    />
                  </div>
                )
              }
            })}
          </div>
        )
      }
    },

    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row, value, pageNum }) => {
        if (value === 'COMPLETED_TASK') {
          return (
            <div className="flex justify-center place-items-center bg-[#C800A5] bg-opacity-[0.08] rounded-[8px] w-[59px] h-[28px]">
              <span className="text-[#C800A5] font-poppins font-semibold ">Closed</span>
            </div>
          )
        } else if (value === 'OPEN_TASK') {
          return (
            <div className="flex justify-center place-items-center bg-[#01B59C] bg-opacity-[0.08] rounded-[8px] w-[59px] h-[28px]">
              <span className="text-[#01B59C] font-poppins font-semibold ">Open</span>
            </div>
          )
        } else {
          return (
            <div className="flex justify-center place-items-center bg-[#6546C3] bg-opacity-[0.08] rounded-[8px] w-[59px] h-[28px]">
              <span className="text-[#6546C3] font-poppins font-semibold ">Draft</span>
            </div>
          )
        }
      }
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleDetailFormClick = () => {
          const DETAIL_TASK_PATH = `/DocumentLibrary/Task/DetailTask/${row.original.id}`
          const DETAIL_TASK_CRUMBS = 'Document Library/Task/Detail Task'
          const crumbs = DETAIL_TASK_CRUMBS.split('/')
          navigate(DETAIL_TASK_PATH, {
            state: { title: 'Detail Task', crumbs: crumbs }
          })
        }
        const handleEditTaskClick = () => {
          const DETAIL_TASK_PATH = `/DocumentLibrary/Task/EditTask/${row.original.id}`
          const DETAIL_TASK_CRUMBS = 'Document Library/Task/Edit Task'
          const crumbs = DETAIL_TASK_CRUMBS.split('/')
          navigate(DETAIL_TASK_PATH, {
            state: { title: 'Detail Task', crumbs: crumbs }
          })
        }
        return (
          <div className="flex flex-wrap gap">
            {row.original.status === 'DRAFT' ? (
              <img
                src={ICONS.icEdit}
                className="w-[28px] cursor-pointer"
                onClick={() => handleEditTaskClick()}
              />
            ) : null}
            <img
              src={ICONS.icDetail}
              className="w-[28px] cursor-pointer"
              onClick={() => handleDetailFormClick()}
            />
          </div>
        )
      }
    }
  ]
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const isAddRow = useRef(false)
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [taskData, setTaskData] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedInitiatedOption, setSelectedInitiatedOption] = useState(null)
  const [initiatedOptions, setInitiatedOptions] = useState([
    { value: 'OPEN_TASK', label: 'Open' },
    { value: 'DRAFT', label: 'Draft' },
    { value: 'COMPLETED_TASK', label: 'Closed' }
  ])
  const [typeTask, setTypeTask] = useState(['All', 'Task', 'Following'])
  const [activeType, setActiveType] = useState('All')
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    setHiddenColumns,
    prepareRow
  } = useTable(
    {
      columns,
      data: data || [],
      manualSortBy: true,
      initialState: {
        hiddenColumns: []
      },
      pageNum,
      isDesktop
    },
    useSortBy
  )
  useEffect(() => {
    if (sortBy.length > 0) {
      setManualSortBy(sortBy[0].id)
      setOrderBy(sortBy[0].desc ? 'desc' : 'asc')
    } else {
      setManualSortBy('')
      setOrderBy('')
    }
  }, [sortBy])
  useEffect(() => {
    if (isDesktop) {
      setHiddenColumns([])
    } else {
      setHiddenColumns(['planned_start', 'action'])
    }
  }, [isDesktop])
  useEffect(() => {
    fetchTasks()
  }, [
    search,
    pageNum,
    limit,
    manualSortBy,
    orderBy,
    selectedInitiatedOption,
    startDate,
    endDate,
    activeType
  ])
  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => setPageNum(data)}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }
  const handleOnStartDateChange = newDate => {
    setStartDate(newDate)
  }
  const handleOnEndDateChange = newDate => {
    setEndDate(newDate)
  }
  const handleOnInitiatedChange = selectedOption => {
    setSelectedInitiatedOption(selectedOption)
  }
  function fetchTasks(currPage) {
    let query = {
      query: {}
    }
    let status
    if (selectedInitiatedOption) {
      status = selectedInitiatedOption.map((option, index) => {
        return option.value
      })
    }
    if (startDate !== '') query.query.planned_start = startDate
    if (endDate !== '') query.query.due_date = endDate
    if (activeType !== '') query.query.type = activeType.toLowerCase()
    if (status !== '') query.query.status_task = status
    if (search !== '') query.query.search = search

    if (pageNum !== '') query.query.page = currPage ? currPage : pageNum

    if (limit !== '') query.query.limit = limit

    if (manualSortBy !== '') query.query.sort_by = manualSortBy

    if (orderBy !== '') query.query.order_by = orderBy

    get(apiUrls.TASKS_URL, query).then(response => {
      const { status } = response
      if (status === 200) {
        if (pageNum > response.data?.total_page) {
          setPageNum(1)
        }
        setData(response.data.data)
        setTotalPage(response.data.total_page)
        setTotalData(response.data.total_data)
      } else {
        switch (status) {
          case 408:
            toast.error(
              <Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />
            )
            break
          case 400:
            toast.error(
              <Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />
            )
            break
          default:
            toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
            break
        }
      }
    })
  }
  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function handleAddFormClick() {
    const ADD_TASK_PATH = '/Create/Task/AddTask'
    const ADD_TASK_CRUMBS = 'Create/Task/AddTask'
    const crumbs = ADD_TASK_CRUMBS.split('/')
    navigate(ADD_TASK_PATH, { state: { title: 'Create', crumbs: crumbs } })
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 != 0
  }

  const goToNextPage = () => {
    setPageNum(pageNum + 1)
  }
  const goToPreviousPage = () => {
    setPageNum(pageNum - 1)
  }
  const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children

    if (Array.isArray(values)) {
      const val = i => values[i].props.children
      const { length } = values

      switch (length) {
        case 1:
          values = `${val(0)}`
          break
        case 2:
          values = `${val(0)}, ${val(1)}`
          break
        case 3:
          values = `${val(0)}, ${val(1)}, ${val(2)}`
          break
      }
    }
    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    )
  }
  return (
    <div className="p-2">
      <div className="flex flex-col p-6 rounded-2xl bg-[#F2F5FC] gap-4">
        <div className="font-semibold text-[16px] text-[#C800A5]">Filter</div>
        <div className="flex gap-2">
          <div className="flex flex-1 items-center gap-2">
            <div className="flex flex-1 relative">
              <DatePicker
                selected={startDate}
                onChange={date => handleOnStartDateChange(date)}
                dateFormat="dd-MMM-yyyy"
                name="Start date"
                className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                placeholderText={'Start date'}
              />
              <img
                src={ICONS.icCalendar}
                className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
              />
            </div>
            -
            <div className="flex flex-1 relative">
              <DatePicker
                selected={endDate}
                onChange={date => handleOnEndDateChange(date)}
                dateFormat="dd-MMM-yyyy"
                name="End date"
                className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                placeholderText={'End date'}
              />
              <img
                src={ICONS.icCalendar}
                className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
              />
            </div>
            <Select
              components={{ DropdownIndicator, ValueContainer }}
              defaultValue={selectedInitiatedOption}
              isMulti
              onChange={selectedOption => handleOnInitiatedChange(selectedOption)}
              options={initiatedOptions}
              isSearchable={false}
              name="Attachment"
              className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
              placeholder="Select Initiated"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: 'none',
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                indicatorSeparator: () => ({})
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col border-t-2 border-b-[#DEDEDE] md:flex-row gap-2 items-center justify-between font-poppins text-[12px] mb-10 mt-5 pt-5">
        <button
          className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-8 py-3"
          onClick={() => handleAddFormClick()}
        >
          Add Task
        </button>
        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
            />
            <select
              value={limit}
              onChange={e => {
                setLimit(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(limit => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex sm:flex-row  flex-col  ">
          <div className="flex w-full md:w-fit mr-[24px] mb-[20px]">
            <GlobalFilter filter={search} setFilter={setSearch} />
          </div>
          <div className="btn-group">
            {typeTask?.map((filter, index) => (
              <button
                key={index}
                className={`btn h-[40px] font-normal text-xs normal-case
              border-solid border-[#472F92]
              w-[72px] min-h-[40px] p-0 ${
                activeType === filter
                  ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white hover:bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
                  : 'bg-white text-[#472F92] hover:bg-gray-300'
              }`}
                onClick={() => setActiveType(filter)}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>
      </div>
      <table {...getTableProps()} className="font-poppins text-[12px]">
        <thead className="">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex gap-1">
                    {column.render('Header')}
                    <img
                      src={
                        column.canSort
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? ICONS.icSortDown
                              : ICONS.icSortUp
                            : ICONS.icSort
                          : ''
                      }
                      className="w-2"
                    />
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-9">
        <div className="font-poppins text-[12px]">
          Showing {pageNum * limit - (limit - 1)} -{' '}
          {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
        </div>
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  )
}

export default TaskTable
