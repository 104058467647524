import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import { ICONS, apiUrls } from '../../../constants'
import { Editor } from 'react-draft-wysiwyg'
import { get } from '../../../api/base'
import { Toast } from '../../../components'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { FileUploader } from 'react-drag-drop-files'
import Select from 'react-select'
import { DropdownIndicator } from '../../../components'
import { selectUserData } from '../../../redux/slices/userSlice'
import { format } from 'date-fns'
import ReactAudioPlayer from 'react-audio-player'
import { useDispatch } from 'react-redux'
import { EditorState, convertToRaw } from 'draft-js'
import { post } from '../../../api/base'
import { useNavigate } from 'react-router-dom'
import { convertToHTML } from 'draft-convert'
import { draftLogic } from '../../../constants'
import DeletePopup from '../../../components/Popup/DeletePopup'
import { setShowPopupDelete, setMessage } from '../../../redux/slices/popupSlice'
import mockData from '../../../constants/mockData'

const AddTaskPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [plannedStartDate, setPlannedStartDate] = useState(null)
  const userData = useSelector(selectUserData)
  const [dueDate, setDueDate] = useState(null)
  const [selectAllMember, setSelectAllMember] = useState(false)
  const [selectAllMemberGroup, setSelectAllMemberGroup] = useState(false)
  const [totalMemberChecked, setTotalMemberChecked] = useState(0)
  const [totalMemberGroupChecked, setTotalMemberGroupChecked] = useState(0)
  const [searchMemberQuery, setSearchMemberQuery] = useState('')
  const [searchMemberGroupQuery, setSearchMemberGroupQuery] = useState('')
  const [activeTab, setActiveTab] = useState('users')
  const [listOfMembers, setListOfMembers] = useState([])
  const [listOfMembersGroup, setListOfMembersGroup] = useState([])
  const [listVisibility, setListVisibility] = useState([])
  const [listWorkLocation, setListWorkLocation] = useState([])
  const [selectedVisibility, setSelectedVisibility] = useState({})
  const [selectedWorkLocation, setSelectedWorkLocation] = useState(null)
  const [selectedAssignTo, setSelectedAssignTo] = useState([])
  const [selectedGroup, setSelectedGroup] = useState([])
  const [continueSaveDraft, setContinueSaveDraft] = useState(false)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [file, setFile] = useState([])
  const [isLoadingTask, setIsLoadingTask] = useState(false)
  const [validationForm, setValidationForm] = useState({
    title: false,
    description: false,
    planned_start: false,
    due_date: false,
    visibility: false,
    status: false
  })
  const [form, setForm] = useState({
    title: '',
    description: '',
    planned_start: '',
    due_date: '',
    visibility: '',
    status: ''
  })

  useEffect(() => {
    let counter = 0
    const listOfMembersLength = listOfMembers.length
    listOfMembers.map(member => {
      if (member.checked) {
        counter++
      }
    })
    setTotalMemberChecked(counter)
    setSelectAllMember(listOfMembersLength === counter && counter !== 0)
  }, [listOfMembers])

  useEffect(() => {
    let data = convertToHTML(draftLogic.HTML_TO_DRAFT)(editorState.getCurrentContent())
    setForm({ ...form, description: data })
  }, [editorState])

  useEffect(() => {
    let counter = 0
    const listOfMembersLength = listOfMembers.length
    listOfMembersGroup.map(member => {
      if (member.checked) {
        counter++
      }
    })
    setTotalMemberGroupChecked(counter)
    setSelectAllMemberGroup(listOfMembersLength === counter && counter !== 0)
  }, [listOfMembersGroup])

  useEffect(() => {
    const tagInput = document.getElementById('search-member')
    if (tagInput) {
      tagInput.focus()
    }
  }, [searchMemberQuery])

  useEffect(() => {
    const tagInput = document.getElementById('search-member-group')
    if (tagInput) {
      tagInput.focus()
    }
  }, [searchMemberGroupQuery])

  useEffect(() => {
    fetchMembers()
    fetchGroups()
    fetchVisibility()
    fetchWorkLocation()

    // hide some icon from editor
    document.querySelector('[title="Monospace"]').style.display = 'none'
    document.querySelector('[title="Superscript"]').style.display = 'none'
    document.querySelector('[title="Subscript"]').style.display = 'none'
    document.querySelector('[title="Indent"]').style.display = 'none'
    document.querySelector('[title="Outdent"]').style.display = 'none'
  }, [])

  useEffect(() => {
    if (continueSaveDraft) {
      handleSaveSubmit('DRAFT')
    }
  }, [continueSaveDraft])

  const handleOnCheckAllMember = event => {
    var newListOfMembers = listOfMembers.map(member => {
      return { ...member, checked: event.target.checked }
    })
    setListOfMembers(newListOfMembers)
    setSelectAllMember(event.target.checked)

    var checkedMembers = newListOfMembers.filter(member => {
      if (member.checked) {
        return member
      }
    })
    var assignTo = checkedMembers.map(member => {
      return member.id
    })
    setForm({ ...form, assign_to: assignTo })
  }

  const handleOnChangeCheckMember = (id, event) => {
    var newListOfMembers = listOfMembers.map(member =>
      member.id === id ? { ...member, checked: event.target.checked } : member
    )
    setListOfMembers(newListOfMembers)

    var checkedMembers = newListOfMembers.filter(member => {
      if (member.checked) {
        return member
      }
    })
    var assignTo = checkedMembers.map(member => {
      return member.id
    })
    setSelectedAssignTo(assignTo)
  }

  const handleOnChangeCheckMemberGroup = (id, event) => {
    var newListOfMembers = listOfMembersGroup.map(member =>
      member.id === id ? { ...member, checked: event.target.checked } : member
    )
    setListOfMembersGroup(newListOfMembers)

    var checkedMembers = newListOfMembers.filter(member => {
      if (member.checked) {
        return member
      }
    })
    var assignTo = checkedMembers.map(member => {
      return member.id
    })
    setSelectedGroup(assignTo)
  }

  const fetchMembers = async () => {
    get(apiUrls.TASKS_USER_URL).then(response => {
      const { status } = response
      if (status === 200) {
        populateMemberList(response.data.users)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        populateMemberList([])
      }
    })
  }

  const fetchGroups = async () => {
    get(apiUrls.TASKS_GROUP_URL).then(response => {
      const { status } = response
      if (status === 200) {
        populateMemberGroupList(response.data.groups)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        populateMemberGroupList([])
      }
    })
  }

  const fetchVisibility = async () => {
    get(apiUrls.TASKS_VISIBILITY).then(response => {
      const { status } = response
      if (status === 200) {
        populateVisibility(response.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        populateVisibility([])
      }
    })
  }

  const fetchWorkLocation = async () => {
    get(apiUrls.WORK_LOCATIONS).then(response => {
      const { status } = response
      if (status === 200) {
        populateWorkLocation(response.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        populateWorkLocation([])
      }
    })
  }

  const populateMemberList = members => {
    const newListOfMembers = members.map(member => {
      return { id: member.id, name: member.full_name, checked: false }
    })
    setListOfMembers(newListOfMembers)

    var checkedMembers = newListOfMembers.filter(member => {
      if (member.checked) {
        return member
      }
    })
    var assignTo = checkedMembers.map(member => {
      return member.id
    })
  }

  const populateMemberGroupList = members => {
    const newListOfMembers = members.map(member => {
      return {
        id: member.id,
        name: member.name,
        work_location: member.work_location,
        checked: false
      }
    })
    setListOfMembersGroup(newListOfMembers)

    var checkedMembers = newListOfMembers.filter(member => {
      if (member.checked) {
        return member
      }
    })
    var assignTo = checkedMembers.map(member => {
      return member.id
    })
  }

  const populateVisibility = visibilities => {
    const newListOfVisibilities = visibilities.map(visibility => {
      return {
        value: visibility.id,
        label: visibility.name,
        desc: visibility.description
      }
    })
    setListVisibility(newListOfVisibilities)
  }

  const populateWorkLocation = workLocations => {
    const newListOfWorkLocations = workLocations.map(workLocation => {
      return {
        value: workLocation.id,
        label: workLocation.name
      }
    })
    setListWorkLocation(newListOfWorkLocations)
  }

  const handleChange = fileParam => {
    const fileSizeUser = userData.file_size * 1000000
    if (fileParam.length + file.length > 5) {
      toast.error(
        <Toast message={`Error`} detailedMessage={"Can't attach file more than 5 file!"} />
      )
      return
    }

    let newData = []
    for (let i = 0; i < fileParam.length; i++) {
      if (fileParam[i].size > fileSizeUser) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`File size for ${fileParam[i].name} is more than ${fileSizeUser}KB (Size File: ${fileParam[i].size}KB)`}
          />
        )
      } else {
        newData.push(fileParam[i])
      }
    }

    if (file.length) {
      file.map(el => {
        newData.push(el)
      })
    }
    setFile(newData)
  }

  const handleOnChangeVisibility = selectedOptions => {
    setSelectedVisibility(selectedOptions)
    setValidationForm({ ...validationForm, visibility: false })
    setForm({ ...form, visibility: selectedOptions.label })
  }

  const handleOnChangeWorkLocation = selectedOptions => {
    setSelectedWorkLocation(selectedOptions)
  }

  const handleOnChangeTitle = event => {
    setValidationForm({ ...validationForm, title: false })
    setForm({ ...form, title: event.target.value })
  }

  const handleOnPlannedStartDate = date => {
    const formatedDate = format(date, 'yyyy-MM-dd HH:mm')
    setPlannedStartDate(date)
    setValidationForm({ ...validationForm, planned_start: false })
    setForm({ ...form, planned_start: formatedDate })
  }

  const handleOnDueDate = date => {
    const formatedDate = format(date, 'yyyy-MM-dd HH:mm')
    setDueDate(date)
    setValidationForm({ ...validationForm, due_date: false })
    setForm({ ...form, due_date: formatedDate })
  }

  const checkImageType = file => {
    const found = mockData.extensionImages.findIndex(
      el => `image/${el.toLowerCase()}` === file.type.toLowerCase()
    )
    return found !== -1
  }

  const checkSoundType = file => {
    const found = mockData.extensionSounds.findIndex(
      el => `audio/${el.toLowerCase()}` === file.type.toLowerCase()
    )
    return found !== -1
  }

  const getIconType = fileType => {
    if (fileType.type.includes('spreadsheetml')) return ICONS.icXls
    if (fileType.type.includes('wordprocessingml')) return ICONS.icDoc
    if (fileType.type.includes('presentationml')) return ICONS.icPpt
    if (checkImageType(fileType) || checkSoundType(fileType))
      return window.URL.createObjectURL(fileType)

    return ICONS.icDocumentActive
  }

  const handleRemoveAttachment = idx => {
    const newData = file.filter((el, elIdx) => elIdx !== idx)
    setFile(newData)
    if (document.getElementsByName('file')[0].value) {
      document.getElementsByName('file')[0].value = null
    }
  }

  const getStringHtml = html => {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  const handleSaveSubmit = action => {
    if (!formValidation(action)) {
      toast.error(<Toast message={`Error`} detailedMessage={'Please complete the form!'} />)
      return
    }

    if (getStringHtml(form.description) === '') {
      toast.error(<Toast message={`Error`} detailedMessage={'Description is required!'} />)
      return
    }

    if (action === 'DRAFT' && (selectedAssignTo.length || selectedGroup.length)) {
      dispatch(setShowPopupDelete(true))
      dispatch(
        setMessage(
          'Are you sure want to save as draft? Save as draft will delete all assign to that have been choosed.'
        )
      )
      return
    }

    let fileSend = new FormData()
    let dataSend = form
    file.map((el, index) => {
      fileSend.append('files[]', el)
    })

    if (!file.length) {
      setIsLoadingTask(true)
      dataSend = {
        ...dataSend,
        status: action
      }
      if (action === 'OPEN_TASK') {
        dataSend = {
          ...dataSend,
          assign_to_users: selectedAssignTo,
          assign_to_groups: selectedGroup
        }
      }
      post(apiUrls.TASKS_URL, dataSend).then(responsePost => {
        const statusPost = responsePost.status
        if (statusPost === 201) {
          setIsLoadingTask(false)
          toast.success(
            <Toast message={`Success`} detailedMessage={`You have successfully add new task!`} />
          )
          navigate('/DocumentLibrary/Task', {
            state: { title: 'Task', crumbs: ['Document Library', 'Task'] }
          })
        } else {
          setIsLoadingTask(false)
          errorApi(statusPost)
          return
        }
      })
      return
    }

    setIsLoadingTask(true)
    post(apiUrls.TASK_ATTACHMENT, fileSend, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(responseAttachment => {
      const statusAttachment = responseAttachment.status
      if (statusAttachment === 200) {
        dataSend = {
          ...dataSend,
          attachments: responseAttachment.data,
          status: action
        }

        if (action === 'OPEN_TASK') {
          dataSend = {
            ...dataSend,
            assign_to_users: selectedAssignTo,
            assign_to_groups: selectedGroup
          }
        }

        post(apiUrls.TASKS_URL, dataSend).then(responsePost => {
          const statusPost = responsePost.status
          if (statusPost === 201) {
            setIsLoadingTask(false)
            toast.success(
              <Toast message={`Success`} detailedMessage={`You have successfully add new task!`} />
            )
            navigate('/DocumentLibrary/Task', {
              state: { title: 'Task', crumbs: ['Document Library', 'Task'] }
            })
          } else {
            setIsLoadingTask(false)
            errorApi(statusPost)
            return
          }
        })
      } else {
        setIsLoadingTask(false)
        errorApi(statusAttachment)
        return
      }
    })
  }

  const errorApi = status => {
    switch (status) {
      case 401:
        toast.error(<Toast message={`Error`} detailedMessage={`Unauthorized user.`} />)
        break
      case 422:
        toast.error(
          <Toast message={`Error`} detailedMessage={`Failed to add new task. Invalid Data.`} />
        )
        break
      case 500:
        toast.error(<Toast message={`Server Error`} detailedMessage={`Failed to add new task.`} />)
        break
      case 408:
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Looks like the server is taking too long to respond. Please try again in while if still no update.`}
          />
        )
        break
      default:
        toast.error(<Toast message={`Server Error`} detailedMessage={`Error!`} />)
        break
    }
  }

  const formValidation = action => {
    let isValid = true
    const titleElement = document.getElementById('title')

    if (action === 'OPEN_TASK') {
      if (!selectedAssignTo.length && !selectedGroup.length) {
        isValid = false
        titleElement.focus()
        toast.error(<Toast message={`Error`} detailedMessage={'Please select contact/group!'} />)
      }
    }

    if (!form.due_date || !form.title || !form.planned_start || !form.visibility) {
      isValid = false
      titleElement.focus()
    }

    setValidationForm({
      due_date: form.due_date === '',
      planned_start: form.planned_start === '',
      title: form.title === '',
      visibility: form.visibility === ''
    })

    return isValid
  }

  const removeSelectedAssignTo = () => {
    setSelectedAssignTo([])
    setSelectedGroup([])
    setContinueSaveDraft(true)
  }

  const handleSetActiveTab = choice => {
    setSearchMemberGroupQuery('')
    setSearchMemberQuery('')
    setActiveTab(choice)
  }

  const UserList = () => {
    return (
      <div className="flex-row rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
        <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
          <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
            Available Member
          </div>
          {totalMemberChecked !== 0 ? (
            <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
              {totalMemberChecked} Member Selected
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-row px-4 py-3">
          <div className={`flex items-center relative mb-4 overflow-hidden`}>
            <input
              id="search-member"
              type="text"
              placeholder="Search here"
              className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
              value={searchMemberQuery}
              onChange={event => {
                setSearchMemberQuery(event.target.value)
              }}
            />
            <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" />
          </div>
          <div className="flex-row h-[200px] overflow-y-auto scrollbar">
            {listOfMembers
              .filter(member => {
                return member.name
                  .toLowerCase()
                  .match(new RegExp(searchMemberQuery.toLowerCase(), ''))
              })
              .map((member, index) => (
                <div key={member.id} className="flex items-center justify-between mb-4">
                  <div className="flex flex-1 text-[12px] items-center gap-3">
                    <img src={ICONS.icAdminActive} className="w-6 h-6 rounded-full" />
                    {member.name}
                  </div>
                  <input
                    type="checkbox"
                    className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                    checked={member.checked}
                    onChange={event => handleOnChangeCheckMember(member.id, event)}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }

  const GroupList = () => {
    return (
      <div className="flex-row rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
        <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
          <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
            Available Group
          </div>
          {totalMemberGroupChecked !== 0 ? (
            <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
              {totalMemberGroupChecked} Member Selected
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="flex-row px-4 py-3">
          <div className={`flex items-center relative mb-2 overflow-hidden`}>
            <input
              id="search-member-group"
              type="text"
              placeholder="Search here"
              className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
              value={searchMemberGroupQuery}
              onChange={event => {
                setSearchMemberGroupQuery(event.target.value)
              }}
            />
            <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" />
          </div>
          <div className="mb-4">
            <div className="font-poppins font-semibold text-[12px]">Work Location</div>
            <Select
              components={{ DropdownIndicator }}
              name="Work location"
              options={listWorkLocation}
              defaultValue={selectedWorkLocation}
              className={`mb-1 input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
              placeholder="Select work location"
              isMulti={false}
              isClearable={true}
              onChange={data => handleOnChangeWorkLocation(data)}
              styles={{
                option: (provided, state) => ({
                  ...provided
                }),
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  color: '#C800A5',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({}),
                valueContainer: provided => ({
                  ...provided
                })
              }}
            />
          </div>
          <div className="flex-row h-[135px] overflow-y-auto scrollbar">
            {listOfMembersGroup
              .filter(member => {
                return selectedWorkLocation?.label
                  ? member.name
                      .toLowerCase()
                      .match(new RegExp(searchMemberGroupQuery.toLowerCase(), '')) &&
                      (member?.work_location
                        ? member.work_location.match(selectedWorkLocation.label)
                        : null)
                  : member.name
                      .toLowerCase()
                      .match(new RegExp(searchMemberGroupQuery.toLowerCase(), ''))
              })
              .map((member, index) => (
                <div key={member.id} className="flex items-center justify-between mb-4">
                  <div className="flex flex-1 text-[12px] items-center gap-3">
                    <img src={ICONS.icAdminActive} className="w-6 h-6 rounded-full" />
                    {member.name}
                  </div>
                  <input
                    type="checkbox"
                    className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                    checked={member.checked}
                    onChange={event => handleOnChangeCheckMemberGroup(member.id, event)}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="p-[24px]">
      <div className="flex flex-col md:flex-row justify-evenly gap-6">
        <div className="bg-white w-full rounded-lg p-[24px]">
          <p className="text-[16px] text-[#C800A5] font-semibold mb-[24px]">Task Info</p>
          <div>
            <div className="font-poppins font-semibold text-[12px] mb-2">Title</div>
            <input
              id="title"
              type="text"
              name="Code Form"
              className={`${
                validationForm.title ? 'empty-field ' : ''
              }input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
              placeholder="Enter title"
              onChange={handleOnChangeTitle}
            />
          </div>
          <div>
            <div className="font-poppins font-semibold text-[12px] mb-2">Planned Start</div>
            <div className="flex relative">
              <DatePicker
                id="planned_start"
                onChange={handleOnPlannedStartDate}
                selected={plannedStartDate}
                timeInputLabel="Time:"
                dateFormat="dd-MMM-yyyy HH:mm"
                showTimeInput
                name="Planned start"
                minDate={new Date()}
                className={`${
                  validationForm.planned_start ? 'empty-field ' : ''
                }input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                autoComplete="off"
                placeholderText="Select Planned Start"
              />
              <img
                src={ICONS.icCalendar}
                className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-poppins font-semibold text-[12px] mb-2">Due Date</div>
            <div className="flex relative">
              <DatePicker
                id="due_date"
                onChange={handleOnDueDate}
                selected={dueDate}
                timeInputLabel="Time:"
                dateFormat="dd-MMM-yyyy HH:mm"
                showTimeInput
                name="Due date"
                className={`${
                  validationForm.due_date ? 'empty-field ' : ''
                }input-text text-[12px] px-[16px] py-[12px] h-[40px] border-2 rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                autoComplete="off"
                placeholderText="Select Due Date"
              />
              <img
                src={ICONS.icCalendar}
                className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
              />
            </div>
          </div>
          <div>
            <div className="font-poppins font-semibold text-[12px] mb-2">Visibility</div>
            <Select
              id="visibility"
              components={{ DropdownIndicator }}
              name="Visibility"
              options={listVisibility}
              className={`${
                validationForm.visibility ? 'empty-field ' : ''
              }mb-1 input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
              placeholder="Select visibility"
              isMulti={false}
              onChange={data => handleOnChangeVisibility(data)}
              styles={{
                option: (provided, state) => ({
                  ...provided
                }),
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  color: '#C800A5',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({}),
                valueContainer: provided => ({
                  ...provided
                })
              }}
            />
            <div>
              {selectedVisibility?.desc ? (
                <span className="p-1 rounded-lg w-auto font-poppins font-semibold bg-opacity-[0.16] text-[10px] mb-2 bg-[#C800A5] text-[#C800A5]">
                  {selectedVisibility?.desc}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="bg-white w-full rounded-lg p-[24px]">
          <div className="flex mb-3 overflow-auto">
            <div className="font-poppins font-semibold text-[16px] text-[#C800A5] mr-[36px] self-center">
              Assign to
            </div>
            <div className="flex">
              <div
                className={`w-[140px] flex bg-${
                  activeTab === 'users' ? 'gradient-to-r from-[#9E7CE3] to-[#6546C3]' : '[#F2F5FC]'
                } rounded-md mr-[16px] p-[12px] cursor-pointer`}
                onClick={() => handleSetActiveTab('users')}
              >
                <div
                  className={`w-[4px] h-[27px] bg-${
                    activeTab === 'users' ? 'white' : '[#6546C3]'
                  } rounded-lg mr-[12px]`}
                ></div>
                <p
                  className={`text-[12px] font-semibold text-${
                    activeTab === 'users' ? 'white' : '[#6546C3]'
                  } self-center`}
                >
                  Contact
                </p>
              </div>
              <div
                className={`w-[140px] flex bg-${
                  activeTab === 'groups' ? 'gradient-to-r from-[#9E7CE3] to-[#6546C3]' : '[#F2F5FC]'
                } rounded-md p-[12px] cursor-pointer`}
                onClick={() => handleSetActiveTab('groups')}
              >
                <div
                  className={`w-[4px] h-[27px] bg-${
                    activeTab === 'groups' ? 'white' : '[#6546C3]'
                  } rounded-lg mr-[12px]`}
                ></div>
                <p
                  className={`text-[12px] font-semibold text-${
                    activeTab === 'groups' ? 'white' : '[#6546C3]'
                  } self-center`}
                >
                  Groups
                </p>
              </div>
            </div>
          </div>
          {activeTab === 'users' ? <UserList /> : <GroupList />}
        </div>
      </div>
      <div className="bg-white mt-[24px] p-[24px] rounded-lg overflow-auto">
        <div className="flex justify-between mb-[16px]">
          <p className="text-[12px] font-semibold">Attachment</p>
          <p className="text-[12px] font-poppins italic text-[#C800A5]">
            Upload document, image, or audio up to {userData?.file_size}mb
          </p>
        </div>
        <FileUploader
          handleChange={handleChange}
          name="file"
          classes="bg-[#EBEDF8] text-[#6546C3] w-full mb-4"
          multiple={true}
          types={mockData.fileTypes}
        />
        {file?.length ? (
          <div className="mt-4 border-t-2 border-b-[#DEDEDE] pt-[12px]">
            <p>Attachment: </p>
            {file.map((el, idx) => (
              <div key={idx} className="border-b-2 border-b-[#DEDEDE]">
                <div className="flex justify-between w-[400px] pr-5 xl:w-full">
                  <div className="flex">
                    {!checkSoundType(el) ? (
                      <div className="m-4 w-[150px] h-[150px] bg-[#EBEDF8] rounded-lg p-1 flex items-center justify-center">
                        <div className="flex justify-center">
                          <img src={getIconType(el)} className="w-[70px] h-[70px] " />
                        </div>
                      </div>
                    ) : (
                      <ReactAudioPlayer
                        className="m-4 xl:w-[750px]"
                        src={getIconType(el)}
                        controls
                      />
                    )}
                    {checkSoundType(el) ? (
                      ''
                    ) : (
                      <div className="flex justify-center items-center">
                        <div>
                          <p className="text-[12px] text-[#232323]">{el.name}</p>
                          <p className="text-[12px] text-[#AAAAAA]">{el.size / 1000}Kb</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      src={ICONS.icTrashNoBg}
                      className="w-[20px] h-[20px] rounded-full bg-white shadow-md shadow-[#A3A375] cursor-pointer"
                      onClick={() => handleRemoveAttachment(idx)}
                    />
                  </div>
                </div>
                {!checkSoundType(el) ? (
                  ''
                ) : (
                  <div className="px-4 pb-4">
                    <p className="text-[12px] text-[#232323]">{el.name}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-4 border-t-2 border-b-[#DEDEDE] pt-[36px] flex justify-center">
            <p className="text-[#AAAAAA] text-[12px]">"No attachment"</p>
          </div>
        )}
      </div>
      <div className="bg-white mt-[24px] p-[24px] rounded-lg overflow-auto">
        <p className="text-[12px] font-semibold mb-[8px]">Description</p>
        <Editor
          toolbar={{
            options: ['inline', 'list', 'history']
          }}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={setEditorState}
        />
      </div>
      <div className="mt-[60px] flex flex-col md:flex-row justify-between gap-1">
        <button
          className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
          onClick={() => handleSaveSubmit('DRAFT')}
        >
          {isLoadingTask ? 'Loading...' : 'Save Draft'}
        </button>
        <div className="flex justify-center">
          <button
            className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md mr-4"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            onClick={() => handleSaveSubmit('OPEN_TASK')}
          >
            {isLoadingTask ? 'Loading...' : 'Submit'}
          </button>
        </div>
      </div>
      <DeletePopup setContinueDelete={removeSelectedAssignTo} buttonNext={'Continue'} />
    </div>
  )
}

export default AddTaskPage
