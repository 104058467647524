import { createContext } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { selectSites } from '../../redux/slices/micrositeSlice'

import { Header, SideNav } from './components'

export const PageContext = createContext()

export default function Site() {
  const { microsite, menu, subMenu } = useParams()
  const { state } = useLocation()
  const site = useSelector(selectSites).find(
    s => s.route_name.toLowerCase() === microsite.toLowerCase()
  )
  const currentMenu = site.menus.find(m => m.route_name === menu)
  const page = !subMenu ? currentMenu : currentMenu.children.find(sm => sm.route_name === subMenu)

  const crumbs =
    state?.crumbs ||
    (!subMenu
      ? [currentMenu?.name]
      : [currentMenu?.name, currentMenu.children.find(sm => sm.route_name === subMenu)?.name])

  return (
    <>
      <div className="flex w-full h-full bg-[#F2F5FC] p-2 sm:p-4 font-poppins">
        <SideNav />
        <div className="flex-1 w-full overflow-auto sm:ml-6">
          <div>
            <Header page={page} crumbs={crumbs} />
          </div>
          <div className="flex-auto min-h-screen overflow-hidden mt-4">
            {site.menus.length ? (
              <PageContext.Provider
                value={{ page, topColor: site?.top_color, bottomColor: site?.bottom_color }}
              >
                <Outlet />
              </PageContext.Provider>
            ) : (
              <div className="flex items-center justify-center h-60">No Menu Created Yet</div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme="colored"
      />
    </>
  )
}
