const areas = [
  {
    areaId: 1,
    areaName: 'Montana',
    isActive: true
  },
  {
    areaId: 2,
    areaName: 'John Wall',
    isActive: true
  },
  {
    areaId: 3,
    areaName: 'Northridge',
    isActive: false
  },
  {
    areaId: 4,
    areaName: 'American Ash',
    isActive: false
  },
  {
    areaId: 5,
    areaName: 'Towne',
    isActive: false
  },
  {
    areaId: 6,
    areaName: 'School',
    isActive: true
  },
  {
    areaId: 7,
    areaName: 'Bowman',
    isActive: true
  },
  {
    areaId: 8,
    areaName: 'Buena Vista',
    isActive: false
  },
  {
    areaId: 9,
    areaName: 'Sherman',
    isActive: false
  },
  {
    areaId: 10,
    areaName: 'Sullivan',
    isActive: false
  },
  {
    areaId: 11,
    areaName: 'Longview',
    isActive: true
  },
  {
    areaId: 12,
    areaName: 'Cody',
    isActive: true
  },
  {
    areaId: 13,
    areaName: 'Birchwood',
    isActive: false
  }
]

const extensionSounds = ['wav', 'mp3', 'mp4']
const extensionImages = ['jpeg', 'png', 'jpg']
const fileTypes = [
  'jpg',
  'png',
  'jpeg',
  'wav',
  'mp3',
  'mp4',
  'csv',
  'pdf',
  'doc',
  'docx',
  'txt',
  'ppt',
  'pptx',
  'mp4',
  'xls',
  'xlsx',
  'zip',
  'ogg',
  'mkv'
]

export default {
  areas,
  extensionImages,
  extensionSounds,
  fileTypes
}
