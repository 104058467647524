import { TextH6 } from '../../../components'
import { ICONS } from '../../../constants'

export default function Info({ data: { logo_url, name, description, is_active } }) {
  return (
    <div className="bg-white rounded-2xl p-2 sm:p-6 w-full my-6">
      <div className="flex lg:flex-row flex-col">
        <div className="md:w-60 w-full lg:my-0 my-2">
          <img
            src={logo_url || ICONS.icDefaultImage}
            className="lg:px-0 max-w-[100px] max-h-[100px]"
            alt={logo_url ? name : 'placeholder logo'}
          />
        </div>
        <div className="lg:w-60 w-full lg:my-0 my-2">
          <TextH6 text="Title" />
          <div className="text-xs mt-2">{name || '-'}</div>
        </div>
        <div className="lg:grow lg:w-80 w-full lg:my-0 my-2">
          <TextH6 text="Description" />
          <div
            className="text-xs mt-2 max-w-[500px]"
            dangerouslySetInnerHTML={{ __html: description || '-' }}
          />
        </div>
        <div className="lg:my-0 my-2">
          <div
            className={`flex justify-center items-center ${
              is_active ? 'bg-green-100' : 'bg-red-100'
            } rounded-lg p-2`}
          >
            <span
              className={`${is_active ? 'text-green-600' : 'text-red-600'} font-semibold text-xs`}
            >
              <div>{is_active ? 'Active' : 'Inactive'}</div>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
