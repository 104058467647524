import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { selectToken, setToken } from '../../redux/slices/authSlice'
import { selectUserData } from '../../redux/slices/userSlice'

const RequireAuthentication = () => {
  const location = useLocation()
  const token = useSelector(selectToken)
  const userData = useSelector(selectUserData)

  return token ? <Outlet /> : <Navigate to="/Login" state={{ from: location }} />
}

export default RequireAuthentication
