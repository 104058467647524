import React from 'react'
import Select from 'react-select'
import { ICONS } from '../../../../../../constants'

const Field = ({ field }) => {
  const fieldTitle = field?.field_title
  const fieldType = field?.field_type.value
  const fieldValue = field?.value
  const fieldOptions = field?.options
  const fieldId = field?.field_id
  const fieldAttachments = field?.attachment_value
  const fieldKeyboardType = field?.keyboard_type

  const renderField = (
    fieldId,
    fieldType,
    fieldTitle,
    fieldValue,
    fieldOptions,
    fieldAttachments,
    fieldKeyboardType
  ) => {
    switch (fieldType) {
      case 'TEXT_FIELD':
        return <TextField title={fieldTitle} value={fieldValue} type={fieldKeyboardType} />
        break
      case 'TEXT_AREA':
        return <TextArea title={fieldTitle} value={fieldValue} />
        break
      case 'CHECKBOX':
        return <Checkbox title={fieldTitle} value={fieldValue} options={fieldOptions} />
        break
      case 'RADIO_BUTTON':
        return (
          <RadioButton id={fieldId} title={fieldTitle} value={fieldValue} options={fieldOptions} />
        )
        break
      case 'DROPDOWN':
        return (
          <Dropdown id={fieldId} title={fieldTitle} value={fieldValue} options={fieldOptions} />
        )
        break
      case 'DROPDOWN_MASTER_DATA':
        return (
          <Dropdown id={fieldId} title={fieldTitle} value={fieldValue} options={fieldOptions} />
        )
        break
      case 'DATE_PICKER':
        return <DatePicker title={fieldTitle} value={fieldValue} />
        break
      case 'SIGNATURE':
        return <Signature title={fieldTitle} value={fieldValue} />
        break
      case 'CHECKBOX_ATTACHMENT':
        return (
          <CheckboxWithAttachment
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            attachments={fieldAttachments}
          />
        )
        break
      case 'RADIO_BUTTON_ATTACHMENT':
        return (
          <RadioButtonWithAttachment
            id={fieldId}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            attachments={fieldAttachments}
          />
        )
        break
      default:
        return <div>{fieldTitle}</div>
        break
    }
  }

  if (field?.additional_option.length > 0) {
    return (
      <div>
        <span className="font-semibold text-[14px]">{fieldTitle}</span>
        {field?.additional_option.map((field, index) => (
          <div key={index}>
            {renderField(
              field.field_id,
              fieldType,
              field.title,
              field.value,
              field.options,
              field.attachment_value,
              fieldKeyboardType
            )}
          </div>
        ))}
      </div>
    )
  } else {
    return renderField(
      fieldId,
      fieldType,
      fieldTitle,
      fieldValue,
      fieldOptions,
      fieldAttachments,
      fieldKeyboardType
    )
  }
}

export default Field

const TextField = ({ title, value, type }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <input
        type={type?.value === 'NUMERIC' ? 'number' : 'text'}
        className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
        placeholder={`Enter ${title}`}
        defaultValue={value}
        disabled
      />
    </div>
  )
}

const TextArea = ({ title, value }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <textarea
        className={`text-[12px] px-[16px] py-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
        defaultValue={value}
        disabled
      ></textarea>
    </div>
  )
}

const Checkbox = ({ title, options }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex flex-col gap-1">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <input type="checkbox" defaultChecked={option.value} disabled />
            <div className="text-[12px]">{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const RadioButton = ({ id, title, options, value }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex flex-col gap-1">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <input type="radio" name={id} disabled defaultChecked={option.value === value} />
            <div className="text-[12px]">{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const Dropdown = ({ title, options, value }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <Select
        isSearchable={false}
        options={options}
        name={title}
        isDisabled
        defaultValue={{
          label: value.label ? value.label : value,
          value: value.value ? value.value : value
        }}
        className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
        placeholder={`Select ${title}`}
        styles={{
          control: provided => ({
            ...provided,
            borderColor: 'none',
            ':focus': { borderColor: '#6546C3' },
            ':active': { borderColor: '#6546C3' },
            ':hover': { borderColor: '#6546C3' },
            paddingLeft: 4,
            boxShadow: 'none'
          }),
          dropdownIndicator: base => ({
            ...base,
            color: '#C800A5',
            marginRight: '4px',
            ':hover': { color: '#6546C3' }
          }),
          indicatorSeparator: () => ({})
        }}
      />
    </div>
  )
}

const DatePicker = ({ title, value }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex relative">
        <input
          type="text"
          defaultValue={value}
          disabled
          className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
        />
        <img
          src={ICONS.icCalendar}
          className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
          alt=""
        />
      </div>
    </div>
  )
}
const Signature = ({ title, value }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex min-h-[100px] rounded-2xl border-solid border-2 bg-white items-center justify-center">
        <img src={value} className="flex" />
      </div>
    </div>
  )
}

const CheckboxWithAttachment = ({ title, options, attachments }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex flex-col gap-1">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <input type="checkbox" defaultChecked={option.value} disabled />
            <div className="text-[12px]">{option.label}</div>
          </div>
        ))}
      </div>
      <div className="flex gap-4">
        {attachments?.map((attachment, index) => (
          <img key={index} src={attachment.url} className="w-20 h-20" />
        ))}
      </div>
    </div>
  )
}

const RadioButtonWithAttachment = ({ id, title, options, value, attachments }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex flex-col gap-1">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <input type="radio" name={id} disabled defaultChecked={option.value === value} />
            <div className="text-[12px]">{option.label}</div>
          </div>
        ))}
      </div>
      <div className="flex gap-4">
        {attachments?.map((attachment, index) => (
          <img key={index} src={attachment.url} className="w-20 h-20" />
        ))}
      </div>
    </div>
  )
}
