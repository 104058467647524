import { format } from 'date-fns'
import React, { useState, useRef, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { ICONS, mockData } from '../../../../../../constants'
import { Toast } from '../../../../../../components'
import { apiUrls } from '../../../../../../constants'
import { get } from '../../../../../../api/base'

const Field = ({
  field,
  sectionId,
  onChange,
  onChangeCheck,
  onChangeRadioButton,
  addAttachment,
  onRemoveFile
}) => {
  const fieldTitle = field?.field_title
  const fieldType = field?.field_type.value
  const fieldValue = field?.value
  const fieldOptions = field?.options
  const fieldId = field?.field_id
  const fieldAttachments = field?.attachment_value
  const fieldKeyboardType = field?.keyboard_type
  const inputRef = useRef(null)
  const [masterDataList, setMasterDataList] = useState([])

  const fetchDropdownMasterData = async (masterDataId, parent_id) => {
    let res = null
    const query = {
      query: {
        parent_id: parent_id
      }
    }

    if (parent_id) res = await get(`${apiUrls.MASTER_DATA}/${masterDataId}/details`, query)
    else res = await get(`${apiUrls.MASTER_DATA}/${masterDataId}/details`)

    if (res.ok) {
      const dropdownList = res.data.map(v => {
        return { ...v, label: v.name, value: v.name }
      })
      setMasterDataList(dropdownList)
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={`${res.data.error.message}`} />)
      return []
    }
  }

  useEffect(() => {
    if (field.field_type.value === 'DROPDOWN_MASTER_DATA') {
      fetchDropdownMasterData(field.master_data.sourceMasterDataId, field.master_data.contentId)
    }
  }, [field.master_data.contentId])

  const onChangeData = (event, index) => {
    onChange(event, index)
  }

  const handleClick = () => {
    inputRef.current.click()
  }

  const onChangeSignature = (e, index) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    const arrayFileName = file.name.split('.')

    if (
      mockData.extensionImages.includes(arrayFileName[arrayFileName.length - 1]) &&
      file.size / 10000000 < 10
    ) {
      reader.addEventListener('load', () => {
        const base64Signature = reader.result
        onChange(base64Signature, index)
      })

      reader.readAsDataURL(file)
    } else {
      toast.error(
        <Toast message={`Error`} detailedMessage={'file must be image and maximum size 10 mb'} />
      )
    }
  }

  const onChangeAttachment = (e, index) => {
    const file = e.target.files[0]
    const arrayFileName = file.name.split('.')

    if (
      mockData.fileTypes.includes(arrayFileName[arrayFileName.length - 1]) &&
      file.size / 5000000 < 10
    ) {
      addAttachment(file, index)
    } else {
      toast.error(
        <Toast message={`Error`} detailedMessage={'file must be image and maximum size 5 mb'} />
      )
    }
  }

  const onChangeCheckBox = (event, label, index) => {
    onChangeCheck(event, label, index)
  }

  const onChangeRadio = (event, label, index) => {
    onChangeRadioButton(event, label, index)
  }

  const onDeleteFile = (indexFile, additionalIndex) => {
    onRemoveFile(indexFile, additionalIndex)
  }

  const renderField = (
    fieldId,
    sectionId,
    fieldType,
    fieldTitle,
    fieldValue,
    fieldOptions,
    fieldAttachments,
    index,
    fieldKeyboardType
  ) => {
    switch (fieldType) {
      case 'TEXT_FIELD':
        return (
          <TextField
            title={fieldTitle}
            value={fieldValue}
            type={fieldKeyboardType}
            onChangeValue={event => onChangeData(event, index)}
          />
        )
        break
      case 'TEXT_AREA':
        return (
          <TextArea
            title={fieldTitle}
            value={fieldValue}
            onChangeValue={event => onChangeData(event, index)}
          />
        )
        break
      case 'CHECKBOX':
        return (
          <Checkbox
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            onChange={(event, label) => onChangeCheckBox(event, label, index)}
          />
        )
        break
      case 'RADIO_BUTTON':
        return (
          <RadioButton
            id={fieldId}
            sectionId={sectionId}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            onChange={(event, label) => onChangeRadio(event, label, index)}
          />
        )
        break
      case 'DROPDOWN':
        return (
          <Dropdown
            id={fieldId}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            onChange={event => onChangeData(event)}
          />
        )
        break
      case 'DROPDOWN_MASTER_DATA':
        return (
          <DropdownMasterData
            id={fieldId}
            title={fieldTitle}
            value={fieldValue}
            options={masterDataList}
            onChange={event => onChangeData(event)}
          />
        )
        break
      case 'DATE_PICKER':
        return (
          <DatePicker
            title={fieldTitle}
            value={fieldValue}
            onChange={event => onChangeData(event)}
          />
        )
        break
      case 'SIGNATURE':
        return (
          <Signature
            title={fieldTitle}
            value={fieldValue}
            handleClick={handleClick}
            inputRef={inputRef}
            handleChangeSignature={e => onChangeSignature(e, index)}
          />
        )
        break
      case 'CHECKBOX_ATTACHMENT':
        return (
          <CheckboxWithAttachment
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            attachments={fieldAttachments}
            handleClick={handleClick}
            inputRef={inputRef}
            onChange={(event, label) => onChangeCheckBox(event, label, index)}
            handleChangeAttachment={e => onChangeAttachment(e, index)}
            handleRemoveFile={indexFile => onDeleteFile(indexFile, index)}
          />
        )
        break
      case 'RADIO_BUTTON_ATTACHMENT':
        return (
          <RadioButtonWithAttachment
            id={fieldId}
            title={fieldTitle}
            value={fieldValue}
            options={fieldOptions}
            attachments={fieldAttachments}
            handleClick={handleClick}
            inputRef={inputRef}
            onChange={(event, label) => onChangeRadio(event, label, index)}
            handleChangeAttachment={e => onChangeAttachment(e, index)}
            handleRemoveFile={indexFile => onDeleteFile(indexFile, index)}
          />
        )
        break
      default:
        return <div>{fieldTitle}</div>
        break
    }
  }

  if (field?.additional_option.length > 0) {
    return (
      <div>
        <span className="font-semibold text-[14px]">{fieldTitle}</span>
        {field?.additional_option.map((field, index) => (
          <div key={index}>
            {renderField(
              field.field_id,
              sectionId,
              fieldType,
              field.title,
              field.value,
              field.options,
              field.attachment_value,
              index,
              fieldKeyboardType
            )}
          </div>
        ))}
      </div>
    )
  } else {
    return renderField(
      fieldId,
      sectionId,
      fieldType,
      fieldTitle,
      fieldValue,
      fieldOptions,
      fieldAttachments,
      null,
      fieldKeyboardType
    )
  }
}

export default Field

const TextField = ({ title, value, type, onChangeValue }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <input
        type={type.value === 'NUMERIC' ? 'number' : 'text'}
        className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
        placeholder={`Enter ${title}`}
        defaultValue={value}
        onChange={event => onChangeValue(event)}
      />
    </div>
  )
}

const TextArea = ({ title, value, onChangeValue }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <textarea
        className={`text-[12px] px-[16px] py-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
        defaultValue={value}
        onChange={event => onChangeValue(event)}
      ></textarea>
    </div>
  )
}

const Checkbox = ({ title, options, value, onChange }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex flex-col gap-1">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <input
              type="checkbox"
              defaultChecked={option.value}
              onChange={event => onChange(event, option.label)}
            />
            <div className="text-[12px]">{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const RadioButton = ({ id, sectionId, title, options, value, onChange }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex flex-col gap-1">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <input
              type="radio"
              name={`${sectionId}-${id}`}
              defaultChecked={option.value === value}
              onChange={event => onChange(event, option.value)}
            />
            <div className="text-[12px]">{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const Dropdown = ({ title, options, value, onChange }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <Select
        isSearchable={false}
        options={options}
        name={title}
        defaultValue={{
          label: value.label ? value.label : value,
          value: value.value ? value.value : value
        }}
        className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
        placeholder={`Select ${title}`}
        onChange={selectedOption => onChange(selectedOption)}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: 'none',
            ':focus': { borderColor: '#6546C3' },
            ':active': { borderColor: '#6546C3' },
            ':hover': { borderColor: '#6546C3' },
            paddingLeft: 4,
            boxShadow: 'none'
          }),
          dropdownIndicator: base => ({
            ...base,
            color: '#C800A5',
            marginRight: '4px',
            ':hover': { color: '#6546C3' }
          }),
          indicatorSeparator: () => ({})
        }}
      />
    </div>
  )
}

const DropdownMasterData = ({ title, options, value, onChange }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <Select
        isSearchable={false}
        options={options}
        name={title}
        defaultValue={value}
        className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
        placeholder={`Select ${title}`}
        onChange={selectedOption => onChange(selectedOption)}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: 'none',
            ':focus': { borderColor: '#6546C3' },
            ':active': { borderColor: '#6546C3' },
            ':hover': { borderColor: '#6546C3' },
            paddingLeft: 4,
            boxShadow: 'none'
          }),
          dropdownIndicator: base => ({
            ...base,
            color: '#C800A5',
            marginRight: '4px',
            ':hover': { color: '#6546C3' }
          }),
          indicatorSeparator: () => ({})
        }}
      />
    </div>
  )
}

const DatePicker = ({ title, value, onChange }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex relative">
        <input
          type="text"
          defaultValue={value}
          className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
          onChange={event => onChange(event)}
        />
        <img
          src={ICONS.icCalendar}
          className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
        />
      </div>
    </div>
  )
}

const Signature = ({ title, value, handleClick, inputRef, handleChangeSignature }) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex min-h-[100px] rounded-2xl border-solid border-2 bg-white items-center justify-center">
        <input
          className="absolute w-full h-16 invisible cursor-pointer"
          ref={inputRef}
          type="file"
          onChange={e => handleChangeSignature(e)}
        />
        {value ? (
          <img
            src={value}
            className="flex cursor-pointer w-96 h-96 my-4"
            onClick={handleClick}
            alt={value}
          />
        ) : (
          <div
            onClick={handleClick}
            className="flex items-center justify-center bg-white rounded-2xl h-[212px] gap-3 cursor-pointer"
          >
            <img src={ICONS.icPen} className="w-6 h-6" />
            <span className="font-semibold text-[12px]">Sign Here</span>
          </div>
        )}
      </div>
    </div>
  )
}

const CheckboxWithAttachment = ({
  title,
  options,
  value,
  attachments,
  onChange,
  inputRef,
  handleClick,
  handleChangeAttachment,
  handleRemoveFile
}) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex flex-col gap-1">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <input
              type="checkbox"
              defaultChecked={option.value}
              onChange={event => onChange(event, option.label)}
            />
            <div className="text-[12px]">{option.label}</div>
          </div>
        ))}
      </div>
      <div className="flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px] border-[#6546C3]">
        <button
          onClick={handleClick}
          className="px-4 py-3 text-[12px] rounded-lg bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold"
        >
          Select Files...
        </button>
        <div className="flex-1 text-center text-[12px] text-[#6546C3]">
          Drag file here to upload
        </div>
      </div>
      <input
        className="absolute w-full h-16 invisible cursor-pointer"
        ref={inputRef}
        type="file"
        multiple
        onChange={e => handleChangeAttachment(e)}
      />
      <div className="flex gap-4 mt-4">
        {attachments?.map((attachment, index) => (
          <div className="indicator" key={index}>
            <img
              src={ICONS.icTrash}
              className="indicator-item w-7 h-7 cursor-pointer"
              alt={`delete attachment ${index}`}
              onClick={() => handleRemoveFile(index)}
            />
            <img
              src={attachment.url ? attachment.url : URL.createObjectURL(attachment)}
              className="w-20 h-20 min-w-[80px] rounded-md"
              alt={`attachment ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const RadioButtonWithAttachment = ({
  id,
  title,
  options,
  value,
  attachments,
  handleClick,
  inputRef,
  onChange,
  handleChangeAttachment,
  handleRemoveFile
}) => {
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{title}</div>
      <div className="flex flex-col gap-1">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <input
              type="radio"
              name={id}
              defaultChecked={option.value === value}
              onChange={event => onChange(event, option.label)}
            />
            <div className="text-[12px]">{option.label}</div>
          </div>
        ))}
      </div>
      <div className="flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px] border-[#6546C3]">
        <button
          onClick={handleClick}
          className="px-4 py-3 text-[12px] rounded-lg bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold"
        >
          Select Files...
        </button>
        <div className="flex-1 text-center text-[12px] text-[#6546C3]">
          Drag file here to upload
        </div>
      </div>
      <input
        className="absolute w-full h-16 invisible cursor-pointer"
        ref={inputRef}
        type="file"
        multiple
        onChange={e => handleChangeAttachment(e)}
      />
      <div className="flex gap-4 mt-4">
        {attachments?.map((attachment, index) => (
          <div className="indicator" key={index}>
            <img
              src={ICONS.icTrash}
              className="indicator-item w-7 h-7 cursor-pointer"
              alt={`delete attachment ${index}`}
              onClick={() => handleRemoveFile(index)}
            />
            <img
              src={attachment.url ? attachment.url : URL.createObjectURL(attachment)}
              className="w-20 h-20 min-w-[80px] rounded-md"
              alt={`attachment ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
