import ImageLeavePage from '../../../assets/images/confirmation.png'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setHandleLeavePage, setShowPopupLeavePage } from '../../../redux/slices/popupSlice'

const LeavePagePopup = ({ onLeavePage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const show = useSelector(state => state.popup.showPopupLeavePage)
  const leavePage = useSelector(state => state.popup.handleLeavePage)

  function handleLeavePage() {
    dispatch(setShowPopupLeavePage(false))
    if (leavePage) {
      navigate('/' + leavePage.path, { state: leavePage.state })
      dispatch(setHandleLeavePage(null))
    } else {
      if (onLeavePage) {
        onLeavePage()
      }
    }
  }

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal bg-[#232323]/[0.5] overflow-y-auto py-6">
        <div
          className="modal-box bg-white relative w-full max-h-max max-w-max py-[60px] px-0"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center min-w-[21.25rem]">
            <img src={ImageLeavePage} className="w-[120px] h-[120px] mb-9" alt="leave page icon" />
            <span className="font-semibold text-lg mb-1">Leave This Page</span>
            <span className="text-xs text-[#AAAAAA]">Changes that you made may not be saved</span>
          </div>
          <div className="flex justify-center gap-4 mt-9">
            <button
              onClick={() => handleLeavePage()}
              className="py-3 px-8 border-[2px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Leave Page
            </button>
            <button
              onClick={() => dispatch(setShowPopupLeavePage(false))}
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            >
              Stay Page
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeavePagePopup
