import { get } from '../../../../../../../api/base'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { apiUrls, ICONS } from '../../../../../../../constants'
import { Toast } from '../../../../../../../components'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

const Field = ({ fieldData }) => {
  const fieldType = fieldData.field_type.value
  const fieldTitle = fieldData.field_title
  const fieldOptions = fieldData.options
  const [masterDataList, setMasterDataList] = useState([])

  const fetchDropdownMasterData = async masterDataId => {
    const res = await get(`${apiUrls.MASTER_DATA}/${masterDataId}/details`)

    if (res.ok) {
      const dropdownList = res.data.map(v => {
        return { ...v, label: v.name, value: v.id }
      })
      setMasterDataList(dropdownList)
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={`${res.data.error.message}`} />)
      return []
    }
  }

  useEffect(() => {
    if (fieldData.field_type.value === 'DROPDOWN_MASTER_DATA')
      fetchDropdownMasterData(fieldData.master_data.sourceMasterDataId)
  }, [])

  const renderField = (fieldType, fieldTitle, fieldOptions) => {
    switch (fieldType) {
      case 'TEXT_FIELD': {
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <input
              type="text"
              className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
              placeholder={`Enter ${fieldTitle}`}
            />
          </div>
        )
        break
      }
      case 'TEXT_AREA': {
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <textarea></textarea>
          </div>
        )
        break
      }
      case 'CHECKBOX': {
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex flex-col gap-1">
              {fieldOptions.map((option, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input type="checkbox" />
                  <div className="text-[12px]">{option.label}</div>
                </div>
              ))}
            </div>
          </div>
        )
        break
      }
      case 'RADIO_BUTTON': {
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex flex-col gap-1">
              {fieldOptions.map((option, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input type="radio" name={fieldTitle} />
                  <div className="text-[12px]">{option.label}</div>
                </div>
              ))}
            </div>
          </div>
        )
        break
      }
      case 'DROPDOWN': {
        const dropdownOptions = fieldData.options
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <Select
              isSearchable={false}
              options={dropdownOptions}
              name={fieldTitle}
              className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
              placeholder={`Select ${fieldTitle}`}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: 'none',
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  color: '#C800A5',
                  marginRight: '4px',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({})
              }}
            />
          </div>
        )
        break
      }
      case 'DROPDOWN_MASTER_DATA': {
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <Select
              isSearchable={false}
              options={masterDataList}
              name={fieldTitle}
              className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
              placeholder={`Select ${fieldTitle}`}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: 'none',
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  color: '#C800A5',
                  marginRight: '4px',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({})
              }}
            />
          </div>
        )
        break
      }
      case 'DATE_PICKER': {
        return <DatePickerField label={fieldTitle} />
        break
      }
      case 'ATTACHMENT': {
        return (
          <div className="flex flex-col gap-2">
            <hr />
            <div className="flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px] border-[#6546C3]">
              <button className="px-4 py-3 text-[12px] rounded-lg bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold">
                Select Files...
              </button>
              <div className="flex-1 text-center text-[12px] text-[#6546C3]">
                Drag file here to upload
              </div>
            </div>
          </div>
        )
        break
      }
      case 'SIGNATURE': {
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex items-center justify-center bg-white rounded-2xl h-[212px] gap-3">
              <img src={ICONS.icPen} className="w-6 h-6" />
              <span className="font-semibold text-[12px]">Sign Here</span>
            </div>
          </div>
        )
        break
      }
      case 'CHECKBOX_ATTACHMENT': {
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex flex-col gap-1">
              {fieldOptions.map((option, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input type="checkbox" />
                  <div className="text-[12px]">{option.label}</div>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-2">
              <hr />
              <div className="flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px] border-[#6546C3]">
                <button className="px-4 py-3 text-[12px] rounded-lg bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold">
                  Select Files...
                </button>
                <div className="flex-1 text-center text-[12px] text-[#6546C3]">
                  Drag file here to upload
                </div>
              </div>
            </div>
          </div>
        )
        break
      }
      case 'RADIO_BUTTON_ATTACHMENT': {
        return (
          <div className="flex flex-1 flex-col gap-2">
            <div className="font-semibold text-[12px]">{fieldTitle}</div>
            <div className="flex flex-col gap-1">
              {fieldOptions.map((option, index) => (
                <div key={index} className="flex items-center gap-2">
                  <input type="radio" name={fieldTitle} />
                  <div className="text-[12px]">{option.label}</div>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-2">
              <hr />
              <div className="flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px] border-[#6546C3]">
                <button className="px-4 py-3 text-[12px] rounded-lg bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold">
                  Select Files...
                </button>
                <div className="flex-1 text-center text-[12px] text-[#6546C3]">
                  Drag file here to upload
                </div>
              </div>
            </div>
          </div>
        )
        break
      }
      default:
        break
    }
  }

  if (fieldData?.additional_option.length > 0) {
    return (
      <div>
        <span className="font-semibold text-[14px]">{fieldTitle}</span>
        {fieldData?.additional_option.map((field, index) => (
          <div key={index}>{renderField(fieldType, field.title, field.options)}</div>
        ))}
      </div>
    )
  } else {
    return renderField(fieldType, fieldTitle, fieldOptions)
  }
}
export default Field

const DatePickerField = ({ label }) => {
  const [date, setDate] = useState(null)
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="font-semibold text-[12px]">{label}</div>
      <div className="flex relative">
        <DatePicker
          selected={date}
          onChange={newDate => setDate(newDate)}
          timeInputLabel="Time:"
          dateFormat="dd-MMM-yyyy HH:mm"
          showTimeInput
          name="Last submission"
          className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
        />
        <img
          src={ICONS.icCalendar}
          className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
        />
      </div>
    </div>
  )
}
