import { cloneDeep } from 'lodash'
import { Fragment, useEffect, useRef } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { post } from '../../../../../api/base'
import { Select } from '../../../../../components'
import { apiUrls, ICONS } from '../../../../../constants'
import Section from '../components/Section'
import { useContext } from 'react'
import { PageContext } from '../../..'

export default function Form({ form, companies = [], onChange, onSubmit, isSubmitting }) {
  const { topColor } = useContext(PageContext)
  const fileInputRef = useRef()
  const formMethods = useForm()
  const { control, reset, watch, setValue, handleSubmit, setFocus, formState } = formMethods

  useEffect(() => {
    reset(form)
  }, [form, formMethods])

  useEffect(() => {
    const subscription = watch(onChange)
    return () => subscription.unsubscribe()
  }, [formMethods, onChange])

  useEffect(() => {
    const { errors } = formState
    const keys = Object.keys(errors)

    if (keys.length) {
      const el = document.getElementsByName(errors[keys[0]].ref.name)[0]
      el.scrollIntoView({ behavior: 'smooth' })
      setFocus(errors[keys[0]].ref.name)
    }
  }, [setFocus, formState])

  function onInnerSectionAdd(sectionIndex) {
    const section = cloneDeep(form.sections[sectionIndex])
    section.inner_sections = [...section.inner_sections, cloneDeep(section.fields)]
    setValue(`sections.${sectionIndex}`, section)
  }

  function onInnerSectionRemove(sectionIndex, innerIndex) {
    const section = cloneDeep(form.sections[sectionIndex])
    section.inner_sections = [
      ...section.inner_sections.slice(0, innerIndex),
      ...section.inner_sections.slice(innerIndex + 1)
    ]
    setValue(`sections.${sectionIndex}`, section)
  }

  async function onAttachmentChange({ target: { files } }) {
    if (!files.length) return

    const formData = new FormData()
    for (let index = 0; index < files.length; index++) {
      formData.append('files[]', files[index])
    }
    const { ok, data } = await post(`${apiUrls.FORMS_URL}/attachments`, formData)
    fileInputRef.current.value = null
    if (!ok) return

    const { attachments } = form
    setValue('attachments', [...attachments, ...data])
  }

  function removeAttachment(index) {
    const { attachments } = form
    setValue('attachments', [...attachments.slice(0, index), ...attachments.slice(index + 1)])
  }

  return (
    <div className="text-xs">
      <div className="bg-white rounded-2xl mb-4 p-1 lg:p-2">
        <div className="md:flex flex-wrap">
          <div className="flex items-center md:shrink-0 md:basis-1/2 p-2">
            <img src={ICONS.icDocumentGreen} className="h-12 mr-4" alt="code" />
            <div>
              <div className="font-semibold">Code Form</div>
              <div>{form.code_form}</div>
            </div>
          </div>
          <div className="flex items-center md:shrink-0 md:basis-1/2 p-2">
            <img src={ICONS.icDocumentOrange} className="h-12 mr-4" alt="title" />
            <div>
              <div className="font-semibold">Title</div>
              <div>{form.title}</div>
            </div>
          </div>
          <div className="flex items-center md:shrink-0 md:basis-1/2 p-2">
            <img src={ICONS.icTag} className="h-12 mr-4" alt="title" />
            <div>
              <div className="font-semibold">Tags</div>
              <div className="flex flex-wrap">
                {!form?.tags?.length && <div>-</div>}
                {form?.tags?.map((tag, t) => (
                  <div
                    className="flex items-center rounded bg-teal-50 text-teal-500 my-2 mr-2 px-2 py-1"
                    key={t}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center md:shrink-0 md:basis-1/2 p-2">
            <div className="flex flex-1 flex-col gap-2">
              <div className="font-semibold">Company</div>
              <Controller
                name="company_id"
                control={control}
                rules={{ required: 'Please select a company first' }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <div className="relative">
                    <input name="company_id" className="absolute inset-0 opacity-0 -z-10" />
                    <Select
                      options={companies}
                      value={value}
                      onChange={onChange}
                      placeholder="Select company"
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ name }) => name}
                      isDisabled={form._isSubmitted}
                    />
                    <div className="text-red-600 mt-1">{error?.message}</div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <FormProvider {...formMethods}>
        <form className="bg-white rounded-2xl p-2 lg:p-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-3">
            {form?.sections.map((section, index) => (
              <div className="bg-[#F2F5FC] rounded-xl" key={section.section_id}>
                <Section
                  formIndex={index}
                  index={index}
                  path={`sections.${index}`}
                  sectionData={section}
                  onInnerSectionAdd={() => onInnerSectionAdd(index)}
                  onInnerSectionRemove={innerIndex => onInnerSectionRemove(index, innerIndex)}
                  formSubmitted={form._isSubmitted}
                />
              </div>
            ))}
          </div>

          {form.attachment && (
            <div className="flex flex-col gap-2 mt-4">
              <div
                className="font-semibold text-[14px]"
                style={{
                  color: topColor || '#00B1B5'
                }}
              >
                Attachment
              </div>
              <hr />
              <div
                className="relative flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px]"
                style={{
                  borderColor: topColor || '#00B1B5'
                }}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
                  multiple={true}
                  accept={['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'].join(',')}
                  onChange={onAttachmentChange}
                />
                <button
                  type="button"
                  className="px-4 py-3 text-[12px] rounded-lg text-white font-semibold"
                  style={{
                    backgroundColor: topColor || '#00B1B5'
                  }}
                >
                  Select Files...
                </button>
                <div
                  className="flex-1 text-center text-[12px]"
                  style={{
                    color: topColor || '#00B1B5'
                  }}
                >
                  Drag file here to upload
                </div>
              </div>
              {form.attachments.map((attachment, a) => (
                <Fragment key={a}>
                  {a > 0 && <hr className="my-2" />}
                  <div className="flex items-center py-2">
                    <img src={attachment.thumbnail} className="w-20 h-20 mr-2" alt="file" />
                    <div>{attachment.fileName}</div>
                    <img
                      src={ICONS.icTrash}
                      className="w-[28px] cursor-pointer ml-auto"
                      onClick={() => removeAttachment(a)}
                      alt="remove attachment"
                    />
                  </div>
                </Fragment>
              ))}
            </div>
          )}

          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="px-6 py-2 text-white font-semibold rounded-md disabled:opacity-50"
              style={{
                backgroundColor: topColor || '#00B1B5'
              }}
              disabled={isSubmitting || form._isSubmitted}
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
