import { useEffect, useState } from 'react'
import { FormInput, TextH1, TextH6, Toast } from '../../components'
import { ICONS, images } from '../../constants'
import { useDispatch } from 'react-redux'
import { setShowPopupSuccess, setMessage } from '../../redux/slices/popupSlice'
import MessageModal from './MessageModal'
import SuccessPopup from '../../components/Popup/SuccessPopup'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

const CreatePasswordPage = () => {
  const { token } = useParams()
  const baseURL = process.env.REACT_APP_BASE_URL
  const config = {
    headers: {
      'Cache-Control': 'no-store',
      Authorization: `Bearer ${token}`
    }
  }
  const axiosInstance = axios.create({ config, baseURL, timeout: 10000 })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [passVisibility, setPassVisibility] = useState('password')
  const [confirmPassVisibility, setConfirmPassVisibility] = useState('password')
  const [passIcon, setPassIcon] = useState(ICONS.icEyeClose)
  const [confirmPassIcon, setConfirmPassIcon] = useState(ICONS.icEyeClose)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [messageModalVisibility, setMessageModalVisibility] = useState('hidden')
  const [isFormError, setIsFormError] = useState(false)

  axiosInstance.interceptors.response.use(
    response => {
      const { data, status } = response
      const ok = status && status >= 200 && status < 300
      return { ok, status, data }
    },
    error => {
      const { response, config, message } = error
      const { data, status } = response
      const ok = status && status >= 200 && status < 300
      return { ok, status, data }
    }
  )

  useEffect(() => {
    fetchUser()
  }, [])

  const handlePassVisibility = () => {
    if (passVisibility === 'password') {
      setPassVisibility('text')
      setPassIcon(ICONS.icEyeOpen)
    } else {
      setPassVisibility('password')
      setPassIcon(ICONS.icEyeClose)
    }
  }

  const handleConfirmPassVisibility = () => {
    if (confirmPassVisibility === 'password') {
      setConfirmPassVisibility('text')
      setConfirmPassIcon(ICONS.icEyeOpen)
    } else {
      setConfirmPassVisibility('password')
      setConfirmPassIcon(ICONS.icEyeClose)
    }
  }

  const handleMessageModalClose = () => {
    setMessageModalVisibility('hidden')
  }

  function handleFormValidation() {
    if (password === '' || confirmPassword === '') {
      setErrorMessage('Password and Confirm Password must not be empty')
      setIsFormError(true)
      setMessageModalVisibility('visible')
      return false
    } else if (password !== confirmPassword) {
      setErrorMessage('Password and Confirm Password must be the same')
      setIsFormError(true)
      setMessageModalVisibility('visible')
      return false
    } else {
      return true
    }
  }

  const handleSavePassword = () => {
    if (handleFormValidation()) {
      savePassword()
    }
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
    setIsFormError(false)
    setMessageModalVisibility('hidden')
  }

  const handleConfirmPasswordChange = event => {
    setConfirmPassword(event.target.value)
    setIsFormError(false)
    setMessageModalVisibility('hidden')
  }

  const fetchUser = () => {
    axiosInstance
      .get(`${baseURL}/api/users/me`, config)
      .then(response => {
        const { status, data } = response
        if (status === 200) {
          setUser(data)
        } else if (status === 422) {
          setErrorMessage(`Error. ${data.error.errors[0][0].message}`)
          setIsFormError(true)
          setMessageModalVisibility('visible')
        } else {
          setErrorMessage(`Error. ${data.error.message}`)
          setIsFormError(true)
          setMessageModalVisibility('visible')
        }
      })
      .catch(e => {})
  }

  const savePassword = () => {
    axiosInstance
      .put(
        `${baseURL}/api/users/password`,
        { password: password, confirm_password: confirmPassword },
        config
      )
      .then(response => {
        const { status, data } = response
        if (status === 200) {
          handleOnSavePasswordSuccess()
        } else if (status === 422) {
          setErrorMessage(`Error. ${data.error.errors[0][0].message}`)
          setIsFormError(true)
          setMessageModalVisibility('visible')
        } else {
          setErrorMessage(`Error. ${data.error.message}`)
          setIsFormError(true)
          setMessageModalVisibility('visible')
        }
      })
      .catch(e => {})
  }

  const handleOnSavePasswordSuccess = () => {
    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage('You have successfully create new password'))
    navigate('/Login')
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] p-[16px]  md:px-[100px] md:py-[60px]">
      <div className="w-full h-full bg-white rounded-[36px] p-5 flex">
        <div className="flex-auto p-[8px] md:p-[60px] overflow-y-auto">
          <img src={images.m2pLogo} alt="ITM LOGO" className="w-[36px] mb-9" />
          <div className="mb-4">
            <TextH1 text="Create Password" />
            <div className="font-poppins font-normal text-[12px] text-[#AAAAAA]">
              Please create a password for account{' '}
              <span className="text-[#C800A5]">{user?.username}</span>
            </div>
          </div>
          <MessageModal
            message={errorMessage}
            visibility={messageModalVisibility}
            onCloseClick={handleMessageModalClose}
          />
          <div className="mb-6 mt-4">
            <div className="mb-1">
              <TextH6 text="Create Password" />
            </div>
            <FormInput
              type={passVisibility}
              placeholder="Enter new password"
              value={password}
              onChange={handlePasswordChange}
              rightIcon={passIcon}
              onRightIconClick={handlePassVisibility}
              isError={isFormError}
              setIsFormError={setIsFormError}
            />
          </div>
          <div className="mb-[60px]">
            <div className="mb-1">
              <TextH6 text="Confirm Password" />
            </div>
            <FormInput
              type={confirmPassVisibility}
              placeholder="Enter confirm password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              rightIcon={confirmPassIcon}
              onRightIconClick={handleConfirmPassVisibility}
              isError={isFormError}
              setIsFormError={setIsFormError}
            />
          </div>
          <button
            className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] font-poppins font-semibold text-[12px] text-white p-3 w-full rounded-md"
            onClick={handleSavePassword}
          >
            Save Password
          </button>
        </div>
        <div className="hidden md:block h-auto overflow-hidden">
          <img src={images.ilsPassword} alt="ilustration" className="h-full max-w-fit" />
        </div>
      </div>
      <SuccessPopup />
    </div>
  )
}

export default CreatePasswordPage
