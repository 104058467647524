import { useContext } from 'react'

import { PageContext } from '..'
import TypeForm from './TypeForm'
import TypeReport from './TypeReport'
import TypeHTML from './TypeHTML'
import ManageUser from '../../ManageUser'

const MENU_TYPES = {
  FORM: TypeForm,
  REPORT: TypeReport,
  HTML: TypeHTML,
  MANAGE_USER: ManageUser
}

export default function Page() {
  const { page, topColor, bottomColor } = useContext(PageContext)
  const PageType = MENU_TYPES[page?.menu_type]

  return (
    <PageContext.Provider value={{ page, topColor, bottomColor }}>
      <PageType />
    </PageContext.Provider>
  )
}
