import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { TextH1 } from '../../components'
import Header from './components/Header'
import SideNav from './components/SideNav'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const MainPage = () => {
  const location = useLocation()
  let title, crumbs
  const { pathname } = location
  if (location.state) {
    title = location.state.title
    crumbs = location.state.crumbs
  }

  if (!crumbs) {
    title = pathname.split('/')[1]
    crumbs = new Array(pathname.split('/')[1])
  }

  return (
    <>
      <div className="flex w-full h-full bg-[#F2F5FC] p-2 sm:p-4 font-poppins">
        <div className="duration-300 py-4 rounded-2xl bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] hidden sm:block">
          <SideNav />
        </div>
        <div className="flex-1 w-full overflow-auto">
          <div>
            <Header title={title} crumbs={crumbs} />
          </div>
          <div className="flex-auto min-h-screen overflow-hidden">
            <Outlet />
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default MainPage
