import { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { put } from '../../../../api/base'
import { Select, Toast } from '../../../../components'
import { apiUrls } from '../../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../../redux/slices/popupSlice'

import Empty from './Empty'
import PrivateMenu from './PrivateMenu'
import TypeForm from './TypeForm'
import TypeReport from './TypeReport'
import TypeHTML from './TypeHTML'

const MENU_TYPES = [
  { label: 'Form', value: 'FORM' },
  { label: 'Report', value: 'REPORT' },
  { label: 'Html', value: 'HTML' },
  { label: 'User Management', value: 'MANAGE_USER' }
]

const REPORT_TYPES = [
  { label: 'SSRS', value: 'SSRS' },
  { label: 'Power BI', value: 'POWER BI' }
]

export default function MenuDetail({ selected = {}, groups, onSuccess }) {
  const params = useParams()
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const formMethods = useForm()
  const { control, watch, reset, handleSubmit } = formMethods
  const menuType = watch('menu_type')?.value

  const hasSelected = !!Object.keys(selected).length

  async function onSubmit(formData) {
    const payload = {
      parent_id: selected.parent_id,
      name: selected.name,
      menu_type: formData.menu_type.value,
      html_body: formData.html_body,
      is_private_menu: formData.is_private_menu,
      group_ids: formData.groups.map(g => g.group_id)
    }

    switch (formData.menu_type.value) {
      case 'FORM': {
        payload.form_ids = formData.forms.map(f => f.form.id)
        break
      }
      case 'REPORT': {
        if (formData.report_type.value === 'SSRS') {
          payload.report_urls = [
            {
              report_link: formData.report_link,
              report_type: formData.report_type.value,
              attributes: {
                is_segregate_by_companies: formData.attributes.is_segregate_by_companies,
                is_segregate_by_contractors: formData.attributes.is_segregate_by_contractors
              }
            }
          ]
        } else {
          payload.report_urls = [
            {
              report_link: formData.report_link,
              report_type: formData.report_type.value
            }
          ]
        }
        break
      }
      default: {
        break
      }
    }

    const { ok, data } = await put(
      `${apiUrls.MICROSITES_ROUTE}/${params.microsite_route}/menus/${selected.id}`,
      payload
    )

    if (ok) {
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully updated menu'))
      setIsEditing(false)
      onSuccess()
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
    }
  }

  useEffect(() => {
    const hasReports = selected?.reports?.length > 0
    reset({
      report_link: '',
      report_type: '',
      menu_type: { label: '', value: '' },
      ...selected,
      menu_type: MENU_TYPES.find(type => type.value === selected.menu_type),
      forms: selected?.forms?.map(form => ({ ...form, id: form.form_id })),
      report_type: hasReports
        ? REPORT_TYPES.find(type => type.value === selected?.reports[0].report_type)
        : null,
      report_link: hasReports ? selected?.reports[0]?.report_link : null,
      attributes: {
        is_segregate_by_companies:
          (hasReports && selected?.reports[0]?.attributes?.is_segregate_by_companies) || false,
        is_segregate_by_contractors:
          (hasReports && selected?.reports[0]?.attributes?.is_segregate_by_contractors) || false
      },
      html_body: selected?.html?.html_body || '',
      groups: selected?.groups?.map(group => ({
        ...group,
        group_id: group.id,
        group: { name: group.name }
      }))
    })
    setIsEditing(false)
  }, [selected])

  return (
    <div
      className={`bg-white rounded-2xl p-4 md:p-6 lg:col-span-2 lg:my-0 my-6 lg:mx-5 lg:grow ${
        hasSelected ? 'lg:w-[400px]' : 'flex'
      }`}
    >
      {!hasSelected && <Empty />}

      {hasSelected && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...formMethods}>
            <div className="bg-[#F2F5FC] rounded-2xl p-2 sm:p-4 flex items-center justify-between mb-4">
              <div className="font-semibold text-black text-lg">{selected.name || ''}</div>
              {isEditing ? (
                <div>
                  <button
                    type="button"
                    className="md:w-fit bg-white border border-[#6546C3] text-[#6546C3] font-semibold rounded-md px-6 py-2 text-xs mr-2"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="md:w-fit bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-6 py-2 text-xs"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="md:w-fit bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-6 py-2 text-xs"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                </button>
              )}
            </div>

            <div>
              <PrivateMenu isEdit={isEditing} groups={groups} />

              <hr className="my-6" />

              <div className="font-semibold text-xs mb-2">Menu Option</div>
              <Controller
                control={control}
                name="menu_type"
                className="mb-2"
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    options={MENU_TYPES}
                    isDisabled={!isEditing}
                    placeholder="Select menu option"
                  />
                )}
              />

              <hr className="my-6" />

              {menuType === 'FORM' && (
                <TypeForm isEdit={isEditing} micrositeId={selected.microsite_id} />
              )}
              {menuType === 'REPORT' && <TypeReport isEdit={isEditing} />}
              {menuType === 'HTML' && (
                <TypeHTML control={control} name="html_body" isEditing={isEditing} />
              )}
            </div>
          </FormProvider>
        </form>
      )}
    </div>
  )
}
