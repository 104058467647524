import axios from 'axios'
import { apiUrls } from '../../constants'
import { logout, logoutMicrosite, setToken } from '../../redux/slices/authSlice'
import { deleteUserData } from '../../redux/slices/userSlice'
import { store } from '../../redux/store'

const baseURL = process.env.REACT_APP_BASE_URL

const httpInstance = axios.create({ timeout: process.env.REACT_APP_TIMEOUT, baseURL })

const handleResponse = res => {
  const environtment = process.env.NODE_ENV
  if (!res) return { ok: false }

  const { data, status } = res
  const ok = status && status >= 200 && status < 300

  return { ok, status, data }
}

const isTokenExpired = res => {
  return res?.status === 401
}
const isServerFault = res => res?.status >= 500 && res?.status < 600

const handleLogout = async () => {
  store.dispatch(logout())
  store.dispatch(logoutMicrosite())
  store.dispatch(deleteUserData())
  await localStorage.clear()
}

const refreshTokenAndRetry = async (req, refreshToken) => {
  try {
    const res = await axios.get(`${baseURL}${apiUrls.REFRESH_TOKEN_URL}`, {
      params: {
        refresh_token: refreshToken
      }
    })
    const token = res.data.token

    store.dispatch(setToken(token))
    req.headers.Authorization = `Bearer ${token}`
    return await httpInstance.request(req)
  } catch (error) {
    const { response } = error
    if (isTokenExpired(response)) {
      return handleLogout()
    } else if (!isServerFault(response)) {
    }
    return handleResponse(error?.response)
  }
}

httpInstance.interceptors.response.use(
  response => handleResponse(response),
  async error => {
    const { response, config, message } = error
    let responseTimeout
    if (error?.code === 'ECONNABORTED') {
      responseTimeout = {
        status: 408,
        data: {
          error: {
            message:
              'Looks like the server is taking too long to respond. Please try again in while if still no update.'
          }
        }
      }
    }

    const loginRequest = error.config.url.endsWith('/auth/login')
    if (isTokenExpired(error.response) && !config._retry && !loginRequest) {
      const { refreshToken } = store.getState().auth
      config._retry = true

      if (refreshToken) {
        return refreshTokenAndRetry(config, refreshToken)
      } else {
        return handleLogout()
      }
    }

    // TODO: HANDLE ERROR WITH UNDEFINED RESPONSE ( CLIENT ERROR MESSAGE )

    return handleResponse(response ? response : responseTimeout)
  }
)

export default httpInstance
