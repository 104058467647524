import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ICONS } from '../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../redux/slices/popupSlice'
import { FormInput } from '../../../components'

export default function SysAdminUserModal({
  showModal,
  setShowModal,
  handleSaveSysAdminUser,
  currentData
}) {
  const dispatch = useDispatch()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [baseApi, setBaseApi] = useState('')
  const [passVisibility, setPassVisibility] = useState('password')
  const [passIcon, setPassIcon] = useState(ICONS.icEyeClose)

  const handlePassVisibility = () => {
    if (passVisibility === 'password') {
      setPassVisibility('text')
      setPassIcon(ICONS.icEyeOpen)
    } else {
      setPassVisibility('password')
      setPassIcon(ICONS.icEyeClose)
    }
  }

  useEffect(() => {
    if (showModal && currentData) {
      setUsername(currentData?.username)
      setPassword(currentData?.password)
      setBaseApi(currentData?.base_api)
    }
  }, [showModal])

  const formValidation = () => {
    if (!username || !password || !baseApi) {
      return false
    }
    return true
  }

  function onFormSubmit(e) {
    e.preventDefault()
    let data = {
      username,
      password,
      base_api: baseApi
    }
    handleSaveSysAdminUser(data)

    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage(`You have successfully ${currentData ? 'edit' : 'set'} SysAdmin User`))
    close()
  }

  function close() {
    setShowModal(false)
    setUsername('')
    setPassword('')
    setBaseApi('')
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white max-w-full w-fit md:w-[420px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">SysAdmin User</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="mt-4 mb-6" />

          <form onSubmit={onFormSubmit} className="flex flex-col">
            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">Username</div>
              <input
                type="text"
                name="attributeName"
                className="text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                placeholder="Enter username"
                value={username}
                onChange={event => setUsername(event.target.value)}
              />
            </div>

            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">Password</div>
              <FormInput
                type={passVisibility}
                placeholder="Enter password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                rightIcon={passIcon}
                onRightIconClick={handlePassVisibility}
              />
            </div>

            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">URL Base API</div>
              <input
                type="text"
                name="attributeName"
                className="text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                placeholder="Enter url base api"
                value={baseApi}
                onChange={event => setBaseApi(event.target.value)}
              />
            </div>

            <div className="flex justify-end gap-4 lg:col-span-3">
              <button
                type="button"
                onClick={close}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={!formValidation()}
                className="py-3 px-8 disabled:bg-gray-400 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
