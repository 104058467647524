import axios from 'axios'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { get } from '../../../api/base'
import { FormInput, TextH1, TextH6 } from '../../../components'
import { apiUrls, ICONS, images } from '../../../constants'
import { setMicrositeTokens, setRefreshToken, setToken } from '../../../redux/slices/authSlice'
import { selectSites } from '../../../redux/slices/micrositeSlice'
import { setUserData } from '../../../redux/slices/userSlice'

export default function Login() {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const site = useSelector(selectSites).find(
    s => s.route_name.toLowerCase() === params.microsite.toLowerCase()
  )

  const { control, handleSubmit } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  })
  const [passwordShown, setPasswordShown] = useState(false)
  const [error, setError] = useState()

  async function login(form) {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${apiUrls.LOGIN_URL}`,
        form
      )
      const { token, refresh_token, microsite_tokens } = data

      if (
        !Object.keys(microsite_tokens)
          .map(t => t.toLowerCase())
          .find(t => t === params.microsite.toLowerCase())
      ) {
        setError(`You don't have access to this Site`)
        return
      }

      const lowerCasedMicrositeTokens = Object.entries(microsite_tokens).reduce(
        (obj, [name, token]) => ({ ...obj, [name.toLowerCase()]: token }),
        {}
      )
      dispatch(setToken(token))
      dispatch(setRefreshToken(refresh_token))
      dispatch(setMicrositeTokens(lowerCasedMicrositeTokens))
      await getProfile()
      navigate(`/Site/${params.microsite}`)
    } catch ({ response }) {
      setError(response.data.error.message)
    }
  }

  async function getProfile() {
    const { data } = await get(apiUrls.USER_PROFILE)
    dispatch(setUserData(data))
  }

  return (
    <div
      className="w-screen h-screen  p-4 lg:flex justify-center items-center"
      style={{
        background: site?.bottom_color
          ? `linear-gradient(to bottom, ${site?.top_color}, ${site?.bottom_color})`
          : site?.top_color || `linear-gradient(to bottom, #01a76b, #00B1B5)`
      }}
    >
      <div className="w-full lg:w-[1040px] h-[441px] bg-white rounded-[36px] p-5 flex">
        <form
          onSubmit={handleSubmit(login)}
          className="flex-auto p-2 md:p-[10px] lg:p-5 overflow-y-auto"
        >
          <img
            src={site.logo_url}
            alt="logo"
            className="text-xs max-w-[150px] max-h-[100px] mb-6"
          />

          <div className="mb-4">
            <TextH1 text="Get Started" />
            <div
              className="font-poppins font-normal text-[12px] text-[#AAAAAA]"
              dangerouslySetInnerHTML={{ __html: site.description }}
            />
          </div>

          {!!error && <div className="rounded-lg bg-red-400 text-white text-xs p-3">{error}</div>}

          <div className="flex flex-col md:flex-row justify-evenly gap-6 mt-4">
            <div className="flex-1">
              <div className="mb-1">
                <TextH6 text="Username" />
              </div>
              <Controller
                control={control}
                name="username"
                rules={{ required: 'Username is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <FormInput
                      type="text"
                      placeholder="Enter username"
                      value={value}
                      onChange={onChange}
                      isError={error}
                      setIsFormError={() => {}}
                    />
                    {error && (
                      <div className="text-xs text-red-600">
                        <small>{error.message}</small>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <div className="flex-1">
              <div className="mb-[60px]">
                <div className="mb-1">
                  <TextH6 text="Password" />
                </div>
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: 'Password is required' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <FormInput
                        type={passwordShown ? 'text' : 'password'}
                        placeholder="Enter password"
                        value={value}
                        onChange={onChange}
                        rightIcon={passwordShown ? ICONS.icEyeOpen : ICONS.icEyeClose}
                        onRightIconClick={() => setPasswordShown(shown => !shown)}
                        isError={error}
                        setIsFormError={() => {}}
                      />
                      {error && (
                        <div className="text-xs text-red-600">
                          <small>{error.message}</small>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="font-poppins font-semibold text-xs text-white p-3 w-full rounded-md"
            style={{
              background: site?.top_color || '#00B1B5'
            }}
          >
            Login
          </button>
        </form>

        <div className="hidden md:flex h-auto overflow-hidden items-center">
          <img
            src={site?.login_image_url || images.siteLogin}
            alt="illustration"
            className="max-h-full max-w-[500px] rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}
