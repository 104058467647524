import { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { get, post } from '../../api/base'
import { FormInput, TextBody1, TextH1, TextH6, Toast } from '../../components'
import SuccessPopup from '../../components/Popup/SuccessPopup'
import { ICONS, apiUrls, images } from '../../constants'
import {
  setMicrositeTokens,
  setRefreshToken,
  setToken,
  saveUuid
} from '../../redux/slices/authSlice'
import { setUserData } from '../../redux/slices/userSlice'
import MessageModal from './components/MessageModal'
import { v4 as uuidv4 } from 'uuid'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { loginRequest } from '../../authConfig'
import * as App from '../../index'
import LoadingComponent from './components/LoadingComponent'

const LoginPage = () => {
  const dispatch = useDispatch()
  const [passVisibility, setPassVisibility] = useState('password')
  const [passIcon, setPassIcon] = useState(ICONS.icEyeClose)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [messageModalVisibility, setMessageModalVisibility] = useState('hidden')
  const [bgColor, setBgColor] = useState('')
  const [isFormError, setIsFormError] = useState(false)
  const [showApkModal, setShowApkModal] = useState(isMobile)
  const [showDownloadApkModal, setShowDownloadApkModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const uuid = uuidv4()
  const [latestVer, setLatestVer] = useState('')
  const { msalInstance } = App
  const accounts = msalInstance.getAllAccounts()
  const isAuthenticated = useIsAuthenticated()
  const [isLoading, setIsLoading] = useState(true)
  const LOGIN_WITH_AZURE_KEY = 'user_login_with_azure'

  const handlePassVisibility = () => {
    if (passVisibility === 'password') {
      setPassVisibility('text')
      setPassIcon(ICONS.icEyeOpen)
    } else {
      setPassVisibility('password')
      setPassIcon(ICONS.icEyeClose)
    }
  }

  const handleMessageModalClose = () => {
    setMessageModalVisibility('hidden')
  }

  const handleLoginError = response => {
    const { status } = response
    setBgColor('')
    if (status === 401) {
      setBgColor(response.data.error.code === 40103 ? 'bg-green-600' : '')
      setErrorMessage(response.data.error.message)
      setMessageModalVisibility('visible')
      setIsFormError(true)
    } else if (status === 403) {
      setErrorMessage(
        'Thank you for visiting us, please contact IT Helpdesk to activate your account.'
      )
      setMessageModalVisibility('visible')
    } else if (status === 500) {
      setErrorMessage('Error trying to connect to server')
      setMessageModalVisibility('visible')
    } else if (status === 408) {
      setErrorMessage(
        'Looks like the server is taking too long to respond. Please try again in while if still no update.'
      )
      setMessageModalVisibility('visible')
    }
  }

  function handleFormValidation() {
    if (username === '' || password === '') {
      setErrorMessage('Username and password must not be empty')
      setIsFormError(true)
      setMessageModalVisibility('visible')
      return false
    } else {
      return true
    }
  }

  const handleLogin = () => {
    if (handleFormValidation()) {
      const bodyParams = {
        username: username,
        password: password,
        uuid: uuid
      }
      post(apiUrls.LOGIN_URL, bodyParams).then(async response => {
        const { status } = response
        if (status === 200) {
          const { token, refresh_token, microsite_tokens } = response.data
          dispatch(setToken(token))
          dispatch(setRefreshToken(refresh_token))
          dispatch(
            setMicrositeTokens(
              Object.entries(microsite_tokens).reduce(
                (obj, [name, token]) => ({ ...obj, [name.toLowerCase()]: token }),
                {}
              )
            )
          )
          dispatch(saveUuid(uuid))

          get(apiUrls.USER_PROFILE).then(response => {
            const { status, data } = response
            if (status === 200) {
              dispatch(setUserData(data))
            } else if (status === 408) {
              toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
            } else {
              toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
            }
          })
        } else {
          handleLoginError(response)
        }
      })
    }
  }

  const { instance } = useMsal()

  const handleLoginAzure = () => {
    localStorage.clear()
    localStorage.setItem(LOGIN_WITH_AZURE_KEY, true)
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e)
    })
  }

  const callLoginAzureAPI = async () => {
    post(apiUrls.LOGIN_AZURE_URL).then(async response => {
      const { status } = response
      if (status === 200) {
        const { token, refresh_token, microsite_tokens } = response.data
        dispatch(setToken(token))
        dispatch(setRefreshToken(refresh_token))
        dispatch(
          setMicrositeTokens(
            Object.entries(microsite_tokens).reduce(
              (obj, [name, token]) => ({ ...obj, [name.toLowerCase()]: token }),
              {}
            )
          )
        )
        dispatch(saveUuid(uuid))

        get(apiUrls.USER_PROFILE).then(response => {
          const { status, data } = response
          if (status === 200) {
            dispatch(setUserData(data))
          } else if (status === 408) {
            toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
          } else {
            toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
          }
        })
      } else {
        handleLoginError(response)
      }
    })
  }

  useEffect(() => {
    if (accounts.length > 0 && !isAuthenticated) {
      callLoginAzureAPI()
    }
  }, [accounts])

  useEffect(() => {
    if (localStorage.getItem(LOGIN_WITH_AZURE_KEY)) {
      setTimeout(() => {
        setIsLoading(false)
        localStorage.removeItem(LOGIN_WITH_AZURE_KEY)
      }, 3000)
    }
  }, [])

  const handleUsernameChange = event => {
    setUsername(event.target.value)
    setIsFormError(false)
    setMessageModalVisibility('hidden')
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
    setIsFormError(false)
    setMessageModalVisibility('hidden')
  }

  const handleOpenFromApp = () => {
    // window.location.href = "https://www.m2p-cms.banpuindo.co.id/deeplink";
    window.location.href = 'm2p://www.m2p-cms.banpuindo.co.id/deeplink'
    setShowApkModal(false)
  }
  const handleOnDownloadApkClick = () => {
    setShowApkModal(false)
    setShowDownloadApkModal(true)
  }
  const handleOnCancelDownload = () => {
    setShowApkModal(true)
    setShowDownloadApkModal(false)
  }
  const handleDownloaApk = () => {
    setShowDownloadApkModal(false)
    setShowSuccessModal(true)
    window.open(process.env.REACT_APP_APK_URL, '_blank')
  }

  const fetchLatestAppVersion = () => {
    get(apiUrls.LATEST_VERSION).then(response => {
      const { status } = response
      if (status === 200) {
        setLatestVer(response.data?.latest_version || '')
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={`Failed to fetch latest app version`} />
        )
      }
    })
  }

  useEffect(() => {
    if (showApkModal) {
      fetchLatestAppVersion()
    }
  }, [showApkModal])

  return (
    <>
      {localStorage.getItem(LOGIN_WITH_AZURE_KEY) && isLoading ? (
        <LoadingComponent />
      ) : (
        <div className="w-screen h-screen bg-gradient-to-b from-[#6546C3] to-[#9E7CE3] p-[16px] lg:flex justify-center items-center">
          <div className="w-full h-full lg:w-[1040px] h-[441px] bg-white rounded-[36px] p-5 flex">
            <div className="flex-auto p-[8px] md:p-[10px] lg:p-[20px] overflow-y-auto">
              <img src={images.m2pLogo} alt="ITM LOGO" className="w-[36px] mb-9" />
              <div className="mb-4">
                <TextH1 text="Get Started" />
                <TextBody1 text="Continue to set a parameter on mobile application MPort" />
              </div>
              <MessageModal
                message={errorMessage}
                visibility={messageModalVisibility}
                onCloseClick={handleMessageModalClose}
                bgColor={bgColor}
              />
              <div className="flex flex-col md:flex-row justify-evenly gap-6 mt-4">
                <div className="flex-1">
                  <div>
                    <div className="mb-1">
                      <TextH6 text="Username" />
                    </div>
                    <FormInput
                      type="text"
                      placeholder="Enter username"
                      value={username}
                      onChange={handleUsernameChange}
                      isError={isFormError}
                      setIsFormError={setIsFormError}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="mb-[60px]">
                    <div className="mb-1">
                      <TextH6 text="Password" />
                    </div>
                    <FormInput
                      type={passVisibility}
                      placeholder="Enter password"
                      value={password}
                      onChange={handlePasswordChange}
                      rightIcon={passIcon}
                      onRightIconClick={handlePassVisibility}
                      isError={isFormError}
                      setIsFormError={setIsFormError}
                    />
                  </div>
                </div>
              </div>
              <button
                className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] font-poppins font-semibold text-[12px] text-white p-3 w-full rounded-md"
                onClick={handleLogin}
              >
                Login
              </button>
              <button
                className="bg-[#01b59c] font-poppins font-semibold text-[12px] text-white p-3 w-full rounded-md mt-4"
                onClick={handleLoginAzure}
              >
                Login with Azure
              </button>
            </div>
            <div className="hidden md:block h-auto overflow-hidden">
              <img src={images.ilsLogin} alt="ilustration" className="h-full max-w-fit" />
            </div>
          </div>
          <ReactModal
            isOpen={showApkModal}
            appElement={document.getElementById('root') || undefined}
            className="absolute bottom-0 left-0 right-0 rounded-2xl border-none m-2"
          >
            <div className="flex flex-col font-poppins">
              <img className="" src={images.bgApkPopup} />
              <div className="absolute w-full h-full p-3">
                <div className="flex flex-row items-center">
                  <img className="w-12 h-12" src={ICONS.icM2pApps} />
                  <div className="flex flex-col px-4">
                    <div className="font-semibold text-[16px] text-white">M2P Superapp</div>
                    <div className="font-semibold text-[10px] text-white">
                      App Version {latestVer}
                    </div>
                    <div className="font-normal text-[10px] text-[#EBEDF8]">
                      Available for mobile android
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-[206px] bg-opacity-[0.16] rounded-lg bg-white pl-3 pr-2 py-1 mb-2 mt-2 items-center justify-between">
                  <div className="font-normal text-[12px] text-[#EBEDF8]">M2P Application</div>
                  <div className="flex flex-row gap-2 items-center">
                    <img src={ICONS.icOpen} className="w-6 h-6" onClick={handleOpenFromApp} />
                    <div className="w-[1px] h-[16px] bg-[#DEDEDE]"></div>
                    <img
                      src={ICONS.icDownload}
                      className="w-6 h-6"
                      onClick={handleOnDownloadApkClick}
                    />
                  </div>
                </div>
                <div className="flex flex-row w-[206px] bg-opacity-[0.16] rounded-lg bg-white pl-3 pr-2 py-1 items-center justify-between">
                  <div className="font-normal text-[12px] text-[#EBEDF8]">Open with browser</div>
                  <img
                    src={ICONS.icCevRightBg}
                    className="w-6 h-6"
                    onClick={() => setShowApkModal(false)}
                  />
                </div>
              </div>
              <img
                src={ICONS.icCloseRound}
                className="w-6 h-6 absolute right-0 top-[-8px]"
                onClick={() => setShowApkModal(false)}
              />
            </div>
          </ReactModal>
          <ReactModal
            isOpen={showDownloadApkModal}
            appElement={document.getElementById('root') || undefined}
            className="absolute bottom-0 left-0 right-0 rounded-2xl border-none"
          >
            <div className="flex flex-col font-poppins bg-white p-4 pt-6 rounded-t-[32px]">
              <div className="font-semibold text-[18px] text-[#232323] mb-[15px]">Confirmation</div>
              <hr />
              <div className="flex flex-col items-center">
                <img src={ICONS.icAndroidRounded} className="w-[120px] h-[120px] m-6" />
                <div className="font-semibold text-[14px] text-[#232323] mb-2">
                  Do you want to install M2P Superapp?
                </div>
                <div className="font-normal text-[12px] text-[#AAAAAA] mb-[46px]">
                  Click Ok to continue download android installler.
                </div>
              </div>
              <div className="flex flex-row">
                <button
                  className="flex-1 p-3 border-[#6546C3] border-[2px] rounded-md text-[#6546C3] font-semibold text-[12px]"
                  onClick={handleOnCancelDownload}
                >
                  Cancel
                </button>
                <div className="w-6"></div>
                <button
                  className="flex-1 p-3 rounded-md bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold text-[12px]"
                  onClick={handleDownloaApk}
                >
                  Ok
                </button>
              </div>
            </div>
          </ReactModal>
          <ReactModal
            isOpen={showSuccessModal}
            appElement={document.getElementById('root') || undefined}
            className="absolute bottom-0 left-0 right-0 rounded-2xl border-none"
          >
            <div className="flex flex-col font-poppins bg-white p-4 pt-6 rounded-t-[32px]">
              <div className="flex flex-col items-center">
                <img src={ICONS.icSuccess} className="w-[120px] h-[120px] m-[36px]" />
                <div className="font-semibold text-[18px] text-[#232323] mb-2">Successfully</div>
                <div className="font-normal text-[12px] text-[#AAAAAA] mb-[60px]">
                  You have successfully download application
                </div>
              </div>
              <button
                className="flex-1 p-3 rounded-md bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold text-[12px]"
                onClick={() => setShowSuccessModal(false)}
              >
                OK
              </button>
            </div>
          </ReactModal>
          <SuccessPopup />
        </div>
      )}
    </>
  )
}

export default LoginPage
