const DRAFT_TO_HTML = {
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'figure' && node.firstChild.nodeName === 'IMG') {
      return createEntity('IMAGE', 'IMMUTABLE', {
        src: node.firstChild.src,
        ...(node.style.textAlign !== 'center' && { alignment: node.style.textAlign })
      })
    }
    if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', { url: node.href })
    }
  },
  htmlToBlock: (nodeName, node, lastList, inBlock) => {
    if (
      (nodeName === 'figure' && node.firstChild.nodeName === 'IMG') ||
      (nodeName === 'img' && inBlock !== 'atomic')
    ) {
      return 'atomic'
    }

    if (nodeName === 'p') {
      const textAlign = node.style.textAlign || node.className
      if (textAlign) {
        return {
          type: 'unstyled',
          data: { 'text-align': textAlign }
        }
      }
    }
  },
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (nodeName === 'span') {
      let style = currentStyle
      if (node.style.textDecoration === 'line-through') {
        style = style.add('STRIKETHROUGH')
      }
      if (node.style.backgroundColor) {
        style = style.add(`bgcolor-${node.style.backgroundColor}`)
      }
      if (node.style.color) {
        style = style.add(`color-${node.style.color}`)
      }
      if (node.style.fontSize) {
        let fontSize = node.style.fontSize.replace('px', '')
        style = style.add(`fontsize-${fontSize}`)
      }
      return style
    }
    return currentStyle
  }
}

const HTML_TO_DRAFT = {
  //eslint-disable-next-line react/display-name
  styleToHTML: style => {
    if (style === 'STRIKETHROUGH') {
      return <span style={{ textDecoration: 'line-through' }} />
    }
    if (style.startsWith('bgcolor-')) {
      const backgroundColor = style.substring(8)
      return <span style={{ backgroundColor }} />
    }
    if (style.startsWith('color-')) {
      const textColor = style.substring(6)
      return <span style={{ color: textColor }} />
    }
    if (style.startsWith('fontsize-')) {
      const fontSize = style.substring(9)
      return <span style={{ fontSize: `${fontSize}px` }} />
    }
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'image' || entity.type === 'IMAGE') {
      let classNames = 'draft-image '
      if (entity.data.alignment) {
        classNames = classNames + entity.data.alignment
      }
      let style = `width: ${entity.data.width || 'auto'}; height: ${entity.data.height || 'auto'};`
      return `<figure style="
      text-align: ${entity.data.alignment || 'center'}; 
      display: flex; 
      justify-content: ${
        entity.data.alignment === 'left'
          ? 'flex-start'
          : entity.data.alignment === 'right'
          ? 'flex-end'
          : 'center'
      }"><img src="${entity.data.src}" class="${classNames}" style="${style}" /></figure>`
    }
    if (entity.type === 'LINK') {
      return {
        start: `<a href="${entity.data.url || entity.data.href}">`,
        end: '</a>'
      }
    }
    if (entity.type === 'IMG') {
      const className = 'draft-inline-image'
      return `<img src="${entity.data.src}" class="${className}" alt="${entity.data.alt}"/>`
    }
    if (entity.type === 'INLINETEX') {
      if (entity.data.html) {
        return `<span>${entity.data.css ? `<style>${entity.data.css}</style>` : ''}${
          entity.data.html
        }</span>`
      } else {
        return `<span class="draft-latex-placeholder"> &lt; refresh to render LaTeX &gt; </span>`
      }
    }
    return originalText
  },
  //eslint-disable-next-line react/display-name
  blockToHTML: block => {
    const type = block.type
    if (type === 'atomic') {
      if (block.data && block.data.mathjax && block.data.html) {
        return `<div>${block.data.css ? `<style>${block.data.css}</style>` : ''}${
          block.data.html
        }</div>`
      } else if (block.data && block.data.mathjax) {
        return `<div class="draft-latex-placeholder-block"> &lt;refresh to render LaTeX&gt; </div>`
      } else {
        return { start: '<span>', end: '</span>' }
      }
    }
    if (type === 'blockquote') {
      return <blockquote />
    }
    if (type === 'code-block') {
      return { start: '<pre><code>', end: '</code></pre>' }
    }
    if (type === 'divider') {
      return <hr className="dividerBlock" />
    }
    if (type === 'spoiler') {
      return <p className="spoiler" />
    }
    if (type === 'unstyled') {
      if (block.text === ' ' || block.text === '') return <br />
      if (block.data['text-align']) {
        return { start: `<p style="text-align: ${block.data['text-align']}">`, end: '</p>' }
      }
      return <p />
    }
  }
}

export default {
  DRAFT_TO_HTML,
  HTML_TO_DRAFT
}
