import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ICONS } from '../../../../../../constants'
import SubMenu from '../SubMenu'
import { useDispatch } from 'react-redux'
import {
  setHandleLeavePage,
  setShowPopupLeavePage
} from '../../../../../../redux/slices/popupSlice'

const Menu = ({ data, open }) => {
  const { subMenus } = data
  const location = useLocation()
  const path = location.pathname
  let isActive = false
  const crumbs = data.path.split('/')
  if (Object.values(crumbs).includes(path.split('/')[1])) isActive = true

  const iconData = isActive ? data.icon_active : data.icon
  const classNames = isActive
    ? `cursor-pointer bg-white bg-opacity-[16%] h-10 rounded-lg p-1 flex gap-x-4 items-center ${
        open ? '' : 'justify-center'
      } mb-2`
    : `cursor-pointer hover:bg-white hover:bg-opacity-[16%] h-10 rounded-lg p-1 flex gap-x-4 items-center ${
        open ? '' : 'justify-center'
      } mb-2`
  const icon = isActive ? (
    <img src={data.icon_active} className="w-8 h-8" />
  ) : (
    <div className="p-1">
      <img src={data.icon} className="w-6 h-6" />
    </div>
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleMenuClick = () => {
    if (
      location.pathname === '/DocumentLibrary/Form/AddForm' ||
      location.pathname.startsWith('/DocumentLibrary/Form/EditForm')
    ) {
      dispatch(setShowPopupLeavePage(true))
      dispatch(setHandleLeavePage({ path: data.path, state: { title: data.name, crumbs: crumbs } }))
    } else {
      navigate(data.path, { state: { title: data.name, crumbs: crumbs } })
    }
  }

  if (subMenus.length > 0 && isActive && open) {
    return (
      <>
        <li className={classNames} onClick={handleMenuClick}>
          {icon}
          {open ? <span className="text-white font-poppins text-[14px]">{data.name}</span> : <></>}
          {subMenus.length > 0 ? <img src={ICONS.icChevronDown} className="w-5 h-5" /> : <></>}
        </li>
        <ul className="bg-white/[16%] rounded-lg py-[10px]">
          {subMenus.map((subMenu, index) => (
            <SubMenu data={subMenu} key={index} open={open} />
          ))}
        </ul>
      </>
    )
  } else if (subMenus.length > 0) {
    return (
      <>
        <li className={classNames} onClick={handleMenuClick}>
          {icon}
          {open ? <span className="text-white font-poppins text-[14px]">{data.name}</span> : <></>}
          {open && subMenus.length > 0 ? (
            <img src={ICONS.icChevronUp} className="w-5 h-5" />
          ) : (
            <></>
          )}
        </li>
      </>
    )
  } else {
    return (
      <li className={classNames} onClick={handleMenuClick}>
        {icon}
        {open ? <span className="text-white font-poppins text-[14px]">{data.name}</span> : <></>}
      </li>
    )
  }
}

export default Menu
