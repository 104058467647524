import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTable, useSortBy } from 'react-table'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Toast } from '../../../components'
import { DropdownIndicator } from '../../../components'
import { apiUrls, ICONS, images } from '../../../constants'
import { get, put, del } from '../../../api/base'
import { useDispatch } from 'react-redux'
import {
  setShowPopupDelete,
  setShowPopupSuccess,
  setMessage
} from '../../../redux/slices/popupSlice'
import DeletePopup from '../../../components/Popup/DeletePopup'
import SuccessPopup from '../../../components/Popup/SuccessPopup'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="flex w-full md:w-fit font-poppins border-[1px] items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}

const GroupTable = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [deleteGroup, setDeleteGroup] = useState(false)
  const [deletedRowId, setDeletedRowId] = useState(-1)

  const COLUMNS = [
    {
      Header: 'Group Name',
      accessor: 'name',
      Cell: ({ row, value }) => {
        return value
      }
    },
    {
      Header: 'Is Active',
      accessor: 'active',
      Cell: ({ row, value, pageNum }) => {
        const groupId = row.original.id
        const checked = value
        const handleStatusChange = (event, id) => {
          const newChecked = event.target.checked
          put(`${apiUrls.GROUPS_URL}/${id}`, { active: newChecked }).then(response => {
            const { status } = response
            if (status === 200) {
              toast.success(
                <Toast
                  message={`Success`}
                  detailedMessage={`You have successfully update group ${row.original.name} to ${
                    newChecked ? 'Active' : 'Inactive'
                  }`}
                />
              )
            } else {
              toast.error(
                <Toast
                  message={`Error`}
                  detailedMessage={`Failed to update group ${row.original.name} to ${
                    newChecked ? 'Active' : 'Inactive'
                  }`}
                />
              )
            }
            fetchGroups(pageNum)
          })
        }
        const checkedClass =
          "w-11 h-6 bg-[#6546C3] rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all after:translate-x-full after:border-white "
        const unCheckedClass =
          "w-11 h-6 bg-gray-200 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all"
        return (
          <label
            htmlFor={`status-toggle-${groupId}`}
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              id={`status-toggle-${groupId}`}
              className="sr-only peer"
              checked={checked}
              onChange={event => handleStatusChange(event, row.original.id)}
            />
            <div className={checked ? checkedClass : unCheckedClass}></div>
          </label>
        )
      }
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ row, value }) => {
        return (
          <div className="flex gap-2">
            {value.map((type, index) => (
              <div
                key={index}
                className="text-center rounded-[8px] bg-[#C800A5]/[0.08306085495721721] font-poppins text-[#C800A5] text-[12px] font-semibold px-3 py-[7px] mt-2 mb-2"
              >
                {type}
              </div>
            ))}
          </div>
        )
      }
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleEditGroupClick = () => {
          const DETAIL_GROUP_PATH = `/Admin/ManageGroup/EditGroup/${row.original.id}`
          const DETAIL_GROUP_CRUMBS = 'Admin/Manage Group/Edit Group'
          const crumbs = DETAIL_GROUP_CRUMBS.split('/')
          navigate(DETAIL_GROUP_PATH, {
            state: { title: 'Edit Group', crumbs: crumbs }
          })
        }
        const handleDeleteGroupClick = id => {
          setDeletedRowId(id)
          dispatch(setShowPopupDelete(true))
          dispatch(setMessage('Are you sure want to delete this group?'))
        }
        return (
          <div className="flex gap">
            <img
              src={ICONS.icEdit}
              className="w-[28px] cursor-pointer"
              onClick={() => handleEditGroupClick()}
              alt="edit icon"
            />
            <img
              src={ICONS.icTrash}
              className="w-[28px] cursor-pointer"
              onClick={() => handleDeleteGroupClick(row.original.id)}
              alt="delete icon"
            />
          </div>
        )
      }
    }
  ]
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const isAddRow = useRef(false)
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [nameFilter, setNameFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [typeFilter, setTypeFilter] = useState('')
  const [groupsNameOptions, setGroupsNameOptions] = useState([])
  const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
  ]
  const typeOptions = [
    { label: 'Assignment', value: 'ASSIGNMENT' },
    { label: 'VIP', value: 'VIP' },
    { label: 'Local Group', value: 'LOCAL_GROUP' }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      pageNum
    },
    useSortBy
  )

  const customSelectInputStyle = {
    control: (provided, state) => ({
      ...provided,
      borderColor: '#DEDEDE',
      borderRadius: '8px',
      ':focus': { borderColor: '#6546C3' },
      ':active': { borderColor: '#6546C3' },
      ':hover': { borderColor: '#6546C3' },
      paddingLeft: 4,
      boxShadow: 'none'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#C800A5',
      ':hover': { color: '#6546C3' }
    }),
    indicatorSeparator: () => ({})
  }

  // handle delete group after click action delete
  useEffect(() => {
    if (deleteGroup) {
      setDeleteGroup(false)
      setDeletedRowId(-1)

      del(`${apiUrls.GROUPS_URL}/${deletedRowId}`).then(response => {
        const { status } = response
        if (status === 200) {
          dispatch(setShowPopupSuccess(true))
          dispatch(setMessage('You have successfully delete group'))
          fetchGroups()
        } else if (status === 401) {
          toast.error(<Toast message={`Error`} detailedMessage={`Unauthorized user.`} />)
        } else if (status === 403) {
          toast.error(
            <Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />
          )
        } else if (status === 500) {
          toast.error(
            <Toast message={`Server Error`} detailedMessage={`Failed to delete group.`} />
          )
        }
      })
    }
  }, [deleteGroup])

  useEffect(() => {
    if (sortBy.length > 0) {
      setManualSortBy(sortBy[0].id)
      setOrderBy(sortBy[0].desc ? 'desc' : 'asc')
    } else {
      setManualSortBy('')
      setOrderBy('')
    }
  }, [sortBy])

  useEffect(() => {
    // Populate groups name options
    fetchGroupsName()
  }, [])

  function fetchGroupsName() {
    get(`${apiUrls.GROUPS_URL}/distinct`).then(response => {
      const { status } = response
      if (status === 200) {
        const groupsName = response.data.map(name => {
          return { label: name, value: name }
        })
        setGroupsNameOptions(groupsName)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch groups name'} />)
      }
    })
  }

  useEffect(() => {
    fetchGroups()
  }, [search, pageNum, limit, manualSortBy, orderBy, nameFilter, statusFilter, typeFilter])

  function fetchGroups(currPage) {
    let query = {
      query: {}
    }
    if (search !== '') query.query.search = search

    if (pageNum !== '') query.query.page = currPage ? currPage : pageNum

    if (limit !== '') query.query.limit = limit

    if (manualSortBy !== '') query.query.sort_by = manualSortBy

    if (orderBy !== '') query.query.order_by = orderBy

    if (nameFilter !== '') query.query.name = nameFilter

    if (statusFilter !== '') query.query.status = statusFilter

    if (typeFilter !== '') query.query.type = typeFilter

    get(apiUrls.GROUPS_URL, query).then(response => {
      const { status } = response
      if (status === 200) {
        if (pageNum > response.data?.total_page) {
          setPageNum(1)
        }
        setData(response.data.data)
        setTotalPage(response.data.total_page)
        setTotalData(response.data.total_data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch groups'} />)
      }
    })
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map(data => {
        if (data === 0)
          return (
            <div key={data} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={data}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => setPageNum(data)}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function handleAddGroupClick() {
    const ADD_GROUP_PATH = '/Admin/ManageGroup/AddGroup'
    const ADD_GROUP_CRUMBS = 'Admin/Manage Group/Add Group'
    const crumbs = ADD_GROUP_CRUMBS.split('/')
    navigate(ADD_GROUP_PATH, { state: { title: 'Add Group', crumbs: crumbs } })
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 != 0
  }

  const goToNextPage = () => {
    setPageNum(pageNum + 1)
  }
  const goToPreviousPage = () => {
    setPageNum(pageNum - 1)
  }

  return (
    <>
      <div className="flex flex-col flex-1 p-6 bg-[#F2F5FC] rounded-2xl gap-2">
        <div className="font-poppins font-semibold text-[16px] text-[#C800A5]">Filter</div>
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          <Select
            components={{ DropdownIndicator }}
            onChange={selectedOption => setNameFilter(selectedOption.value)}
            options={groupsNameOptions}
            name="Name"
            className={`input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            placeholder="Select name"
            styles={customSelectInputStyle}
          />
          <Select
            components={{ DropdownIndicator }}
            onChange={selectedOption => setStatusFilter(selectedOption.value)}
            options={statusOptions}
            name="Status"
            className={`input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            placeholder="Select status"
            styles={customSelectInputStyle}
          />
          <Select
            components={{ DropdownIndicator }}
            onChange={selectedOption => setTypeFilter(selectedOption.value)}
            options={typeOptions}
            name="Type"
            className={`input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            placeholder="Select type"
            styles={customSelectInputStyle}
          />
        </div>
      </div>
      <hr className="my-6" />
      <div className="flex flex-col md:flex-row gap-2 items-center justify-between font-poppins text-[12px] mb-10">
        <button
          className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-8 py-3"
          onClick={() => handleAddGroupClick()}
        >
          Add Group
        </button>
        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
            />
            <select
              value={limit}
              onChange={e => {
                setLimit(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(limit => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex w-full md:w-fit">
          <GlobalFilter filter={search} setFilter={setSearch} />
        </div>
      </div>
      <table {...getTableProps()} className="font-poppins text-[12px]">
        <thead className="">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex gap-1">
                    {column.render('Header')}
                    <img
                      src={
                        column.canSort
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? ICONS.icSortDown
                              : ICONS.icSortUp
                            : ICONS.icSort
                          : ''
                      }
                      className="w-2"
                    />
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
          <div className="font-semibold text-[18px] mb-2">You’ve not added any group yet</div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "add group" button to add it now!
          </div>
        </div>
      )}
      <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-9">
        {data.length !== 0 ? (
          <div className="font-poppins text-[12px]">
            Showing {pageNum * limit - (limit - 1)} -{' '}
            {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
          </div>
        ) : (
          <div className="font-poppins text-[12px]">No data to show</div>
        )}
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &gt;
          </button>
        </div>
      </div>
      <DeletePopup setContinueDelete={setDeleteGroup} />
      <SuccessPopup />
    </>
  )
}

export default GroupTable
