import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'
import { FormInput, DropdownIndicator, Toast } from '../../../../components'
import { ICONS } from '../../../../constants'
import { setMessage, setShowPopupSuccess } from '../../../../redux/slices/popupSlice'
import { del, get, post } from '../../../../api/base'
import CollaboratorTable from './CollaboratorTable'
import RemoveCollabModal from './RemoveCollabModal'
import SuccessPopup from '../../../../components/Popup/SuccessPopup'

const CollaboratorModal = ({ showModal, setShowModal, formData }) => {
  const dispatch = useDispatch()
  const formId = formData?.id
  const formTitle = formData?.title
  const [userOptions, setUserOptions] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const selectedUserIds = selectedUsers.map(selectedUser => {
    return selectedUser.id
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteSoonCollaborator, setDeleteSoonCollaborator] = useState(null)
  const [updateCollaborator, setUpdateCollaborator] = useState(false)

  useEffect(() => {
    if (showModal) {
      fetchNotCollaborators()
      updateCollabTable()
    }
  }, [showModal])

  const fetchNotCollaborators = () => {
    get(`/api/dynamic-forms/${formId}/not-collaborators`).then(response => {
      const { status } = response
      if (status === 200) {
        setUserOptions(response.data)
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={`Failed to fetch not collaborators`} />
        )
      }
    })
  }

  const handleCloseModal = () => {
    setSelectedUsers([])
    setShowModal(false)
  }

  const handleOnUserChange = selectedUsers => {
    setSelectedUsers(selectedUsers)
  }

  const collaboratorValidation = () => {
    if (selectedUserIds.length < 1) {
      toast.error(<Toast message={`Error`} detailedMessage={`Select atleast 1 user.`} />)
      return false
    }
    return true
  }

  const handleOnAddCollaboratorClick = () => {
    if (collaboratorValidation()) {
      addCollaborator()
    }
  }

  const addCollaborator = () => {
    post(`/api/dynamic-forms/${formId}/collaborators`, {
      collaborators: selectedUserIds
    }).then(response => {
      const { status } = response
      if (status === 200) {
        handleOnSuccessAddCollaborator()
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`Failed add collaborator.`} />)
      }
    })
  }

  const handleOnSuccessAddCollaborator = () => {
    setSelectedUsers([])
    updateCollabTable()
    fetchNotCollaborators()
    dispatch(setMessage('You have successfully add collaborate form'))
    dispatch(setShowPopupSuccess(true))
  }

  const handleOnDeleteActionClick = data => {
    setDeleteSoonCollaborator(data)
    setShowDeleteModal(true)
  }

  const deleteCollaborator = () => {
    del(`/api/dynamic-forms/${formId}/collaborators/${deleteSoonCollaborator.id}`).then(
      response => {
        const { status } = response
        if (status === 200) {
          handleOnSuccessDeleteCollaborator()
        } else {
          toast.error(<Toast message={`Error`} detailedMessage={`Failed to delete collaborator`} />)
        }
      }
    )
  }

  const handleOnSuccessDeleteCollaborator = () => {
    updateCollabTable()
    fetchNotCollaborators()
    dispatch(setMessage('You have successfully remove collaborate'))
    dispatch(setShowPopupSuccess(true))
  }

  const updateCollabTable = () => {
    setUpdateCollaborator(prev => {
      return !prev
    })
  }

  return (
    <>
      <input
        type="checkbox"
        id="collaborator-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white w-fit md:w-[780px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Collaborate Form</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={handleCloseModal}
            />
          </div>
          <hr className="my-4" />
          <div className="flex flex-row items-center px-6 py-4 bg-[#C800A518] rounded-2xl">
            <div className="font-normal text-[12px] text-[#232323]">Form Name:</div>
            <div className="font-semibold text-[12px] text-[#C800A5] ml-4">{formTitle}</div>
          </div>
          {/* Suggestion Section */}
          <div className="flex flex-row items-center mt-[30px] gap-4">
            <ReactSelect
              className="flex flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]"
              components={{ DropdownIndicator }}
              value={selectedUsers}
              onChange={selectedOptions => handleOnUserChange(selectedOptions)}
              options={userOptions}
              getOptionLabel={option => option.full_name}
              getOptionValue={option => option.full_name}
              name="Collaborator"
              placeholder="Select user to add collaborate"
              isMulti={true}
              styles={{
                option: (provided, state) => ({
                  ...provided
                }),
                control: provided => ({
                  ...provided,
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none',
                  flex: 1
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  color: '#C800A5',
                  ':hover': { color: '#6546C3' }
                }),
                indicatorSeparator: () => ({}),
                valueContainer: provided => ({
                  ...provided
                }),
                multiValueLabel: provided => ({
                  ...provided,
                  color: '#fff',
                  fontFamily: 'poppins'
                }),
                multiValueRemove: provided => ({
                  ...provided,
                  color: '#fff'
                }),
                multiValue: provided => ({
                  ...provided,
                  backgroundColor: '#6546C3',
                  borderRadius: 8
                })
              }}
            />
            <button
              className="flex justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] p-3 text-[12px] text-white rounded-md"
              onClick={handleOnAddCollaboratorClick}
            >
              Add Collaborator
            </button>
          </div>
          <hr className="my-4" />
          <CollaboratorTable
            formId={formId}
            onDeleteActionClick={handleOnDeleteActionClick}
            updateData={updateCollaborator}
          />
          <RemoveCollabModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onRemoveClick={deleteCollaborator}
            deleteSoonData={deleteSoonCollaborator}
          />
          <SuccessPopup />
        </div>
      </div>
    </>
  )
}

export default CollaboratorModal
