import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Select, Toast, SuccessPopup, DropdownIndicator } from '../../../components'
import { post, get, put } from '../../../api/base'
import { ICONS, apiUrls } from '../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../redux/slices/popupSlice'

export default function MenuModal({
  showModal,
  isEdit,
  formData,
  setFormData,
  setIsEdit,
  setShowModal,
  on_success
}) {
  const params = useParams()
  const dispatch = useDispatch()
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      parent: null,
      name: null
    }
  })
  const handleCloseModal = () => {
    setIsEdit(false)
    setShowModal(false)
    setValue('name', null)
    setValue('parent', null)
  }

  const fetchParentsMenu = async () => {
    const { data } = await get(
      `${apiUrls.MICROSITES_ROUTE}/${params.microsite_route}/menus/parents`
    )
    const parentList = []
    data.map(v => {
      parentList.push({
        ...v,
        label: v.name,
        disabled: v.name === formData.name || (formData.children && formData.children.length > 0)
      })
    })

    //populate parent and name if isEdit true
    if (isEdit) {
      const parentValue = parentList.find(v => v.id === formData.parent_id)
      setValue('parent', parentValue)
      setValue('name', formData.name)
    }
    setParentOptions(parentList)
  }

  const [parentOptions, setParentOptions] = useState([])

  async function onSubmit({ name, parent }) {
    const payload = {
      name: name,
      parent_id: parent ? parent.id : null,
      menu_type: isEdit ? formData.menu_type : 'FORM'
    }

    if (isEdit) {
      const { ok, data } = await put(
        `${apiUrls.MICROSITES_ROUTE}/${params.microsite_route}/menus/${formData.id}`,
        payload
      )
      if (ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully update menu'))
        handleCloseModal()
        on_success()
        reset()
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      }
    } else {
      const { ok, data } = await post(
        `${apiUrls.MICROSITES_ROUTE}/${params.microsite_route}/menus`,
        payload
      )
      if (ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully add menu'))
        handleCloseModal()
        on_success()
        reset()
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      }
    }
    setFormData(null)
  }

  useEffect(() => {
    fetchParentsMenu()
  }, [isEdit])

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white w-fit md:w-[780px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">
              {isEdit ? 'Edit menu' : 'Add Menu'}
            </div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={handleCloseModal}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />

          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col py-4 rounded-2xl">
            <div>
              <div className="font-semibold text-[12px] mb-2">Menu Name</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter menu name"
                {...register('name', { required: true })}
              />
            </div>
            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">Parent</div>
              <Controller
                control={control}
                name="parent"
                render={({ field: { onChange, value } }) => (
                  <Select
                    components={{ DropdownIndicator }}
                    value={value}
                    onChange={selected => onChange(selected)}
                    options={parentOptions}
                    className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                    placeholder="Select parent"
                    isClearable={true}
                    styles={{
                      option: (provided, state) => ({
                        ...provided
                      }),
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({}),
                      valueContainer: provided => ({
                        ...provided
                      }),
                      multiValueLabel: provided => ({
                        ...provided,
                        color: '#fff',
                        fontFamily: 'poppins'
                      }),
                      multiValueRemove: provided => ({
                        ...provided,
                        color: '#fff'
                      }),
                      multiValue: provided => ({
                        ...provided,
                        backgroundColor: '#6546C3',
                        borderRadius: 8
                      })
                    }}
                  />
                )}
              />
            </div>
            <div className="flex justify-end gap-4 lg:col-span-3">
              <button
                type="button"
                onClick={() => handleCloseModal()}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Save
              </button>
            </div>
          </form>
          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
