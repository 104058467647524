import { useDispatch } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import {
  setHandleLeavePage,
  setShowPopupLeavePage
} from '../../../../../../redux/slices/popupSlice'

const SubMenu = ({ data, open }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const path = location.pathname
  let isActive = false
  const crumbs = data.path.split('/')
  if (data.path.split('/')[1] === path.split('/')[2]) isActive = true

  const handleMenuClick = () => {
    if (
      location.pathname === '/DocumentLibrary/Form/AddForm' ||
      location.pathname.startsWith('/DocumentLibrary/Form/EditForm')
    ) {
      dispatch(setShowPopupLeavePage(true))
      dispatch(setHandleLeavePage({ path: data.path, state: { title: data.name, crumbs: crumbs } }))
    } else {
      navigate(data.path, { state: { title: data.name, crumbs: crumbs } })
    }
  }

  return (
    <li className={`flex items-center cursor-pointer`} onClick={handleMenuClick}>
      <div
        className={`${
          isActive ? 'bg-[#C800A5] rounded-[3px]' : 'bg-white'
        }  h-[44px] w-[5px] ml-[4px] `}
      ></div>
      {open ? (
        <span
          className={`${
            isActive ? 'text-[#C800A5] bg-white font-semibold' : 'text-white'
          } font-poppins text-[14px]  py-[10px] pl-[31px] flex-auto mr-[10px] ml-[8px] rounded-lg`}
        >
          {data.name}
        </span>
      ) : (
        <></>
      )}
    </li>
  )
}

export default SubMenu
