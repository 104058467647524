import { images } from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { setShowPopupVerifyEmail } from '../../../redux/slices/popupSlice'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const VerifyEmailPopup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const show = useSelector(state => state.popup.showPopupVerifyEmail)
  const message = useSelector(state => state.popup.popupMessage)
  const location = useLocation()
  const param = useParams()

  const handleOnClose = () => {
    dispatch(setShowPopupVerifyEmail(false))
    if (location.pathname?.includes('Site')) {
      navigate(`/Site/${param?.microsite}/menu/${param?.menu}`, {
        state: { title: 'User Management', crumbs: ['User Management'] }
      })
    } else {
      navigate('/Admin/ManageUser', {
        state: { title: 'Manage User', crumbs: ['Admin', 'Manage User'] }
      })
    }
  }

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal bg-[#232323]/[0.5] flex flex-col gap-4 overflow-y-auto py-6">
        <button
          onClick={() => handleOnClose()}
          className="p-2 bg-white font-black text-[#C800A5] text-[8px] leading-[8px] rounded-full w-6 h-6"
        >
          ✕
        </button>
        <div
          className="modal-box bg-white relative w-full max-h-max max-w-[340px] py-[60px] px-0"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center min-w-[21.25rem]">
            <img src={images.success} className="w-[120px] h-[120px] mb-9" alt="success icon" />
            <span className="font-poppins font-semibold text-lg mb-1">Verify Your Email</span>
            <span className="font-poppins text-xs text-gray-500 text-center px-5">
              An email has been sent to <span className="text-[#C800A5]">{message}</span> with a
              link to verify your account
            </span>
          </div>
          <div className="flex justify-center mt-9">
            <button
              onClick={() => handleOnClose()}
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmailPopup
