import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ICONS, apiUrls } from '../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../redux/slices/popupSlice'
import { get } from '../../../api/base'
import { toast } from 'react-toastify'
import { Toast } from '../../../components'

export default function VisibilityModal({
  showModal,
  setShowModal,
  handleSaveWorkLocation,
  currentData
}) {
  const dispatch = useDispatch()
  const [workLocation, setWorkLocation] = useState([])
  const [listOfWorkLocation, setListOfWorkLocation] = useState([])
  const [searchWorkLocationQuery, setSearchWorkLocationQuery] = useState('')

  const handleOnChangeCheckWorkLocation = (id, event) => {
    let workLocationCopy = [...workLocation]
    if (event.target.checked) {
      workLocationCopy.push(id)
    } else {
      const index = workLocationCopy.indexOf(id)
      workLocationCopy.splice(index, 1)
    }

    setWorkLocation(workLocationCopy)
  }

  useEffect(() => {
    if (currentData?.length > 0 && listOfWorkLocation?.length > 0) setWorkLocation(currentData)
  }, [listOfWorkLocation])

  useEffect(() => {
    if (showModal) {
      fetchWorkLocation()
    }
  }, [showModal])

  const fetchWorkLocation = async () => {
    get(apiUrls.WORK_LOCATIONS).then(response => {
      const { status } = response
      if (status === 200) {
        setListOfWorkLocation(response.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch work locations'} />)
      }
    })
  }

  const formValidation = () => {
    if (!workLocation.length) {
      return false
    }
    return true
  }

  function onFormSubmit(e) {
    e.preventDefault()
    handleSaveWorkLocation(workLocation)

    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage(`You have successfully ${currentData ? 'edit' : 'set'} visibility`))
    close()
  }

  function close() {
    setShowModal(false)
    setWorkLocation([])
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white max-w-full w-fit md:w-[420px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Visibility</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="mt-4 mb-6" />

          <form onSubmit={onFormSubmit} className="flex flex-col">
            <div className="flex flex-col flex-1 h-60">
              <div className="flex-row flex-1 rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
                <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
                  <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
                    Work Location
                  </div>
                  {workLocation.length > 0 ? (
                    <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
                      {workLocation.length} Work Location Selected
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex-row px-4 py-3">
                  <div className={`flex items-center relative mb-4 overflow-hidden`}>
                    <input
                      type="text"
                      placeholder="Search here"
                      className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                      value={searchWorkLocationQuery}
                      onChange={event => {
                        setSearchWorkLocationQuery(event.target.value)
                      }}
                    />
                    <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" />
                  </div>
                  <div className="flex-row h-[100px] overflow-y-auto scrollbar">
                    {listOfWorkLocation
                      .filter(loc => {
                        return loc.name
                          .toLowerCase()
                          .match(new RegExp(searchWorkLocationQuery.toLowerCase(), ''))
                      })
                      .map(loc => (
                        <div key={loc.id} className="flex items-center gap-3 mb-4">
                          <input
                            type="checkbox"
                            className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                            checked={workLocation?.includes(loc.id)}
                            onChange={event => handleOnChangeCheckWorkLocation(loc.id, event)}
                          />
                          <div className="flex flex-1 text-[12px]">{loc.name}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4 mt-16">
              <button
                type="button"
                onClick={close}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={!formValidation()}
                className="py-3 px-8 disabled:bg-gray-400 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
