const icEyeOpen = require('../assets/icons/ic_eye_open.png')
const icEyeClose = require('../assets/icons/ic_eye_close.png')
const icCloseRound = require('../assets/icons/ic_close_round.png')
const icCloseRoundRed = require('../assets/icons/ic_close_round_red.png')
const icCollapseExpand = require('../assets/icons/ic_collapsexpand.png')
const icCollapseExpandGreen = require('../assets/icons/ic_collapsexpand_green.png')
const icHome = require('../assets/icons/ic_home.png')
const icHomeActive = require('../assets/icons/ic_home_active.png')
const icActivity = require('../assets/icons/ic_activity.png')
const icActivityActive = require('../assets/icons/ic_activity_active.png')
const icDocument = require('../assets/icons/ic_document.png')
const icDocumentActive = require('../assets/icons/ic_document_active.png')
const icAdmin = require('../assets/icons/ic_admin.png')
const icAdminActive = require('../assets/icons/ic_admin_active.png')
const icChevronDown = require('../assets/icons/ic_chevron_down.png')
const icChevronUp = require('../assets/icons/ic_chevron_up.png')
const icNotification = require('../assets/icons/ic_notification.png')
const icChevronDownSecondary = require('../assets/icons/ic_chevron_down_secondary.png')
const icEdit = require('../assets/icons/ic_edit.png')
const icEditTeal = require('../assets/icons/ic_edit_teal.png')
const icSort = require('../assets/icons/ic_sort.png')
const icSortUp = require('../assets/icons/ic_sort_up.png')
const icSortDown = require('../assets/icons/ic_sort_down.png')
const icSearch = require('../assets/icons/ic_search.png')
const icChecklistAction = require('../assets/icons/ic_checklist_action.png')
const icRemoveForm = require('../assets/icons/ic_remove_form.png')
const icDetail = require('../assets/icons/ic_detail.png')
const icDetailTeal = require('../assets/icons/ic_detail_teal.png')
const icTrash = require('../assets/icons/ic_trash.png')
const icCloseRedShadow = require('../assets/icons/ic_close_red_shadow.png')
const icAdd = require('../assets/icons/ic_add.png')
const icPen = require('../assets/icons/ic_pen.png')
const icArrowDown = require('../assets/icons/ic_arrow_down.png')
const icCalendar = require('../assets/icons/ic_calendar.png')
const icM2pApps = require('../assets/icons/ic_m2p_apps.png')
const icOpen = require('../assets/icons/ic_open.png')
const icDownload = require('../assets/icons/ic_download.png')
const icCevRightBg = require('../assets/icons/ic_cev_right_bg.png')
const icAndroidRounded = require('../assets/icons/ic_android_rounded.png')
const icSuccess = require('../assets/icons/ic_success.png')
const icCollaborator = require('../assets/icons/ic_collaborator.png')
const icCloseModal = require('../assets/icons/ic_close_modal.png')
const icTeams = require('../assets/icons/ic_teams.webp')
const icWa = require('../assets/icons/ic_wa.webp')
const icClock = require('../assets/icons/ic_clock.webp')
const icInfo = require('../assets/icons/ic_info.png')
const icDoc = require('../assets/icons/ic_doc.png')
const icPpt = require('../assets/icons/ic_ppt.png')
const icXls = require('../assets/icons/ic_xls.png')
const icTrashNoBg = require('../assets/icons/ic_trash_no_bg.png')
const icDefaultImage = require('../assets/icons/ic_default_image.png')
const icAddMenuList = require('../assets/icons/ic_add_menu_list.webp')
const icAction = require('../assets/icons/ic_action.webp')
const icAddWhite = require('../assets/icons/ic_add_white.webp')
const icEditWhite = require('../assets/icons/ic_edit_white.webp')
const icArrowUpMasterData = require('../assets/icons/ic_arrow_up_master_data.png')
const icArrowDownMasterData = require('../assets/icons/ic_arrow_down_master_data.png')
const icBulletPurple = require('../assets/icons/bullet-purple.png')
const icBulletRed = require('../assets/icons/bullet-red.png')
const icDocumentGreen = require('../assets/icons/ic_document_green.webp')
const icDocumentOrange = require('../assets/icons/ic_document_orange.webp')
const icDocumentPurple = require('../assets/icons/ic_document_purple.webp')
const icTag = require('../assets/icons/ic_tag.webp')
const icForm = require('../assets/icons/ic_form.png')
const icFormGreen = require('../assets/icons/ic_form_green.png')
const icHeartBeat = require('../assets/icons/ic_heartbeat.png')
const icHeartBeatGreen = require('../assets/icons/ic_heartbeat_green.png')
const icArrowDownSite = require('../assets/icons/ic_arrow_down_site.png')
const icSettings = require('../assets/icons/ic_settings.webp')
const icRewind = require('../assets/icons/ic_rewind.webp')
const icHtml5 = require('../assets/icons/ic_html_5.png')
const icHtml5Green = require('../assets/icons/ic_html_5_green.png')
const icDuplicate = require('../assets/icons/ic_duplicate.png')
const icGroup = require('../assets/icons/ic_group.webp')
const icFullScreen = require('../assets/icons/ic_fullscreen.png')
const icHelp = require('../assets/icons/ic_help.png')
const icApp = require('../assets/icons/ic_app.png')
const icDefaultModule = require('../assets/icons/ic_default_module.png')
const icParameter = require('../assets/icons/ic_parameter.png')
const icTextField = require('../assets/icons/ic_textfield.png')
const icAddContent = require('../assets/icons/ic_add_content.webp')
const icCloseContent = require('../assets/icons/ic_close_content.webp')
const icMerge = require('../assets/icons/ic_merge.webp')
const icSplitHorizontal = require('../assets/icons/ic_split_horizontal.webp')
const icSplitVertical = require('../assets/icons/ic_split_vertical.webp')
const icInfoSecondary = require('../assets/icons/ic_info_secondary.png')
const icAdministrator = require('../assets/icons/ic_administrator.png')
const icAdministratorGreen = require('../assets/icons/ic_administrator_green.png')
const icEditTransparent = require('../assets/icons/ic_edit_transparent.png')
const icDetailTransparent = require('../assets/icons/ic_detail_transparent.png')
const icDuplicateTransparent = require('../assets/icons/ic_duplicate_transparent.png')
const icFilter = require('../assets/icons/ic_filter.png')
const icDrag = require('../assets/icons/ic_drag.png')
const icTable = require('../assets/icons/ic_table.png')
const icTableGreen = require('../assets/icons/ic_table_green.png')
const icList = require('../assets/icons/ic_list.png')
const icListGreen = require('../assets/icons/ic_list_green.png')
const icFilterGray = require('../assets/icons/ic_filter_gray.png')
const icAdvanceSearch = require('../assets/icons/ic_advance_search.png')
const icSelectionField = require('../assets/icons/ic_selection_field.png')
const icMore = require('../assets/icons/ic_more.png')

export default {
  icEyeOpen,
  icEyeClose,
  icCloseRound,
  icCloseRoundRed,
  icCollapseExpand,
  icCollapseExpandGreen,
  icHome,
  icHomeActive,
  icActivity,
  icActivityActive,
  icDocument,
  icDocumentActive,
  icAdmin,
  icAdminActive,
  icChevronDown,
  icChevronUp,
  icNotification,
  icChevronDownSecondary,
  icEdit,
  icEditTeal,
  icSort,
  icSortUp,
  icSortDown,
  icSearch,
  icRemoveForm,
  icChecklistAction,
  icRemoveForm,
  icDetail,
  icDetailTeal,
  icTrash,
  icCloseRedShadow,
  icAdd,
  icPen,
  icArrowDown,
  icCalendar,
  icM2pApps,
  icOpen,
  icDownload,
  icCevRightBg,
  icAndroidRounded,
  icSuccess,
  icCollaborator,
  icCloseModal,
  icTeams,
  icWa,
  icClock,
  icInfo,
  icDoc,
  icXls,
  icPpt,
  icTrashNoBg,
  icDefaultImage,
  icAddMenuList,
  icAction,
  icAddWhite,
  icEditWhite,
  icArrowUpMasterData,
  icArrowDownMasterData,
  icBulletPurple,
  icBulletRed,
  icDocumentGreen,
  icDocumentOrange,
  icDocumentPurple,
  icTag,
  icForm,
  icFormGreen,
  icHeartBeat,
  icHeartBeatGreen,
  icArrowDownSite,
  icSettings,
  icRewind,
  icHtml5,
  icHtml5Green,
  icDuplicate,
  icGroup,
  icFullScreen,
  icHelp,
  icApp,
  icDefaultModule,
  icParameter,
  icTextField,
  icAddContent,
  icCloseContent,
  icMerge,
  icSplitHorizontal,
  icSplitVertical,
  icInfoSecondary,
  icAdministrator,
  icAdministratorGreen,
  icEditTransparent,
  icDetailTransparent,
  icDuplicateTransparent,
  icFilter,
  icDrag,
  icTable,
  icTableGreen,
  icList,
  icListGreen,
  icFilterGray,
  icAdvanceSearch,
  icSelectionField,
  icMore
}
