import ImageDelete from '../../../assets/images/confirmation.png'
import { useDispatch, useSelector } from 'react-redux'
import { setShowPopupDelete } from '../../../redux/slices/popupSlice'

const DeletePopup = ({ setContinueDelete, buttonNext }) => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.popup.showPopupDelete)
  const message = useSelector(state => state.popup.popupMessage)

  function handleDelete() {
    setContinueDelete(true)
    dispatch(setShowPopupDelete(false))
  }

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal bg-[#232323]/[0.5] overflow-y-auto py-6">
        <div
          className="modal-box bg-white relative w-full max-h-max max-w-max py-[60px] px-0"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center min-w-[21.25rem]">
            <img src={ImageDelete} className="w-[120px] h-[120px] mb-9" alt="leave page icon" />
            <span className="font-semibold text-lg mb-1">Delete</span>
          </div>
          <div className="flex justify-center">
            <div className="text-xs text-[#AAAAAA] break-words w-[200px] text-center">
              {`${message}`}
            </div>
          </div>
          <div className="flex justify-center gap-4 mt-9">
            <button
              onClick={() => dispatch(setShowPopupDelete(false))}
              className="py-3 px-8 border-[2px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={() => handleDelete()}
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            >
              {buttonNext || 'Delete'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeletePopup
