import MyActivityTable from './MyActivityTable'

const MyActivity = () => {
  return (
    <div className="bg-white rounded-2xl p-2 mx-2 sm:p-6 xl:p-0 xl:px-6 sm:mx-6 my-2">
      <MyActivityTable />
    </div>
  )
}

export default MyActivity
