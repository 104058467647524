import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ICONS } from '../../../constants'
import { selectSites } from '../../../redux/slices/micrositeSlice'

import Menu from './Menu'

const SideNav = () => {
  const params = useParams()
  const [open, setOpen] = useState(true)
  const site = useSelector(selectSites).find(
    s => s.route_name.toLowerCase() === params.microsite.toLowerCase()
  )
  const menus = site.menus.map(menu => ({
    name: menu.name,
    path: menu.route_name,
    type: menu.menu_type,
    subMenus: menu.children.map(child => ({
      name: child.name,
      path: child.route_name
    }))
  }))

  return (
    <div
      className="duration-300 py-4 rounded-3xl hidden sm:block"
      style={{
        background: site?.bottom_color
          ? `linear-gradient(to bottom, ${site?.top_color}, ${site?.bottom_color})`
          : site?.top_color || `linear-gradient(to bottom, #01a76b, #00B1B5)`
      }}
    >
      <div className={`${open ? 'w-[252px] px-4' : 'w-[72px]'} `}>
        <div
          className={`flex bg-white rounded-xl relative mb-6 p-2 ${
            open ? 'justify-between' : 'justify-center mx-2'
          }`}
        >
          {open && (
            <div className="mr-2">
              <img src={site.logo_url} className="max-h-[100px] cursor-pointer" alt="logo" />
            </div>
          )}
          <img
            src={ICONS.icCollapseExpandGreen}
            className="w-6 h-6 cursor-pointer"
            onClick={() => setOpen(!open)}
            alt="open menu"
          />
        </div>

        <ul className="pt-2">
          {menus.length > 0
            ? menus.map((menu, index) => (
                <Menu data={menu} key={index} open={open} textColor={site?.top_color} />
              ))
            : null}
        </ul>
      </div>
    </div>
  )
}

export default SideNav
