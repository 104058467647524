import ApplicationList from './ApplicationList'

const ApplicationProfile = () => {
  return (
    <div className="bg-white rounded-2xl p-6 mx-2 sm:mx-6 my-2">
      <ApplicationList />
    </div>
  )
}

export default ApplicationProfile
