import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import { DropdownIndicator, Select as Dropdown, Toast } from '../../../../../components'
import { ICONS, apiUrls } from '../../../../../constants'
import { selectFields } from '../../../../../redux/slices/masterSlice'
import { FormContext, MasterDataContext, TagsContext } from '../..'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, AtomicBlockUtils } from 'draft-js'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import { draftLogic } from '../../../../../constants'
import ApiDetailModal from '../ApiDetailModal'
import ShowDataApiDetailModal from '../ApiDetailModal/ShowDataApiDetailModal'
import { toast } from 'react-toastify'
import MasterStockModal from '../MasterStockModal'
import { get } from '../../../../../api/base'
import { Mention, MentionsInput } from 'react-mentions'

const Field = ({
  index,
  data,
  editable,
  multipleInput,
  tabular,
  checkStock,
  onDelete,
  onDeleteMasterStock,
  handleChangeSection,
  firstIdx,
  secondIdx,
  parentName,
  handleOnAddField,
  handleOnAddFieldMasterStock,
  handleOnChangeMandatoryMasterStock,
  handleAfterFieldTypeChange,
  isSubSection,
  hasSubSections,
  hasMtopFormField,
  fieldsForTabular,
  fieldsForOperand,
  validationBeforeChangeFieldData,
  hasCalculatedSubtotalField
}) => {
  const fieldData = useRef(data)
  const fieldId = useRef(index)
  const { formType } = useContext(FormContext)
  const [additionalOptions, setAdditionalOptions] = useState(fieldData.current.additional_option)
  const [options, setOptions] = useState(fieldData.current.options)
  const [selectedFieldTypeOption, setSelectedFieldTypeOption] = useState(data.field_type)
  const fields = useSelector(selectFields)
  const [fieldTypeOptions, setFieldTypeOptions] = useState([])
  const [selectedKeyboardTypeOption, setSelectedKeyboardTypeOption] = useState(data.keyboard_type)
  const [keyboardTypeOptions, setKeyboardTypeOptions] = useState([
    { value: 'DEFAULT', label: 'Default' },
    { value: 'NUMERIC', label: 'Numeric' }
  ])
  const [selectedDatePickerTypeOption, setSelectedDatePickerTypeOption] = useState(
    data.date_picker_type
  )
  const [datePickerTypeOptions, setDatePickerTypeOptions] = useState([
    { value: 'DATE', label: 'Date' },
    { value: 'DATE_TIME', label: 'Date/Time' },
    { value: 'TIME', label: 'Time' }
  ])
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [selectedMaximumOption, setSelectedMaximumOption] = useState(
    fieldData.current?.maximum_option || null
  )
  const selectedMaximumOptionLength = Array.from(
    { length: selectedMaximumOption },
    (_, index) => index
  )
  const [maximumOptions] = useState([
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 5, label: '5' },
    { value: 10, label: '10' }
  ])
  const [iconOptions] = useState([
    { value: 'U+1F600', label: '😀' },
    { value: 'U+1F604', label: '😄' },
    { value: 'U+1F606', label: '😆' },
    { value: 'U+1F642', label: '🙂' },
    { value: 'U+1F60D', label: '😍' },
    { value: 'U+1F929', label: '🤩' },
    { value: 'U+1F610', label: '😐' },
    { value: 'U+1F611', label: '😑' },
    { value: 'U+1F641', label: '🙁' },
    { value: 'U+1F62D', label: '😭' },
    { value: 'U+1F620', label: '😠' },
    { value: 'U+1F44D', label: '👍' },
    { value: 'U+1F44E', label: '👎' },
    { value: 'U+2764', label: '❤️' },
    { value: 'U+2B50', label: '⭐' },
    { value: 'U+274e', label: '❎' },
    { value: 'U+2705', label: '✅' }
  ])
  const [selectedIcons, setSelectedIcons] = useState(
    fieldData.current.options.map(item => iconOptions.find(icon => icon.value === item))
  )
  const [selectAllIcon, setSelectAllIcon] = useState(
    fieldData.current.options.length === 1 &&
      selectedMaximumOption > fieldData.current.options.length
  )
  const [mandatoryField, setMandatoryField] = useState(fieldData.current?.mandatory || false)
  const [selectedFormSystem, setSelectedFormSystem] = useState(data.form_system || null)
  const [formSystemOptions, setFormSystemOptions] = useState([])
  const [showMtopFormModal, setShowMtopFormModal] = useState(false)
  const [fieldFormula, setFieldFormula] = useState(
    fieldData.current?.field_formula?.formula || undefined
  )
  const [operandFields, setOperandFields] = useState(
    fieldData.current?.field_formula?.operand_fields || []
  )
  const [fieldTitle, setFieldTitle] = useState(fieldData.current?.field_title || '')
  const [selectedFieldSubtotal, setSelectedFieldSubtotal] = useState(
    fieldData.current?.field_subtotal || null
  )

  const additionalOptionChecked = additionalOptions.length > 0
  const showKeyboardTypeDropdown =
    selectedFieldTypeOption &&
    (selectedFieldTypeOption.value === 'TEXT_FIELD' ||
      selectedFieldTypeOption.value === 'TEXT_AREA' ||
      selectedFieldTypeOption.value === 'MASTER_STOCK_AMOUNT' ||
      selectedFieldTypeOption.value === 'STOCK_IN_TRANSACTION')
      ? true
      : false
  const showDatePickerTypeDropdown =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'DATE_PICKER' ? true : false
  const showDateFormat =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'DATE_PICKER' ? true : false
  const showOptions =
    selectedFieldTypeOption &&
    (selectedFieldTypeOption.value === 'RADIO_BUTTON' ||
      selectedFieldTypeOption.value === 'CHECKBOX' ||
      selectedFieldTypeOption.value === 'DROPDOWN' ||
      selectedFieldTypeOption.value === 'MASTER_STOCK' ||
      selectedFieldTypeOption.value === 'STOCK_UNIT')
      ? true
      : false
  const showSignHere =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'SIGNATURE' ? true : false
  const showMandatoryAttachmentOptions =
    selectedFieldTypeOption &&
    (selectedFieldTypeOption.value === 'CHECKBOX_ATTACHMENT' ||
      selectedFieldTypeOption.value === 'RADIO_BUTTON_ATTACHMENT')
      ? true
      : false
  const showMasterDataOptions =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'DROPDOWN_MASTER_DATA'
  const showStaticField =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'STATIC_FIELD' ? true : false
  const showRateByIcon =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'RATE_BY_ICON' ? true : false
  const isMasterStockFields =
    selectedFieldTypeOption &&
    (selectedFieldTypeOption?.value === 'MASTER_STOCK' ||
      selectedFieldTypeOption?.value === 'MASTER_STOCK_AMOUNT' ||
      selectedFieldTypeOption?.value === 'STOCK_IN_TRANSACTION' ||
      selectedFieldTypeOption?.value === 'STOCK_UNIT' ||
      selectedFieldTypeOption?.value === 'SECTION_SEQ' ||
      selectedFieldTypeOption?.value === 'M2P_FORM')
      ? true
      : false
  const showFormSystemDropdown =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'M2P_FORM' ? true : false
  const showFormulaInput =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'CALCULATED_FIELD' ? true : false
  const showFieldForCalculatedSubtotal =
    selectedFieldTypeOption && selectedFieldTypeOption.value === 'CALCULATED_SUBTOTAL'
      ? true
      : false

  const formulaInputStyle = {
    control: {
      backgroundColor: '#fff',
      fontSize: 12,
      fontWeight: 'normal'
    },

    '&multiLine': {
      control: {
        fontFamily: 'poppins',
        minHeight: 80
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent'
      },
      input: {
        padding: 9,
        border: '1px solid silver',
        borderRadius: '8px',
        outline: 'none'
      }
    },

    '&singleLine': {
      display: 'inline-block',
      width: 180,

      highlighter: {
        padding: 1,
        border: '2px inset transparent'
      },
      input: {
        padding: 1,
        border: '2px inset'
      }
    },

    suggestions: {
      marginTop: '18px',
      list: {
        backgroundColor: '#232323',
        border: '1px solid rgba(0,0,0,0.15)',
        borderRadius: 8,
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        color: 'white'
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          borderRadius: 8,
          backgroundColor: '#9E7CE3'
        }
      }
    }
  }

  useEffect(() => {
    populateFieldTypeOptions(fields)
  }, [fields, formType, multipleInput])

  useEffect(() => {
    if (selectedFieldTypeOption?.value === 'M2P_FORM') populateFormSystem()
  }, [selectedFieldTypeOption])

  useEffect(() => {
    setMandatoryField(fieldData.current?.mandatory)
  }, [fieldData.current?.mandatory])

  // Additional Option Handling
  const handleOnAdditionalOptionValueChange = (index, event) => {
    const newValue = event.target.value
    let newAdditionalOptions = additionalOptions.map((additionalOption, i) =>
      index === i ? { ...additionalOption, title: newValue } : additionalOption
    )
    fieldData.current.additional_option = newAdditionalOptions
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setAdditionalOptions(newAdditionalOptions)
  }

  const handleOnDeleteAdditionalOption = index => {
    const newAdditionalOptions = additionalOptions.filter((additionalOption, i) => i !== index)
    fieldData.current.additional_option = newAdditionalOptions
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setAdditionalOptions(newAdditionalOptions)
  }

  const handleOnAddAdditionalOption = () => {
    let newAdditionalOption
    switch (selectedFieldTypeOption.value) {
      case 'RADIO_BUTTON':
      case 'CHECKBOX':
      case 'DROPDOWN':
      case 'CHECKBOX_ATTACHMENT':
      case 'RADIO_BUTTON_ATTACHMENT':
        newAdditionalOption = {
          title: '',
          options: options
        }
        break
      default:
        newAdditionalOption = {
          title: '',
          value: ''
        }
        break
    }
    const newAdditionalOptions = [...fieldData.current.additional_option, newAdditionalOption]
    fieldData.current.additional_option = newAdditionalOptions
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setAdditionalOptions(newAdditionalOptions)
  }

  const removeAllAdditionalOptions = () => {
    fieldData.current.additional_option = []
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setAdditionalOptions([])
  }

  const handleAdditionalOptionCheck = event => {
    if (event.target.checked) {
      handleOnAddAdditionalOption()
    } else {
      removeAllAdditionalOptions()
    }
  }

  // Option Handling

  const handleOnOptionValueChange = (index, event) => {
    const newValue = event.target.value
    let newOptions, newAdditionalOptions
    switch (selectedFieldTypeOption.value) {
      case 'CHECKBOX':
      case 'CHECKBOX_ATTACHMENT':
        newOptions = options.map((option, i) =>
          index === i ? { ...option, label: newValue } : option
        )
        // TODO: Update additional option for checkbox
        newAdditionalOptions = additionalOptions.map(additionalOption => {
          return { ...additionalOption, options: newOptions }
        })
        fieldData.current.additional_option = newAdditionalOptions
        handleChangeSection(
          parentName,
          'fields',
          firstIdx,
          secondIdx,
          fieldId.current,
          fieldData.current
        )
        break
      case 'RADIO_BUTTON':
      case 'RADIO_BUTTON_ATTACHMENT':
        newOptions = options.map((option, i) =>
          index === i ? { ...option, label: newValue, value: newValue } : option
        )
        // TODO: Update additional option for checkbox
        newAdditionalOptions = additionalOptions.map(additionalOption => {
          return { ...additionalOption, options: newOptions }
        })
        fieldData.current.additional_option = newAdditionalOptions
        handleChangeSection(
          parentName,
          'fields',
          firstIdx,
          secondIdx,
          fieldId.current,
          fieldData.current
        )
        break
        break
      default:
        newOptions = options.map((option, i) =>
          index === i ? { ...option, label: newValue, value: newValue } : option
        )
        break
    }
    fieldData.current.options = newOptions
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setOptions(newOptions)
  }

  const handleMandatoryAttachmentOptionChange = (index, event) => {
    const checked = event.target.checked
    let newOptions, newAdditionalOptions
    newOptions = options.map((option, i) =>
      index === i ? { ...option, mandatory: checked } : option
    )
    // TODO: Update additional option for checkbox
    newAdditionalOptions = additionalOptions.map(additionalOption => {
      return { ...additionalOption, options: newOptions }
    })
    fieldData.current.additional_option = newAdditionalOptions
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )

    fieldData.current.options = newOptions
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setOptions(newOptions)
  }

  const handleOnDeleteOption = index => {
    const newOptions = options.filter((option, i) => i !== index)
    fieldData.current.options = newOptions
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setOptions(newOptions)

    switch (selectedFieldTypeOption.value) {
      case 'CHECKBOX':
      case 'RADIO_BUTTON':
      case 'CHECKBOX_ATTACHMENT':
      case 'RADIO_BUTTON_ATTACHMENT':
        // TODO: Update additional option for checkbox
        const newAdditionalOptions = additionalOptions.map(additionalOption => {
          return { ...additionalOption, options: newOptions }
        })
        fieldData.current.additional_option = newAdditionalOptions
        handleChangeSection(
          parentName,
          'fields',
          firstIdx,
          secondIdx,
          fieldId.current,
          fieldData.current
        )
        break
    }
  }

  const handleOnAddOption = () => {
    let newOption
    let newOptions
    let newAdditionalOptions
    switch (selectedFieldTypeOption.value) {
      case 'CHECKBOX':
        newOption = {
          label: '',
          value: false
        }
        newOptions = [...options, newOption]
        // TODO: Update additional option for checkbox
        newAdditionalOptions = additionalOptions.map(additionalOption => {
          return { ...additionalOption, options: newOptions }
        })
        fieldData.current.additional_option = newAdditionalOptions
        handleChangeSection(
          parentName,
          'fields',
          firstIdx,
          secondIdx,
          fieldId.current,
          fieldData.current
        )
        break
      case 'RADIO_BUTTON':
        newOption = {
          label: '',
          value: ''
        }
        newOptions = [...options, newOption]
        // TODO: Update additional option for checkbox
        newAdditionalOptions = additionalOptions.map(additionalOption => {
          return { ...additionalOption, options: newOptions }
        })
        fieldData.current.additional_option = newAdditionalOptions
        handleChangeSection(
          parentName,
          'fields',
          firstIdx,
          secondIdx,
          fieldId.current,
          fieldData.current
        )
        break
      case 'CHECKBOX_ATTACHMENT':
        newOption = {
          label: '',
          value: false,
          mandatory: false
        }
        newOptions = [...options, newOption]
        // TODO: Update additional option for checkbox
        newAdditionalOptions = additionalOptions.map(additionalOption => {
          return { ...additionalOption, options: newOptions }
        })
        fieldData.current.additional_option = newAdditionalOptions
        handleChangeSection(
          parentName,
          'fields',
          firstIdx,
          secondIdx,
          fieldId.current,
          fieldData.current
        )
        break
      case 'RADIO_BUTTON_ATTACHMENT':
        newOption = {
          label: '',
          value: '',
          mandatory: false
        }
        newOptions = [...options, newOption]
        // TODO: Update additional option for checkbox
        newAdditionalOptions = additionalOptions.map(additionalOption => {
          return { ...additionalOption, options: newOptions }
        })
        fieldData.current.additional_option = newAdditionalOptions
        handleChangeSection(
          parentName,
          'fields',
          firstIdx,
          secondIdx,
          fieldId.current,
          fieldData.current
        )
        break
      default:
        newOption = {
          label: '',
          value: ''
        }
        newOptions = [...options, newOption]
        break
    }
    fieldData.current.options = newOptions
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setOptions(newOptions)
  }

  const removeAllOptions = () => {
    fieldData.current.options = []
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setOptions([])
  }

  const handleFieldTitleChange = event => {
    // validation related to field with type calculated field
    if (!isSubSection && !validationBeforeChangeFieldData(fieldData.current.field_id)) return

    fieldData.current.field_title = event.target.value
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setFieldTitle(event.target.value)
  }

  const handleFieldTypeChange = selectedOption => {
    // validation related to field with type calculated field
    if (!isSubSection && !validationBeforeChangeFieldData(fieldData.current.field_id)) return

    // check for field type master stock
    if (
      selectedOption.value === 'MASTER_STOCK' &&
      (fieldData.current.field_type?.value !== 'MASTER_STOCK' || !fieldData.current.field_type)
    ) {
      if (multipleInput) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Field Master Stock can only added when section is not a multiple input!`}
          />
        )
        return
      }
      if (hasSubSections) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Field Master Stock can only added when section does not have sub sections`}
          />
        )
        return
      }
      // add master stock amount, stock in transaction, stock unit field
      handleOnAddFieldMasterStock({ fieldIndex: index })
    }

    /* delete master stock amount, stock in transaction, stock unit field 
      if the type is changed from master stock to other */
    if (
      fieldData.current.field_type?.value === 'MASTER_STOCK' &&
      selectedOption.value !== 'MASTER_STOCK'
    ) {
      onDeleteMasterStock(index, true)
    }

    // check for field type m2p form
    // validation for check stock checkbox
    if (
      selectedOption.value !== 'M2P_FORM' &&
      fieldData.current.field_type?.value === 'M2P_FORM' &&
      checkStock
    ) {
      setSelectedFieldTypeOption(fieldData.current.field_type)
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Section with checked check stock must have a field with type M2P Form! Uncheck first if you want to change this field type!`}
        />
      )
      return
    }

    // validation for tabular checkbox
    if (
      ((selectedOption.value !== 'M2P_FORM' &&
        fieldData.current.field_type?.value === 'M2P_FORM') ||
        (selectedOption.value !== 'SECTION_SEQ' &&
          fieldData.current.field_type?.value === 'SECTION_SEQ') ||
        (selectedOption.value !== 'CALCULATED_FIELD' &&
          fieldData.current.field_type?.value === 'CALCULATED_FIELD')) &&
      tabular &&
      fieldsForTabular.length === 1
    ) {
      setSelectedFieldTypeOption(fieldData.current.field_type)
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Section with checked tabular must have a field with type M2P Form, Section_SEQ, or Calculated Field! Uncheck first if you want to change this field type!`}
        />
      )
      return
    }

    if (selectedOption.value === 'M2P_FORM' && hasMtopFormField) {
      setSelectedFieldTypeOption(fieldData.current.field_type || null)
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Section only allows one field with type M2P Form`}
        />
      )
      return
    }

    if (selectedOption.value === 'CALCULATED_SUBTOTAL' && hasCalculatedSubtotalField) {
      setSelectedFieldTypeOption(fieldData.current.field_type || null)
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Section only allows one field with type Calculated Subtotal`}
        />
      )
      return
    }

    if (!isSubSection) handleAfterFieldTypeChange()
    fieldData.current.field_type = selectedOption
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setSelectedFieldTypeOption(selectedOption)
    removeAllAdditionalOptions()
    removeAllOptions()
  }

  const handleKeyboardTypeChange = selectedOption => {
    // validation related to field with type calculated field
    if (!isSubSection && !validationBeforeChangeFieldData(fieldData.current.field_id)) return

    fieldData.current.keyboard_type = selectedOption
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setSelectedKeyboardTypeOption(selectedOption)
  }

  const handleDatePickerTypeChange = selectedOption => {
    fieldData.current.date_picker_type = selectedOption
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
    setSelectedDatePickerTypeOption(selectedOption)
  }

  const handleDateFormatChange = event => {
    fieldData.current.format = event.target.value
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
  }

  const handleMaximumOptionChange = selectedOption => {
    fieldData.current.maximum_option = selectedOption.value
    setSelectedMaximumOption(selectedOption.value)
  }

  const handleSetAllIconCheck = event => {
    setSelectAllIcon(event.target.checked)
    if (event.target.checked) {
      if (selectedIcons.length) {
        const firstNonEmptyIndex = selectedIcons.findIndex(
          icon => icon && icon.hasOwnProperty('value')
        )
        let newSelectedIcons = []
        newSelectedIcons.push(selectedIcons[firstNonEmptyIndex])

        let options = newSelectedIcons?.map(s => s.value)
        fieldData.current.options = options
        setSelectedIcons(newSelectedIcons)
      }
    }
  }

  const handleIconChange = (selectedOption, index) => {
    let currentSelectedIcons = [...selectedIcons]

    if (selectAllIcon) {
      if (currentSelectedIcons.length) {
        let newSelectedIcons = []
        newSelectedIcons.push(selectedOption)

        let options = newSelectedIcons?.map(s => s.value)
        fieldData.current.options = options
        setSelectedIcons(newSelectedIcons)
      }
    } else {
      currentSelectedIcons[index] = selectedOption
      let options = currentSelectedIcons.map(s => s.value)
      fieldData.current.options = options
      setSelectedIcons(currentSelectedIcons)
    }
  }

  const handleMandatoryField = event => {
    fieldData.current.mandatory = event.target.checked
    setMandatoryField(event.target.checked)
    if (selectedFieldTypeOption?.value === 'MASTER_STOCK') {
      handleOnChangeMandatoryMasterStock(index, event.target.checked)
    }
  }

  const handleFormSystemChange = selectedOption => {
    fieldData.current.form_system = selectedOption
    setSelectedFormSystem(selectedOption)
  }

  useEffect(() => {
    if (selectedFieldTypeOption?.value === 'CALCULATED_FIELD') {
      fieldData.current.field_formula = {
        formula: fieldFormula,
        operand_fields: operandFields.filter((obj, index, self) => {
          const isDuplicate = index !== self.findIndex(o => o.field_id === obj.field_id)
          const isFieldTitleIncluded = fieldFormula.includes(obj.field_title)
          return !isDuplicate && isFieldTitleIncluded
        })
      }
    } else {
      delete fieldData.current?.field_formula
    }
  }, [fieldFormula, operandFields, selectedFieldTypeOption])

  const handleFormulaChange = value => {
    if (value !== undefined) {
      setFieldFormula(value)
    }
  }

  const handleKeyDown = event => {
    const allowedCharacters = /^[a-zA-Z0-9#.+*/()%\s-]+$/
    const inputValue = event.key

    if (!inputValue.match(allowedCharacters)) {
      event.preventDefault()
    }
  }

  const handleFieldSubtotalChange = selectedOption => {
    fieldData.current.field_subtotal = selectedOption
    setSelectedFieldSubtotal(selectedOption)
  }

  const populateFieldTypeOptions = fields => {
    const filteredFields = fields.filter(field => {
      const filterMasterStock =
        (formType !== 'SYSTEM' || isSubSection) && field.code === 'MASTER_STOCK'
      const filterM2PForm =
        (formType === 'SYSTEM' || formType === 'LINKAGE' || isSubSection) &&
        field.code === 'M2P_FORM'
      const filterSectionSeq =
        (formType === 'SYSTEM' || formType === 'LINKAGE' || isSubSection || !multipleInput) &&
        field.code === 'SECTION_SEQ'
      const filterCalculatedField = isSubSection && field.code === 'CALCULATED_FIELD'
      const filterCalculatedSubtotal =
        (isSubSection || !multipleInput) && field.code === 'CALCULATED_SUBTOTAL'

      const shouldFilterOut =
        filterMasterStock ||
        filterM2PForm ||
        filterSectionSeq ||
        filterCalculatedField ||
        filterCalculatedSubtotal

      return !shouldFilterOut
    })

    const newFieldTypeOptions = filteredFields.map(field => {
      return { value: field.code, label: field.name }
    })
    setFieldTypeOptions(newFieldTypeOptions)
  }

  const populateFormSystem = () => {
    get(`${apiUrls.FORMS_URL}/master-stocks`).then(response => {
      if (response.status === 200) {
        setFormSystemOptions(response.data.data)
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={'Failed to fetch form system list'} />
        )
      }
    })
  }

  //====== STATIC FIELD ======\\

  // Populate description data to text editor
  useEffect(() => {
    if (fieldData.current?.description) {
      const contentState = convertFromHTML(draftLogic.DRAFT_TO_HTML)(fieldData.current.description)
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [])

  useEffect(() => {
    if (showStaticField) {
      // hide some icon from editor
      document.querySelector('[title="Monospace"]').style.display = 'none'
      document.querySelector('[title="Superscript"]').style.display = 'none'
      document.querySelector('[title="Subscript"]').style.display = 'none'
      document.querySelector('[title="Indent"]').style.display = 'none'
      document.querySelector('[title="Outdent"]').style.display = 'none'
    }
  }, [showStaticField])

  useEffect(() => {
    let data = convertToHTML(draftLogic.HTML_TO_DRAFT)(editorState.getCurrentContent())
    fieldData.current.description = data
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
  }, [editorState])

  const handleHeightChange = event => {
    fieldData.current.height = event.target.value
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
  }

  // Handling image upload in static field editor
  const handleImageUpload = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => {
        resolve({ data: { link: e.target.result } })
      }
      reader.onerror = e => {
        reject(e)
      }
      reader.readAsDataURL(file)
    })
  }

  // Handling paste image in static field editor
  const handlePastedFiles = files => {
    Array.from(files).forEach(file => {
      if (file.type.includes('image')) {
        const reader = new FileReader()
        reader.onload = () => {
          const imageDataUrl = reader.result

          const contentStateWithEntity = editorState
            .getCurrentContent()
            .createEntity('IMAGE', 'IMMUTABLE', { src: imageDataUrl })
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
          const editorStateWithEntity = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
          })
          const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorStateWithEntity,
            entityKey,
            ' '
          )
          const newState = EditorState.forceSelection(
            newEditorState,
            newEditorState.getCurrentContent().getSelectionAfter()
          )

          setEditorState(newState)
        }
        reader.readAsDataURL(file)
      }
    })
  }

  //====== MASTER DATA ======\\

  const { masterDataOptions, onAddMasterDataChild } = useContext(MasterDataContext)
  const { sourceMasterDataId, sourceMasterDataName } = data.master_data || {}
  const [selectedMasterData, setSelectedMasterData] = useState(
    sourceMasterDataId && sourceMasterDataName
      ? { id: sourceMasterDataId, name: sourceMasterDataName }
      : null
  )
  const [showApiDetailModal, setShowApiDetailModal] = useState(false)
  const [showDataApiDetailModal, setShowDataApiDetailModal] = useState(false)

  function handleMasterDataChange({ id, name }) {
    if (name === 'Other Master Data') {
      setShowApiDetailModal(true)
    }
    fieldData.current.master_data = {
      parentFieldId: fieldData.current.master_data?.parentFieldId || null,
      sourceMasterDataId: id,
      sourceMasterDataName: name,
      parents: []
    }
    onChangeSection()
    setSelectedMasterData({ id, name })
  }

  function handleAddChild() {
    onAddMasterDataChild(
      {
        parentFieldId: data.field_id,
        sourceMasterDataId: null,
        sourceMasterDataName: null,
        parents: [...data.master_data.parents, selectedMasterData]
      },
      handleOnAddField
    )
  }

  function onSubmitApiDetail(data) {
    fieldData.current.other_master_data = data
  }

  //====== TAGS ======\\

  const { tags } = useContext(TagsContext)
  const unselectedTags = tags.filter(t => !data.tags?.includes(t.value))

  const [isSegregateByTags, setIsSegregateByTags] = useState(data.is_segregate_by_tags || false)
  const [selectedTags, setSelectedTags] = useState(data.tags || [])

  function onTagSegregateChange({ target }) {
    fieldData.current.is_segregate_by_tags = target.checked
    onChangeSection()
    setIsSegregateByTags(target.checked)
  }

  function onTagChange(v) {
    const newTags = [...(fieldData.current.tags || []), v.value]
    fieldData.current.tags = newTags
    onChangeSection()
    setSelectedTags(newTags)
  }

  function onRemoveTag(index) {
    const newTags = [
      ...fieldData.current.tags.slice(0, index),
      ...fieldData.current.tags.slice(index + 1)
    ]
    fieldData.current.tags = newTags
    onChangeSection()
    setSelectedTags(newTags)
  }

  function onChangeSection() {
    handleChangeSection(
      parentName,
      'fields',
      firstIdx,
      secondIdx,
      fieldId.current,
      fieldData.current
    )
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between items-center gap-6 rounded-2xl border-[1px] border-[#DEDEDE] bg-white p-6">
        <div className="flex flex-1 flex-col gap-6">
          <div className="flex flex-1 flex-col sm:flex-row gap-6">
            {/* Left */}
            <div className="flex flex-1 flex-col gap-6">
              <div className="flex flex-1 gap-6">
                {/* Title */}
                <div className="flex flex-col flex-1 gap-2">
                  <div className="font-semibold text-[12px]">Field Title</div>
                  <input
                    type="text"
                    name="Field Title"
                    placeholder="Enter Field Title"
                    className="input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                    onChange={event => handleFieldTitleChange(event)}
                    defaultValue={fieldData.current.field_title}
                    value={fieldTitle}
                  />
                  {/* Check Additional */}
                  {!showMasterDataOptions && !showStaticField && !isMasterStockFields && (
                    <div className="flex items-center gap-2 mt-4">
                      <input
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                        checked={additionalOptionChecked}
                        onChange={event => handleAdditionalOptionCheck(event)}
                        disabled={!editable}
                      />
                      <div className="text-[12px]">Additional Text Option</div>
                    </div>
                  )}
                  {/* List Additional */}
                  <div className="flex flex-col gap-6 mt-4 max-w-sm">
                    {additionalOptions.map((additionalOption, index) => (
                      <div key={index} className="flex items-center gap-4">
                        <input
                          type="text"
                          name="Additional Text Option Value"
                          className="input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                          value={additionalOption.title}
                          onChange={event => handleOnAdditionalOptionValueChange(index, event)}
                          disabled={!editable}
                        />
                        <img
                          src={ICONS.icCloseRedShadow}
                          className="w-8 h-8 cursor-pointer"
                          onClick={
                            editable ? () => handleOnDeleteAdditionalOption(index) : () => {}
                          }
                        />
                      </div>
                    ))}
                    {additionalOptions && additionalOptions.length > 0 ? (
                      <div
                        className="flex items-center gap-1"
                        onClick={editable ? () => handleOnAddAdditionalOption() : () => {}}
                      >
                        <img src={ICONS.icAdd} className="w-8 h-8 cursor-pointer" />
                        <div className="text-[12px] text-[#6546C3]">Add Option</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* Type */}
                <div className="flex flex-col flex-1 gap-2">
                  <div className="font-semibold text-[12px]">Field Type</div>
                  <Select
                    components={{ DropdownIndicator }}
                    defaultValue={selectedFieldTypeOption}
                    value={selectedFieldTypeOption}
                    onChange={handleFieldTypeChange}
                    options={fieldTypeOptions}
                    name="Field Type"
                    className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Select field type"
                    isDisabled={
                      !editable ||
                      (showMasterDataOptions && data.master_data?.parents.length) ||
                      selectedFieldTypeOption?.value === 'MASTER_STOCK_AMOUNT' ||
                      selectedFieldTypeOption?.value === 'STOCK_IN_TRANSACTION' ||
                      selectedFieldTypeOption?.value === 'STOCK_UNIT'
                    }
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({})
                    }}
                  />

                  {!showStaticField && (
                    <>
                      <div className="flex items-center mt-3">
                        <input
                          type="checkbox"
                          className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                          checked={isSegregateByTags}
                          onChange={onTagSegregateChange}
                          disabled={!editable}
                        />
                        <div className="text-xs ml-2">Segregate by Tags?</div>
                      </div>
                      <div className="mt-3">
                        <div className="font-semibold text-xs mb-2">Search or Add New Tag</div>
                        <Dropdown
                          placeholder="Type here"
                          options={unselectedTags}
                          value={null}
                          onChange={onTagChange}
                          onCreateOption={value => onTagChange({ value })}
                          isCreatable
                          isDisabled={!editable}
                        />
                        <div className="flex flex-wrap text-xs mt-2">
                          {selectedTags.map((tag, t) => (
                            <div
                              className="flex items-center rounded bg-purple-700 text-white my-2 mr-2 p-2"
                              key={t}
                            >
                              {tag}
                              <img
                                src={ICONS.icCloseModal}
                                className="w-4 h-4 bg-white rounded-full ml-2 cursor-pointer"
                                alt="remove tag"
                                onClick={editable ? () => onRemoveTag(t) : () => {}}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Right */}
            <div className="flex flex-1 gap-6">
              {showKeyboardTypeDropdown ? (
                <>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Keyboard Type</div>
                    <Select
                      components={{ DropdownIndicator }}
                      defaultValue={selectedKeyboardTypeOption}
                      value={selectedKeyboardTypeOption}
                      onChange={handleKeyboardTypeChange}
                      options={keyboardTypeOptions}
                      name="Keyboard Type"
                      className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                      placeholder="Select keyboard type"
                      isDisabled={
                        !editable ||
                        selectedFieldTypeOption?.value === 'MASTER_STOCK_AMOUNT' ||
                        selectedFieldTypeOption?.value === 'STOCK_IN_TRANSACTION'
                      }
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: base => ({
                          ...base,
                          color: '#C800A5',
                          ':hover': { color: '#6546C3' }
                        }),
                        indicatorSeparator: () => ({})
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        onChange={event => handleMandatoryField(event)}
                        disabled={
                          !editable ||
                          selectedFieldTypeOption?.value === 'MASTER_STOCK_AMOUNT' ||
                          selectedFieldTypeOption?.value === 'STOCK_IN_TRANSACTION'
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {showDatePickerTypeDropdown ? (
                <div className="flex flex-col flex-1 gap-2">
                  <div className="font-semibold text-[12px]">Date Picker Type</div>
                  <Select
                    components={{ DropdownIndicator }}
                    defaultValue={selectedDatePickerTypeOption}
                    onChange={handleDatePickerTypeChange}
                    options={datePickerTypeOptions}
                    name="Date Picker Type"
                    className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Select keyboard type"
                    isDisabled={!editable}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({})
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {showDateFormat ? (
                <>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Format</div>
                    <input
                      type="text"
                      placeholder="Enter Date Format"
                      name="Format"
                      className="input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                      onChange={event => handleDateFormatChange(event)}
                      defaultValue={data.format}
                      disabled={!editable}
                    />
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        onChange={event => handleMandatoryField(event)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {showOptions ? (
                <>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Options</div>
                    <div className="flex flex-col gap-6">
                      {options.map((option, index) => (
                        <div key={index} className="flex items-center gap-4">
                          <input
                            type="text"
                            name="Option Value"
                            className="input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                            value={option.label}
                            onChange={event => handleOnOptionValueChange(index, event)}
                            disabled={!editable}
                          />
                          <img
                            src={ICONS.icCloseRedShadow}
                            className="w-8 h-8 cursor-pointer"
                            onClick={editable ? () => handleOnDeleteOption(index) : () => {}}
                          />
                        </div>
                      ))}
                      <div
                        className="flex items-center gap-1"
                        onClick={editable ? () => handleOnAddOption() : () => {}}
                      >
                        <img src={ICONS.icAdd} className="w-8 h-8 cursor-pointer" />
                        <div className="text-[12px] text-[#6546C3]">Add Option</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        onChange={event => handleMandatoryField(event)}
                        disabled={!editable || selectedFieldTypeOption?.value === 'STOCK_UNIT'}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {showMandatoryAttachmentOptions ? (
                <>
                  <div className="flex flex-1 gap-4">
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-[12px] items-center text-center">
                        Mandatory
                      </div>
                      <div className="flex flex-col gap-6">
                        {options.map((option, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-center gap-4 h-[40px]"
                          >
                            <input
                              id={index}
                              type="checkbox"
                              className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                              checked={option.mandatory}
                              onChange={event =>
                                handleMandatoryAttachmentOptionChange(index, event)
                              }
                              disabled={!editable}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 gap-2">
                      <div className="font-semibold text-[12px]">Options</div>
                      <div className="flex flex-col gap-6">
                        {options.map((option, index) => (
                          <div key={index} className="flex justify-start items-center gap-4">
                            <input
                              type="text"
                              name="Option Value"
                              className="input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                              value={option.label}
                              onChange={event => handleOnOptionValueChange(index, event)}
                              disabled={!editable}
                            />
                            <img
                              src={ICONS.icCloseRedShadow}
                              className="w-8 h-8 cursor-pointer"
                              onClick={editable ? () => handleOnDeleteOption(index) : () => {}}
                            />
                          </div>
                        ))}
                        <div
                          className="flex items-center gap-1"
                          onClick={editable ? () => handleOnAddOption() : () => {}}
                        >
                          <img src={ICONS.icAdd} className="w-8 h-8 cursor-pointer" />
                          <div className="text-[12px] text-[#6546C3]">Add Option</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Field Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        onChange={event => handleMandatoryField(event)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {showMasterDataOptions && (
                <>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Option</div>
                    <Dropdown
                      value={selectedMasterData}
                      onChange={handleMasterDataChange}
                      options={masterDataOptions}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      placeholder="Select option"
                      isDisabled={!editable || data.master_data?.parents.length}
                    />
                    {!!selectedMasterData ? (
                      selectedMasterData.name !== 'Other Master Data' ? (
                        <div
                          className="flex items-center gap-1 mt-2"
                          onClick={editable ? handleAddChild : () => {}}
                        >
                          <img
                            src={ICONS.icAdd}
                            className="w-8 h-8 cursor-pointer"
                            alt="add child"
                          />
                          <div className="text-[12px] text-[#6546C3]">Add Child</div>
                        </div>
                      ) : (
                        fieldData.current.other_master_data && (
                          <div className="flex gap-4">
                            <div className="bg-[#F2F5FC] rounded-lg w-10 h-10 flex items-center justify-center">
                              <img
                                src={ICONS.icEdit}
                                className="w-7 cursor-pointer"
                                onClick={editable ? () => setShowApiDetailModal(true) : () => {}}
                              />
                            </div>
                            <button
                              onClick={() => setShowDataApiDetailModal(true)}
                              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                            >
                              Show Data
                            </button>
                          </div>
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        onChange={event => handleMandatoryField(event)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                </>
              )}
              {showStaticField && (
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-[12px]">Height</div>
                  <input
                    type="number"
                    name="Height"
                    placeholder="Enter height"
                    className="input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                    onChange={event => handleHeightChange(event)}
                    defaultValue={fieldData.current.height}
                    disabled={!editable}
                  />
                </div>
              )}
              {showRateByIcon && (
                <>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Maximum Option</div>
                    <Select
                      components={{ DropdownIndicator }}
                      value={maximumOptions.find(option => option.value === selectedMaximumOption)}
                      onChange={handleMaximumOptionChange}
                      isDisabled={!editable}
                      options={maximumOptions}
                      name="Maximum Option"
                      className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                      placeholder="Select maximum option"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: base => ({
                          ...base,
                          color: '#C800A5',
                          ':hover': { color: '#6546C3' }
                        }),
                        indicatorSeparator: () => ({})
                      }}
                    />
                  </div>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Icon</div>
                    {selectedMaximumOptionLength.map(index => (
                      <Select
                        key={index}
                        components={{ DropdownIndicator }}
                        value={selectAllIcon ? selectedIcons[0] : selectedIcons[index]}
                        onChange={selectedOption => handleIconChange(selectedOption, index)}
                        isDisabled={!editable || (index > 0 && selectAllIcon)}
                        options={iconOptions}
                        name="Icon"
                        className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                        placeholder="Icon"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            ':focus': { borderColor: '#6546C3' },
                            ':active': { borderColor: '#6546C3' },
                            ':hover': { borderColor: '#6546C3' },
                            paddingLeft: 4,
                            boxShadow: 'none'
                          }),
                          dropdownIndicator: base => ({
                            ...base,
                            color: '#C800A5',
                            ':hover': { color: '#6546C3' }
                          }),
                          indicatorSeparator: () => ({}),
                          menuPortal: base => ({
                            ...base,
                            fontSize: '18px'
                          }),
                          option: base => ({
                            ...base,
                            fontSize: '18px'
                          })
                        }}
                      />
                    ))}
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px]">Set All</div>
                    <input
                      type="checkbox"
                      className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                      checked={selectAllIcon}
                      onChange={event => handleSetAllIconCheck(event)}
                      disabled={!editable}
                    />
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        onChange={event => handleMandatoryField(event)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                </>
              )}
              {showSignHere && (
                <div className="flex flex-col gap-2 items-center">
                  <div className="font-semibold text-[12px] items-center text-center">
                    Mandatory
                  </div>
                  <div className="flex flex-col gap-6">
                    <input
                      id={index}
                      type="checkbox"
                      className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                      checked={mandatoryField}
                      onChange={event => handleMandatoryField(event)}
                      disabled={!editable}
                    />
                  </div>
                </div>
              )}
              {showFormSystemDropdown && (
                <>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Form System</div>
                    <Select
                      components={{ DropdownIndicator }}
                      getOptionLabel={option => option?.title}
                      getOptionValue={option => option?.id}
                      defaultValue={selectedFormSystem}
                      onChange={handleFormSystemChange}
                      options={formSystemOptions}
                      name="Form System"
                      className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                      placeholder="Select form system"
                      isDisabled={!editable}
                      styles={{
                        control: provided => ({
                          ...provided,
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: base => ({
                          ...base,
                          color: '#C800A5',
                          ':hover': { color: '#6546C3' }
                        }),
                        indicatorSeparator: () => ({})
                      }}
                    />
                    {selectedFormSystem && (
                      <button
                        onClick={() => setShowMtopFormModal(true)}
                        className="w-fit py-3 px-8 mt-2 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                      >
                        Check Data
                      </button>
                    )}
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        disabled={!editable}
                        onChange={event => handleMandatoryField(event)}
                      />
                    </div>
                  </div>
                </>
              )}
              {showFormulaInput && (
                <>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Formula</div>
                    <MentionsInput
                      style={formulaInputStyle}
                      placeholder="Enter formula here. Type # to show field list"
                      value={fieldFormula}
                      onChange={e => handleFormulaChange(e.target.value)}
                      onKeyDown={e => handleKeyDown(e)}
                      disabled={!editable}
                    >
                      <Mention
                        markup="#__display__#"
                        data={fieldsForOperand
                          .filter(field => field.field_id !== fieldData.current.field_id)
                          .map(field => {
                            return { id: field.field_id, display: field.field_title }
                          })}
                        onAdd={(id, display) => {
                          let fieldOperand = { field_id: id, field_title: display }
                          setOperandFields(
                            operandFields?.length > 0
                              ? [...operandFields, fieldOperand]
                              : [fieldOperand]
                          )
                        }}
                        trigger="#"
                        className="mentions__mention"
                        displayTransform={(id, display) => {
                          return '#' + display + '#'
                        }}
                        renderSuggestion={data => {
                          return (
                            <div key={data?.id} className="flex flex-row gap-2">
                              {data?.display}
                            </div>
                          )
                        }}
                      />
                    </MentionsInput>
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        onChange={event => handleMandatoryField(event)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                </>
              )}
              {showFieldForCalculatedSubtotal && (
                <>
                  <div className="flex flex-col flex-1 gap-2">
                    <div className="font-semibold text-[12px]">Field</div>
                    <Select
                      components={{ DropdownIndicator }}
                      value={selectedFieldSubtotal}
                      onChange={handleFieldSubtotalChange}
                      getOptionLabel={option => option?.field_title}
                      getOptionValue={option => option?.field_id}
                      options={fieldsForOperand.map(field => ({
                        field_title: field.field_title,
                        field_id: field.field_id
                      }))}
                      name="Field Subtotal"
                      className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                      placeholder="Select field for calculated subtotal"
                      isDisabled={!editable}
                      styles={{
                        control: provided => ({
                          ...provided,
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: base => ({
                          ...base,
                          color: '#C800A5',
                          ':hover': { color: '#6546C3' }
                        }),
                        indicatorSeparator: () => ({})
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div className="font-semibold text-[12px] items-center text-center">
                      Mandatory
                    </div>
                    <div className="flex flex-col gap-6">
                      <input
                        id={index}
                        type="checkbox"
                        className="w-6 h-6 accent-[#6546C3] bg-gray-100 border-gray-300"
                        checked={mandatoryField}
                        onChange={event => handleMandatoryField(event)}
                        disabled={!editable}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Signature */}
          {showSignHere ? (
            <div className="flex items-center justify-center bg-[#F2F5FC] rounded-2xl h-[212px] gap-3">
              <img src={ICONS.icPen} className="w-6 h-6" />
              <span className="font-semibold text-[12px]">Sign Here</span>
            </div>
          ) : (
            <></>
          )}
          {/* Text Editor Static Field */}
          {showStaticField ? (
            <Editor
              toolbar={{
                options: ['inline', 'list', 'history', 'image', 'colorPicker'],
                image: {
                  previewImage: true,
                  uploadEnabled: true,
                  uploadCallback: handleImageUpload,
                  alt: { present: false, mandatory: false }
                }
              }}
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState}
              handlePastedText={() => false}
              handlePastedFiles={handlePastedFiles}
              readOnly={!editable}
            />
          ) : (
            <></>
          )}
        </div>
        <img
          src={ICONS.icTrash}
          className={`w-8 h-8 ${
            !editable ||
            selectedFieldTypeOption?.value === 'MASTER_STOCK_AMOUNT' ||
            selectedFieldTypeOption?.value === 'STOCK_IN_TRANSACTION' ||
            selectedFieldTypeOption?.value === 'STOCK_UNIT'
              ? 'opacity-50'
              : 'cursor-pointer'
          }`}
          onClick={
            !editable ||
            selectedFieldTypeOption?.value === 'MASTER_STOCK_AMOUNT' ||
            selectedFieldTypeOption?.value === 'STOCK_IN_TRANSACTION' ||
            selectedFieldTypeOption?.value === 'STOCK_UNIT'
              ? () => {}
              : () => {
                  if (fieldData.current.field_type?.value === 'MASTER_STOCK') {
                    onDeleteMasterStock(index)
                  } else {
                    onDelete(index)
                  }
                }
          }
        />
      </div>
      <ApiDetailModal
        showModal={showApiDetailModal}
        setShowModal={setShowApiDetailModal}
        onSubmit={onSubmitApiDetail}
        currentOtherMasterData={fieldData.current?.other_master_data}
      />
      <ShowDataApiDetailModal
        showModal={showDataApiDetailModal}
        setShowModal={setShowDataApiDetailModal}
        currentOtherMasterData={fieldData.current?.other_master_data}
      />
      <MasterStockModal
        showModal={showMtopFormModal}
        setShowModal={setShowMtopFormModal}
        formTitle={selectedFormSystem?.title}
        formId={selectedFormSystem?.id}
      />
    </>
  )
}

export default Field
