import React, { useState, useEffect } from 'react'
import { get, post } from '../../../api/base'
import { apiUrls, ICONS } from '../../../constants'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Toast } from '../../../components'
import { toast } from 'react-toastify'
import { DropdownIndicator } from '../../../components'
import { useDispatch } from 'react-redux'
import {
  setShowPopupLeavePage,
  setShowPopupVerifyEmail,
  setMessage
} from '../../../redux/slices/popupSlice'
import LeavePagePopup from '../../../components/Popup/LeavePagePopup'
import VerifyEmailPopup from '../../../components/Popup/VerifyEmailPopup'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectSites } from '../../../redux/slices/micrositeSlice'

const AddUserPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isMicrosite = location?.pathname.includes('Site')
  const { microsite, menu } = useParams()
  const siteColor =
    useSelector(selectSites).find(s => s.route_name.toLowerCase() === microsite.toLowerCase())
      ?.top_color || (isMicrosite ? '#01a76b' : '')
  const [user, setUser] = useState({
    username: '',
    full_name: '',
    groups: [],
    company: '',
    email: '',
    work_location: null,
    landing_page: null,
    timezone: null,
    is_active: false,
    is_external: false,
    is_vip: false,
    microsoft_teams: '',
    whatsapp_number: '',
    tags: [],
    other_company_ids: [],
    contractor_id: null,
    subcon_id: null
  })
  const [workLocationOptions, setWorkLocationOptions] = useState([])
  const [selectedRoleOption, setSelectedRoleOption] = useState(null)
  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null)
  const [selectedOtherCompanyOption, setSelectedOtherCompanyOption] = useState(null)

  const [roleOptions, setRoleOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])
  const [otherCompanyOptions, setOtherCompanyOptions] = useState([])
  const landingPageOptions = [
    { label: 'Home', value: 'Home' },
    { label: 'My Activity', value: 'My Activity' },
    { label: 'Task', value: 'Task' },
    { label: 'User Log', value: 'User Log' }
  ]
  const [timeZoneOptions, setTimeZoneOptions] = useState([])

  const [teamsChecked, setTeamsChecked] = useState(false)
  const [waChecked, setWaChecked] = useState(false)
  const [isCheckedExternalUser, setIsCheckedExternalUser] = useState(false)
  const [isCheckedTag, setIsCheckedTag] = useState(false)
  const [tagOptions, setTagOptions] = useState([])
  const [contractorOptions, setContractorOptions] = useState([])
  const [subContractorOptions, setSubContractorOptions] = useState([])

  useEffect(() => {
    fetchWorkLocations()
    fetchTimeZones()
    fetchRoles()
    fetchCompanies()
    fetchTags()
    fetchContractors()
  }, [])

  const handleOnDisplayNameChange = event => {
    setUser({ ...user, full_name: event.target.value })
  }

  const handleOnUsernameChange = event => {
    setUser({ ...user, username: event.target.value })
  }

  const handleOnRoleChange = selectedOptions => {
    const roles = selectedOptions.map(selectedOption => selectedOption.id)
    setUser({ ...user, groups: roles })
    setSelectedRoleOption(selectedOptions)
  }

  const handleOnCompanyChange = selectedOption => {
    setUser({ ...user, company: selectedOption ? selectedOption.name : null })
    setSelectedCompanyOption(selectedOption)

    //populate new list other company
    if (selectedOption) {
      const newOtherCompanyOption = []
      companyOptions.map(v => {
        if (selectedOption.id !== v.id) {
          newOtherCompanyOption.push(v)
        }
      })

      setOtherCompanyOptions(newOtherCompanyOption)
    } else {
      setOtherCompanyOptions(companyOptions)
    }
  }

  const handleOnOtherCompanyChange = selectedOption => {
    const otherCompanies = []
    if (selectedOption.length) {
      selectedOption.map(v => {
        otherCompanies.push(v.id)
      })
    }
    setUser({ ...user, other_company_ids: otherCompanies })
    setSelectedOtherCompanyOption(selectedOption)
  }

  const handleOnEmailChange = event => {
    setUser({ ...user, email: event.target.value })
  }

  const handleOnWorkLocationChange = selectedOption => {
    setUser({ ...user, work_location: selectedOption ? selectedOption.name : null })
  }

  const handleOnLandingPageChange = selectedOption => {
    setUser({ ...user, landing_page: selectedOption ? selectedOption.value : null })
  }

  const handleOnTimeZoneChange = selectedOption => {
    setUser({
      ...user,
      timezone: selectedOption
        ? { name: selectedOption.name, offset: selectedOption.timezone }
        : null
    })
  }

  const handleOnStatusChange = event => {
    setUser({ ...user, is_active: event.target.checked })
  }

  const handleOnExternalUserChange = event => {
    setIsCheckedExternalUser(event.target.checked)
    setUser({ ...user, is_external: event.target.checked })
    if (event.target.checked) {
      setUser({
        ...user,
        is_external: event.target.checked,
        is_vip: false,
        is_active: false,
        microsoft_teams: ''
      })
      setTeamsChecked(false)
    } else {
      setUser({
        ...user,
        is_external: event.target.checked
      })
    }
  }

  const handleOnTagChange = event => {
    setIsCheckedTag(event.target.checked)
  }

  const onChangeTags = selectedOption => {
    if (selectedOption.length) {
      const tag_names = []

      selectedOption.map(v => {
        tag_names.push(v.value)
      })
      setUser({ ...user, tags: tag_names })
    } else {
      setUser({ ...user, tags: [] })
    }
  }

  const handleOnVipChange = event => {
    setUser({ ...user, is_vip: event.target.checked })
  }

  const handleOnContractorChange = selectedOption => {
    const subContOptions = []

    if (selectedOption) {
      setUser({ ...user, contractor_id: selectedOption ? selectedOption.id : null })
      const contractor = contractorOptions.find(
        contractor => contractor.id === selectedOption.id
      ).children

      contractor.map(v => {
        subContOptions.push({ ...v, label: v.name, value: v.id })
      })
    } else {
      setUser({ ...user, subcon_id: null, contractor_id: null })
    }
    setSubContractorOptions(subContOptions)
  }

  const handleOnSubContractorChange = selectedOption => {
    setUser({ ...user, subcon_id: selectedOption ? selectedOption.id : null })
  }

  const handleOnTeamsCheckboxChange = event => {
    setTeamsChecked(event.target.checked)
    if (event.target.checked) {
      if (user.email.includes('@banpuindo.co.id')) {
        setUser({ ...user, microsoft_teams: user.email })
      }
    } else {
      setUser({ ...user, microsoft_teams: '' })
    }
  }

  const handleOnTeamsChange = event => {
    setUser({ ...user, microsoft_teams: event.target.value })
  }

  const handleOnWaCheckboxChange = event => {
    setWaChecked(event.target.checked)
    if (!event.target.checked) {
      setUser({ ...user, whatsapp_number: '' })
    }
  }

  const handleOnWaChange = event => {
    setUser({ ...user, whatsapp_number: event.target.value })
  }

  const fetchWorkLocations = () => {
    get(`/api/users/organization-master`, {
      query: { organization_column: 'WORK_LOCATION' }
    }).then(response => {
      const { status, data } = response
      if (status === 200) {
        setWorkLocationOptions(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch work locations'} />)
      }
    })
  }

  const fetchTimeZones = () => {
    get(apiUrls.TIMEZONE_LIST, { query: '' }).then(response => {
      const { status, data } = response
      if (status === 200) {
        setTimeZoneOptions(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch time zone list'} />)
      }
    })
  }

  const fetchRoles = () => {
    get(`/api/groups`).then(response => {
      const { status, data } = response
      if (status === 200) {
        setRoleOptions(data.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch roles'} />)
      }
    })
  }

  const fetchCompanies = () => {
    get(`/api/users/organization-master`, {
      query: { organization_column: 'COMPANY' }
    }).then(response => {
      const { status, data } = response
      if (status === 200) {
        const listCompany = []

        data.map(v => {
          listCompany.push({ ...v, label: v.name, value: v.id })
        })

        setCompanyOptions(listCompany)
        setOtherCompanyOptions(listCompany)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch companies'} />)
      }
    })
  }

  const fetchTags = () => {
    get(apiUrls.TAGS).then(response => {
      const { ok, data } = response
      if (ok) {
        const listTags = []
        data.map(v => {
          listTags.push({
            ...v,
            label: v.name,
            value: v.name
          })
        })

        setTagOptions(listTags)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch tags'} />)
      }
    })
  }

  const fetchContractors = () => {
    const query = {
      query: {
        child_master_data_id: 2
      }
    }
    get(`${apiUrls.MASTER_DATA}/2/details`, query).then(response => {
      const { ok, data } = response
      if (ok) {
        const listContractors = []
        data.map(v => {
          listContractors.push({
            ...v,
            label: v.name,
            value: v.id
          })
        })

        setContractorOptions(listContractors)
      } else {
        ;<Toast message={`Error`} detailedMessage={'Failed to fetch contractor list.'} />
      }
    })
  }

  const handleLeavePage = () => {
    dispatch(setShowPopupLeavePage(true))
  }

  const handleSubmitUser = () => {
    if (userValidation()) {
      submitUser()
    }
  }

  const submitUser = () => {
    post(`/api/users/manage-user`, user).then(response => {
      const { status, data } = response
      if (status === 200) {
        handleOnSuccessSubmitUser()
      } else if (status === 422) {
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`Error adding user. ${
              data.error?.errors[0][0]?.message || data.error?.errors[0]?.message
            }`}
          />
        )
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={`Error adding user. ${data.error.message}`} />
        )
      }
    })
  }

  const handleOnSuccessSubmitUser = () => {
    dispatch(setShowPopupVerifyEmail(true))
    dispatch(setMessage(user.email))
  }

  const emailValidation = email => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }

  const userValidation = () => {
    let isUserValid = true
    // Text Input Validation
    const textInputs = document.getElementsByClassName('input-text')
    Array.from(textInputs).forEach(function (input) {
      if (
        input.value === '' &&
        input.name !== 'Microsoft Teams' &&
        input.name !== 'WhatsApp Number'
      ) {
        isUserValid = false
        toast.error(
          <Toast message={`Error`} detailedMessage={`${input.name} should not be empty`} />
        )
        input.focus()
      }
    })

    if (user.groups.length === 0) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Role should not be empty`} />)
    }

    if (!user.is_external && user.company.length === 0) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Company should not be empty`} />)
    }

    if (!user.work_location) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Work Location should not be empty`} />)
    }

    if (!user.landing_page) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Landing Page should not be empty`} />)
    }

    if (!emailValidation(user.email)) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Please input a valid email`} />)
    }

    if (isCheckedTag && user.tags.length < 1) {
      isUserValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Tags should not be empty`} />)
    }

    return isUserValid
  }

  return (
    <div className="flex-row p-2 sm:p-6">
      <div className="flex flex-col justify-evenly gap-6">
        <div className="flex-1 bg-white p-6 rounded-2xl">
          <div className="font-poppins font-semibold text-[16px] text-[#C800A5] mb-6">
            User Info
          </div>
          <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Display Name</div>
              <input
                type="text"
                name="Display Name"
                className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Enter display name"
                onChange={event => handleOnDisplayNameChange(event)}
              />
            </div>
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Username</div>
              <input
                type="text"
                name="Username"
                className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Enter username"
                onChange={event => handleOnUsernameChange(event)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Role</div>
              <Select
                components={{ DropdownIndicator }}
                defaultValue={selectedRoleOption}
                onChange={selectedOptions => handleOnRoleChange(selectedOptions)}
                options={roleOptions}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                name="Role"
                className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Select role"
                isMulti={true}
                isClearable={true}
                styles={{
                  option: provided => ({
                    ...provided
                  }),
                  control: provided => ({
                    ...provided,
                    minHeight: '40px',
                    borderColor: '#DEDEDE',
                    borderRadius: '6px',
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    paddingTop: 2,
                    paddingBottom: 2,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({}),
                  valueContainer: provided => ({
                    ...provided
                  }),
                  multiValueLabel: provided => ({
                    ...provided,
                    color: '#fff',
                    fontFamily: 'poppins'
                  }),
                  multiValueRemove: provided => ({
                    ...provided,
                    color: '#fff'
                  }),
                  multiValue: provided => ({
                    ...provided,
                    backgroundColor: '#6546C3',
                    borderRadius: 8
                  })
                }}
              />
            </div>
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Email</div>
              <input
                type="text"
                name="Email"
                className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Ex: abcd@banpuindo.co.id"
                onChange={event => handleOnEmailChange(event)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Company</div>
              <Select
                components={{ DropdownIndicator }}
                defaultValue={selectedCompanyOption}
                onChange={selectedOptions => handleOnCompanyChange(selectedOptions)}
                options={companyOptions}
                name="Company"
                className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Select company"
                isMulti={false}
                isClearable={true}
                styles={{
                  option: provided => ({
                    ...provided
                  }),
                  control: provided => ({
                    ...provided,
                    minHeight: '40px',
                    borderColor: '#DEDEDE',
                    borderRadius: '6px',
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    paddingTop: 2,
                    paddingBottom: 2,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({}),
                  valueContainer: provided => ({
                    ...provided
                  }),
                  multiValueLabel: provided => ({
                    ...provided,
                    color: '#fff',
                    fontFamily: 'poppins'
                  }),
                  multiValueRemove: provided => ({
                    ...provided,
                    color: '#fff'
                  }),
                  multiValue: provided => ({
                    ...provided,
                    backgroundColor: '#6546C3',
                    borderRadius: 8
                  })
                }}
              />
            </div>
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Other Company</div>
              <Select
                components={{ DropdownIndicator }}
                defaultValue={selectedOtherCompanyOption}
                onChange={selectedOptions => handleOnOtherCompanyChange(selectedOptions)}
                options={otherCompanyOptions}
                getOptionValue={option => option.name}
                getOptionLabel={option => option.name}
                name="Company"
                className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Select other company"
                isMulti={true}
                styles={{
                  option: provided => ({
                    ...provided
                  }),
                  control: provided => ({
                    ...provided,
                    minHeight: '40px',
                    borderColor: '#DEDEDE',
                    borderRadius: '6px',
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    paddingTop: 2,
                    paddingBottom: 2,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: provided => ({
                    ...provided,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({}),
                  valueContainer: provided => ({
                    ...provided
                  }),
                  multiValueLabel: provided => ({
                    ...provided,
                    color: '#fff',
                    fontFamily: 'poppins'
                  }),
                  multiValueRemove: provided => ({
                    ...provided,
                    color: '#fff'
                  }),
                  multiValue: provided => ({
                    ...provided,
                    backgroundColor: '#6546C3',
                    borderRadius: 8
                  })
                }}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Work Location</div>
              <Select
                components={{ DropdownIndicator }}
                onChange={selectedOption => handleOnWorkLocationChange(selectedOption)}
                options={workLocationOptions}
                getOptionValue={option => option.name}
                getOptionLabel={option => option.name}
                name="Work Location"
                className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Select work location"
                isClearable={true}
                styles={{
                  control: provided => ({
                    ...provided,
                    height: '40px',
                    borderColor: '#DEDEDE',
                    borderRadius: '6px',
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Landing Page</div>
              <Select
                components={{ DropdownIndicator }}
                onChange={selectedOption => handleOnLandingPageChange(selectedOption)}
                options={landingPageOptions}
                name="Landing Page"
                className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Select landing page"
                isClearable={true}
                styles={{
                  control: provided => ({
                    ...provided,
                    height: '40px',
                    borderColor: '#DEDEDE',
                    borderRadius: '6px',
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-2">Time Zone</div>
              <Select
                components={{ DropdownIndicator }}
                onChange={selectedOption => handleOnTimeZoneChange(selectedOption)}
                options={timeZoneOptions}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                name="Time Zone"
                className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                placeholder="Select time zone"
                isClearable={true}
                styles={{
                  control: provided => ({
                    ...provided,
                    height: '40px',
                    borderColor: '#DEDEDE',
                    borderRadius: '6px',
                    ':focus': { borderColor: '#6546C3' },
                    ':active': { borderColor: '#6546C3' },
                    ':hover': { borderColor: '#6546C3' },
                    paddingLeft: 4,
                    boxShadow: 'none'
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    color: '#C800A5',
                    ':hover': { color: '#6546C3' }
                  }),
                  indicatorSeparator: () => ({})
                }}
              />
            </div>
            <div className="flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-4">Is Active</div>
              <label
                htmlFor={`is-user-active`}
                className="flex relative items-center cursor-pointer"
              >
                <input
                  type="checkbox"
                  id={`is-user-active`}
                  className="sr-only peer"
                  checked={user.is_active}
                  disabled={user.is_external}
                  onChange={event => handleOnStatusChange(event)}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
            <div className="flex flex-1">
              <div className="flex flex-col w-28">
                <div className="font-poppins font-semibold text-[12px] mb-4">External User</div>
                <input
                  type="checkbox"
                  className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-[#6546C3]"
                  onChange={event => handleOnExternalUserChange(event)}
                />
              </div>
              <div className={`${isCheckedExternalUser ? 'block' : 'hidden'} flex-1 ml-3`}>
                <div className="font-poppins font-semibold text-[12px] mb-2">Contractor</div>
                <Select
                  components={{ DropdownIndicator }}
                  onChange={selectedOption => handleOnContractorChange(selectedOption)}
                  options={contractorOptions}
                  name="Contractor"
                  className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select contractor"
                  isClearable={true}
                  styles={{
                    control: provided => ({
                      ...provided,
                      height: '40px',
                      borderColor: '#DEDEDE',
                      borderRadius: '6px',
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({})
                  }}
                />
                <div className="font-poppins font-semibold text-[12px] my-2">Sub Contractor</div>
                <Select
                  components={{ DropdownIndicator }}
                  onChange={selectedOption => handleOnSubContractorChange(selectedOption)}
                  options={subContractorOptions}
                  name="Sub Contractor"
                  className={`input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select sub contractor"
                  isClearable={true}
                  isDisabled={
                    !user.contractor_id ||
                    !contractorOptions.find(v => v.id === user.contractor_id).children.length
                  }
                  styles={{
                    control: provided => ({
                      ...provided,
                      height: '40px',
                      borderColor: '#DEDEDE',
                      borderRadius: '6px',
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({})
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="font-poppins font-semibold text-[12px] mb-4">VIP</div>
              <input
                type="checkbox"
                className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-[#6546C3]"
                disabled={user.is_external}
                checked={user.is_vip}
                onChange={event => handleOnVipChange(event)}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
            <div className="flex flex-1">
              <div className="flex flex-col w-28">
                <div className="font-poppins font-semibold text-[12px] mb-4">Tags</div>
                <input
                  type="checkbox"
                  className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-[#6546C3]"
                  onChange={event => handleOnTagChange(event)}
                />
              </div>
              <div className={`${isCheckedTag ? 'block' : 'hidden'} grow ml-3`}>
                <div className="font-poppins font-semibold text-[12px] mb-2">
                  Search or Add New Tag
                </div>
                <div className="flex">
                  <CreatableSelect
                    options={tagOptions}
                    isMulti={true}
                    onChange={selectedOption => onChangeTags(selectedOption)}
                    className={`grow input-select text-[12px] rounded-md w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Type here..."
                    styles={{
                      option: provided => ({
                        ...provided
                      }),
                      control: provided => ({
                        ...provided,
                        minHeight: '40px',
                        borderColor: '#DEDEDE',
                        borderRadius: '6px',
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        paddingTop: 2,
                        paddingBottom: 2,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({}),
                      valueContainer: provided => ({
                        ...provided
                      }),
                      multiValueLabel: provided => ({
                        ...provided,
                        color: '#fff',
                        fontFamily: 'poppins'
                      }),
                      multiValueRemove: provided => ({
                        ...provided,
                        color: '#fff'
                      }),
                      multiValue: provided => ({
                        ...provided,
                        backgroundColor: '#6546C3',
                        borderRadius: 8
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1"></div>
          </div>
        </div>

        <div className="flex-1 bg-white p-6 rounded-2xl">
          <div className="font-poppins font-semibold text-[16px] text-[#C800A5] mb-6">
            Connected Chat
          </div>
          <div className="flex flex-col md:flex-row justify-evenly gap-4 md:gap-6 mb-4">
            <div className="flex-1">
              <div className="flex flex-row gap-2 mb-1">
                <div className="font-poppins font-semibold text-[12px] flex items-center">
                  Microsoft Teams
                </div>
                <label
                  htmlFor={`is-teams-active`}
                  className="flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value=""
                    id={`is-teams-active`}
                    className="sr-only peer"
                    disabled={user.is_external}
                    checked={teamsChecked}
                    onChange={event => handleOnTeamsCheckboxChange(event)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                </label>
              </div>
              <div
                className={`flex w-full h-[40px] font-poppins border-[1px] items-center p-[12px] rounded-lg ${
                  user.is_external || !teamsChecked ? 'bg-[#F7F5F9]' : ''
                }`}
              >
                <img src={ICONS.icTeams} alt="Microsoft Teams" className="w-[24px] h-[24px]" />
                <input
                  type="text"
                  name="Microsoft Teams"
                  className={`input-text text-[12px] w-full focus:outline-none focus:border-[#6546C3] ml-2 disabled:bg-[#F7F5F9]`}
                  placeholder="Enter microsoft teams link"
                  disabled={user.is_external || !teamsChecked}
                  value={user.microsoft_teams}
                  onChange={event => handleOnTeamsChange(event)}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-row gap-2 mb-1">
                <div className="font-poppins font-semibold text-[12px] flex items-center">
                  WhatsApp Number
                </div>
                <label
                  htmlFor={`is-wa-active`}
                  className="flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value=""
                    id={`is-wa-active`}
                    className="sr-only peer"
                    checked={waChecked}
                    onChange={event => handleOnWaCheckboxChange(event)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                </label>
              </div>
              <div
                className={`flex w-full h-[40px] font-poppins border-[1px] items-center p-[12px] rounded-lg ${
                  !waChecked ? 'bg-[#F7F5F9]' : ''
                }`}
              >
                <img src={ICONS.icWa} alt="WhatsApp" className="w-[24px] h-[24px]" />
                <input
                  type="number"
                  name="WhatsApp Number"
                  className={`input-text text-[12px] w-full focus:outline-none focus:border-[#6546C3] ml-2 disabled:bg-[#F7F5F9]`}
                  placeholder="Ex: 081509870987"
                  disabled={!waChecked}
                  value={user.whatsapp_number}
                  onChange={event => handleOnWaChange(event)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-10">
          <button
            onClick={() => handleLeavePage()}
            className="py-3 px-8 border-[1px] text-[12px] font-semibold rounded-md"
            style={{
              borderColor: siteColor || '#6546C3',
              color: siteColor || '#6546C3'
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => handleSubmitUser()}
            className="py-3 px-8 text-white text-[12px] font-semibold rounded-md"
            style={{
              background: siteColor || 'linear-gradient(to right, #6546C3, #9E7CE3)'
            }}
          >
            Submit
          </button>
        </div>
      </div>
      <LeavePagePopup
        onLeavePage={() => {
          if (isMicrosite) {
            navigate(`/Site/${microsite}/menu/${menu}`, {
              state: { title: 'Manage User', crumbs: ['Manage User'] }
            })
          } else {
            navigate('/Admin/ManageUser', {
              state: { title: 'Manage User', crumbs: ['Admin', 'Manage User'] }
            })
          }
        }}
      />
      <VerifyEmailPopup />
    </div>
  )
}

export default AddUserPage
