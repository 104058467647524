import fileDownload from 'js-file-download'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { useRowSelect, useSortBy, useTable } from 'react-table'
import { toast } from 'react-toastify'

import { del, get } from '../../../../../api/base'
import { DeletePopup, DropdownIndicator, SuccessPopup, Toast } from '../../../../../components'
import { ICONS, apiUrls } from '../../../../../constants'
import {
  setMessage,
  setShowPopupDelete,
  setShowPopupSuccess
} from '../../../../../redux/slices/popupSlice'
import { selectUserData } from '../../../../../redux/slices/userSlice'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="font-poppins border-[1px] flex items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}

const SubmissionTable = ({ id, users, title }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userData = useSelector(selectUserData)

  const [deletedSubmissionId, setDeletedSubmissionId] = useState(null)
  const [deleteSubmission, setDeleteSubmission] = useState(false)

  const ref = useRef({
    indeterminate: true
  })

  const CustomCheckbox = forwardRef(({ value, ...props }, ref) => {
    return (
      <input
        type="checkbox"
        className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300 "
        value={value}
        {...props}
      />
    )
  })
  CustomCheckbox.displayName = 'CustomCheckbox'

  const Checkbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <CustomCheckbox ref={resolvedRef} {...rest} />
      </>
    )
  })
  Checkbox.displayName = 'Checkbox'

  const COLUMNS = [
    {
      Header: ({ getToggleAllRowsSelectedProps }) => {
        return <Checkbox ref={ref} {...getToggleAllRowsSelectedProps()} />
      },
      id: 'id',
      Cell: ({ row }) => {
        return <Checkbox ref={ref} {...row.getToggleRowSelectedProps()} />
      }
    },
    {
      Header: 'Sent at',
      accessor: 'sent_at',
      Cell: ({ row, value }) => {
        return value
      }
    },
    {
      Header: 'Initiated By',
      accessor: 'user',
      Cell: ({ row, value }) => {
        return (
          <div className="flex items-center gap-3">
            <img
              src={value.picture ? value.picture : ICONS.icAdminActive}
              className="w-6 h-6 rounded-full"
              alt=""
            />
            <div>{value.full_name}</div>
          </div>
        )
      }
    },
    {
      Header: 'Company',
      accessor: 'company',
      Cell: ({ row, value }) => {
        return value
      }
    },
    {
      Header: 'ID Request',
      accessor: 'module_id',
      Cell: ({ row, value }) => {
        return value || '-'
      }
    },
    {
      Header: 'Workflow Status',
      accessor: 'status',
      Cell: ({ row, value }) => {
        return value ? (
          <span
            className={`${
              value.toLowerCase() === 'submitted'
                ? 'class-submitted'
                : value.toLowerCase().includes('approved')
                ? 'class-approved'
                : value.toLowerCase().includes('revise')
                ? 'class-sendback'
                : 'class-ongoing'
            } truncate`}
          >
            {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
          </span>
        ) : (
          ''
        )
      }
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleDetailSubmissionClick = () => {
          const DETAIL_SUBMISSION_PATH = `/DocumentLibrary/Form/DetailForm/${id}/DetailSubmission/${row.original.id}`
          const DETAIL_SUBMISSION_CRUMBS = 'Document Library/Form/Detail Form/Show Response'
          const crumbs = DETAIL_SUBMISSION_CRUMBS.split('/')
          navigate(DETAIL_SUBMISSION_PATH, {
            state: { title: 'Detail Submission', crumbs: crumbs }
          })
        }
        const handleEditSubmissionClick = () => {
          const EDIT_SUBMISSION_PATH = `/DocumentLibrary/Form/DetailForm/${id}/EditSubmission/${row.original.id}`
          const EDIT_SUBMISSION_CRUMBS = 'Document Library/Form/Detail Form/Show Response'
          const crumbs = EDIT_SUBMISSION_CRUMBS.split('/')
          navigate(EDIT_SUBMISSION_PATH, {
            state: { title: 'Edit Submission', crumbs: crumbs }
          })
        }
        const handleDeleteSubmissionClick = () => {
          setDeletedSubmissionId(row.original.id)
          dispatch(setShowPopupDelete(true))
          dispatch(setMessage('Are you sure you want to delete this form submitted?'))
        }
        return (
          <div className="flex gap-x-2 grow">
            {isAdmin && (
              <img
                src={ICONS.icEdit}
                className="w-[28px] cursor-pointer"
                alt="edit"
                onClick={() => handleEditSubmissionClick()}
              />
            )}
            <img
              src={ICONS.icDetail}
              alt="view"
              className="w-[28px] cursor-pointer"
              onClick={() => handleDetailSubmissionClick()}
            />
            {isAdmin && (
              <img
                src={ICONS.icTrash}
                alt="delete"
                className="w-[28px] cursor-pointer"
                onClick={() => handleDeleteSubmissionClick()}
              />
            )}
          </div>
        )
      }
    }
  ]
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const isAddRow = useRef(false)
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [selectedInitiatedOption, setSelectedInitiatedOption] = useState(null)
  const [initiatedOptions, setInitiatedOptions] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const [companyOptions, setCompanyOptions] = useState([])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    prepareRow,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true
    },
    useSortBy,
    useRowSelect
  )

  useEffect(() => {
    populateInitiatedDropdown()
    fetchCompanies()
  }, [users])

  useEffect(() => {
    if (sortBy.length > 0) {
      setManualSortBy(sortBy[0].id)
      setOrderBy(sortBy[0].desc ? 'desc' : 'asc')
    } else {
      setManualSortBy('')
      setOrderBy('')
    }
  }, [sortBy])

  useEffect(() => {
    fetchSubmissions()
  }, [
    search,
    pageNum,
    limit,
    manualSortBy,
    orderBy,
    selectedInitiatedOption,
    startDate,
    endDate,
    companyId,
    deleteSubmission
  ])

  function fetchSubmissions() {
    let query = {
      query: {}
    }
    if (search !== '') query.query.search = search

    if (pageNum !== '') query.query.page = pageNum

    if (limit !== '') query.query.limit = limit

    if (manualSortBy !== '') query.query.sort_by = manualSortBy

    if (orderBy !== '') query.query.order_by = orderBy

    if (companyId) query.query.company_id = companyId

    if (selectedInitiatedOption && selectedInitiatedOption.value !== '')
      query.query.user_id = selectedInitiatedOption.value

    if (startDate !== '') query.query.start_date = startDate

    if (endDate !== '') query.query.end_date = endDate

    get(`${apiUrls.FORMS_URL}/${id}/contents`, query).then(response => {
      const { status } = response
      if (status === 200) {
        setData(response.data.form_contents)
        setTotalPage(response.data.total_page)
        setTotalData(response.data.total_data)
      } else {
        errorApi(status, response)
      }
    })
  }

  function fetchCompanies() {
    get(`${apiUrls.USER_PROFILE}/companies`).then(response => {
      const { status } = response
      if (status === 200) {
        const newCompanyOptions = response.data.map(v => {
          return { ...v, label: v.name, value: v.id }
        })
        setCompanyOptions(newCompanyOptions)
      } else {
        errorApi(status, response)
      }
    })
  }

  const onDeleteSubmission = async () => {
    if (deletedSubmissionId) {
      const data = await del(`${apiUrls.FORMS_URL}/contents/${deletedSubmissionId}`)
      if (data.ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully delete form submission.'))
        fetchSubmissions()
      } else {
        toast.error(
          <Toast message={`Error`} detailedMessage={'Failed to delete this form submission.'} />
        )
      }
    }
  }

  const errorApi = (status, response) => {
    switch (status) {
      case 408:
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
        break
      case 400:
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
        break
      default:
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
        break
    }
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map(data => {
        if (data === 0)
          return (
            <div key={data} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={data}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => setPageNum(data)}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function handleAddFormClick() {
    const ADD_FORM_PATH = '/DocumentLibrary/Form/AddForm'
    const ADD_FORM_CRUMBS = 'DocumentLibrary/Form/AddForm'
    const crumbs = ADD_FORM_CRUMBS.split('/')
    navigate(ADD_FORM_PATH, { state: { title: 'Add Form', crumbs: crumbs } })
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 != 0
  }

  const goToNextPage = () => {
    setPageNum(pageNum + 1)
  }
  const goToPreviousPage = () => {
    setPageNum(pageNum - 1)
  }

  const handleOnExportExcelClick = () => {
    const selectedSubmission = selectedFlatRows.map(d => d.original)
    const arrIdSubmission = selectedSubmission.map(x => x.id)

    let query = {
      responseType: 'blob',
      query: {}
    }
    if (arrIdSubmission.length > 0) query.query.form_submission_ids = arrIdSubmission

    get(`/api/dynamic-forms/${id}/contents/export-excel`, query).then(response => {
      const { status } = response
      if (status === 200) {
        const file = new Blob([response.data])

        const dateTime = new Date()
        const formatedDate =
          dateTime.getDate() + '-' + (dateTime.getMonth() + 1) + '-' + dateTime.getFullYear()
        const formatedTime =
          dateTime.getHours() + '-' + dateTime.getMinutes() + '-' + dateTime.getSeconds()

        let validName = title.replace(/[/\\?%*:|"<>]/g, '-')

        let fileName = validName + '_' + formatedDate + '_' + formatedTime + '.xlsx'
        fileDownload(file, fileName)
      } else {
        errorApi(status, response)
      }
    })
  }

  const handleOnExportPdfClick = () => {}

  const handleOnInitiatedChange = selectedOption => {
    setSelectedInitiatedOption(selectedOption)
  }

  const handleOnStartDateChange = newDate => {
    setStartDate(newDate)
  }
  const handleOnEndDateChange = newDate => {
    setEndDate(newDate)
  }

  const handleOnCompanyChange = selectedOption => {
    setSelectedCompany(selectedOption)
    setCompanyId(selectedOption ? selectedOption.id : null)
  }

  const populateInitiatedDropdown = () => {
    if (users && users.length) {
      const newinitiatedOptions = users.map(user => {
        return { label: user.full_name, value: user.id }
      })
      setInitiatedOptions(newinitiatedOptions)
    }
  }

  const isAdmin = userData?.roles.includes('Administrator')

  return (
    <div className="flex flex-col flex-1 p-6 rounded-2xl bg-white gap-10">
      <div className="flex flex-col p-6 rounded-2xl bg-[#F2F5FC] gap-4">
        <div className="font-semibold text-[16px] text-[#C800A5]">Filter</div>
        <div className="flex gap-2">
          <div className="flex flex-1 items-center gap-2">
            <Select
              components={{ DropdownIndicator }}
              defaultValue={selectedInitiatedOption}
              onChange={selectedOption => handleOnInitiatedChange(selectedOption)}
              options={initiatedOptions}
              isSearchable={false}
              isClearable={true}
              name="Attachment"
              className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
              placeholder="Select Initiated"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: 'none',
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                indicatorSeparator: () => ({})
              }}
            />
            <Select
              components={{ DropdownIndicator }}
              defaultValue={selectedCompany}
              onChange={selectedOption => handleOnCompanyChange(selectedOption)}
              options={companyOptions}
              isSearchable={false}
              isClearable={true}
              name="Company"
              className={`flex-1 text-[12px] border rounded-lg focus:outline-none focus:border-[#6546C3]`}
              placeholder="Select company"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderColor: 'none',
                  ':focus': { borderColor: '#6546C3' },
                  ':active': { borderColor: '#6546C3' },
                  ':hover': { borderColor: '#6546C3' },
                  paddingLeft: 4,
                  boxShadow: 'none'
                }),
                indicatorSeparator: () => ({})
              }}
            />
          </div>

          <div className="flex flex-1 items-center gap-2">
            <div className="flex flex-1 relative">
              <DatePicker
                selected={startDate}
                onChange={date => handleOnStartDateChange(date)}
                dateFormat="dd-MMM-yyyy"
                name="Start date"
                className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                placeholderText={'Start date'}
              />
              <img
                src={ICONS.icCalendar}
                className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
                alt="calendar"
              />
            </div>
            -
            <div className="flex flex-1 relative">
              <DatePicker
                selected={endDate}
                onChange={date => handleOnEndDateChange(date)}
                dateFormat="dd-MMM-yyyy"
                name="End date"
                className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                placeholderText={'End date'}
              />
              <img
                src={ICONS.icCalendar}
                className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
                alt="calendar"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="flex justify-between font-poppins text-[12px] mb-10 gap-4">
          <div className="flex gap-4">
            <button
              className="bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-8 py-3"
              onClick={() => handleOnExportExcelClick()}
            >
              Export Excel
            </button>
            <button
              className="bg-[#E5E7FA] text-[#6546C3] font-semibold rounded-md px-8 py-3"
              onClick={() => handleOnExportPdfClick()}
            >
              Export PDF
            </button>
          </div>
          <div className="flex items-center gap-x-2">
            Show
            <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
              <img
                src={ICONS.icSort}
                className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
              />
              <select
                value={limit}
                onChange={e => {
                  setLimit(Number(e.target.value))
                }}
                className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
              >
                {[5, 10, 25, 100].map(limit => (
                  <option key={limit} value={limit}>
                    {limit}
                  </option>
                ))}
              </select>
            </div>
            Data
          </div>
          <div>
            <GlobalFilter filter={search} setFilter={setSearch} />
          </div>
        </div>
        <div className="overflow-auto">
          <table {...getTableProps()} className="font-poppins text-[12px] min-w-[1000px]">
            <thead className="">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <div className="flex gap-1">
                        {column.render('Header')}
                        <img
                          src={
                            column.canSort
                              ? column.isSorted
                                ? column.isSortedDesc
                                  ? ICONS.icSortDown
                                  : ICONS.icSortUp
                                : ICONS.icSort
                              : ''
                          }
                          className="w-2"
                        />
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {(rows.length > 0 &&
                rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      })}
                    </tr>
                  )
                })) || (
                <tr>
                  <td className="align-middle">No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-9">
          <div className="font-poppins text-[12px]">
            Showing {pageNum * limit - (limit - 1)} -{' '}
            {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
          </div>
          <div className="flex font-poppins text-[12px]">
            <button
              onClick={() => goToPreviousPage()}
              disabled={!canPreviousPage()}
              className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                !canPreviousPage()
                  ? 'bg-[#F2F5FC]'
                  : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
              }`}
            >
              &lt;
            </button>
            <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
            <button
              onClick={() => goToNextPage()}
              disabled={!canNextPage()}
              className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                !canNextPage()
                  ? 'bg-[#F2F5FC]'
                  : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
              }`}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <DeletePopup setContinueDelete={() => onDeleteSubmission()} buttonNext={'Delete Now'} />
      <SuccessPopup />
    </div>
  )
}

export default SubmissionTable
