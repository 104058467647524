import React from 'react'
import Field from '../Field'

const SubSection = ({
  section,
  onChangeValueSubSectionField,
  onChangeSubSectionCheckBox,
  onChangeSubSectionRadio,
  onAddSubSectionAttachment,
  removeSubSectionFile
}) => {
  const fields = section.fields
  return (
    <div className="flex flex-col bg-[#F2F5FC] rounded-2xl p-6">
      <div className="font-semibold text-[14px] text-[#C800A5]">{section.section_title}</div>
      <hr className="mt-2 mb-6" />
      <div className="flex flex-col gap-4">
        {fields?.map((field, fieldIndex) => (
          <Field
            key={`${field.field_id}${fieldIndex}`}
            field={field}
            sectionId={section.section_id}
            onChange={(event, additionalIndex) => {
              onChangeValueSubSectionField(event, additionalIndex, fieldIndex)
            }}
            onChangeCheck={(e, label, additionalIndex) =>
              onChangeSubSectionCheckBox(e, label, additionalIndex, fieldIndex)
            }
            onChangeRadioButton={(e, label, additionalIndex) =>
              onChangeSubSectionRadio(e, label, additionalIndex, fieldIndex)
            }
            addAttachment={(file, additionalIndex) =>
              onAddSubSectionAttachment(file, additionalIndex, fieldIndex)
            }
            onRemoveFile={(indexFile, additionalIndex) =>
              removeSubSectionFile(indexFile, additionalIndex, fieldIndex)
            }
          />
        ))}
      </div>
    </div>
  )
}

export default SubSection
