import ImageSuccess from '../../../assets/images/success.png'
import { useDispatch, useSelector } from 'react-redux'
import { setShowPopupSuccess } from '../../../redux/slices/popupSlice'

const SuccessPopup = ({ onClosePopup }) => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.popup.showPopupSuccess)
  const message = useSelector(state => state.popup.popupMessage)

  const handleOnClosePopUp = () => {
    dispatch(setShowPopupSuccess(false))
    if (onClosePopup) {
      onClosePopup()
    }
  }

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal bg-[#232323]/[0.5] flex flex-col gap-4 overflow-y-auto py-6">
        <button
          onClick={handleOnClosePopUp}
          className="p-2 bg-white font-black text-[#C800A5] text-[8px] leading-[8px] rounded-full w-6 h-6"
        >
          ✕
        </button>
        <div
          className="modal-box bg-white relative w-full max-h-max max-w-max py-[60px] px-0"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center min-w-[21.25rem]">
            <img src={ImageSuccess} className="w-[120px] h-[120px] mb-9" alt="success icon" />
            <span className="font-semibold text-lg mb-1">Successfully</span>
            <span className="text-xs text-gray-500">{message}</span>
          </div>
          <div className="flex justify-center mt-9">
            <button
              onClick={handleOnClosePopUp}
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessPopup
