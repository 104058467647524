import { Editor as BaseEditor } from '@tinymce/tinymce-react'
import styles from './Editor.module.css'

export default function Editor(props) {
  return (
    <div className={styles.editor}>
      <BaseEditor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        init={{
          height: 500,
          menu: {
            file: {
              title: 'File',
              items: 'newdocument restoredraft | preview | export print | deleteallconversations'
            },
            edit: {
              title: 'Edit',
              items: 'undo redo | cut copy paste pastetext | selectall | searchreplace'
            },
            view: {
              title: 'View',
              items:
                'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments'
            },
            insert: {
              title: 'Insert',
              items:
                'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime'
            },
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat'
            },
            tools: {
              title: 'Tools',
              items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount'
            },
            table: {
              title: 'Table',
              items: 'inserttable | cell row column | advtablesort | tableprops deletetable'
            },
            help: { title: 'Help', items: 'help' }
          },
          plugins: [
            'a11ychecker',
            'advlist',
            'advcode',
            'advtable',
            'autolink',
            'checklist',
            'code',
            'export',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'powerpaste',
            'fullscreen',
            'formatpainter',
            'insertdatetime',
            'media',
            'table',
            'help',
            'wordcount'
          ],
          toolbar:
            'undo redo | casechange blocks | bold italic backcolor forecolor | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        {...props}
      />
    </div>
  )
}
