import moment from 'moment/moment'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import { useRef } from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { useSortBy, useTable } from 'react-table'
import { get } from '../../../api/base'
import { Toast, DropdownIndicator } from '../../../components'
import { apiUrls, ICONS } from '../../../constants'
import Select, { components, ValueContainerProps } from 'react-select'
import { toast } from 'react-toastify'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="flex w-full md:w-fit font-poppins border-[1px] items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}

const MyActivityTable = () => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

  const COLUMNS = [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row, value, isDesktop }) => {
        if (isDesktop) {
          return value
        } else {
          return (
            <button
              className=" md:no-disabled underline md:no-underline text-blue-700 md:text-current md:font-reguler underline-offset-2"
              onClick={() => {}}
            >
              {value}
            </button>
          )
        }
      }
    },
    {
      Header: 'Planned Start',
      accessor: 'planned_start',
      Cell: ({ row, value }) => {
        return moment(value).format('DD-MMM-YYYY HH:mm')
      }
    },
    {
      Header: 'Due Date',
      accessor: 'due_date',
      Cell: ({ row, value }) => {
        return moment(value).format('DD-MMM-YYYY HH:mm')
      }
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ row, value, pageNum }) => {
        return value ? (
          <span
            className={`${
              value.toLowerCase().includes('task')
                ? 'class-submitted'
                : value.toLowerCase().includes('issue')
                ? 'class-approved'
                : 'class-ongoing'
            } truncate`}
          >
            {value}
          </span>
        ) : (
          '-'
        )
      }
    },
    {
      Header: 'ID Request',
      accessor: 'workflow_id',
      Cell: ({ row, value }) => {
        return value || '-'
      }
    },
    {
      Header: 'Workflow Status',
      accessor: 'workflow_status',
      Cell: ({ row, value }) => {
        return value ? (
          <span
            className={`${
              value.toLowerCase() === 'submitted'
                ? 'class-submitted'
                : value.toLowerCase().includes('approved')
                ? 'class-approved'
                : value.toLowerCase().includes('revise')
                ? 'class-sendback'
                : 'class-ongoing'
            } truncate`}
          >
            {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
          </span>
        ) : (
          '-'
        )
      }
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }) => {
        const handleDetailClick = () => {
          switch (row.original.type) {
            case 'Form':
              const DETAIL_FORM_PATH = `/DocumentLibrary/Form/DetailForm/2/DetailSubmission/${row.original.id}`
              const DETAIL_FORM_CRUMBS = 'Document Library/Form/Detail Form'
              const crumbs = DETAIL_FORM_CRUMBS.split('/')
              navigate(DETAIL_FORM_PATH, {
                state: { title: 'Detail Form', crumbs: crumbs }
              })
              break
            case 'Task':
              const DETAIL_TASK_PATH = `/DocumentLibrary/Task/DetailTask/${row.original.id}`
              const DETAIL_TASK_CRUMBS = 'Document Library/Task/Detail Task'
              const crumbs_TASK = DETAIL_TASK_CRUMBS.split('/')
              navigate(DETAIL_TASK_PATH, {
                state: { title: 'Detail Task', crumbs: crumbs_TASK }
              })
              break
            default:
              break
          }
        }
        return (
          <div className="flex flex-wrap gap">
            <img
              src={ICONS.icDetail}
              className="w-[28px] cursor-pointer"
              onClick={() => handleDetailClick()}
            />
          </div>
        )
      }
    }
  ]

  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const isAddRow = useRef(false)
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [totalPage, setTotalPage] = useState(0)
  const [totalData, setTotalData] = useState(0)
  const [taskData, setTaskData] = useState(null)
  const [typeTask, setTypeTask] = useState(['All', 'Task', 'Following'])
  const [activeType, setActiveType] = useState('All')
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    setHiddenColumns,
    prepareRow
  } = useTable(
    {
      columns,
      data: data || [],
      manualSortBy: true,
      initialState: {
        hiddenColumns: []
      },
      pageNum,
      isDesktop
    },
    useSortBy
  )

  useEffect(() => {
    fetchMyActivity()
  }, [search, pageNum, limit, manualSortBy, orderBy])

  function fetchMyActivity(currPage) {
    let query = {
      query: {}
    }
    if (search !== '') query.query.search = search

    if (pageNum !== '') query.query.page = currPage ? currPage : pageNum

    if (limit !== '') query.query.limit = limit

    if (manualSortBy !== '') query.query.sort_by = manualSortBy

    if (orderBy !== '') query.query.order_by = orderBy

    get(apiUrls.MY_ACTIVITIES, query).then(response => {
      const { status } = response
      if (status === 200) {
        if (pageNum > response.data?.total_page) {
          setPageNum(1)
        }
        setData(response.data.data)
        setTotalPage(response.data.total_page)
        setTotalData(response.data.total_data)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
      }
    })
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => setPageNum(data)}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 != 0
  }

  const goToNextPage = () => {
    setPageNum(pageNum + 1)
  }
  const goToPreviousPage = () => {
    setPageNum(pageNum - 1)
  }

  return (
    <div className="p-2">
      <div className="flex flex-col md:flex-row gap-2 items-center justify-between font-poppins text-[12px] mb-10">
        <div className="font-semibold text-[14px]">My Activity List</div>
        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
            />
            <select
              value={limit}
              onChange={e => {
                setLimit(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(limit => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex sm:flex-row  flex-col  ">
          <div className="flex w-full md:w-fit mr-[24px] mb-[20px] mt-3">
            <GlobalFilter filter={search} setFilter={setSearch} />
          </div>
        </div>
      </div>
      <table {...getTableProps()} className="font-poppins text-[12px]">
        <thead className="">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex gap-1">
                    {column.render('Header')}
                    <img
                      src={
                        column.canSort
                          ? column.isSorted
                            ? column.isSortedDesc
                              ? ICONS.icSortDown
                              : ICONS.icSortUp
                            : ICONS.icSort
                          : ''
                      }
                      className="w-2"
                    />
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-9">
        <div className="font-poppins text-[12px]">
          Showing {pageNum * limit - (limit - 1)} -{' '}
          {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
        </div>
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  )
}

export default MyActivityTable
