import { ICONS } from '../../../constants'

export const MENU_ICONS = {
  FORM: {
    active: ICONS.icFormGreen,
    inactive: ICONS.icForm
  },
  REPORT: {
    active: ICONS.icHeartBeatGreen,
    inactive: ICONS.icHeartBeat
  },
  HTML: {
    active: ICONS.icHtml5Green,
    inactive: ICONS.icHtml5
  },
  MANAGE_USER: {
    active: ICONS.icAdministratorGreen,
    inactive: ICONS.icAdministrator
  }
}
