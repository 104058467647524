import { ICONS, apiUrls } from '../../constants'
import { get } from '../../api/base'
import { useState, useEffect } from 'react'
import { Toast } from '../../components'
import { toast } from 'react-toastify'

const Dashboard = () => {
  const [urls, setUrls] = useState([])
  const [fullscreenIndex, setFullscreenIndex] = useState(null)

  const getDashboardUrl = () => {
    get(apiUrls.DASHBOARD_POWER_BI)
      .then(response => {
        if (response.status === 200) {
          setUrls(response.data)
        } else {
          toast.error(
            <Toast message={`Error`} detailedMessage={`${response.data?.error?.message}`} />
          )
        }
      })
      .catch(_ => toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />))
  }

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    }
  }

  const handleFullscreenClick = index => {
    if (fullscreenIndex === index) {
      exitFullscreen()
      setFullscreenIndex(null)
    } else {
      const iframe = document.querySelector(`#iframe-${index}`)
      iframe.requestFullscreen()
      setFullscreenIndex(index)
    }
  }

  useEffect(() => {
    getDashboardUrl()
  }, [])

  return urls.map((url, index) => {
    return (
      <div key={index} className="static m-5">
        <div className="flex gap-2 items-center justify-between px-2 static">
          <img
            src={ICONS.icFullScreen}
            alt="fullscreen icon"
            className="w-6 h-6 cursor-pointer ml-auto mb-2"
            onClick={() => handleFullscreenClick(index)}
          />
        </div>
        <iframe
          id={`iframe-${index}`}
          src={url.url}
          className={`block w-full h-screen border-none`}
        />
      </div>
    )
  })
}

export default Dashboard
