import React from 'react'
import Field from '../Field'
import SubSection from '../SubSection'

const Section = ({ section }) => {
  const fields = section.fields
  const innerSections = section.inner_section
  const subSections = section.sub_sections

  return (
    <div className="flex flex-col bg-[#F2F5FC] rounded-2xl p-6">
      <div className="font-semibold text-[14px] text-[#C800A5]">{section.section_title}</div>
      <hr className="mt-2 mb-6" />
      <div className="flex flex-col gap-4">
        {fields?.map((field, index) => (
          <Field key={`${field.field_id}${index}`} field={field} />
        ))}
      </div>
      {subSections?.map((section, subSectionIndex) => {
        return (
          <div key={subSectionIndex}>
            <hr className="my-6" />
            <div className="flex flex-col gap-4">
              <SubSection key={`${section.section_id}${subSectionIndex}`} section={section} />
            </div>
          </div>
        )
      })}
      {innerSections?.map((innerSectionFields, index) => {
        return (
          <div key={index}>
            <hr className="my-6" />
            <div className="flex flex-col gap-4">
              {innerSectionFields.map((field, index) => (
                <Field key={`${field.field_id}${index}`} field={field} />
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Section
