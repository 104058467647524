import { useLocation, useNavigate, useParams } from 'react-router-dom'

const SubMenu = ({ data, open, textColor }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const path = location.pathname
  const isActive = data.path === path.split('/')[6]

  const handleMenuClick = () => {
    navigate(`menu/${params.menu}/sub-menu/${data.path}`)
  }

  return (
    <li className={`flex items-center cursor-pointer`} onClick={handleMenuClick}>
      <div
        className={`${
          isActive ? 'bg-orange-400 rounded-[3px]' : 'bg-white'
        }  h-[44px] w-[5px] ml-[4px] `}
      />
      {open && (
        <span
          className={`${
            isActive ? 'bg-white font-semibold' : ''
          } font-poppins text-[14px] py-[10px] pl-8 flex-auto mr-[10px] ml-[8px] rounded-lg`}
          style={{
            color: isActive ? textColor || '#00B1B5' : 'white'
          }}
        >
          {data.name}
        </span>
      )}
    </li>
  )
}

export default SubMenu
