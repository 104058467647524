import { images } from '../../../../constants'

export default function Empty() {
  return (
    <div className="flex flex-col flex-1 justify-center items-center p-6">
      <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
      <div className="font-semibold text-[18px] mb-2">Manage Content</div>
      <div className="text-[12px] text-[#AAAAAA] mb-6 text-center">
        Once the menu is created, you can manage it’s content here.
      </div>
    </div>
  )
}
