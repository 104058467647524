import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSortBy, useTable } from 'react-table'
import { toast } from 'react-toastify'
import { get } from '../../../../../api/base'
import { Toast } from '../../../../../components'
import { ICONS, images } from '../../../../../constants'

const CollaboratorTable = ({ formId, onDeleteActionClick, updateData }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const COLUMNS = [
    {
      Header: 'Name',
      accessor: 'full_name',
      Cell: ({ row, value }) => {
        return (
          <div className="flex flex-row items-center gap-3">
            <img
              src={row.original.picture ? row.original.picture : ICONS.icAdminActive}
              className="w-6 h-6 rounded-xl"
            />
            <div>{value}</div>
          </div>
        )
      }
    },
    {
      Header: 'Action',
      Cell: ({ row, value }) => {
        const handleOnDeleteActionClick = () => {
          onDeleteActionClick(row.original)
        }
        return (
          <img
            src={ICONS.icTrash}
            className="w-6 h-6 cursor-pointer"
            onClick={handleOnDeleteActionClick}
          />
        )
      }
    }
  ]
  const columns = useMemo(() => COLUMNS, [])
  const [fullData, setFullData] = useState(null)
  const data = fullData?.data ? fullData?.data : []
  const [pageNum, setPageNum] = useState(1)
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const totalPage = fullData?.total_page
  const totalData = fullData?.total_data
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true
    },
    useSortBy
  )

  useEffect(() => {
    if (formId) {
      fetchCollaborators()
    }
  }, [formId, updateData, pageNum])

  const fetchCollaborators = () => {
    get(`/api/dynamic-forms/${formId}/collaborators`, {
      query: { limit: limit, page: pageNum }
    }).then(response => {
      const { status } = response
      if (status === 200) {
        setFullData(response.data)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`Failed to fetch collaborators`} />)
      }
    })
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map(data => {
        if (data === 0)
          return (
            <div key={data} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={data}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => setPageNum(data)}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 != 0
  }

  const goToNextPage = () => {
    setPageNum(pageNum + 1)
  }
  const goToPreviousPage = () => {
    setPageNum(pageNum - 1)
  }

  return (
    <>
      <div className="min-h-[200px]">
        <table {...getTableProps()} className="font-poppins text-[12px]">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex gap-1">
                      {column.render('Header')}
                      <img
                        src={
                          column.canSort
                            ? column.isSorted
                              ? column.isSortedDesc
                                ? ICONS.icSortDown
                                : ICONS.icSortUp
                              : ICONS.icSort
                            : ''
                        }
                        className="w-2"
                      />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {data.length === 0 && (
          <div className="flex flex-col justify-center items-center p-4">
            <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
            <div className="font-semibold text-[18px] mb-2">
              You’ve not added any user to collaborate
            </div>
            <div className="text-[12px] text-[#AAAAAA] mb-4 text-center">
              Please search user and click the “Add Collaborate” button to add it now!
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-9">
        {data.length !== 0 ? (
          <div className="font-poppins text-[12px]">
            Showing {pageNum * limit - (limit - 1)} -{' '}
            {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
          </div>
        ) : (
          <div className="font-poppins text-[12px]">No data to show</div>
        )}
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  )
}

export default CollaboratorTable
