import { Fragment, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { post } from '../../../../../../api/base'
import { apiUrls, ICONS } from '../../../../../../constants'
import { useContext } from 'react'
import { PageContext } from '../../../..'

export default function Attachment({ path, field, value, onChange, disabled }) {
  const { topColor } = useContext(PageContext)
  const inputRef = useRef()
  const { setValue } = useFormContext()

  async function onAttachmentChange({ target: { files } }) {
    if (!files.length) return

    const form = new FormData()
    for (let index = 0; index < files.length; index++) {
      form.append('files[]', files[index])
    }
    const { ok, data } = await post(`${apiUrls.FORMS_URL}/attachments`, form)
    inputRef.current.value = null
    if (!ok) return

    setValue(
      `${path}.attachment_value`,
      field.attachment_value && Array.isArray(field.attachment_value)
        ? [...field.attachment_value, ...data]
        : [...data]
    )
  }

  function removeAttachment(index) {
    const { attachment_value } = field
    setValue(`${path}.attachment_value`, [
      ...attachment_value.slice(0, index),
      ...attachment_value.slice(index + 1)
    ])
  }

  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex flex-col gap-1">
        {field.field_type.value === 'CHECKBOX_ATTACHMENT' &&
          field.options.map((option, index) => (
            <label key={index} className="flex items-center gap-2">
              <input
                type="checkbox"
                name={path}
                defaultChecked={Array.isArray(value) ? value.includes(option.label) : false}
                value={option.label}
                onChange={event => {
                  let values = Array.isArray(value) ? [...value] : []
                  if (event.target.checked) {
                    values = [...values, event.target.value]
                  } else {
                    values.splice(values.indexOf(event.target.value), 1)
                  }
                  onChange(values)
                }}
                disabled={disabled}
              />
              <div className="text-[12px]">{option.label}</div>
            </label>
          ))}

        {field.field_type.value === 'RADIO_BUTTON_ATTACHMENT' &&
          field.options.map((option, index) => (
            <label key={index} className="flex items-center gap-2">
              <input
                type="radio"
                name={path}
                value={option.value}
                defaultChecked={value === option.value}
                onChange={onChange}
                disabled={disabled}
              />
              <div className="text-[12px]">{option.label}</div>
            </label>
          ))}
      </div>
      <div className="flex flex-col gap-2">
        <hr />
        <div
          className="relative flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px]"
          style={{
            borderColor: topColor || '#00B1B5'
          }}
        >
          <input
            ref={inputRef}
            type="file"
            className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
            multiple={true}
            accept={['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'].join(',')}
            onChange={onAttachmentChange}
            disabled={disabled}
          />
          <button
            type="button"
            className="px-4 py-3 text-[12px] rounded-lg text-white font-semibold"
            style={{
              backgroundColor: topColor || '#00B1B5'
            }}
          >
            Select Files...
          </button>
          <div
            className="flex-1 text-center text-[12px]"
            style={{
              color: topColor || '#00B1B5'
            }}
          >
            Drag file here to upload
          </div>
        </div>
        {!!field.attachment_value &&
          field.attachment_value.map((attachment, a) => (
            <Fragment key={a}>
              {a > 0 && <hr className="my-2" />}
              <div className="flex items-center text-xs py-2">
                <img src={attachment.thumbnail} className="h-20 mr-2" alt="file" />
                <div>{attachment.fileName}</div>
                <img
                  src={ICONS.icTrash}
                  className="w-[28px] cursor-pointer ml-auto"
                  onClick={() => removeAttachment(a)}
                  alt="remove attachment"
                />
              </div>
            </Fragment>
          ))}
      </div>
    </div>
  )
}
