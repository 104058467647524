import React, { useState } from 'react'
import ImageConfirmation from '../../../assets/images/confirmation.png'
import { useParams } from 'react-router-dom'
import { get, post } from '../../../api/base'
import { ICONS } from '../../../constants'
import moment from 'moment'

const DetailTaskPage = () => {
  const [task, setTask] = useState(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { id } = useParams()
  React.useEffect(() => {
    fetchDetailTask()
  }, [])
  const fetchDetailTask = () => {
    get(`/api/tasks/${id}`).then(response => {
      if (response.status === 200) {
        setTask(response.data)
      }
    })
  }
  const handleStartTask = () => {
    post(`/api/tasks/${id}/start`).then(response => {
      if (response.status === 200) {
        setShowConfirmationModal(false)
        fetchDetailTask()
      }
    })
  }
  const getStringHtml = html => {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  const renderFormPreview = () => {
    return (
      <div className="flex flex-1 gap-6">
        <div className="flex flex-col flex-1 bg-white rounded-2xl p-6 ">
          <div className="font-semibold text-[16px] text-[#C800A5] mb-[27px]">History</div>
          <div className="font-normal text-[14px] text-[#C0C0C0]">
            Comment is not available now in CMS
          </div>
          <div className="font-normal text-[14px] text-[#C0C0C0] mb-[10px]">
            This feature will add soon, thank you!
          </div>
          <hr />
          <div className="flex items-center justify-between text-[12px] gap-2"></div>
        </div>
        <div className="flex flex-col flex-1 bg-white rounded-2xl p-6 max-w-[250px]">
          <div className="font-semibold text-[16px] text-[#C800A5] mb-[27px]">
            Assignee{' '}
            <span className="font-normal text-[#232323] mb-[24px]">
              {`(${task?.users.concat(task?.groups).length})`}
            </span>
          </div>
          <div className="flex flex-col gap-4">
            {task?.users.concat(task?.groups).map(user => (
              <div key={user.id} className="flex items-center gap-3">
                <img
                  src={user.picture ? user.picture : ICONS.icAdminActive}
                  className="w-6 h-6 rounded-full"
                />
                <div className="text-[#232323] text-[12px]">{user.full_name || user.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className="flex px-6 gap-[22px]">
        <div className="flex flex-1 flex-col p-6 bg-white rounded-2xl min-w-[150px] max-w-[252px]  min-h-[550px] gap-3">
          <div className="flex ">
            <div className="font-semibold text-[16px] text-[#C800A5] mb-[27px]">Task Info</div>
            <img src={ICONS.icInfo} className="w-[18px] h-[18px] ml-[8px]" onClick={() => {}} />
          </div>
          <hr />
          <div className="font-semibold text-[12px]">Title</div>
          <div className="font-normal text-[#AAAAAA] text-[12px]">{task?.title}</div>
          <div className="font-semibold text-[12px]">Planned Start</div>
          <div className="font-normal text-[#AAAAAA] text-[12px]">
            {moment(task?.planned_start).format('DD-MMM-YYYY HH:mm')}
          </div>
          <div className="font-semibold text-[12px]">Due Date</div>
          <div className="font-normal text-[#AAAAAA] text-[12px]">
            {moment(task?.due_date).format('DD-MMM-YYYY HH:mm')}
          </div>
          <div className="font-semibold text-[12px]">Description</div>
          <div className="break-words font-normal text-[#AAAAAA] text-[12px]">
            {task?.description === '<br/>' ? '' : getStringHtml(task?.description || '')}
          </div>
        </div>
        <div className="flex flex-1">{renderFormPreview()}</div>
      </div>
      <div className="flex gap-4 mt-10 ml-[24px]">
        <button
          className="py-3 px-8 border-[2px] border-[#9E7CE3] text-[#6546C3] text-[12px] font-semibold rounded-md mr-2"
          onClick={() => {}}
        >
          Back
        </button>
        {task?.status === 'OPEN_TASK' ? (
          <button
            onClick={() =>
              task?.status_progress_task === 'IN_PROGRESS' ? {} : setShowConfirmationModal(true)
            }
            className=" ml-auto mr-[24px] py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
          >
            {task?.status_progress_task === 'IN_PROGRESS' ? 'Completed Task' : 'Started Task'}
          </button>
        ) : null}
      </div>
      <div>
        <input
          type="checkbox"
          className="modal-toggle"
          checked={showConfirmationModal}
          onChange={e => e.preventDefault()}
        />
        <div className="modal bg-[#232323]/[0.5] overflow-y-auto py-6">
          <div
            className="modal-box bg-white relative w-full max-h-max max-w-max py-[60px] px-0"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex flex-col items-center min-w-[21.25rem]">
              <img
                src={ImageConfirmation}
                className="w-[120px] h-[120px] mb-9"
                alt="leave page icon"
              />
              <span className="font-semibold text-lg mb-1">Start Task</span>
            </div>
            <div className="flex justify-center">
              <div className="text-xs text-[#AAAAAA] break-words w-[300px] text-center">
                Are you sure you want to start the task?
              </div>
            </div>
            <div className="flex justify-center gap-4 mt-9">
              <button
                onClick={() => setShowConfirmationModal(false)}
                className="py-3 px-8 border-[2px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleStartTask()
                }}
                className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Start Task
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailTaskPage
