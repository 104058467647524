import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTable, useSortBy } from 'react-table'
import Select from 'react-select'
import { DropdownIndicator, Toast } from '../../../components'
import { ICONS, apiUrls, images } from '../../../constants'
import { get, put } from '../../../api/base'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { selectSites } from '../../../redux/slices/micrositeSlice'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="flex w-full md:w-fit font-poppins border-[1px] items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}

const UserList = () => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const userData = useSelector(state => state?.user?.userData)
  const location = useLocation()
  const isMicroSite = location?.pathname.includes('Site')
  const { microsite } = useParams()
  const site = useSelector(selectSites).find(
    s => s.route_name.toLowerCase() === microsite.toLowerCase()
  )
  const topColor = site?.top_color || (isMicroSite ? '#00B1B5' : '')
  const bottomColor = site?.bottom_color || (isMicroSite ? '#00B1B5' : '')

  const multipleInputStyle = {
    option: provided => ({
      ...provided
    }),
    control: provided => ({
      ...provided,
      ':focus': { borderColor: topColor || '#6546C3' },
      ':active': { borderColor: topColor || '#6546C3' },
      ':hover': { borderColor: topColor || '#6546C3' },
      paddingLeft: 4,
      boxShadow: 'none'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#C800A5',
      ':hover': { color: topColor || '#6546C3' }
    }),
    indicatorSeparator: () => ({}),
    valueContainer: provided => ({
      ...provided
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: '#fff',
      fontFamily: 'poppins'
    }),
    multiValueRemove: provided => ({
      ...provided,
      color: '#fff'
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: topColor || '#6546C3',
      borderRadius: 8
    })
  }
  const isDefaultFilter = userData?.site_admin && userData?.company && userData?.work_location
  const COLUMNS = [
    {
      Header: 'Username',
      accessor: 'username',
      Cell: ({ value }) => {
        return value
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => {
        return value
      }
    },
    {
      Header: 'Role',
      accessor: 'groups',
      Cell: ({ value }) => {
        return (
          <div className="flex gap-2 flex-col xl:flex-row">
            {value.map((role, index) => (
              <div
                key={index}
                className="text-center rounded-[8px] bg-[#C800A5]/[0.08306085495721721] font-poppins text-[#C800A5] text-[12px] font-semibold px-3 py-[7px] mt-2 mb-2"
              >
                {role.name}
              </div>
            ))}
          </div>
        )
      }
    },
    {
      Header: 'Company',
      accessor: 'company',
      Cell: ({ value }) => {
        return <div>{value}</div>
      }
    },
    {
      Header: 'Work Location',
      accessor: 'work_location',
      Cell: ({ value }) => {
        return value
      }
    },
    {
      Header: 'Status',
      accessor: 'is_active',
      Cell: ({ row, value, pageNum }) => {
        const userId = row.original.id
        const checked = value
        const checkedClass =
          "w-11 h-6 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all after:translate-x-full after:border-white "
        const unCheckedClass =
          "w-11 h-6 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all"

        const handleStatusChange = (event, id) => {
          const isActive = event.target.checked
          put(`/api/users/manage-user/${id}`, { is_active: isActive }).then(response => {
            const { status, data } = response
            if (status === 200) {
              toast.success(
                <Toast
                  message={`Success`}
                  detailedMessage={`Successfully changing status to ${
                    isActive ? 'Active' : 'Inactive'
                  }.`}
                />
              )
            } else {
              toast.error(
                <Toast
                  message={`Error`}
                  detailedMessage={`Error changing status to ${isActive ? 'Active' : 'Inactive'}. ${
                    data.error.message
                  }`}
                />
              )
            }
            fetchUsers(pageNum)
          })
        }

        return (
          <label
            htmlFor={`status-toggle-${userId}`}
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              id={`status-toggle-${userId}`}
              className="sr-only peer"
              checked={checked}
              onChange={event => handleStatusChange(event, row.original.id)}
            />
            <div
              className={checked ? checkedClass : unCheckedClass}
              style={{
                background: checked ? topColor || '#6546C3' : '#e5e7eb'
              }}
            ></div>
          </label>
        )
      }
    },
    {
      Header: 'User Type',
      accessor: 'type',
      Cell: ({ value }) => {
        if (value[0] === 'Internal') {
          return <span className="font-semibold italic text-[12px] text-[#6546C3]">Internal</span>
        } else {
          return <span className="font-semibold italic text-[12px] text-[#FF7F57]">External</span>
        }
      }
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const isEditable =
          (userData?.company === row.original.company &&
            userData?.work_location === row.original.work_location) ||
          !userData?.site_admin

        const handleEditUserClick = () => {
          if (isMicroSite) {
            const siteCrumbs = 'Manage User/Edit User'.split('/')
            navigate(`edit-user/${row.original.id}`, {
              state: { title: 'Edit User', crumbs: siteCrumbs }
            })
          } else {
            const EDIT_USER_PATH = `/Admin/ManageUser/EditUser/${row.original.id}`
            const EDIT_USER_CRUMBS = 'Admin/Manage User/Edit User'
            const crumbs = EDIT_USER_CRUMBS.split('/')
            navigate(EDIT_USER_PATH, {
              state: { title: 'Edit User', crumbs: crumbs }
            })
          }
        }
        const handleDuplicateUserClick = () => {
          const siteCrumbs = 'Manage User/Duplicate User'.split('/')
          navigate(`duplicate-user/${row.original.id}`, {
            state: { title: 'Duplicate User', crumbs: siteCrumbs }
          })
        }
        return (
          <div className="flex flex-row w-full">
            {isEditable &&
              (topColor ? (
                <div className="relative">
                  <div
                    className="h-[28px] min-w-[28px] rounded-full"
                    style={{
                      background: topColor || '#6546C3',
                      opacity: '0.08'
                    }}
                  ></div>
                  <div
                    className="h-[22px] w-[22px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
                    style={{
                      background: bottomColor
                        ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                        : topColor
                    }}
                  ></div>
                  <img
                    src={ICONS.icEditTransparent}
                    className="w-[14px] h-[14px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                    onClick={() => handleEditUserClick()}
                    alt="edit icon"
                  />
                </div>
              ) : (
                <img
                  src={ICONS.icEdit}
                  className="w-[28px] cursor-pointer"
                  onClick={() => handleEditUserClick()}
                  alt="edit icon"
                />
              ))}
            {isMicroSite &&
              (topColor ? (
                <div className="relative">
                  <div
                    className="h-[28px] min-w-[28px] rounded-full"
                    style={{
                      background: topColor || '#6546C3',
                      opacity: '0.08'
                    }}
                  ></div>
                  <div
                    className="h-[22px] w-[22px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"
                    style={{
                      background: bottomColor
                        ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                        : topColor
                    }}
                  ></div>
                  <img
                    src={ICONS.icDuplicateTransparent}
                    className="w-[14px] h-[14px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                    onClick={() => handleDuplicateUserClick()}
                    alt="edit icon"
                  />
                </div>
              ) : (
                <img
                  src={ICONS.icDuplicate}
                  className="w-[28px] cursor-pointer"
                  onClick={() => handleDuplicateUserClick()}
                  alt="edit icon"
                />
              ))}
          </div>
        )
      }
    }
  ]
  const columns = useMemo(() => COLUMNS, [])
  const [fullData, setFullData] = useState(null)
  const data = fullData?.data ? fullData?.data : []
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const totalPage = fullData?.total_page
  const totalData = fullData?.total_data
  const [roleFilter, setRoleFilter] = useState('')
  const [companyFilter, setCompanyFilter] = useState(isDefaultFilter ? userData?.company : '')
  const [workLocationFilter, setWorkLocationFilter] = useState(
    isDefaultFilter ? userData?.work_location : ''
  )
  const [statusFilter, setStatusFilter] = useState('')
  const [tagFilter, setTagFilter] = useState('')
  const [companyOptions, setCompanyOptions] = useState([])
  const [roleOptions, setRoleOptions] = useState([])
  const [workLocationOptions, setWorkLocationOptions] = useState([])
  const [tagOptions, setTagOptions] = useState([])
  const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    setHiddenColumns,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        hiddenColumns: []
      },
      pageNum
    },
    useSortBy
  )

  useEffect(() => {
    if (isDesktop) {
      setHiddenColumns([])
    } else {
      setHiddenColumns(['email', 'company', 'work_location', 'type'])
    }
  }, [isDesktop])

  const customSelectInputStyle = {
    control: provided => ({
      ...provided,
      borderColor: '#DEDEDE',
      borderRadius: '8px',
      ':focus': { borderColor: '#6546C3' },
      ':active': { borderColor: '#6546C3' },
      ':hover': { borderColor: '#6546C3' },
      paddingLeft: 4,
      boxShadow: 'none'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#C800A5',
      ':hover': { color: '#6546C3' }
    }),
    indicatorSeparator: () => ({})
  }

  useEffect(() => {
    if (sortBy.length > 0) {
      setManualSortBy(sortBy[0].id)
      setOrderBy(sortBy[0].desc ? 'desc' : 'asc')
    } else {
      setManualSortBy('')
      setOrderBy('')
    }
  }, [sortBy])

  useEffect(() => {
    // Populate role options
    fetchRoles()

    // Populate company options
    fetchCompanies()

    // Populate work location options
    fetchWorkLocations()

    // Populate tag options
    fetchTags()
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [
    search,
    pageNum,
    limit,
    manualSortBy,
    orderBy,
    roleFilter,
    companyFilter,
    workLocationFilter,
    statusFilter,
    tagFilter
  ])

  function fetchRoles() {
    get(apiUrls.GROUPS_URL).then(response => {
      const { status, data } = response
      if (status === 200) {
        populateRoleOptions(data.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch roles'} />)
      }
    })
  }

  function fetchCompanies() {
    get(apiUrls.COMPANIES).then(response => {
      const { status, data } = response
      if (status === 200) {
        populateCompanyOptions(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch companies'} />)
      }
    })
  }

  const fetchTags = () => {
    get(apiUrls.TAGS).then(response => {
      const { ok, data } = response
      if (ok) {
        populateTags(data)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch tags'} />)
      }
    })
  }

  function populateCompanyOptions(data) {
    const options = data.map(option => {
      return { label: option.name, value: option.name }
    })
    setCompanyOptions(options)
  }

  function populateRoleOptions(data) {
    const options = data.map(option => ({ label: option.name, value: option.id }))
    setRoleOptions(options)
  }

  function populateWorkLocation(data) {
    const options = data.map(option => ({ label: option.name, value: option.name }))
    setWorkLocationOptions(options)
  }

  function populateTags(data) {
    const options = data.map(option => ({ label: option.name, value: option.name }))
    setTagOptions(options)
  }

  function fetchWorkLocations() {
    get(apiUrls.ORGANIZATION_MASTER, {
      query: { organization_column: 'WORK_LOCATION' }
    }).then(response => {
      const { status, data } = response
      if (status === 200) {
        populateWorkLocation(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch work locations'} />)
      }
    })
  }

  function fetchUsers(currPage) {
    let query = {
      query: {}
    }
    if (search !== '') query.query.search = search

    if (pageNum !== '') query.query.page = currPage ? currPage : pageNum

    if (limit !== '') query.query.limit = limit

    if (manualSortBy !== '') query.query.sort_by = manualSortBy

    if (orderBy !== '') query.query.order_by = orderBy

    if (roleFilter !== '') query.query.group = roleFilter

    if (companyFilter !== '') query.query.company = companyFilter

    if (workLocationFilter !== '') query.query.work_location = workLocationFilter

    if (statusFilter !== '') query.query.status = statusFilter

    if (tagFilter !== '') query.query.tag = tagFilter

    get(`/api/users/manage-user`, query).then(response => {
      const { status, data } = response
      if (status === 200) {
        if (pageNum > data?.total_page) {
          setPageNum(1)
        }
        setFullData(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch users'} />)
      }
    })
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data, index) => {
        if (data === 0)
          return (
            <div key={index} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={index}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'text-white' : ''
            }`}
            style={{
              background:
                pageNum === data
                  ? bottomColor
                    ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                    : topColor || 'linear-gradient(to right, #6546C3, #9E7CE3)'
                  : ''
            }}
            onClick={() => {
              setPageNum(data)
            }}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function handleAddUserClick() {
    if (isMicroSite) {
      const ADD_USER_PATH = 'add-user'
      const ADD_USER_CRUMBS = 'Manage User/Add User'
      const crumbs = ADD_USER_CRUMBS.split('/')
      navigate(ADD_USER_PATH, { state: { title: 'Add User', crumbs: crumbs } })
    } else {
      const ADD_USER_PATH = '/Admin/ManageUser/AddUser'
      const ADD_USER_CRUMBS = 'Admin/Manage User/Add User'
      const crumbs = ADD_USER_CRUMBS.split('/')
      navigate(ADD_USER_PATH, { state: { title: 'Add User', crumbs: crumbs } })
    }
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 !== 0
  }

  const goToNextPage = () => {
    setPageNum(prev => {
      return prev + 1
    })
  }
  const goToPreviousPage = () => {
    setPageNum(prev => {
      return prev - 1
    })
  }

  const handleMultipleFilterChange = (type, selectedOptions) => {
    const values = selectedOptions.map(selectedOption => selectedOption.value)
    switch (type) {
      case 'Role':
        setRoleFilter(values)
        break
      case 'Company':
        setCompanyFilter(values)
        break
      case 'WorkLocation':
        setWorkLocationFilter(values)
        break
      case 'Tag':
        setTagFilter(values)
      default:
        break
    }
  }

  return (
    <>
      <div className="flex flex-col flex-1 p-6 bg-[#F2F5FC] rounded-2xl gap-2">
        <div className="font-poppins font-semibold text-[16px] text-[#C800A5]">Filter</div>
        <div className="flex flex-col lg:flex-row items-center justify-between gap-6">
          <Select
            isClearable
            isMulti={isMicroSite}
            components={{ DropdownIndicator }}
            onChange={selectedOption => {
              isMicroSite
                ? handleMultipleFilterChange('Role', selectedOption)
                : setRoleFilter(selectedOption?.value)
            }}
            name="Role"
            className={`input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            placeholder="Role"
            styles={isMicroSite ? multipleInputStyle : customSelectInputStyle}
            options={roleOptions}
          />
          <Select
            isMulti={isMicroSite}
            components={{ DropdownIndicator }}
            onChange={selectedOption =>
              isMicroSite
                ? handleMultipleFilterChange('Company', selectedOption)
                : setCompanyFilter(selectedOption?.value)
            }
            defaultValue={isDefaultFilter && { label: userData?.company, value: userData?.company }}
            name="Company"
            isClearable
            className={`input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            placeholder="Company"
            styles={isMicroSite ? multipleInputStyle : customSelectInputStyle}
            options={companyOptions}
          />
          <Select
            isMulti={isMicroSite}
            components={{ DropdownIndicator }}
            isClearable
            onChange={selectedOption =>
              isMicroSite
                ? handleMultipleFilterChange('WorkLocation', selectedOption)
                : setWorkLocationFilter(selectedOption?.value)
            }
            name="Work Location"
            defaultValue={
              isDefaultFilter && { label: userData?.work_location, value: userData?.work_location }
            }
            className={`input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            placeholder="Work Location"
            styles={isMicroSite ? multipleInputStyle : customSelectInputStyle}
            options={workLocationOptions}
          />
          <Select
            isMulti={isMicroSite}
            isClearable
            components={{ DropdownIndicator }}
            onChange={selectedOption => setStatusFilter(selectedOption?.value)}
            options={statusOptions}
            name="Status"
            className={`input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
            placeholder="Status"
            styles={isMicroSite ? multipleInputStyle : customSelectInputStyle}
          />
          {isMicroSite && (
            <Select
              isMulti
              components={{ DropdownIndicator }}
              isClearable
              onChange={selectedOption => handleMultipleFilterChange('Tag', selectedOption)}
              name="Tag"
              className={`input-select text-[12px] rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
              placeholder="Tag"
              styles={multipleInputStyle}
              options={tagOptions}
            />
          )}
        </div>
      </div>
      <hr className="my-6" />
      <div className="flex flex-col md:flex-row gap-2 items-center justify-between font-poppins text-[12px] mb-10">
        <button
          className="flex w-full md:w-fit justify-center text-white font-semibold rounded-md px-8 py-3"
          style={{
            background: topColor || 'linear-gradient(to right, #6546C3, #9E7CE3)'
          }}
          onClick={() => handleAddUserClick()}
        >
          Add User
        </button>
        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
            />
            <select
              value={limit}
              onChange={e => {
                setLimit(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(limit => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex w-full md:w-fit">
          <GlobalFilter filter={search} setFilter={setSearch} />
        </div>
      </div>
      <div className="overflow-x-auto w-full">
        <table {...getTableProps()} className="w-full font-poppins text-[12px]">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex gap-1">
                      {column.render('Header')}
                      <img
                        src={
                          column.canSort
                            ? column.isSorted
                              ? column.isSortedDesc
                                ? ICONS.icSortDown
                                : ICONS.icSortUp
                              : ICONS.icSort
                            : ''
                        }
                        className="w-2"
                      />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {data.length === 0 && (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
          <div className="font-semibold text-[18px] mb-2">You’ve not added any user yet</div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "add user" button to add it now!
          </div>
        </div>
      )}
      <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-9">
        {data.length !== 0 ? (
          <div className="font-poppins text-[12px]">
            Showing {pageNum * limit - (limit - 1)} -{' '}
            {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
          </div>
        ) : (
          <div className="font-poppins text-[12px]">No data to show</div>
        )}
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage() ? '' : 'text-white'
            }`}
            style={{
              background: !canPreviousPage()
                ? '#F2F5FC'
                : bottomColor
                ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                : topColor || 'linear-gradient(to right, #6546C3, #9E7CE3)'
            }}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage() ? '' : 'text-white'
            }`}
            style={{
              background: !canNextPage()
                ? '#F2F5FC'
                : bottomColor
                ? `linear-gradient(to right, ${topColor}, ${bottomColor})`
                : topColor || 'linear-gradient(to right, #6546C3, #9E7CE3)'
            }}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  )
}

export default UserList
