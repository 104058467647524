import React, { createContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import moment from 'moment'
import { format } from 'date-fns'
import { Toast } from '../../../components'
import { apiUrls, ICONS, images } from '../../../constants'
import 'react-datepicker/dist/react-datepicker.css'
import Section from './components/Section'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { get, post, put } from '../../../api/base'
import { toast } from 'react-toastify'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setFields } from '../../../redux/slices/masterSlice'
import { DropdownIndicator } from '../../../components'
import { v4 as uuidv4 } from 'uuid'
import SuccessPopup from '../../../components/Popup/SuccessPopup'
import MasterDataModal from './components/MasterDataModal'
import AdvancedSettingsModal from './components/AdvancedSettingsModal'
import ScrollToBottom from '../../../components/ScrollToBottom'
import { setShowLoading, setShowPopupLeavePage } from '../../../redux/slices/popupSlice'
import LeavePagePopup from '../../../components/Popup/LeavePagePopup'
import LoadingPopup from '../../../components/Popup/LoadingPopup'
import JumpSettingsModal from './components/JumpSettingsModal'

export const MasterDataContext = createContext(null)
export const TagsContext = createContext(null)
export const FormContext = createContext(null)

const EditFormPage = () => {
  const { id } = useParams()
  const [form, setForm] = useState({
    code_form: '',
    title: '',
    ref_number: '',
    company: [],
    attachment: true,
    last_submission: '',
    assign_to: [],
    assign_to_group: [],
    allow_share_respon: false,
    sections: [],
    is_active: false,
    point: null,
    running_number: { title: '', format: '' },
    module_id: {},
    is_segregate_by_tags: false,
    tags: [],
    advanced_settings: null,
    location: false,
    editable: false,
    print_approval_format: { format: '', params: [], exclude_box_approval: false },
    params_wfe: null,
    different_requestor: false,
    image_resolution: null,
    form_size: null,
    form_method: null,
    form_type: null,
    custom_tablet_layout: null,
    linkage: null,
    api: []
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [listOfMembers, setListOfMembers] = useState([])
  const [totalMemberChecked, setTotalMemberChecked] = useState(0)
  const [searchMemberQuery, setSearchMemberQuery] = useState('')
  const [selectAllMember, setSelectAllMember] = useState(false)
  const [listOfGroups, setListOfGroups] = useState([])
  const [totalGroupChecked, setTotalGroupChecked] = useState(0)
  const [searchGroupQuery, setSearchGroupQuery] = useState('')
  const [selectAllGroup, setSelectAllGroup] = useState(false)
  const [isWorkflow, setIsWorkflow] = useState(false)
  const [selectedAttachmentOption, setSelectedAttachmentOption] = useState(null)
  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null)
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState(null)
  const [selectedWorkLocation, setSelectedWorkLocation] = useState(null)
  const attachmentOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ]
  const [companyOptions, setCompanyOptions] = useState([])
  const [lastSubmissionDate, setLastSubmissionDate] = useState(null)
  const [workflowModules, setWorkflowModules] = useState([])
  const [activeTab, setActiveTab] = useState('users')
  const [listWorkLocation, setListWorkLocation] = useState([])
  const [sectionsOriginal, setSectionsOriginal] = useState([])
  const [isFormTypeSystem, setIsFormTypeSystem] = useState(false)

  useEffect(() => {
    fetchWorkflowModules()
    fetchFields()
    fetchCompanies()
    fetchMasterData()
    fetchWorkLocation()
    fetchGroups()
  }, [])

  useEffect(() => {
    fetchFormDetail()
  }, [workflowModules])

  useEffect(() => {
    // Populate company
    const companies = form.company.map(company => {
      return { label: company, value: company }
    })
    setSelectedCompanyOptions(companies)
    setSelectedCompanyOption(form.company)

    // Populate attachment
    const selectedAttachment = {
      label: form?.attachment ? 'Yes' : 'No',
      value: form?.attachment ? 'Yes' : 'No'
    }
    setSelectedAttachmentOption(selectedAttachment)

    // Populate last submission
    if (form?.last_submission) {
      const lastSubmission = form?.last_submission
      const format = 'DD-MMM-YY HH:mm'

      const dateFormatted = moment(lastSubmission, format).format(format)
      const isValidFormat = lastSubmission === dateFormatted

      const lastSubmissionDate = isValidFormat
        ? moment(lastSubmission, format).toDate()
        : moment(lastSubmission).toDate()

      setLastSubmissionDate(lastSubmissionDate)
    }

    // Populate assign to
    const checkMemberIds = form?.assign_to
    const newMembers = listOfMembers?.map(member => {
      const isOnTheList = checkMemberIds.includes(member.id)
      return isOnTheList ? { ...member, checked: true } : member
    })
    setListOfMembers(newMembers)

    // Populate assign to group
    const checkGroupIds = form?.assign_to_group
    if (checkGroupIds.length) {
      const newGroups = listOfGroups?.map(group => {
        const isOnTheList = checkGroupIds.includes(group.id)
        return isOnTheList ? { ...group, checked: true } : group
      })
      setListOfGroups(newGroups)
    }
  }, [form])

  useEffect(() => {
    let counter = 0
    const listOfMembersLength = listOfMembers.length
    listOfMembers.map(member => {
      if (member.checked) {
        counter++
      }
    })
    setTotalMemberChecked(counter)
    setSelectAllMember(listOfMembersLength === counter && counter !== 0)
  }, [listOfMembers])

  useEffect(() => {
    let counter = 0
    const listOfGroupsLength = listOfGroups.length
    listOfGroups.map(group => {
      if (group.checked) {
        counter++
      }
    })
    setTotalGroupChecked(counter)
    setSelectAllGroup(listOfGroupsLength === counter && counter !== 0)
  }, [listOfGroups])

  useEffect(() => {
    if (selectedCompanyOption && selectedCompanyOption.length > 0) {
      fetchMembers()
    } else {
      populateMemberList([])
    }
  }, [selectedCompanyOption, isWorkflow])

  const fetchFormDetail = () => {
    get(`/api/dynamic-forms/:id`, { params: { id: id } }).then(response => {
      const original = form
      const isDuplicate = searchParams.has('duplicate')

      const { status } = response
      if (status === 200) {
        let form = response.data
        delete form.id
        delete form.users
        delete form.groups
        delete form.created_at
        delete form.full_access

        let choosenWorflow

        if (form.module_id !== null) {
          setIsWorkflow(true)
          choosenWorflow = workflowModules.filter(el => el.id === form.module_id)
        }
        setIsFormTypeSystem(form.form_type === 'SYSTEM')

        setSectionsOriginal([...form.sections])

        setForm({
          ...original,
          ...form,
          editable: isDuplicate ? true : form.editable,
          code_form: isDuplicate ? '' : form.code_form,
          module_id: !!form.module_id ? choosenWorflow[0] : form.module_id,
          tags: form.tags || [],
          sections: isDuplicate
            ? form.sections.map(s => ({
                ...s,
                section_id: uuidv4(),
                fields: s.fields.map(f => ({
                  ...f,
                  field_id: uuidv4()
                })),
                sub_sections:
                  s.sub_sections?.map(ss => ({
                    ...ss,
                    section_id: uuidv4(),
                    fields: ss.fields.map(f => ({
                      ...f,
                      field_id: uuidv4()
                    }))
                  })) || []
              }))
            : form.sections
        })
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
      }
    })
  }

  const handleOnCheckAllMember = event => {
    var newListOfMembers = listOfMembers.map(member => {
      return { ...member, checked: event.target.checked }
    })
    setListOfMembers(newListOfMembers)
    setSelectAllMember(event.target.checked)

    var checkedMembers = newListOfMembers.filter(member => {
      if (member.checked) {
        return member
      }
    })
    var assignTo = checkedMembers.map(member => {
      return member.id
    })
    setForm({ ...form, assign_to: assignTo })
  }

  const handleOnChangeCheckMember = (id, event) => {
    var newListOfMembers = listOfMembers.map(member =>
      member.id === id ? { ...member, checked: event.target.checked } : member
    )
    setListOfMembers(newListOfMembers)

    var checkedMembers = newListOfMembers.filter(member => {
      if (member.checked) {
        return member
      }
    })
    var assignTo = checkedMembers.map(member => {
      return member.id
    })
    setForm({ ...form, assign_to: assignTo })
  }
  const handleOnCheckAllGroup = event => {
    var newListOfGroups = listOfGroups.map(group => {
      return { ...group, checked: event.target.checked }
    })
    setListOfGroups(newListOfGroups)
    setSelectAllGroup(event.target.checked)

    var checkedGroups = newListOfGroups.filter(group => {
      if (group.checked) {
        return group
      }
    })
    var assignTo = checkedGroups.map(group => {
      return group.id
    })
    setForm({ ...form, assign_to_group: assignTo })
  }

  const handleOnChangeCheckGroup = (id, event) => {
    var newListOfGroups = listOfGroups.map(group =>
      group.id === id ? { ...group, checked: event.target.checked } : group
    )
    setListOfGroups(newListOfGroups)

    var checkedGroups = newListOfGroups.filter(group => {
      if (group.checked) {
        return group
      }
    })
    var assignTo = checkedGroups.map(group => {
      return group.id
    })
    setForm({ ...form, assign_to_group: assignTo })
  }

  const handleOnChangeWorkLocation = selectedOptions => {
    setSelectedWorkLocation(selectedOptions)
  }

  const handleOnDragEnd = result => {
    if (!result.destination) return
    const items = Array.from(form.sections)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setForm({ ...form, sections: items })
  }

  const handleOnAddSection = () => {
    const sectionId = uuidv4()
    const newSection = {
      section_id: sectionId,
      section_title: '',
      multiple_input: false,
      fields: []
    }
    const newSections = [...form.sections, newSection]

    setForm({ ...form, sections: newSections })
  }

  const handleOnDeleteSection = index => {
    const newSections = form.sections.filter((section, i) => i !== index)
    setForm({ ...form, sections: newSections })
  }

  const handleOnCodeFormChange = event => {
    setForm({ ...form, code_form: event.target.value })
  }
  const handleOnRefNumberChange = event => {
    setForm({ ...form, ref_number: event.target.value })
  }
  const handleOnAttachmentChange = selectedOption => {
    setForm({ ...form, attachment: selectedOption.value === 'Yes' })
  }
  const handleOnTitleChange = event => {
    setForm({ ...form, title: event.target.value })
  }
  const handleOnCompanyChange = selectedOptions => {
    const companies = selectedOptions.map(selectedOption => selectedOption.value)
    setForm({ ...form, company: companies })
    setSelectedCompanyOption(companies)
    setSelectedCompanyOptions(selectedOptions)
  }
  const handleOnLastSumbissionChange = date => {
    setLastSubmissionDate(date)
    if (date) {
      const formatedDate = format(date, 'yyyy-MM-dd HH:mm')
      setForm({ ...form, last_submission: formatedDate })
    } else {
      setForm({ ...form, last_submission: '' })
    }
  }
  const handleOnAllowShareChange = event => {
    setForm({ ...form, allow_share_respon: event.target.checked })
  }
  const handleOnStatusChange = event => {
    setForm({ ...form, is_active: event.target.checked })
  }

  const fetchCompanies = () => {
    get(apiUrls.REGISTERED_COMPANY).then(response => {
      const { status } = response
      if (status === 200) {
        populateCompanyDropdown(response.data)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
      }
    })
  }

  const populateCompanyDropdown = companies => {
    const newCompanyOptions = companies.map(company => {
      if (company != null) {
        return { value: company, label: company }
      } else {
        return { value: '', label: '' }
      }
    })
    setCompanyOptions(newCompanyOptions)
  }

  const fetchMembers = async () => {
    const query = {
      query: {
        company: form.company,
        workflow_user: isWorkflow || ''
      }
    }
    get(apiUrls.REGISTERED_MEMBER, query).then(response => {
      const { status } = response
      if (status === 200) {
        populateMemberList(response.data)
      } else {
        populateMemberList([])
      }
    })
  }

  const populateMemberList = members => {
    const newListOfMembers = members.map(member => {
      if (form?.assign_to.includes(member.id)) {
        return { id: member.id, name: member.full_name || member.username, checked: true }
      } else {
        return { id: member.id, name: member.full_name || member.username, checked: false }
      }
    })
    setListOfMembers(newListOfMembers)
  }

  const fetchGroups = async () => {
    get(apiUrls.FORMS_GROUP_URL).then(response => {
      const { status } = response
      if (status === 200) {
        populateGroupList(response.data?.groups)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        populateGroupList([])
      }
    })
  }

  const populateGroupList = groups => {
    const newListOfGroups = groups.map(group => {
      return {
        id: group.id,
        name: group.name,
        work_location: group.work_location,
        picture: group.picture,
        checked: false
      }
    })
    setListOfGroups(newListOfGroups)

    var checkedGroups = newListOfGroups.filter(group => {
      if (group.checked) {
        return group
      }
    })
    var assignTo = checkedGroups.map(group => {
      return group.id
    })
    setForm({ ...form, assign_to_group: assignTo })
  }

  const fetchWorkLocation = async () => {
    get(apiUrls.WORK_LOCATIONS).then(response => {
      const { status } = response
      if (status === 200) {
        populateWorkLocation(response.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        populateWorkLocation([])
      }
    })
  }

  const populateWorkLocation = workLocations => {
    const newListOfWorkLocations = workLocations.map(workLocation => {
      return {
        value: workLocation.id,
        label: workLocation.name
      }
    })
    setListWorkLocation(newListOfWorkLocations)
  }

  const handleSubmitForm = () => {
    if (formValidation()) {
      dispatch(setShowLoading(true))
      const formSend = {
        ...form,
        running_number: form.running_number?.format ? form.running_number : null,
        print_approval_format:
          getStringHtml(form.print_approval_format?.format).trim() !== ''
            ? form.print_approval_format
            : null,
        module_id: form.module_id?.id || null,
        module_name: form.module_id?.name || null,
        all_member: selectAllMember,
        all_group: selectAllGroup
      }
      delete formSend.updated_at
      delete formSend.editable
      delete formSend.collaborators
      delete formSend.children
      delete formSend.master_stock_forms

      if (searchParams.has('duplicate')) {
        post(apiUrls.FORMS_URL, formSend).then(response => {
          const { status, data } = response
          dispatch(setShowLoading(false))
          if (status === 201) {
            toast.success(
              <Toast
                message={`Success`}
                detailedMessage={`You have successfully duplicated form`}
              />
            )
            navigate('/DocumentLibrary/Form', {
              state: { title: 'Form', crumbs: ['Document Library', 'Form'] }
            })
          } else if (status === 401) {
            toast.error(<Toast message={`Error`} detailedMessage={`Unauthorized user.`} />)
          } else if (status === 422) {
            toast.error(
              <Toast
                message={`Error`}
                detailedMessage={`Failed to duplicate form. Invalid Data. ${
                  data.error.errors[0].message || data.error.errors[0][0].message
                }`}
              />
            )
          } else if (status === 500) {
            toast.error(
              <Toast message={`Server Error`} detailedMessage={`Failed to duplicate form.`} />
            )
          } else if (status === 408) {
            toast.error(
              <Toast
                message={`Error`}
                detailedMessage={`Looks like the server is taking too long to respond. Please try again in while if still no update.`}
              />
            )
          }
        })
      } else {
        put(`/api/dynamic-forms/:id`, formSend, { params: { id: id } }).then(response => {
          const { status, data } = response
          dispatch(setShowLoading(false))
          if (status === 200) {
            toast.success(
              <Toast message={`Success`} detailedMessage={`You have successfully edit form`} />
            )
            navigate('/DocumentLibrary/Form', {
              state: { title: 'Form', crumbs: ['Document Library', 'Form'] }
            })
          } else if (status === 401) {
            toast.error(<Toast message={`Error`} detailedMessage={`Unauthorized user.`} />)
          } else if (status === 422) {
            toast.error(
              <Toast
                message={`Error`}
                detailedMessage={`Failed to add new form. Invalid Data. ${
                  data.error.errors[0].message || data.error.errors[0][0].message
                }`}
              />
            )
          } else if (status === 403) {
            toast.error(
              <Toast
                message={`Failed`}
                detailedMessage={`Failed to add new form. ${data.error.message}`}
              />
            )
          } else if (status === 500) {
            toast.error(
              <Toast message={`Server Error`} detailedMessage={`Failed to add new form.`} />
            )
          }
        })
      }
    }
  }

  const fetchFields = () => {
    get(apiUrls.FORM_FIELDS_URL).then(response => {
      const { status } = response
      if (status == 200) {
        dispatch(setFields(response.data))
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
      }
    })
  }

  const getStringHtml = html => {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  const formValidation = () => {
    let isFormValid = true

    const excludedInputText = [
      'Last submission',
      'Running Number Title',
      'Running Number Format',
      'Row',
      'Column',
      'Text Field'
    ]
    // Text Input Validation
    const textInputs = document.getElementsByClassName('input-text')
    Array.from(textInputs).forEach(function (input) {
      if (input.value === '' && !excludedInputText.includes(input.name)) {
        isFormValid = false
        toast.error(
          <Toast message={`Error`} detailedMessage={`${input.name} should not be empty`} />
        )
        input.focus()
      }
    })

    const excludedInputSelect = [
      'Work location',
      'Tag',
      'Image Resolution',
      'Form Size',
      'Form Method',
      'Form Type'
    ]
    // Dropdown/Select validation
    const selecInputs = document.querySelectorAll('input[type=hidden]')
    Array.from(selecInputs).forEach(function (input) {
      if (input.value === '' && !excludedInputSelect.includes(input.name)) {
        isFormValid = false
        toast.error(
          <Toast message={`Error`} detailedMessage={`${input.name} should not be empty`} />
        )
        input.focus()
      }
    })

    // Assign form validation
    if (totalMemberChecked === 0 && totalGroupChecked === 0) {
      isFormValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Atleast 1 member or 1 group need to be assigned to this form`}
        />
      )
    }

    if (
      form.sections.some(
        s =>
          s.fields.some(f => f.is_segregate_by_tags && !f.tags.length) ||
          s.sub_sections?.some(ss => ss.fields.some(f => f.is_segregate_by_tags && !f.tags.length))
      )
    ) {
      isFormValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage="At least 1 tag selected when Segregate by Tags is true. Please check any field's tags."
        />
      )
    }

    // Static Field Validation
    if (
      form.sections.some(
        s =>
          s.fields.some(
            f => f.field_type.value === 'STATIC_FIELD' && getStringHtml(f.description).trim() === ''
          ) ||
          s.sub_sections?.some(ss =>
            ss.fields.some(
              f =>
                f.field_type.value === 'STATIC_FIELD' && getStringHtml(f.description).trim() === ''
            )
          )
      )
    ) {
      isFormValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage="Content for fields with static field type is required!"
        />
      )
    }

    // Minimum section and field validation
    const sections = form.sections
    if (sections.length === 0) {
      isFormValid = false
      toast.error(
        <Toast message={`Error`} detailedMessage={`Form should have atleast 1 section`} />
      )
    } else {
      sections.map(section => {
        const fields = section.fields
        if (fields.length === 0) {
          isFormValid = false
          toast.error(
            <Toast message={`Error`} detailedMessage={`Section should have atleast 1 field`} />
          )
        }
      })
    }

    // Section name should not be duplicate
    const sectionTitles = sections.map(section => {
      const fields = section.fields

      // Field name should not be duplicate
      const fieldTitles = fields.map(field => {
        return field.field_title
      })
      const isFieldTitleDuplicate = fieldTitles.some(function (fieldTitle, i) {
        return fieldTitles.indexOf(fieldTitle) != i
      })
      if (isFieldTitleDuplicate) {
        isFormValid = false
        toast.error(<Toast message={`Error`} detailedMessage={`Duplicate Field Title`} />)
      }
      return section.section_title
    })
    const isSectionTitleDuplicate = sectionTitles.some(function (sectionTitle, idx) {
      return sectionTitles.indexOf(sectionTitle) != idx
    })
    if (isSectionTitleDuplicate) {
      isFormValid = false
      toast.error(<Toast message={`Error`} detailedMessage={`Duplicate Section Title`} />)
    }

    // If form have sub sections
    sections.map(section => {
      if (section?.sub_sections?.length) {
        // [Subsection] - Minimum sub section and sub section field validation
        const subSections = section.sub_sections
        subSections.map(section => {
          const fields = section.fields
          if (fields.length === 0) {
            isFormValid = false
            toast.error(
              <Toast
                message={`Error`}
                detailedMessage={`Sub Section should have atleast 1 field`}
              />
            )
          }
        })

        // [Subsection] -  Sub Section name should not be duplicate
        const subSectionTitles = section.sub_sections.map(section => {
          const fields = section.fields

          // Field name should not be duplicate
          const fieldTitles = fields.map(field => {
            return field.field_title
          })
          const isFieldTitleDuplicate = fieldTitles.some(function (fieldTitle, i) {
            return fieldTitles.indexOf(fieldTitle) != i
          })
          if (isFieldTitleDuplicate) {
            isFormValid = false
            toast.error(
              <Toast message={`Error`} detailedMessage={`Duplicate Sub Section Field Title`} />
            )
          }
          return section.section_title
        })
        const isSubSectionTitleDuplicate = subSectionTitles.some(function (sectionTitle, idx) {
          return subSectionTitles.indexOf(sectionTitle) != idx
        })
        if (isSubSectionTitleDuplicate) {
          isFormValid = false
          toast.error(<Toast message={`Error`} detailedMessage={`Duplicate Sub Section Title`} />)
        }
      }
    })

    // Only 1 signature per form validation
    let allField = []
    sections.forEach(section => {
      let fields = section.fields
      fields.forEach(field => {
        allField.push(field)
      })
    })
    const signatureFields = allField.filter(field => {
      return field.field_type.value === 'SIGNATURE'
    })
    if (signatureFields.length > 1) {
      isFormValid = false
      toast.error(
        <Toast message={`Error`} detailedMessage={`Only 1 Signature is allowed per form`} />
      )
    }

    // Only 1 master stock per form validation
    const masterStocksFields = allField.filter(field => {
      return field.field_type.value === 'MASTER_STOCK'
    })
    if (masterStocksFields.length > 1) {
      isFormValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Only 1 Master Stock field is allowed per form`}
        />
      )
    }

    // Not define jump settings validation
    for (const section of sections) {
      if (section.hasOwnProperty('jump_to_section')) {
        if (
          !section.hasOwnProperty('jump_settings') ||
          !section.jump_settings ||
          !Array.isArray(section.jump_settings)
        ) {
          isFormValid = false
          toast.error(
            <Toast
              message={`Error`}
              detailedMessage={`You've defined jump to section without jump settings. Please complete the jump settings or delete the jump to section data`}
            />
          )
        }
      }
    }

    return isFormValid
  }

  const validationBefAddSubSection = id => {
    const sections = form.sections
    let isFormValid = true

    if (sections[id].multiple_input) {
      isFormValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Subsection can only added when section is not a multiple input!`}
        />
      )
      return isFormValid
    }

    const fields = sections[id].fields
    if (fields.length === 0) {
      isFormValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Subsection can only added when section has fields!`}
        />
      )
    }

    if (fields.some(field => field.field_type?.value === 'MASTER_STOCK')) {
      isFormValid = false
      toast.error(
        <Toast
          message={`Error`}
          detailedMessage={`Subsection cannot be added when section has Master Stock field!`}
        />
      )
    }

    // Text Input Validation
    const textInputs = document.getElementsByClassName('input-text')
    Array.from(textInputs).forEach(function (input) {
      if (
        input.value === '' &&
        input.name !== 'Last submission' &&
        input.name.toLowerCase().includes('field')
      ) {
        isFormValid = false
        toast.warning(
          <Toast
            message={`Warning`}
            detailedMessage={`You must complete the form field first! ${input.name} should not be empty`}
          />
        )
        input.focus()
      }
    })

    // Dropdown/Select validation
    const selecInputs = document.querySelectorAll('input[type=hidden]')
    Array.from(selecInputs).forEach(function (input) {
      if (input.value === '' && input.name.toLowerCase().includes('field')) {
        isFormValid = false
        toast.warning(
          <Toast
            message={`Warning`}
            detailedMessage={`You must complete the form field first! ${input.name} should not be empty`}
          />
        )
        input.focus()
      }
    })

    return isFormValid
  }

  const fetchWorkflowModules = () => {
    get(apiUrls.WORKFLOW_MODULES).then(response => {
      const { status } = response
      if (status === 200 && Array.isArray(response.data)) {
        setWorkflowModules(response.data)
      } else {
        errorApi(status, response)
        return
      }
    })
  }

  const errorApi = (status, response) => {
    switch (status) {
      case 408:
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
        break
      default:
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
        break
    }
  }

  const handleChangeSection = (parent, child, firstIdx, secondIdx, thirdIdx, dataChanged) => {
    if (parent === 'sections' && child === '') {
      let tempData = form.sections
      tempData[firstIdx] = dataChanged
      setForm({ ...form, sections: tempData })
    }
    if (parent === 'sections' && child === 'fields') {
      let tempData = form.sections
      tempData[firstIdx].fields[thirdIdx] = dataChanged
      setForm({ ...form, sections: tempData })
    }
    if (parent === 'sub_sections' && child === '') {
      let tempData = form.sections
      tempData[firstIdx].sub_sections[secondIdx] = dataChanged
      setForm({ ...form, sections: tempData })
    }
    if (parent === 'sub_sections' && child === 'fields') {
      let tempData = form.sections
      tempData[firstIdx].sub_sections[secondIdx].fields[thirdIdx] = dataChanged
      setForm({ ...form, sections: tempData })
    }
  }

  const handleSetActiveTab = choice => {
    setSearchGroupQuery('')
    setSearchMemberQuery('')
    setActiveTab(choice)
  }

  const handleLeavePage = () => {
    dispatch(setShowPopupLeavePage(true))
  }

  //====== ADVANCED SETTINGS ======\\

  const [advancedSettingsModal, setAdvancedSettingsModal] = useState(false)

  function onSubmitAdvancedSettingsModal(otherFormData, advanced_settings) {
    // set isWorkflow based on module_id
    setIsWorkflow(Object.keys(otherFormData.module_id).length > 0)
    setForm(form => ({ ...form, ...otherFormData }))
    setForm(form => ({ ...form, advanced_settings }))
  }

  //====== MASTER DATA ======\\

  const [masterDataOptions, setMasterDataOptions] = useState([])
  const [masterDataModal, setMasterDataModal] = useState(false)
  const [masterDataModalItem, setMasterDataModalItem] = useState(null)
  const [masterDataCallback, setMasterDataCallback] = useState(null)

  async function fetchMasterData() {
    const { data } = await get(`${apiUrls.MASTER_DATA}/all`)
    setMasterDataOptions(data)
  }

  function onAddMasterDataChild(child, callback) {
    setMasterDataModalItem(child)
    setMasterDataCallback({ callback })
    setMasterDataModal(true)
  }

  function onSubmitMasterDataModal(child) {
    const { parentType, sectionIdx, subSectionIdx, fieldIdx, newField } =
      masterDataCallback.callback()
    handleChangeSection(parentType, 'fields', sectionIdx, subSectionIdx, fieldIdx, {
      ...newField,
      field_type: {
        label: 'Master Data Dropdown',
        value: 'DROPDOWN_MASTER_DATA'
      },
      master_data: child
    })
    setMasterDataModalItem(null)
    setMasterDataCallback(null)
  }

  //====== TAGS ======\\

  const [tags, setTags] = useState([])

  useEffect(() => {
    fetchTags()
  }, [])

  async function fetchTags() {
    const { data } = await get(apiUrls.TAGS)
    setTags(data.map(({ name }) => ({ label: name, value: name })))
  }

  //====== JUMP SETTINGS ======\\
  const [showJumpSettingsModal, setShowJumpSettingsModal] = useState(false)
  const [currentSectionId, setCurrentSectionId] = useState(null)

  return (
    <div className="flex-row p-2 sm:p-6">
      <div className="flex flex-col md:flex-row justify-evenly gap-6">
        <div className="flex-1 bg-white p-6 rounded-2xl">
          <div className="flex items-center justify-between mb-6">
            <div className="font-poppins font-semibold text-[16px] text-[#C800A5]">Form Info</div>
            <button
              className="rounded bg-purple-50 text-purple-700 text-xs font-semibold p-2"
              onClick={() => setAdvancedSettingsModal(true)}
            >
              <img
                src={ICONS.icSettings}
                className="inline w-6 mr-2"
                alt="advanced settings icon"
              />
              Advanced Settings
            </button>
          </div>
          <div className="flex flex-col md:flex-row justify-evenly gap-6">
            <div className="flex-1">
              <div>
                <div className="font-poppins font-semibold text-[12px] mb-2">Code Form</div>
                <input
                  type="text"
                  name="Code Form"
                  className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                  placeholder="Enter code form"
                  onChange={event => handleOnCodeFormChange(event)}
                  defaultValue={form?.code_form}
                />
              </div>
              <div>
                <div className="font-poppins font-semibold text-[12px] mb-2">Ref number</div>
                <input
                  type="text"
                  name="Ref Number"
                  className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                  placeholder="Enter Ref number"
                  onChange={event => handleOnRefNumberChange(event)}
                  defaultValue={form?.ref_number}
                />
              </div>
              <div>
                <div className="font-poppins font-semibold text-[12px] mb-2">Attachment</div>
                <Select
                  components={{ DropdownIndicator }}
                  value={selectedAttachmentOption}
                  onChange={selectedOption => handleOnAttachmentChange(selectedOption)}
                  isSearchable={false}
                  options={attachmentOptions}
                  name="Attachment"
                  className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                  placeholder="Select attachment"
                  styles={{
                    control: provided => ({
                      ...provided,
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({})
                  }}
                />
              </div>
              <div className="mb-3">
                <div className="font-poppins font-semibold text-[12px] mb-2">
                  Allow User to Share Respon
                </div>
                <label
                  htmlFor={`allow-share`}
                  className="flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value=""
                    id={`allow-share`}
                    className="sr-only peer"
                    defaultChecked={false}
                    onChange={event => handleOnAllowShareChange(event)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                </label>
              </div>
            </div>
            <div className="flex-1">
              <div>
                <div className="font-poppins font-semibold text-[12px] mb-2">Title</div>
                <input
                  type="text"
                  name="Title"
                  className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                  placeholder="Enter title"
                  onChange={event => handleOnTitleChange(event)}
                  defaultValue={form?.title}
                />
              </div>
              <div>
                <div className="font-poppins font-semibold text-[12px] mb-2">Company</div>
                <Select
                  components={{ DropdownIndicator }}
                  value={selectedCompanyOptions}
                  onChange={selectedOptions => handleOnCompanyChange(selectedOptions)}
                  options={companyOptions}
                  name="Company"
                  className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                  placeholder="Select company"
                  isMulti={true}
                  styles={{
                    option: provided => ({
                      ...provided
                    }),
                    control: provided => ({
                      ...provided,
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: provided => ({
                      ...provided,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({}),
                    valueContainer: provided => ({
                      ...provided
                    }),
                    multiValueLabel: provided => ({
                      ...provided,
                      color: '#fff',
                      fontFamily: 'poppins'
                    }),
                    multiValueRemove: provided => ({
                      ...provided,
                      color: '#fff'
                    }),
                    multiValue: provided => ({
                      ...provided,
                      backgroundColor: '#6546C3',
                      borderRadius: 8
                    })
                  }}
                />
              </div>
              <div>
                <div className="font-poppins font-semibold text-[12px] mb-2">Last Submission</div>
                <div className="flex relative">
                  <DatePicker
                    selected={lastSubmissionDate}
                    onChange={date => handleOnLastSumbissionChange(date)}
                    timeInputLabel="Time:"
                    dateFormat="dd-MMM-yyyy HH:mm"
                    showTimeInput
                    name="Last submission"
                    className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                    placeholderText="Select last submission"
                    autoComplete="off"
                  />
                  <img
                    src={ICONS.icCalendar}
                    className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="font-poppins font-semibold text-[12px] mb-2">Status</div>
                <label htmlFor={`status`} className="flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    id={`status`}
                    className="sr-only peer"
                    onChange={event => handleOnStatusChange(event)}
                    checked={form.is_active}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-[#6546C3] dark:peer-focus:ring-[#6546C3] rounded-full peer dark:bg-[#DEDEDE] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#DEDEDE] peer-checked:bg-[#6546C3]"></div>
                </label>
              </div>
            </div>
          </div>
          {(form.form_type === 'SYSTEM' || isFormTypeSystem) && (
            <div className="flex-1 mt-6 bg-[#F2F5FC] rounded-2xl px-4 py-2 flex justify-between items-center">
              <div className="font-semibold text-[12px]">
                Form Type : <span className="text-[#C800A5]">System</span>
              </div>
              <div
                className={`flex gap-2 items-center cursor-pointer ${
                  isFormTypeSystem ? '' : 'opacity-70'
                }`}
                onClick={() => {
                  if (isFormTypeSystem) {
                    window.open(
                      `${process.env.REACT_APP_PWA_BASE_URL}/Task/FormSubmission/DetailFormSubmission/${id}`
                    )
                  }
                }}
              >
                <img src={ICONS.icAdd} className="w-8 h-8" />
                <div className="text-[12px] text-[#6546C3]">Add Data</div>
              </div>
            </div>
          )}
        </div>
        <div className="flex-1 bg-white p-6 rounded-2xl">
          <div className="flex-1 bg-white p-6 rounded-2xl">
            <div className="flex flex-col gap-2">
              <div className="font-poppins font-semibold text-[16px] text-[#C800A5]">Assign to</div>
              <div className="flex mb-4">
                <div
                  className={`w-[140px] flex flex-row flex-1 bg-${
                    activeTab === 'users'
                      ? 'gradient-to-r from-[#9E7CE3] to-[#6546C3]'
                      : '[#F2F5FC]'
                  } rounded-md mr-[16px] p-[12px] cursor-pointer`}
                  onClick={() => handleSetActiveTab('users')}
                >
                  <div
                    className={`w-[4px] h-[27px] bg-${
                      activeTab === 'users' ? 'white' : '[#6546C3]'
                    } rounded-lg mr-[12px]`}
                  ></div>
                  <p
                    className={`text-[12px] font-semibold text-${
                      activeTab === 'users' ? 'white' : '[#6546C3]'
                    } self-center`}
                  >
                    User
                  </p>
                </div>
                <div
                  className={`w-[140px] flex flex-row flex-1  bg-${
                    activeTab === 'groups'
                      ? 'gradient-to-r from-[#9E7CE3] to-[#6546C3]'
                      : '[#F2F5FC]'
                  } rounded-md p-[12px] cursor-pointer`}
                  onClick={() => handleSetActiveTab('groups')}
                >
                  <div
                    className={`w-[4px] h-[27px] bg-${
                      activeTab === 'groups' ? 'white' : '[#6546C3]'
                    } rounded-lg mr-[12px]`}
                  ></div>
                  <p
                    className={`text-[12px] font-semibold text-${
                      activeTab === 'groups' ? 'white' : '[#6546C3]'
                    } self-center`}
                  >
                    Groups
                  </p>
                </div>
              </div>
            </div>
            {activeTab === 'users' ? (
              <div className="flex-row rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
                <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
                  <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
                    Available Member
                  </div>
                  <div className="flex flex-row gap-4">
                    {totalMemberChecked !== 0 ? (
                      <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
                        {totalMemberChecked} User Selected
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                        checked={selectAllMember}
                        onChange={handleOnCheckAllMember}
                      />
                      <div className="font-poppins font-normal text-[12px] text-white">
                        All User
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-row px-4 py-3">
                  <div className={`flex items-center relative mb-4 overflow-hidden`}>
                    <input
                      type="text"
                      placeholder="Search here"
                      className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                      value={searchMemberQuery}
                      onChange={event => {
                        setSearchMemberQuery(event.target.value)
                      }}
                    />
                    <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" />
                  </div>
                  <div className="flex-row max-h-[200px] min-h-[200px] overflow-y-auto scrollbar">
                    {listOfMembers
                      .filter(member => {
                        return member.name
                          .toLowerCase()
                          .match(new RegExp(searchMemberQuery.toLowerCase(), ''))
                      })
                      .map(member => (
                        <div key={member.id} className="flex items-center justify-between mb-4">
                          <div className="flex flex-1 text-[12px] items-center gap-3">
                            <img src={ICONS.icAdminActive} className="w-6 h-6 rounded-full" />
                            {member.name}
                          </div>
                          <input
                            type="checkbox"
                            className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                            checked={member.checked}
                            onChange={event => handleOnChangeCheckMember(member.id, event)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-row rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
                <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
                  <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
                    Available Group
                  </div>
                  <div className="flex flex-row gap-4">
                    {totalGroupChecked !== 0 ? (
                      <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
                        {totalGroupChecked} Group Selected
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                        checked={selectAllGroup}
                        onChange={handleOnCheckAllGroup}
                      />
                      <div className="font-poppins font-normal text-[12px] text-white">
                        All Group
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-row px-4 py-3">
                  <div className={`flex items-center relative mb-2 overflow-hidden`}>
                    <input
                      type="text"
                      placeholder="Search here"
                      className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                      value={searchGroupQuery}
                      onChange={event => {
                        setSearchGroupQuery(event.target.value)
                      }}
                    />
                    <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" />
                  </div>
                  <div className="mb-4">
                    <div className="font-poppins font-semibold text-[12px]">Work Location</div>
                    <Select
                      components={{ DropdownIndicator }}
                      name="Work location"
                      options={listWorkLocation}
                      defaultValue={selectedWorkLocation}
                      className={`mb-1 input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                      placeholder="Select work location"
                      isMulti={false}
                      isClearable={true}
                      onChange={data => handleOnChangeWorkLocation(data)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided
                        }),
                        control: provided => ({
                          ...provided,
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: provided => ({
                          ...provided,
                          color: '#C800A5',
                          ':hover': { color: '#6546C3' }
                        }),
                        indicatorSeparator: () => ({}),
                        valueContainer: provided => ({
                          ...provided
                        })
                      }}
                    />
                  </div>
                  <div className="flex-row h-[135px] overflow-y-auto scrollbar">
                    {listOfGroups
                      .filter(group => {
                        return selectedWorkLocation?.label
                          ? group.name
                              .toLowerCase()
                              .match(new RegExp(searchGroupQuery.toLowerCase(), '')) &&
                              (group?.work_location
                                ? group.work_location.match(selectedWorkLocation.label)
                                : null)
                          : group.name
                              .toLowerCase()
                              .match(new RegExp(searchGroupQuery.toLowerCase(), ''))
                      })
                      .map(group => (
                        <div key={group.id} className="flex items-center justify-between mb-4">
                          <div className="flex flex-1 text-[12px] items-center gap-3">
                            <img
                              src={group.picture ? group.picture : ICONS.icGroup}
                              className="w-6 h-6 rounded-full"
                            />
                            {group.name}
                          </div>
                          <input
                            type="checkbox"
                            className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                            checked={group.checked}
                            onChange={event => handleOnChangeCheckGroup(group.id, event)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 rounded-2xl">
        {form.sections.length === 0 ? (
          <div className="flex flex-col justify-center items-center p-6">
            <img src={images.noSection} className="w-[160px] h-[160px] mb-6" />
            <div className="font-semibold text-[18px] mb-2">You’ve not added any section yet</div>
            <div className="text-[12px] text-[#AAAAAA] mb-6">
              Please click the "add section” button to add it now!
            </div>
            <button
              onClick={handleOnAddSection}
              disabled={!form.editable}
              className={`${
                !form.editable ? 'btn-disabled' : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
              } py-3 px-6 text-white text-[12px] font-semibold rounded-md`}
            >
              Add Section
            </button>
          </div>
        ) : (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="sections" isDropDisabled={!form.editable}>
              {provided => (
                <MasterDataContext.Provider value={{ masterDataOptions, onAddMasterDataChild }}>
                  <TagsContext.Provider value={{ tags }}>
                    <FormContext.Provider
                      value={{ appApiList: form.api, formType: form.form_type }}
                    >
                      <div
                        className="flex flex-col gap-6"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {form.sections.map((section, index) => (
                          <Draggable
                            key={section.section_id}
                            draggableId={section.section_id}
                            index={index}
                            isDragDisabled={!form.editable}
                          >
                            {provided => (
                              <div
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <Section
                                  index={index}
                                  sections={form.sections}
                                  data={section}
                                  enableJump={
                                    form.form_method === 'WIZARD' && form.sections.length > 1
                                  }
                                  setShowJumpSettingsModal={setShowJumpSettingsModal}
                                  setCurrentSectionId={setCurrentSectionId}
                                  editable={form.editable}
                                  onDelete={handleOnDeleteSection}
                                  validationBefAddSubSection={validationBefAddSubSection}
                                  handleChangeSection={handleChangeSection}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <button
                          onClick={handleOnAddSection}
                          disabled={!form.editable}
                          className={`${
                            !form.editable
                              ? 'btn-disabled'
                              : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
                          } mt-[60px] max-w-[140px] py-3 px-8 text-white text-[12px] font-semibold rounded-md`}
                        >
                          Add Section
                        </button>
                      </div>
                    </FormContext.Provider>
                  </TagsContext.Provider>
                </MasterDataContext.Provider>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <div className="flex justify-end gap-4 mt-10">
          <button
            onClick={() => handleLeavePage()}
            className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={() => handleSubmitForm()}
            className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
          >
            Save
          </button>
        </div>
      </div>
      <MasterDataModal
        showModal={masterDataModal}
        setShowModal={setMasterDataModal}
        item={masterDataModalItem}
        form={form}
        onSubmit={onSubmitMasterDataModal}
      />
      {advancedSettingsModal && (
        <AdvancedSettingsModal
          showModal={advancedSettingsModal}
          setShowModal={setAdvancedSettingsModal}
          settings={form.advanced_settings}
          otherFormData={{
            point: form.point,
            running_number: form.running_number,
            is_segregate_by_tags: form.is_segregate_by_tags,
            tags: form.tags,
            location: form.location,
            module_id: form.module_id || {},
            assign_to: form.assign_to,
            print_approval_format: form.print_approval_format,
            params_wfe: form.params_wfe,
            different_requestor: form.different_requestor,
            image_resolution: form.image_resolution,
            form_size: form.form_size,
            form_method: form.form_method,
            form_type: form.form_type,
            custom_tablet_layout: form.custom_tablet_layout,
            linkage: form.linkage,
            api: form.api
          }}
          sections={form.sections}
          sectionsOriginal={sectionsOriginal}
          tags={tags}
          workflowModules={workflowModules}
          onSubmit={onSubmitAdvancedSettingsModal}
        />
      )}
      <SuccessPopup />
      <LeavePagePopup
        onLeavePage={() => {
          navigate(-1)
        }}
      />
      <LoadingPopup />
      {showJumpSettingsModal && (
        <JumpSettingsModal
          showModal={showJumpSettingsModal}
          setShowModal={setShowJumpSettingsModal}
          section={form.sections?.find(section => section.section_id === currentSectionId)}
          setCurrentSectionId={setCurrentSectionId}
        />
      )}

      <ScrollToBottom />
    </div>
  )
}

export default EditFormPage
