import React from 'react'
import Field from '../Field'
import SubSection from '../SubSection'

const Section = ({
  section,
  onChangeValueField,
  onChangeValueInnerSectionField,
  onChangeCheckBox,
  onChangeRadio,
  onAddAttachment,
  removeFile,
  onChangeValueSubSection,
  onChangeSubCheckBox,
  onChangeSubRadio,
  onAddSubAttachment,
  removeSubSectionFile
}) => {
  const fields = section.fields
  const innerSections = section.inner_section
  const subSections = section.sub_sections

  return (
    <div className="flex flex-col bg-[#F2F5FC] rounded-2xl p-6">
      <div className="font-semibold text-[14px] text-[#C800A5]">{section.section_title}</div>
      <hr className="mt-2 mb-6" />
      <div className="flex flex-col gap-4">
        {fields?.map((field, fieldIndex) => (
          <Field
            key={`${field.field_id}${fieldIndex}`}
            field={field}
            sectionId={section.section_id}
            onChange={(event, additionalIndex) => {
              onChangeValueField(event, additionalIndex, fieldIndex)
            }}
            onChangeCheck={(e, label, index) => onChangeCheckBox(e, label, index, fieldIndex, null)}
            onChangeRadioButton={(e, label, index) =>
              onChangeRadio(e, label, index, fieldIndex, null)
            }
            addAttachment={(file, additionalIndex) =>
              onAddAttachment(file, additionalIndex, fieldIndex, null)
            }
            onRemoveFile={(indexFile, additionalIndex) =>
              removeFile(indexFile, additionalIndex, fieldIndex, null)
            }
          />
        ))}
      </div>
      {subSections?.map((section, subSectionIndex) => {
        return (
          <div key={subSectionIndex}>
            <hr className="my-6" />
            <div className="flex flex-col gap-4">
              <SubSection
                key={`${section.section_id}${subSectionIndex}`}
                section={section}
                onChangeValueSubSectionField={(event, additionalIndex, fieldIndex) =>
                  onChangeValueSubSection(event, additionalIndex, fieldIndex, subSectionIndex)
                }
                onChangeSubSectionCheckBox={(e, label, additionalIndex, fieldIndex) =>
                  onChangeSubCheckBox(e, label, additionalIndex, fieldIndex, subSectionIndex)
                }
                onChangeSubSectionRadio={(e, label, index, fieldIndex) =>
                  onChangeSubRadio(e, label, index, fieldIndex, subSectionIndex)
                }
                onAddSubSectionAttachment={(file, additionalIndex, fieldIndex) =>
                  onAddSubAttachment(file, additionalIndex, fieldIndex, subSectionIndex)
                }
                removeSubSectionFile={(indexFile, additionalIndex, fieldIndex) =>
                  removeSubSectionFile(indexFile, additionalIndex, fieldIndex, subSectionIndex)
                }
              />
            </div>
          </div>
        )
      })}
      {innerSections?.map((innerSectionFields, innerSectionIndex) => {
        return (
          <div key={innerSectionIndex}>
            <hr className="my-6" />
            <div className="flex flex-col gap-4">
              {innerSectionFields.map((field, fieldIndex) => (
                <Field
                  key={`${field.field_id}${fieldIndex}`}
                  field={field}
                  sectionId={section.section_id}
                  onChange={(event, additionalIndex) =>
                    onChangeValueInnerSectionField(
                      event,
                      additionalIndex,
                      fieldIndex,
                      innerSectionIndex
                    )
                  }
                  onChangeCheck={(e, label, index) =>
                    onChangeCheckBox(e, label, index, fieldIndex, innerSectionIndex)
                  }
                  onChangeRadioButton={(e, label, index) =>
                    onChangeRadio(e, label, index, fieldIndex, innerSectionIndex)
                  }
                  addAttachment={(file, additionalIndex) =>
                    onAddAttachment(file, additionalIndex, fieldIndex, innerSectionIndex)
                  }
                  onRemoveFile={(indexFile, additionalIndex) =>
                    removeFile(indexFile, additionalIndex, fieldIndex, innerSectionIndex)
                  }
                />
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Section
