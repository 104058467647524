import AreaTable from './AreaTable'

const Area = () => {
  return (
    <div className="bg-white rounded-2xl p-6 mx-2 sm:mx-6 my-2">
      <AreaTable />
    </div>
  )
}

export default Area
