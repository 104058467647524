const BASE_URL = 'http://appitm-dev.banpuindo.co.id'
const LOGIN_URL = '/api/auth/login'
const REFRESH_TOKEN_URL = '/api/auth/refresh-token'
const LOGOUT_URL = '/api/auth/logout'
const AREAS_URL = '/api/areas'
const REGISTERED_COMPANY = '/api/users/registered/company'
const REGISTERED_MEMBER = '/api/users/registered'
const FORMS_URL = '/api/dynamic-forms'
const FORM_FIELDS_URL = '/api/dynamic-forms/attributes'
const GROUPS_URL = '/api/groups'
const WORK_LOCATIONS = '/api/work-locations'
const USER_PROFILE = '/api/users/me'
const TASKS_URL = '/api/tasks'
const TASKS_USER_URL = '/api/tasks/users'
const TASKS_GROUP_URL = '/api/tasks/groups'
const TASKS_VISIBILITY = '/api/tasks/visibility'
const TASK_ATTACHMENT = '/api/tasks/attachments'
const WORKFLOW_MODULES = '/api/dynamic-forms/modules'
const MY_ACTIVITIES = '/api/my-activities'
const LIST_MICROSITES = '/api/microsites'
const MICROSITES_ROUTE = '/api/microsites/route'
const MASTER_DATA = '/api/master-data'
const TAGS = '/api/tags'
const ORGANIZATION_MASTER = '/api/users/organization-master'
const TIMEZONE_LIST = '/api/timezones'
const LATEST_VERSION = '/api/master-data/latest-version'
const FORMS_GROUP_URL = '/api/dynamic-forms/groups'
const DASHBOARD_POWER_BI = '/api/dashboards/power-bi'
const LOGIN_AZURE_URL = '/api/auth/login/azure'
const APPLICATIONS_URL = '/api/applications'
const MODULES_URL = '/api/modules'
const COMPANIES = '/api/users/me/companies'
export default {
  BASE_URL,
  LOGIN_URL,
  REFRESH_TOKEN_URL,
  LOGOUT_URL,
  AREAS_URL,
  REGISTERED_COMPANY,
  REGISTERED_MEMBER,
  FORMS_URL,
  FORM_FIELDS_URL,
  GROUPS_URL,
  WORK_LOCATIONS,
  USER_PROFILE,
  TASKS_URL,
  TASKS_USER_URL,
  TASKS_GROUP_URL,
  TASKS_VISIBILITY,
  TASK_ATTACHMENT,
  WORKFLOW_MODULES,
  MY_ACTIVITIES,
  LIST_MICROSITES,
  MICROSITES_ROUTE,
  MASTER_DATA,
  TAGS,
  ORGANIZATION_MASTER,
  TIMEZONE_LIST,
  LATEST_VERSION,
  FORMS_GROUP_URL,
  DASHBOARD_POWER_BI,
  LOGIN_AZURE_URL,
  APPLICATIONS_URL,
  MODULES_URL,
  COMPANIES
}
