import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { del, get } from '../../../api/base'
import { Toast, SuccessPopup } from '../../../components'
import { apiUrls, images } from '../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../redux/slices/popupSlice'

import Info from './Info'
import DataContentModal from './DataContentModal'
import FormMappingModal from './FormMappingModal'

export default function MasterDataDetail() {
  const params = useParams()
  const dispatch = useDispatch()

  const [masterData, setMasterData] = useState({
    name: null,
    id: null
  })
  const [form, setForm] = useState({})
  const [isEdit, setIsEdit] = useState({})
  const [showDataContentModal, setShowDataContentModal] = useState(false)
  const [showFormMappingModal, setShowFormMappingModal] = useState(false)

  const onEditClick = async item => {
    const { ok, data } = await get(
      `${apiUrls.MASTER_DATA}/${item.master_data_id}/details/${item.id}`
    )

    if (ok) {
      setForm({
        data_content_id: data.id,
        name: data.name,
        alias_name: data.alias_name,
        is_active: data.is_active,
        parents: data.parents
          ? data.parents.map(v => {
              return { ...v, label: v.name, value: v.id }
            })
          : null
      })
      setIsEdit(true)
      setShowDataContentModal(true)
    } else {
      toast.error(
        <Toast message={`Error`} detailedMessage={'Failed to fetch detail data content'} />
      )
    }
  }
  const onDeleteClick = async item => {
    const data = await del(`${apiUrls.MASTER_DATA}/${item.master_data_id}/details/${item.id}`)

    if (data.status === 204) {
      dispatch(setShowPopupSuccess(true))
      dispatch(setMessage('You have successfully delete data content'))
      fetchMasterData(params.id)
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={'Failed to delete data content'} />)
    }
  }

  const addContent = () => {
    setShowDataContentModal(true)
    setIsEdit(false)
  }

  useEffect(() => {
    fetchMasterData(params.id)
  }, [])

  async function fetchMasterData(masterDataId) {
    const { status, data } = await get(`${apiUrls.MASTER_DATA}/${masterDataId}`)
    if (status === 200) {
      setMasterData(data)
    } else if (status === 408) {
      toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
    } else {
      toast.error(
        <Toast message={`Error`} detailedMessage={'Failed to fetch detail master data'} />
      )
    }
  }

  return (
    <div className="mx-2 md:mx-6 my-2">
      <Info data={masterData} is_content={false} />

      {masterData.data && masterData.data.length > 0 ? (
        <>
          <div className="flex gap-4">
            <button
              className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold text-xs rounded-md px-8 py-3"
              onClick={() => addContent()}
            >
              Add Content
            </button>
            <button
              className="flex w-full md:w-fit justify-center border-2 border-[#6546C3] text-[#6546C3] font-semibold text-xs rounded-md px-8 py-3"
              onClick={() => setShowFormMappingModal(true)}
            >
              Form Mapping
            </button>
          </div>

          {masterData.data.map(item => (
            <Info
              key={item.id}
              data={item}
              is_content={true}
              handleEditClick={() => onEditClick(item)}
              handleDeleteClick={() => onDeleteClick(item)}
            />
          ))}
        </>
      ) : (
        <div className="flex flex-col justify-center items-center p-6">
          <img
            src={images.noSection}
            className="w-[160px] h-[160px] mb-6 mt-[80px]"
            alt="No Data"
          />
          <div className="font-semibold text-[18px] mb-2">Data content does not exist yet</div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "add content” button to add it now!
          </div>
          <button
            className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold text-xs rounded-md px-8 py-3"
            onClick={() => addContent()}
          >
            Add Content
          </button>
        </div>
      )}

      <DataContentModal
        showModal={showDataContentModal}
        setShowModal={() => setShowDataContentModal(false)}
        form={form}
        masterData={masterData}
        isEdit={isEdit}
        setIsEdit={() => setIsEdit(false)}
        onSuccess={() => fetchMasterData(params.id)}
      />
      {showFormMappingModal && (
        <FormMappingModal
          showModal={showFormMappingModal}
          setShowModal={setShowFormMappingModal}
          masterData={masterData}
          fetchMasterData={() => fetchMasterData(params.id)}
        />
      )}
      <SuccessPopup />
    </div>
  )
}
