import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fields: []
}

export const masterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    setFields: (state, action) => {
      state.fields = action.payload
    }
  }
})

export const { setFields } = masterSlice.actions

export const selectFields = state => state.master.fields

export default masterSlice.reducer
