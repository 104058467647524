import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { get } from '../../../../../api/base'
import { Toast } from '../../../../../components'
import { ICONS, apiUrls } from '../../../../../constants'
import { selectUserData } from '../../../../../redux/slices/userSlice'
import Section from './Section'
import CommentPopup from '../../../../../components/Popup/CommentPopup'
import { useDispatch } from 'react-redux'
import { setShowComment } from '../../../../../redux/slices/popupSlice'
import { post } from '../../../../../api/base'
import ScrollToBottom from '../../../../../components/ScrollToBottom'

const DetailSubmission = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { ids } = useParams()
  const [submission, setSubmission] = useState(null)
  const [isPic, setIsPic] = useState(false)
  const [action, setAction] = useState(false)
  const user = submission?.user
  const sentAt = submission?.sent_at
  const sections = submission?.sections
  const attachments = submission?.attachments
  const form = submission?.form
  const userData = useSelector(selectUserData)

  useEffect(() => {
    fetchDetailSubmission()
  }, [])

  useEffect(() => {
    if (form) {
      checkIsPic()
    }
  }, [form])

  useEffect(() => {
    if (action) {
      dispatch(setShowComment(true))
    }
  }, [action])

  const checkIsPic = () => {
    const found = submission?.form?.userPics?.findIndex(
      el => el.name.toLowerCase() === userData.username.toLowerCase()
    )
    if (found !== -1) {
      setIsPic(true)
    }
  }

  const fetchDetailSubmission = () => {
    get(`/api/dynamic-forms/contents/${ids}`).then(response => {
      const { status } = response
      if (status === 200) {
        setSubmission(response.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch'} />)
      }
    })
  }

  const titleCase = str => {
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
  }

  const handleButton = action => {
    setAction(action)
  }

  const handleSend = comment => {
    const query = {
      query: { type: action, comment: comment }
    }
    post(`${apiUrls.FORMS_URL}/contents/${ids}/action`, {}, query).then(response => {
      const { status } = response
      if (response.status === 200) {
        toast.success(
          <Toast
            message={`Success`}
            detailedMessage={`You have successfully ${action.toLowerCase()}`}
          />
        )
        navigate('/MyActivity', {
          state: { title: 'My Activity' }
        })
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`Failed to fetch.`} />)
      }
    })
  }

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex flex-col bg-white rounded-2xl p-6 gap-6">
        <div className="flex">
          <p className="font-semibold text-[16px] text-[#C800A5] self-center mr-3">
            Info Initiated
          </p>
          {form?.status ? (
            <span
              className={`${
                form?.status.toLowerCase() === 'submitted'
                  ? 'class-submitted'
                  : form?.status.toLowerCase().includes('approved')
                  ? 'class-approved'
                  : form?.status.toLowerCase().includes('revise')
                  ? 'class-sendback'
                  : 'class-ongoing'
              } text-[12px]`}
            >
              {titleCase(form?.status)}
            </span>
          ) : (
            ''
          )}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-[12px] text-[#232323]">Initiated</div>
            <div className="flex items-center gap-3">
              <img
                src={user?.picture ? user?.picture : ICONS.icAdminActive}
                className="w-6 h-6 rounded-full"
              />
              <div className="text-[12px] text-[#6546C3]">{user?.full_name}</div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-[12px] text-[#232323]">Sent At</div>
            <div className="text-[12px] text-[#6546C3]">{sentAt}</div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-[12px] text-[#232323]">Company</div>
            <div className="text-[12px] text-[#6546C3]">{user?.company}</div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-semibold text-[12px] text-[#232323]">ID Request</div>
            <div className="text-[12px] text-[#6546C3]">{form?.module_id || '-'}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-2xl p-6 gap-6">
        <div className="flex font-semibold text-[16px] text-[#C800A5]">Result Response</div>
        <div className="flex flex-col gap-6">
          {sections?.map((section, index) => (
            <Section key={index} section={section} />
          ))}
          <div className="flex flex-col bg-[#F2F5FC] rounded-2xl p-6">
            <div className="font-semibold text-[14px] text-[#C800A5]">Attachment</div>
            <hr className="mt-2 mb-6" />
            <div className="flex gap-4">
              {attachments ? (
                attachments?.map((attachment, index) => (
                  <img key={index} src={attachment.url} className="w-20 h-20" />
                ))
              ) : (
                <p className="text-[12px] pl-2 text-[##808080] italic">No Attachment</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-10">
        {isPic ? (
          <div className="gap-2 border-r-2 border-r-[#DEDEDE] pr-4">
            <button
              className="py-3 px-8 bg-gradient-to-r from-[#C800A5] to-[#FD79E6] text-white text-[12px] font-semibold rounded-md mr-3"
              onClick={() => handleButton('REVISE')}
            >
              Send Back
            </button>
            <button
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              onClick={() => handleButton('APPROVE')}
            >
              Approve
            </button>
          </div>
        ) : (
          ''
        )}
        <div className="gap-2">
          <button
            className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md mr-2"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
      <CommentPopup
        setContinue={handleSend}
        buttonNext={`Yes, ${action ? titleCase(action) : ''}`}
        action={action}
      />

      <ScrollToBottom />
    </div>
  )
}

export default DetailSubmission
