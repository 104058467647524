import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTable } from 'react-table'
import { Toast, SuccessPopup } from '../../../components'
import { apiUrls, ICONS, images } from '../../../constants'
import { get } from '../../../api/base'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="flex w-full md:w-fit font-poppins border-[1px] items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" alt="search icon" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}

const ApplicationList = () => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const userData = useSelector(state => state.user.userData)
  const COLUMNS = [
    {
      Header: 'Application Name',
      accessor: 'name'
    },
    {
      Header: 'Application Link',
      accessor: 'applicationLink'
    },
    {
      Header: 'Authentication API Link',
      accessor: 'authenticationApiLink'
    },
    {
      Header: 'Total Module',
      accessor: 'modules',
      Cell: ({ value }) => (
        <div
          className={`flex justify-center items-center rounded-lg w-7 h-7 ${
            value.length === 0 ? 'bg-[#858585] text-[#858585]' : 'bg-[#C800A5] text-[#C800A5]'
          } bg-opacity-10  font-semibold`}
        >
          {value.length}
        </div>
      )
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleEditClick = () => {
          const EDIT_APPLICATION_PATH = `/Admin/ApplicationProfile/EditApplicationProfile/${row.original.id}`
          const EDIT_APPLICATION_CRUMBS = 'Admin/Application Profile/Edit Application Profile'
          const crumbs = EDIT_APPLICATION_CRUMBS.split('/')
          navigate(EDIT_APPLICATION_PATH, {
            state: { title: 'Edit Application Profile', crumbs: crumbs }
          })
        }
        const handleDetailClick = () => {
          const DETAIL_APPLICATION_PATH = `/Admin/ApplicationProfile/DetailApplicationProfile/${row.original.id}`
          const DETAIL_APPLICATION_CRUMBS = 'Admin/Application Profile/Detail Application Profile'
          const crumbs = DETAIL_APPLICATION_CRUMBS.split('/')
          navigate(DETAIL_APPLICATION_PATH, {
            state: { title: 'Detail Application Profile', crumbs: crumbs }
          })
        }

        return (
          <div className="flex gap-2">
            {hasAuthority('UPDATE') && (
              <img
                src={ICONS.icEdit}
                className="w-[28px] cursor-pointer"
                onClick={() => handleEditClick(row.original.id)}
                alt="edit"
              />
            )}
            {hasAuthority('READ') && (
              <img
                src={ICONS.icDetail}
                className="w-[28px] cursor-pointer"
                onClick={() => handleDetailClick(row.original.id)}
                alt="view detail"
              />
            )}
          </div>
        )
      }
    }
  ]
  const location = useLocation()

  const hasAuthority = permission => {
    const path = location.pathname
    const pathSplit = path.split('/')
    const isSubMenu = pathSplit.length > 2
    if (isSubMenu) {
      const menus = userData?.menus
      const menu = menus?.find(menu => {
        const menuPath = menu.path
        const menuPathSplit = menuPath.split('/')
        return menuPathSplit[0] === pathSplit[1]
      })
      const subMenus = menu?.subMenus
      const subMenu = subMenus?.find(subMenu => {
        const subMenuPath = subMenu.path
        const subMenuPathSplit = subMenuPath.split('/')
        return subMenuPathSplit[1] === pathSplit[2]
      })
      return subMenu?.permissions.includes(permission)
    }
  }

  const columns = useMemo(() => COLUMNS, [])
  const [fullData, setFullData] = useState(null)
  const data = fullData?.applications ? fullData?.applications : []
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const totalPage = fullData?.total_page
  const totalData = fullData?.total_data

  const { getTableProps, getTableBodyProps, headerGroups, rows, setHiddenColumns, prepareRow } =
    useTable({
      columns,
      data,
      initialState: {
        hiddenColumns: []
      },
      pageNum
    })

  useEffect(() => {
    if (isDesktop) {
      setHiddenColumns([])
    } else {
      setHiddenColumns(['authenticationApiLink'])
    }
  }, [isDesktop])

  useEffect(() => {
    fetchApplications()
  }, [search, limit, pageNum])

  function fetchApplications(currPage) {
    let query = {
      query: {}
    }
    if (search !== '') query.query.search = search
    if (pageNum !== '') query.query.page = currPage ? currPage : pageNum
    if (limit !== '') query.query.limit = limit

    get(apiUrls.APPLICATIONS_URL, query).then(response => {
      const { status, data } = response
      if (status === 200) {
        if (pageNum > data?.total_page) {
          setPageNum(1)
        }
        setFullData(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch applications'} />)
      }
    })
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map(data => {
        if (data === 0)
          return (
            <div key={data} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={data}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => {
              setPageNum(data)
            }}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function addApplication() {
    navigate('/Admin/ApplicationProfile/AddApplicationProfile', {
      state: {
        title: 'Add Application Profile',
        crumbs: 'Admin/Application Profile/Add Application Profile'.split('/')
      }
    })
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 !== 0
  }

  const goToNextPage = () => {
    setPageNum(prev => {
      return prev + 1
    })
  }
  const goToPreviousPage = () => {
    setPageNum(prev => {
      return prev - 1
    })
  }

  return (
    <>
      <div className="flex flex-col md:flex-row gap-2 items-center justify-between font-poppins text-[12px] mb-10">
        {hasAuthority('CREATE') ? (
          <button
            className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-8 py-3"
            onClick={() => addApplication()}
          >
            Add Application
          </button>
        ) : (
          <div />
        )}
        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
              alt="page limit icon"
            />
            <select
              value={limit}
              onChange={e => {
                setLimit(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(limit => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex w-full md:w-fit">
          <GlobalFilter filter={search} setFilter={setSearch} />
        </div>
      </div>
      <div className="overflow-x-auto w-full">
        <table {...getTableProps()} className="w-full font-poppins text-[12px]">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th key={index}>
                    <div className="flex gap-1">
                      {column.render('Header')}
                      {<img src={ICONS.icSort} className="w-2" alt="sort icon" />}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {data.length === 0 && (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
          <div className="font-semibold text-[18px] mb-2">
            {search.length ? 'Not Found' : 'You’ve not added any application yet'}
          </div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "add application" button to add it now!
          </div>
        </div>
      )}
      <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-9">
        {data.length !== 0 ? (
          <div className="font-poppins text-[12px]">
            Showing {pageNum * limit - (limit - 1)} -{' '}
            {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
          </div>
        ) : (
          <div className="font-poppins text-[12px]">No data to show</div>
        )}
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &gt;
          </button>
        </div>
      </div>
      <SuccessPopup />
    </>
  )
}

export default ApplicationList
