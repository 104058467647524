import { cloneDeep } from 'lodash'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Tabs } from 'react-daisyui'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { get, post } from '../../../../../api/base'
import { SuccessPopup, Toast } from '../../../../../components'
import ConfirmationPopup from '../../../../../components/Popup/ConfirmationPopup'
import { apiUrls, ICONS } from '../../../../../constants'
import { logoutMicrosite, selectMicrositeTokens } from '../../../../../redux/slices/authSlice'
import { setMessage, setShowPopupSuccess } from '../../../../../redux/slices/popupSlice'
import { PageContext } from '../../..'
import Empty from './Empty'
import Form from './Form'

export default function Create() {
  const { microsite } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { page, topColor } = useContext(PageContext)
  const [tab, setTab] = useState(0)
  const [forms, setForms] = useState([])
  const [companies, setCompanies] = useState([])
  const [fetched, setFetched] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showConfirmSubmitAll, setShowConfirmSubmitAll] = useState(false)
  const micrositeTokens = useSelector(selectMicrositeTokens)

  const fetchForms = useCallback(async () => {
    const { ok, data, status } = await get(
      `${apiUrls.MICROSITES_ROUTE}/${microsite}/menus/${page.id}/forms`,
      {
        headers: {
          Authorization: `Bearer ${micrositeTokens[microsite.toLowerCase()]}`
        }
      }
    )

    if (!ok && status === 401) {
      dispatch(logoutMicrosite())
      return
    }

    setForms(
      data.map(form => ({
        ...form,
        company_id: null,
        sections: form.sections.map(section => {
          return section.multiple_input
            ? { ...section, inner_sections: [cloneDeep(section.fields)] }
            : section
        }),
        ...(form.attachment ? { attachments: [] } : {}),
        _isSubmitted: false
      }))
    )
    setFetched(true)
  }, [micrositeTokens, page])

  useEffect(() => {
    fetchForms()
    fetchCompanies()
  }, [fetchForms])

  useEffect(() => {
    setTab(0)
    setForms([])
  }, [page])

  async function fetchCompanies() {
    const { data } = await get(`${apiUrls.USER_PROFILE}/companies`)
    setCompanies(data)
  }

  function onFormChange(index, value) {
    const clone = forms
    clone[index] = value
    setForms(() => [...clone])
  }

  async function onSubmit(form) {
    if (isSubmitting) return

    try {
      setIsSubmitting(true)

      const formToSubmit = {
        form_id: form.id,
        sections: form.sections,
        company_id: form.company_id?.id || null,
        submitted_time: moment().toDate(),
        attachments: form.attachments
      }

      const { ok, data } = await post(`${apiUrls.FORMS_URL}/contents`, formToSubmit)

      if (ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully submitted form'))

        form._isSubmitted = true
        const formsClone = cloneDeep(forms)
        const index = formsClone.findIndex(f => f.id === form.id)
        formsClone[index] = { ...form, _isSubmitted: true }
        setForms(formsClone)

        if (formsClone.every(f => f._isSubmitted)) {
          navigate(location.pathname.split('/').slice(0, -1).join('/'))
        }

        return
      }

      toast.error(<Toast message={`Error`} detailedMessage={data.error.message} />)
    } catch (error) {
      return
    } finally {
      setIsSubmitting(false)
    }
  }

  function onSubmitAll() {
    let companyFilledOnForm = 0
    forms.map(form => {
      if (!form.company_id)
        toast.error(
          <Toast
            message={`Error`}
            detailedMessage={`You have not filled in the company on the form ${form.title}`}
          />
        )
      else companyFilledOnForm = companyFilledOnForm + 1
    })

    if (companyFilledOnForm === forms.length)
      forms.filter(form => !form._isSubmitted).forEach(onSubmit)

    setShowConfirmSubmitAll(false)
  }

  return (
    <div>
      {fetched && !forms.length && <Empty />}

      {!!forms.length && (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-4 lg:col-span-3 bg-white rounded-2xl p-4">
            <div className="font-semibold">Form List</div>
            <hr className="my-2" />
            <Tabs value={tab} onChange={setTab}>
              {forms.map((form, f) => (
                <Tabs.Tab
                  value={f}
                  key={f}
                  className={`flex flex-nowrap w-full h-auto text-xs my-1 p-2 justify-start rounded-lg break-words ${
                    f === tab ? 'text-white font-semibold' : ''
                  }`}
                  style={{
                    backgroundColor: f === tab ? topColor || '#00B1B5' : '#e5e7eb'
                  }}
                >
                  <div
                    className={`flex-shrink-0 w-1 h-6 rounded mr-4`}
                    style={{
                      backgroundColor: f === tab ? 'white' : topColor || '#00B1B5'
                    }}
                  />
                  <div className="break-all text-start mr-2">{form.title}</div>
                  {form._isSubmitted && (
                    <img src={ICONS.icSuccess} className="w-6 h-6 shrink-0 ml-auto" alt="success" />
                  )}
                </Tabs.Tab>
              ))}
            </Tabs>
          </div>

          <div className="col-span-12 md:col-span-8 lg:col-span-9">
            <Form
              form={forms[tab]}
              companies={companies}
              onChange={value => onFormChange(tab, value)}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
            />

            <div className="flex justify-between mt-8 text-xs">
              <button
                type="button"
                className="py-2 px-6 border-2 font-semibold rounded-md"
                style={{
                  color: topColor || '#00B1B5',
                  borderColor: topColor || '#00B1B5'
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="py-2 px-6 text-white font-semibold rounded-md disabled:opacity-50"
                style={{
                  backgroundColor: topColor || '#00B1B5'
                }}
                onClick={() => setShowConfirmSubmitAll(true)}
                disabled={isSubmitting}
              >
                Submit All Forms
              </button>
            </div>
          </div>
        </div>
      )}

      <ConfirmationPopup
        showConfirmationModal={showConfirmSubmitAll}
        setShowConfirmationModal={setShowConfirmSubmitAll}
        title="Submit All Forms"
        message="Are you sure to submit all forms?"
        onConfirm={onSubmitAll}
      />
      <SuccessPopup />
    </div>
  )
}
