import { useRef, useState } from 'react'
import { ICONS } from '../../../../../constants'
import { useEffect } from 'react'
import GlobalFilter from './Filter'
import { useDispatch } from 'react-redux'
import { saveFilterField } from '../../../../../redux/slices/formSlice'

export default function FilterFieldModal({
  topColor,
  isJustOneForm,
  fields,
  selectedForms,
  micrositeName,
  pageId
}) {
  const [tempFields, setTempFields] = useState([])
  const [fieldList, setFieldList] = useState([])
  const [searchFormQuery, setSearchFormQuery] = useState('')
  const dispatch = useDispatch()
  const dropdown = useRef()

  const populateFields = () => {
    setTempFields(fields?.selectionFields)
    setFieldList(fields?.fieldList)
  }

  function onformCheck(checked, field) {
    const payload = {
      ...field,
      index: fieldList.indexOf(field)
    }
    setTempFields(
      checked ? [...tempFields, payload] : tempFields.filter(f => f.field_id !== payload.field_id)
    )
  }

  function onSave() {
    let result = { ...fields, selectionFields: tempFields }
    dispatch(saveFilterField({ micrositeName, pageId, filterField: result }))
    dropdown.current.classList.toggle('dropdown-open')
    document.activeElement.blur()
  }

  function close() {
    setTempFields(fields?.selectionFields)
    dropdown.current.classList.toggle('dropdown-open')
    document.activeElement.blur()
  }

  function reset() {
    setTempFields([])
  }

  useEffect(() => {
    isJustOneForm && populateFields()
  }, [isJustOneForm, fields])
  return (
    <div className="dropdown dropdown-bottom h-fit " ref={dropdown}>
      <button className={`block ${!isJustOneForm && 'pointer-events-none'}`} onClick={close}>
        <div
          className={`flex flex-row border rounded-md p-1 gap-1 h-[40px] items-center ${
            isJustOneForm ? 'cursor-pointer' : ''
          }`}
        >
          {fields?.selectionFields?.length && (
            <div
              className="p-2 text-[12px] font-bold rounded-md text-white"
              style={{ background: topColor }}
            >
              {fields?.selectionFields?.length}
            </div>
          )}
          <img
            className={`${isJustOneForm ? '' : 'opacity-25'}`}
            src={ICONS.icSelectionField}
            width={26}
            height={26}
          />
        </div>
      </button>

      <div
        tabIndex="0"
        className={`dropdown-content menu p-4 shadow rounded-box bg-white ${
          !isJustOneForm && 'hidden'
        }`}
      >
        <div className="py-2 px-4 bg-[#F2F5FC] text-[#232323] mb-4 rounded-lg">
          {selectedForms[0]?.label}
        </div>
        <GlobalFilter filter={searchFormQuery} setFilter={setSearchFormQuery} />
        <div className="flex-row h-[200px] overflow-y-auto scrollbar mt-4">
          {fieldList
            ?.filter(field => {
              return field.field_title
                .toLowerCase()
                .match(new RegExp(searchFormQuery.toLowerCase(), ''))
            })
            .map((field, index) => (
              <div key={index} className="flex items-center justify-between mb-4">
                <div className="font-poppins text-xs">{field.field_title}</div>
                <input
                  type="checkbox"
                  className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                  checked={tempFields.some(f => f.field_id === field.field_id)}
                  onChange={({ target: { checked } }) => onformCheck(checked, field)}
                />
              </div>
            ))}
        </div>
        <div className="flex justify-end gap-4 lg:col-span-3 mt-8">
          <button
            type="button"
            onClick={reset}
            className="py-3 px-8 text-[12px] font-semibold rounded-md"
            style={{ border: `1px solid ${topColor}`, color: topColor }}
          >
            Reset
          </button>
          <button
            onClick={onSave}
            className="py-3 px-8 text-white text-[12px] font-semibold rounded-md"
            style={{ background: topColor }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
