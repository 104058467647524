import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTable, useSortBy } from 'react-table'
import { Toast } from '../../../components'
import { apiUrls, ICONS, images } from '../../../constants'
import { get, put, del } from '../../../api/base'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import {
  setShowPopupDelete,
  setShowPopupSuccess,
  setMessage
} from '../../../redux/slices/popupSlice'
import DeletePopup from '../../../components/Popup/DeletePopup'
import SuccessPopup from '../../../components/Popup/SuccessPopup'
import { useSelector } from 'react-redux'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="flex w-full md:w-fit font-poppins border-[1px] items-center p-[13px] rounded-lg">
      <img src={ICONS.icSearch} className="w-[14px] h-[14px]" alt="search icon" />
      <input
        className="ml-2 focus:outline-none"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search here..."
      />
    </div>
  )
}

const MicrositeList = () => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const [deletedRowId, setDeletedRowId] = useState(-1)
  const [deletedMicrosite, setDeletedMicrosite] = useState(false)
  const dispatch = useDispatch()
  const userData = useSelector(state => state.user.userData)
  const COLUMNS = [
    {
      Header: 'Logo',
      accessor: 'logo_url',
      Cell: ({ row, value }) => {
        return <img src={value} className="max-w-[50px] max-h-[50px]" alt={row.original.name} />
      }
    },
    {
      Header: 'Title',
      accessor: 'name',
      Cell: ({ row, value }) => (
        <a
          className="cursor-pointer hover:text-[#6546C3] hover:font-semibold"
          href={`/Site/${row.original.route_name}`}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      )
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ row, value }) => (
        <div className="w-[400px]" dangerouslySetInnerHTML={{ __html: value }} />
      )
    },
    {
      Header: 'Status',
      accessor: 'is_active',
      Cell: ({ row, value, pageNum }) => {
        const { id } = row.original
        const checked = value
        const checkedClass =
          "w-11 h-6 bg-[#6546C3] rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all after:translate-x-full after:border-white "
        const unCheckedClass =
          "w-11 h-6 bg-gray-200 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:border-gray-300 after:border after:h-5 after:w-5 after:transition-all"

        const handleStatusChange = (event, id) => {
          const isActive = event.target.checked

          const form = new FormData()
          form.append('name', row.original.name)
          form.append('description', row.original.description)
          form.append('file_logo', '')
          form.append('is_active', isActive)

          put(`/api/microsites/${id}`, form).then(response => {
            const { status, data } = response
            if (status === 200) {
              fetchMicrosites(pageNum)
              toast.success(
                <Toast
                  message={`Success`}
                  detailedMessage={`Successfully changing status to ${
                    isActive ? 'Active' : 'Inactive'
                  }.`}
                />
              )
            } else {
              toast.error(
                <Toast
                  message={`Error`}
                  detailedMessage={`Error changing status to ${isActive ? 'Active' : 'Inactive'}. ${
                    data.error.message
                  }`}
                />
              )
            }
          })
        }

        return (
          <label
            htmlFor={`status-toggle-${id}`}
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              id={`status-toggle-${id}`}
              className="sr-only peer"
              checked={checked}
              onChange={event => handleStatusChange(event, id)}
            />
            <div className={checked ? checkedClass : unCheckedClass}></div>
          </label>
        )
      }
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleEditClick = () => {
          const DETAIL_GROUP_PATH = `/Admin/Microsite/EditMicrosite/${row.original.route_name}`
          const DETAIL_GROUP_CRUMBS = 'Admin/Microsite/Edit Microsite'
          const crumbs = DETAIL_GROUP_CRUMBS.split('/')
          navigate(DETAIL_GROUP_PATH, {
            state: { title: 'Edit Microsite', crumbs: crumbs }
          })
        }
        const handleDetailClick = () => {
          const DETAIL_GROUP_PATH = `/Admin/Microsite/DetailMicrosite/${row.original.route_name}`
          const DETAIL_GROUP_CRUMBS = 'Admin/Microsite/Detail Microsite'
          const crumbs = DETAIL_GROUP_CRUMBS.split('/')
          navigate(DETAIL_GROUP_PATH, {
            state: { title: 'Detail Microsite', crumbs: crumbs }
          })
        }
        const handleRemoveClick = id => {
          setDeletedRowId(id)
          dispatch(setShowPopupDelete(true))
          dispatch(setMessage('Are you sure want to delete this microsite?'))
        }

        return (
          <div className="flex gap-2">
            {hasAuthority('UPDATE') && (
              <img
                src={ICONS.icEdit}
                className="w-[28px] cursor-pointer"
                onClick={() => handleEditClick(row.original.id)}
                alt="edit"
              />
            )}
            {hasAuthority('READ') && (
              <img
                src={ICONS.icDetail}
                className="w-[28px] cursor-pointer"
                onClick={() => handleDetailClick(row.original.id)}
                alt="view detail"
              />
            )}
            {hasAuthority('DELETE') && (
              <img
                src={ICONS.icTrash}
                className="w-[28px] cursor-pointer"
                onClick={() => handleRemoveClick(row.original.id)}
                alt="delete"
              />
            )}
          </div>
        )
      }
    }
  ]
  const location = useLocation()

  const hasAuthority = permission => {
    const path = location.pathname
    const pathSplit = path.split('/')
    const isSubMenu = pathSplit.length > 2
    if (isSubMenu) {
      const menus = userData?.menus
      const menu = menus?.find(menu => {
        const menuPath = menu.path
        const menuPathSplit = menuPath.split('/')
        return menuPathSplit[0] === pathSplit[1]
      })
      const subMenus = menu?.subMenus
      const subMenu = subMenus?.find(subMenu => {
        const subMenuPath = subMenu.path
        const subMenuPathSplit = subMenuPath.split('/')
        return subMenuPathSplit[1] === pathSplit[2]
      })
      return subMenu?.permissions.includes(permission)
    }
  }

  const columns = useMemo(() => COLUMNS, [])
  const [fullData, setFullData] = useState(null)
  const data = fullData?.microsites ? fullData?.microsites : []
  const [pageNum, setPageNum] = useState(1)
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(5)
  const [manualSortBy, setManualSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const totalPage = fullData?.total_page
  const totalData = fullData?.total_data

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: { sortBy },
    setHiddenColumns,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        hiddenColumns: []
      },
      pageNum
    },
    useSortBy
  )

  useEffect(() => {
    if (isDesktop) {
      setHiddenColumns([])
    } else {
      setHiddenColumns(['logo_url', 'description'])
    }
  }, [isDesktop])

  useEffect(() => {
    if (sortBy.length > 0) {
      setManualSortBy(sortBy[0].id)
      setOrderBy(sortBy[0].desc ? 'desc' : 'asc')
    } else {
      setManualSortBy('')
      setOrderBy('')
    }
  }, [sortBy])
  // handle delete microsite after click action delete
  useEffect(() => {
    if (deletedMicrosite) {
      setDeletedMicrosite(false)
      setDeletedRowId(-1)

      del(`${apiUrls.LIST_MICROSITES}/${deletedRowId}`).then(response => {
        const { status } = response
        if (status === 200) {
          dispatch(setShowPopupSuccess(true))
          dispatch(setMessage('You have successfully delete microsite'))
          fetchMicrosites()
        } else if (status === 401) {
          toast.error(<Toast message={`Error`} detailedMessage={`Unauthorized user.`} />)
        } else if (status === 403) {
          toast.error(
            <Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />
          )
        } else if (status === 500) {
          toast.error(
            <Toast message={`Server Error`} detailedMessage={`Failed to delete microsite.`} />
          )
        }
      })
    }
  }, [deletedMicrosite])

  useEffect(() => {
    fetchMicrosites()
  }, [search, limit, pageNum])

  function fetchMicrosites(currPage) {
    let query = {
      query: {}
    }
    if (search !== '') query.query.search = search
    if (pageNum !== '') query.query.page = currPage ? currPage : pageNum
    if (limit !== '') query.query.limit = limit
    if (manualSortBy !== '') query.query.sort_by = manualSortBy
    if (orderBy !== '') query.query.order_by = orderBy

    get(apiUrls.LIST_MICROSITES, query).then(response => {
      const { status, data } = response
      if (status === 200) {
        if (pageNum > data?.total_page) {
          setPageNum(1)
        }
        setFullData(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch microsites'} />)
      }
    })
  }

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map(data => {
        if (data === 0)
          return (
            <div key={data} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={data}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => {
              setPageNum(data)
            }}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  function addMicrosite() {
    navigate('/Admin/Microsite/AddMicrosite', {
      state: {
        title: 'Add Microsite',
        crumbs: 'Admin/Microsite/Add Microsite'.split('/')
      }
    })
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 !== 0
  }

  const goToNextPage = () => {
    setPageNum(prev => {
      return prev + 1
    })
  }
  const goToPreviousPage = () => {
    setPageNum(prev => {
      return prev - 1
    })
  }

  return (
    <>
      <div className="flex flex-col md:flex-row gap-2 items-center justify-between font-poppins text-[12px] mb-10">
        {hasAuthority('CREATE') ? (
          <button
            className="flex w-full md:w-fit justify-center bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold rounded-md px-8 py-3"
            onClick={() => addMicrosite()}
          >
            Add Microsite
          </button>
        ) : (
          <div />
        )}
        <div className="flex items-center gap-x-2">
          Show
          <div className="flex relative bg-[#F2F5FC] rounded-lg p-1">
            <img
              src={ICONS.icSort}
              className="w-2 absolute left-[22px] top-[5px] pointer-events-none"
              alt="page limit icon"
            />
            <select
              value={limit}
              onChange={e => {
                setLimit(Number(e.target.value))
              }}
              className="text-primary cursor-pointer appearance-none w-7 bg-[#F2F5FC] focus:outline-none"
            >
              {[5, 10, 25, 100].map(limit => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          Data
        </div>
        <div className="flex w-full md:w-fit">
          <GlobalFilter filter={search} setFilter={setSearch} />
        </div>
      </div>
      <div className="overflow-x-auto w-full">
        <table {...getTableProps()} className="w-full font-poppins text-[12px]">
          <thead className="">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex gap-1">
                      {column.render('Header')}
                      {column.canSort && (
                        <img
                          src={
                            column.isSorted
                              ? column.isSortedDesc
                                ? ICONS.icSortDown
                                : ICONS.icSortUp
                              : ICONS.icSort
                          }
                          className="w-2"
                          alt="sort icon"
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {data.length === 0 && (
        <div className="flex flex-col justify-center items-center p-6">
          <img src={images.noSection} className="w-[160px] h-[160px] mb-6" alt="No Data" />
          <div className="font-semibold text-[18px] mb-2">
            {search.length ? 'Not Found' : 'You’ve not added any microsite yet'}
          </div>
          <div className="text-[12px] text-[#AAAAAA] mb-6">
            Please click the "add microsite" button to add it now!
          </div>
        </div>
      )}
      <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-9">
        {data.length !== 0 ? (
          <div className="font-poppins text-[12px]">
            Showing {pageNum * limit - (limit - 1)} -{' '}
            {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
          </div>
        ) : (
          <div className="font-poppins text-[12px]">No data to show</div>
        )}
        <div className="flex font-poppins text-[12px]">
          <button
            onClick={() => goToPreviousPage()}
            disabled={!canPreviousPage()}
            className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canPreviousPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &lt;
          </button>
          <div className="bg-[#F2F5FC] rounded-full flex">{showPaginationNumbers(totalPage)}</div>
          <button
            onClick={() => goToNextPage()}
            disabled={!canNextPage()}
            className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
              !canNextPage()
                ? 'bg-[#F2F5FC]'
                : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
            }`}
          >
            &gt;
          </button>
        </div>
      </div>

      <DeletePopup setContinueDelete={setDeletedMicrosite} buttonNext={'Delete Now'} />
      <SuccessPopup />
    </>
  )
}

export default MicrositeList
