import FormTable from './FormTable'

const Form = () => {
  return (
    <div className="bg-white rounded-2xl p-2 mx-2 sm:p-6 sm:mx-6 my-2">
      <FormTable />
    </div>
  )
}

export default Form
