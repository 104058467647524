import { Controller, useFormContext } from 'react-hook-form'

import { Select } from '../../../../components'

const REPORT_TYPES = [
  { label: 'SSRS', value: 'SSRS' },
  { label: 'Power BI', value: 'POWER BI' }
]

export default function TypeReport({ isEdit }) {
  const { control, register, watch, errors } = useFormContext()
  const reportType = watch('report_type')?.value

  return (
    <div>
      <div className="font-semibold text-[12px] mb-2">Report Type</div>
      <Controller
        control={control}
        name="report_type"
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            onChange={onChange}
            options={REPORT_TYPES}
            isDisabled={!isEdit}
            placeholder="Select report option"
          />
        )}
      />
      <div className="flex-1">
        <div className="font-semibold text-[12px] my-2">Power BI/SSRS URL</div>
        <input
          disabled={!isEdit}
          className={`${
            errors?.report_link ? 'empty-field ' : ''
          }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
          placeholder="Enter Power BI/SSRS URL"
          {...register('report_link')}
        />
      </div>
      {reportType === 'SSRS' && (
        <div className="flex flex-1 flex-row md:gap-20 gap-10">
          <div className="font-semibold text-[12px] font-poppins">Is Segregate By</div>
          <div className="flex flex-row md:gap-20 gap-10">
            <div className="flex flex-row">
              <input
                {...register('attributes.is_segregate_by_companies')}
                type="checkbox"
                disabled={!isEdit}
              />
              <div className="text-[12px] ml-2">Companies</div>
            </div>
            <div className="flex flex-row">
              <input
                {...register('attributes.is_segregate_by_contractors')}
                type="checkbox"
                disabled={!isEdit}
              />
              <div className="text-[12px] ml-2">Contractors</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
