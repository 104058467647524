import ImageDelete from '../../../assets/images/confirmation.png'
import { useDispatch, useSelector } from 'react-redux'
import { setShowPopupDelete } from '../../../redux/slices/popupSlice'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { Toast } from '../../../components'
import { setShowComment } from '../../../redux/slices/popupSlice'
import { useEffect } from 'react'

const CommentPopup = ({ setContinue, buttonNext, action }) => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.popup.showPopupComment)
  const [remark, setRemark] = useState('')

  useEffect(() => {
    setRemark('')
  }, [show])

  function handleComment() {
    dispatch(setShowComment(false))
    if (setContinue) {
      setContinue(remark)
    }
  }

  const titleCase = str => {
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(' ')
  }

  return (
    <div>
      <input
        type="checkbox"
        className="modal-toggle"
        checked={show}
        onChange={e => e.preventDefault()}
      />
      <div className="modal bg-[#232323]/[0.5] overflow-y-auto py-6">
        <div
          className="modal-box bg-white relative w-full max-h-max max-w-max py-[60px] px-0"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-col items-center min-w-[21.25rem]">
            <img src={ImageDelete} className="w-[120px] h-[120px] mb-9" alt="leave page icon" />
            <span className="font-semibold text-lg mb-1">{titleCase(action || '')}</span>
          </div>
          <div className="flex justify-center">
            <div className="text-xs text-[#AAAAAA] break-words w-[400px] text-center">
              Are you sure you want to {action ? action.toLowerCase() : ''} this form?
            </div>
          </div>
          <div className="mt-4">
            <div className="text-xs text-[#AAAAAA] break-words bg-[#EBEDF8] w-full p-5">
              <div className="text-xs text-black font-semibold break-words">Remark</div>
              <input
                id="remark"
                type="text"
                name="Remark"
                className={`input-text text-[12px] text-black px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter remark"
                value={remark}
                onChange={event => setRemark(event.target.value)}
              />
            </div>
          </div>
          <div className="flex justify-center gap-4 mt-9">
            <button
              onClick={() => dispatch(setShowComment(false))}
              className="py-3 px-8 border-[2px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={() => handleComment()}
              className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
            >
              {buttonNext || 'Delete'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentPopup
