import { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useParams } from 'react-router-dom'

import { get } from '../../api/base'
import { apiUrls } from '../../constants'
import { addSite, removeSite, selectSites } from '../../redux/slices/micrositeSlice'

export default function RequireMicrosite() {
  const params = useParams()
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)
  const sites = useSelector(selectSites)
  const site = sites.find(s => s.route_name.toLowerCase() === params.microsite.toLowerCase())

  const fetchMicrosite = useCallback(async () => {
    const { ok, data } = await get(`${apiUrls.MICROSITES_ROUTE}/${params.microsite}`)
    if (ok && !data.error) dispatch(addSite(data))
    else dispatch(removeSite(params.microsite))
    setLoaded(true)
  }, [dispatch, params.microsite])

  useEffect(() => {
    if (site) {
      setLoaded(true)
      document.title = site.name
    }
    if (params.microsite && !site) fetchMicrosite()
  }, [fetchMicrosite, params.microsite, site])

  if (!params.microsite) return <Navigate to="/" />
  if (!loaded)
    return <div className="flex h-screen items-center justify-center">Checking site...</div>
  if (!site || (site && !site.is_active)) return <Navigate to="/" />
  return <Outlet />
}
