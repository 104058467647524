import { ICONS } from '../../constants'

function FormInput({
  type,
  placeholder,
  value,
  onChange,
  rightIcon,
  onRightIconClick,
  isError,
  setIsFormError
}) {
  return (
    <div className="relative w-full">
      <div className="absolute inset-y-0 right-0 flex items-center px-2">
        {rightIcon && !isError ? (
          <img
            src={rightIcon}
            width="32px"
            height="32px"
            onClick={onRightIconClick}
            className="cursor-pointer"
          />
        ) : (
          <></>
        )}
        {isError ? (
          <img
            src={ICONS.icCloseRoundRed}
            width="12px"
            height="12px"
            onClick={() => setIsFormError(false)}
            className="cursor-pointer m-[12px]"
          />
        ) : (
          <></>
        )}
      </div>
      <input
        className={`text-[12px] px-[16px] py-[12px] pr-[42px] border ${
          isError ? 'border-[#FF647C]' : ''
        } rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  )
}

export default FormInput
