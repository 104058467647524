import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import { ICONS } from '../../../../../../constants'

export default function DatePicker({
  format = 'dd-MMM-yyyy HH:mm',
  value = null,
  onChange,
  disabled
}) {
  let valueFormat = format.replace(/d/g, 'D').replace(/y/g, 'Y')
  let adjustedFormat = format.replace(/D/g, 'd').replace(/Y/g, 'y')

  return (
    <div className="flex relative">
      <ReactDatePicker
        selected={!value ? null : moment(value, valueFormat).toDate()}
        onChange={v => onChange(moment(v).format(valueFormat))}
        timeInputLabel="Time:"
        dateFormat={adjustedFormat}
        showYearPicker={['yy', 'yyyy'].includes(format.toLowerCase())}
        className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
        showTimeInput={adjustedFormat.includes('H') || adjustedFormat.includes('h')}
        showTimeSelectOnly={
          (adjustedFormat.includes('H') || adjustedFormat.includes('h')) &&
          (!adjustedFormat.includes('y') || !adjustedFormat.includes('d'))
        }
        disabled={disabled}
      />
      <img
        src={ICONS.icCalendar}
        className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
        alt="calendar"
      />
    </div>
  )
}
