import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { post } from '../../../api/base'
import { Toast } from '../../../components'
import { apiUrls, ICONS } from '../../../constants'
import { logout, logoutMicrosite } from '../../../redux/slices/authSlice'
import { selectSites } from '../../../redux/slices/micrositeSlice'
import { deleteUserData, selectUserData } from '../../../redux/slices/userSlice'

import { MENU_ICONS } from './constants'
import Menu from './Menu'
import { removeFilter } from '../../../redux/slices/formSlice'

const Header = ({ page, crumbs }) => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const { state } = useLocation()

  const title = state?.title || page?.name
  let crumbsLastIndex = crumbs ? crumbs.length - 1 : 0
  const [visibility, setVisibility] = useState('hidden')
  const [showNav, setshowNav] = useState(false)
  const userData = useSelector(selectUserData)

  const site = useSelector(selectSites).find(
    s => s.route_name.toLowerCase() === params.microsite.toLowerCase()
  )
  const menus = site.menus.map(menu => ({
    name: menu.name,
    path: menu.route_name,
    type: menu.menu_type,
    subMenus: menu.children.map(child => ({
      name: child.name,
      path: child.route_name
    }))
  }))

  const handleLogout = () => {
    post(apiUrls.LOGOUT_URL)
      .then(async () => {
        localStorage.clear()
        dispatch(logout())
        dispatch(deleteUserData())
        dispatch(removeFilter())
        dispatch(logoutMicrosite())
        navigate(`/Site/${params.microsite}/Login`)
      })
      .catch(() => {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to logout'} />)
      })
  }

  const handleProfileClick = () => {
    if (visibility === 'hidden') {
      setVisibility('visible')
    } else {
      setVisibility('hidden')
    }
  }

  const handleOnBurgerClick = () => {
    setshowNav(prevState => {
      return !prevState
    })
  }

  return (
    <div className="flex flex-col-reverse md:flex-row md:items-start justify-between my-2 gap-4">
      <div className="flex items-center">
        {page && (
          <img
            src={MENU_ICONS[page?.menu_type]?.active}
            className="w-12 h-12 mr-4"
            alt={`${page?.name} icon`}
          />
        )}

        <div className="flex flex-col">
          {/* title and crumbs */}
          <div className="font-poppins text-[20px] font-semibold text-black">{title}</div>
          <div>
            {crumbs ? (
              crumbs.map((crumbItem, index) => {
                if (index === crumbsLastIndex) {
                  return (
                    <span
                      key={index}
                      className={`font-poppins text-[12px]`}
                      style={{
                        color: index === 0 ? site?.top_color || '#00B1B5' : 'black'
                      }}
                    >
                      {crumbItem}
                    </span>
                  )
                } else {
                  return (
                    <span
                      key={index}
                      className="font-poppins text-[12px]"
                      style={{
                        color: site?.top_color || '#00B1B5'
                      }}
                    >
                      {crumbItem} /{' '}
                    </span>
                  )
                }
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        {/* Mobile Nav */}
        <div className="sm:hidden">
          <div
            className={`flex items-center justify-center cursor-pointer sm:hidden rounded-md p-1 w-9 h-9 ${
              showNav ? 'rounded-b-[0px]' : ''
            }`}
            style={{
              background: site?.top_color || '#00B1B5'
            }}
          >
            <img
              src={ICONS.icCollapseExpand}
              className="w-6 h-6"
              onClick={handleOnBurgerClick}
              alt="toggle menu"
            />
          </div>
          {showNav ? (
            <div className="absolute w-64 rounded-b-md rounded-tr-md z-10 bg-white">
              <div
                className="w-full h-full p-2 rounded-b-md rounded-tr-md"
                style={{
                  background: site?.top_color || '#00B1B5'
                }}
              >
                {menus.length > 0
                  ? menus.map((menu, index) => <Menu data={menu} key={index} open={true} />)
                  : null}
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex items-center">
          {/* notificaation icon and profile */}
          <div className="flex bg-white rounded-lg p-[6px] relative">
            {/* Profile Icon */}
            <div className="flex items-center justify-between w-[195px]">
              <div className="flex items-center">
                <img
                  src={userData?.picture ? userData.picture : ICONS.icAdminActive}
                  className="w-6 h-6 ml rounded-full"
                  alt="profile"
                />
                <div className="px-2 font-poppins text-xs">
                  {userData?.full_name ? userData?.full_name : 'Profile Name'}
                </div>
              </div>
              <img
                src={ICONS.icChevronDownSecondary}
                className="w-[10px] mr-1 cursor-pointer"
                onClick={handleProfileClick}
                alt="profile menu"
              />
            </div>
            <div
              className={`${visibility} bg-black bg-opacity-70 py-3 px-4 rounded-xl absolute right-0 bottom-[-65px]`}
            >
              <button
                className={`bg-white rounded-lg px-[12px] py-[10px] w-[104px] text-left text-xs`}
                style={{
                  color: site?.top_color || '#00B1B5'
                }}
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
