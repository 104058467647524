import { useEffect, useState } from 'react'

import { ICONS } from '../../../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../../../redux/slices/popupSlice'
import ParameterModal from './components/ParameterModal'
import { useRef } from 'react'
import icons from '../../../../../constants/icons'
import { useDispatch } from 'react-redux'
import NoDataTable from '../../../../../components/NoDataTable'

export default function CustomTabletLayout({
  showModal,
  setShowModal,
  setCustomTabletLayout,
  sections,
  currentTabletLayout
}) {
  const [showParameterModal, setShowParameterModal] = useState(false)
  const [parameterOptions, setParameterOptions] = useState([])
  const [selectedParameter, setSelectedParameter] = useState(null)
  const [size, setSize] = useState({
    row: null,
    col: null
  })
  const [gridItems, setGridItems] = useState([])
  const [activeCells, setActiveCells] = useState([])
  const [items, setItems] = useState([])
  const [enableMerge, setEnableMerge] = useState(false)
  const [enableSplitCol, setEnableSplitCol] = useState(false)
  const [enableSplitRow, setEnableSplitRow] = useState(false)

  const content = useRef()
  const [addContentOpen, setAddContentOpen] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (showModal) {
      setActiveCells([])
      // generate parameter options
      if (sections) {
        function getMappedFields(dataArray) {
          const mappedFields = []
          dataArray.forEach(item => {
            mappedFields.push(...getFieldsWithAdditionalOption(item.fields))
            if (item.sub_sections) {
              item.sub_sections.forEach(subSection => {
                mappedFields.push(...getFieldsWithAdditionalOption(subSection.fields))
              })
            }
          })
          return mappedFields
        }

        function getFieldsWithAdditionalOption(fields) {
          const mappedFields = []
          fields.forEach(field => {
            if (field.additional_option && field.additional_option.length > 0) {
              field.additional_option.forEach(option => {
                mappedFields.push({
                  field_id: field.field_id,
                  field_title: field.field_title,
                  additionalOption: option.title
                })
              })
            } else {
              mappedFields.push({
                field_id: field.field_id,
                field_title: field.field_title
              })
            }
          })
          return mappedFields
        }

        const result = getMappedFields(sections)
        setParameterOptions(result)
      }

      if (currentTabletLayout) {
        setSize(currentTabletLayout?.size)
        setItems(currentTabletLayout?.items)
        setGridItems(currentTabletLayout?.items?.map(item => item.minIndex))
      }
    }
  }, [showModal])

  useEffect(() => {
    if (
      size?.col !== currentTabletLayout?.size?.col ||
      size?.row !== currentTabletLayout?.size?.row
    ) {
      setItems([])
      setActiveCells([])
      // populate grid and item for each grid
      setGridItems(Array.from({ length: size?.col * size?.row }, (_, index) => index))
      if (size?.col && size?.row) {
        let newItems = []
        for (let i = 0; i < size.col * size.row; i++) {
          newItems.push({ minIndex: i, fieldId: '', fieldTitle: '' })
        }
        setItems(newItems)
      }
    }

    if (!size?.col || !size?.row) {
      setItems([])
      setActiveCells([])
      setGridItems([])
    }
  }, [size])

  useEffect(() => {
    let listActiveCells = [...activeCells]
    // update parameter for selected cell
    if (selectedParameter) {
      let itemsCopy = [...items]
      let currentActiveCells = itemsCopy.filter(item => listActiveCells.includes(item.minIndex))
      currentActiveCells = currentActiveCells.map(cell => ({
        ...cell,
        fieldId: selectedParameter.field_id,
        fieldTitle: selectedParameter.field_title,
        ...(selectedParameter?.additionalOption && {
          additionalOption: selectedParameter?.additionalOption
        })
      }))

      let restItemsCopy = itemsCopy.filter(item => !listActiveCells.includes(item.minIndex))

      let newItems = [...currentActiveCells, ...restItemsCopy]
      newItems.sort((a, b) => a.minIndex - b.minIndex)
      setItems(newItems)
      setActiveCells([])
      setSelectedParameter(null)
    }
  }, [selectedParameter])

  const handleActiveCells = index => {
    let listActiveCells = [...activeCells]
    const indexInArray = listActiveCells.indexOf(index)

    if (indexInArray === -1) {
      listActiveCells.push(index)
    } else {
      listActiveCells.splice(indexInArray, 1)
    }

    setActiveCells(listActiveCells)
  }

  function splitConsecutiveArrays(arr, col) {
    let result = []
    let currentSubarray = [arr[0]]

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] - arr[i - 1] === 1 && arr[i] % col !== 0 && currentSubarray.length < col) {
        currentSubarray.push(arr[i])
      } else {
        result.push(currentSubarray)
        currentSubarray = [arr[i]]
      }
    }

    result.push(currentSubarray)
    return result
  }

  function isSequential(arr) {
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] !== arr[i - 1] + 1) {
        return false
      }
    }
    return true
  }

  function hasConstantGap(arr, gap) {
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] - arr[i - 1] !== gap) {
        return false
      }
    }
    return true
  }

  function isDifferentRow(arr, x) {
    const multiples = arr.filter(item => item % x === 0)
    const lessThanMultiples = arr.filter(item => multiples.includes(item + 1))

    return multiples.length > 0 && lessThanMultiples.length > 0
  }

  function onlyRowsSelected(array) {
    return array.every(item => Array.isArray(item) && item.length === 1)
  }

  function isSelectedCellsFormSquare(array, column) {
    if (!Array.isArray(array) || array.length === 0) {
      return false
    }

    const firstLength = array[0].length
    if (
      !array.every(item => Array.isArray(item) && firstLength !== 1 && item.length === firstLength)
    ) {
      return false
    }

    const newArray = array.map(subArray => subArray[0])

    if (hasConstantGap(newArray, column)) {
      return true
    }
  }

  function mergeValidation(listActiveCells) {
    // validation for merged cells
    // cannot merge cells that already merged
    let itemsCopy = [...items]
    let currentActiveCells = itemsCopy.filter(item => listActiveCells.includes(item.minIndex))
    function isMergedCells(cells) {
      for (let cell of cells) {
        if (cell.hasOwnProperty('cells')) {
          return true
        }
      }
      return false
    }
    if (isMergedCells(currentActiveCells)) {
      return false
    }

    let splitActiveCells = splitConsecutiveArrays(listActiveCells, size?.col)

    // validation for columns selected
    if (listActiveCells.length <= size?.col && splitActiveCells.length === 1) {
      // only adjacent columns in the same row that can be merged
      if (isSequential(listActiveCells)) {
        if (!isDifferentRow(listActiveCells, size?.col)) {
          return 'col'
        }
      }
    }

    // validation for rows selected
    if (listActiveCells.length <= size?.row && onlyRowsSelected(splitActiveCells)) {
      // only adjacent rows that can be merged
      if (hasConstantGap(listActiveCells, size?.col)) {
        return 'row'
      }
    }

    // validation for selected cells form square
    if (isSelectedCellsFormSquare(splitActiveCells, size?.col)) {
      return 'square'
    }

    return false
  }

  function splitValidation(activeCell) {
    const currentActiveCells = items.find(item => item.minIndex === activeCell)

    if (currentActiveCells?.cells?.length > 1) {
      let splitActiveCells = splitConsecutiveArrays(currentActiveCells?.cells, size?.col)

      // validation for columns selected
      if (currentActiveCells.cells.length <= size?.col && splitActiveCells.length === 1) {
        // only adjacent columns that can be splitted
        if (isSequential(currentActiveCells.cells)) {
          return 'col'
        }
      }

      // validation for rows selected
      if (currentActiveCells.cells.length <= size?.row && onlyRowsSelected(splitActiveCells)) {
        // only adjacent rows that can be splitted
        if (hasConstantGap(currentActiveCells.cells, size?.col)) {
          return 'row'
        }
      }

      if (isSelectedCellsFormSquare(splitActiveCells, size?.col)) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    // check for merge cells
    if (activeCells.length > 1) {
      let listActiveCells = [...activeCells]
      listActiveCells.sort((a, b) => a - b)

      if (mergeValidation(listActiveCells)) {
        setEnableMerge(true)
      } else {
        setEnableMerge(false)
      }
    } else {
      setEnableMerge(false)
    }

    // check for split cells
    if (activeCells.length === 1) {
      if (splitValidation(activeCells[0]) === 'col') {
        setEnableSplitCol(true)
        setEnableSplitRow(false)
      } else if (splitValidation(activeCells[0]) === 'row') {
        setEnableSplitRow(true)
        setEnableSplitCol(false)
      } else if (splitValidation(activeCells[0]) === true) {
        setEnableSplitCol(true)
        setEnableSplitRow(true)
      } else {
        setEnableSplitCol(false)
        setEnableSplitRow(false)
      }
    } else {
      setEnableSplitCol(false)
      setEnableSplitRow(false)
    }
  }, [activeCells])

  const getIndexForDropdown = () => {
    let listActiveCells = [...activeCells]
    listActiveCells.sort((a, b) => a - b)

    let index = -1

    if (listActiveCells.length > 1) {
      if (mergeValidation(listActiveCells) === 'col') {
        index = Math.min(...listActiveCells)
      } else if (mergeValidation(listActiveCells) === 'row') {
        index = Math.max(...listActiveCells)
      } else if (mergeValidation(listActiveCells) === 'square') {
        let splitActiveCells = splitConsecutiveArrays(listActiveCells, size?.col)
        index = Math.min(...splitActiveCells[splitActiveCells.length - 1])
      }
    }

    if (listActiveCells.length === 1) {
      const currentActiveCells = items.find(item => item.minIndex === listActiveCells[0])
      if (splitValidation(listActiveCells[0]) === 'col') {
        index = Math.min(...currentActiveCells.cells)
      } else if (splitValidation(activeCells[0]) === 'row') {
        index = Math.max(...currentActiveCells.cells)
      } else if (splitValidation(activeCells[0])) {
        let splitActiveCells = splitConsecutiveArrays(currentActiveCells.cells, size?.col)
        index = Math.min(...splitActiveCells[splitActiveCells.length - 1])
      }
    }

    return index
  }

  const getRow = index => {
    let gridIndexs = Array.from({ length: size?.col * size?.row }, (_, index) => index)
    let splitActiveCells = splitConsecutiveArrays(gridIndexs, size?.col)

    let rowIndex = -1

    for (let i = 0; i < splitActiveCells.length; i++) {
      const row = splitActiveCells[i]
      if (row.includes(index)) {
        rowIndex = i
        break
      }
    }
    return rowIndex
  }

  const getPosition = cellArray => {
    const minValue = Math.min(...cellArray)
    const maxValue = Math.max(...cellArray)
    const row = Math.floor(minValue / size.col)
    const column = minValue % size.col
    const rowMax = Math.floor(maxValue / size.col)
    const columnMax = maxValue % size.col
    return {
      row: [row + 1, rowMax + 1],
      col: [column + 1, columnMax + 1]
    }
  }

  const handleMergeCells = () => {
    let listActiveCells = [...activeCells]
    listActiveCells.sort((a, b) => a - b)

    const minValue = Math.min(...listActiveCells)

    let gridItemsCopy = [...gridItems]
    gridItemsCopy = gridItemsCopy.filter(
      item => !listActiveCells.includes(item) || item === minValue
    )
    setGridItems(gridItemsCopy)

    let itemsCopy = [...items]
    let firstItem = itemsCopy.find(item => item.minIndex === minValue)
    firstItem.cells = listActiveCells
    firstItem.position = getPosition(listActiveCells)
    let restItemsCopy = itemsCopy.filter(item => !listActiveCells.includes(item.minIndex))
    itemsCopy = [firstItem, ...restItemsCopy]
    setItems(itemsCopy)
    setActiveCells([])
  }

  const handleSplitCells = ({ type }) => {
    let listActiveCells = [...activeCells]
    let itemsCopy = [...items]

    const currentActiveCells = itemsCopy.find(item => item.minIndex === listActiveCells[0])
    let splitActiveCells = splitConsecutiveArrays(currentActiveCells?.cells, size?.col)

    if (
      splitValidation(listActiveCells[0]) === 'col' ||
      splitValidation(listActiveCells[0]) === 'row'
    ) {
      let otherItemsIndex = currentActiveCells?.cells.filter(item => item !== listActiveCells[0])
      let newGridItems = [...gridItems, ...otherItemsIndex]
      newGridItems.sort((a, b) => a - b)
      setGridItems(newGridItems)

      let splittedItems = []
      for (let i = 0; i < otherItemsIndex?.length; i++) {
        splittedItems.push({ minIndex: otherItemsIndex[i], fieldId: '', fieldTitle: '' })
      }

      let restItemsCopy = itemsCopy.filter(item => !listActiveCells.includes(item.minIndex))
      let newItems = [
        {
          minIndex: currentActiveCells.minIndex,
          fieldId: currentActiveCells.fieldId,
          fieldTitle: currentActiveCells.fieldTitle
        },
        ...splittedItems,
        ...restItemsCopy
      ]
      newItems.sort((a, b) => a.minIndex - b.minIndex)
      setItems(newItems)
    } else if (type === 'row') {
      const arrayFirstRowIndex = splitActiveCells.map(subArray => subArray[0])
      let otherItemsFirstIndex = arrayFirstRowIndex.filter(item => item !== listActiveCells[0])
      let newGridItems = [...gridItems, ...otherItemsFirstIndex]
      newGridItems.sort((a, b) => a - b)
      setGridItems(newGridItems)

      let splittedItems = []
      for (let i = 0; i < otherItemsFirstIndex?.length; i++) {
        let currentListActiveCells = splitActiveCells.find(
          item => item[0] === otherItemsFirstIndex[i]
        )

        splittedItems.push({
          minIndex: otherItemsFirstIndex[i],
          cells: currentListActiveCells,
          position: getPosition(currentListActiveCells),
          fieldId: '',
          fieldTitle: ''
        })
      }

      let restItemsCopy = itemsCopy.filter(item => !listActiveCells.includes(item.minIndex))

      let currentListActiveCells = splitActiveCells.find(
        item => item[0] === currentActiveCells.minIndex
      )

      let newItems = [
        {
          minIndex: currentActiveCells.minIndex,
          cells: currentListActiveCells,
          position: getPosition(currentListActiveCells),
          fieldId: currentActiveCells.fieldId,
          fieldTitle: currentActiveCells.fieldTitle
        },
        ...splittedItems,
        ...restItemsCopy
      ]
      newItems.sort((a, b) => a.minIndex - b.minIndex)
      setItems(newItems)
    } else if (type === 'col') {
      const newArrayOfCol = []
      for (let i = 0; i < splitActiveCells[0].length; i++) {
        const groupedArray = splitActiveCells.map(subArray => subArray[i])
        newArrayOfCol.push(groupedArray)
      }

      const arrayFirstColIndex = newArrayOfCol.map(subArray => subArray[0])
      let otherItemsFirstIndex = arrayFirstColIndex.filter(item => item !== listActiveCells[0])
      let newGridItems = [...gridItems, ...otherItemsFirstIndex]
      newGridItems.sort((a, b) => a - b)
      setGridItems(newGridItems)

      let splittedItems = []
      for (let i = 0; i < otherItemsFirstIndex?.length; i++) {
        let currentListActiveCells = newArrayOfCol.find(item => item[0] === otherItemsFirstIndex[i])

        splittedItems.push({
          minIndex: otherItemsFirstIndex[i],
          cells: currentListActiveCells,
          position: getPosition(currentListActiveCells),
          fieldId: '',
          fieldTitle: ''
        })
      }

      let restItemsCopy = itemsCopy.filter(item => !listActiveCells.includes(item.minIndex))

      let currentListActiveCells = newArrayOfCol.find(
        item => item[0] === currentActiveCells.minIndex
      )

      let newItems = [
        {
          minIndex: currentActiveCells.minIndex,
          cells: currentListActiveCells,
          position: getPosition(currentListActiveCells),
          fieldId: currentActiveCells.fieldId,
          fieldTitle: currentActiveCells.fieldTitle
        },
        ...splittedItems,
        ...restItemsCopy
      ]
      newItems.sort((a, b) => a.minIndex - b.minIndex)
      setItems(newItems)
    }

    setActiveCells([])
  }

  const handleSubmitTabletLayout = () => {
    let customTabletLayout = {
      size,
      items,
      modified_date: new Date()
    }
    setCustomTabletLayout(customTabletLayout)
    setShowModal(false)
    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage('You have successfully add tablet layout'))
  }

  const handleClickAddContent = () => {
    setAddContentOpen(prev => !prev)
    content.current.classList.toggle('dropdown-open')
    document.activeElement.blur()
  }

  const handleAddTextField = () => {
    let listActiveCells = [...activeCells]
    let itemsCopy = [...items]
    let currentActiveCells = itemsCopy.filter(item => listActiveCells.includes(item.minIndex))
    currentActiveCells = currentActiveCells.map(cell => ({
      ...cell,
      text: ''
    }))

    let restItemsCopy = itemsCopy.filter(item => !listActiveCells.includes(item.minIndex))

    let newItems = [...currentActiveCells, ...restItemsCopy]
    newItems.sort((a, b) => a.minIndex - b.minIndex)
    setItems(newItems)
    setActiveCells([])
  }

  const handleChangeTextField = (index, value) => {
    let itemsCopy = [...items]
    let currentActiveCells = itemsCopy.find(item => item.minIndex === index)
    currentActiveCells.text = value

    let restItemsCopy = itemsCopy.filter(item => item.minIndex !== index)

    let newItems = [currentActiveCells, ...restItemsCopy]
    newItems.sort((a, b) => a.minIndex - b.minIndex)
    setItems(newItems)
  }

  const handleDeleteParameter = index => {
    let itemsCopy = [...items]
    let currentActiveCells = itemsCopy.find(item => item.minIndex === index)
    currentActiveCells.fieldId = ''
    currentActiveCells.fieldTitle = ''
    if (currentActiveCells.hasOwnProperty('text')) {
      delete currentActiveCells.text
    }

    let restItemsCopy = itemsCopy.filter(item => item.minIndex !== index)

    let newItems = [currentActiveCells, ...restItemsCopy]
    newItems.sort((a, b) => a.minIndex - b.minIndex)
    setItems(newItems)
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white max-w-full w-fit md:w-[70vw] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Tablet Layout</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setShowModal(false)
                let firstValue = { row: null, col: null }
                setSize({ ...firstValue })
              }}
              alt={'close-modal'}
            />
          </div>

          <hr className="mt-4 mb-6" />

          <div className="flex gap-3 items-center mb-6">
            <div className="font-semibold text-[12px] text-[#232323]">Matrix</div>
            <input
              type="number"
              min={1}
              max={12}
              name="Column"
              className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] max-w-[195px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
              placeholder="Column"
              value={parseInt(size?.col) || ''}
              onChange={e => setSize({ ...size, col: parseInt(e.target.value) })}
            />
            <div className="font-semibold text-[12px] text-[#232323]">x</div>
            <input
              type="number"
              min={1}
              max={10}
              name="Row"
              className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] max-w-[195px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
              placeholder="Row"
              value={parseInt(size?.row) || ''}
              onChange={e => setSize({ ...size, row: parseInt(e.target.value) })}
            />
          </div>

          {gridItems.length ? (
            <>
              <div
                className={`w-full overflow-x-auto overflow-y-hidden ${
                  enableMerge || enableSplitCol || enableSplitRow
                    ? getRow(getIndexForDropdown()) + 1 === size?.row
                      ? 'pb-12'
                      : getRow(getIndexForDropdown()) + 2 === size?.row
                      ? 'pb-2'
                      : ''
                    : ''
                }`}
              >
                <div
                  className="grid w-max"
                  style={{
                    gridTemplateColumns: `repeat(${size?.col}, minmax(0, 1fr))`,
                    gridTemplateRows: `repeat(${size?.row}, minmax(0, 1fr))`
                  }}
                >
                  {gridItems.map(index => (
                    <div
                      key={index}
                      className={`border ${
                        activeCells.includes(index) ? 'border-[#6546C3]' : 'border-[#DEDEDE]'
                      } min-h-[48px] min-w-[250px] cursor-pointer flex justify-between items-center px-3 relative`}
                      style={
                        items?.find(item => item.minIndex === index)
                          ? {
                              ...(items.find(item => item.minIndex === index)?.position?.row[0] && {
                                gridRowStart: items.find(item => item.minIndex === index)?.position
                                  ?.row[0]
                              }),
                              ...(items.find(item => item.minIndex === index)?.position?.row[1] && {
                                gridRowEnd:
                                  items.find(item => item.minIndex === index)?.position?.row[1] + 1
                              }),
                              ...(items.find(item => item.minIndex === index)?.position?.col[0] && {
                                gridColumnStart: items.find(item => item.minIndex === index)
                                  ?.position?.col[0]
                              }),
                              ...(items.find(item => item.minIndex === index)?.position?.col[1] && {
                                gridColumnEnd:
                                  items.find(item => item.minIndex === index)?.position?.col[1] + 1
                              })
                            }
                          : {}
                      }
                      onClick={() => handleActiveCells(index)}
                    >
                      {items?.find(item => item.minIndex === index)?.fieldTitle ||
                      items?.find(item => item.minIndex === index)?.additionalOption ? (
                        <>
                          <div className="text-[#C800A5] text-[14px] flex gap-1">
                            {'{'}
                            <div className="truncate max-w-[150px]">
                              {items?.find(item => item.minIndex === index)?.additionalOption
                                ? items?.find(item => item.minIndex === index)?.additionalOption
                                : items?.find(item => item.minIndex === index)?.fieldTitle}
                            </div>
                            {'}'}
                          </div>
                          <img
                            src={icons.icTrash}
                            className="w-6 h-6 cursor-pointer"
                            onClick={e => {
                              e.stopPropagation()
                              handleDeleteParameter(index)
                            }}
                          />
                        </>
                      ) : items?.find(item => item.minIndex === index)?.hasOwnProperty('text') ? (
                        <>
                          <input
                            type="text"
                            name="Text Field"
                            className="flex-1 input-text text-[12px] px-[12px] py-[8px] mr-2 h-[32px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                            placeholder="Type here..."
                            onClick={e => {
                              e.stopPropagation()
                            }}
                            value={items?.find(item => item.minIndex === index)?.text}
                            onChange={e => handleChangeTextField(index, e.target.value)}
                          />
                          <img
                            src={icons.icTrash}
                            className="w-6 h-6 cursor-pointer"
                            onClick={e => {
                              e.stopPropagation()
                              handleDeleteParameter(index)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {enableMerge && index === getIndexForDropdown() && (
                        <div className="absolute z-20 left-0 -bottom-[52px] bg-white rounded-2xl w-fit px-4 py-3 flex gap-4 items-center shadow-[0_6px_16px_0px_rgba(0,0,0,0.12)]">
                          <img
                            src={icons.icMerge}
                            className="w-6 cursor-pointer"
                            onClick={e => {
                              e.stopPropagation()
                              handleMergeCells()
                            }}
                          />
                        </div>
                      )}
                      {(enableSplitCol || enableSplitRow) && index === activeCells[0] && (
                        <div className="absolute z-20 left-0 -bottom-[52px] bg-white rounded-2xl w-fit px-4 py-3 flex gap-4 items-center shadow-[0_6px_16px_0px_rgba(0,0,0,0.12)]">
                          {enableSplitRow && (
                            <img
                              src={icons.icSplitHorizontal}
                              className="w-6 cursor-pointer"
                              onClick={e => {
                                e.stopPropagation()
                                handleSplitCells({ type: 'row' })
                              }}
                            />
                          )}
                          {enableSplitCol && (
                            <img
                              src={icons.icSplitVertical}
                              className="w-4 h-4 cursor-pointer"
                              onClick={e => {
                                e.stopPropagation()
                                handleSplitCells({ type: 'col' })
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-end mt-4">
                <div className="dropdown dropdown-top dropdown-end" ref={content}>
                  <button
                    type="button"
                    tabIndex={0}
                    onClick={handleClickAddContent}
                    className="py-2 px-4 bg-[#6546C3] rounded-full flex gap-2 items-center"
                  >
                    {addContentOpen ? (
                      <img src={icons.icCloseContent} className="w-6 h-6" />
                    ) : (
                      <img src={icons.icAddContent} className="w-6 h-6" />
                    )}{' '}
                    <div className="text-white text-[14px] font-medium">Add Content</div>
                  </button>
                  <div
                    tabIndex="0"
                    className="dropdown-content z-[1] bg-transparent w-fit pb-4 flex flex-col gap-4"
                  >
                    <div
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={() => {
                        handleClickAddContent()
                        setShowParameterModal(true)
                      }}
                    >
                      <div className="py-2 bg-[#232323] bg-opacity-60 rounded-lg text-white text-[12px] w-[80px] text-center">
                        Parameter
                      </div>
                      <div
                        className="bg-white rounded-full w-[40px] h-[40px] flex items-center justify-center 
                      shadow-[0_6px_16px_0px_rgba(0,0,0,0.12)]"
                      >
                        <img src={icons.icParameter} className="w-6 h-6" />
                      </div>
                    </div>
                    <div
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={() => {
                        handleClickAddContent()
                        handleAddTextField()
                      }}
                    >
                      <div className="py-2 bg-[#232323] bg-opacity-60 rounded-lg text-white text-[12px] w-[80px] text-center">
                        Text Field
                      </div>
                      <div
                        className="bg-white rounded-full w-[40px] h-[40px] flex items-center justify-center 
                      shadow-[0_6px_16px_0px_rgba(0,0,0,0.12)]"
                      >
                        <img src={icons.icTextField} className="w-6 h-6" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end gap-4 mt-10">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
                >
                  Back
                </button>
                <button
                  type="submit"
                  onClick={() => handleSubmitTabletLayout()}
                  className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                >
                  Submit
                </button>
              </div>
            </>
          ) : (
            <div className="py-20">
              <NoDataTable
                message="You’ve not added any matrix yet"
                detailedMessage='Please input the "matrix" to show it now!'
              />
            </div>
          )}

          <ParameterModal
            showModal={showParameterModal}
            setShowModal={setShowParameterModal}
            parameterOptions={parameterOptions}
            setSelectedParameter={setSelectedParameter}
          />
        </div>
      </div>
    </>
  )
}
