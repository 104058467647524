import { isEqual } from 'lodash'
import { memo } from 'react'

import { ICONS } from '../../../../../constants'
import Field from './Field'
import { useContext } from 'react'
import { PageContext } from '../../..'

const FieldMemoized = memo(
  props => <Field {...props} />,
  (prev, next) =>
    isEqual(prev.fieldData, next.fieldData) &&
    isEqual(prev.siblings, next.siblings) &&
    isEqual(prev.disabled, next.disabled)
)
FieldMemoized.displayName = 'FieldMemoized'

export default function Section({
  path,
  sectionData,
  onInnerSectionAdd,
  onInnerSectionRemove,
  formSubmitted
}) {
  const { topColor } = useContext(PageContext)
  const fields = sectionData.fields
  const isMultiple = sectionData.multiple_input
  const subSections = sectionData.sub_sections
  const innerSections = sectionData.inner_sections

  return (
    <div className="flex flex-col p-2 md:p-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-[14px]" style={{ color: topColor || '#00B1B5' }}>
          {sectionData.section_title}
        </div>
      </div>

      <hr className="mt-2 mb-4" />

      {/* MULTI */}
      {isMultiple && (
        <div className="flex flex-col gap-4">
          <button
            type="button"
            className="px-6 py-2 text-white font-semibold rounded-md text-xs self-start"
            style={{
              backgroundColor: topColor || '#00B1B5'
            }}
            onClick={onInnerSectionAdd}
          >
            Add New
          </button>

          {innerSections.map((fields, innerIndex) => (
            <div className="flex flex-col gap-4 bg-white rounded-xl p-2 md:p-4" key={innerIndex}>
              {innerSections.length > 1 && (
                <img
                  src={ICONS.icTrash}
                  className="w-[28px] cursor-pointer self-end"
                  onClick={() => onInnerSectionRemove(innerIndex)}
                  alt="remove inner section"
                />
              )}
              {fields.map((field, fieldIndex) => (
                <FieldMemoized
                  key={field.field_id}
                  path={`${path}.inner_sections.${innerIndex}.${fieldIndex}`}
                  fieldData={field}
                  siblings={fields}
                  disabled={formSubmitted}
                />
              ))}
            </div>
          ))}
        </div>
      )}

      {/* !MULTI */}
      {!isMultiple && (
        <div className="flex flex-col gap-4">
          {fields.map((field, fieldIndex) => (
            <FieldMemoized
              key={field.field_id}
              path={`${path}.fields.${fieldIndex}`}
              fieldData={field}
              siblings={fields}
              disabled={formSubmitted}
            />
          ))}
        </div>
      )}

      {subSections?.map((section, index) => (
        <div key={section.section_id}>
          <hr className="my-6" />
          <div className="bg-white rounded-xl">
            <Section
              key={section.section_id}
              path={`${path}.sub_sections.${index}`}
              sectionData={section}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
