import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { get } from '../../../api/base'
import { Toast, Toggle } from '../../../components'
import { apiUrls, ICONS } from '../../../constants'

export default function DetailModule() {
  const navigate = useNavigate()
  const { id_module } = useParams()
  const [detailData, setDetailData] = useState({})

  useEffect(() => {
    fetchModule()
  }, [])

  async function fetchModule() {
    await get(`${apiUrls.MODULES_URL}/${id_module}`).then(response => {
      const { status, data } = response
      if (status === 200) {
        setDetailData(data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch module'} />)
      }
    })
  }

  return (
    <div className="grid lg:grid-cols-4 gap-6 mx-2 md:mx-6 my-2">
      {/* LOGO */}
      <div className="bg-white rounded-2xl p-4">
        <div className="flex items-center justify-center rounded-xl bg-[#F2F5FC] h-full py-8 px-4">
          {detailData.icon ? (
            <img src={detailData.icon} className="max-w-full max-h-full" alt="icon" />
          ) : (
            <img src={ICONS.icDefaultModule} className="w-28" alt="placeholder icon" />
          )}
        </div>
      </div>

      {/* INFO */}
      <div className="bg-white rounded-2xl p-4 sm:p-6 lg:col-span-3">
        <div className="grid lg:grid-cols-3 gap-6">
          <div>
            <div className="font-semibold text-[12px] mb-2">Module Name</div>
            <div className="text-[12px] text-[#858585]">{detailData.name}</div>
          </div>

          <div>
            <div className="font-semibold text-[12px] mb-2">URL</div>
            <div className="text-[12px] text-[#858585] break-words">
              {detailData.deeplink || '-'}
            </div>
          </div>

          <div>
            <div className="font-semibold text-[12px] mb-2">Available Area</div>
            <div className="text-[12px] text-[#858585]">
              {detailData.areas?.map(area => area.name).join(', ') || '-'}
            </div>
          </div>

          <div>
            <div className="font-semibold text-[12px] mb-2">Is Active</div>
            <Toggle checked={detailData.active} />
          </div>

          <div className="lg:col-span-2">
            <div className="font-semibold text-[12px] mb-2">Description</div>
            <div className="text-[12px] text-[#858585] mb-2">
              {detailData.description ? (
                <span dangerouslySetInnerHTML={{ __html: detailData.description }} />
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-4 lg:col-span-4">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
        >
          Back
        </button>
      </div>
    </div>
  )
}
