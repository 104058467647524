import { Controller, useFormContext } from 'react-hook-form'

import { Select } from '../../../../components'

export default function PrivateMenu({ isEdit, groups }) {
  const { control, register, watch } = useFormContext()
  const isPrivateMenu = watch('is_private_menu')
  const selectedGroups = watch('groups')

  return (
    <div className="flex flex-row mt-7">
      <div className="mr-4 mt-2">
        <div className="flex flex-row">
          <input {...register('is_private_menu')} type="checkbox" disabled={!isEdit} />
          <div className="text-[12px] ml-2">Private Menu</div>
        </div>
      </div>
      {isPrivateMenu && (
        <div className="grow">
          {isEdit ? (
            <div>
              <Controller
                control={control}
                name="groups"
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    options={groups}
                    getOptionLabel={({ group }) => group?.name}
                    getOptionValue={({ group_id }) => group_id}
                    isDisabled={!isEdit}
                    placeholder="Select groups"
                    isMulti={true}
                    styles={{
                      option: provided => ({
                        ...provided
                      }),
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({}),
                      valueContainer: provided => ({
                        ...provided
                      }),
                      multiValueLabel: provided => ({
                        ...provided,
                        color: '#fff',
                        fontFamily: 'poppins'
                      }),
                      multiValueRemove: provided => ({
                        ...provided,
                        color: '#fff'
                      }),
                      multiValue: provided => ({
                        ...provided,
                        backgroundColor: '#6546C3',
                        borderRadius: 8
                      })
                    }}
                  />
                )}
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-2">
              {selectedGroups.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="font-poppins text-xs font-semibold text-center rounded-xl mx-2 px-4 py-2 bg-[#F0EEF6] text-[#6748C3]"
                  >
                    {data.name}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
