import { ICONS } from '../../../../../../../constants'
import Field from '../Field'
import SubSection from '../SubSection'

const Section = ({ sectionData }) => {
  const fields = sectionData.fields
  const innerSections = sectionData.innerSections
  const subSections = sectionData.sub_sections
  const isMultiple = sectionData.multiple_input
  return (
    <div className="flex flex-col p-4 bg-[#F2F5FC] rounded-2xl">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-[14px] text-[#C800A5]">{sectionData.section_title}</div>
        {isMultiple ? <img src={ICONS.icAdd} className="w-6 h-6 rounded-full" /> : <></>}
      </div>
      <hr className="mt-2 mb-4" />
      <div className="flex flex-col gap-4">
        {fields.map(field => (
          <Field key={field.field_id} fieldData={field} />
        ))}
        {subSections?.map((section, subSectionIndex) => {
          return (
            <div key={subSectionIndex}>
              <hr className="" />
              <div className="flex flex-col gap-4">
                <SubSection key={`${section.section_id}${subSectionIndex}`} section={section} />
              </div>
            </div>
          )
        })}
        {innerSections?.map((innerSectionFields, index) => {
          return (
            <div key={index}>
              <hr className="my-6" />
              <div className="flex flex-col gap-4">
                {innerSectionFields.map((field, index) => (
                  <Field key={`${field.field_id}${index}`} fieldData={field} />
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Section
