import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { get } from '../../../api/base'
import { Toast, SuccessPopup } from '../../../components'
import { apiUrls } from '../../../constants'

import Info from './Info'
import MenuList from './MenuList'
import MenuDetail from './MenuDetail'
import UserGroup from './UserGroup'

export default function DetailMicrosite() {
  const params = useParams()

  const [microsite, setMicrosite] = useState({})
  const [selectedMenu, setSelectedMenu] = useState({})

  useEffect(() => {
    fetchMicrosite(params.microsite_route)
  }, [])

  async function fetchMicrosite(micrositeRoute) {
    const { status, data } = await get(`${apiUrls.MICROSITES_ROUTE}/${micrositeRoute}`)
    if (status === 200) {
      setMicrosite(data)
    } else if (status === 408) {
      toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch microsite data'} />)
    }
  }

  function onChangeMenus(menus) {
    setMicrosite(microsite => ({ ...microsite, menus }))
  }

  return (
    <div className="mx-2 md:mx-6 my-2">
      <Info data={microsite} />

      <div className="flex flex-col lg:flex-row my-6">
        <MenuList
          menus={microsite.menus}
          route_name={microsite.route_name}
          selected={selectedMenu}
          onSelect={setSelectedMenu}
          onChangeMenus={onChangeMenus}
          onSuccess={() => fetchMicrosite(params.microsite_route)}
        />
        <MenuDetail
          groups={microsite.groups}
          selected={selectedMenu}
          onSuccess={() => fetchMicrosite(params.microsite_route)}
        />
        <UserGroup
          data={microsite.groups}
          route_name={microsite.route_name}
          onSuccess={() => fetchMicrosite(params.microsite_route)}
        />
      </div>

      <SuccessPopup />
    </div>
  )
}
