import { useEffect, useMemo, useState } from 'react'

import { SuccessPopup, DropdownIndicator, DeletePopup } from '../../../../../components'
import { ICONS } from '../../../../../constants'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import {
  setShowPopupSuccess,
  setMessage,
  setShowPopupDelete
} from '../../../../../redux/slices/popupSlice'
import NoDataTable from '../../../../../components/NoDataTable'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useTable } from 'react-table'
import { useRef } from 'react'

export default function JumpSettingsModal({
  showModal,
  setShowModal,
  section,
  setCurrentSectionId
}) {
  const fields = section?.fields
  const sectionData = useRef(section)
  const dispatch = useDispatch()
  const [fieldOption, setFieldOption] = useState([])
  const [selectedField, setSelectedField] = useState(null)
  const [selectedCondition, setSelectedCondition] = useState(null)
  const [value, setValue] = useState(null)
  const [selectedLogicOperator, setSelectedLogicOperator] = useState(null)
  let valueFormat = selectedField?.format?.replace(/d/g, 'D').replace(/y/g, 'Y')
  let adjustedFormat = selectedField?.format?.replace(/D/g, 'd').replace(/Y/g, 'y')
  const [isEditingMode, setIsEditingMode] = useState(false)
  const [selectedJumpSettingId, setSelectedJumpSettingId] = useState(null)

  useEffect(() => {
    sectionData.current = section
  }, [section])

  const logicOperatorOptions = [
    { label: 'AND', value: 'AND' },
    { label: 'OR', value: 'OR' }
  ]

  const conditionOptions = [
    { label: 'Equal', value: 'EQUAL' },
    { label: 'Not Equal', value: 'NOT EQUAL' },
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '>=', value: '>=' },
    { label: '<=', value: '<=' }
  ]

  useEffect(() => {
    if (showModal && fields?.length > 0) {
      let includedFieldType = [
        'TEXT_FIELD',
        'TEXT_AREA',
        'RATE_BY_ICON',
        'RADIO_BUTTON',
        'RADIO_BUTTON_ATTACHMENT',
        'DATE_PICKER'
      ]
      setFieldOption(
        fields
          .filter(
            field =>
              includedFieldType.includes(field.field_type?.value) &&
              (!field?.additional_option || field?.additional_option?.length === 0)
          )
          .map(field => {
            return {
              field_id: field.field_id,
              field_title: field.field_title,
              field_type: field.field_type?.value,
              keyboard_type: field.keyboard_type?.value,
              ...(field.field_type?.value === 'DATE_PICKER' &&
                field?.format && { format: field?.format })
            }
          })
      )
    }
  }, [showModal])

  function close() {
    setShowModal(false)
    setCurrentSectionId(null)
  }

  function cancel() {
    setSelectedField(null)
    setSelectedCondition(null)
    setValue(null)
    setSelectedLogicOperator(null)
    setIsEditingMode(false)
  }

  function jumpSettingsValidation() {
    if (
      (!sectionData?.current?.jump_settings?.length || (isEditingMode && !selectedLogicOperator)) &&
      (!selectedField || !selectedCondition || value === null || value === '')
    ) {
      return false
    } else if (
      sectionData?.current?.jump_settings?.length > 0 &&
      !(isEditingMode && !selectedLogicOperator) &&
      (!selectedField ||
        !selectedCondition ||
        value === null ||
        value === '' ||
        !selectedLogicOperator)
    ) {
      return false
    }
    return true
  }

  function handleAddJumpSettings() {
    let jumpSetting = {
      id: uuidv4(),
      field_id: selectedField?.field_id,
      field_title: selectedField?.field_title,
      condition: selectedCondition,
      value: value,
      ...(sectionData.current?.jump_settings?.length > 0 && {
        logic_operator: selectedLogicOperator
      })
    }
    setSelectedField(null)
    setSelectedCondition(null)
    setValue(null)
    setSelectedLogicOperator(null)

    if (sectionData.current) {
      sectionData.current.jump_settings = sectionData.current?.jump_settings
        ? [...sectionData.current.jump_settings, jumpSetting]
        : [jumpSetting]
    }

    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage('You have successfully add jump setting'))
  }

  function handleEditJumpSettings() {
    const jumpSetting = sectionData.current.jump_settings.find(
      item => item.id === selectedJumpSettingId
    )
    if (jumpSetting) {
      jumpSetting.field_id = selectedField?.field_id
      jumpSetting.field_title = selectedField?.field_title
      jumpSetting.condition = selectedCondition
      jumpSetting.value = value
      if (sectionData.current.hasOwnProperty('logic_operator')) {
        jumpSetting.logic_operator = selectedLogicOperator
      }
    }
    setSelectedField(null)
    setSelectedCondition(null)
    setValue(null)
    setSelectedLogicOperator(null)
    setSelectedJumpSettingId(null)
    setIsEditingMode(false)

    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage('You have successfully edit jump setting'))
  }

  function handleDeleteJumpSettings() {
    sectionData.current.jump_settings = sectionData.current.jump_settings.filter(
      item => item.id !== selectedJumpSettingId
    )
    setSelectedJumpSettingId(null)

    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage('You have successfully delete jump setting'))
  }

  //====== Jump Settings Table ======\\

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map(data => {
        if (data === 0)
          return (
            <div key={data} className="p-1 px-2 font-bold mr-1 ">
              ...
            </div>
          )
        return (
          <div
            key={data}
            className={`w-8 h-8 py-[8px] font-poppins cursor-pointer text-center rounded-full ${
              pageNum === data ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white' : ''
            }`}
            onClick={() => {
              setPageNum(data)
            }}
          >
            {data}
          </div>
        )
      })
      return result
    }
  }

  const COLUMNS = [
    {
      Header: '',
      accessor: 'logic_operator',
      Cell: ({ value }) => <div className="font-semibold text-[#C800A5]">{value}</div>
    },
    {
      Header: 'Field',
      accessor: 'field_title'
    },
    {
      Header: 'Condition',
      accessor: 'condition'
    },
    {
      Header: 'Value',
      accessor: 'value'
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        const handleEditClick = () => {
          setIsEditingMode(true)
          setSelectedJumpSettingId(row.original.id)
          setSelectedField(fieldOption.find(field => field.field_id === row.original.field_id))
          setSelectedCondition(row.original.condition)
          setValue(row.original.value)
          setSelectedLogicOperator(row.original?.logic_operator || null)
        }
        const handleDeleteClick = () => {
          setSelectedJumpSettingId(row.original.id)
          dispatch(setShowPopupDelete(true))
          dispatch(setMessage('Are you sure you want to delete this jump setting?'))
        }

        return (
          <div className="flex gap-2">
            <img
              src={ICONS.icEdit}
              className="w-[28px] cursor-pointer"
              onClick={() => handleEditClick()}
              alt="edit"
            />
            <img
              src={ICONS.icTrash}
              className="w-[28px] cursor-pointer"
              onClick={() => handleDeleteClick(row.original.id)}
              alt="delete"
            />
          </div>
        )
      }
    }
  ]

  const columns = useMemo(() => COLUMNS, [])
  const [pageNum, setPageNum] = useState(1)
  const limit = 8
  const totalPage = Math.ceil(sectionData.current?.jump_settings?.length / limit)
  const totalData = sectionData.current?.jump_settings?.length

  const splittedData = []

  for (let i = 0; i < sectionData.current?.jump_settings?.length; i += limit) {
    const chunk = sectionData.current?.jump_settings?.slice(i, i + limit)
    splittedData.push(chunk)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: splittedData[pageNum - 1] || [],
    pageNum
  })

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = []
    if (pageNumbers) {
      paginationNumbers.push(1)
      if (pageNum - 2 > 1) paginationNumbers.push(0)
      if (pageNum - 1 > 1) paginationNumbers.push(pageNum - 1)
      if (pageNum !== 1 && pageNum !== pageNumbers) paginationNumbers.push(pageNum)
      if (pageNum + 1 < pageNumbers) paginationNumbers.push(pageNum + 1)
      if (pageNum + 2 < pageNumbers) paginationNumbers.push(0)
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers)

      return showRenderPageNumbers(paginationNumbers)
    }
  }

  const canNextPage = () => {
    return pageNum + 1 <= totalPage
  }
  const canPreviousPage = () => {
    return pageNum - 1 !== 0
  }

  const goToNextPage = () => {
    setPageNum(prev => {
      return prev + 1
    })
  }
  const goToPreviousPage = () => {
    setPageNum(prev => {
      return prev - 1
    })
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div
          className="modal-box bg-white max-w-full w-fit md:w-[760px] lg:w-[900px] h-fit flex flex-col"
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Jump Settings</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />
          <div className="flex gap-6">
            <div className="flex flex-col justify-between p-4 bg-white rounded-2xl shadow-lg basis-5/12">
              <div className="flex flex-col gap-4">
                <div className="flex-1">
                  <div className="font-semibold text-xs mb-2">Field</div>
                  <Select
                    components={{ DropdownIndicator }}
                    getOptionLabel={option => option?.field_title}
                    getOptionValue={option => option?.field_id}
                    onChange={field => {
                      setSelectedField(field)
                      setSelectedCondition(null)
                      setValue(null)
                    }}
                    value={selectedField}
                    isSearchable={true}
                    options={fieldOption}
                    className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Select field"
                    styles={{
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({})
                    }}
                  />
                </div>
                <div className="flex-1">
                  <div className="font-semibold text-xs mb-2">Condition</div>
                  <Select
                    components={{ DropdownIndicator }}
                    onChange={cond => setSelectedCondition(cond.value)}
                    value={conditionOptions?.find(cond => cond.value === selectedCondition) || null}
                    isSearchable={true}
                    options={
                      selectedField
                        ? ((selectedField?.field_type === 'TEXT_FIELD' ||
                            selectedField?.field_type === 'TEXT_AREA') &&
                            selectedField?.keyboard_type === 'DEFAULT') ||
                          selectedField?.field_type === 'RADIO_BUTTON' ||
                          selectedField?.field_type === 'RADIO_BUTTON_ATTACHMENT'
                          ? conditionOptions.slice(0, 2)
                          : conditionOptions
                        : []
                    }
                    className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Select condition"
                    styles={{
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({})
                    }}
                  />
                </div>
                <div className="flex-1">
                  <div className="font-semibold text-xs mb-2">Value</div>
                  {selectedField &&
                  (selectedField?.field_type === 'TEXT_FIELD' ||
                    selectedField?.field_type === 'TEXT_AREA' ||
                    selectedField?.field_type === 'RATE_BY_ICON') ? (
                    <input
                      type={selectedField?.keyboard_type === 'DEFAULT' ? 'text' : 'number'}
                      className="flex-1 text-[12px] px-[16px] py-[12px] mr-2 h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
                      placeholder="Enter value"
                      onChange={e => setValue(e.target.value)}
                      value={value || ''}
                    />
                  ) : selectedField?.field_type === 'RADIO_BUTTON' ||
                    selectedField?.field_type === 'RADIO_BUTTON_ATTACHMENT' ? (
                    <Select
                      components={{ DropdownIndicator }}
                      onChange={option => setValue(option.value)}
                      value={
                        fields
                          ?.find(field => field.field_id === selectedField?.field_id)
                          ?.options?.find(opt => opt.value === value) || null
                      }
                      isSearchable={true}
                      options={
                        fields?.find(field => field.field_id === selectedField?.field_id)?.options
                      }
                      className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                      placeholder="Select value"
                      styles={{
                        control: provided => ({
                          ...provided,
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: base => ({
                          ...base,
                          color: '#C800A5',
                          ':hover': { color: '#6546C3' }
                        }),
                        indicatorSeparator: () => ({})
                      }}
                    />
                  ) : selectedField?.field_type === 'DATE_PICKER' ? (
                    <div className="flex relative">
                      <DatePicker
                        selected={!value ? null : moment(value, valueFormat).toDate()}
                        onChange={date => setValue(moment(date).format(valueFormat))}
                        timeInputLabel="Time:"
                        dateFormat={adjustedFormat}
                        showYearPicker={['yy', 'yyyy'].includes(
                          selectedField?.format?.toLowerCase()
                        )}
                        className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                        wrapperClassName="date-picker"
                        placeholderText="Select value"
                        onKeyDown={e => {
                          e.preventDefault()
                        }}
                        showTimeInput={
                          adjustedFormat?.includes('H') || adjustedFormat?.includes('h')
                        }
                        showTimeSelectOnly={
                          (adjustedFormat?.includes('H') || adjustedFormat?.includes('h')) &&
                          (!adjustedFormat?.includes('y') || !adjustedFormat?.includes('d'))
                        }
                      />
                      <img
                        src={ICONS.icCalendar}
                        className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
                      />
                    </div>
                  ) : (
                    <Select
                      components={{ DropdownIndicator }}
                      options={[]}
                      value={null}
                      className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                      placeholder="Select value"
                      styles={{
                        control: provided => ({
                          ...provided,
                          ':focus': { borderColor: '#6546C3' },
                          ':active': { borderColor: '#6546C3' },
                          ':hover': { borderColor: '#6546C3' },
                          paddingLeft: 4,
                          boxShadow: 'none'
                        }),
                        dropdownIndicator: base => ({
                          ...base,
                          color: '#C800A5',
                          ':hover': { color: '#6546C3' }
                        }),
                        indicatorSeparator: () => ({})
                      }}
                    />
                  )}
                </div>
                <div className="flex-1">
                  <div className="font-semibold text-xs mb-2">Logic Operator</div>
                  <Select
                    components={{ DropdownIndicator }}
                    onChange={operator => setSelectedLogicOperator(operator.value)}
                    value={
                      logicOperatorOptions?.find(
                        operator => operator.value === selectedLogicOperator
                      ) || null
                    }
                    isSearchable={true}
                    isDisabled={
                      !sectionData.current?.jump_settings?.length ||
                      (isEditingMode && !selectedLogicOperator)
                    }
                    options={logicOperatorOptions}
                    className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                    placeholder="Select logic operator"
                    styles={{
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: base => ({
                        ...base,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({})
                    }}
                  />
                </div>
              </div>
              {isEditingMode ? (
                <div className="flex justify-end gap-4 mt-12">
                  <button
                    onClick={cancel}
                    className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    className="py-3 px-8 disabled:bg-gray-400 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                    onClick={() => handleEditJumpSettings()}
                    disabled={!jumpSettingsValidation()}
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div className="flex justify-end mt-12">
                  <button
                    className="py-3 px-8 disabled:bg-gray-400 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
                    onClick={() => handleAddJumpSettings()}
                    disabled={!jumpSettingsValidation()}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
            <div className="flex-col basis-7/12">
              <div className="overflow-x-auto w-full">
                <table {...getTableProps()} className="w-full font-poppins text-[12px]">
                  <thead className="">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th key={index}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return (
                              <td {...cell.getCellProps()} className="!bg-transparent">
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {!sectionData.current?.jump_settings?.length && (
                <div className="py-10">
                  <NoDataTable
                    message="No Jumping Setting yet"
                    detailedMessage="The added data will be displayed here"
                  />
                </div>
              )}
              <div className="flex flex-col sm:flex-row gap-2 justify-between items-center mt-4">
                {sectionData.current?.jump_settings?.length > 0 ? (
                  <div className="font-poppins text-[12px]">
                    Showing {pageNum * limit - (limit - 1)} -{' '}
                    {pageNum * limit > totalData ? totalData : pageNum * limit} of {totalData} data
                  </div>
                ) : (
                  <div className="font-poppins text-[12px]">No data to show</div>
                )}
                <div className="flex font-poppins text-[12px]">
                  <button
                    onClick={() => goToPreviousPage()}
                    disabled={!canPreviousPage()}
                    className={`w-8 h-8 mr-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                      !canPreviousPage()
                        ? 'bg-[#F2F5FC]'
                        : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
                    }`}
                  >
                    &lt;
                  </button>
                  <div className="bg-[#F2F5FC] rounded-full flex">
                    {showPaginationNumbers(totalPage)}
                  </div>
                  <button
                    onClick={() => goToNextPage()}
                    disabled={!canNextPage()}
                    className={`w-8 h-8 ml-2 py-[5px] font-poppins cursor-pointer text-center rounded-full ${
                      !canNextPage()
                        ? 'bg-[#F2F5FC]'
                        : 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white'
                    }`}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>

          <SuccessPopup />
          <DeletePopup
            setContinueDelete={() => handleDeleteJumpSettings()}
            buttonNext={'Delete Now'}
          />
        </div>
      </div>
    </>
  )
}
