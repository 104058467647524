import { useEffect, useState } from 'react'

import { SuccessPopup, DropdownIndicator, Toast } from '../../../../../components'
import { ICONS, apiUrls } from '../../../../../constants'
import Select, { components } from 'react-select'
import { useDispatch } from 'react-redux'
import { setShowPopupSuccess, setMessage } from '../../../../../redux/slices/popupSlice'
import NoDataTable from '../../../../../components/NoDataTable'
import { get } from '../../../../../api/base'
import { toast } from 'react-toastify'

export default function LinkageModal({ showModal, setShowModal, currentLinkage, setLinkage }) {
  const dispatch = useDispatch()
  const [listOfFields, setListOfFields] = useState([])
  const [selectAllFields, setSelectAllFields] = useState(false)
  const [selectedFields, setSelectedFields] = useState([])
  const [selectedForm, setSelectedForm] = useState(null)
  const [idValue, setIdValue] = useState(null)
  const [canMultiple, setCanMultiple] = useState(false)
  const [formOptions, setFormOptions] = useState([])

  const handleOnCheckAllField = event => {
    setSelectAllFields(event.target.checked)
    if (event.target.checked) {
      let fieldsCopy = [...listOfFields]
      setSelectedFields(fieldsCopy)
    } else {
      setSelectedFields([])
    }
  }

  const handleOnChangeCheckField = (field, event) => {
    let selectedFieldsCopy = [...selectedFields]
    if (event.target.checked) {
      selectedFieldsCopy.push(field)
    } else {
      const index = selectedFieldsCopy.indexOf(field)
      selectedFieldsCopy.splice(index, 1)
    }

    setSelectedFields(selectedFieldsCopy)
  }

  useEffect(() => {
    if (selectedFields.length === listOfFields.length && listOfFields.length > 0) {
      setSelectAllFields(true)
    } else {
      setSelectAllFields(false)
    }

    if (selectedFields.length > 0) {
      if (idValue) {
        /* check if selected id value exist in field options to handle selected id value 
        still have value even when the selected value removed from selected fields */
        const exist = selectedFields.find(field => field.field_id === idValue)

        if (!exist) {
          setIdValue(null)
        }
      }
    } else {
      setIdValue(null)
    }
  }, [selectedFields])

  useEffect(() => {
    if (selectedForm) {
      fetchFields(selectedForm.id)
      if (selectedForm.id !== currentLinkage?.form_id) {
        setSelectedFields([])
      }
    } else {
      setListOfFields([])
    }
  }, [selectedForm])

  const handleSaveLinkage = () => {
    setLinkage({
      form_id: selectedForm.id,
      form_title: selectedForm.title,
      configuration: {
        fields: selectedFields,
        id_value: idValue,
        can_multiple: canMultiple
      }
    })
    setShowModal(false)

    dispatch(setShowPopupSuccess(true))
    dispatch(setMessage(`You have successfully add linkage`))
  }

  const fetchForms = () => {
    get(`${apiUrls.FORMS_URL}/linkages`).then(response => {
      const { status, data } = response
      if (status === 200) {
        setFormOptions(data)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch form linkage'} />)
      }
    })
  }

  const fetchFields = id => {
    get(`${apiUrls.FORMS_URL}/${id}/fields`).then(response => {
      const { status, data } = response
      if (status === 200) {
        setListOfFields(data)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to fetch fields form'} />)
      }
    })
  }

  useEffect(() => {
    if (showModal) {
      fetchForms()
      if (currentLinkage) {
        if (currentLinkage?.form_id) {
          setSelectedForm({ id: currentLinkage?.form_id })
        }
        if (currentLinkage?.configuration?.fields) {
          setSelectedFields(currentLinkage?.configuration?.fields)
        } else {
          setSelectedFields([])
        }
        if (currentLinkage?.configuration?.id_value) {
          setIdValue(currentLinkage?.configuration?.id_value)
        } else {
          setIdValue(null)
        }
        if (currentLinkage?.configuration?.can_multiple) {
          setCanMultiple(currentLinkage?.configuration?.can_multiple)
        } else {
          setCanMultiple(false)
        }
      } else {
        setSelectedForm(null)
        setSelectedFields([])
        setIdValue(null)
        setCanMultiple(false)
      }
    }
  }, [showModal])

  const Option = props => {
    return (
      <components.Option {...props}>
        <div>
          {props.data.title.length > 20
            ? props.data.title.substring(0, 20) + '...'
            : props.data.title}
          {props.data.form_type && (
            <span
              className={`ml-1 py-1 px-1.5 rounded-lg font-semibold text-[12px] bg-opacity-[0.08] ${
                props.data.form_type === 'DEFAULT'
                  ? 'text-[#6546C3] bg-[#6546C3]'
                  : 'text-[#FFAF00] bg-[#FFAF00]'
              }`}
            >
              {props.data.form_type}
            </span>
          )}
        </div>
      </components.Option>
    )
  }

  const SingleValue = ({ ...props }) => {
    return (
      <components.SingleValue {...props}>
        <div>
          {props.data.title.length > 20
            ? props.data.title.substring(0, 20) + '...'
            : props.data.title}
          {props.data.form_type && (
            <span
              className={`ml-1 py-1 px-1.5 rounded-lg font-semibold text-[12px] bg-opacity-[0.08] ${
                props.data.form_type === 'DEFAULT'
                  ? 'text-[#6546C3] bg-[#6546C3]'
                  : 'text-[#FFAF00] bg-[#FFAF00]'
              }`}
            >
              {props.data.form_type}
            </span>
          )}
        </div>
      </components.SingleValue>
    )
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />

      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white max-w-full w-fit md:w-[650px] h-fit flex flex-col">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Linkage</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={() => setShowModal(false)}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />
          <div className="flex flex-col gap-6">
            <div className="flex-row flex-1 rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
              <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
                <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
                  Form
                </div>
                <div className="flex flex-row gap-4">
                  {selectedFields.length !== 0 ? (
                    <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
                      {selectedFields.length} Field Selected
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                      checked={selectAllFields}
                      onChange={handleOnCheckAllField}
                    />
                    <div className="font-poppins font-normal text-[12px] text-white">All Field</div>
                  </div>
                </div>
              </div>
              <div className="flex-row px-4 py-3">
                <Select
                  components={{ DropdownIndicator, Option, SingleValue }}
                  onChange={selectedOption => setSelectedForm(selectedOption)}
                  getOptionLabel={option => option?.title}
                  getOptionValue={option => option?.id}
                  isSearchable={true}
                  options={formOptions}
                  value={formOptions?.find(form => form.id === selectedForm?.id) || null}
                  className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
                  placeholder="Select form"
                  styles={{
                    control: provided => ({
                      ...provided,
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({}),
                    menuList: base => ({
                      ...base,
                      maxHeight: '200px'
                    })
                  }}
                />
                <hr className="my-4" />
                {listOfFields.length ? (
                  <div className="flex-row max-h-[200px] min-h-[200px] overflow-y-auto scrollbar">
                    {listOfFields.map((field, index) => (
                      <div key={index} className="flex items-center justify-between mb-4">
                        <div className={`flex flex-1 text-[12px]`}>{field.field_title}</div>
                        <input
                          type="checkbox"
                          className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                          checked={selectedFields?.find(obj => obj.field_id === field.field_id)}
                          onChange={event => handleOnChangeCheckField(field, event)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="pb-4">
                    <NoDataTable
                      message="Field not available"
                      detailedMessage="Please choose a form to display field data"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-1 gap-8">
              <div className="flex flex-col flex-1">
                <div className="font-semibold text-xs mb-2">ID Value</div>
                <Select
                  components={{ DropdownIndicator }}
                  onChange={selectedOption => setIdValue(selectedOption.field_id)}
                  getOptionLabel={option => option?.field_title}
                  getOptionValue={option => option?.field_id}
                  isSearchable={true}
                  options={selectedFields}
                  value={selectedFields?.find(field => field.field_id === idValue) || null}
                  className={`text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-6`}
                  placeholder="Select id value"
                  styles={{
                    control: provided => ({
                      ...provided,
                      ':focus': { borderColor: '#6546C3' },
                      ':active': { borderColor: '#6546C3' },
                      ':hover': { borderColor: '#6546C3' },
                      paddingLeft: 4,
                      boxShadow: 'none'
                    }),
                    dropdownIndicator: base => ({
                      ...base,
                      color: '#C800A5',
                      ':hover': { color: '#6546C3' }
                    }),
                    indicatorSeparator: () => ({}),
                    menuList: base => ({
                      ...base,
                      maxHeight: '80px'
                    })
                  }}
                />
              </div>
              <div className="flex flex-col flex-1">
                <div className="font-semibold text-xs mb-4">Allow create more than 1 doc</div>
                <input
                  type="checkbox"
                  className="w-6 h-6 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                  checked={canMultiple}
                  onChange={event => setCanMultiple(event.target.checked)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
            >
              Back
            </button>
            <button
              type="submit"
              onClick={() => handleSaveLinkage()}
              className="py-3 px-8 enabled:bg-gradient-to-r enabled:from-[#6546C3] enabled:to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md disabled:bg-gray-200"
              disabled={selectedFields.length === 0}
            >
              Save
            </button>
          </div>

          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
