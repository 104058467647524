import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { get } from '../../../../../api/base'
import { apiUrls, ICONS } from '../../../../../constants'
import Section from './Section'
import { useContext } from 'react'
import { PageContext } from '../../..'

export default function View() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [content, setContent] = useState(null)
  const { topColor } = useContext(PageContext)

  const fetch = useCallback(async () => {
    const { data } = await get(`${apiUrls.FORMS_URL}/contents/${id}`)
    setContent(data)
  }, [id])

  useEffect(() => {
    fetch()
  }, [fetch])

  if (!content) return <div />

  return (
    <div className="text-xs">
      <div className="md:flex flex-wrap bg-white rounded-2xl mb-4 p-1 lg:p-2">
        <div className="flex items-center md:shrink-0 md:basis-1/3 p-2">
          <img src={ICONS.icDocumentGreen} className="h-10 mr-2" alt="code" />
          <div>
            <div className="font-semibold">Code Form</div>
            <div>{content.form.code_form}</div>
          </div>
        </div>
        <div className="flex items-center md:shrink-0 md:basis-1/3 p-2">
          <img src={ICONS.icDocumentOrange} className="h-10 mr-2" alt="title" />
          <div>
            <div className="font-semibold">Title</div>
            <div>{content.form.title}</div>
          </div>
        </div>
        <div className="flex items-center md:shrink-0 md:basis-1/3 p-2">
          <img src={ICONS.icDocumentPurple} className="h-10 mr-2" alt="title" />
          <div>
            <div className="font-semibold">Submitted Date</div>
            <div>{content.submitted_time}</div>
          </div>
        </div>
        <div className="flex items-center md:shrink-0 md:basis-1/3 p-2">
          <img src={ICONS.icTag} className="h-10 mr-2" alt="title" />
          <div>
            <div className="font-semibold">Tags</div>
            <div className="flex flex-wrap text-xs">
              {!content?.form?.tags?.length && <div>-</div>}
              {content?.form?.tags?.map((tag, t) => (
                <div
                  className="flex items-center rounded bg-teal-50 text-teal-500 my-2 mr-2 p-2"
                  key={t}
                >
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center md:shrink-0 md:basis-1/3 p-2">
          <div className="h-10" />
          <div>
            <div className="font-semibold">Company</div>
            <div>{content?.company?.name || '-'}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 bg-white rounded-2xl p-2 lg:p-4">
        {content.sections.map((section, index) => (
          <div className="bg-[#F2F5FC] rounded-xl" key={index}>
            <Section sectionData={section} />
          </div>
        ))}

        {content.attachments && (
          <div className="flex flex-col gap-2 mt-4 p-2 md:p-4">
            <div className="font-semibold text-[14px]" style={{ color: topColor || '#00B1B5' }}>
              Attachment
            </div>
            <hr />
            {content.attachments.map((attachment, a) => (
              <Fragment key={a}>
                {a > 0 && <hr className="my-2" />}
                <div className="flex items-center text-xs py-2">
                  <img src={attachment.thumbnail} className="w-20 h-20 mr-2" alt="file" />
                  <div>{attachment.fileName}</div>
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </div>

      <div className="flex justify-end mt-8 text-xs">
        <button
          type="button"
          className="py-2 px-6 border-2 font-semibold rounded-md"
          style={{ color: topColor || '#00B1B5', borderColor: topColor || '#00B1B5' }}
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  )
}
