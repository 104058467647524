import { ICONS, apiUrls } from '../../../../../constants'
import Select from 'react-select'
import { components } from 'react-select'
import { useEffect } from 'react'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { get } from '../../../../../api/base'
import { toast } from 'react-toastify'
import { Toast } from '../../../../../components'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { saveFilterForm } from '../../../../../redux/slices/formSlice'

export default function FilterFormModal({
  showModal,
  page,
  setShowModal,
  selectedFormOption,
  topColor,
  startDate,
  endDate,
  logicalFilter,
  populateLogical,
  micrositeName,
  menuId
}) {
  const [formOptions, setFormOptions] = useState([])
  const [fieldOptions, setFieldOptions] = useState([])
  const [minDate, setMinDate] = useState('')
  const [logical, setLogical] = useState([])
  const [addCriteriaPermission, setAddCriteriaPermission] = useState(false)
  const dispatch = useDispatch()
  const numericCondition = [
    {
      label: 'More Than',
      value: 'MORE'
    },
    { label: 'Less Than', value: 'LESS' }
  ]

  const dateCondition = [
    {
      label: 'After',
      value: 'AFTER'
    },
    { label: 'Before', value: 'BEFORE' }
  ]

  const logicOperatorOptions = [
    { label: 'AND', value: 'AND' },
    { label: 'OR', value: 'OR' }
  ]

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      selectedForms: null,
      startDate: null,
      endDate: null
    }
  })

  const close = () => {
    setShowModal(false)
    setValue('selectedForms', null)
    setValue('startDate', null)
    setValue('endDate', null)
    setAddCriteriaPermission(false)
    setLogical([])

    reset()
  }
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img src={ICONS.icArrowDownSite} className="w-3 mr-1" />
        </components.DropdownIndicator>
      )
    )
  }

  const populateForm = () => {
    const formOptions = page.forms?.map(form => ({
      label: form?.form?.title,
      value: form?.form?.id
    }))
    setFormOptions(formOptions)
    setLogical([...logicalFilter])
    if (formOptions.length === 1) {
      setValue('selectedForms', formOptions)
      const payloadLocal = {
        [menuId]: {
          selectedForms: formOptions,
          startDate,
          endDate,
          logical
        }
      }
      dispatch(saveFilterForm({ micrositeName, payloadLocal }))
    } else {
      setValue('selectedForms', selectedFormOption)
    }
    if (selectedFormOption.length === 1) {
      setAddCriteriaPermission(true)
    }
    setValue('startDate', startDate ? new Date(startDate) : null)
    setValue('endDate', endDate ? new Date(endDate) : null)
  }

  const populateFields = () => {
    let tempSelectedForms = getValues('selectedForms')
    if (tempSelectedForms?.length === 1) {
      setAddCriteriaPermission(true)
      get(`${apiUrls.FORMS_URL}/${tempSelectedForms[0]?.value}/fields`).then(res => {
        if (res.status === 200) {
          let temp = res.data.map(field => ({
            label: field.field_title,
            value: field.field_id,
            fieldType: field?.field_type,
            keyboardType: field?.keyboard_type
          }))
          setFieldOptions(temp)
        } else {
          toast.error(<Toast message="Error" detailedMessage={res.data.error.message} />)
        }
      })
    } else {
      setAddCriteriaPermission(false)
      setLogical([])
    }
  }

  useEffect(() => {
    if (showModal || page.forms?.length === 1) {
      populateForm()
    }
  }, [showModal, page])

  useEffect(() => {
    selectedFormOption.length === 1 && populateFields()
  }, [selectedFormOption])

  function valueValidation(logic, selectedForms) {
    let validation = true
    logic?.forEach(log => {
      if (log.includes('undefined') || !selectedForms.length) {
        validation = false
      }
    })

    return validation
  }

  function onSubmit({ selectedForms, startDate, endDate }) {
    if (valueValidation(populateLogical(logical), selectedForms)) {
      const payloadLocal = {
        [menuId]: {
          selectedForms,
          startDate,
          endDate,
          logical
        }
      }

      dispatch(saveFilterForm({ micrositeName, payloadLocal }))
      setShowModal(false)
    } else {
      toast.error(<Toast message="Error" detailedMessage={'All mandatory Field Cannot be empty'} />)
    }
  }

  function handleAddCriteria() {
    let tempLogical = logical
    tempLogical.push({
      operator: '',
      field: { fieldData: null, rules: { value: null, condition: null } }
    })
    setLogical([...tempLogical])
  }

  function handleDeleteCriteria(index) {
    let tempLogical = [...logical]
    tempLogical.splice(index, 1)
    setLogical([...tempLogical])
  }

  function populateFieldType(fieldType, keyboardType) {
    if (fieldType || keyboardType) {
      if (fieldType === 'TEXT_FIELD') {
        if (keyboardType === 'DEFAULT') {
          return 'default'
        } else {
          return 'numeric'
        }
      } else if (fieldType === 'DATE_PICKER') {
        return 'datePicker'
      } else {
        return 'default'
      }
    }
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div
          className="modal-box bg-white w-fit md:w-[760px] lg:w-[900px] h-fit flex flex-col"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row justify-between forms-center">
            <div className="font-semibold text-[16px]">Filter</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>
          <hr className="my-4" />
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col py-4 rounded-2xl">
            <div>
              <div className="font-poppins font-semibold text-[12px] mb-2">Forms</div>
              <Controller
                control={control}
                name="selectedForms"
                render={({ field: { onChange, value } }) => (
                  <Select
                    components={{ DropdownIndicator }}
                    defaultValue={selectedFormOption}
                    value={value}
                    onChange={selected => {
                      onChange(selected)
                      populateFields()
                    }}
                    options={formOptions}
                    name="selectedForms"
                    className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                    placeholder="Select forms"
                    isMulti={true}
                    styles={{
                      option: provided => ({
                        ...provided
                      }),
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: topColor },
                        ':active': { borderColor: topColor },
                        ':hover': { borderColor: topColor },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        color: '#C800A5',
                        ':hover': { color: topColor }
                      }),
                      indicatorSeparator: () => ({}),
                      valueContainer: provided => ({
                        ...provided
                      }),
                      multiValueLabel: provided => ({
                        ...provided,
                        color: '#fff',
                        fontFamily: 'poppins'
                      }),
                      multiValueRemove: provided => ({
                        ...provided,
                        color: '#fff'
                      }),
                      multiValue: provided => ({
                        ...provided,
                        backgroundColor: topColor,
                        borderRadius: 8
                      })
                    }}
                  />
                )}
              />
            </div>
            <div className="flex flex-row gap-4 w-full">
              <div className="flex-grow">
                <div className="font-poppins font-semibold text-[12px] mb-2">Submitted Date</div>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, value } }) => (
                    <div className="flex relative">
                      <DatePicker
                        id="planned_start"
                        format="dd-MMM-yyyy"
                        onChange={date => {
                          setMinDate(date)
                          onChange(date)
                        }}
                        dateFormat="dd-MMM-yyyy"
                        selected={value}
                        name="Planned start"
                        className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                        autoComplete="off"
                        placeholderText="Select Planned Start"
                      />
                      {value && (
                        <img
                          src={ICONS.icCloseModal}
                          className="w-5 absolute right-[40px] top-[10px] cursor-pointer"
                          onClick={() => setValue('startDate', null)}
                          alt={'close-modal'}
                        />
                      )}
                      <img
                        src={ICONS.icCalendar}
                        className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="mb-4 flex-grow">
                <div className="font-poppins font-semibold text-[12px] mb-2">To</div>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, value } }) => (
                    <div className="flex relative">
                      <DatePicker
                        id="end_date"
                        format="dd-MMM-yyyy"
                        minDate={minDate}
                        onChange={date => onChange(date)}
                        dateFormat="dd-MMM-yyyy"
                        selected={value}
                        name="End Date"
                        className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                        autoComplete="off"
                        placeholderText="Select End Date"
                      />

                      {value && (
                        <img
                          src={ICONS.icCloseModal}
                          className="w-5 absolute right-[40px] top-[10px] cursor-pointer"
                          onClick={() => setValue('endDate', null)}
                          alt={'close-modal'}
                        />
                      )}
                      <img
                        src={ICONS.icCalendar}
                        className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {logical.map((logic, index) => {
                let fieldType = logical[index]?.field?.fieldData?.fieldType?.value
                let keyboardType = logical[index]?.field?.fieldData?.keyboardType?.value
                return (
                  <div className="bg-[#F5F5F5] rounded-md p-4 flex flex-col" key={index}>
                    <img
                      className="self-end mr-[-25px] mt-[-20px]"
                      src={ICONS.icTrash}
                      width={28}
                      height={28}
                      onClick={() => handleDeleteCriteria(index)}
                    />
                    <div className="flex flex-row gap-2">
                      <div className="flex flex-col flex-grow gap-1">
                        <div className="font-semibold text-[12px] flex flex-row ">
                          Logic Operator<div className="text-red-500">*</div>
                        </div>
                        <Select
                          components={{ DropdownIndicator }}
                          defaultValue={selectedFormOption}
                          value={{ label: logic.operator, value: logic.operator }}
                          onChange={selected => {
                            logic.operator = selected.value
                            logical[index] = logic
                            setLogical([...logical])
                          }}
                          options={logicOperatorOptions}
                          name="selectedOperator"
                          className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                          placeholder="Select operator"
                          isMulti={false}
                          styles={{
                            option: provided => ({
                              ...provided
                            }),
                            control: provided => ({
                              ...provided,
                              ':focus': { borderColor: topColor },
                              ':active': { borderColor: topColor },
                              ':hover': { borderColor: topColor },
                              paddingLeft: 4,
                              boxShadow: 'none'
                            }),
                            dropdownIndicator: provided => ({
                              ...provided,
                              color: '#C800A5',
                              ':hover': { color: topColor }
                            }),
                            indicatorSeparator: () => ({})
                          }}
                        />
                      </div>
                      <div className="flex flex-col flex-grow gap-1">
                        <div className="font-semibold text-[12px] flex flex-row">
                          Field <div className="text-red-500">*</div>
                        </div>
                        <Select
                          components={{ DropdownIndicator }}
                          value={logic.field.fieldData}
                          onChange={selected => {
                            logic.field.fieldData = selected
                            logical[index] = logic
                            setLogical([...logical])
                          }}
                          options={fieldOptions}
                          name="selectedFields"
                          className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                          placeholder="Select fields"
                          isMulti={false}
                          styles={{
                            option: provided => ({
                              ...provided
                            }),
                            control: provided => ({
                              ...provided,
                              ':focus': { borderColor: topColor },
                              ':active': { borderColor: topColor },
                              ':hover': { borderColor: topColor },
                              paddingLeft: 4,
                              boxShadow: 'none'
                            }),
                            dropdownIndicator: provided => ({
                              ...provided,
                              color: '#C800A5',
                              ':hover': { color: topColor }
                            }),
                            indicatorSeparator: () => ({})
                          }}
                        />
                      </div>
                    </div>
                    {logical[index].field.fieldData !== null && (
                      <div className="flex flex-row gap-2">
                        {populateFieldType(fieldType, keyboardType) !== 'default' && (
                          <div className="flex flex-col flex-grow gap-1">
                            <div className="font-semibold text-[12px] flex flex-row">
                              Condition<div className="text-red-500">*</div>
                            </div>
                            <Select
                              components={{ DropdownIndicator }}
                              defaultValue={selectedFormOption}
                              value={logic.field.rules?.condition}
                              onChange={selected => {
                                logic.field.rules.condition = selected
                                logical[index] = logic
                                setLogical([...logical])
                              }}
                              options={
                                populateFieldType(fieldType, keyboardType) === 'numeric'
                                  ? numericCondition
                                  : dateCondition
                              }
                              name="selectedForms"
                              className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                              placeholder="Select forms"
                              isMulti={false}
                              styles={{
                                option: provided => ({
                                  ...provided
                                }),
                                control: provided => ({
                                  ...provided,
                                  ':focus': { borderColor: topColor },
                                  ':active': { borderColor: topColor },
                                  ':hover': { borderColor: topColor },
                                  paddingLeft: 4,
                                  boxShadow: 'none'
                                }),
                                dropdownIndicator: provided => ({
                                  ...provided,
                                  color: '#C800A5',
                                  ':hover': { color: topColor }
                                }),
                                indicatorSeparator: () => ({})
                              }}
                            />
                          </div>
                        )}
                        <div className="flex flex-grow flex-col gap-1">
                          <div className="font-poppins font-semibold text-[12px] flex flex-row">
                            Value <div className="text-red-500">*</div>
                          </div>
                          <div className="flex w-full font-poppins ">
                            {populateFieldType(fieldType, keyboardType) === 'datePicker' ? (
                              <div className="flex relative">
                                <DatePicker
                                  id="planned_start"
                                  onChange={date => {
                                    logic.field.rules.value = {
                                      payload: moment(date).format(),
                                      displayValue: date
                                    }
                                    logical[index] = logic
                                    setLogical([...logical])
                                  }}
                                  selected={logic.field.rules.value?.displayValue}
                                  timeInputLabel="Time:"
                                  showTimeInput
                                  name="Date"
                                  className={`input-text text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                                  autoComplete="off"
                                  placeholderText="Select Value"
                                />
                                <img
                                  src={ICONS.icCalendar}
                                  className="w-4 absolute right-[16px] top-[12px] pointer-events-none"
                                />
                              </div>
                            ) : (
                              <input
                                className="focus:outline-none w-full text-[12px] p-2 rounded-lg"
                                placeholder="Enter Value..."
                                defaultValue={logic.field?.rules?.value}
                                onChange={e => {
                                  logic.field.rules.value = e.target.value
                                  logical[index] = logic
                                  setLogical([...logical])
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <div
              className={`flex flex-row items-center gap-2 mt-4 ${
                addCriteriaPermission ? 'cursor-pointer' : 'opacity-25'
              } `}
              onClick={addCriteriaPermission ? () => handleAddCriteria() : null}
            >
              <img
                className="rounded-full"
                style={{ border: `6px solid ${topColor}` }}
                src={ICONS.icAddWhite}
                width={32}
                height={32}
              />
              <div className="text-[12px]" style={{ color: topColor }}>
                Add Filter Criteria
              </div>
            </div>
            <div className="flex justify-end gap-4 lg:col-span-3 mt-20">
              <button
                type="button"
                onClick={() => close()}
                className="py-3 px-8 text-[12px] font-semibold rounded-md"
                style={{ border: `1px solid ${topColor}`, color: topColor }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="py-3 px-8 text-white text-[12px] font-semibold rounded-md"
                style={{ background: topColor }}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
