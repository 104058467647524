import { useContext } from 'react'
import Field from './Field'
import { PageContext } from '../../..'

export default function Section({ sectionData }) {
  const { topColor } = useContext(PageContext)
  const fields = sectionData.fields
  const isMultiple = sectionData.multiple_input
  const subSections = sectionData.sub_sections
  const innerSections = sectionData.inner_sections

  return (
    <div className="flex flex-col p-2 md:p-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-[14px]" style={{ color: topColor || '#00B1B5' }}>
          {sectionData.section_title}
        </div>
      </div>
      <hr className="mt-2 mb-4" />
      {/* !MULTI */}
      <div className="flex flex-col gap-4">
        {fields.map((field, fieldIndex) =>
          field.additional_option.length > 0 ? (
            <div key={fieldIndex}>
              <div className="font-semibold text-[14px] mb-2">{field.field_title}</div>
              {field.additional_option.map((add, additionalIndex) => (
                <div className="mb-2" key={additionalIndex}>
                  <Field
                    name={`${sectionData.section_id}_${additionalIndex}_${field.field_id}`}
                    fieldData={field}
                    value={add.value}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Field
              key={fieldIndex}
              name={`${sectionData.section_id}_${field.field_id}`}
              fieldData={field}
              value={field.value}
            />
          )
        )}
      </div>
      {/* MULTI */}
      {isMultiple && (
        <div className="flex flex-col gap-4">
          {innerSections?.map((fields, innerIndex) => (
            <div className="flex flex-col gap-4 bg-white rounded-xl p-2 md:p-4" key={innerIndex}>
              {fields.map((field, fieldIndex) =>
                field.additional_option.length > 0 ? (
                  <div key={fieldIndex}>
                    <div className="font-semibold text-[14px] mb-2">{field.field_title}</div>
                    {field.additional_option.map((add, additionalIndex) => (
                      <div className="mb-2" key={additionalIndex}>
                        <Field
                          name={`${sectionData.section_id}_${innerIndex}_${additionalIndex}_${field.field_id}`}
                          fieldData={field}
                          value={add.value}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <Field
                    key={fieldIndex}
                    name={`${sectionData.section_id}_${innerIndex}_${field.field_id}`}
                    fieldData={field}
                    value={field.value}
                  />
                )
              )}
            </div>
          ))}
        </div>
      )}
      {subSections?.map((section, index) => (
        <div key={index}>
          <hr className="my-6" />
          <div className="bg-white rounded-xl">
            <Section sectionData={section} />
          </div>
        </div>
      ))}
    </div>
  )
}
