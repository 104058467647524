import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { Toast, SuccessPopup, DropdownIndicator } from '../../../components'
import { post, get } from '../../../api/base'
import { ICONS, apiUrls } from '../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../redux/slices/popupSlice'

export default function UserGroupModal({ showModal, setShowModal, on_success }) {
  const params = useParams()
  const dispatch = useDispatch()
  const [groupOptions, setUserGroupOptions] = useState([])
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      groups: []
    }
  })
  const handleCloseModal = () => {
    setValue('groups', [])
    setShowModal(false)
  }

  const fetchGroups = async () => {
    const { status, data } = await get(`${apiUrls.GROUPS_URL}`)

    if (status === 200) {
      const groupOptions = data.data
      const newGroupOptions = []

      groupOptions.map(v => {
        newGroupOptions.push({
          ...v,
          label: v.name,
          value: v.id
        })
        return true
      })

      setUserGroupOptions(newGroupOptions)
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
    }
  }

  async function onSubmit({ groups }) {
    if (groups.length > 0) {
      const payload = {
        group_ids: []
      }
      if (groups) {
        groups.map(v => {
          payload.group_ids.push(v.value)
          return true
        })
      }

      const { ok, data } = await post(
        `${apiUrls.MICROSITES_ROUTE}/${params.microsite_route}/groups`,
        payload
      )
      if (ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully add user group'))
        handleCloseModal()
        on_success()
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      }
    } else {
      toast.error(<Toast message={`Error`} detailedMessage={'Group is required'} />)
    }
  }

  useEffect(() => {
    setValue('groups', [])
    fetchGroups()
  }, [showModal])

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white w-fit md:w-[780px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">
              Add User Group Assignment
            </div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={handleCloseModal}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col py-4 rounded-2xl">
            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">Group</div>
              <Controller
                control={control}
                name="groups"
                render={({ field: { onChange, value } }) => (
                  <Select
                    components={{ DropdownIndicator }}
                    defaultValue={value}
                    onChange={selected => onChange(selected)}
                    options={groupOptions}
                    className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                    placeholder="Select parent"
                    isClearable={true}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    styles={{
                      option: (provided, state) => ({
                        ...provided
                      }),
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({}),
                      valueContainer: provided => ({
                        ...provided
                      }),
                      multiValueLabel: provided => ({
                        ...provided,
                        color: '#fff',
                        fontFamily: 'poppins'
                      }),
                      multiValueRemove: provided => ({
                        ...provided,
                        color: '#fff'
                      }),
                      multiValue: provided => ({
                        ...provided,
                        backgroundColor: '#6546C3',
                        borderRadius: 8
                      })
                    }}
                  />
                )}
              />
            </div>
            <br></br>
            <br></br>
            <div className="flex justify-end gap-4 lg:col-span-3">
              <button
                type="button"
                onClick={() => handleCloseModal()}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Cancel
              </button>
              <button className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md">
                Save
              </button>
            </div>
          </form>
          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
