import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageContext } from '../../../..'
import { useSelector } from 'react-redux'
import { selectSites } from '../../../../../../redux/slices/micrositeSlice'

export default function StaticField({ title, value, height, fieldId }) {
  const { topColor } = useContext(PageContext)
  const navigate = useNavigate()
  const params = useParams()
  const site = useSelector(selectSites).find(
    s => s.route_name.toLowerCase() === params.microsite.toLowerCase()
  )
  const currentMenu = site.menus.find(m => m.route_name === params.menu)
  const handleFullscreenClick = () => {
    let detailRoute = title.toUpperCase().replace(/ /g, '_')
    navigate(detailRoute, {
      state: {
        title: title,
        crumbs: [
          currentMenu?.name,
          currentMenu.children.find(sm => sm.route_name === params.subMenu)?.name,
          title
        ],
        content: value
      }
    })
  }
  return (
    <div className="flex flex-1 flex-col ">
      <div className="flex flex-row items-center mb-2">
        <div className="font-semibold">{title}</div>
        <div
          className="flex flex-row items-center ml-auto cursor-pointer"
          style={{ color: topColor || '#00B1B5' }}
          onClick={handleFullscreenClick}
        >
          <div className="font-semibold text-[12px]">See Detail</div>
          <div className="text-[18px] ml-[20px]">{'>'}</div>
        </div>
      </div>

      <div
        id={`static-field-${fieldId}`}
        className="overflow-hidden overflow-y-auto bg-white"
        style={{ height: parseInt(height) || 300 }}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </div>
  )
}
