import { ICONS } from '../../../constants'

const MessageModal = ({ message, visibility, onCloseClick }) => {
  return (
    <div
      className={`${visibility} bg-[#FF647C] font-poppins font-semibold text-[12px] text-white p-3 w-full rounded-md flex`}
    >
      <div className="flex-1">{message}</div>
      <img
        src={ICONS.icCloseRound}
        alt="close"
        className="w-3 h-full self-center cursor-pointer mr-[8px]"
        onClick={onCloseClick}
      />
    </div>
  )
}

export default MessageModal
