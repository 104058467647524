import { useController, useFormContext } from 'react-hook-form'

import { Select } from '../../../../../components'
import Attachment from './Field/Attachment'
import MasterData from './Field/MasterData'
import Signature from './Field/Signature'
import DatePicker from './Field/DatePicker'
import StaticField from './Field/StaticField'

export default function Field({ path, fieldData, siblings, disabled }) {
  return (
    <div className="flex flex-1 flex-col gap-2 text-xs">
      {fieldData.field_type.value !== 'STATIC_FIELD' && (
        <div className="font-semibold mb-2">{fieldData.field_title}</div>
      )}
      {fieldData.additional_option.length > 0 ? (
        fieldData.additional_option.map((add, additionalIndex) => (
          <div className="mb-2" key={additionalIndex}>
            <div className="font-semibold mb-2">{add.title}</div>
            <Render
              field={fieldData}
              path={`${path}.additional_option.${additionalIndex}`}
              siblings={siblings}
              disabled={disabled}
            />
          </div>
        ))
      ) : (
        <Render field={fieldData} path={path} siblings={siblings} disabled={disabled} />
      )}
    </div>
  )
}

function Render({ field, path, siblings, disabled }) {
  const { control } = useFormContext()
  const {
    field: { value, onChange }
  } = useController({
    name: `${path}.value`,
    control
  })

  const fieldType = field.field_type.value
  const fieldTitle = field.field_title
  const fieldOptions = field.options

  switch (fieldType) {
    case 'TEXT_FIELD':
      return (
        <input
          type={field.keyboard_type.value === 'NUMERIC' ? 'number' : 'text'}
          onWheel={event => {
            field.keyboard_type.value === 'NUMERIC' ? event.currentTarget.blur() : null
          }}
          className={`text-[12px] px-[16px] py-[12px] h-[40px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3]`}
          placeholder={`Enter ${fieldTitle}`}
          value={value || ''}
          onChange={onChange}
          disabled={disabled}
        />
      )

    case 'TEXT_AREA':
      return (
        <textarea
          className="border rounded-lg p-2 text-xs"
          rows={3}
          value={value || ''}
          onChange={onChange}
          disabled={disabled}
        />
      )

    case 'CHECKBOX':
      return (
        <div className="flex flex-col gap-1">
          {fieldOptions.map((option, o) => (
            <label key={o} className="flex items-center gap-2">
              <input
                type="checkbox"
                name={path}
                checked={Array.isArray(value) ? value.includes(option.label) : false}
                value={option.label}
                onChange={event => {
                  let values = Array.isArray(value) ? [...value] : []
                  if (event.target.checked) {
                    values = [...values, event.target.value]
                  } else {
                    values.splice(values.indexOf(event.target.value), 1)
                  }
                  onChange(values)
                }}
                disabled={disabled}
              />
              <div className="text-[12px]">{option.label}</div>
            </label>
          ))}
        </div>
      )

    case 'RADIO_BUTTON':
      return (
        <div className="flex flex-col gap-1">
          {fieldOptions.map((option, o) => (
            <label key={o} className="flex items-center gap-2">
              <input
                type="radio"
                name={path}
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                disabled={disabled}
              />
              <div className="text-[12px]">{option.label}</div>
            </label>
          ))}
        </div>
      )

    case 'DROPDOWN':
      return (
        <Select
          isSearchable={false}
          options={field.options}
          name={fieldTitle}
          placeholder={`Select ${fieldTitle}`}
          value={value}
          onChange={onChange}
          isDisabled={disabled}
        />
      )

    case 'DATE_PICKER':
      return (
        <DatePicker
          label={fieldTitle}
          value={value}
          onChange={onChange}
          format={field.format || undefined}
          disabled={disabled}
        />
      )

    case 'SIGNATURE':
      return <Signature field={field} value={value} onChange={onChange} disabled={disabled} />

    case 'CHECKBOX_ATTACHMENT':
      return (
        <Attachment
          path={path}
          field={field}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )

    case 'RADIO_BUTTON_ATTACHMENT':
      return (
        <Attachment
          path={path}
          field={field}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )

    case 'DROPDOWN_MASTER_DATA':
      return (
        <MasterData
          field={field}
          value={value}
          onChange={onChange}
          siblings={siblings}
          disabled={disabled}
        />
      )

    case 'STATIC_FIELD':
      return (
        <StaticField
          title={field.field_title}
          value={field?.description}
          height={field?.height}
          fieldId={field.field_id}
        />
      )

    default:
      return <></>
  }
}
