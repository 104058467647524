import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/css/tailwind.css'
import './tailwind.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'
import persistStore from 'redux-persist/es/persistStore'
import ModalDetectDevice from './components/DetectDevice'

import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'

let persistor = persistStore(store)

export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ModalDetectDevice />
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </MsalProvider>
  // </React.StrictMode>
)
