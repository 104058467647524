import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ICONS, apiUrls } from '../../../../constants'
import { Toast } from '../../../../components'
import { get } from '../../../../api/base'
import SortingFieldsModal from './components/SortingFieldsModal'

export default function TypeForm({ isEdit, micrositeId }) {
  const { watch } = useFormContext()
  const selectedForms = watch('forms')

  function handleOnChangeCheckForm() {}

  return (
    <div>
      {isEdit ? (
        <div className="mt-5">
          <FormList
            onChangeCheckForm={(id, event) => handleOnChangeCheckForm(id, event)}
            micrositeId={micrositeId}
          />
        </div>
      ) : (
        <div>
          {selectedForms.map((data, index) => {
            return (
              <div key={index} className="font-poppins text-xs my-4">
                {data.form?.title}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

function FormList({ micrositeId }) {
  const [searchFormQuery, setSearchFormQuery] = useState('')
  const [showSortingModal, setShowSortingModal] = useState(false)
  const [formList, setFormList] = useState([])
  const { setValue, watch } = useFormContext()
  const forms = watch('forms')
  const totalFormChecked = forms?.length

  const fetchFormList = useCallback(async () => {
    let query = {
      order_by: 'desc'
    }

    if (searchFormQuery !== '') query.query.search = searchFormQuery

    const { status, data } = await get(apiUrls.FORMS_URL, { query })

    if (status === 200) {
      if (searchFormQuery === '') setFormList(data.forms)
    } else {
      toast.error(<Toast message="Error" detailedMessage="Failed to fetch forms." />)
    }
  }, [searchFormQuery])

  useEffect(() => {
    fetchFormList()
  }, [])

  function onformCheck(checked, form) {
    if (checked) {
      form.form = {
        id: form.id
      }
    }
    setValue('forms', checked ? [...forms, form] : forms.filter(f => f.form.id !== form.id))
  }

  return (
    <div className="flex-row rounded-2xl border-[1px] border-[#DEDEDE] overflow-hidden">
      <div className="flex justify-between items-center px-4 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]">
        <div className="flex-auto font-poppins font-semibold text-[12px] text-white py-[14px]">
          Available Form
        </div>
        {totalFormChecked !== 0 && (
          <div className="text-center rounded-[15px] bg-white font-poppins text-[#C800A5] text-[10px] px-3 py-[7px] mt-2 mb-2">
            {totalFormChecked} Form Selected
          </div>
        )}
        <div
          className={`bordered justify-center items-center p-2 border border-white rounded-lg ml-2 mr-4 cursor-pointer ${
            totalFormChecked === 0 ? 'opacity-25' : ''
          }`}
          onClick={() => setShowSortingModal(true)}
        >
          <img src={ICONS.icFilter} width={18} height={18} />
        </div>
      </div>
      <div className="flex-row px-4 py-3">
        <GlobalFilter filter={searchFormQuery} setFilter={setSearchFormQuery} />
        <div className="flex-row h-[200px] overflow-y-auto scrollbar">
          {formList
            .filter(form => {
              return form.title.toLowerCase().match(new RegExp(searchFormQuery.toLowerCase(), ''))
            })
            .map(form => (
              <div key={form.id} className="flex items-center justify-between mb-4">
                <div className="font-poppins text-xs">{form.title}</div>
                <input
                  type="checkbox"
                  className="w-4 h-4 accent-[#6546C3] bg-gray-100 rounded-3xl border-gray-300"
                  checked={forms.some(f => f.form.id === form.id)}
                  onChange={({ target: { checked } }) => onformCheck(checked, form)}
                />
              </div>
            ))}
        </div>
      </div>
      <SortingFieldsModal
        showModal={showSortingModal}
        setShowModal={setShowSortingModal}
        forms={forms}
        micrositeId={micrositeId}
      />
    </div>
  )
}

function GlobalFilter({ filter, setFilter }) {
  return (
    <div className={`flex items-center relative mb-4 overflow-hidden`}>
      <input
        id="search-form"
        type="text"
        placeholder="Search here"
        className="flex-1 text-[12px] px-[16px] py-[12px] pl-9 outline-none border rounded-lg w-full focus:outline-none focus:border-[#6546C3]"
        value={filter}
        onChange={event => {
          setFilter(event.target.value)
        }}
      />
      <img src={ICONS.icSearch} className="w-[14px] h-[14px] absolute left-[13px]" alt="search" />
    </div>
  )
}
