import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useParams } from 'react-router-dom'

import { get } from '../../api/base'
import { apiUrls } from '../../constants'
import { logoutMicrosite, selectMicrositeTokens } from '../../redux/slices/authSlice'
import { addSite, removeSite, selectSites } from '../../redux/slices/micrositeSlice'

export default function RequireMicrositeAuthentication() {
  const { microsite, menu, subMenu } = useParams()
  const dispatch = useDispatch()
  const micrositeTokens = useSelector(selectMicrositeTokens)
  const site = useSelector(selectSites).find(
    s => s.route_name.toLowerCase() === microsite.toLowerCase()
  )
  const currentMenu = site.menus.find(m => m.route_name === menu)
  const [loaded, setLoaded] = useState(false)

  const refetchMicrosite = useCallback(async () => {
    const { ok, data, status } = await get(`${apiUrls.MICROSITES_ROUTE}/${microsite}/segregate`, {
      headers: {
        Authorization: `Bearer ${micrositeTokens[microsite.toLowerCase()]}`
      }
    })

    if (!ok && status === 401) {
      dispatch(logoutMicrosite())
      return
    }

    if (ok && !data.error) dispatch(addSite(data))
    else dispatch(removeSite(microsite))
    setLoaded(true)
  }, [dispatch, microsite])

  useEffect(() => {
    refetchMicrosite()
  }, [])

  if (!micrositeTokens[microsite.toLowerCase()]) {
    return <Navigate to={`/Site/${microsite}/Login`} />
  }

  if (!loaded) {
    return (
      <div className="flex h-screen items-center justify-center">Initializing site data...</div>
    )
  }

  if (menu && !currentMenu) {
    return <Navigate to={`/Site/${microsite}`} replace />
  }

  if (subMenu && !currentMenu.children.find(sm => sm.route_name === subMenu)) {
    return <Navigate to={`/Site/${microsite}/${menu}`} replace />
  }

  return <Outlet />
}
