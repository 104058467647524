import { useState } from 'react'
import { ICONS, apiUrls } from '../../../../../constants'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { get, post } from '../../../../../api/base'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading'
import { Toast } from '../../../../../components'

const SortingFieldsModal = ({ forms, showModal, setShowModal, micrositeId }) => {
  // disable all react-beautiful-dnd development warnings
  window['__react-beautiful-dnd-disable-dev-warnings'] = true

  const close = () => setShowModal(false)
  const [selectedForm, setselectedForm] = useState('')
  const [fields, setFields] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  const populateField = () => {
    setShowLoading(true)
    get(`${apiUrls.FORMS_URL}/${selectedForm}/microsite/${micrositeId}/fields`).then(res => {
      setShowLoading(false)
      if (res.status === 200) {
        setFields(res.data)
      } else {
        toast.error(<Toast message="Error" detailedMessage={res.data.error.message} />)
      }
    })
  }

  useEffect(() => {
    if (forms.length) {
      if (forms[0].form) {
        setselectedForm(forms[0].form.id)
      } else {
        setselectedForm(forms[0].id)
      }
    }
  }, [forms])

  useEffect(() => {
    if (selectedForm && showModal) populateField()
  }, [selectedForm, showModal])

  const onDragEnd = result => {
    if (!result.destination) return

    const removed = fields.splice(result.source.index, 1)
    fields.splice(result.destination.index, 0, ...removed)
    post(`${apiUrls.FORMS_URL}/microsite/sorting-fields`, {
      form_id: selectedForm,
      microsite_id: micrositeId,
      fields: fields
    }).then(res => {
      if (res.status === 201) {
        toast.success(<Toast message="Success" detailedMessage="Menu list updated" />)
      } else {
        toast.error(<Toast message="Error" detailedMessage="Failed updating field list" />)
      }
    })
  }

  const portal = document.createElement('div')
  document.body.appendChild(portal)

  function FieldCard({ provided, snapshot, field }) {
    const usePortal = snapshot.isDragging

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={`item ${usePortal ? 'is-dragging' : ''}`}
      >
        <div className="flex flex-row mb-2 !top-0">
          <div className="bg-[#E7E2F6] p-2 rounded-l-lg ">
            <img src={ICONS.icDrag} width={24} height={24} />
          </div>
          <div className="p-2 flex-grow bg-white rounded-r-lg">{field.field_title}</div>
        </div>
      </div>
    )
  }

  const isSelected = form => {
    return selectedForm === form?.id || selectedForm === form?.form?.id
  }

  return (
    <>
      <input
        type="checkbox"
        id="menu-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div
          className="modal-box bg-white max-w-full w-fit md:w-[760px] lg:w-[900px] h-fit flex flex-col"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-row justify-between forms-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">Form Sort Order</div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={close}
              alt={'close-modal'}
            />
          </div>
          <hr className="my-4" />
          <div className="flex flex-row gap-2">
            <div className="flex-grow flex-col py-4 px-6 w-full ">
              <div className="text-[12px] font-semibold">Form Name</div>
              {forms?.map((form, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      isSelected(form)
                        ? 'bg-gradient-to-r from-[#6546C3] to-[#9E7CE3]'
                        : 'bg-[#F2F5FC]'
                    } w-full mx-[4px] rounded-md my-2 flex forms-center justify-between cursor-pointer p-2 text-sm`}
                    onClick={() => setselectedForm(form?.form?.id || form.id)}
                  >
                    <div
                      className={`${
                        isSelected(form) ? 'bg-white' : 'bg-[#6546C3]'
                      } self-stretch w-[5px] rounded-[3px] mr-3`}
                    />

                    <span
                      className={`${
                        isSelected(form) ? 'text-white font-semibold' : 'text-black'
                      } pl-[2px] grow text-[12px]`}
                    >
                      {form.title || form?.form?.title}
                    </span>
                  </div>
                )
              })}
            </div>
            <div className="flex-grow flex-col bg-[#F2F5FC] py-7 px-6 w-full">
              {showLoading ? (
                <ReactLoading type="spin" color="#6546C3" />
              ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="parents"
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => (
                      <FieldCard
                        field={fields[rubric.source.index]}
                        provided={provided}
                        snapshot={snapshot}
                      />
                    )}
                  >
                    {provided => (
                      <div
                        className="flex flex-col w-[100%]"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {fields?.map((field, fieldIndex) => {
                          return (
                            <Draggable
                              key={fieldIndex}
                              draggableId={`${fieldIndex}`}
                              index={fieldIndex}
                            >
                              {(provided, snapshot) => (
                                <FieldCard field={field} provided={provided} snapshot={snapshot} />
                              )}
                            </Draggable>
                          )
                        })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SortingFieldsModal
