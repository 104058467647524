const Toast = ({ message, detailedMessage }) => {
  return (
    <div className="font-poppins text-white">
      <div className="font-semibold text-[14px]">{message}</div>
      <div className="text-[12px]">{detailedMessage}</div>
    </div>
  )
}

export default Toast
