import { useRef } from 'react'

import { ICONS } from '../../constants'

export default function ScrollToBottom() {
  const anchorRef = useRef(null)

  function scrollToBottom() {
    anchorRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="mt-4">
      <button
        type="button"
        className="fixed bottom-4 right-4 rounded-lg w-8 h-8 flex items-center justify-center bg-white border-purple-600 border-2 opacity-75"
        onClick={scrollToBottom}
      >
        <img className="w-4" src={ICONS.icArrowDown} />
      </button>

      <div ref={anchorRef} />
    </div>
  )
}
