import { apiUrls, ICONS } from '../../../constants'
import { get, post } from '../../../api/base'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import SubmissionTable from './components/SubmissionTable'
import Section from './components/SubmissionTable/components/Section'
import { toast } from 'react-toastify'
import { Toast } from '../../../components'

const DetailFormPage = () => {
  const { id } = useParams()
  const [detailMode, setDetailMode] = useState('view')
  const [form, setForm] = useState(null)
  const showPreview = detailMode === 'view' ? true : false
  const showResponse = detailMode === 'response' ? true : false

  useEffect(() => {
    fetchDetailForm()
  }, [])

  const fetchDetailForm = () => {
    get(`${apiUrls.FORMS_URL}/${id}`).then(response => {
      const { status } = response
      if (status === 200) {
        setForm(response.data)
      } else if (status === 408) {
        toast.error(<Toast message={`Error`} detailedMessage={`${response.data.error.message}`} />)
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={'Failed to get data'} />)
      }
    })
  }

  const renderFormPreview = () => {
    return (
      <div className="flex flex-1 gap-6">
        <div className="flex flex-col flex-1 bg-white rounded-2xl p-6 gap-3">
          <div className="font-semibold text-[16px]">Form Info</div>
          <hr />
          {/* Dates */}
          <div className="flex items-center justify-between text-[12px] gap-2">
            <div>
              Created <span className="font-semibold text-[#6546C3]">{form?.created_at}</span>
            </div>
            <div>
              Last Submission{' '}
              <span className="font-semibold text-[#C800A5]">{form?.last_submission || '-'}</span>
            </div>
          </div>

          <div className="my-2">
            <div className="font-semibold text-xs mb-1">Tags</div>
            <div className="flex flex-wrap text-xs">
              {!form?.tags?.length && <div>-</div>}
              {form?.tags?.map((tag, t) => (
                <div
                  className="flex items-center rounded bg-purple-700 text-white my-2 mr-2 p-2"
                  key={t}
                >
                  {tag}
                </div>
              ))}
            </div>
          </div>

          {/* Form preview*/}
          <div className="font-semibold text-[12px]">Preview Form {form?.title}</div>
          {form?.sections.map(section => (
            <Section key={section.section_id} sectionData={section} />
          ))}
          {form?.attachment ? (
            <div className="flex flex-col gap-2">
              <div className="font-semibold text-[14px] text-[#C800A5]">Attachment</div>
              <hr />
              <div className="flex items-center gap-2 bg-[#EBEDF8] rounded-xl p-2 border-dashed border-[1px] border-[#6546C3]">
                <button className="px-4 py-3 text-[12px] rounded-lg bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white font-semibold">
                  Select Files...
                </button>
                <div className="flex-1 text-center text-[12px] text-[#6546C3]">
                  Drag file here to upload
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col flex-1 bg-white rounded-2xl p-6 max-w-[250px]">
          <div className="font-semibold text-[16px] text-[#C800A5] mb-[27px]">
            Assignee{' '}
            <span className="font-normal text-[#3D3D3D]">{`(${
              form?.users.length + form?.groups.length
            })`}</span>
          </div>
          <div className="flex flex-col gap-4 mb-4">
            {form?.users.map(user => (
              <div key={user.id} className="flex items-center gap-3">
                <img
                  src={user.picture ? user.picture : ICONS.icAdminActive}
                  className="w-6 h-6 rounded-full"
                />
                <div className="text-[#232323] text-[12px]">{user.full_name}</div>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-4">
            {form?.groups.map(group => (
              <div key={group.id} className="flex items-center gap-3">
                <img
                  src={group.picture ? group.picture : ICONS.icGroup}
                  className="w-6 h-6 rounded-full"
                />
                <div className="text-[#232323] text-[12px]">{group.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const renderNavButton = (label, isActive, onNavButtonClick) => {
    if (isActive) {
      return (
        <div
          onClick={() => onNavButtonClick(label.toLowerCase())}
          className="cursor-pointer flex items-center rounded-lg bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] gap-3"
        >
          <div className="w-1 h-[27px] bg-white m-2 rounded-sm"></div>
          <div className="font-semibold text-[12px] text-white">{label}</div>
        </div>
      )
    } else {
      return (
        <div
          onClick={() => onNavButtonClick(label.toLowerCase())}
          className="cursor-pointer flex items-center rounded-lg bg-[#F2F5FC] gap-3"
        >
          <div className="w-1 h-[27px] bg-[#6546C3] m-2 rounded-sm"></div>
          <div className="text-[12px] text-[#232323]">{label}</div>
        </div>
      )
    }
  }

  const handleOnNavButtonClick = mode => {
    setDetailMode(mode)
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-6 px-6">
      <div className="bg-white p-6 rounded-2xl flex flex-1 flex-col gap-3 mb-2 lg:mb-0">
        <div className="font-semibold text-[16px]">List Detail</div>
        <hr />
        {renderNavButton('View', showPreview, handleOnNavButtonClick)}
        {renderNavButton('Response', showResponse, handleOnNavButtonClick)}
      </div>
      <div className="col-span-3">
        {showPreview ? renderFormPreview() : <></>}
        {showResponse ? <SubmissionTable id={id} users={form?.users} title={form?.title} /> : <></>}
      </div>
    </div>
  )
}

export default DetailFormPage
