import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { Toast, Toggle, SuccessPopup, DropdownIndicator } from '../../../components'
import { post, put, get } from '../../../api/base'
import { ICONS, apiUrls, images } from '../../../constants'
import { setShowPopupSuccess, setMessage } from '../../../redux/slices/popupSlice'

export default function DataContentModal({
  showModal,
  setShowModal,
  isEdit,
  setIsEdit,
  masterData,
  form,
  onSuccess
}) {
  const dispatch = useDispatch()
  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      is_active: false,
      alias_name: null,
      name: null,
      parents: []
    }
  })

  const [parentList, setParentList] = useState([])
  const [tempParentList, setTempParentList] = useState([])

  const handleCloseModal = () => {
    setIsEdit(false)
    setShowModal(false)
  }

  const fetchDataContent = async () => {
    let listParent = []
    if (masterData.parent) {
      const { ok, data } = await get(`${apiUrls.MASTER_DATA}/${masterData.parent.id}/details`)
      if (ok) {
        listParent = data
      }
    }

    //merge list parent from parent master data with data content in this master data
    if (isEdit) {
      //checking if edited data content has not include in list parent
      const listDataContent = []
      if (masterData.data) {
        masterData.data.map(v => {
          if (v.id !== form.data_content_id) listDataContent.push(v)
        })
      }
      const newListParent = listParent.concat(listDataContent)
      populateParentList(newListParent)
    } else {
      const newListParent = listParent.concat(masterData.data)
      populateParentList(newListParent)
    }
  }

  function populateParentList(data) {
    const newParentList = []
    data.map(v => {
      newParentList.push({ ...v, label: v.name, value: v.id })
    })
    setParentList(newParentList)
  }

  async function onSubmit({ name, is_active, alias_name, parents }) {
    const payload = {
      name: name,
      is_active: is_active,
      alias_name: alias_name,
      parent_ids: parents.map(v => v.id)
    }

    if (isEdit) {
      const { ok, data } = await put(
        `${apiUrls.MASTER_DATA}/${masterData.id}/details/${form.data_content_id}`,
        payload
      )
      if (ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully update data content'))
        handleCloseModal()
        onSuccess()
        reset()
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      }
    } else {
      const { ok, data } = await post(`${apiUrls.MASTER_DATA}/${masterData.id}/details`, payload)
      if (ok) {
        dispatch(setShowPopupSuccess(true))
        dispatch(setMessage('You have successfully add data content'))
        handleCloseModal()
        onSuccess()
        reset()
      } else {
        toast.error(<Toast message={`Error`} detailedMessage={`${data.error.message}`} />)
      }
    }
  }

  useEffect(() => {
    fetchDataContent()
    if (isEdit) {
      setValue('name', form.name)
      setValue('is_active', form.is_active)
      setValue('alias_name', form.alias_name)
      setValue('parents', form.parents)
    } else {
      setValue('name', null)
      setValue('is_active', false)
      setValue('alias_name', null)
      setValue('parents', [])
    }
  }, [showModal])

  return (
    <>
      <input
        type="checkbox"
        id="master-data-modal"
        checked={showModal}
        onChange={e => e.preventDefault()}
        className="modal-toggle"
      />
      <div className="modal bg-[#23232350]">
        <div className="modal-box bg-white w-fit md:w-[780px] h-fit">
          {/* Header */}
          <div className="flex flex-row justify-between items-center">
            <div className="font-semibold text-[16px] text-[#C800A5]">
              {isEdit ? 'Edit Data Content' : 'Add Data Content'}
            </div>
            <img
              src={ICONS.icCloseModal}
              className="w-6 h-6 cursor-pointer"
              onClick={handleCloseModal}
              alt={'close-modal'}
            />
          </div>

          <hr className="my-4" />

          <div className=" flex border rounded-xl w-full p-3 font-poppins text-sm">
            <span className="mt-[6px]">Master Data:</span>
            <div className=" mx-2 flex justify-center items-center bg-[#F0EEF6] rounded-lg p-2">
              <span className="text-[#6546C3] font-semibold text-xs">
                <div>{masterData.parent ? masterData.parent.name : masterData.name}</div>
              </span>
            </div>
            {masterData.parent && masterData.parent.name ? (
              <div className="mt-[6px]">
                <span className="text-[#C800A5] font-poppins">/</span>
                <span className="font-poppins mx-2">{masterData.name}</span>
              </div>
            ) : (
              <></>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col py-4 rounded-2xl">
            <div className="flex justify-end">
              <div className="grow mr-4">
                <div className="font-semibold text-[12px] mb-2">Full Name</div>
                <input
                  className={`${
                    errors.name ? 'empty-field ' : ''
                  }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                  placeholder="Enter full name"
                  {...register('name', { required: true })}
                />
              </div>
              <div>
                <div className="font-semibold text-xs mb-4">Status</div>
                <Controller
                  control={control}
                  name="is_active"
                  render={({ field: { onChange, value } }) => (
                    <Toggle checked={value} onChange={onChange} />
                  )}
                />
              </div>
            </div>
            <div>
              <div className="font-semibold text-[12px] mb-2">Alias Name</div>
              <input
                className={`${
                  errors.name ? 'empty-field ' : ''
                }input-text text-xs px-4 py-3 h-10 border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                placeholder="Enter alias name"
                {...register('alias_name', { required: true })}
              />
            </div>
            <div className="flex-1 mb-6">
              <div className="font-semibold text-[12px] mb-2">Parent</div>
              <Controller
                control={control}
                name="parents"
                render={({ field: { onChange, value } }) => (
                  <Select
                    components={{ DropdownIndicator }}
                    value={value}
                    onChange={selected => onChange(selected)}
                    options={parentList}
                    className={`input-select text-[12px] border rounded-lg w-full focus:outline-none focus:border-[#6546C3] mb-4`}
                    placeholder="Select parent"
                    isClearable={true}
                    isMulti={true}
                    styles={{
                      option: (provided, state) => ({
                        ...provided
                      }),
                      control: provided => ({
                        ...provided,
                        ':focus': { borderColor: '#6546C3' },
                        ':active': { borderColor: '#6546C3' },
                        ':hover': { borderColor: '#6546C3' },
                        paddingLeft: 4,
                        boxShadow: 'none'
                      }),
                      dropdownIndicator: provided => ({
                        ...provided,
                        color: '#C800A5',
                        ':hover': { color: '#6546C3' }
                      }),
                      indicatorSeparator: () => ({}),
                      valueContainer: provided => ({
                        ...provided
                      }),
                      multiValueLabel: provided => ({
                        ...provided,
                        color: '#fff',
                        fontFamily: 'poppins'
                      }),
                      multiValueRemove: provided => ({
                        ...provided,
                        color: '#fff'
                      }),
                      multiValue: provided => ({
                        ...provided,
                        backgroundColor: '#6546C3',
                        borderRadius: 8
                      })
                    }}
                  />
                )}
              />
            </div>
            <br></br>
            <div className="flex justify-end gap-4 lg:col-span-3">
              <button
                type="button"
                onClick={() => handleCloseModal()}
                className="py-3 px-8 border-[1px] border-[#6546C3] text-[#6546C3] text-[12px] font-semibold rounded-md"
              >
                Back
              </button>
              <button
                type="submit"
                className="py-3 px-8 bg-gradient-to-r from-[#6546C3] to-[#9E7CE3] text-white text-[12px] font-semibold rounded-md"
              >
                Submit
              </button>
            </div>
          </form>
          <SuccessPopup />
        </div>
      </div>
    </>
  )
}
